import React, { useEffect } from "react"
import { useState, useCallback , useMemo } from 'react';
import ReactFlow, { Controls, Background, applyNodeChanges, applyEdgeChanges, Panel , useReactFlow , ReactFlowProvider , useViewport} from 'reactflow';
import 'reactflow/dist/style.css';
import ButtonEdge from "../../../Component/edge-types/ButtonEdge";
import Default from "../../../Component/node-types/Default";
import End from "../../../Component/node-types/End";
import Start from "../../../Component/node-types/Start";
// import inquiry from "../../../data/templates/Inquiry";
import templateToArray from "../../../utilities/templateToArray";
// import templateToArray from "../../utilities/templatetoArray";
import {useSelector , useDispatch} from 'react-redux'
import convertTemplateToFlowChart from "../../../utilities/convertTemplateToFlowChart";
import NodePopup from "../../../Component/node-types/NodePopup";
import NodeMenu from "../../../Component/panels/NodeMenu";
import { getBotFlowChartData, resetTemplate, updateNodeMenuPosition, updateTemplate } from "../../../features/botTemplate/botTemplateSlice";

const EditorDesign = (props) =>{

        //getting the reactflow instance object
        const reactFlowInstance = useReactFlow();

        //getting the viewport
        const viewport = useViewport()

        //destructuring

        const {template} = props

        //getting data from the global state
        //state -- botTemplate
        const {nodeMenuPosition , nodeToBeFocused} = useSelector(state => state.botTemplate)





        //initializing dispatch
        const dispatch = useDispatch()


        

        //local states
        //array of nodes and edges
        const [nodes, setNodes] = useState([]);
        const [edges, setEdges] = useState([]);

        // useEffect(() => {
        //         const urlParams = new URLSearchParams(window.location.search)
        //         const botId = urlParams.get('did')
        //         dispatch(getBotFlowChartData(botId))
        // },[])

        useEffect(() => {
                       if(template){
                         //calling utility function - templateToArray
                        //@DEF - to convert the json template into the array of nodes and edges
                        const {nodes : initialNodes , edges : initialEdges} = convertTemplateToFlowChart(template)

                        

                        
                        //updating local states
                        //states -- nodes and edges for react flow component
                        setNodes(initialNodes)
                        setEdges(initialEdges)
                        // let viewPort = initialNodes[initialNodes.length -1].position
                        // let focusableNode = null

                        // for (var i=0 ; i<initialNodes.length ; i++){
                        //     if(initialNodes[i].id == nodeToBeFocused){
                        //         focusableNode =  initialNodes[i];
                        //     }
                        // }
                        // console.log(focusableNode , ' focus')
                        // let viewPort = focusableNode.position
                        // console.log(viewPort , ' view')
                        // viewPort = {...viewPort,
                        // x : 0 - viewPort.x + 500 , y : 0 - viewPort.y , zoom  : 1}
                        // // reactFlowInstance.viewportInitialized = true
                        if(viewport.x === 0 && viewport.y === 0 && viewport.zoom === 1){
                            let initialViewport = initialNodes[initialNodes.length -1].position
                            initialViewport = {
                                ...initialViewport,
                                x : 0 - initialViewport.x + 500,
                                y : 0 - initialViewport.y,
                                zoom : 1
                            }
                            reactFlowInstance.setViewport(initialViewport)
                        }
                        else{
                            reactFlowInstance.setViewport(viewport)
                        }
                        
                        // console.log(reactFlowInstance, ' swag')
                        // console.log(viewport , ' swag 2')
                       }
    },[template , reactFlowInstance])

    // useEffect(() => {
    //     return () => {
    //         dispatch(resetTemplate())
    //     }
    // },[])

        
        // useEffect(() => {                

            
        //         if(!template){
        //             const urlParams = new URLSearchParams(window.location.search)
        //         const botId = urlParams.get('did')
        //         dispatch(getBotFlowChartData(botId))

        //                //calling utility function - templateToArray
        //                 //@DEF - to convert the json template into the array of nodes and edges
        //                 const {nodes : initialNodes , edges : initialEdges} = convertTemplateToFlowChart(template)

                        

                        
        //                 //updating local states
        //                 //states -- nodes and edges for react flow component
        //                 setNodes(initialNodes)
        //                 setEdges(initialEdges)
        //                 // let viewPort = initialNodes[initialNodes.length -1].position
        //                 // let focusableNode = null

        //                 // for (var i=0 ; i<initialNodes.length ; i++){
        //                 //     if(initialNodes[i].id == nodeToBeFocused){
        //                 //         focusableNode =  initialNodes[i];
        //                 //     }
        //                 // }
        //                 // console.log(focusableNode , ' focus')
        //                 // let viewPort = focusableNode.position
        //                 // console.log(viewPort , ' view')
        //                 // viewPort = {...viewPort,
        //                 // x : 0 - viewPort.x + 500 , y : 0 - viewPort.y , zoom  : 1}
        //                 // // reactFlowInstance.viewportInitialized = true
        //                 if(viewport.x === 0 && viewport.y === 0 && viewport.zoom === 1){
        //                     let initialViewport = initialNodes[initialNodes.length -1].position
        //                     initialViewport = {
        //                         ...initialViewport,
        //                         x : 0 - initialViewport.x + 500,
        //                         y : 0 - initialViewport.y,
        //                         zoom : 1
        //                     }
        //                     reactFlowInstance.setViewport(initialViewport)
        //                 }
        //                 else{
        //                     reactFlowInstance.setViewport(viewport)
        //                 }
                        
        //                 // console.log(reactFlowInstance, ' swag')
        //                 // console.log(viewport , ' swag 2')

        //         }
            
                
        //     // else{

        //     //                 //calling utility function - templateToArray
        //     //             //@DEF - to convert the json template into the array of nodes and edges
        //     //             const {nodes : initialNodes , edges : initialEdges} = convertTemplateToFlowChart(template)

                        

                        
        //     //             //updating local states
        //     //             //states -- nodes and edges for react flow component
        //     //             setNodes(initialNodes)
        //     //             setEdges(initialEdges)
        //     //             // let viewPort = initialNodes[initialNodes.length -1].position
        //     //             // let focusableNode = null

        //     //             // for (var i=0 ; i<initialNodes.length ; i++){
        //     //             //     if(initialNodes[i].id == nodeToBeFocused){
        //     //             //         focusableNode =  initialNodes[i];
        //     //             //     }
        //     //             // }
        //     //             // console.log(focusableNode , ' focus')
        //     //             // let viewPort = focusableNode.position
        //     //             // console.log(viewPort , ' view')
        //     //             // viewPort = {...viewPort,
        //     //             // x : 0 - viewPort.x + 500 , y : 0 - viewPort.y , zoom  : 1}
        //     //             // // reactFlowInstance.viewportInitialized = true
        //     //             if(viewport.x === 0 && viewport.y === 0 && viewport.zoom === 1){
        //     //                 let initialViewport = initialNodes[initialNodes.length -1].position
        //     //                 initialViewport = {
        //     //                     ...initialViewport,
        //     //                     x : 0 - initialViewport.x + 500,
        //     //                     y : 0 - initialViewport.y,
        //     //                     zoom : 1
        //     //                 }
        //     //                 reactFlowInstance.setViewport(initialViewport)
        //     //             }
        //     //             else{
        //     //                 reactFlowInstance.setViewport(viewport)
        //     //             }
                        
        //     //             // console.log(reactFlowInstance, ' swag')
        //     //             // console.log(viewport , ' swag 2')

        //     //     }

        //     return () => {
        //         dispatch(resetTemplate())
        //     }

            
                            
        // },[template , reactFlowInstance])


        // useEffect(() => {
        //                 if(template){
        //                         //calling utility function - templateToArray
        //                 //@DEF - to convert the json template into the array of nodes and edges
        //                 const {nodes : initialNodes , edges : initialEdges} = convertTemplateToFlowChart(template)

                        
        //                 //updating local states
        //                 //states -- nodes and edges for react flow component
        //                 setNodes(initialNodes)
        //                 setEdges(initialEdges)
        //                 // let viewPort = initialNodes[initialNodes.length -1].position
        //                 // let focusableNode = null

        //                 // for (var i=0 ; i<initialNodes.length ; i++){
        //                 //     if(initialNodes[i].id == nodeToBeFocused){
        //                 //         focusableNode =  initialNodes[i];
        //                 //     }
        //                 // }
        //                 // console.log(focusableNode , ' focus')
        //                 // let viewPort = focusableNode.position
        //                 // console.log(viewPort , ' view')
        //                 // viewPort = {...viewPort,
        //                 // x : 0 - viewPort.x + 500 , y : 0 - viewPort.y , zoom  : 1}
        //                 // // reactFlowInstance.viewportInitialized = true
        //                 if(viewport.x === 0 && viewport.y === 0 && viewport.zoom === 1){
        //                     let initialViewport = initialNodes[initialNodes.length -1].position
        //                     initialViewport = {
        //                         ...initialViewport,
        //                         x : 0 - initialViewport.x + 500,
        //                         y : 0 - initialViewport.y,
        //                         zoom : 1
        //                     }
        //                     reactFlowInstance.setViewport(initialViewport)
        //                 }
        //                 else{
        //                     reactFlowInstance.setViewport(viewport)
        //                 }
                        
        //                 // console.log(reactFlowInstance, ' swag')
        //                 // console.log(viewport , ' swag 2')

        //                 }
        // },[template , reactFlowInstance])

        


      


        //adding custom node types in the reactflow component
        const nodeTypes = useMemo(() => ({ default : Default , start : Start , end : End }), []);

        //adding custom edge types in the reactflow component
        const edgeTypes = {
            
            buttonedge: ButtonEdge,
        };


    
        


        //callback function
        //@DEF - handles the drag and drop of the nodes
        const onNodesChange = useCallback(
            (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
            []
        );
        const onEdgesChange = useCallback(
            (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
            []
        );

        

  
            
    
    return(
        <>
        
            {template && <div style={{ height: '100%' , width : '100%'}}>
                <ReactFlow
                    nodes={nodes}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    onEdgesChange={onEdgesChange}
                    nodeTypes = {nodeTypes}
                    edgeTypes = {edgeTypes}
                    onEdgeClick = {(event) => {console.log(event)}}
                    panOnDrag = {nodeMenuPosition ? false :  true}
                    panOnScroll = {nodeMenuPosition ? false :  true}
                    zoomOnScroll = {false}
                    onPaneClick = {() => {dispatch(updateNodeMenuPosition(null))}}
                    // onPaneScroll = {(event) => {dispatch}}
                    // preventScrolling = {nodeMenuPosition ? false :  true}
                    //fitView = 'true'
                    // minZoom={1}
                    // fitView = 'true'
                    // onInit={(reactFlowInstance) => {initializeViewport(reactFlowInstance)}}
                    // defaultViewport = {{x : -1350 + 500 , y : -50 , zoom : 1}}
                    // defaultViewport = {{x : 0 , y : 0 , zoom : 1}}
                >
                    {/* <Background/> */}
                    {nodeMenuPosition && <Panel style={{position : 'fixed' , top : nodeMenuPosition.y , left : nodeMenuPosition.x}} > <NodeMenu/> </Panel>}
                    <Controls position = 'bottom-right' style={{display : 'flex' , flexDirection : 'row'}} />
                </ReactFlow>
                </div>}
        </>
    )
}

export default EditorDesign