import React from 'react'
import { useEffect } from 'react'
import { getCookie, getRealmSession, handleLogin, redirectToCommonLogin } from '../../utilities/chatbotMethods'
import { useNavigate } from "react-router-dom";



function Login(props) {


  const navigate = useNavigate()

  useEffect(() => {
     ( async() => {
      //check if alrteady login
  
      if (localStorage.getItem('email')) {
        //redirect to chatbot
        if(sessionStorage.getItem('loginSource')){
          var link = sessionStorage.getItem('loginSource')
          sessionStorage.removeItem('loginSource')
          navigate(link)
          
        }
        else{
        navigate('/cx/Chatbot')

        }
      }
      else {
        var loginCookie = getCookie('APPYID')
        // loginCookie = '9badfc94-a78b-49ad-b36c-f043457dd87b'
        // loginCookie = '24e3a0e0-9c1a-4898-bdf0-9d73625476c2'
        // loginCookie = 'c3713eea-3cc1-4eaf-bd9b-a717ed140f0c'
        if (loginCookie) {
          let userData = await getRealmSession(loginCookie)
          if (userData) {
            handleLogin(userData , navigate).then((res) => {
              //redirect to dashboard
              if(sessionStorage.getItem('loginSource')){
                var link = sessionStorage.getItem('loginSource')
          sessionStorage.removeItem('loginSource')
          navigate(link)
                
              }
              else{
              navigate('/cx/Chatbot')
      
              }
            })
  
          }
          else {
            // debugger
            //redirect to login
            redirectToCommonLogin()
          }
        }
        else {
          //redirect to login
          // debugger
  
          redirectToCommonLogin()
        }
      }
      //get realm data using cookies
      //check if email id exists in db
      //save/fetch details in db
      //set locakl storage
    })()
  },[])
  return (
    <div>Login</div>
  )
}




export default Login