import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Handle , Position} from 'reactflow'
import { updateSelectedNode } from '../../features/botTemplate/botTemplateSlice'
import DropDownList from '../DropDownList'
import TestButton from '../TestButton'
import { getProper } from '../../utilities/chatbotMethods'

function Default(props) {

    //getting data from the global state
    const {selectedNode} = useSelector(state => state.botTemplate)

    // //getting the flow from the template
    // const flow = template.template_One

    // //getting the node id from the props
    // const nodeId = Number(props.id)

    // //getting the id of the parent node
    // const parentNodeId = Number(flow[nodeId].parentNode)

    

    //initiliazing dispatch
    const dispatch = useDispatch()

    //updates teh global state
    //state -- botTemplate.selectedNode
    //dispatch action -- updateSelectedNode
    //payload -- node object
    function onClickHandler(selectedNode){

        //dispatching action
        //action creator -- updateSelectedNode
        dispatch(updateSelectedNode(selectedNode))
    }




  return (
     <div className={(selectedNode && Number(selectedNode.id) === Number(props.id)) ? "editorBox border border-[#DDDDDD] rounded-xl w-96 border border-[blue]" : "editorBox border border-[#DDDDDD] rounded-xl w-96" } >
                <div className="editorHeader flex items-center justify-center border-[#DDDDDD] border-b-[1px] px-3 py-2">
                    <span className="editorIcon w-5 h-5 rounded bg-[#019354] mr-2 text-white flex items-center justify-center" >
                        <img src={require("../../img/edit-message.png")} />
                    </span>
                    <span className="editorTitle text-[#838383] text-sm" >{props.data.UI} {props.id} {props.data.parentNode}</span>
                    <span className="dropdown editorDropDown ml-auto cursor-pointer px-2">    
                        <svg xmlns="http://www.w3.org/2000/svg" width="3.216" height="10.864" viewBox="0 0 2.216 8.864">
                            <path id="Icon_metro-more-vert" data-name="Icon metro-more-vert" d="M16.532,9.928A1.108,1.108,0,1,0,15.424,8.82,1.108,1.108,0,0,0,16.532,9.928Zm0,1.108a1.108,1.108,0,1,0,1.108,1.108A1.108,1.108,0,0,0,16.532,11.036Zm0,3.324a1.108,1.108,0,1,0,1.108,1.108A1.108,1.108,0,0,0,16.532,14.36Z" transform="translate(-15.424 -7.712)" fill="#acacac" />
                        </svg>
                        {/* <DropDownList/> */}
                        {/* test button for testing purpose and will be deleted  */}
                        <TestButton nodeId = {props.id} template = {props.data.template}/>
                    </span>
                </div>
                <div className="editorBody px-3 py-3" onClick={() => {onClickHandler({data : props.data , id : props.id})}}>
                    <p className="text-xs font-semibold text-[#5C5C5C] truncate">{getProper(props.data.data.text).replace(
                    /(<([^>]+)>)/gi,
                    ""
                  )}</p>
                    {/* dangerouslySetInnerHTML={{ __html: props.data.data.text }} */}
                    {/* <p className="text-xs font-semibold text-[#5C5C5C] truncate" dangerouslySetInnerHTML={{ __html: props.data.data.text.replace(
                    /(<([^>]+)>)/gi,
                    ""
                  ) }}  /> */}
                </div>
                <Handle style={{visibility : 'hidden'}} type="target" position={Position.Top} isConnectable={true} />
                <Handle
                    type="source"
                    position={Position.Bottom}
                    //id="a"
                    style={{visibility : 'hidden'}}
                    isConnectable={true}
                />
                
            </div> 


    // <div>
    //     <Handle type="target" position={Position.Top} isConnectable={true} />
    //     <p>{props.id}</p>
    //     <p>{props.data.UI}</p>
    //     <Handle
    //     type="source"
    //     position={Position.Bottom}
    //     id="a"
    //     //style={handleStyle}
    //     isConnectable={true}
    //   />
    // </div>
  )
}

export default Default