import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Home() {

    const navigate = useNavigate()
    useEffect(() => {
        navigate('/cx/Chatbot')
    },[])
  return (
    <div>Loading...</div>
  )
}

export default Home