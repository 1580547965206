import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select'
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';

const Survey = (props)=>{


    const selectStatusOption = [
        { label: 'Scale', value: 'rating-scale' },
        { label: 'Stars', value: 'rating-stars' },
        { label: 'Smileys', value: 'rating-smile' }
      ]
      
      const scale_presentation = [
        { label: '1 to 5 scale', value: '1-5' },
        { label: '1 to 10 scale', value: '1-10' }
      ]
      const stars_presentation = [
        { label: '3 stars', value: '3' },
        { label: '5 stars', value: '5' }
      ]
      const smile_presentation = [
        { label: '3 smileys', value: '3' },
        { label: '5 smileys', value: '5' }
      ]


    //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }
 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }

     



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)


    const optionPlaceholderString = selectedNode.data.data.optionPlaceholder ? selectedNode.data.data.optionPlaceholder : 'Skip this question'
    const optionSuggestionString = selectedNode.data.data.optionSuggestion ? selectedNode.data.data.optionSuggestion : 'You can skip this question by clicking on '
    const tipIcon = selectedNode.data.data.tipIcon ? selectedNode.data.data.tipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f'
    const skipIcon = selectedNode.data.data.skipIcon ? selectedNode.data.data.skipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556'
    const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text


    //initial state of rich text editor
    const [botMessage , setBotMessage] = useState(getInitialStateForEditor(selectedNode.data.data.text))
    const [feedbackType , setFeedbackType] = useState(selectedNode.data.data.feedback_type.type ? selectedNode.data.data.feedback_type.type :selectStatusOption[0])
    const [feedbackPresentationOption , setFeedbackPresentationOption] = useState({})
    const [feedbackPresentation , setFeedbackPresentation] = useState(selectedNode.data.data.feedback_type.presentation ? selectedNode.data.data.feedback_type.presentation :scale_presentation[0])
    const [optionPlaceholder , setOptionPlaceholder] = useState(getInitialStateForEditor(optionPlaceholderString))
    const [optionSuggestion , setOptionSuggestion] = useState(getInitialStateForEditor(optionSuggestionString))
    const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
    const [isOptional , setIsOptional] = useState(selectedNode.data.data.isOptional ? selectedNode.data.data.isOptional : false)
    const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)


    //callback function
    const onFeedbackTypeChange = (selectedOption) => {
        if(selectedOption.label === 'Scale'){
            setFeedbackType(selectedOption)
            setFeedbackPresentationOption(scale_presentation)
            setFeedbackPresentation(scale_presentation[0])
        }
        else if(selectedOption.label === 'Stars'){
            setFeedbackType(selectedOption)
            setFeedbackPresentationOption(stars_presentation)
            setFeedbackPresentation(stars_presentation[0])
        }
        else{
            setFeedbackType(selectedOption)
            setFeedbackPresentationOption(smile_presentation)
            setFeedbackPresentation(smile_presentation[0])
        }
     }

     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {
        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                
                feedback_type : {
                    ...selectedNode.data.data.feedback_type,
                    type : feedbackType,
                    presentation : feedbackPresentation
                },
                text : getHtmlFromEditorState(botMessage),
                isOptional,
                optionPlaceholder : getHtmlFromEditorState(optionPlaceholder),
                optionSuggestion : getHtmlFromEditorState(optionSuggestion),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff),
                skipIcon,
                tipIcon,

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }

     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }


     useEffect(() => {
        if(feedbackType.label === 'Scale'){            
            setFeedbackPresentationOption(scale_presentation)
        }
        else if(feedbackType.label === 'Stars'){           
            setFeedbackPresentationOption(stars_presentation)            
        }
        else{            
            setFeedbackPresentationOption(smile_presentation)            
        }
     },[feedbackType])

     

    return(
        <>
            <div className='mb-4 px-3'>
                <h5>Bot Message</h5>
                <RichTextEditor editorState = {botMessage} setEditorState = {setBotMessage} selectedNode = {selectedNode} />
            </div>
            
            <div className='flex items-center mb-2 px-3'>
                <p className='mr-auto text-[14px]'>Feedback type:</p>
                <div className='w-32 max-w-[128px] min-w-[128px]'>
                    <Select options = {selectStatusOption} value = {feedbackType} onChange = {(selectedOption) => {onFeedbackTypeChange(selectedOption)}} />
                </div>
            </div>
            <div className='flex items-center mb-2 px-3'>
                <p className='mr-auto text-[14px]'>Feedback Presentation:</p>
                <div className='w-32 max-w-[128px] min-w-[128px]'>
                    <Select options = {feedbackPresentationOption} value = {feedbackPresentation} onChange = {(selectedOption) => {setFeedbackPresentation(selectedOption)}}  />
                </div>
            </div>

            <div className='flex items-center px-3 pb-3'>
                <input type='checkbox' id='aa' checked = {isOptional} onChange = {() => setIsOptional(!isOptional)} />
                <label className='text-[14px] pl-2 cursor-pointer ' for="aa">Optional</label>
            </div>
            {isOptional && <div>
            <p>Option skipping suggestion text</p>
            <RichTextEditor editorState = {optionSuggestion} setEditorState = {setOptionSuggestion} selectedNode = {selectedNode}/>
            <p>Option skipping text placeholder</p>
            <RichTextEditor editorState = {optionPlaceholder} setEditorState = {setOptionPlaceholder} selectedNode = {selectedNode}/>
        </div>}
            <div className='flex items-center px-3 pb-3'>
                <input type='checkbox' id='bb' checked = {shortHand} onChange = {() => setShortHand(!shortHand)} />
                <label className='text-[14px] pl-2 cursor-pointer ' for="bb">Shorten for Handoffs</label>
            </div>
            {shortHand && <div>
            <p>Text for Handoffs</p>
            <RichTextEditor editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode}/>
        </div>}

            <div className='editorBtn px-3 py-3'>
                  <button onClick = {handleSave} className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button onClick = {handleCancel} className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
              </div>
        </>
    )
}


export default Survey;