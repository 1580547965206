import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import SideMenu from "../../Component/sidemenu";
import "react-accessible-accordion/dist/fancy-example.css";

const Trainbot = () => {
  return (
    <>
      {/* <SideMenu /> */}
        <div className="boatEditSection bg-white">
          <div className="importQuestion w-full">
            <div className="searchQuestion flex items-center mb-5">
              <label className="relative block">
                <span className="sr-only">Search</span>
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.701" height="19.701" viewBox="0 0 19.701 19.701">
                    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3.75 -3.75)">
                      <path
                        id="Path_174173"
                        data-name="Path 174173"
                        d="M20.4,12.451A7.951,7.951,0,1,1,12.451,4.5,7.951,7.951,0,0,1,20.4,12.451Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#b4b4b4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_174174"
                        data-name="Path 174174"
                        d="M29.3,29.3l-4.324-4.324"
                        transform="translate(-6.908 -6.908)"
                        fill="none"
                        stroke="#b4b4b4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </span>
                <input
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search..."
                  type="text"
                  name="search"
                  autoComplete="off"
                />
              </label>
              <div className="btnSearchImportfile ml-auto flex items-center">
                <button className="group flex items-center border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-5 py-2 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">
                  Import from library
                </button>
                <button className="flex items-center border border-indigo-600 text-sm px-5 py-2 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white mx-3">
                  Add new question
                </button>
                <span className="flex items-center justify-center bg-[#6D85EB] w-8 h-8 rounded-full cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.832" height="8.891" viewBox="0 0 13.832 8.891">
                    <path
                      id="Path_22814"
                      data-name="Path 22814"
                      d="M9.16,7.875c-2.482,0-4.44,1.562-6.78,4.115a.488.488,0,0,0,0,.658c2,2.214,3.77,4.118,6.783,4.118,2.976,0,5.208-2.4,6.8-4.137a.484.484,0,0,0,.015-.639C14.353,10.03,12.115,7.875,9.16,7.875ZM9.3,15.1a2.779,2.779,0,1,1,2.646-2.646A2.78,2.78,0,0,1,9.3,15.1Z"
                      transform="translate(-2.252 -7.875)"
                      fill="#fff"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="customeAccordian">
              <Accordion>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className="accordianHeader flex items-center bg-[#5269C9] px-5 py-3 text-white text-sm">
                      <p className="text-white text-sm">Source: File type - Sheet1.tsv</p>
                      <span className="ml-auto adddQuestionSpan flex w-5 h-5 items-center justify-center bg-[#6D85EB] rounded-full text-white cursor-pointer">
                        +
                      </span>
                      <span className="ml-3 adddQuestionSpan flex items-center justify-center cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.675"
                          height="9.534"
                          viewBox="0 0 16.675 9.534"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-down"
                            data-name="Icon ionic-ios-arrow-down"
                            d="M14.528,14.121l6.305,6.31a1.187,1.187,0,0,0,1.683,0,1.2,1.2,0,0,0,0-1.688l-7.144-7.149a1.189,1.189,0,0,0-1.643-.035L6.535,18.738a1.192,1.192,0,0,0,1.683,1.688Z"
                            transform="translate(-6.188 -11.246)"
                            fill="#fff"
                          />
                        </svg>
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="accordianBody bg-[#F8F9FB] px-5 py-3 rounded-b-lg">
                    <div className="accordianBody">
                      <div className="questionFeilds mb-10">
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What are python namespaces?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <textarea
                            type="text"
                            rows="5"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="A Python namespace ensures that object names in a program are unique and
can be used without any conflict."
                          />
                        </div>
                      </div>
                      <div className="questionFeilds mb-5 bg-white px-5 py-5 border  rounded-lg relative hover:bg-[#F8F9FB]">
                        <span className="absolute flex items-center justify-center w-8 h-8 rounded-full drop-shadow-md top-2 right-2 bg-white cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.849"
                            height="13.949"
                            viewBox="0 0 10.849 13.949"
                          >
                            <path
                              id="Icon_material-delete-forever"
                              data-name="Icon material-delete-forever"
                              d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#5269c9"
                            />
                          </svg>
                        </span>
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What is pep 8?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <input
                            type="text"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="PEP in Python stands for Python Enhancement Proposal."
                          />
                        </div>
                      </div>
                      <div className="questionFeilds mb-5 bg-white px-5 py-5 border  rounded-lg relative hover:bg-[#F8F9FB]">
                        <span className="absolute flex items-center justify-center w-8 h-8 rounded-full drop-shadow-md top-2 right-2 bg-white cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.849"
                            height="13.949"
                            viewBox="0 0 10.849 13.949"
                          >
                            <path
                              id="Icon_material-delete-forever"
                              data-name="Icon material-delete-forever"
                              d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#5269c9"
                            />
                          </svg>
                        </span>
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What is pep 8?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <input
                            type="text"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="PEP in Python stands for Python Enhancement Proposal."
                          />
                        </div>
                      </div>
                      <div className="questionFeilds mb-5 bg-white px-5 py-5 border  rounded-lg relative hover:bg-[#F8F9FB]">
                        <span className="absolute flex items-center justify-center w-8 h-8 rounded-full drop-shadow-md top-2 right-2 bg-white cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.849"
                            height="13.949"
                            viewBox="0 0 10.849 13.949"
                          >
                            <path
                              id="Icon_material-delete-forever"
                              data-name="Icon material-delete-forever"
                              d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#5269c9"
                            />
                          </svg>
                        </span>
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What is pep 8?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <input
                            type="text"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="PEP in Python stands for Python Enhancement Proposal."
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className="accordianHeader flex items-center bg-[#5269C9] px-5 py-3 text-white text-sm">
                      <p className="text-white text-sm">Source: File type - Sheet1.tsv</p>
                      <span className="ml-auto adddQuestionSpan flex w-5 h-5 items-center justify-center bg-[#6D85EB] rounded-full text-white cursor-pointer">
                        +
                      </span>
                      <span className="ml-3 adddQuestionSpan flex items-center justify-center cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.675"
                          height="9.534"
                          viewBox="0 0 16.675 9.534"
                        >
                          <path
                            id="Icon_ionic-ios-arrow-down"
                            data-name="Icon ionic-ios-arrow-down"
                            d="M14.528,14.121l6.305,6.31a1.187,1.187,0,0,0,1.683,0,1.2,1.2,0,0,0,0-1.688l-7.144-7.149a1.189,1.189,0,0,0-1.643-.035L6.535,18.738a1.192,1.192,0,0,0,1.683,1.688Z"
                            transform="translate(-6.188 -11.246)"
                            fill="#fff"
                          />
                        </svg>
                      </span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="accordianBody bg-[#F8F9FB] px-5 py-3 rounded-b-lg">
                    <div className="accordianBody">
                      <div className="questionFeilds mb-10">
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What are python namespaces?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <textarea
                            type="text"
                            rows="5"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="A Python namespace ensures that object names in a program are unique and
can be used without any conflict."
                          />
                        </div>
                      </div>
                      <div className="questionFeilds mb-5 bg-white px-5 py-5 border  rounded-lg relative hover:bg-[#F8F9FB]">
                        <span className="absolute flex items-center justify-center w-8 h-8 rounded-full drop-shadow-md top-2 right-2 bg-white cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.849"
                            height="13.949"
                            viewBox="0 0 10.849 13.949"
                          >
                            <path
                              id="Icon_material-delete-forever"
                              data-name="Icon material-delete-forever"
                              d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#5269c9"
                            />
                          </svg>
                        </span>
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What is pep 8?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <input
                            type="text"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="PEP in Python stands for Python Enhancement Proposal."
                          />
                        </div>
                      </div>
                      <div className="questionFeilds mb-5 bg-white px-5 py-5 border  rounded-lg relative hover:bg-[#F8F9FB]">
                        <span className="absolute flex items-center justify-center w-8 h-8 rounded-full drop-shadow-md top-2 right-2 bg-white cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.849"
                            height="13.949"
                            viewBox="0 0 10.849 13.949"
                          >
                            <path
                              id="Icon_material-delete-forever"
                              data-name="Icon material-delete-forever"
                              d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#5269c9"
                            />
                          </svg>
                        </span>
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What is pep 8?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <input
                            type="text"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="PEP in Python stands for Python Enhancement Proposal."
                          />
                        </div>
                      </div>
                      <div className="questionFeilds mb-5 bg-white px-5 py-5 border  rounded-lg relative hover:bg-[#F8F9FB]">
                        <span className="absolute flex items-center justify-center w-8 h-8 rounded-full drop-shadow-md top-2 right-2 bg-white cursor-pointer">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.849"
                            height="13.949"
                            viewBox="0 0 10.849 13.949"
                          >
                            <path
                              id="Icon_material-delete-forever"
                              data-name="Icon material-delete-forever"
                              d="M8.275,16.9a1.554,1.554,0,0,0,1.55,1.55h6.2a1.554,1.554,0,0,0,1.55-1.55V7.6h-9.3Zm1.906-5.517,1.093-1.093,1.651,1.643,1.643-1.643,1.093,1.093-1.643,1.643,1.643,1.643L14.567,15.76l-1.643-1.643L11.282,15.76l-1.093-1.093,1.643-1.643Zm5.455-6.106L14.862,4.5H10.987l-.775.775H7.5v1.55H18.349V5.275Z"
                              transform="translate(-7.5 -4.5)"
                              fill="#5269c9"
                            />
                          </svg>
                        </span>
                        <div className="questionRow flex items-center mb-5">
                          <p className="text-base font-medium mr-2">Q1 :</p>
                          <input
                            type="text"
                            placeholder="What is pep 8?"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                          />
                          <button className="ml-3 bg-[#5269C9] rounded flex items-center justify-center w-9 h-9 text-center text-white font-semibold text-lg">
                            +
                          </button>
                        </div>
                        <div className="answerRow flex items-basline">
                          <p className="text-base font-medium mr-2">A1 :</p>
                          <input
                            type="text"
                            className="px-5 py-2 text-sm w-10/12 border rounded-md"
                            placeholder="PEP in Python stands for Python Enhancement Proposal."
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
    </>
  );
};

export default Trainbot;
