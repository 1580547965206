import React from "react";
import { Link } from "react-router-dom";

const Topheader =(props)=>{
    return(
        <>
        <div className="drop-shadow-sm bg-white w-full flex justify-between p-4 rounded-xl items-center">
        <div className="text-black font-semibold text-sm flex items-center">
          {props.pT&& <Link className="flex items-center" to="/chatBoatTemplate">
              <span className="inline-block pr-3 cursor-pointer ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.15" height="15.979" viewBox="0 0 20.15 15.979">
                      <g id="Group_72295" data-name="Group 72295" transform="translate(-317 -143.511)">
                          <path id="Path_22485" data-name="Path 22485" d="M25.65,18H7.5" transform="translate(310.5 133.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                          <path id="Path_22486" data-name="Path 22486" d="M14.075,20.65,7.5,14.075,14.075,7.5" transform="translate(310.5 137.425)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
                      </g>
                  </svg>  
              </span>
          </Link> } 
          {props.Analytics}{props.Chatbot}{props.cT}{props.datamanagement}{props.pT}{props.Usercontent}{props.HandoffLog}{props.AnswerLog}{props.PendingInquiries}
        </div>
        <div className="flex gap-6 items-center">
          <img className="w-4 cursor-pointer" src={require("../img/notificatio.png")} alt="Notification icon" />
          <span className=" border-r-[1px]">&nbsp;</span>
          <div className="rounded-full bg-[#F5F6FA] w-7 h-7 text-[12px] text-center leading-7 cursor-pointer font-semibold text-xs">
            {"GM"}
          </div>
          <div className="cursor-pointer font-semibold text-xs">{"Ola Boluwatife"}</div>
        </div>
      </div>
        </>
    )
}

export default Topheader;