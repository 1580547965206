import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath , MarkerType , getSmoothStepPath } from 'reactflow';
import { updateNodeMenuPosition, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
// import addNode from '../../utilities/getNewBotTemplate';
import {useDispatch} from 'react-redux'

import './buttonedge.css';
import getNewBotTemplate from '../../utilities/getNewBotTemplate';
import NodePopup from '../node-types/NodePopup';


export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {
    stroke : 'blue'
  },
  markerEnd,
  source,
  target,
  data
}) {

  //initializing dispatch
  const dispatch = useDispatch()
  // const [showModal, setShowModal] = React.useState(true);

  //handler function
  //@TRIGGERS WHEN - when user click the plus button on the edge of the flow
  //@PURPOSE - update the global state -- botTemplate to new bot template
  const onEdgeClick = (event , source,target) => {
    
    event.stopPropagation();
    console.log(event) //for debugging purpose


    dispatch(updateNodeMenuPosition({x : event.clientX , y : event.clientY , source , target}))


    // //calling utility function to get new bot template
    // const newTemplate = getNewBotTemplate(data , data.botTemplate )

    // //updating global state -- botTemplate
    // dispatch(updateTemplate(newTemplate))
    
  };
  
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    
  });

  return (
    <>
    
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
            
          }}
          className="nodrag nopan"
        >
          
          {/* <button data-modal-target="defaultModal" data-modal-toggle="defaultModal" className="edgebutton" type="button" onClick={(event) => onEdgeClick(event, {
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style ,
  markerEnd,
  source,
  target,
  newNode : 17,
  botTemplate : data
})}>
  +
</button> */}

      <a
        className="edgebutton pulse-animation"
        type="button" onClick={(event) => onEdgeClick(event , source , target)}>
        +
      </a>
    </div>
      </EdgeLabelRenderer>
      
      

    </>
  );
}
