import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';
const { convertToMarkdown } = require('draftjs-to-markdown');

function EndMessage(props) {



  

   //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState
      }
      else{
          EditorState.createEmpty()
      }
   }



   //initializing dispatch
   const dispatch = useDispatch()

   //destructuring props
   const {selectedNode , template} = props

   //getting flow from the template
   const flow = template.template_One

   //getting the id of the node to be edited
   const nodeId = Number(selectedNode.id)


   //local state
   const [botMessageText , setBotMessageText] = useState(getInitialStateForEditor(selectedNode.data.data.text))


    //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
      return draftToHtml(convertToRaw(editorState.getCurrentContent()))
   }




   //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
              ...selectedNode.data.data,
                text : getHtmlFromEditorState(botMessageText),
                

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }

  return (
    <div> 

      {/* rich text editor */}
      <RichTextEditor editorState = {botMessageText} setEditorState = {setBotMessageText} selectedNode = {selectedNode}/>

      <div className='editorBtn pt-4'>
            <button onClick={handleSave} className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' onClick={handleCancel}>Cancel</button>
        </div>


      {/* <p>{draftToHtml(convertToRaw(editorState.getCurrentContent()))}</p> */}
      {/* <p>{convertToRaw(editorState.getCurrentContent())}</p> */}


    </div>

    
  )
}

export default EndMessage