import React from 'react'

function SetupListItem(props) {
  return (
    <div className={props.depWeb === props.setupType ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer":"depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>props.selectDeployWeb(props.setupType)}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        {/* <img className="w-full h-full" src={require("../../img/customeWebsite.png")} alt = 'hello' /> */}
                        <img className="w-full h-full" src={require(`../../img/${props.imageUrl}`)} />
                      </span>
                      <span className="deplWebName text-sm">{props.name}</span>
                      <span className={props.depWeb === props.setupType? "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require(`../../img/${props.arrowUrl}`)} />
                      </span>
                    </div>
  )
}

export default SetupListItem