// import React, { useEffect, useRef, useState } from 'react';
// import { Editor } from '@tinymce/tinymce-react';
// import { style } from 'd3-selection';


import React, {useEffect, useRef, useState } from 'react';
import { EditorState, convertToRaw, Modifier , ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import RichTextEditor from '../../../Component/rich-text-editor/RichTextEditor';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeMessage from '../../../Component/editor-types/WelcomeMessage';
import EndMessage from '../../../Component/editor-types/EndMessage';
import BotMessage from '../../../Component/editor-types/BotMessage';
import Button from '../../../Component/editor-types/Button';
import Feedback from '../../../Component/editor-types/Feedback';
import Email from '../../../Component/editor-types/Email';
import Phone from '../../../Component/editor-types/Phone';
import { updateSelectedNode } from '../../../features/botTemplate/botTemplateSlice';
import Currency from '../../../Component/editor-types/Currency';
import DateSelector from '../../../Component/editor-types/DateSelector';
import Gender from '../../../Component/editor-types/Gender';
import GetName from '../../../Component/editor-types/GetName';
import GetCountry from '../../../Component/editor-types/GetCountry';
import FileUpload from '../../../Component/editor-types/FileUpload';
import GetUserInput from '../../../Component/editor-types/GetUserInput';
import Survey from '../../../Component/editor-types/Survey';
import GetAddress from '../../../Component/editor-types/GetAddress';
import GetPayment from '../../../Component/editor-types/GetPayment';
import RequestCallback from '../../../Component/editor-types/RequestCallback';
import Appointment from '../../../Component/editor-types/Appointment';
import GetLocation from '../../../Component/editor-types/GetLocation';
import GetFollow from '../../../Component/editor-types/GetFollow';
import Cards from '../../../Component/editor-types/Card';
import SetupHandoff from '../../../Component/editor-types/SetupHandoff';

 const EditorView =(props)=> {

  // const [styleState, setstyleState] = useState(0)
  // // console.log("dheeraj", props.type)



  // const editorRef = useRef(null);
  // const getValEditor = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setstyleState(384);
  //   }, 1000);
  // }, [styleState]);


  //getting data from the global state
  const {selectedNode , template} = useSelector(data => data.botTemplate)

  //initializing dispatch
  const dispatch = useDispatch()
  

  //local state
  //@PURPOSE - to store the state of rich text editor
  // const[editorState , setEditorState] = useState(EditorState.createEmpty())

  return (
    <>
      <div className='border border-[#D8D8D8] w-[410px] rounded-xl'>
          <div className='editorHeader bg-[#fff] px-3 py-3 rounded-t-xl flex items-center'>
              <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19.926" height="18.239" viewBox="0 0 21.926 20.239">
                    <g id="Icon_ionic-md-chatboxes" data-name="Icon ionic-md-chatboxes" transform="translate(-3.375 -4.5)">
                      <path id="Path_174660" data-name="Path 174660" d="M21.482,4.5H3.881a.507.507,0,0,0-.506.43V16.221a.529.529,0,0,0,.506.455H6.906V21.36l4.75-4.685h9.826a.425.425,0,0,0,.393-.455V4.93A.4.4,0,0,0,21.482,4.5Z" fill="#5269c9" />
                      <path id="Path_174661" data-name="Path 174661" d="M27.21,8.93H25.5v8.779c0,.843-.364,1.393-1.326,1.393H14.65L12.6,21.158h6.835l4.75,4.691V21.158H27.21a.434.434,0,0,0,.4-.458V9.409A.456.456,0,0,0,27.21,8.93Z" transform="translate(-2.31 -1.109)" fill="#5269c9" />
                    </g>
                  </svg>
              </span>
              <h5 className='text-[14px] pl-3'>{props.selectedNode.data.UI}</h5>
              <span className='ml-auto cursor-pointer'>
                  <svg onClick={() => dispatch(updateSelectedNode(null))} xmlns="http://www.w3.org/2000/svg" width="17.486" height="17.486" viewBox="0 0 17.486 17.486">
                    <g id="Icon_ionic-ios-close-circle-outline" data-name="Icon ionic-ios-close-circle-outline" transform="translate(-3.375 -3.375)">
                      <path id="Path_174658" data-name="Path 174658" d="M18.9,17.945l-2.215-2.215L18.9,13.514a.672.672,0,0,0-.95-.95l-2.215,2.215-2.215-2.215a.672.672,0,0,0-.95.95l2.215,2.215-2.215,2.215a.649.649,0,0,0,0,.95.667.667,0,0,0,.95,0l2.215-2.215,2.215,2.215a.675.675,0,0,0,.95,0A.667.667,0,0,0,18.9,17.945Z" transform="translate(-3.613 -3.611)" fill="#343434" />
                      <path id="Path_174659" data-name="Path 174659" d="M12.118,4.552A7.563,7.563,0,1,1,6.767,6.767a7.516,7.516,0,0,1,5.351-2.215m0-1.177a8.743,8.743,0,1,0,8.743,8.743,8.742,8.742,0,0,0-8.743-8.743Z" transform="translate(0 0)" fill="#343434" />
                    </g>
                  </svg>
              </span>
          </div>
          <div className='editorBody bg-[#F2F6F8] px-3 py-3 rounded-b-xl'>
            <div className={'editorView  rounded-xl border border-[#D8D8D8] relative transition'}>
              {/* <p className='text-sm text-[#5C5C5C] mb-2 font-semibold px-3 pt-3'>{props.selectedNode.data.UI  + ' ' + props.selectedNode.id}</p> */}
              {/* <Editor 
                  apiKey='your-api-key'
                  onInit={(evt, editor) => editorRef.current = editor}
                  initialValue="<p>Is there anything I can help you with?</p>"
                  init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: 'bold italic fontsize_formats | blocks | ' +
                      'alignleft aligncenter | forecolor| fontsizeselect | undo redo' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                  fontsize_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',    
                  }}
              /> */}

            {/* <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
              handlePastedText={handlePastedText}
              handleKeyCommand={handleKeyCommand}
              // toolbar = {{
              //   options : ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
              //   inline: {
              //     inDropdown: false,
              //     className: undefined,
              //     component: undefined,
              //     dropdownClassName: undefined,
              //     options: ['bold', 'italic', 'underline'],
                
              //   }
              // }}
            /> */}

            {selectedNode.data.UI === 'Welcome Message' && <WelcomeMessage selectedNode = {selectedNode} template = {template}/>}
            {selectedNode.data.UI === 'End Message' && <EndMessage selectedNode = {selectedNode} template = {template}/>}
            {selectedNode.data.UI === 'Bot Message' && <BotMessage selectedNode = {selectedNode} template = {template}/>}
            {selectedNode.data.UI === 'Button' && <Button selectedNode = {selectedNode} template = {template}/>}
            {selectedNode.data.UI === 'Feedback' && <Feedback selectedNode = {selectedNode} template = {template} /> }
            {selectedNode.data.UI === 'Get Email' && <Email selectedNode = {selectedNode} template = {template} />}
            {selectedNode.data.UI === 'Get Phone' && <Phone selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Currency' && <Currency selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Date Selector' && <DateSelector selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Gender' && <Gender selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Get Name' && <GetName selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Get Country' && <GetCountry selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'File Upload' && <FileUpload selectedNode = {selectedNode} template = {template}  />}
            {(selectedNode.data.UI === 'Get User Input' || selectedNode.data.UI === 'Bot Question') && <GetUserInput selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Survey' && <Survey selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Get Address' && <GetAddress selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Payment' && <GetPayment selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Request Callback' && <RequestCallback selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Date & Time' && <Appointment selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Get Location' && <GetLocation selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Follow us' && <GetFollow selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Cards' && <Cards selectedNode = {selectedNode} template = {template}  />}
            {selectedNode.data.UI === 'Handoffs' && <SetupHandoff selectedNode = {selectedNode} template = {template}/>}


            {/* <RichTextEditor editorState = {editorState} setEditorState = {setEditorState} selectedNode = {props.selectedNode}/> */}

            {/* <textarea
              disabled
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            />     */}
              {/* <div className='editorBtn pt-4'>
                  <button className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white ' onClick={()=>getValEditor()}>Save as draft</button><button className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' onClick={props.closeEditor}>Cancel</button>
              </div> */}
              {/* <p>{draftToHtml(convertToRaw(editorState.getCurrentContent()))}</p> */}
              
              {/* <div className='editorBtn pt-4'>
                  <button className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' onClick={() => console.log('cancel editor')}>Cancel</button>
              </div> */}
            </div>
          </div>
      </div>
    </>
  );
}

export default EditorView


