import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateinstalledIntegrations } from "../../features/integraion/integrationSlice";
import {
  getconnectURL,
  installMSteam,
  uninstallMSteam,
  fetchMSDropdown,
  getConnectHandoff
} from "./../../../src/utilities/chatbotMethods";

function MicrosoftIntegration(props) {
  const [isLoading, setIsLoading] = useState(true);
  const { installedIntegrations } = useSelector((state) => state.integration);
  const [isLogin, setIsLogin] = useState(false);
  const [isInstalled, setIsInstalled] = useState(false);
  const [toDisconnect, setToDisconnect] = useState(false);
  const [msTeamArr, setMsTeamArr] = useState([]);
  const [msChannelArr, setMsChannelArr] = useState([]);
  const [msTeam, setMsTeam] = useState("");
  const [msTeamName, setMsTeamName] = useState("");
  const [msChannel, setMsChannel] = useState("");
  const [msChannelName, setMsChannelName] = useState("");
  const [mode, setMode] = useState(props.mode);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [connectId, setConnectId] = useState("");
  const [nodeId, setNodeId] = useState("");
  const [error, setError] = useState(false);
  const [popupHit, setPopupHit] = useState(false);


  const dispatch = useDispatch();
  const triggerMSLogin = (data) => {};
  useEffect(() => {
    localStorage.setItem("connectType", "Message");
    localStorage.setItem("connectApp", "microsoftteams");
    // setIsLoading(false);
    console.log(props, "props in zendesk");



    if (props.mode == "edit") {

        getConnectHandoff("microsoftteams").then((res) => {
            if(res){
                setConnectId(res.connectId);
                setNodeId(res.actionNode);
                setMsTeam(res.teams&&res.teams.team?res.teams.team:"");
                setMsTeamName(res.teams&&res.teams.teamName?res.teams.teamName:"");
                setMsChannel(res.teams&&res.teams.channel?res.teams.channel:"");
                setMsChannelName(res.teams&&res.teams.channelName?res.teams.channelName:"");
                

                fetchMSDropdown(res.connectId,res.actionNode).then((list) => {
                    // setIsInstalled(true);
                    setMsTeamArr(list);
                    console.log(list)

                    if(res.teams&&res.teams.team){
                        fetchMSDropdown(res.connectId,res.actionNode,res.teams.team).then((list) => {
                            setMsChannelArr(list);
                            setIsLoading(false);
                        })
                    }else{
                        setIsLoading(false);
                    }
                  });

            }else{
                setIsLoading(false);
                setMode("install")
            }
        })

     
    } else {
      setIsLoading(false);
    }

   
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsBtnLoading(true);
    setError(false)
    if(msTeam&&msChannel){
    var data = {};
    var body = { "team":msTeam, "teamName":msTeamName, "channel":msChannel, "channelName" :msChannelName};
    data["teams"] = body;

    installMSteam(data).then((status) => {
        if (status) {
            dispatch(
            updateinstalledIntegrations(["microsoftteams", ...installedIntegrations])
            );
            setIsBtnLoading(false);
            props.closePopup();
        }
    })
    }else{
        setError(true)
        setIsBtnLoading(false);
    }

    
    //   if (status) {
    //     dispatch(updateinstalledIntegrations(["ms", ...installedIntegrations]));
    //     setIsLoading(false);
    //     props.closePopup();
    //   }
    // });
  };

  const handleTeamChange = (event) => {
    setMsTeam(event.target.value.split('(')[1].split(')')[0]);
    setMsTeamName(event.target.value.split("(")[0]);
    setMsChannel("");
    fetchMSDropdown(connectId ,nodeId,event.target.value.split('(')[1].split(')')[0] ).then((list) => {
      setMsChannelArr(list);
    });
  };
  const handleChannelChange = (event) => {
    setMsChannel(event.target.value.split('(')[1].split(')')[0]);
    setMsChannelName(event.target.value.split("(")[0]);
  };

  const handleLogin = () => {
    setIsBtnLoading(true);
    getconnectURL(props.appData.appId, props.appData.integrationUrl).then(
      (url) => {
        setIsBtnLoading(false);
        var wind = window.open(
          `${url}`,
          "popUpWindow",
          "height=500,width=700,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
        );

        // Add an event listener to listen for messages from the popup
        window.addEventListener("message", handleMessage);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("message", handleMessage);
        };
      }
    );
  };

  const handleMessage = (event) => {
    // Ensure the message is from an expected origin to avoid security risks

    console.log(event, "event", event.data);
    if (event.origin == window.location.origin) {
      
      const receivedData = event.data;
      if(receivedData&&receivedData.connectId){
      setConnectId(receivedData.connectId);
      setNodeId(receivedData.actionNode);
      setIsLoading(true);
    
      fetchMSDropdown(receivedData.connectId,receivedData.actionNode).then((list) => {
        setMsTeamArr(list);
        setMode("edit")
        setIsBtnLoading(false);
        setIsLoading(false);
        console.log(list)
        })
    }

      
    }
  };

  const handleUninstall = () => {
    setIsBtnLoading(true);
    uninstallMSteam().then((status) => {
      if (status) {
        dispatch(
          updateinstalledIntegrations(
            installedIntegrations.filter((item) => item !== "microsoftteams")
          )
        );
        setIsBtnLoading(false);
        props.closePopup();
      }
    });
  };

  return (
    <>
      {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}

      {isLoading ? (
        <p>Loading...</p>
      ) : mode == "uninstall" ? (
        <>
          <span>
            {" "}
            Are you sure you want to uninstall {props.appData.title} ?
          </span>
          <br />
          {isBtnLoading ? (
            <span>...</span>
          ) : (
            <button
              onClick={() => {
                handleUninstall();
              }}
            >
              {" "}
              Yes,uninstall
            </button>
          )}
          <br />
          <button
            onClick={() => {
              props.closePopup();
            }}
          >
            No
          </button>
        </>
      ) : mode == "edit" ? (
        <div className="zendeskContainer">
          <div className="zendeskHeader">
            <span className="zendeskHeaderImage">
              <img src="/dist/img/public/img/croseBgWhite.svg" alt="close" />
            </span>
          </div>
          <div className="zendeskBody pt-4 pb-4">
            <div className="integration-form-heading mb-3">
              <div className="integrationLeftImage">
                <img src="https://mediaconnect.appypie.com/media/icons/128x128/microsoft_teams.png" />
              </div>
              <div className="integrationReftDetails as">
                <div className="title">
                  <h4>Microsoft Teams</h4>
                </div>
                <div className="description">
                  <p className="mb-1">
                    Microsoft Teams is a hub for teamwork, productivity, and
                    collaboration. It brings together your chat, meetings,
                    notes, people, and tools into one place
                  </p>
                </div>
                <div className="link">
                  <span className="learnMore">Learn more:</span>Send a message
                  to a channel of your choice
                </div>
                <div />
                <div />
              </div>
            </div>
            <div>
              <div>
                <form
                  autoComplete="off"
                  onSubmit={
                    handleSubmit
                  }
                >
                  <div>
                    <div className="row">
                      <div className="col-md-12 md-3">
                        <label
                          htmlFor="defaultFormRegisterNameEx"
                          className="grey-text"
                        >
                          Select Integration *
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 mb-3">
                        <select className="form-control" required>
                          <option name="option" value="Send Channel Messages">
                            Send Channel Messages
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-3">
                      <label
                        htmlFor="defaultFormRegisterNameEx"
                        className="grey-text"
                      >
                        Select Team
                      </label>
                      <select
                        className="form-control"
                        required
                        disabled={!msTeamArr.length}
                        onChange={(event) =>
                          handleTeamChange(event)
                        }
                      >
                        <option name="option" value="">
                          Please select
                        </option>

                        {msTeamArr.map((optn, index) => (
                          <option
                            // key={1}
                            name="option"
                            value={optn.disp_val}
                          >
                            {optn.disp_val.split("(")[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label
                        htmlFor="defaultFormRegisterNameEx"
                        className="grey-text"
                      >
                        Select Channel
                      </label>
                      <select
                        className="form-control"
                        required
                        disabled={!msChannelArr.length}
                        onChange={(event) => {
                          handleChannelChange(event);
                        }}
                      >
                        <option name="option" value="">
                          Please select
                        </option>
                        {msChannelArr.map((optn, index) => (
                          <option
                            // key={1}
                            name="option"
                            value={optn.disp_val}
                          >
                            {optn.disp_val.split("(")[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {isBtnLoading ? (
                        <span>...</span>
                      ) : (
                        
                        <input type="submit" value="Connect" />
                      )
                      
                    }
                        {error&&<p>{"Select all fields"}</p>}

                      <span
                        onClick={() => {
                          props.closePopup();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <div>
              <img src={props.appData.img} />
            </div>
            <div>
              <div>
                <h4>Microsoft teams</h4>
              </div>
              <div>
                <p>
                  Receive messages in microsoft teams channel of your choice
                </p>
              </div>
              <div>
                <span>Learn more:</span>
              </div>
              <div />
              <div />
            </div>
          </div>
          <div>
            <div>
              <div>
                <label>Login with your microsoft teams account</label>

                {isBtnLoading ? (
                  <span>...</span>
                ) : (
                  <button onClick={() => handleLogin()}>Sign in with MS</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default MicrosoftIntegration;
