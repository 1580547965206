import * as firebase from 'firebase';
import 'firebase/firestore'

let config = {
   apiKey: "AIzaSyB23Z4vbidPUxMNrXl5hvCWsWYGDbT1dKw",
    authDomain: "appycx.firebaseapp.com",
    databaseURL: "https://appycx.firebaseio.com",
    projectId: "appycx",
    storageBucket: "appycx.appspot.com",
    messagingSenderId: "548534034975",
    appId: "1:548534034975:web:4b5d9d511094f3db"
};


// let config = {
//   apiKey: "AIzaSyAMt4qRjSSCXj-XkHiAm2vSp75Wcq14sx0",
//   authDomain: "appycx-beta.firebaseapp.com",
//   databaseURL: "https://appycx-beta.firebaseio.com",
//   projectId: "appycx-beta",
//   storageBucket: "appycx-beta.appspot.com",
//   messagingSenderId: "491738005705",
//   appId: "1:491738005705:web:0dde81c054aaf314da0d18",
//   measurementId: "G-KZ9D7MLQCK"
// };

var storageConnetion = firebase.storage();
export default storageConnetion;
