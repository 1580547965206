import React from 'react'

function DropDownList(props) {


  //handler function -- edit
  const onEditHandler = () => {
    props.onEdit()
  }

  //handler function -- delete
  const onDeleteHandler = () => {
    props.onDelete()
  }

  //handler function -- share
  const onShareHandler = () => {
    props.onShare()
  }

  //handler function -- duplicate
  const onDuplicateHandler = () => {
    props.onDuplicate()
  }
   
  



  return (
    // '<li><a className="rounded-t border-b py-1 px-4 block whitespace-no-wrap" href="#">Edit</a></li><li><a className="py-1 px-4 border-b block whitespace-no-wrap" href="#">Share</a></li><li><a className="rounded-b border-b py-1 px-4 block whitespace-no-wrap" href="#">Duplicate</a></li>'
    <ul className="dropdown-list text-[#566A81] text-[12px] w-[130px]">
                      {props.edit && <li onClick={onEditHandler}>
                        <a className="rounded-t border-b py-2 px-4 block whitespace-no-wrap font-medium flex items-center" href="#">
                          <span className='inline-block pr-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="11.935" height="10.608" viewBox="0 0 11.935 10.608">
                                <path id="Icon_awesome-edit" data-name="Icon awesome-edit" d="M8.342,1.729,10.211,3.6a.2.2,0,0,1,0,.286L5.686,8.41l-1.923.213a.4.4,0,0,1-.446-.446l.213-1.923L8.056,1.729A.2.2,0,0,1,8.342,1.729ZM11.7,1.255,10.688.243a.81.81,0,0,0-1.144,0L8.811.977a.2.2,0,0,0,0,.286L10.68,3.132a.2.2,0,0,0,.286,0L11.7,2.4a.811.811,0,0,0,0-1.144ZM7.957,7.179v2.11H1.326V2.658H6.088a.255.255,0,0,0,.176-.073l.829-.829a.249.249,0,0,0-.176-.425H.995A1,1,0,0,0,0,2.326V9.621a1,1,0,0,0,.995.995H8.288a1,1,0,0,0,.995-.995V6.35a.249.249,0,0,0-.425-.176L8.029,7A.255.255,0,0,0,7.957,7.179Z" transform="translate(0 -0.007)" fill="#566a81" />
                              </svg>
                          </span>
                          Edit
                        </a>
                      </li>}
                      {props.share && <li onClick={onShareHandler}>
                        <a className="py-2 px-4 border-b block whitespace-no-wrap font-medium flex items-center"  href="#">
                          <span className='inline-block pr-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12.883" height="11.452" viewBox="0 0 12.883 11.452">
                                <path id="Icon_awesome-share-square" data-name="Icon awesome-share-square" d="M12.714,3.969,9.494,7.011a.537.537,0,0,1-.905-.39V5.011c-3.234.022-4.6.785-3.685,3.833a.358.358,0,0,1-.559.388,4.306,4.306,0,0,1-1.66-3.207c0-3.22,2.63-3.858,5.9-3.877V.537a.537.537,0,0,1,.905-.39l3.221,3.042A.537.537,0,0,1,12.714,3.969ZM8.588,8.48v1.541H1.431V2.863H2.57a.268.268,0,0,0,.193-.083A4.36,4.36,0,0,1,3.9,1.938a.268.268,0,0,0-.124-.506H1.074A1.074,1.074,0,0,0,0,2.505v7.873a1.074,1.074,0,0,0,1.074,1.074H8.946a1.074,1.074,0,0,0,1.074-1.074V8.392a.268.268,0,0,0-.358-.253,1.606,1.606,0,0,1-.765.076A.269.269,0,0,0,8.588,8.48Z" fill="#566a81" />
                              </svg>
                          </span>
                          Share
                        </a>
                      </li>}
                      {props.duplicate && <li onClick={onDuplicateHandler}>
                        <a className="rounded-b border-b py-2 px-4 block whitespace-no-wrap font-medium flex items-center" href="#">
                          <span className='inline-block pr-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10.02" height="11.452" viewBox="0 0 10.02 11.452">
                                <path id="Icon_awesome-copy" data-name="Icon awesome-copy" d="M7.157,10.021v.895a.537.537,0,0,1-.537.537H.537A.537.537,0,0,1,0,10.915V2.684a.537.537,0,0,1,.537-.537h1.61V8.768A1.254,1.254,0,0,0,3.4,10.021Zm0-7.695V0H3.4a.537.537,0,0,0-.537.537V8.768A.537.537,0,0,0,3.4,9.3H9.484a.537.537,0,0,0,.537-.537V2.863H7.694A.538.538,0,0,1,7.157,2.326Zm2.706-.694L8.388.157A.537.537,0,0,0,8.009,0H7.873V2.147H10.02V2.012a.537.537,0,0,0-.157-.38Z" fill="#566a81" />
                              </svg>
                          </span>
                          Duplicate
                        </a>
                      </li>}
                      {props.delete && <li onClick={onDeleteHandler}>
                        <a className="rounded-b py-2 px-4 block whitespace-no-wrap font-medium flex items-center" href="#">
                          <span className='inline-block pr-2'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="9.485" height="12.196" viewBox="0 0 9.485 12.196">
                                <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.178,15.341A1.359,1.359,0,0,0,9.533,16.7h5.42a1.359,1.359,0,0,0,1.355-1.355V7.21H8.178ZM16.985,5.178H14.614L13.937,4.5H10.549l-.678.678H7.5V6.533h9.485Z" transform="translate(-7.5 -4.5)" fill="#566a81" />
                              </svg>
                          </span>
                          Delete
                        </a>
                      </li>}
    </ul>
    
  )
}

export default DropDownList

