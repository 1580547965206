import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select'
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';

const Feedback = (props)=>{


    const selectStatusOption = [
        { label: 'Scale', value: 'rating-scale' },
        { label: 'Stars', value: 'rating-stars' },
        { label: 'Smileys', value: 'rating-smile' }
      ]
      
      const scale_presentation = [
        { label: '1 to 5 scale', value: '1-5' },
        { label: '1 to 10 scale', value: '1-10' }
      ]
      const stars_presentation = [
        { label: '3 stars', value: '3' },
        { label: '5 stars', value: '5' }
      ]
      const smile_presentation = [
        { label: '3 smileys', value: '3' },
        { label: '5 smileys', value: '5' }
      ]


    //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }
 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }

     



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)


    //initial state of rich text editor
    const [botMessage , setBotMessage] = useState(getInitialStateForEditor(selectedNode.data.data.text))
    const [responseText , setResponseText] = useState(getInitialStateForEditor(selectedNode.data.data.responseText))
    const [feedbackType , setFeedbackType] = useState(selectStatusOption[0])
    const [feedbackPresentationOption , setFeedbackPresentationOption] = useState(scale_presentation)
    const [feedbackPresentation , setFeedbackPresentation] = useState(scale_presentation[0])

    //callback function
    const onFeedbackTypeChange = (selectedOption) => {
        if(selectedOption.label === 'Scale'){
            setFeedbackType(selectedOption)
            setFeedbackPresentationOption(scale_presentation)
            setFeedbackPresentation(scale_presentation[0])
        }
        else if(selectedOption.label === 'Stars'){
            setFeedbackType(selectedOption)
            setFeedbackPresentationOption(stars_presentation)
            setFeedbackPresentation(stars_presentation[0])
        }
        else{
            setFeedbackType(selectedOption)
            setFeedbackPresentationOption(smile_presentation)
            setFeedbackPresentation(smile_presentation[0])
        }
     }

     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {
        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                text : getHtmlFromEditorState(botMessage),
                responseText : getHtmlFromEditorState(responseText),
                feedback_type : {
                    ...selectedNode.data.data.feedback_type,
                    type : feedbackType,
                    presentation : feedbackPresentation
                }

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }

     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }

     

    return(
        <>
            <div className='mb-4 px-3'>
                <h5>Bot Message</h5>
                <RichTextEditor editorState = {botMessage} setEditorState = {setBotMessage} selectedNode = {selectedNode} />
            </div>
            <div className='mb-4 px-3'>
                <h5>Bot response</h5>
                <RichTextEditor editorState = {responseText} setEditorState = {setResponseText} selectedNode = {selectedNode} />
            </div>
            <div className='flex items-center mb-2 px-3'>
                <p className='mr-auto text-[14px]'>Feedback type:</p>
                <div className='w-32 max-w-[128px] min-w-[128px]'>
                    <Select options = {selectStatusOption} value = {feedbackType} onChange = {(selectedOption) => {onFeedbackTypeChange(selectedOption)}} />
                </div>
            </div>
            <div className='flex items-center mb-2 px-3'>
                <p className='mr-auto text-[14px]'>Feedback Presentation:</p>
                <div className='w-32 max-w-[128px] min-w-[128px]'>
                    <Select options = {feedbackPresentationOption} value = {feedbackPresentation} onChange = {(selectedOption) => {setFeedbackPresentation(selectedOption)}}  />
                </div>
            </div>

            <div className='editorBtn px-3 py-3'>
                  <button onClick = {handleSave} className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button onClick = {handleCancel} className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
              </div>
        </>
    )
}


export default Feedback;