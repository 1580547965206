import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../../Component/sidemenu";
import Topheader from "../../Component/TopHeader";

const HandoffLog = () => {
 
    

const [data, setData] = useState([
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"},
    {botID : "BOTID1672749536988", Date:"2023-01-04", Time:"11:27:52", handoftype:"mail", domain:"https://js.do/", od:"Mac OS", browser:"Chrome", ipAdd:"118.185.108.245"}
])
 
 
    return (
    <>
      {/* <SideMenu /> */}
      <div className="pl-[76px] lg:pl-[276px] p-5">
        <Topheader HandoffLog="Handoff Log" />
        <div className="previewSelectTemp">
          <h4 className="flex items-center py-4 text-[#363636] font-semibold ">
            <div className="goalsTryCol sm:w-52 w-full">
              <select className="border w-full px-3 py-1 text-sm rounded" value="25">
                <option>All</option>
              </select>
            </div>
          </h4>
          <div className="tableOfHandof">
            <table className="table-fixed border-collapse w-full text-sm text-left shadow-md">
              <thead className="bg-[#F2F5FF] text-xs">
                <tr>
                  <th className="px-2 py-4 rounded-tl-xl text-[#4E4E4E]">BOT ID</th>
                  <th className="px-2 py-4 text-[#4E4E4E]">Date</th>
                  <th className="px-2 py-4 text-[#4E4E4E]">Time</th>
                  <th className="px-2 py-4 text-[#4E4E4E]">Handoff Type</th>
                  <th className="px-2 py-4 text-[#4E4E4E]">Domain</th>
                  <th className="px-2 py-4 text-[#4E4E4E]">OS</th>
                  <th className="px-2 py-4 text-[#4E4E4E]">Browser</th>
                  <th className="px-2 py-4 rounded-tr-xl text-[#4E4E4E]">IP Address</th>
                </tr>
              </thead>
              <tbody>
                    {data.map((row)=>{
                        return(
                            <tr className="bg-white even:bg-[#F8F8F8] hover:bg-white border hover:border  hover:drop-shadow-lg">
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.botID}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.Date}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.Time}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.handoftype}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.domain}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.od}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.browser}</td>
                            <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">{row.ipAdd}</td>
                            </tr>
                        )
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default HandoffLog;
