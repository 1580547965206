import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../../Component/sidemenu";
import AppIntegration from "./AppIntegration";
import botList from "../../../src/data/en.json";
import { getInstalledIntegrations } from "../../features/integraion/integrationSlice";
import { useSelector, useDispatch } from "react-redux";
import IntegrationPopup from "../../Component/integration/IntegrationPopup";
const Integration = () => {
  const [data, setData] = useState([]);
  const [handoffTypes, setHandoffTypes] = useState([]);
  const [integrationData, setIntegrationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [appData, setAppData] = useState({}); //[
  const [mode, setMode] = useState("install"); //
  // const [installedIntegration, setInstalledIntegration] = useState([]);
  const { installedIntegrations } = useSelector((state) => state.integration);

  const dispatch = useDispatch();

  useEffect(() => {
    setHandoffTypes(Object.keys(botList.integrations));
    setIntegrationData(botList.integrations);
    console.log("installed Integrations", installedIntegrations);
    // dispatch(getInstalledIntegrations())
  }, []);

  const openPopup = (data,mode="install"
    ) => {
    
    console.log(data, "data on click");
    setShowPopup(true);
    setAppData(data);
    setMode(mode);
      
  };
  const closePopup = () => {
    setShowPopup(false);
    setAppData({});
  };

  return (
    <>
      {/* <SideMenu /> */}
      {/* POPUP */}
     

      {showPopup && (
        <>
          <IntegrationPopup appData={appData} closePopup  = { closePopup} isInstalled={installedIntegrations.includes(appData.id)} mode={mode}/>
        </>
      )}

      <div className="boatEditSection bg-white">
        <div className="boatSettings ">
          <fieldset>
            <input
              id="draft"
              className="peer/draft mr-3"
              type="radio"
              name="status"
              defaultChecked
            />
            <label
              htmlFor="draft"
              className="peer-checked/draft:text-sky-500 mr-5 cursor-pointer"
            >
              Connected Integration
            </label>
            <input
              id="published"
              className="peer/published mr-3"
              type="radio"
              name="status"
            />
            <label
              htmlFor="published"
              className="peer-checked/published:text-sky-500 cursor-pointer"
            >
              Task Log
            </label>
            <div className="float-right mb-3">
              <label className="relative block">
                <span className="sr-only">Search</span>
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.701"
                    height="19.701"
                    viewBox="0 0 19.701 19.701"
                  >
                    <g
                      id="Icon_feather-search"
                      data-name="Icon feather-search"
                      transform="translate(-3.75 -3.75)"
                    >
                      <path
                        id="Path_174173"
                        data-name="Path 174173"
                        d="M20.4,12.451A7.951,7.951,0,1,1,12.451,4.5,7.951,7.951,0,0,1,20.4,12.451Z"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#b4b4b4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                      <path
                        id="Path_174174"
                        data-name="Path 174174"
                        d="M29.3,29.3l-4.324-4.324"
                        transform="translate(-6.908 -6.908)"
                        fill="none"
                        stroke="#b4b4b4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </span>
                <input
                  className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                  placeholder="Search..."
                  type="text"
                  name="search"
                />
              </label>
            </div>

            <div className="hidden peer-checked/draft:block py-5">
              {handoffTypes.map((catName) => {
                return (
                  <div className="connectionIntegrationRow mb-5">
                    <h4 className="text-[#5C5C5C]  text-base  font-medium mb-2">
                      {catName}
                    </h4>

                    {integrationData[catName].map((subCatData) => {
                      console.log(installedIntegrations, "installedIntegration",subCatData.id)
                      return (
                        <AppIntegration
                          openPopup={openPopup}
                          closePopup={closePopup}
                          subCatData={subCatData}
                          img={subCatData.img}
                          desc={subCatData.desc}
                          title={subCatData.title}
                          id={subCatData.id}
                          allowInstall={subCatData.allowInstall}
                          isMultipleAllowed={subCatData.isMultipleAllowed}
                          isInstalled={
                            subCatData.allowInstall
                              ? installedIntegrations &&
                              installedIntegrations.includes(subCatData.id)
                              : true
                          }
                          isConnectApp={subCatData.isConnectApp}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>

            {/*  */}
            <div className="hidden peer-checked/published:block py-5">
              <div className="taskLogTable ">
                <table className="table-fixed border-collapse w-full text-sm text-left shadow-md">
                  <thead className="bg-[#F2F5FF] text-xs">
                    <tr>
                      <th className="px-2 py-4 rounded-tl-xl text-[#4E4E4E]">
                        BOT ID
                      </th>
                      <th className="px-2 py-4 text-[#4E4E4E]">Date</th>
                      <th className="px-2 py-4 text-[#4E4E4E]">Time</th>
                      <th className="px-2 py-4 text-[#4E4E4E]">Handoff Type</th>
                      <th className="px-2 py-4 text-[#4E4E4E]">Domain</th>
                      <th className="px-2 py-4 text-[#4E4E4E]">OS</th>
                      <th className="px-2 py-4 text-[#4E4E4E]">Browser</th>
                      <th className="px-2 py-4 rounded-tr-xl text-[#4E4E4E]">
                        IP Address
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row) => {
                      return (
                        <tr className="bg-white even:bg-[#F8F8F8] hover:bg-white border hover:border  hover:drop-shadow-lg">
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.botID}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.Date}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.Time}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.handoftype}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.domain}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.od}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.browser}
                          </td>
                          <td className="px-2 py-4 text-xs truncate text-[#5A5A5A]">
                            {row.ipAdd}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default Integration;
