
import { db } from "../../config/firebase";
import { setUserBots } from "./userBotsSlice";

//getting data from firebase
export const getDashboardDataAsync = async (_,thunkAPI) => {
return new Promise((resolve, reject) => {
    db
  .collection("user_" + localStorage.getItem("tablealias"))
  .doc(localStorage.getItem("agent_id"))
  .collection("chatbot")
  .doc("dashboard")
  .onSnapshot((doc) => {
    // console.log(doc.data() , ' maggiaa')
    thunkAPI.dispatch(setUserBots(doc.data()))
    resolve()
    // reject(doc)

  }).catch((err) => {
    console.log(err)
    reject(err)
  })
}) 

  
}


export const toggleBotStatusAsync = async(data , thunkAPI) => {
  return new Promise((resolve , reject) => {
    var dashboard_details = {};
    dashboard_details[`${data.botId}.status`] = data.newStatus;
      db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc("dashboard")
      .update(dashboard_details);
      resolve({botId : data.botId , newStatus : data.newStatus})
  })
}