import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { updateinstalledIntegrations } from '../../features/integraion/integrationSlice'
import IntegrationPopup from '../integration/IntegrationPopup'
// import strings from './../../../src/data/en.json'
import strings from './../../../src/data/en.json'
import { updateTemplate , updateSelectedNode } from '../../features/botTemplate/botTemplateSlice'
import { setAllowSave } from '../../features/globalSave/globalSaveSlice'

function SetupHandoff(props) {


    //initializing dispatch
    const dispatch = useDispatch()

    //destructuring props
    const { selectedNode, template } = props

    //getting flow from the template
    const flow = template.template_One

    //getting the id of the node to be edited
    const nodeId = Number(selectedNode.id)

    const handoffTypeArray = Object.keys(strings.integrations)
    const integrationData = strings.integrations
    const [handoffSubTypeArray, sethandoffSubTypeArray] = useState([])
    const [handoffType, sethandoffType] = useState("")
    const [handoffSubType, sethandoffSubType] = useState("")
    const [handoffId, sethandoffId] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [handoffArray, setHandoffArray] = useState([])
    const installedIntegrations = useSelector((state) => state.integration.installedIntegrations);
    const [appData, setAppData] = useState({})
    const [mode, setMode] = useState("install")
    const [isPopup, setIsPopup] = useState(false)


    const closePopup = () => {
        // setMode("install")
        setIsPopup(false)
        setAppData({})

    }

    const handleOpen = () => {
        setMode("install")
        setIsPopup(true)
    }

    const editorFunction = (handoffIdNew = handoffId, handoffSubTypeNew = handoffSubType, handoffTypeNew = handoffType) => {
        var handoffArr = handoffArray
        var dataH = { key: handoffIdNew, value: { type: handoffTypeNew, title: handoffSubTypeNew, installed: true, type_id: handoffIdNew } }
        handoffArr[handoffArr.length - 1] = dataH
        console.log(handoffArr, "handoffArr")
        setHandoffArray(handoffArr)
    }


    const handleHandleTypeChange = (value) => {
        const handoffSubTypeArray = integrationData[value]
        sethandoffType(value)
        sethandoffSubType("")
        sethandoffSubTypeArray(handoffSubTypeArray)
    }

    const handleSubTypeChange = (value) => {

        setAppData(JSON.parse(value))
        sethandoffSubType(JSON.parse(value).title)
        sethandoffId(JSON.parse(value).id)
        if (JSON.parse(value).id.includes("appy_") || installedIntegrations.includes(JSON.parse(value).id)) {
            editorFunction(JSON.parse(value).id, JSON.parse(value).title, handoffType)
        }

    }
    const addNewHandoff = () => {
        var condition = handoffArray.filter(item => item.value.installed == false)
        if (condition.length == 0) {
            var data = { key: "", value: { type: "", title: "", installed: false } }
            var newHandoffArray = [...handoffArray, data]
            setHandoffArray(newHandoffArray)
        } else {
            alert("Please install the previous handoff")
        }
    }


    useEffect(() => {
        let handoffObj = props.selectedNode.data.data.handoffs
        let handoffArray = Object.entries(handoffObj).map(([key, value]) => ({ key, value }));
        console.log(handoffArray, "handoffArray")
        handoffArray = handoffArray.filter(item => item.key.includes("appy_") || installedIntegrations.includes(item.key))
        setHandoffArray(handoffArray)
        console.log(handoffArray, "handoffArray2")
        setIsLoading(false)

    }, [])


    const handleDelete = (data) => {
        var handoffArr = handoffArray
        console.log(handoffArr, "handoffArr before " , data)
        handoffArr = handoffArr.filter(item => item.key != data)
        console.log(handoffArr, "handoffArr after ")
        setHandoffArray(handoffArr)
    }


    //handler function
    //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
    const handleSave = () => {

        //creating new edited data

        const obj = {};

        // Iterate through the array and build the object
        for (const entry of handoffArray) {
            obj[entry.key] = entry.value;
          }
        const modifiedData = {
            ...selectedNode.data,
            data: {
                ...selectedNode.data.data,
                handoffs : obj


            }
        }

        //creating new flow
        const newFlow = {
                ...flow,
                [nodeId]: modifiedData
            }


        //creating new template
        const newTemplate = {
                ...template,
                template_One: newFlow
            }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
            //dispatching action
            dispatch(updateSelectedNode(null))
    }


    //handler function
    //@DEF - remove the editor by dispatching action
    const handleCancel = () => {
        dispatch(updateSelectedNode(null))
    }


    return (
        <>
            {isLoading ? <div>Loading...</div> :
                <>

                    <div>

                        {isPopup && <IntegrationPopup appData={appData} closePopup={closePopup} isInstalled={installedIntegrations.includes(appData.id)} mode={mode} isFromEditor={true} editorFunction={editorFunction} />}

                        <div>
                            <button onClick={() => { addNewHandoff() }}>Add new</button>
                        </div>


                        {handoffArray.map((handoffData) => {

                            return (
                                handoffData.key.includes("appy_") || installedIntegrations.includes(handoffData.type_id ? handoffData.value.type_id : handoffData.key) ?
                                    <>
                                        <span>{handoffData.value.type}</span>
                                        <span>{handoffData.value.title}</span>



                                        <span>Installed</span>
                                        <br />
                                        {
                                            handoffData.key.includes("appy_") ? null :
                                                <>
                                                    <button onClick={() => handleOpen()}>Edit</button>
                                                    <br />
                                                    <button onClick={() =>  handleDelete(handoffData.key)}>Delete</button>
                                                    <br />
                                                </>
                                        }
                                    </>
                                    :
                                    <>
                                        <select name="handoff type" id="handofftype" onChange={(event) => handleHandleTypeChange(event.target.value)}>
                                            <option>Select Handoff Type</option>
                                            {handoffTypeArray.map((item) => {
                                                return <option value={item}>{item}</option>
                                            })}
                                        </select>
                                        <br />


                                        <select name="handoff to" id="handoffto" onChange={(event) => handleSubTypeChange(event.target.value)} disabled = {!handoffType}>
                                            <option>Select Handoff Sub Type</option>
                                            {handoffSubTypeArray.map((item) => {
                                                return <option value={JSON.stringify(item)}>{item.title}</option>
                                            })}
                                        </select>
                                        <br />
                                        {
                                            handoffType && handoffSubType && <button onClick={() => { handleOpen() }}>Install</button>
                                        }
                                        <br />

                                    </>
                            )
                        }
                        )}

                    </div>
                    <div className='editorBtn px-3 py-3'>
                        <button onClick={handleSave} className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button onClick={handleCancel} className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
                    </div>
                </>
            }
        </>
    )
}

export default SetupHandoff