import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";


const settingOption = (props) => {
  // const params = useParams();
  

  // useEffect(() => {
  //   console.log(params);
  
  // })

  const activeCss = "inline-block cursor-pointer rounded-t-lg hover:bg-white bg-white"
  const inactiveCss = "inline-block cursor-pointer rounded-t-lg hover:bg-white"

  const handleAccountsPage = () => {

  }

  return (
    <div className="bg-sideOption text-[#fff] w-52 fixed top-0 left 30 h-screen p-4 text-sm pt-20">
      <ul>
        <li className="flex items-center p-2 bg-[#8799de] rounded cursor-pointer">
          <span>Account settings</span>
          <img className="pl-2 h-2" src={""} />
        </li>
        <li className="mt-4">
            <span className="flex items-center p-2 bg-[#8799de] rounded cursor-pointer">
            <span>Chatbot settings</span>
            <img className="pl-2 h-2" src={""} />
            </span>
          <ul className="p-2">
            <li className={props.page=="gpprconsent"?activeCss:inactiveCss}><span onClick={()=>{props.handleClick('gdprconsent')}}> User consent</span></li>
            <li className={props.page=="handofflog"?activeCss:inactiveCss}><span onClick={()=>{props.handleClick('handofflog')}}> Handoff Log </span></li>
            <li className={props.page=="answerlog"?activeCss:inactiveCss}><span onClick={()=>{props.handleClick('answerlog')}}> Answer Log </span></li>
            <li className={props.page=="pendinginquiries"?activeCss:inactiveCss}><span onClick={()=>{props.handleClick('pendinginquiries')}}> Pending Inquiries </span></li>

            {/* <li className="" onClick={()=>{handleAccountsPage()}}>Subscription</li> */}

           
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default settingOption;
