import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Line from './Line'
import Telegram from './Telegram'
import Whatsapp from './Whatsapp'
import Viber from './Viber'
import Facebook from './Facebook'

function SocialApps(props) {

  const activeCss = "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] bg-[#F2F5FF] cursor-pointer";
  const inactiveCss  = "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer";

    const [apps , setApps] = useState([])

    const [selectedApp ,setSelectedApp] = useState('')

    console.log(props.apps , ' kdjhcfg')

    

    const [isLoading , setIsLoading] = useState(true)

    const [breadCrumpTitle,setBreadCrumpTitle]=useState('')


    useEffect(() => {
        console.log(props,"PRPS")

        var allowedApps=[]
        props.apps.apps.map((deploymentType) => {

            // console.log(deploymentType.botlist , ' setupdata deploymentType' , parseInt(localStorage.getItem("botType")))
            
  
            if(deploymentType.botlist&&deploymentType.botlist.includes(parseInt(localStorage.getItem("botType")))){
                allowedApps.push(deploymentType)
            }
          })
       
          setApps(allowedApps)
          console.log(apps , 'apps in use')
          setSelectedApp(allowedApps[0])
          setIsLoading(false)
        
    },[])
  return (
    <>
        {isLoading?"Loading...":
                
             
                  <div className="peer-checked/published:block border-t-[1px] border-[#BFBFBF] mt-5 w-full">
                <div className="deployBoatInWebsiteRow flex">
                  <div className="deployeWebsiteLink border-r-[1px] border-[#BFBFBF] w-3/12  22">

                  {console.log(apps,"RENDERING THIS")}
                  {/* {apps.map(item=>{
                    console.log('first ' , item )
                    return <p className='SPapplist'>{item.title}</p>
                  })} */}
                  {apps.map((item) => {

                    return(
                      <div onClick={() => {setSelectedApp(item.id); setBreadCrumpTitle(item.title) }} className={selectedApp ===  item.id ? activeCss : inactiveCss}>
                    <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                      {/* <img className="w-full h-full" src={require("../../../img/customeWebsite.png")} alt = 'hello' /> */}
                      <img className="w-full h-full" src={item.img} />
                    </span>
                    <span className="deplWebName text-sm">{item.title}</span>
                    <span className={selectedApp !==  item.id ? "deplWebArrow ml-auto invisible group-hover/depWeb:visible" : "deplWebArrow ml-auto visible group-hover/depWeb:visible"}>
                      <img src={require(`../../../img/rightArrow.png`)} />
                    </span>
                  </div> )
                  })}
                   
                  </div>
                  <div className='deployeWebsiteDetails w-9/12'>
                    <div className="breadcrumb px-5 pt-5">
                      <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF] rounded-l-md">Self-service</span>
                      <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF]">
                        <img className="inline-block w-1.5 h-2.5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAARCAYAAADkIz3lAAAABHNCSVQICAgIfAhkiAAAAKRJREFUKFON0s0NhCAQBWAmFGALW8ImcHe3AreDLWFjJ5ZgCZZgCW4nNAD4xkTizwjOicOXx+QBGWMGpVTtvX9PGJzFIUBHRFWM0eUwPTFa67GEie+5gxd4BydYwjuYwyd4hUXImPtFEw2fQwitCIF6oC8j9PtHv68TlBAezO3gFeLkBHMowRJaIFCHxX/bxXmn4xcia20soTXxgwoe6KqXktbkGTEduXg1045uAAAAAElFTkSuQmCC"/>
                        </span>
                        <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF]">Deploy bot on social media apps</span>
                        <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF] rounded-b">
                          <img className="inline-block w-1.5 h-2.5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAARCAYAAADkIz3lAAAABHNCSVQICAgIfAhkiAAAAKRJREFUKFON0s0NhCAQBWAmFGALW8ImcHe3AreDLWFjJ5ZgCZZgCW4nNAD4xkTizwjOicOXx+QBGWMGpVTtvX9PGJzFIUBHRFWM0eUwPTFa67GEie+5gxd4BydYwjuYwyd4hUXImPtFEw2fQwitCIF6oC8j9PtHv68TlBAezO3gFeLkBHMowRJaIFCHxX/bxXmn4xcia20soTXxgwoe6KqXktbkGTEduXg1045uAAAAAElFTkSuQmCC"/>
                          </span>
                          <span className="inline-block relative text-xs px-3 py-3 ">{breadCrumpTitle}</span>
                    </div>
                  <div className='customeWebsite customeWebsiteWidget px-5 py-5 aa'>
                    
                    {selectedApp === 'telegram' && <Telegram/>}
                    {selectedApp === "whatsapp" && <Whatsapp/>}
                    {selectedApp === "line" && <Line/>}
                    {selectedApp === "viber" && <Viber/>}
                    {selectedApp === "facebook_messenger" && <Facebook/>}
                  </div>
                  </div>
               
                  
                </div>
              </div>
}



                  </>
                
  )
}

export default SocialApps