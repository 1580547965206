import React , {useEffect , useState} from 'react'
import { API_URL } from '../../config/params';
import { useSelector , useDispatch
} from "react-redux";
import { updateinstalledIntegrations } from "../../features/integraion/integrationSlice";
import strings from "./../../data/en.json"
import {db} from '../../config/firebase';
import axios from 'axios';
function VerifyIntegration(props) {

    const [msg, setMsg] = useState("")
    const installedIntegrations = useSelector(state => state.integration.installedIntegrations)
    const dispatch = useDispatch();


    useEffect(() => {


        const token = new URLSearchParams(window.location.search).get('t');
        if(!token){

        }else{
            // const token = new URLSearchParams(this.props.location.search).get('t');
		console.log("token", token);
		axios({
			method: 'GET',
            url: `${API_URL}/connect_integration_new/getauth?t=${token}&companyId=${localStorage.getItem("tablealias")}&botId=${localStorage.getItem("botId")}`,

			// url: `${"http://localhost:5066/chatbot-production-d6ea3/us-central1"}/connect_integration_new/getauth?t=${token}&companyId=${localStorage.getItem("tablealias")}&botId=${localStorage.getItem("botId")}`,
			headers: {
				Authorization: "Basic Y2hhdGJvYXRAYXBweXBpZS5jb206ZmRoamtvaTQ3ZGdoanJ0dW9kZ251NzY="
			}
		}).then(async res => {
			console.log(res, "response");
			if (res.status == 200) {
				var appId=res.data.appId
				var appName=res.data.appName
				// var installed_int=await getInstalledData(appName.replace(' ','_').toLowerCase())
				// console.log(installed_int,"installed_int")
				if (!installedIntegrations.includes(localStorage.getItem("connectApp"))){
				// var allint=await getAllIntegration()
				var reqData=strings["integrations"][localStorage.getItem("connectType")].find((obj) => {
                    return obj.id === localStorage.getItem("connectApp")
                   })
                
                // .find([localStorage.getItem("connectApp")])
				var templateId=reqData.templateId
				console.log(document.cookie,"authDone",document.cookie.includes('common_eml'),document.cookie.includes('APPYID'),appName)
				fetch(
					`${API_URL}/connect_integration_new/add-connect-new`,
					{
						method: "POST",
						body: JSON.stringify({ templateId, userId: localStorage.getItem("tablealias"), appId, botId:localStorage.getItem("botId"),botName:localStorage.getItem("BOTNAME"), cookie: document.cookie }),
						headers: {
							"Content-Type": "application/json"
						},
					}
				).then((res) => res.json()
				).then(async response => {
					console.log("addConnectres",response,response.status)
					if (response.status == 200) { 
						console.log("addConnectDone",response.connectId)
						var connectId=response.connectId
						var actionNode=response.actionNode
						var triggerNode=response.triggerNode


					// fetch(
					// 	`${baseUrl}/turnon-connect`,
					// 	{
					// 		method: "POST",
					// 		body: JSON.stringify({ pc_id: localStorage.getItem("tablealias"),botId:localStorage.getItem("botId"), connectId: response.connectId, cookie: document.cookie }),
					// 		headers: {
					// 			"Content-Type": "application/json"
					// 		},
					// 	}
					// ).then((res) => res.json()
					// ).then(async turnResp => {
					// 	console.log("TurnonConnectDone",connectId,actionNode,triggerNode)
					// 	// ADDTODB
						reqData['connectId']=connectId
						reqData['actionNode']=actionNode
						reqData['triggerNode']=triggerNode
						reqData['added_on']=new Date().getTime()
						reqData['updated_on']=new Date().getTime()
						reqData['turnonConnect']=false
						if (!connectId||!actionNode||!triggerNode){
							setMsg("Connection could not be made. Make sure you are using paid subsccription of the app you want to connect. Close the window and try again in a few minutes.")
							// stopLoader("appjs")
						}else{
						// this.props.propsInstalled([appName.replace(' ','').replace('_','').toLowerCase(),...this.props.installedProps])
						// console.log("this.props.installedPropsAFTER",this.props.installedProps)
                        dispatch(updateinstalledIntegrations([localStorage.getItem('connectApp'),...installedIntegrations]))
                        window.opener.postMessage(reqData);

						await db
							.collection('Connect_List')
							.doc(localStorage.getItem('tablealias'))
							.collection('chatbot')
							.doc(localStorage.getItem("botId"))
							.collection("all_integrations")
							.doc(localStorage.getItem("connectApp"))
							.set(reqData)
						.then(()=>{
							console.log("allDoneCOnnect",reqData)
							// localStorage.setItem('connect_updated_on',new Date().getTime())

							window.close()
							// this.setState({text:"Close the window and return to Chatbot Editor Page."})
                            setMsg("Close the window and return to Chatbot Editor Page.")
							// stopLoader("appjs")
						}).catch((err)=>{console.log(err,'dbsavefailed')})
					}
						
					// })
				}
				}).catch((err)=>{console.log(err,'addconnectfailed')})
			}else{
				// var reqData={}
				// reqData['updated_on']=new Date().getTime()
				// localStorage.setItem('connect_updated_on',new Date().getTime())
				window.close()
				// this.setState({text:`Close the window and return to Chatbot Editor Page.`})
                setMsg("Close the window and return to Chatbot Editor Page.")
				// stopLoader("appjs")
				// await db
				// 	.collection('Connect_List')
				// 	.doc(localStorage.getItem('tablealias'))
				// 	.collection('chatbot')
				// 	.doc(localStorage.getItem("botId"))
				// 	.collection("all_integrations")
				// 	.doc(appName.replace(' ','_').toLowerCase())
				// 	.update(reqData).then(()=>{
				// 		console.log("allDoneCOnnect",reqData)
				// 		window.close()
				// 	}).catch((err)=>{console.log(err,'dbsavefailed')})
			}
			}
		}).catch((err)=>{console.log(err.message,'getauthfailed')})
        }
    },[])
  return (
    <div>{msg}</div>
  )
}

export default VerifyIntegration