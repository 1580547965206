import {React , useState  } from 'react'
import enData from './../../data/en.json'
import {useDispatch} from 'react-redux'
import { changeBotName , changeBotType } from '../../features/createBot/createBotSlice'

function GetBotName(props) {



    const dispatch = useDispatch()


    const activeCss = {backgroundColor: 'green'}
    const inactiveCss = {backgroundColor: 'red'}

    //handler function
    const handleChange = (value) => {
        props.setName(value)
        props.setErrorName('')
        
    }
  return (
    <div>
        <p>Enter your bot name</p>
       <input type="text" value={props.name} onChange = {(event) => {handleChange(event.target.value)}} />
       {props.errorName && <div>{props.errorName}</div>}
    </div>
  )
}

export default GetBotName