import React from 'react'
import { useEffect,useState } from 'react'
import { useSelector , useDispatch } from 'react-redux'
import { updateinstalledIntegrations } from '../../features/integraion/integrationSlice'
import { getEditEmailData,editEmail } from './../../../src/utilities/chatbotMethods'
import strings from "./../../../src/data/en.json"

function EmailIntegration(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { installedIntegrations } = useSelector(state => state.integration)
    // {emailType,email,logo,subject,para,signature,visCheck,visSubject,visPara,visSalutation}

    const [emailTypes, setEmailTypes] = useState([]);
    const [emailData, setEmailData] = useState([]);
    const [hideFields, setHideFields] = useState(true);
    
    const [emailType, setEmailType] = useState("default");
    const [email, setEmail] = useState("");
    const [logo, setLogo] = useState("");
    const [subject, setSubject] = useState("");
    const [para, setPara] = useState("");
    const [signature, setSignature] = useState("");
    const [visCheck, setVisCheck] = useState("");
    const [visSubject, setVisSubject] = useState("");
    const [visPara, setVisPara] = useState("");
    const [visSalutation, setVisSalutation] = useState("");
    const [isEmailLoading, setIsEmailLoading] = useState(false);


    
    




    const dispatch = useDispatch();

    useEffect(() => {

        setIsEmailLoading(true);

       
        if(Object.keys(strings.handoffTypes).includes(localStorage.getItem("botType"))){
            setEmailTypes(strings.handoffTypes[localStorage.getItem("botType")])
        }else{
            setEmailTypes(strings.handoffTypes["default"])
        }
        getEditEmailData().then(doc=>{
            setEmailData(doc)
            setIsLoading(false);
        })
        

    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsButtonLoading(true);

        var data ={}
        data[emailType]={emailType,email,logo,subject,para,signature,visCheck,visSubject,visPara,visSalutation}

   
        editEmail(data).then(status => {
            if (status) {
                dispatch(updateinstalledIntegrations(['smtp', ...installedIntegrations]))
                setIsButtonLoading(false);
                props.closePopup()
            }
        })
    };

    const handleEmailTypeChange = (value) => {
        setEmailType(value);
        setIsEmailLoading(true);

        if (value === "select") {
            setHideFields(true);

        }else{
            
            var newEmailData=emailData[value]
            setEmail(newEmailData.email)
            setLogo(newEmailData.logo)
            setSubject(newEmailData.subject)
            setPara(newEmailData.para)
            setSignature(newEmailData.signature)
            setVisCheck(newEmailData.visCheck)
            setVisSubject(newEmailData.visSubject)
            setVisPara(newEmailData.visPara)
            setVisSalutation(newEmailData.visSalutation)
            setHideFields(false);
            setIsEmailLoading(false);

        }


    }


    
    return (
        <>
            {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}

            {isLoading ? (<p>Loading...</p>)  :

                    (<div >
                        <div >
                            <div >
                                <img src={props.appData.img} />
                            </div>
                            <div>
                                <div >
                                    <h4>Appy Pie Email</h4>
                                </div>
                                <div >
                                    <p >
                                        Edit your emails
                                    </p>
                                </div>
                                <div>
                                    <span >Learn more:</span>
                                </div>
                                <div />
                                <div />
                            </div>
                        </div>
                        <div>
                            <div>
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <div>
                                        <div>
                                            <label htmlFor="">Select Email Type</label>

                                            <select name="" id="" value={emailType} onChange={(event) => handleEmailTypeChange(event.target.value)}>
                                               
                                               {emailTypes.map((item,index) => {
                                                return (<option value={item}>{item} </option>)
                                               })}
                                            </select>
                                           
                                            <br />
                                            

                                            
                                            {hideFields ?null:isEmailLoading ? <span>...</span> : (
                                                <>
                                                <label htmlFor="">Email</label>
                                                <input type="text" value={email} onChange={(event) => setEmail(event.target.value)}  placeHolder='Enter emails seperated by commas(,)' />
                                                <br />
                                                <label htmlFor="">Logo</label>
                                                <input type="text" value={logo} onChange={(event) => setLogo(event.target.value)}  placeHolder='Enter the logo' />
                                                <br />
                                                <label htmlFor="">Subject</label>
                                                <input type="text" value={subject} onChange={(event) => setSubject(event.target.value)}  placeHolder='Enter the subject' />
                                                <br />
                                                <label htmlFor="">Paragraph</label>
                                                <input type="text" value={para} onChange={(event) => setPara(event.target.value)}  placeHolder='Enter the paragraph' />
                                                <br />
                                                <label htmlFor="">Signature</label>
                                                <input type="text" value={signature} onChange={(event) => setSignature(event.target.value)}  placeHolder='Enter the signature' />
                                                <br />
                                                <label htmlFor="">Visibility Check</label>
                                                <input type="radio" value={visCheck} onChange={(event) => setVisCheck(event.target.checked)}  placeHolder='Enter the visibility check' />
                                                <br />
                                                <label htmlFor="">Visibility Subject</label>
                                                <input type="text" value={visSubject} onChange={(event) => setVisSubject(event.target.value)}  placeHolder='Enter the visibility subject' />
                                                <br />
                                                <label htmlFor="">Visibility Paragraph</label>
                                                <input type="text" value={visPara} onChange={(event) => setVisPara(event.target.value)}  placeHolder='Enter the visibility paragraph' />
                                                <br />
                                                <label htmlFor="">Visibility Salutation</label>
                                                <input type="text" value={visSalutation} onChange={(event) => setVisSalutation(event.target.value)}  placeHolder='Enter the visibility salutation' />
                                                <br />

                                                </>

                                            )}
                                            




                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <br />
                                            {isButtonLoading ? <span>...</span> : <input type="submit" value='Connect' />}



                                            <br />
                                            <span onClick={() => { props.closePopup() }}>Cancel</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>)}
        </>

    );
}

export default EmailIntegration