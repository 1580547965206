import React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateinstalledIntegrations } from '../../features/integraion/integrationSlice'
import { installSmtp, uninstallSmtp , removeGlobal , getAllAccounts } from './../../../src/utilities/chatbotMethods'

function SMTPIntegration(props) {
    const [isLoading, setIsLoading] = useState(true);
    const { installedIntegrations } = useSelector(state => state.integration)
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [senderEmail, setSenderEmail] = useState("");
    const [senderName, setSenderName] = useState("");
    const [mode, setMode] = useState(props.mode);
    const [installedAccounts, setInstalledAccounts] = useState([]);




    const dispatch = useDispatch();

    useEffect(() => {
        if(props.mode=="uninstall"){
            setIsLoading(false);
        }else{
        getAllAccounts("smtp").then((data) => {
          if (data.length > 0) {
            setInstalledAccounts(data);
            setMode("switch")
            setIsLoading(false);
          }else{
            setIsLoading(false);
          }
        });
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        var data = {
            "Email/UserName *": username,
            "Host *": host,
            "Password *": password,
            "Port *": port,
            "Sender Email *": senderEmail,
            "Sender Name *": senderName
        }
        setSMTP(data,true)





    };


    const handleUninstall = () => {
        setIsLoading(true);
        uninstallSmtp().then(status => {
            if (status) {
                dispatch(updateinstalledIntegrations(installedIntegrations.filter(item => item !== 'smtp')))
                setIsLoading(false);
                props.closePopup()

            }
        })
    }


    const setSMTP = (data,addGlobal) => {
        installSmtp(data, addGlobal).then((data) => {
            dispatch(
              updateinstalledIntegrations([
                "smtp",
                ...installedIntegrations,
              ])
            );
            setIsLoading(false);
            props.closePopup();
          });
      }
    return (
        <>
            {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}

            {isLoading ? (<p>Loading...</p>) :
                mode === 'uninstall' ? (<>
                    <span> Are you sure you want to uninstall {props.appData.title} ?</span>
                    <br />
                    {isLoading ? <span>...</span> : <button onClick={() => { handleUninstall() }}> Yes,uninstall</button>}
                    <br />
                    <button onClick={() => { props.closePopup() }}>No</button>
                </>) :
                mode == "switch" ? (
                    <>
                      <div className="mt-5">
                        <h4>
                          <b>Choose to continue</b>
                        </h4>
                        <ul className="useracc_list">
                        {installedAccounts.map((item,index)=> {
                            return(
                            <li>
                                <div onClick={()=>{setSMTP(item,false)}}>
            
                            <span className="us_icon">{index+1}</span>
                            <br/>
                            <span className="us_details">
                              <span className="us_name">{item["Email/UserName *"]}</span>
                            </span>
                            </div>
                            <span onClick={()=>{removeGlobal("smtp",index)}} className="sign_out">Sign out</span>
                          </li>
                          )
                        }
                            )}
                            <li>
                                <div onClick={()=>{setMode("install")}}>
                            <span className="us_icon">
                              <img src="/dist/img/public/img/user.svg" alt="user Img" />
                            </span>
                            <span className="us_details">
                              <span className="us_name">Use another account</span>
                            </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) :

                    (<div >
                        <div >
                            <div >
                                <img src={props.appData.img} />
                            </div>
                            <div>
                                <div >
                                    <h4>SMTP</h4>
                                </div>
                                <div >
                                    <p >
                                        Manage your emails via your SMTP
                                    </p>
                                </div>
                                <div>
                                    <span >Learn more:</span>
                                </div>
                                <div />
                                <div />
                            </div>
                        </div>
                        <div>
                            <div>
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <div>
                                        <div>

                                            <label htmlFor="">Host</label>
                                            <input type="text" value={host} onChange={(event) => setHost(event.target.value)} required placeHolder='Enter the host' />
                                            <br />
                                            <label htmlFor="">Port</label>
                                            <input type="text" value={port} onChange={(event) => setPort(event.target.value)} required placeHolder='Enter the port' />
                                            <br />
                                            <label htmlFor="">Username</label>
                                            <input type="text" value={username} onChange={(event) => setUsername(event.target.value)} required placeHolder='Enter the username' />
                                            <br />
                                            <label htmlFor="">Password</label>
                                            <input type="text" value={password} onChange={(event) => setPassword(event.target.value)} required placeHolder='Enter the password' />
                                            <br />
                                            <label htmlFor="">Sender Email</label>
                                            <input type="text" value={senderEmail} onChange={(event) => setSenderEmail(event.target.value)} required placeHolder='Enter the sender email' />
                                            <br />
                                            <label htmlFor="">Sender Name</label>
                                            <input type="text" value={senderName} onChange={(event) => setSenderName(event.target.value)} required placeHolder='Enter the sender name' />
                                            <br />




                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <br />
                                            {isLoading ? <span>...</span> : <input type="submit" value='Connect' />}



                                            <br />
                                            <span onClick={() => { props.closePopup() }}>Cancel</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>)}
        </>

    );
}

export default SMTPIntegration