const dev = {

    "realmapiid": "accounts-dev-ctclr",
    "realmapikey": "Ve9dvUzJyMvbT1rkjmTRalt2No5wQhge5J7y0ZQWD6K7dHn9lqFkCgDE9y49X7if",
    "apiUrl" : "https://us-central1-appycx-beta.cloudfunctions.net",
    "commonloginUrl" : "https://commonlogin.pbodev.info",
    "commonloginApiUrl" : "https://commonlogin.pbodev.info/backend",
    "commonBillingUrl":"https://checkout-dev.pbodev.info"
     };

const prod = {
  
   "realmapiid": "accounts-live-sdpwm",
    "realmapikey": "A1bPAGRUcGsVgupH09R3FLIblKXww60vCKGKnpJVWdCLmdMSzs12ElWCheXWG5XU",
    "apiUrl" : "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net",
    "commonloginUrl" : 'https://accounts.appypie.com',
    "commonloginApiUrl" : "https://backendaccounts.apppypie.com",
    "commonBillingUrl":"https://checkout.appypie.com"
  
};


var realmapiid;
var realmapikey;
var apiUrl;
var commonloginUrl;
var commonBillingUrl;



console.log(process.env.REACT_APP_STAGE,"process.env.REACT_APP_STAGE")
if (process.env.REACT_APP_STAGE!=="prod") {
    
    realmapiid = dev.realmapiid;
    realmapikey = dev.realmapikey;
    apiUrl = dev.apiUrl;
    commonloginUrl = dev.commonloginUrl;
    commonBillingUrl = dev.commonBillingUrl;
}else {
    
    realmapiid = prod.realmapiid;
    realmapikey = prod.realmapikey;
    apiUrl = prod.apiUrl; 
    commonloginUrl = prod.commonloginUrl;  
    commonBillingUrl = prod.commonBillingUrl;
}


export const REALM_API_ID = realmapiid;
export const REALM_API_KEY = realmapikey;
export const API_URL = apiUrl;
export const COMMON_LOGIN_URL = commonloginUrl;
export const COMMON_BILLING_URL = commonBillingUrl;


