var appointment = {
  chatBotAvtars: {
    chatBotAvtar: [
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar2.svg?alt=media&token=8b7982ab-0060-472b-ad51-6792e3fba162',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar1.svg?alt=media&token=fe41f944-828d-47af-bfcc-d14d14af0956'
    ]
  },
  template_One: {
    0: {
      UI: 'Welcome Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: "Hi! I am Snappy. Seems like you're looking for an appointment."
      },
      input: '',
      next: 1,
      parentNode : -1,
      trigger: []
    },
    1: {
      UI: 'Get Name',
      Validation: {
        doValidate: true,
        loop: 0,
        successful: 2,
        type: 'Name'
      },
      action: '',
      continue: false,
      data: {
        responseText: 'Great! Thanks for the name.',
        text: 'Can I please get your name?'
      },
      input: '',
      next: null,
      parentNode : 0,
      trigger: []
    },
    2: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Great! Thanks for the name.'
      },
      input: '',
      next: 3,
      parentNode : 1,
      trigger: []
    },
    3: {
      UI: 'Get Email',
      Validation: {
        doValidate: true,
        loop: 0,
        successful: 5,
        type: 'Email',
        unsuccessful: 4,
        text: 'Please tell me your email id.'
      },
      action: '',
      continue: false,
      data: {
        successful: 'Thanks for sharing your E-mail Id.',
        text: 'Please tell me your email id.',
        unsuccessful: {
          failedAttemptsFinal:
            'Oops! Looks like you are entering an invalid email address. We usually accept business email ids only like abc@appypiellp.com',
          failedAttemptsText:
            'Oops! Looks like you have entered an invalid email address. Please give me a valid email address.',
          no_of_email: '',
          nonBusinessCheck: '',
          nonBusinessEmails: [],
          spamMailCheck: '',
          spamMailText: []
        }
      },
      input: '',
      next: null,
      parentNode : 2,
      trigger: []
    },
    4: {
      UI: 'Get Email',
      Validation: {
        doValidate: true,
        loop: 0,
        successful: 5,
        type: 'Email',
        unsuccessful: 4,
        text: 'Please tell me your email id.'
      },
      action: '',
      continue: false,
      data: {
        text:
          'Oops! Looks like you have entered an invalid email address. Please give me a valid email address.'
      },
      input: '',
      next: null,
      trigger: []
    },
    5: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Thanks for sharing your E-mail Id.'
      },
      input: '',
      next: 6,
      parentNode : 3,
      trigger: []
    },
    6: {
      UI: 'Get Phone',
      Validation: {
        doValidate: true,
        loop: 0,
        successful: 8,
        type: 'Phone',
        unsuccessful: 7,
        text: 'Can I please get your phone number?'
      },
      action: '',
      continue: false,
      data: {
        successful: {
          botResponseCheck: '',
          botResponseText: 'Great! Thanks for your phone number.',
          countrycode: ''
        },
        text: 'Can I please get your phone number?',
        unsuccessful: {
          botResponseUnCheck: '',
          botResponseUnText:
            'Oops! Looks like you entered an invalid contact number. Please give me a valid one.',
          botResponseUsTextFinal: 'Thanks for the number.',
          no_of_repeatQuestion: ''
        }
      },
      input: '',
      next: null,
      parentNode : 5,
      trigger: []
    },
    7: {
      UI: 'Get Phone',
      Validation: {
        doValidate: true,
        loop: 0,
        successful: 8,
        type: 'Phone',
        unsuccessful: 7,
        text: 'Can I please get your phone number?'
      },
      action: '',
      continue: false,
      data: {
        text:
          'Oops! Looks like you entered an invalid contact number. Please give me a valid one.'
      },
      input: '',
      next: null,
      trigger: []
    },
    8: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Great! Thanks for your phone number.'
      },
      input: '',
      next: 9,
      parentNode : 6,
      trigger: []
    },
    9: {
      UI: 'Condition',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        text: 'Upcoming Appointment'
      },
      input: 'Button',
      next: '',
      parentNode : 8,
      trigger: [
        {
          id: '9.1',
          name: 'Present',
          next: 10
        },
        {
          id: '9.2',
          name: 'Not Present',
          next: 33
        }
      ]
    },
    10: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text:
          "We noticed that you've an appointment on {{appointment data and time}}"
      },
      input: '',
      next: 11,
      parentNode : 9,
      trigger: []
    },
    11: {
      UI: 'Button',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        isBranching: true,
        text: 'What do you wish to do?'
      },
      input: 'Button',
      next: '',
      parentNode : 10,
      trigger: [
        // {
        // 	id: 11.1,
        // 	name: "Book another appointment",
        // 	next: 24,
        // },
        {
          id: 11.1,
          name: 'Reschedule appointment',
          next: 12
        },
        {
          id: 11.2,
          name: 'Cancel appointment',
          next: 17
        }
      ]
    },
    12: {
      UI: 'Date_&_Time',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        available: {
          proceedClick: true,
          responseText:
            'Appointment scheduled successfully! An email with relevant details has been sent to you.'
        },
        meetingLink: localStorage.getItem('agent_id'),
        text: 'Please select date and time on which you wish to reschedule.',
        unavailable: {
          textUn:
            "We're sorry! No slots are available at the moment. In case a slot opens up, we'll send you an email immediately."
        }
      },
      input: 'Date_&_Time',
      next: 13,
      parentNode : 11,
      trigger: []
    },
    13: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text:
          'Your appointment has been successfully rescheduled to {{date and time}}.'
      },
      input: '',
      next: 14,
      parentNode : 12,
      trigger: []
    },
    14: {
      UI: 'Handoffs',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        handoffs: {
          appy_email: {
            id: 0,
            type: 'Email',
            title: 'Appy Email',
            data: {
              email: ''
            }
          },
          appy_appointment: {
            id: 1,
            type: 'appointment',
            title: 'Appy Appointment',
            data: {
              email: ''
            }
          }
        },
        text: 'Great! Thanks for the informations.'
      },
      input: '',
      next: 15,
      parentNode : 13,
      trigger: []
    },
    15: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 16,
      parentNode : 14,
      trigger: []
    },
    16: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      parentNode : 15,
      trigger: []
    },
    17: {
      UI: 'Button',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        isBranching: true,
        text: 'Are you sure that you wish to cancel the appointment'
      },
      input: 'Button',
      next: '',
      parentNode : 11,
      trigger: [
        {
          id: 17.1,
          name: 'Yes',
          next: 18
        },
        {
          id: 17.2,
          name: 'No',
          next: 21
        }
      ]
    },
    18: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Your appointment has been cancelled.'
      },
      input: '',
      next: 100,
      parentNode : 17,
      trigger: []
    },


    100: {//
      UI: "Handoffs",
      HandOffType:"Cancel Appointment",
      Validation: {
          doValidate: false,
          type: "",
      },
      action: "SaveInquiry",
      continue: true,
      data: {
          handoffs: {
              appy_email: {
                  id: 0,
                  type: "Email",
                  title: "Appy Email",
                  data: {
                      email: "",
                  },
              },
          },
          text: "Great! Thanks for the informations.",
      },
      input: "",
      next: 19,
      parentNode : 18,
      trigger: [],
  },

    19: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 20,
      parentNode : 100,
      trigger: []
    },
    20: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      parentNode : 19,
      trigger: []
    },
    21: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Your appointment is still intact with us.'
      },
      input: '',
      next: 22,
      parentNode : 17,
      trigger: []
    },
    22: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 23,
      parentNode : 21,
      trigger: []
    },
    23: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      parentNode : 22,
      trigger: []
    },
    24: {
      UI: 'Button',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        isBranching: true,
        text: 'Would you like to book an appointment with us?'
      },
      input: 'Button',
      next: '',
      trigger: [
        {
          id: 24.1,
          name: 'Yes',
          next: 25
        },
        {
          id: 24.2,
          name: 'No',
          next: 30
        }
      ]
    },
    25: {
      UI: 'Date_&_Time',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        available: {
          proceedClick: true,
          responseText:
            'Appointment scheduled successfully! An email with relevant details has been sent to you.'
        },
        meetingLink: localStorage.getItem('agent_id'),
        text: 'Please select date and time on which you wish to reschedule.',
        unavailable: {
          textUn:
            "We're sorry! No slots are available at the moment. In case a slot opens up, we'll send you an email immediately."
        }
      },
      input: 'Date_&_Time',
      next: 26,
      trigger: []
    },
    26: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text:
          'Your appointment has been successfully booked on {{date and time}}.'
      },
      input: '',
      next: 27,
      trigger: []
    },
    27: {
      UI: 'Handoffs',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        handoffs: {
          appy_email: {
            id: 0,
            type: 'Email',
            title: 'Appy Email',
            data: {
              email: ''
            }
          },
          appy_appointment: {
            id: 1,
            type: 'appointment',
            title: 'Appy Appointment',
            data: {
              email: ''
            }
          }
        },
        text: 'Great! Thanks for the informations.'
      },
      input: '',
      next: 28,
      trigger: []
    },
    28: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 29,
      trigger: []
    },
    29: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      trigger: []
    },
    30: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Thank you for precious time.'
      },
      input: '',
      next: 31,
      trigger: []
    },
    31: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 32,
      trigger: []
    },
    32: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      trigger: []
    },
    33: {
      UI: 'Button',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        isBranching: true,
        text: 'Would you like to book an appointment with us?'
      },
      input: 'Button',
      next: '',
      parentNode : 9,
      trigger: [
        {
          id: 33.1,
          name: 'Yes',
          next: 34
        },
        {
          id: 33.2,
          name: 'No',
          // next: 39
          next: 39
        }
      ]
    },
    34: {
      UI: 'Date_&_Time',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: false,
      data: {
        available: {
          proceedClick: true,
          responseText:
            'Appointment scheduled successfully! An email with relevant details has been sent to you.'
        },
        meetingLink: localStorage.getItem('agent_id'),
        text: 'Please select date and time on which you wish to schedule.',
        unavailable: {
          textUn:
            "We're sorry! No slots are available at the moment. In case a slot opens up, we'll send you an email immediately."
        }
      },
      input: 'Date_&_Time',
      next: 35,
      parentNode : 33,
      trigger: []
    },
    35: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text:
          'Your appointment has been successfully booked on {{date and time}}.'
      },
      input: '',
      next: 36,
      parentNode : 34,
      trigger: []
    },
    36: {
      UI: 'Handoffs',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        handoffs: {
          appy_email: {
            id: 0,
            type: 'Email',
            title: 'Appy Email',
            data: {
              email: ''
            }
          },
          appy_appointment: {
            id: 1,
            type: 'appointment',
            title: 'Appy Appointment',
            data: {
              email: ''
            }
          }
        },
        text: 'Great! Thanks for the informations.'
      },
      input: '',
      next: 37,
      parentNode : 35,
      trigger: []
    },
    37: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 38,
      parentNode : 36,
      trigger: []
    },
    38: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      parentNode : 37,
      trigger: []
    },
    39: {
      UI: 'Bot Message',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: '',
      continue: true,
      data: {
        text: 'Thank you for precious time.'
      },
      input: '',
      next: 40,
      parentNode : 33,
      trigger: []
    },
    40: {
      UI: 'Feedback',
      Validation: {
        doValidate: false,
        loop: 0,
        type: ''
      },
      action: 'feedbaack',
      continue: false,
      data: {
        feedback_type: {
          presentation: {
            label: '1 to 5 scale',
            value: '1-5'
          },
          type: {
            label: 'Scale',
            value: 'rating-scale'
          }
        },
        isEnable: true,
        responseText: 'Thank you for the feedback.',
        text: 'Please provide your feedback.'
      },
      input: '',
      next: 41,
      parentNode : 39,
      trigger: []
    },
    41: {
      UI: 'End Message',
      Validation: {
        doValidate: false,
        type: ''
      },
      action: 'SaveInquiry',
      continue: false,
      data: {
        text: 'Great! Thanks for the information.'
      },
      input: '',
      next: null,
      parentNode : 40,
      trigger: []
    },
    '00': {
      Validation: {
        doValidate: false,
        type: ''
      },
      action: '',
      continue: false,
      input: '',
      message: 'null',
      next: 0,
      trigger: []
    },
    customisation: {
      backgroundChatBot: '#FFFFFF',
      botButtonColor: '#2c3049ff',
      botButtonText: 'lora',
      botButtonTextColor: '#FFFFFF',
      botTextcolor: '#000',
      botType: 'Appointment',
      chatBotAvtar:
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2',
      clientBubbleClr: '#2c3049ff',
      clientchattextcolor: '#ffffff',
      designLanguage: 'english(en)',
      designPage: 'default',
      headerClr: '#2c3049ff',
      headerName: 'Appointment bot',
      headertextcolor: '#ffffff',
      messagebubble: '#2c30494d',
      sendButtonClr: '#2c3049ff',
      textfonts: 'roboto',
      widgetBackgroundColor: '#2c3049ff',
      widgetImage:
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&token=6a39c949-c97b-4811-9414-c21c2e249985',
      widgetPosition: {
        bottom: '8',
        position: 'right',
        side: '8'
      },
      widgetSize: 'large'
    },
    time: 1562221203801
  },
  widgetImages: {
    widgetImage: [
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&token=6a39c949-c97b-4811-9414-c21c2e249985',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar2.svg?alt=media&token=eda6ee3f-a82e-405d-91cc-ae80ae5910d0',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar1.svg?alt=media&token=23fb8ec9-5129-4c87-b060-cc0fe84e9377',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget2.svg?alt=media&token=210ce6f4-f9ef-475c-a757-d08cd1d84467',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget1.svg?alt=media&token=782bf939-6627-4f33-ae1a-a9f6e9cf8c1f',
      'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget3.svg?alt=media&token=49981112-ba7b-48e6-90f0-4cd8486b97a2'
    ]
  }
}

export default appointment






