import {
  REALM_API_ID,
  REALM_API_KEY,
  API_URL,
  COMMON_LOGIN_URL,
} from "./../config/params";
import { db, fb } from "./../config/firebase";
import * as Realm from "realm-web";
// import amplitude from 'amplitude-js';
var md5 = require("md5");
//to gain access to firebase db
var addListPC=[]
var delListPC=[]
export const firebaseAuth = async (client_token, _id, alias) => {
  var TOKEN_URL = API_URL + "/getAuthToken?client_token=";

  return new Promise(async (resolve, reject) => {
    let res = await fetch(
      TOKEN_URL + client_token + "&_id=" + _id + "&alias=" + alias
    );
    const token = await res.text();
    localStorage.setItem("fbAuthToken", token);
    console.log(token, "stagingToken");
    fb.auth()
      .signInWithCustomToken(token)
      .then((userCredential) => {
        resolve(true);
      })
      .catch((error) => {
        reject(true);
      });
  });
};

//getting cookie
export const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      var valueData = c.substring(name.length, c.length);
      return valueData;
    }
  }
  return "";
};


let activeLoaderIds = []

export const startLoader = (startId) => {
    /// console.log(activeLoaderIds,"loader_act")
    let find = false;
    activeLoaderIds.forEach(id => {
        if (id == startId) {
            find = true;
        }
    });
    if (!find) {
        activeLoaderIds.push(startId);
        document.getElementById("global@loader").style.display = "block";
    }


}
export const editLoader = (startId) => {
   
 
        document.getElementById("message").innerText = startId;
    


}
export const stopLoader = (stopId) => {
    // console.log(activeLoaderIds,"loader_act",stopId)
    activeLoaderIds.forEach((l, i) => {
        if (l == stopId) {
            // console.log("remove_loader_act",stopId)

            activeLoaderIds.splice(i, 1);
            console.log(activeLoaderIds,"loader_act")
            if (activeLoaderIds.length == 0) {
                document.getElementById("global@loader").style.display = "none";
            }
        }
        if(stopId=="*"){
            activeLoaderIds=[]
            
                document.getElementById("global@loader").style.display = "none";
            
        }
    });
}

export const getProper=(answer)=>{
   // console.log(answer,"beforeproper")
   answer = answer.replaceAll("çp>", "<p>").replaceAll("ç/p>", "</p>");

   answer = answer.replaceAll("çspan", "<span").replaceAll("ç/span>", "</span>");
   answer = answer
     .replaceAll("ça target", " <a target")
     .replaceAll("ç/a>", "</a>");
     answer = answer
     .replaceAll("çol>", "<ol>")
     .replaceAll("ç/ol>", "</ol>");
     answer = answer
     .replaceAll("çspan", " <span")
     .replaceAll("ç/span>", "</span>");
   answer = answer
     .replaceAll("ça href", " <a href")
     .replaceAll("ç/a>", "</a>");
   answer = answer
     .replaceAll("çstrong>", "<strong>")
     .replaceAll("ç/strong>", "</strong>");
   answer = answer.replaceAll("çins>", "<ins>").replaceAll("ç/ins>", "</ins>");
   answer = answer.replaceAll("çem>", "<em>").replaceAll("ç/em>", "</em>");
   answer = answer.replaceAll("çli>", "<li>").replaceAll("ç/li>", "</li>");
   answer = answer.replaceAll("çul>", "<ul>").replaceAll("ç/ul>", "</ul>");
   answer = answer.replaceAll("çbr>", "<br>").replaceAll("ç/br>", "</br>");
   // console.log(answer,"afterproper")

   return answer;
}

//getting realm session
export const getRealmSession = async (loginCookie) => {
  const credentials = Realm.Credentials.serverApiKey(REALM_API_KEY);
  const app = new Realm.App({ id: REALM_API_ID });
  let user = app.currentUser;
  try {
    const md5 = require("md5");
    if (!user) {
      user = await app.logIn(credentials);
    }
    //   console.log(getCookie('connect.sid'),"COOKIESSSSSSS",getCookie("APPYID"))
    const fileName = loginCookie;
    console.log(fileName, "connectsid");
    const realmData = await user.functions.getSessionData(
      md5(fileName),
      "owner"
    );
    console.log(realmData, "getrealmsession api responsr");
    let userData =
      realmData && realmData.userdata ? JSON.parse(realmData.userdata) : "";
    userData = userData && userData.userdata ? userData.userdata : "";
    console.log(JSON.stringify(userData), "getrealmsession", userData.email);
    localStorage.setItem("commonlogin_id", userData._id);
    return userData;
  } catch (error) {
    console.error("Error", error);
    return null;
  }
};

//checking if email exists in firebase db
export const isEmailExists = async (email, phone, loginAlias) => {
  console.log("CHECKISEMAILEXISTING", email, phone, loginAlias);

  var res = false;

  if (loginAlias) {
    // debugger
    await db
      .collection("main_user")
      .where("tablealias", "==", loginAlias)
      .limit(1)
      .get()
      .then((doc) => {
        // debugger
        if (doc.docs.length > 0) {
          localStorage.setItem("tablealias", doc.docs[0].data().tablealias);
          localStorage.setItem("email", email ? email : "");
          localStorage.setItem("phone", phone ? phone : "");
          localStorage.setItem("agent_id", doc.docs[0].data().agent_id);
          localStorage.setItem("sessionStartTime", new Date().getTime());
          // if (doc.data().date){
          //   localStorage.setItem('account_created_at',new Date(doc.data().date.seconds*1000).getTime())
          // }else if(doc.data().agent_id.includes('AGENT')){
          //     localStorage.setItem('account_created_at',doc.data().agent_id.replace('AGENT',""))
          // }

          // var dataLayer = dataLayer || [];
          // dataLayer.push({
          //   "account": {
          //       "id": doc.data().tablealias,
          //       "email": email
          //   }})
          // amplitude.getInstance().init("6d7414941689ba68c9737031ba642609", email,null,function(instance) {console.log("amplitude init");},function() {console.log("amplitude init failed"); })
          // document.cookie = `amplitudeSet=true;  path=/;`

          res = { email: true, deactivated: false, id: doc.docs[0].id };
        } else {
          res = false;
        }
      });

    return res;
  } else {
    await db
      .collection("main_user")
      .doc(email ? email.toLowerCase() : phone)
      .get()
      .then((doc) => {
        console.log(doc.exists, "ISEMAILEXISTING");
        if (doc.exists) {
          // localStorage.setItem('tablealias', doc.data().tablealias)
          // localStorage.setItem('email', email?email:"")
          // localStorage.setItem('phone', phone?phone:"")
          // localStorage.setItem('agent_id', doc.data().agent_id)
          // if (doc.data().date){
          //   localStorage.setItem('account_created_at',new Date(doc.data().date.seconds*1000).getTime())
          // }else if(doc.data().agent_id.includes('AGENT')){
          //     localStorage.setItem('account_created_at',doc.data().agent_id.replace('AGENT',""))
          // }

          // var dataLayer = dataLayer || [];
          // dataLayer.push({
          //   "account": {
          //       "id": doc.data().tablealias,
          //       "email": email
          //   }})
          // amplitude.getInstance().init("6d7414941689ba68c9737031ba642609", email,null,function(instance) {console.log("amplitude init");},function() {console.log("amplitude init failed"); })
          // document.cookie = `amplitudeSet=true;  path=/;`

          res = {
            email: true,
            deactivated: false,
            id: email ? email.toLowerCase() : phone,
          };
        } else {
          res = false;
        }
      });

    return res;
  }
};

//redirect to common login page
export const redirectToCommonLogin = async () => {
  let hostDomain = window.location.hostname;
  let htp = "https";

  if (window.location.href.search("Train_your_Recruitment_bot") > 0) {
    window.location.href =
      COMMON_LOGIN_URL +
      "/login?frompage=" +
      htp +
      "://" +
      hostDomain +
      "/login/Train_your_Recruitment_bot%2F&website=" +
      htp +
      "://" +
      hostDomain +
      "/login";
  } else if (window.location.href.search("Train_your_Medical_bot") > 0) {
    window.location.href =
      COMMON_LOGIN_URL +
      "/login?frompage=" +
      htp +
      "://" +
      hostDomain +
      "/login/Train_your_Medical_bot%2F&website=" +
      htp +
      "://" +
      hostDomain +
      "/login";
  } else if (
    window.location.href.search("Train_your_Banking_loan_application_bot") > 0
  ) {
    window.location.href =
      COMMON_LOGIN_URL +
      "/login?frompage=" +
      htp +
      "://" +
      hostDomain +
      "/login/Train_your_Banking_loan_application_bot%2F&website=" +
      htp +
      "://" +
      hostDomain +
      "/login";
  } else if (window.location.href.search("Train_your_Customer_care_bot") > 0) {
    window.location.href =
      COMMON_LOGIN_URL +
      "/login?frompage=" +
      htp +
      "://" +
      hostDomain +
      "/login/Train_your_Customer_care_bot%2F&website=" +
      htp +
      "://" +
      hostDomain +
      "/login";
  } else if (window.location.href.search("train_your_bot") > 0) {
    window.location.href =
      COMMON_LOGIN_URL +
      "/login?frompage=" +
      htp +
      "://" +
      hostDomain +
      "/login/train_your_bot%2F&website=" +
      htp +
      "://" +
      hostDomain +
      "/login";
  } else {
    if (sessionStorage.getItem("aiLink")) {
      window.location.href =
        COMMON_LOGIN_URL +
        "/register?frompage=" +
        htp +
        "://" +
        hostDomain +
        "/login%2F&website=" +
        htp +
        "://" +
        hostDomain +
        "/login";
    } else {
      window.location.href =
        COMMON_LOGIN_URL +
        "/login?frompage=" +
        htp +
        "://" +
        hostDomain +
        "/login%2F&website=" +
        htp +
        "://" +
        hostDomain +
        "/login";
    }
  }
};

//register user if does not exist
export const handleLogin = async (userData, navigate) => {
  var userdata = userData;
  var email = userdata.email ? userdata.email : "";
  var phone = userdata.user_mobile ? userdata.user_mobile : "";
  var mobile_number = userdata.mobile_number ? userdata.mobile_number : "";
  var loginAlias = userdata.table_alias ? userdata.table_alias : "";
  var id = userdata._id ? userdata._id : "";
  var access = userdata.access_token;
  var company_name = userdata.company;
  var tablealias = userdata.table_alias;
  var owner_id = userdata.owner_id;

  console.log(userdata, "udata", navigate);
  localStorage.setItem("accessToken", access);
  localStorage.setItem("cognito_id", id);
  localStorage.setItem("isLogin", "yes");
  localStorage.setItem("email", email ? email : "");
  localStorage.setItem("phone", phone ? phone : "");
  localStorage.setItem("mobile_number", mobile_number);
  localStorage.setItem("company_name", company_name);

  localStorage.setItem("tablealias", tablealias);
  localStorage.setItem("agent_id", owner_id);

  await firebaseAuth(access, id, "freee");

  console.timeLog("LOGINSTART", "BEFOREOURCHECK");
  console.log(email, phone, "udataloginparam");
  // STAGING
  var res = await isEmailExists(email, phone, loginAlias);
  // debugger
  // var res = false;
  let apps_access = userdata.apps_access ? userdata.apps_access : [];
  let isAccess = false;
  apps_access.forEach((app) => {
    if (app.product == "chatbot") {
      isAccess = true;
    }
  });
  if (!isAccess) {
    registerUserInCommonLogin(userdata._id);
  }
  console.log(res, "ISEMAILEXISTS");
  //  saveThinkific();
  if (res) {
    res = await getOrCreateUserCollection(
      email,
      phone,
      mobile_number,
      id,
      access,
      userdata
    );

    console.log(
      res,
      "RESFOROLDLOGIN",
      localStorage.getItem("BotName"),
      localStorage.getItem("Categoryid")
    );
    if (res == "existing_user") {
      localStorage.setItem("newBot", "no");
      //   console.log(localStorage.getItem('BotName'),localStorage.getItem('Categoryid'),"SISIdafuybwqeiufbqiubdfuisufibsauidfbasiubfdaiusbu")
      // return
      if (sessionStorage.getItem("isApiCoupon")) {
        navigate(
          `/subscribecoupon?botid=${localStorage.getItem(
            "botId"
          )}&couponCode=${sessionStorage.getItem("couponCode")}`
        );
        return;
      }

      if (sessionStorage.getItem("aiLink")) {
        navigate(sessionStorage.getItem("aiLink"));
      } else if (
        // (localStorage.getItem('BotName') != null ||
        //   localStorage.getItem('Categoryid') != null) &&
        // (localStorage.getItem('BotName') != undefined ||
        //   localStorage.getItem('Categoryid') != undefined) &&
        // (localStorage.getItem('BotName') != '' ||
        //   localStorage.getItem('Categoryid') != '')
        !(localStorage.getItem("BotName") && localStorage.getItem("Categoryid"))
      ) {
        // activityLog('Login', id, company_name)
        console.timeLog("LOGINSTART", "EXISYTONG1");

        navigate("/cx/Chatbot/");
      } else {
        // activityLog('Login', id, company_name)
        if (window.location.href.search("Train_your_Recruitment_bot") > 0) {
          navigate("/cx/Chatbot/Train_your_Recruitment_bot");
        } else if (window.location.href.search("Train_your_Medical_bot") > 0) {
          navigate("/cx/Chatbot/Train_your_Medical_bot");
        } else if (
          window.location.href.search(
            "Train_your_Banking_loan_application_bot"
          ) > 0
        ) {
          navigate("/cx/Chatbot/Train_your_Banking_loan_application_bot");
        } else if (
          window.location.href.search("Train_your_Customer_care_bot") > 0
        ) {
          navigate("/cx/Chatbot/Train_your_Customer_care_bot");
        } else if (window.location.href.search("train_your_bot") > 0) {
          navigate("/cx/Chatbot/train_your_bot");
        } else {
          console.timeLog("LOGINSTART", "EXISYTONG2");
          localStorage.setItem("newBot", "no");
          //navigate("/try_now")
          if (sessionStorage.getItem("isApiCoupon")) {
            navigate(
              `/subscribecoupon?botid=${localStorage.getItem(
                "botId"
              )}&couponCode=${sessionStorage.getItem("couponCode")}`
            );
            return;
          } else if (sessionStorage.getItem("aiLink")) {
            navigate(sessionStorage.getItem("aiLink"));
          } else {
            if (localStorage.getItem("Categoryid")) {
              navigate("/try_now");
            } else {
              navigate("/cx/Chatbot");
            }
            return;
          }
          //navigate('/cx/Chatbot/')
          return;
          //navigate('/cx')
        }
      }
    }
  } else {
    // localStorage.removeItem('_email')
    // localStorage.removeItem('OwnerName')

    // activityLog('Sign up', id, company_name);
    if (window.location.href.search("Train_your_Recruitment_bot") > 0) {
      navigate("/cx/Chatbot/Train_your_Recruitment_bot");
    } else if (window.location.href.search("Train_your_Medical_bot") > 0) {
      navigate("/cx/Chatbot/Train_your_Medical_bot");
    } else if (
      window.location.href.search("Train_your_Banking_loan_application_bot") > 0
    ) {
      navigate("/cx/Chatbot/Train_your_Banking_loan_application_bot");
    } else if (
      window.location.href.search("Train_your_Customer_care_bot") > 0
    ) {
      navigate("/cx/Chatbot/Train_your_Customer_care_bot");
    } else if (window.location.href.search("train_your_bot") > 0) {
      navigate("/cx/Chatbot/train_your_bot");
    } else {
      //navigate('/cx')
      console.timeLog("LOGINSTART", "EXISYTONG");

      getOrCreateUserCollection(
        email,
        phone,
        mobile_number,
        id,
        access,
        userdata
      );
      console.timeLog("LOGINSTART");
      if (sessionStorage.getItem("isApiCoupon")) {
        navigate(
          `/subscribecoupon?botid=${localStorage.getItem(
            "botId"
          )}&couponCode=${sessionStorage.getItem("couponCode")}`
        );
        return;
      } else {
        //navigate('/try_now')
        if (sessionStorage.getItem("aiLink")) {
          navigate(sessionStorage.getItem("aiLink"));
        } else if (localStorage.getItem("Categoryid")) {
          //navigate('/try_now')
          navigate("/try_now");
        } else {
          navigate("/cx/Chatbot");
        }
        return;
      }
    }
  }
};

export const registerUserInCommonLogin = async (_id) => {
  let alias = localStorage.getItem("tablealias");
  let agent_id = localStorage.getItem("agent_id");
  var body = [`grant_type=password&username=pedroetb&password=password`];
  var a = await new Promise((resolve, rej) => {
    fetch(COMMON_LOGIN_URL + "/oauth/token", {
      method: "post",
      body: body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic YXBwbGljYXRpb246c2VjcmV0`,
      },
    })
      .then((e) => e.json())
      .then(async (response) => {
        resolve(response.accessToken);
      })
      .catch((e) => {
        resolve("e26dda5b4c78903ed190f840e958484482411714");
      });
  });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  var urlencoded = new URLSearchParams();
  urlencoded.append("user_id", _id);
  urlencoded.append("table_alias", alias);
  urlencoded.append("owner_id", agent_id);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };
  try {
    let r = await fetch(
      COMMON_LOGIN_URL + "/api/common/savechatbot?access_token=" + a,
      requestOptions
    );
    r = await r.text();
    console.log(r, "testdheeraj123");
    return true;
  } catch (error) {
    return false;
  }
};

export const getOrCreateUserCollection = async (
  email,
  phone,
  mobile_number,
  password,
  token,
  userData
) => {
  console.log(
    email,
    phone,
    mobile_number,
    password,
    token,
    userData,
    "userDatauserDatauserDatauserDatauserData"
  );

  var response = "";

  let tableAlias = userData.table_alias;
  let agentId = userData.owner_id;
  let apps_access = userData.apps_access;
  let userId = userData._id;
  let accessAppp = [];
  apps_access.forEach((element) => {
    accessAppp.push(element.product);
  });

  var isEmail = await isEmailExists(email, phone);
  localStorage.setItem("temproryToken", token);
  console.log(isEmail, "isEmail");
  if (isEmail.email) {
    //existing user
    console.log(isEmail.deactivated, "isDeacted");

    response = "existing_user";
    localStorage.setItem("isLogin", "yes");
    //updateSession(token, email, password) //update session for existing user
    var categoryid = localStorage.getItem("Categoryid");
    if (categoryid == null || categoryid == "" || categoryid == undefined) {
    } else {
      const addedon = new Date().getTime();
      const bot_id = "BOTID" + addedon;
      localStorage.setItem("BotID", bot_id);
      localStorage.setItem("botId", bot_id);
    }
  } else {
    response = createNewUserInDb(
      userData,
      email,
      phone,
      mobile_number,
      password,
      token
    );
  }

  return response;
};

export const createNewUserInDb = async (
  userData,
  email,
  phone,
  mobile_number,
  password,
  token,
  tableAlias,
  agentId
) => {
  tableAlias = userData.table_alias;
  agentId = userData.owner_id;
  console.log(tableAlias, agentId);
  let respo = "";
  const id = tableAlias;

  const addedon = new Date().getTime();
  const agent_id = agentId;
  // const agent_id=userData.owner_id;

  const bot_id = "BOTID" + addedon;

  var botName = localStorage.getItem("BotName")
    ? localStorage.getItem("BotName")
    : "Inquiry";

  var _name = email ? email.substring(0, email.lastIndexOf("@")) : "";
  if (userData !== undefined) {
    if (userData.first_name !== "") {
      _name = userData.first_name + " " + userData.last_name;
    }
  }
  var _password = md5(password);

  var country = "";

  country = userData.country ? userData.country : "";

  var main_user_data = {
    email: email ? email : "",
    phone: phone ? phone : "",
    mobile_number: mobile_number ? mobile_number : "",
    agent_company: id,
    agent_parent: null,
    password: "_password",
    agent_id: agent_id,
    tablealias: id,
    isAdmin: true,
    date: new Date(),
    session: token,
    bigquery_implemented: true,
    country: country,
  };
  respo = await addNewUser(email, phone, main_user_data);

  localStorage.setItem("tablealias", id);
  localStorage.setItem("email", email ? email : "");
  localStorage.setItem("phone", phone ? phone : "");

  localStorage.setItem("agent_id", agent_id);
  localStorage.setItem("parent_id", agent_id);
  localStorage.setItem("isAppointmentTableExists", false);
  localStorage.setItem("isLogin", "no");
  localStorage.setItem("BotID", bot_id);
  localStorage.setItem("botId", bot_id);
  sessionStorage.setItem("newUser", true);

  // First Time Flow Set Cookie
  document.cookie = `firstFlow=true;  path=/;`;

  localStorage.removeItem("_email");

  var payment_data = {};

  // profile data
  var profile_data = {
    id: id,
    addedon: addedon,
    agent_id: agent_id,
    name: _name,
    alias: _name,
    agent_short: nameIntials(_name),
    profile_img: "",
    email: email,
    phone: phone,
    departments: ["Owner"],
    role: "Owner",
    role_id: "Owner",
    password: _password,
    timezone: "",
    parent_id: agent_id,
    logout: false,
  };

  // setting data
  var setting_data = {
    chatbot_setting: {
      idle_time: {
        A_end_for_both_VA: false,
        A_inactive: false,
        A_inactive_time: "10",
        A_show: false,
        A_show_text: "",
        A_status_change_aftertime: 0,
        A_time: 10,
        V_end_for_both_VA: false,
        V_remove: false,
        V_time: 10,
        agent_not_reply: false,
        visitor_not_reply: false,
        A_inactive_status: 0,
      },
      localization: {
        language: "English :en",
        timezone: "Pacific/Apia",
      },
      notification_sound: {
        incoming_chat_play: false,
        incoming_chat_show: false,
        new_msg_received_play: false,
        new_msg_received_show: false,
      },
      pervious_show: false,
    },
    livechat_setting: {
      GDPR: {
        ask_consent: 0,
        consent_button_text: "Yes,I agree",
        consent_content:
          "I agree for my personal data, provided via chat, to be processed by <your company name> for the purpose of providing support via chat. I agree for my personal data to be processed for the time <e.g. needed to carry out the service>. I understand the consent may be revoked by sending an email at <your business email/your data protection officers email>.",
        content_asked_by: "",
        privacy_policy_link: "",
        privacy_policy_link_text: "",
      },
    },
  };
  // integration data
  var integration_data = {
    all_integration: {
      appy_email: {
        description: "Manage your emails",
        inputs: ["Email id"],
        learnMore: "",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
        title: "Appy Email",
        type: "email",
      },
      appy_appointment: {
        description: "Manage your appointments",
        inputs: ["Email id"],
        learnMore: "",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
        title: "Appy Appointment",
        type: "appointments",
      },
      appy_agentTransfer: {
        description: "Manage all live agent transfers in Appy Pie Livechat",
        inputs: ["Email id"],
        learnMore: "",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
        title: "Appy Livechat",
        type: "agent transfer",
      },
      zendesk: {
        description: "Manage all live agent transfers in zendesk chat",
        inputs: ["Account key"],
        learnMore: "https://api.zopim.com/web-sdk/#initializing-the-sdk",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/Group%202339.svg?alt=media&token=2d50fd44-fe9d-4079-bdef-785fece6a667",
        title: "Zendesk Chat",
        type: "agent transfer",
      },
      smtp: {
        description: "Manage your emails via your SMTP",
        inputs: [
          "Host *",
          "Port *",
          "Email/UserName *",
          "Password *",
          "Sender Name",
          "Sender email",
        ],
        learnMore: "https://www.smtp.com/smtp-email-relay-solutions/",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/Asset-2-smtp-mon.svg?alt=media&token=b4c24d7c-58c5-4bab-a619-ba63be222f4f",
        title: "SMTP",
        type: "email",
      },
      zoom: {
        description: "Manage all live agent transfers in zoom",
        inputs: ["Client id ", "Client secret "],
        learnMore: "https://support.zoom.us/hc/en-us",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/zoom-1.svg?alt=media&token=c3611cdd-0014-44d6-833f-c84a5b05375a",
        title: "ZOOM",
        type: "agent transfer",
      },
      calendarhero: {
        description: "Manage all live agent transfers in Calander Hero",
        inputs: ["Client id ", "Client secret "],
        learnMore: "https://calendarhero.com/",
        logo: "https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/zoom-1.svg?alt=media&token=c3611cdd-0014-44d6-833f-c84a5b05375a",
        title: "Calendar Hero",
        type: "agent transfer",
      },
    },
    installed_integration: {
      appy_email: {
        botId: [],
        data: { email: email },
        title: "Appy Email",
        type: "email",
      },
      appy_appointment: {
        botId: [],
        data: { email: email },
        title: "Appy Appointment",
        type: "appointments",
      },
      appy_agentTransfer: {
        botId: [],
        data: { email: email },
        title: "Appy Livechat",
        type: "agent transfer",
      },
    },
  };

  // role data
  var role_data = {
    Owner: {
      access_module: [],
      description: "",
      name: "Owner",
      role: "Owner",
      status: 1,
      updatedon: addedon,
      permissions: {
        usermanagement: {
          agentAccess: true,
          rolesAccess: true,
          departmentAccess: true,
        },
        chatbot: {
          create: true,
          delete: true,
          status: true,
          edit: true,
        },
        livechat: {
          initiateChat: true,
          viewPastchat: true,
          answereIncoming: true,
          viewVisitorPath: true,
        },
        appointment: {
          appointment: true,
          addAgent: true,
          ownRooster: true,
          otherRooster: true,
        },
        others: {
          analytics: true,
          intigeration: true,
          settings: true,
          status_change: true,
          mange_preference: true,
          manage_profile: true,
        },
      },
    },
    Agent: {
      access_module: [],
      description: "",
      name: "Agent",
      role: "Agent",
      status: 1,
      updatedon: addedon,
      permissions: {
        usermanagement: {
          agentAccess: false,
          rolesAccess: false,
          departmentAccess: false,
        },
        chatbot: {
          create: false,
          delete: false,
          status: false,
          edit: false,
        },
        livechat: {
          initiateChat: true,
          viewPastchat: true,
          answereIncoming: true,
          viewVisitorPath: true,
        },
        appointment: {
          appointment: false,
          addAgent: false,
          ownRooster: false,
          otherRooster: false,
        },
        others: {
          analytics: true,
          intigeration: false,
          settings: false,
          status_change: true,
          mange_preference: true,
          manage_profile: true,
        },
      },
    },
  };

  // appointment data
  var appointment_data = {};

  var new_user_data = {
    profile: profile_data,
    payment: payment_data,
    integration: integration_data,
    settings: setting_data,
    role: role_data,
    appointment: appointment_data,
  };
  create_user_collection(id, agent_id, new_user_data);
  createBigQueryDB(id);
  return respo;
};

const addNewUser = async (email, phone, data) => {
  var res = false;
  await db
    .collection("main_user")
    .doc(email ? email.toLowerCase() : phone)
    .set(data)
    .then(() => {
      res = "new_user";
    })
    .catch((error) => {
      console.error("Error on adding main_user: ", error);
      res = false;
    });
  return res;
};

// create new user collection
const create_user_collection = (tablealias, agent_id, data) => {
  console.log(tablealias, agent_id, data);
  db.collection("user_" + tablealias)
    .doc(agent_id)
    .set(data);
  db.collection("user_" + tablealias)
    .doc(agent_id)
    .collection("chatbot")
    .doc("dashboard")
    .set({});
};

// create new user Big query DB
const createBigQueryDB = (tablealias) => {
  //API by SHUBHAM
  var body = [
    `tablealias=${tablealias}&tabletype=feedback_log&Bot_ID=BOTID&startdate="2021-01-01"&enddate="2021-01-01"&graphtype=2`,
  ];
  fetch(API_URL + "/fetch", {
    method: "post",
    body: body,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

//Making name in Two UpperCase letters
const nameIntials = (nameData) => {
  var arrayData = nameData.split(" ");
  switch (arrayData.length) {
    case 1:
      return nameData.substr(0, 2).toUpperCase();
      break;
    default:
      return (
        arrayData[0].charAt(0).toUpperCase() +
        arrayData[arrayData.length - 1].charAt(0).toUpperCase()
      );
  }
};

//creating new bot or overwrite old bot from the api
export const createBot = (botName, botType) => {
  return new Promise(async (resolve, reject) => {
    var body = {
      email: localStorage.getItem("email"),
      tablealias: localStorage.getItem("tablealias"),
      agentId: localStorage.getItem("agent_id"),
      botId: localStorage.getItem("botId"),
      botName,
      botType,
    };

    fetch(API_URL + "/makeUser/newBot", {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res,"STATUS",res.status)
      if(res.status==200){
        resolve(true);

      }else{
        resolve(false);
      }

     
    });
  });
};

export const getDeployedTelegramList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().telegramLinked != undefined) {
            resolve(data.data().telegramLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      })
      .catch((error) => {
        console.error("Error on getting telegram list: ", error);
        resolve([]);
      });
  });
};

export const getDeployedLineList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().lineLinked != undefined) {
            resolve(data.data().lineLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};
export const getDeployedWhatsappList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().whatsappLinked != undefined) {
            resolve(data.data().whatsappLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};
export const getDeployedViberList = (botId) => {
  return new Promise((resolve, reject) => {
    db.collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get()
      .then((data) => {
        if (data.exists) {
          if (data.data().viberLinked != undefined) {
            resolve(data.data().viberLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};

export const whatsappsetup = async (whatsappData, action) => {
  console.log(
    "whatsappsetup----->>><<<>>>-------------------------------------------------->>",
    whatsappData,
    action
  );
  return new Promise(async (resolve, reject) => {
    var botId = whatsappData.BOTID;
    var botAlias = whatsappData.whatsappAccessId + "";
    if (action == "delete") {
      db.collection("Whatsapp_Integrations")
        .doc(botAlias)
        .delete()
        .then(() => {
          console.log("delete");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      db.collection("Whatsapp_Integrations").doc(botAlias).set(whatsappData);
    }

    let userData = await db
      .collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get();
    console.log(userData, "--->>u--s--e--r--d--a--t--a---");
    if (userData.exists) {
      console.log("--->userDta exist-->>");
      let counter = 0;
      let botarray = [];
      let whatsappDatalist = userData.data().whatsappLinked
        ? userData.data().whatsappLinked
        : [];
      console.log(whatsappDatalist, "--->>>>whatsappDatalist-----");
      whatsappDatalist.forEach((bot) => {
        if (bot.docId == whatsappData.docId) {
          ++counter;

          if (action == "setup") {
            botarray.push(whatsappData);
          } else if (action == "disable") {
            whatsappData.isActive = false;
            botarray.push(whatsappData);
          } else if (action == "enable") {
            whatsappData.isActive = true;
            botarray.push(whatsappData);
          }
        } else {
          botarray.push(bot);
        }
      });
      if (counter == 0) {
        botarray.push(whatsappData);
      }
      console.log(
        botarray,
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
      );
      await db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(botId)
        .update({ whatsappLinked: botarray });
    } else {
      alert(1);
    }

    // else if (action == "enable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: true })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(botId)
    //     .update({ "viberLinked.isActive": true })

    // } else if (action == "disable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: false })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked.isActive": false })
    // }
    // else if (action == "delete") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).delete()
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked": {} })
    // }

    resolve(true);
  });
};

export const Linesetup = async (lineData, action) => {
  return new Promise(async (resolve, reject) => {
    if (action == "delete") {
      db.collection("Line_Integrations").doc(lineData.docId).delete();
    } else {
      db.collection("Line_Integrations").doc(lineData.docId).set(lineData);
    }
    let userData = await db
      .collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(lineData.BOTID)
      .get();
    if (userData.exists) {
      let counter = 0;
      let botarray = [];
      let lineDatalist = userData.data().lineLinked
        ? userData.data().lineLinked
        : [];
      lineDatalist.forEach((bot) => {
        if (bot.docId == lineData.docId) {
          ++counter;

          if (action == "setup") {
            botarray.push(lineData);
          } else if (action == "disable") {
            lineData.isActive = false;
            botarray.push(lineData);
          } else if (action == "enable") {
            lineData.isActive = true;
            botarray.push(lineData);
          }
        } else {
          botarray.push(bot);
        }
      });
      if (counter == 0) {
        botarray.push(lineData);
      }
      console.log(
        botarray,
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
      );
      await db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(lineData.BOTID)
        .update({ lineLinked: botarray });
    } else {
      alert(1);
    }

    // else if (action == "enable") {
    //   firebase.collection('Line_Integrations').doc(lineData.docId).update({ isActive: true })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(lineData.BOTID)
    //     .update({ "lineLinked.isActive": true })

    // } else if (action == "disable") {
    //   firebase.collection('Line_Integrations').doc(lineData.docId).update({ isActive: false })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(lineData.BOTID)
    //     .update({ "lineLinked.isActive": false })
    // }
    // else if (action == "delete") {
    //   firebase.collection('Line_Integrations').doc(lineData.docId).delete()
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(lineData.BOTID)
    //     .update({ "lineLinked": {} })
    // }

    resolve(true);
  });
};

export const vibersetup = async (viberData, action, botId) => {
  console.log(
    "vibresetup----->>><<<>>>-------------------------------------------------->>"
  );
  return new Promise(async (resolve, reject) => {
    if (action == "delete") {
      db.collection("Viber_Integrations").doc(botId).delete();
    } else {
      db.collection("Viber_Integrations").doc(botId).set(viberData);
    }
    let userData = await db
      .collection("user_" + localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(botId)
      .get();
    console.log(userData, "--->>u--s--e--r--d--a--t--a---");
    if (userData.exists) {
      console.log("--->userDta exist-->>");
      let counter = 0;
      let botarray = [];
      let viberDatalist = userData.data().viberLinked
        ? userData.data().viberLinked
        : [];
      console.log(viberDatalist, "--->>>>viberDatalist-----");
      viberDatalist.forEach((bot) => {
        if (bot.docId == viberData.docId) {
          ++counter;

          if (action == "setup") {
            botarray.push(viberData);
          } else if (action == "disable") {
            viberData.isActive = false;
            botarray.push(viberData);
          } else if (action == "enable") {
            viberData.isActive = true;
            botarray.push(viberData);
          }
        } else {
          botarray.push(bot);
        }
      });
      if (counter == 0) {
        botarray.push(viberData);
      }
      console.log(
        botarray,
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
      );
      await db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(botId)
        .update({ viberLinked: botarray });
    } else {
      alert(1);
    }

    // else if (action == "enable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: true })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(botId)
    //     .update({ "viberLinked.isActive": true })

    // } else if (action == "disable") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).update({ isActive: false })
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked.isActive": false })
    // }
    // else if (action == "delete") {
    //   firebase.collection('Line_Integrations').doc(viberData.docId).delete()
    //   firebase.collection('user_' + localStorage.getItem('tablealias')).doc(localStorage.getItem('agent_id')).collection('chatbot').doc(viberData.BOTID)
    //     .update({ "viberLinked": {} })
    // }

    resolve(true);
  });
};

export const getAllFacebookPage = () => {
  return new Promise(async (resolve, reject) => {
    await db
      .collection("Bot_Deployment")
      .doc("facebook")
      .collection("connected_pages")
      .doc(localStorage.getItem("tablealias"))
      .get()
      .then((doc) => {
        if (doc.data()) {
          let fbPagesLinked = doc.data().pages_linked;
          if (fbPagesLinked && fbPagesLinked.length > 0) {
            resolve(fbPagesLinked);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      });
  });
};

export const getAllConnectedFacebookPage = () => {
  return new Promise(async (resolve, reject) => {
    await db
      .collection(`user_${localStorage.getItem("tablealias")}`)
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc(window.location.href.split("?")[1].split("=")[1])
      .get()
      .then((doc) => {
        let fbPagesLinked = doc.data().facebookPagesLinked;
        resolve(fbPagesLinked ? fbPagesLinked : []);
      });
  });
};

export const setHandoffinDBConnect = (data, name, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    console.log("set_Handoff_in_DB");
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(name)
      .set(data)
      .then((doc) => {
        console.log("doc is set");



        if (addGlobal) {
          db.collection("Connect_List")
            .doc(localStorage.getItem("tablealias"))
            .collection("chatbot")
            .doc(name)
            .get()
            .then((doc) => {
              console.log(doc , 'doc is set')
              if (doc.exists) {
                var newData = {}
                var accArr=doc.data()["accounts"]?doc.data()["accounts"]:[];
                accArr.push(data);
                newData["accounts"]=accArr
                db.collection("Connect_List")
                  .doc(localStorage.getItem("tablealias"))
                  .collection("chatbot")
                  .doc(name)
                  .update(newData).then((doc) => {
                    console.log(doc , 'doc is set')
                  }).catch((err) => {
                    console.log(err , 'ljwdfjkew');
                  })
              } else {
                var newData = {}
                var accArr=[data];
                newData["accounts"]=accArr
                db.collection("Connect_List")
                  .doc(localStorage.getItem("tablealias"))
                  .collection("chatbot")
                  .doc(name)
                  .set(newData).then((doc) => {
                    console.log(doc , 'doc is set')
                  }
                  ).catch((err) => {
                    console.log(err , 'ljwdfjkewww');
                  })
              }
            });
        }

        // db.collection("Connect_List")
        //   .doc(localStorage.getItem("tablealias"))
        //   .collection("chatbot")
        //   .doc(name)
        //   .update(data);

        db.collection("integration_lists")
          .doc(name)
          .collection(name + "_data")
          .doc(localStorage.getItem("tablealias"))
          .collection("chatbot")
          .doc(localStorage.getItem("botId"))
          .set(data)
          .then((doc) => {
            resolve(true);
          });
      })
      .catch((err) => {
        console.log(err.message , 'ljwdfjkew');
      });
  });
};
// export const setHandoffinDBintegrationList = (data,name) => {
// db
//   .collection('integration_lists')
//   .doc(name)
//   .collection(name+'_data')-+

//   .doc(localStorage.getItem("tablealias"))
//   .collection("chatbot")
//   .doc(localStorage.getItem("botId"))
//   .set(data);

export const removeGlobal = (name, data) => {
  return Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(name)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var newArr=doc.data()["accounts"].filter((item) => item != data);
          var newData = {}


          newData["accounts"] = newArr
          db.collection("Connect_List")
            .doc(localStorage.getItem("tablealias"))
            .collection("chatbot")
            .doc(name)
            .update(newData);
        }
      });
  });
};

export const deleteIntegrationInDBConnect = (appName) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(appName.replace(" ", "_").toLowerCase())
      .delete()
      .then(() => {
        console.log("Deleted team data");
        resolve(true);
      })
      .catch((err) => {
        console.log(err, "dbdelfailed");
      });
  });
};

export const updateConnectHandoff = (data, name) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(name)
      .update(data)
      .then(() => {
        console.log("Updated team data");
        resolve(true);
      })
      .catch((err) => {
        console.log(err, "dbupdatefailed");
      });
  });
};

export const getConnectHandoff = (name) => {
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc(name)
      .get()
      .then((doc) => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          resolve({});
        }
      });
  })
}
export const installZendesk = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "zendesk", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallZendesk = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("zendesk").then((doc) => {
      resolve(true);
    });
  });
};

export const installSmtp = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "smtp", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallSmtp = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("smtp").then((doc) => {
      resolve(true);
    });
  });
};

export const getEditEmailData = () => {
  return new Promise(async (resolve, reject) => {
    let data = {};
    await db
      .collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc("appy_email")
      .get()
      .then((doc) => {
        data = doc.data();
        resolve(data);
      })
      .catch((err) => {
        console.log(err.message);
        resolve({});
      });
  });
};

export const editEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await db
      .collection("Connect_List")
      .doc(localStorage.getItem("tablealias"))
      .collection("chatbot")
      .doc(localStorage.getItem("botId"))
      .collection("all_integrations")
      .doc("appy_email")
      .update(data)
      .then((doc) => {
        resolve(true);
      })
      .catch((err) => {
        console.log(err.message);
        resolve(false);
      });
  });
};

export const sendOtp = (code, phone) => {
  return new Promise(async (resolve, reject) => {
    console.log(code, " code ", phone, " phone ");
    let otpUrl = API_URL + "/sendOtp";
    fetch(otpUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code,
        phone,
      }),
    })
      .then((res) => res.json())
      .then((status) => {
        console.log("OTPPPP ==== ", status);
        if (status.status == "pending") {
          resolve(true);
          console.log("loaderfalsefb8");
        } else {
          console.log("____ Invalid phone number _______ ", status);
          resolve(false);
          console.log("loaderfalsefb9");
        }
      })
      .catch((err) => console.log(err));
  });
};

export const verifyOtp = (code, phone, otp) => {
  return new Promise(async (resolve, reject) => {
    // console.log("INTERGETAIOSFSFSOIFOSFFFFF ======= ", this.state)
    let otpUrl = API_URL + "/verifyOtp";
    fetch(otpUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code, phone, otp }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("OTPPPP ==== ", data);
        if (data.status == "approved") {
          resolve(true);
        } else {
          resolve(false);
        }
        //this.setState({verifyOtp : true})
      })
      .catch((err) => console.log(err));
  });
};

export const installWhatsApp = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "whatsapp", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallWhatsApp = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("whatsapp").then((doc) => {
      resolve(true);
    });
  });
};

export const installSms = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    setHandoffinDBConnect(data, "sms", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallSms = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("sms").then((doc) => {
      resolve(true);
    });
  });
};

export const uninstallGoogle = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("gdrive").then((doc) => {
      resolve(true);
    });
  });
};

export const getconnectURL = async (appId, integrationUrl) => {
  return new Promise(async (resolve, reject) => {
    var body = [
      `cookie=${document.cookie}&appId=${appId}&botId=${localStorage.getItem(
        "botId"
      )}&integrationUrl=${integrationUrl}&clientId=${localStorage.getItem(
        "tablealias"
      )}&email=${localStorage.getItem("email")}&callback_url=${
        window.location.origin
      }/verify`,
    ];
    fetch(
      `${"https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"}/connect_integration_new/integration-url`,
      {
        // `${API_URL}/connect_integration_new/integration-url`, {
        method: "POST",
        body: body,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        resolve(response.url);
      })
      .catch((err) => {
        resolve(false);
      });
  });
};

export const fetchMSDropdown = (connectId, nodeId, team) => {
  return new Promise(async (resolve, reject) => {
    var funcName = team ? "getMicrosoftChannelList" : "getMicrosoftTeamList";
    var formField = team
      ? {
          team: team,
        }
      : {};
    await fetch(`${API_URL}/connect_integration_new/trigger-dropdown`, {
      method: "post",
      body: JSON.stringify({
        pc_id: localStorage.getItem("tablealias"),
        botId: localStorage.getItem("botId"),
        connectId,
        nodeId,
        funcName,
        formField,
        cookie: document.cookie,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.success) {
          resolve(response.data);
        } else {
          resolve([]);
        }
      });
  });
};

export const installMSteam = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "microsoftteams", addGlobal).then((doc) => {
      resolve(true);
    });
  });
};
export const uninstallMSteam = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("microsoftteams").then((doc) => {
      resolve(true);
    });
  });
};

export const fetchZHDropdown = (connectId, nodeId,funcName,team) => {
  return new Promise(async (resolve, reject) => {
    // var funcName = funcName;
    var formField = team
      ? {
        organizationId: team,
        }
      : {};
    await fetch(`${API_URL}/connect_integration_new/trigger-dropdown`, {
      method: "post",
      body: JSON.stringify({
        pc_id: localStorage.getItem("tablealias"),
        botId: localStorage.getItem("botId"),
        connectId,
        nodeId,
        funcName,
        formField,
        cookie: document.cookie,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.success) {
          var resArr=response.data.filter((item)=>item.value!=="")
          resolve(resArr);
        } else {
          resolve([]);
        }
      });

  })
}


export const installZohoDesk = (data, addGlobal) => {
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "zoho_desk", addGlobal).then((doc) => {
      resolve(true);
    });
  });
}

export const uninstallZohoDesk = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("zoho_desk").then((doc) => {
      resolve(true);
    });
  });
}

export const installSalesForce = (data) => {
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "salesforce").then((doc) => {
      resolve(true);
    });
  });
}

export const uninstallSalesForce = () => {
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("salesforce").then((doc) => {
      resolve(true);
    });
  });
}

export const installZoom=(data)=>{
  return new Promise(async (resolve, reject) => {
    updateConnectHandoff(data, "zoom").then((doc) => {
      resolve(true);
    });
  });

}

export const uninstallZoom=()=>{
  return new Promise(async (resolve, reject) => {
    deleteIntegrationInDBConnect("zoom").then((doc) => {
      resolve(true);
    });
  });
}


export const getAllAccounts = (appId)=>{
  return new Promise(async (resolve, reject) => {
    db.collection("Connect_List")
    .doc(localStorage.getItem("tablealias"))
    .collection("chatbot")
    .doc(appId)
    .get()
    .then((doc) => {
      if (doc.exists) {
        resolve(doc.data()["accounts"]?doc.data()["accounts"]:[]);
      } else {
        resolve([]);
      }
    })
  
  })

}


export const getGptData = (bot_id) => {
  return new Promise((resolve, reject) => {

     db
    .collection('user_' + localStorage.getItem('tablealias'))
    .doc(localStorage.getItem('agent_id'))
    .collection('chatbot')
    .doc(bot_id)
    .get().then(doc=>{ 
    
      if(doc.exists){
        var data=doc.data().gptData?doc.data().gptData:{}
        console.log(doc.data(),"gptDatagptDatagptDatagptDatagptData")
        resolve(data)
      }
    })
    })

}
 export const updateGptData = async (gptDataG, data, type, source) => {
   return new Promise(async (resolve, reject) => {
     console.log(
       gptDataG,
       data,
       "TYPE",
       type,
       source,
       "SOURCE    SJKDBIBNADIUBDAUBDUIABUIBDBAD"
     );

     var gptData=JSON.parse(JSON.stringify(gptDataG));

     var newData = {};

     newData["import_files"] = gptData["import_files"];
     newData["pcText"] = gptData["initText"];
     newData["import_urls"] = gptData["import_urls"];

     if (type == "init") {
       var initData = newData;
       console.log("DATA TO UPDATE IS", initData);

       db
         .collection("user_" + localStorage.getItem("tablealias"))
         .doc(localStorage.getItem("agent_id"))
         .collection("chatbot")
         .doc(localStorage.getItem("botId"))
         .update({ gptData: initData })
         .then((data) => {
           console.log("UPDATED");
           // resolve(initData);
           resolve(Object.assign({}, gptData, initData));
         });
     } else if (source == "file") {
       if (type == "add") {
         console.log("DATA TO UPDATE IS ADDD TYPE");
         data.map((item, index) => {
           const foundIndex = newData["import_files"].findIndex(
             (element) => element.name === item.name
           );
           console.log("FOUND INDEX", foundIndex, item.name);
           if (foundIndex > -1) {
             console.log("FOUND INDEX", foundIndex, "ADD TOKENS");
             newData["import_files"][foundIndex]["tokens"] = item.len;
           }
         });
         var addData = newData;
         console.log("DATA TO UPDATE IS", addData);
         db
           .collection("user_" + localStorage.getItem("tablealias"))
           .doc(localStorage.getItem("agent_id"))
           .collection("chatbot")
           .doc(localStorage.getItem("botId"))
           .update({ gptData: addData })
           .then((data) => {
             console.log("UPDATED");
             resolve(Object.assign({}, gptData, addData));
           });
       } else if (type == "delete") {
         var files = newData["import_files"];
         data.map((item, index) => {
           files = files.filter((element) => element.name !== item.name);
         });
         newData["import_files"] = files;
         var delData = newData;
         console.log("DATA TO DELETE IS", delData);

         db
           .collection("user_" + localStorage.getItem("tablealias"))
           .doc(localStorage.getItem("agent_id"))
           .collection("chatbot")
           .doc(localStorage.getItem("botId"))
           .update({ gptData: delData })
           .then((data) => {
             console.log("UPDATED");
             // resolve(delData)
             resolve(Object.assign({}, gptData, delData));
           });
       }
     } else if (source == "text") {
       newData["pcText"] = gptData["pcText"];
       db
         .collection("user_" + localStorage.getItem("tablealias"))
         .doc(localStorage.getItem("agent_id"))
         .collection("chatbot")
         .doc(localStorage.getItem("botId"))
         .update({ gptData: newData })
         .then((data) => {
           console.log("UPDATED");
           resolve(Object.assign({}, gptData));
         });
     } else if (source == "url") {
       if (type == "add") {
         console.log("DATA TO UPDATE IS ADDD TYPE");
         data.map((item, index) => {
           const foundIndex = newData["import_urls"].findIndex(
             (element) => element.name === item.name
           );
           console.log("FOUND INDEX", foundIndex, item.name);
           if (foundIndex > -1) {
             console.log("FOUND INDEX", foundIndex, "ADD TOKENS");
             newData["import_urls"][foundIndex]["tokens"] = item.len;
           }
         });
         var addData = newData;
         console.log("DATA TO UPDATE IS", addData);
         db
           .collection("user_" + localStorage.getItem("tablealias"))
           .doc(localStorage.getItem("agent_id"))
           .collection("chatbot")
           .doc(localStorage.getItem("botId"))
           .update({ gptData: addData })
           .then((data) => {
             console.log("UPDATED");
             resolve(Object.assign({}, gptData, addData));
           });
       } else if (type == "delete") {
         var files = newData["import_urls"];
         data.map((item, index) => {
           files = files.filter((element) => element.name !== item.name);
         });
         newData["import_urls"] = files;
         var delData = newData;
         console.log("DATA TO DELETE IS", delData);

         db
           .collection("user_" + localStorage.getItem("tablealias"))
           .doc(localStorage.getItem("agent_id"))
           .collection("chatbot")
           .doc(localStorage.getItem("botId"))
           .update({ gptData: delData })
           .then((data) => {
             console.log("UPDATED");
             // resolve(delData)
             resolve(Object.assign({}, gptData, delData));
           });
       }
     }
   });
 };

  
  export const trainGptData = (alias,addList) => {
    return new Promise((resolve, reject) => {

      var raw = JSON.stringify({
        "alias": alias,
        "addList":addList
      });
      
      var requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: raw,
        redirect: 'follow'
      };
      
      fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/gpt/train", requestOptions)
      // fetch("http://localhost:5051/chatbot-production-d6ea3/us-central1/gpt/train", requestOptions)

        .then(response => response.json())
        .then(result =>{resolve(result)})
        .catch(error => console.log('error', error))
      })
  
  }
  export const removeTrainGptData = (alias,delList) => {
    return new Promise((resolve, reject) => {

      var raw = JSON.stringify({
        "alias": alias,
        "addList":delList
      });
      
      var requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: raw,
        redirect: 'follow'
      };
      
      fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/gpt/delete", requestOptions)
        .then(response => response.json())
        .then(result =>{resolve(result)})
        .catch(error => console.log('error', error))
      })
  
  }
export const textToBot=async (description)=>{
  return new Promise(async (resolve, reject) => {
  if(process.env.NODE_ENV=="production"){
    var raw = JSON.stringify({
      "action": "aiPrompt",
      "email": localStorage.getItem("email"),
      "prompt": description
    });
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/querylog/insert", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  var prompt=`[
    { "nodeType": 5, "name": "Date Selector & Time Slot" },
   
    { "nodeType": 7, "name": "Single Choice Button" },
    { "nodeType": 27, "name": "Payment" },
    { "nodeType": 16, "name": "Date Selector" },
    { "nodeType": 13, "name": "File Upload" },
    { "nodeType": 24, "name": "Multiple Choice Button" },
    { "nodeType": 15, "name": "Gender" },
    { "nodeType": 20, "name": "Get Address" },
    { "nodeType": 4, "name": "Country" },
    { "nodeType": 2, "name": "Capture Email" },
    { "nodeType": 21, "name": "Get Location" },
    { "nodeType": 1, "name": "Capture name" },
    { "nodeType": 3, "name": "Capture Phone Number" },
    { "nodeType": 8, "name": "Bot Question" },
    
    { "nodeType": 25, "name": "Request Callback" },
    { "nodeType": 22, "name": "Send Media" },
    { "nodeType": 18, "name": "Survey" }
  ]
  Use this json.
  
  Use options from this json options to make a bot flow for return bot and write a json of format {"botName":"","botFlow":[]} with array of objects with "nodeType","name", and text generated by you to make a bot as per requirement below.
  Use "nodeType" integer from json .
  If you want user to choose among answer use node button and "options".
  "options" has to be a an array of texts .
   Options cannot be included with any other node.
   Do not ask questions sepearately in Bot Message.
   "Date & Time" has both date and time so you need not include "Date selector" seperately if you need both date and time.
   Add question in button node text if required not two seperated repeated nodes.
  Use Bot Message only for acknowledgement. Do not  include Thank you message for Get Email,Phone,Address,Question.
  Try upto 10 nodes.
  Do not write  a welcome message as we add it already.
  Requirement: Create a bot to `+description

   await generateJson(prompt).then(answer=>{

    
   
    console.log("BOT FLOW IS READY",typeof answer,answer)
    let startIndex = answer.indexOf('{');
    let endIndex = answer.lastIndexOf('}');

    if (startIndex !== -1 && endIndex !== -1) {
       answer = answer.substring(startIndex, endIndex + 1);
      
    } else {
      console.log('Invalid JSON string');
    }
    var raw = typeof answer==typeof "abc"?JSON.stringify(JSON.parse(answer).botFlow):JSON.stringify(answer.botFlow);
    var botName=typeof answer==typeof "abc"?JSON.stringify(JSON.parse(answer).botName):JSON.stringify(answer.botName)
    localStorage.setItem("BOTNAME",botName)
    
    localStorage.setItem("BotName",botName)

    console.log("BOT FLOW IS READY",typeof answer,raw,botName)
    var requestOptions = {
      method: 'POST',
      headers:  {'Content-Type': 'application/json'},
      body: raw,
      redirect: 'follow'
    };
    // fetch("http://localhost:5010/chatbot-production-d6ea3/us-central1/makeBotNew", requestOptions)

    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"+"/makeBotNew", requestOptions)
      .then(response => response.json())
      .then(result => {
        // res.status(200).send(result)
        resolve(result)
      
      })
      .catch(error => {
        // res.status(300).send("ERROR")
        // console.log('error', error)
        resolve(false)
      });
    
    

  }).catch(err=>{
    console.log(err)
    resolve(false)


    
  })
})

}

async function generateJson(prompt) {
  // console.log(prompt,"PROMPT")
  return new Promise(async (resolve, reject) => {

  db.collection("Api_Key").doc("Preview").get().then(async keys=>{
    if(keys.exists){

    const openaiApiKey = keys.data().openAI
  const apiUrl = 'https://api.openai.com/v1/engines/text-davinci-003/completions';

  const body = {
    prompt: prompt,
    temperature:0,
    max_tokens:2048,
    n:1,
    stop:'None',
    
  };

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${openaiApiKey}`
    },
    body: JSON.stringify(body)
  });

  const result = await response.json();
  // console.log(result,"DDATATATTATATTATA")
  // return true
  if (result.error){
    // res.status(200).json({ data: result.error.message });
    console.log("ERROR IN AI RESPONSE")
    resolve(result.error.message);
  }else{
  console.log(typeof result.choices[0].text,"AI RESPONSE")
  
  const message = result.choices[0].text
  resolve(message);
  // res.status(200).json({ data: aiResponse });
  }
}

  })

  
  // const message = data.choices[0].text;

  // resolve(message);
})
}
export const getPrompt = async(userId) => {
  return new Promise(async (resolve, reject) => {
    db.collection("TextToBot").doc(userId).get().then(doc=>{
      if(doc.exists){
        console.log(doc.data(),"PROMPT")
        resolve({"status":true,"prompt":doc.data().user_prompt})
      }else{
        resolve({"status":false})
      }
    })
  })
}

// export const makeBotInDb = async(botId) => {
//   return new Promise(async (resolve, reject) => {
//     console.log("MAKING BOT IN DB",botId)
  
// var chatBotAvtar=custom.chatBotAvtars.chatBotAvtar
// var widgetImage=custom.widgetImages.widgetImage
// if(localStorage.getItem('aiSteps')){
//   custom["template_One"]=JSON.parse(localStorage.getItem('aiSteps'))
//   // localStorage.removeItem('aiSteps')
  
//   for (const key in custom.template_One) {
//     if (custom.template_One[key].UI == 'Handoffs') {
//       var handoffs_ = custom.template_One[key].data.handoffs
//       for (const key in handoffs_) {
//         handoffs_[key].data.email = localStorage.getItem('email')
//       }
//     }
//   }
//   custom.template_One.customisation.headerName = localStorage.getItem("BOTNAME")?localStorage.getItem("BOTNAME"):"AI Bot"
//   custom.template_One['0'].data.text = custom.template_One[
//     '0'
//   ].data.text.replace('Snappy', `{{bot_name}}`)
//   var bot_details = {
//     bot_name: localStorage.getItem("BOTNAME")?localStorage.getItem("BOTNAME").replace(/"/g,''):"AI Bot",
//     bot_template_category: 17,
//     create_date: new Date().getTime(),
//     update_date: new Date().getTime(),
//     isDelete: 0
//   }
//   localStorage.setItem("botTemp",17)
//   first_time_saveDataFlow(
//     botId,
//     custom.template_One,
//     bot_details,
//    chatBotAvtar,
//     widgetImage
//   )
//   resolve(true)
// }else{
//   NotificationManager.error("Please create a bot first")
//   resolve(false)
// }
//   })

// }

export const getPromptsStatic = async() => {
  return new Promise(async (resolve, reject) => {
    db.collection("TextToBot").doc("promptList").get().then(doc=>{
      if(doc.exists){
       console.log(doc.data().promptList,"PROMPT LIST")
        resolve(doc.data().promptList)
      }else{
        resolve(false)
      }
    })
  })
}


export const trainPC = (alias,addList) => {
  return new Promise((resolve, reject) => {

    var raw = JSON.stringify({
      "nameSpace": alias,
      "addList":addList?addList:[]
    });
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: raw,
      redirect: 'follow'
    };
    
    fetch(API_URL + "/pineCone/train", requestOptions)
    // fetch("http://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/train", requestOptions)

      // .then(response => response.json())
      .then(result =>{
        if (result.status == 200 ){
          resolve(true)
        
        }else{
          resolve(false)
          }
        })
      .catch(error => console.log('error', error))
    })

}
export const deletePC = (alias,delList) => {
  return new Promise((resolve, reject) => {

    var raw = JSON.stringify({
      "nameSpace": alias,
      "delList":delList
    });
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: raw,
      redirect: 'follow'
    };
    
    fetch(API_URL + "/pineCone/delete", requestOptions)
    // fetch("http://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/delete", requestOptions)

      // .then(response => response.json())
      .then(result =>{resolve(true)})
      .catch(error => console.log('error', error))
    })

}



export const trainPCNew = (addList,source) => {

  console.log(addList,"ADD LIST",source)
    return new Promise(async (resolve, reject) => {

        await trainPCDoc(addList,0,source).then(result=>{
          console.log(result,"RESULT of trainPCALL",addListPC)
          if(result){
            resolve(addListPC)
          }else{
            resolve(false)
          }
        })
    })
}

export const trainPCDoc=(docs,index,source)=>{
  return new Promise((resolve, reject) => {

  console.log(docs,index,"DOC cond",index<docs.length)
  if(index<docs.length){
    // console.log(docs[index],"DOC CALLED API")
    callTrainUrl(docs[index],source).then(result=>{
      console.log(result,"RESULT of trainPCALL URL API")

      if(result.status){
        // UPDATE DB
        addListPC.push(result)
        resolve(trainPCDoc(docs,index+1,source))
      }else{
        // UPDATE DB
        resolve(trainPCDoc(docs,index+1,source))

      }

      }).catch(err=>{
        // UPDATE DB
        trainPCDoc(docs,index+1,source)
      })
      }else{
       resolve(true)
      }
    })
}
export const callTrainUrl=(body,source)=>{
  return new Promise((resolve, reject) => {
 
    if(source=="file"){
    getUnstructuredUrlText(body.url).then(text=>{
      if(text){
        // console.log(text,"API URL is result text")
        trainText(text,body.name).then(result=>{
          resolve(result)
        })
      }else{
        resolve(false)
      }
    })
  }else if (source=="url"){
    console.log(body.url,"API URL input fot result text")
    getTextFromUrl(body.url).then(text=>{
      if(text){
        // console.log(text,"API URL is result text")
        trainText(text,body.name).then(result=>{
          resolve(result)
        })
      }else{
        resolve(false)
      }
    })


  }
  

  
})
}


export const trainText=(text,name)=>{

  return new Promise((resolve, reject) => {


  var raw =JSON.stringify({text,nameSpace:localStorage.getItem("botId"),name})
  console.log(raw,"RAW TEXT")
  var apiUrl="https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/pineCone/trainText"

  var requestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: raw,
    redirect: 'follow'
  };
  // console.log(apiUrl,"API URL is this final")
  fetch(apiUrl, requestOptions)
    
  .then(result => {
    console.log(result,"API URL is result")
    if(result.status==200){
      return result.json()
      
    }else{
     return false
    }
    
    
    // console.log(result)
  }).then(text=>{
    if(text){
      resolve(text)
    }
    else{
      resolve(false)
    }
  })
    .catch(error => {resolve(false)})


  })




}

export const getUnstructuredUrlText=(url)=>{
  return new Promise((resolve, reject) => {

   

var raw = JSON.stringify(
 
    {url}
  
);

var requestOptions = {
  method: 'POST',
  headers: {'Content-Type': 'application/json'},
  body: raw,
  redirect: 'follow'
};
// fetch("https://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/getUnstText", requestOptions)
fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/pineCone/getUnstText", requestOptions)
  // .then(response => response.json())

  .then(result => {
    console.log(result,"API URL is result")
    if(result.status==200){
      return result.json()
      
    }else{
     return false
    }
    
    
    // console.log(result)
  }).then(text=>{
    if(text){
      resolve(text.body)
    }
    else{
      resolve(false)
    }
  })
  .catch(error => {
    console.log('error', error)
    resolve(false)
  });

  })
}

export const getTextFromUrl=(url)=>{
  // return new Promise(async (resolve, reject) => {
  //   var raw = JSON.stringify({
  //     "method": "url",
  //     "url": url
  //   });
    
  //   var requestOptions = {
  //     method: 'POST',
  //     headers: {"Content-Type": "application/json"},
  //     body: raw,
  //     redirect: 'follow'
  //   };
    
  //   fetch("https://us-central1-appycx-beta.cloudfunctions.net/getText", requestOptions)
  //   .then(result => {
  //     console.log(result,"API URL is result")
  //     if(result.status==200){
  //       return result.json()
        
  //     }else{
  //      return false
  //     }
      
      
  //     // console.log(result)
  //   }).then(text=>{
  //     if(text){
  //       resolve(text.body)
  //     }
  //     else{
  //       resolve(false)
  //     }
  //   })
  //   .catch(error => {
  //     console.log('error', error)
  //     resolve(false)
  //   });

  // })
  return new Promise((resolve, reject) => {

   

    var raw = JSON.stringify(
     
        {url}
      
    );
    
    var requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: raw,
      redirect: 'follow'
    };
    // fetch("http://localhost:5099/chatbot-production-d6ea3/us-central1/pineCone/getUrlText", requestOptions)
    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/pineCone/getUrlText", requestOptions)
      // .then(response => response.json())
    
      .then(result => {
        console.log(result,"API URL is result")
        if(result.status==200){
          return result.json()
          
        }else{
         return false
        }
        
        
        // console.log(result)
      }).then(text=>{
        if(text){
          resolve(text.body)
        }
        else{
          resolve(false)
        }
      })
      .catch(error => {
        console.log('error', error)
        resolve(false)
      });
    
      })


}


export const updateApptConfig=(data)=>{
  return new Promise((resolve, reject) => {

    var body={}
    body[`appointment.configuration`]=data
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .update(body).then(result=>{
      console.log("UPDATED APPT CONFIG")
      resolve(true)
    })
    .catch(error => {
      console.log('error', error)
      resolve(false)
    })
  })
}
export const saveApptWorkHours=(data)=>{
  return new Promise((resolve, reject) => {
    var body={}
    body[`appointment.workingHours.workingHours`]=data  
    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .update(body).then(result=>{
      console.log("UPDATED APPT CONFIG")
      resolve(true)
    })
    .catch(error => {
      console.log('error', error)
      resolve(false)
    })


  
  })

}


export const updateTemplateinDB=(data,name)=>{
  return new Promise((resolve, reject) => {

    console.log(data,"DATA TO UPDATE",name)

    db.collection("user_"+localStorage.getItem("tablealias"))
    .doc(localStorage.getItem("agent_id"))
    .collection("chatbot")
    .doc(localStorage.getItem("botId"))
    .update({template:data}).then(result=>{

      db.collection("user_"+localStorage.getItem("tablealias"))
      .doc(localStorage.getItem("agent_id"))
      .collection("chatbot")
      .doc("dashboard")
      .update({bot_name:name,update_date: new Date().getItem()}).then(result=>{
        resolve(true)
      })
    })


  })
}

export const isAnyPaymentinAcc=()=>{
  return new Promise((resolve, reject) => {
    resolve(false)
    //   db.collection("DheerajPaymentTest").where("email","==",localStorage.getItem('email')).limit(1).get().then(async docs=>{
    //   console.log(docs.docs.length,"DOCS  PAYMENT LENGTH")
    //   if(docs.docs.length>0){
    //     resolve(true)
    //   }else{
    //     resolve(false)
    //   }
    // })
    // .catch(err=>{
    //   resolve(false)
    // })
  })
}