import React ,{useState,useEffect}from 'react'
import SelectTimezone from './SelectTimezone'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { updateApptConfig }  from "./../../utilities/chatbotMethods"
// '../../../utilities/chatbotMethods'


function Config() {

    const {agentData} = useSelector((state) => state.agentData)

    const [allowSave, setAllowSave] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [timeFormat, setTimeFormat] = useState('12')
    const [duration, setDuration] = useState('15 minutes')
    const [schedule, setSchedule] = useState('15 days')
    const [buffer, setBuffer] = useState('5 minutes')
    const [timezone, setTimezone] = useState('Asia/Kolkata')
    var durationArr = Array.from({ length: 4 }, (_, index) => (index + 1)*15 +" minutes")
    var bufferArr = Array.from({ length: 12 }, (_, index) => (index + 1)*5 +" minutes")
    var scheduleArr=Array.from({ length: 45 }, (_, index) => index + 1)




    useEffect(() => {
        if(agentData&&agentData.appointment){
            setTimeFormat(agentData.appointment.configuration.timeFormat)
            setDuration(agentData.appointment.configuration.duration)
            setSchedule(agentData.appointment.configuration.schedule)
            setBuffer(agentData.appointment.configuration.buffer)
            setTimezone(agentData.appointment.configuration.timezone)
            setIsLoading(false)
        }else{
            setIsLoading(false)
        }
    },[])


    const handleSave = () => {
 
        var body={
            schedule: schedule,
            buffer: buffer,
            duration: duration,
            timeFormat: timeFormat,
            timezone: timezone
        }

        updateApptConfig(body).then(status=>{
            if(status){
                setAllowSave(false)
                NotificationManager.success('Appointment Configuration Updated Successfully',"",2000)
            }else{
                NotificationManager.error('Appointment Configuration Updation Failed',"Reload and try again.",2000)
            }
        })




    }
    const handleCancel = () => {}
    const handleDuration = (e) => {
        setAllowSave(true)
        setDuration(e.target.value)
    }
    const handleTimeFormat = (e) => {
        setAllowSave(true)
        setTimeFormat(e.target.value)
    }
    const handleBuffer = (e) => {
        setAllowSave(true)
        setBuffer(e.target.value)
    }
    const changeTimezone = (e) => {
        setAllowSave(true)
        console.log(e,"timezone")
        setTimezone(e)
    }
    const handleSchedule = (e) => {
        setAllowSave(true)
        setSchedule(e.target.value)
    }

    
    
    
  return (
    <>
    
        {isLoading ?"Loading...":
        <div>
          <div className='innerAgentinfoContainer'>
          <div className='agentinfo-headername'>Configuration</div>
          <div className='agentinfo-content'>
            {/* <div className="configuration-header"> */}
            <div className='agentWorkingHead'>
              <span className='workingHoursheadingstate'>
                <h4>
                 
                    Appointment Configuration
                </h4>
              </span>
              <span className='workingHoursbuttons'>
              {allowSave?<button
                  className='btnBlue'
                  onClick={handleSave}
                 
                >
                  Save
                </button>:<span>Save</span>}
                {/* <button className='btntransparent' onClick={handleCancel}>
                  Cancel
                </button> */}
              </span>
            </div>
            <div className='configuration-body'>
             
              <>
                <div>
                  <span className='configrationHeading'>Select duration</span>
                  <span className='selectOption'>
                    <select
                      value={duration}
                      onChange={handleDuration}
                    >
                      {durationArr.map(item => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
                <div>
                  <span className='configrationHeading'>
                    Select buffer time between appointments
                  </span>
                  <span className='selectOption'>
                    <select
                      value={buffer}
                      onChange={handleBuffer}
                    >
                      {bufferArr.map(item => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>
                <div>
                  <span className='configrationHeading'>
                    Select schedule window
                  </span>
                  <span className='selectOption'>
                    <select
                    //  disabled
                      value={schedule}
                      onChange={handleSchedule}
                    >
                      {scheduleArr.map(item => (
                        <option key={item} value={item}>
                          {item} days
                        </option>
                      ))}
                    </select>
                  </span>
                 
                  <span className='informationI'>
                    i
                    <span className='titleShowHover'>
                      Here you can define how much time period in advance are
                      customers allowed to book an appointment
                    </span>
                  </span>
                
                </div>
                
                <div>
                  <span className='configrationHeading'>
                    Select time format
                  </span>
                  <span className='selectOption'>
                    <select
                      value={timeFormat}
                      onChange={handleTimeFormat}
                    >
                      <option value='12'>12 hours</option>
                      <option value='24'>24 hours</option>
                    </select>
                  </span>
                </div>
                <div className='d-flex align-items-center'>
                  <span className='configrationHeading mr-2'>Timezone:</span>
                  <span className='selectOption' style={{ width: '300px' }}>
                    <SelectTimezone
                      value={timezone}
                      onChange={changeTimezone}
                    />
                  </span>
                </div>
              </>
            </div>
          </div>
        </div>
        </div>
        }
      </>
  
  )
}

export default Config