const Pagignation = (props) => {

  const {totalCount, onPageChange, currentPage, pageSize} = props

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let noOfPages= Math.ceil(totalCount/pageSize);


  // let totalPageSize = (totalCount/pageSize)-1
  // let val = parseInt(totalCount/pageSize)
  // if(totalPageSize!=0){ 
  //   totalPageSize= val
  // }else{
  //   totalPageSize= val
  // }



 
  

  return (
    <div className="pagignationShowResult flex items-center mt-5 ">
      <p className="mr-auto text-sm text-[#343434]">Showing {(currentPage-1)*pageSize+1} to {Math.min(currentPage*pageSize,totalCount)} of {totalCount} items</p>
      <div className="pagignation flex align-center bg-white px-2 py-2 rounded-full border border-slate-300">
        {currentPage > 1 ? 
        <span className="w-6 h-6 rounded-full border flex items-center justify-center border-slate-300 cursor-pointer mr-2"  onClick={onPrevious}>
          <svg xmlns="http://www.w3.org/2000/svg" width="5.657" height="10.115" viewBox="0 0 5.657 10.115">
            <path
              id="Shape"
              d="M4.209,8.418,0,4.209,4.209,0"
              transform="translate(0.6 0.849)"
              fill="none"
              stroke="#343434"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              strokeWidth="1.2"
            />
          </svg>
        </span> : 
        <span className="w-6 h-6 rounded-full border flex items-center justify-center border-slate-300 cursor-auto opacity-50 mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="5.657" height="10.115" viewBox="0 0 5.657 10.115">
            <path
              id="Shape"
              d="M4.209,8.418,0,4.209,4.209,0"
              transform="translate(0.6 0.849)"
              fill="none"
              stroke="#343434"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              strokeWidth="1.2"
            />
          </svg>
        </span> }



      

       

        {currentPage < noOfPages ? 
        <span className="w-6 h-6 rounded-full border flex items-center justify-center border-slate-300 cursor-pointer ml-2"  onClick={onNext}>
          <svg xmlns="http://www.w3.org/2000/svg" width="5.657" height="10.115" viewBox="0 0 5.657 10.115">
            <path
              id="Shape"
              d="M0,8.418,4.209,4.209,0,0"
              transform="translate(0.849 0.849)"
              fill="none"
              stroke="#343434"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit={10}
              strokeWidth="1.2"
            />
          </svg>
        </span>: 
        <span className="w-6 h-6 rounded-full border flex items-center justify-center border-slate-300 cursor-auto opacity-50 ml-2" >
        <svg xmlns="http://www.w3.org/2000/svg" width="5.657" height="10.115" viewBox="0 0 5.657 10.115">
          <path
            id="Shape"
            d="M0,8.418,4.209,4.209,0,0"
            transform="translate(0.849 0.849)"
            fill="none"
            stroke="#343434"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth="1.2"
          />
        </svg>
      </span> }
      </div>
    </div>
  );
};

export default Pagignation;
