import React , {useState} from 'react'
import { useEffect } from 'react'
import botList from './../../data/en.json'

function GetBotType(props) {

    const [iFrameUrl , setIFrameUrl] = useState("https://chatwidget.bot/previewnew.html?tempid="+props.type)

    const [limitedBots , setLimitedBots] = useState([])

    const [searchValue , setSearchValue] = useState('')

    const [searchError , setSearchError] = useState('')


    const handleBotSelect = (value) => {
        props.setType(value)
        setIFrameUrl("https://chatwidget.bot/previewnew.html?tempid="+value)
    }


    const handleSearch = (value) => {
            setSearchValue(value)
            var result = botList.botList.filter((obj) => {
            // console.log(obj,"OBJOBJOBJ",val,obj.button.toLowerCase().includes(val.toLowerCase()))
            return obj.button.toLowerCase().includes(value.toLowerCase())

          })
          if(result.length === 0){
            setSearchError(true)
            result = botList.botList.find((obj) => {
             return obj.id === 17
            })
          }
          else{
            setSearchError(false)
          }
          setLimitedBots(result)
          props.setType(result[0].id)
          setIFrameUrl("https://chatwidget.bot/previewnew.html?tempid="+result[0].id)
    }


    useEffect(() => {
       var botsToShow = botList.botList.filter((obj) => {
        return !obj.hide
       })
         setLimitedBots(botsToShow)
    }, [])




  return (
    <div>
        <input type="text"  value={searchValue} onChange = {(event) => handleSearch(event.target.value)}/>
        {searchError && <div>You can try making your own bot</div>}
        {limitedBots.map((bot) => {
            return <div className={bot.id === props.type ? 'selected' : '' }><button onClick={() => handleBotSelect(bot.id)}>{bot.button}</button></div>
        })}

        <iframe src={iFrameUrl} width="100%" height="500px" frameBorder="0"></iframe>
    </div>
  )
}

export default GetBotType