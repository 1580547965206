import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateNodeMenuPosition, updateSelectedNode, updateTemplate } from "../../features/botTemplate/botTemplateSlice";
import { nodeMenu as nodeMenuList } from "../../data/node/nodeData";
import { useSelector } from "react-redux";
import { addNode } from "../../utilities/convertTemplateToFlowChart";

const NodePopup = () => {


 //getting data from global state -- botTemplate
 const {nodeMenuPosition , template} = useSelector(state => state.botTemplate)


 //destructuring
 const {source , target} = nodeMenuPosition



  const dispatch = useDispatch()
  // const [showModal, setShowModal] = React.useState(true);


  //local state
  const [nodeMenu , setNodeMenu] = useState(nodeMenuList)

  //event listner
  function onChangeHandler(event){
    const inputText = event.target.value
    console.log(inputText, ' input maggi')
    const newNodeMenu = nodeMenuList.filter((item) => {
      if((item.newname && item.newname.toLowerCase().includes(inputText.toLowerCase())) || item.name.toLowerCase().includes(inputText.toLowerCase())){
        return item
      }
      
    })
    console.log(newNodeMenu , ' maggi newNodeMenu')
    setNodeMenu(newNodeMenu)
  }

  //callback function
  //to add a new node in the flow
  //@PARAMETERS -- source node id , target node id , id of the node type to be added
  function addNewNode(source , target , nodeTypeId){

    //metadata -- data of the new node to be added
    const nodeToBeAdded = {
      source,
      target,
      nodeTypeId
    }
    

    //calling utility function -- addNode --> to add a new node in the flow
    //@PARAMETERS -- template , metadata of the node to be added
    //RETURN - new template
    const {newTemplate , selectedNode} = addNode(template , nodeToBeAdded)

    console.log(newTemplate , ' new template')//for debugging purpose


    //dispatching action -- updateTemplate
    //rerenders the editor
    dispatch(updateTemplate(newTemplate))



    //dispatching action -- updateNodeMenuPosition
    //remove the dropdown/node menu after adding a node
    //rerenders the editor
    dispatch(updateNodeMenuPosition(null))

    //dispatching actions -- updateNodeToBeFocused
    //moves the viewport to the node position
    //rerenders the editor
    dispatch(updateSelectedNode(selectedNode))
  }

    return (
        <>
           {/* {showModal ? ( */}
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed z-50 outline-none focus:outline-none"
          >
            <div className="relative w-full  mx-auto max-w-sm">
              <div className="border-2 border-[#0634620F] rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 py-2 border-b border-solid border-slate-200 rounded-t">
                <label className="relative block w-full">
                    <input className="placeholder:text-slate-400 block bg-white w-full border-none rounded-md py-2 pl-1 pr-8 focus:outline-none sm:text-sm" placeholder="Search..." type="text" name="search" onChange={onChangeHandler}/>
                    {/* <span class="absolute inset-y-0 right-0 flex items-center pl-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.245" height="20.245" viewBox="0 0 20.245 20.245">
                    <g id="Group_72319" data-name="Group 72319" transform="translate(-974.17 -319.169)">
                        <path id="Path_22828" data-name="Path 22828" d="M20.35,12.425A7.925,7.925,0,1,1,12.425,4.5a7.925,7.925,0,0,1,7.925,7.925Z" transform="translate(970.67 315.669)" fill="none" stroke="#5269c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_22829" data-name="Path 22829" d="M29.284,29.284l-4.309-4.309" transform="translate(963.717 308.716)" fill="none" stroke="#5269c9" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                    </svg>
                    </span> */}
                    
                    
                    </label>
                  {/* <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => dispatch(updateNodeMenuPosition(null))}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button> */}
                </div>
                <div className="relative p-6 flex-auto max-h-80 h-full overflow-auto">
                  <ul>


                  {nodeMenu.map((item) => {
                    return <li onClick={(event) => {addNewNode(source , target , item.id)}} className="flex items-center mb-4 hover:text-linkColor cursor-pointer group last:mb-0" key={item.id}>
                    <span className="mr-5 bg-[#F5F6FA] p-2 rounded-full">
                            <svg className="fill-[#788290] group-hover:fill-linkColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 8.09 8.09">
                                <path id="Icon_material-question-answer" data-name="Icon material-question-answer" d="M10.685,4.618H9.876v3.64H4.618v.809a.406.406,0,0,0,.4.4H9.472L11.09,11.09V5.022A.406.406,0,0,0,10.685,4.618ZM9.067,7.045V3.4a.406.406,0,0,0-.4-.4H3.4a.406.406,0,0,0-.4.4V9.067L4.618,7.449H8.663A.406.406,0,0,0,9.067,7.045Z" transform="translate(-3 -3)" />
                            </svg>
                        </span>
                        {item.newname ? item.newname : item.name}
                    </li>
                  })}


                    {/* <li className="flex items-center mb-4 hover:text-linkColor cursor-pointer group">
                        <span className="mr-5 bg-[#F5F6FA] p-2 rounded-full">
                            <svg className="fill-[#788290] group-hover:fill-linkColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 8.77 8.77">
                                <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M4.385,4.933A2.466,2.466,0,1,0,1.918,2.466,2.467,2.467,0,0,0,4.385,4.933Zm2.192.548H5.633a2.982,2.982,0,0,1-2.5,0H2.192A2.192,2.192,0,0,0,0,7.673v.274a.822.822,0,0,0,.822.822H7.947a.822.822,0,0,0,.822-.822V7.673A2.192,2.192,0,0,0,6.577,5.481Z" />
                            </svg>
                        </span>
                        User Input
                    </li>
                    <li className="flex items-center mb-4 hover:text-linkColor cursor-pointer group">
                    <span className="mr-5 bg-[#F5F6FA] p-2 rounded-full">
                            <svg className="fill-[#788290] group-hover:fill-linkColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 8.09 8.09">
                                <path id="Icon_material-question-answer" data-name="Icon material-question-answer" d="M10.685,4.618H9.876v3.64H4.618v.809a.406.406,0,0,0,.4.4H9.472L11.09,11.09V5.022A.406.406,0,0,0,10.685,4.618ZM9.067,7.045V3.4a.406.406,0,0,0-.4-.4H3.4a.406.406,0,0,0-.4.4V9.067L4.618,7.449H8.663A.406.406,0,0,0,9.067,7.045Z" transform="translate(-3 -3)" />
                            </svg>
                        </span>
                        FAQ
                    </li>
                    <li className="flex items-center mb-4 hover:text-linkColor cursor-pointer group">
                        <span className="mr-5 bg-[#F5F6FA] p-2 rounded-full">
                        <svg className="fill-[#788290] group-hover:fill-linkColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 10.721 5.896">
                            <path id="Icon_material-attachment" data-name="Icon material-attachment" d="M3,13.448A2.947,2.947,0,0,1,5.948,10.5h5.628a2.144,2.144,0,1,1,0,4.288H7.02a1.34,1.34,0,0,1,0-2.68h4.02V13.18H6.972c-.295,0-.295.536,0,.536h4.6a1.072,1.072,0,0,0,0-2.144H5.948a1.876,1.876,0,1,0,0,3.752h5.092V16.4H5.948A2.947,2.947,0,0,1,3,13.448Z" transform="translate(-3 -10.5)" />
                        </svg>
                        </span>
                        Attachment Input
                    </li>
                    <li className="flex items-center mb-4 hover:text-linkColor cursor-pointer group">
                    <span className="mr-5 bg-[#F5F6FA] p-2 rounded-full">
                        <svg className="fill-[#788290] group-hover:fill-linkColor" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 7.417 7.417">
                        <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M11.686,4.519,4.595,7.61a.163.163,0,0,0,.006.3L6.519,8.99a.309.309,0,0,0,.354-.035l3.782-3.261c.025-.021.085-.062.108-.039s-.014.083-.035.108L7.456,9.449a.308.308,0,0,0-.031.369l1.254,2.011a.163.163,0,0,0,.294,0L11.9,4.734A.163.163,0,0,0,11.686,4.519Z" transform="translate(-4.503 -4.503)" />
                        </svg>
                        </span>
                        Bot response
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </>
      {/* ) : null}  */}
        </>
    )
}

export default NodePopup;
