import React, { useState, useEffect } from "react";
import {Calendar as BigCalendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import 'react-big-calendar/lib/css/react-big-calendar.css';

// import {
//   appointment_popup_method,
//   updatingAppointmentBadge,
// } from "../../../actions";
// import "../../../plugin/calendar/lib/css/react-big-calendar.css";
// import AppointmentDetails from "./AppointmentDetails";
// import {
//   getAllUsers,
//   getWorkingHours,
//   getBookingsCalendar_,
//   deleteAppointmentFromDB,
//   getConfiguration,
// } from "../../../../config/Collections";
// import Loader from "../../../loader/Oval";
// import ShowMorePopUp from "./ShowMorePopUp";
// import firebase, { fb } from "../../../../config/firebase";
// import { updateBadgeCount } from "../../../../config/chatbotmethods";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

// const localizer = BigCalendar.momentLocalizer(moment);

const Event = ({ event }) => {
  return (
    <span>
      <span>{event.title.split("\n")[0]}</span>&nbsp;&nbsp;
      <span>{event.title.split("\n")[1]}</span>
    </span>
  );
};
var _events = [];

const CalendarJS = () => {
  const dispatch = useDispatch();
//   const user_details = useSelector((state) => state.chatbot.user_details);
//   const appointmentBadge = useSelector(
//     (state) => state.chatbot.appointmentBadge
//   );
  const {agentData} = useSelector((state) => state.agentData);

  const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [key, setKey] = useState(false);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState("");
  const [details, setDetails] = useState("");
  const [showMoreDate, setShowMoreDate] = useState("");
  const [showMorePopUp, setShowMorePopUp] = useState(false);
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    // const fetchEventData = async () => {
      setLoader(true);

      const events = [];
      const timeFormat =
        agentData.appointment.configuration.timeFormat;

    //   const snapshot = await firebase
    //     .collection("user_" + localStorage.getItem("tablealias"))
    //     .doc(localStorage.getItem("agent_id"))
    //     .get();

      const _eventsData = agentData.appointment.appointmentsbooked || [];

      _eventsData.forEach((element) => {
        if (timeFormat.timeFormat == "12") {
          events.push({
            end: element.end,
            start: element.start,
            title:
              element.client_name.charAt(0).toUpperCase() +
              element.client_name.slice(1) +
              "\n" +
              formatAMPM(element.start.toDate()) +
              " - " +
              formatAMPM(element.end.toDate()),
          });
        } else {
          events.push({
            end: element.end,
            start: element.start,
            title:
              element.client_name.charAt(0).toUpperCase() +
              element.client_name.slice(1) +
              "\n" +
              format24(element.start.toDate()) +
              " - " +
              format24(element.end.toDate()),
          });
        }
      });

      const newArr = events.map((element) => {
        return {
          title: element.title,
          start: element.start.toDate(),
          end: element.end.toDate(),
        };
      });

      setEvents(newArr);
      setLoader(false);

    //   const users = [];
    //   const usersSnapshot = await firebase
    //     .collection("user_" + localStorage.getItem("tablealias"))
    //     .get();

    //   usersSnapshot.forEach(function (doc) {
    //     users.push({
    //       id: doc.id,
    //       data: doc.data().profile.name,
    //       completeData: doc.data(),
    //     });
    //   });

    //   const name = users.find((element) => element.id === localStorage.getItem("agent_id")).completeData.name;

    //   setUsers(users);
    //   setId(localStorage.getItem("agent_id"));
    //   setLoader(false);

    //   const _appointmentBadge = {
    //     new: 0,
    //     remove: appointmentBadge.remove + appointmentBadge.new,
    //   };

    //   dispatch(updatingAppointmentBadge(_appointmentBadge));
    //   updateBadgeCount(_appointmentBadge);
    // };

    // fetchEventData();
  }, [agentData]);

  const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};
const format24 = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? "pm" : "am";
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes;
    return strTime;
};

  const handleSelectEvent = (e) => {
    setShowAppointmentDetails(true);
    // dispatch(appointment_popup_method(true));
    // _events.forEach(element => {
    // 	if (element.start.toDate().toString() == e.start.toString()) {
    // 		setShowAppointmentDetails(true);
    // 		setDetails(element);
    // 	}
    // });
  };

  const close = () => {
    setShowAppointmentDetails(false);
  };

  const handleUsers = async (user) => {
    // setLoader(true);
    // const events = await getWorkingHours(user);

    // const newArr = events.events.map((element) => {
    //   return {
    //     title: element.title,
    //     start: element.start.toDate(),
    //     end: element.end.toDate(),
    //   };
    // });

    // setEvents(newArr);
    // setId(user);
    // setLoader(false);
  };

//   const deleteAppointment = (data) => {
//     const events_ = [];
//     let id = "";
//     _events.forEach((element) => {
//       if (element.start.toDate().toString() !== data.start.toDate().toString()) {
//         events_.push(element);
//       } else {
//         id = element.id;
//       }
//     });

//     const events = events_.map((element) => {
//       return {
//         end: element.end,
//         start: element.start,
//         title:
//           element.client_name.charAt(0).toUpperCase() +
//           element.client_name.slice(1) +
//           "\n" +
//           formatAMPM(element.start.toDate()) +
//           " - " +
//           formatAMPM(element.end.toDate()),
//       };
//     });

//     const newArr = events.map((element) => {
//       return {
//         title: element.title,
//         start: element.start.toDate(),
//         end: element.end.toDate(),
//       };
//     });

//     _events = events_;
//     close();
//     setEvents(newArr);
//     deleteAppointmentFromDB(id);
//   };

  const handleShowMore = (segments) => {
    const list = [];
    events.forEach((element) => {
      if (formatDateMonth(segments[0].event.start) === formatDateMonth(element.start)) {
        list.push(element);
      }
    });

    setShowMorePopUp(!showMorePopUp);
    setEventList(list.reverse().reverse());
  };

  const formatDateMonth = (date) => {
    var monthNames = [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
    ];
    var daysName = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

    var day = date.getDate();
    var _day = date.getDay();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return (
        daysName[_day] + " " + monthNames[monthIndex] + " " + day + " " + year
    );
  };

  return (
    <div className="wrapperLefts">
      <section className="dashboardArea">
        <div className="innerDashboardAreaSec paddingLR30">
          {showAppointmentDetails && (
            <></>
            // <AppointmentDetails
            //   close={close}
            //   data={details}
            //   name={name}
            //   deleteAppointment={deleteAppointment}
            // />
          ) }
          {showMorePopUp && (
            <></>
            // <div>
            //   {/* <ShowMorePopUp
            //     data={eventList}
            //     handleShowMore={handleSelectEvent}
            //     close={() => setShowMorePopUp(!showMorePopUp)}
            //   /> */}
            //   <div className="cover_show_more" />
            // </div>
          ) }
          <div className="dashboardTitleSection">
            <div className="title">
              <h2>BigCalendar</h2>
            </div>
          </div>
          <div className="calendarBody">
            <div
              style={{
                height: "85vh",
                display: "inline-block",
                width: "100%",
              }}
            >
              {loader ? (
                <div className="mainLoaderNew">
                  <div className="loadingNew">
                    <div className="bounceball"></div>
                    <div className="textLoding">NOW LOADING</div>
                  </div>
                </div>
              ) : (
                <BigCalendar
                  key={key}
                  onSelectEvent={handleSelectEvent}
                  localizer={localizer}
                  events={events}
                  views={["month", "week", "day"]}
                  step={60}
                  users={users}
                  id={id}
                  handleUsers={handleUsers}
                  showMore={handleShowMore}
                  components={{
                    event: Event,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CalendarJS;
