import React from "react";
import Pagignation from "../../Component/Pagination";
import SideMenu from "../../Component/sidemenu";
import Topheader from "../../Component/TopHeader";
import { Chart } from "react-google-charts";

const Analytics = () => {
  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  //    const options = {
  //     title: "My Daily Activities",
  //   };

  return (
    <>
      {/* <SideMenu /> */}
      <div className="pl-[76px] lg:pl-[276px] p-5">
        <Topheader Analytics="Analytics" />
        <div className="w-full flex justify-between rounded-xl items-center mt-7">
          <select className="p-4 rounded-lg drop-shadow-sm bg-white w-44 outline-none font-light text-[#2E4765]">
            <option>All chatbots</option>
            <option>All chatbots 2</option>
            <option>All chatbots 3</option>
          </select>
          <div className="relative">
            <span className="inline-block mr-4 font-semibold">Create Bot</span>
            <button className=" bg-white drop-shadow-sm rounded-xl p-4 text-left font-light text-[#2E4765] pr-9">
              January 6, 2023 - January 6, 2023
              <img
                className="cursor-pointer absolute top-[20px] right-[10px] w-4"
                src={require("../../img/add-icon.png")}
              />
            </button>{" "}
          </div>
        </div>

        <div className="goalsTray py-4 bg-white mt-4 rounded-2xl mb-5">
          <h5 className="text-xl font-medium text-[#2E4765] px-4">Goals Tray</h5>
          <h6 className="text-[#2E4765] font-medium text-base px-4">Bot Flow Summary</h6>
          <div className="goalsTryRow block align-center my-5 px-4 sm:flex">
            <div className="goalsTryCol sm:w-11/12 w-full">
              <p className="text-xs font-light mb-2">Interactions Started</p>
              <input type="text" className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25" />
            </div>
            <div className="goalsTryCol sm:w-11/12 w-full">
              <p className="text-xs font-light mb-2">Completed Intractions</p>
              <input type="text" className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25" />
            </div>
            <div className="goalsTryCol sm:w-11/12 w-full">
              <p className="text-xs font-light mb-2">Completion Percentage</p>
              <input type="text" className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25" />
            </div>
          </div>
          <div className="py-5 border-t-2 px-5">
            <div className="goalsTryCol sm:w-2/6  w-full">
              <p className="text-xs font-light mb-2">Completion Comparison</p>
              <input type="text" className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25" />
            </div>
          </div>
        </div>

        <div className="goalsTray py-4 bg-white mt-4 rounded-2xl mb-5">
          <h6 className="text-[#2E4765] font-medium text-base px-4">Feedback Received</h6>
          {/* <img src={require('../../img/noDataFound.png')} className="p-5" /> */}
          <Chart
            chartType="PieChart"
            data={data}
            // options={options}
            width={"100%"}
            height={"400px"}
          />
        </div>

        <Pagignation />
      </div>
    </>
  );
};

export default Analytics;
