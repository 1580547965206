import React , {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import createBotSlice from '../../features/createBot/createBotSlice'
import { createBot } from '../../utilities/chatbotMethods'
import GetBotName from './GetBotName'
import GetBotSubType from './GetBotSubType'
import GetBotType from './GetBotType'
import botList from './../../data/en.json'
import { NotificationManager } from 'react-notifications'

function Builder() {

    const [step , setStep] = useState('name')
    const [name , setName] = useState('')
    const [errorName , setErrorName] = useState('')
    const [type , setType] = useState(36)
    const [subType , setSubType] = useState('')


    const navigate  = useNavigate()

    const handleLoginOrPayment = () => {

            sessionStorage.setItem('botName' , name)
            sessionStorage.setItem('botType' , type)
            sessionStorage.setItem('botSubType' , subType)
            const newBotId = 'BOTID' + new Date().getTime()
            localStorage.setItem('botId' ,newBotId)


        if(localStorage.getItem('tablealias')){
            //send to payment
            // navigate('/subscribe')

            // create BOT ()
            const foundBot = botList.botList.find(botObj => botObj.id===type)
           
            const newBotType = foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-')
            localStorage.setItem('bot_type' , newBotType)
            navigate('/subscribe')
            // createBot(name , type).then((result) => {
            //     if(result){
            //         navigate(`/cx/Chatbot/${newBotType}/editor?did=${newBotId}`)
            //     }
            //     else{
            //         NotificationManager.error('Bot Creation Failed' , 'Please try again in few minutes.' , 4000)
            //     }
            // })

        }
        else{
            sessionStorage.setItem('loginSource' , '/subscribe')
            navigate('/login')
        }
    }

    //hanlder function
    const handleNext = () => {
        if(step === 'name'){
            if(name === ''){
                setErrorName('Name cannot be empty')
            }
            else{
            setStep('type')
            }
        }
        else if(step === 'type'){
            
            if([].includes(type)){
                setStep('subtype')
                
            }
            else{
                //send to login or payment
                handleLoginOrPayment() 
            }
        }
        else if(step === 'subtype'){
                        //send to login or payment
                        handleLoginOrPayment() 

        }
    }

    //hanlder function
    const handlePrevious = () => {
        if(step === 'name'){
            //send to dashboard
        }
        else if(step === 'type'){
            setType('')
            setStep('name')
        }
        else if(step === 'subtype'){
            setSubType('')
            setStep('type')
        }
    }

  return (
    <div className='pl-[76px] lg:pl-[276px] p-5'>
         
        {step === 'name'&& <GetBotName name  = {name} setName = {setName} errorName = {errorName} setErrorName = {setErrorName}/>}
        {step === 'type'&& <GetBotType type = {type} setType = {setType}/>}
        {step === 'subtype'&& <GetBotSubType subType = {subType} setSubType = {setSubType}/>}

        
        <button onClick={handlePrevious}>previous</button>
        <br />
        <button onClick={handleNext}>next</button>

       
       
    </div>
  )
}

export default Builder