import { db } from "../../config/firebase";
import { addParentNodeInTemplate } from "../../utilities/convertTemplateToFlowChart";
import { updateTemplate } from "./botTemplateSlice";

//getting data from firebase
export const getBotFlowChartDataAsyc = async (botId,thunkAPI) => {
return new Promise((resolve, reject) => {
    db
        .collection("user_" + localStorage.getItem("tablealias"))
        .doc(localStorage.getItem("agent_id"))
        .collection("chatbot")
        .doc(botId)
        .get().then((result) => {
            console.log(result.data())
            const response = result.data()
            const template = {
                template_One : addParentNodeInTemplate(response.template),
                chatBotAvtars : response.chatBotAvtars,
                widgetImages : response.widgetImages
            }
            thunkAPI.dispatch(updateTemplate(template))
            resolve()
        })
}) 

  
}