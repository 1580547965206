import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import { addBranch, deleteNode, getIdForNewNode } from '../../utilities/convertTemplateToFlowChart';
import { initialDataOfNode } from '../../data/node/nodeData';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';

function Button(props) {



    const selectStatusOption = [
        { label: 'Single Selection', value: 'single' },
        { label: 'Multiple Selection', value: 'multiple' }
      ]

 //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }
  
  
  
     //initializing dispatch
     const dispatch = useDispatch()
  
     //destructuring props
     const {selectedNode , template} = props
  
     //getting flow from the template
     const flow = template.template_One
  
     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)

     


 //LOCAL STATES

 const optionPlaceholderString = selectedNode.data.data.optionPlaceholder ? selectedNode.data.data.optionPlaceholder : 'Skip this question'
 const optionSuggestionString = selectedNode.data.data.optionSuggestion ? selectedNode.data.data.optionSuggestion : 'You can skip this question by clicking on '
 const tipIcon = selectedNode.data.data.tipIcon ? selectedNode.data.data.tipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f'
 const skipIcon = selectedNode.data.data.skipIcon ? selectedNode.data.data.skipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556'
 const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text
 const selectionOptionTextString = selectedNode.data.data.selectionOption ? selectedNode.data.data.selectionOption :'Submit selected options'

 //LOCAL STATE
 //initial state of rich text editor
 const [botMessageText , setBotMessageText] = useState(getInitialStateForEditor(selectedNode.data.data.text))
 const [optionPlaceholder , setOptionPlaceholder] = useState(getInitialStateForEditor(optionPlaceholderString))
 const [optionSuggestion , setOptionSuggestion] = useState(getInitialStateForEditor(optionSuggestionString))
 const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
 const [isOptional , setIsOptional] = useState(selectedNode.data.data.isOptional ? selectedNode.data.data.isOptional : false )
 const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)
 const [isBranching , setIsBranching] = useState(selectedNode.data.data.isBranching)
 const [selectedType ,  setSelectedType] = useState(selectedNode.data.data.selected_type ? selectedNode.data.data.selected_type: selectStatusOption[0])
 const [selectionOption , setSelectionOption] = useState(getInitialStateForEditor(selectionOptionTextString))
 const [trigger , setTrigger] = useState(selectedNode.data.trigger)
 const [draftTemplate , setDraftTemplate] = useState(template)
 
 



 //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }
  


 //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                ...selectedNode.data.data,
                isBranching,
                isOptional,
                optionPlaceholder : getHtmlFromEditorState(optionPlaceholder),
                optionSuggestion : getHtmlFromEditorState(optionSuggestion),
                text : getHtmlFromEditorState(botMessageText),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff),
                tipIcon,
                skipIcon,
                selected_type : selectedType,
                selectionOption : getHtmlFromEditorState(selectionOption)
            },
            trigger
            
        }


        //creating new flow
        const newFlow = {
            ...draftTemplate.template_One,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...draftTemplate,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }


     //callback function
     const isBranchingChange = (event) => {
        setIsBranching(event.target.checked)
        if(event.target.checked){
            setIsOptional(false)
            //adding end message node
            const lengthOfTriggerArray = trigger.length
            const listOfNewId = getIdForNewNode(draftTemplate , lengthOfTriggerArray-1)
            console.log(listOfNewId , ' 4') //for debugging purpose
            const newTrigger = trigger.map((item,index) => {
                if(index === 0){
                    return item
                }
                else{
                    return {
                        ...item,
                        next : Number(listOfNewId[index-1])
                    }
                }
            }) 
            setTrigger(newTrigger)

            let newFlow = draftTemplate.template_One

            newFlow = {
                ...newFlow,
                [nodeId] : {
                    ...newFlow[nodeId],
                    trigger : newTrigger
                }
            }

            const initialDataOfEndMessageNode = initialDataOfNode[100]

            for(var i=1;i<newTrigger.length;i++){
                newFlow = {
                    ...newFlow,
                    [newTrigger[i].next] : {
                        ...initialDataOfEndMessageNode,
                        parentNode : nodeId
                    }
                }
            }

            const newTemplate = {
                ...draftTemplate,
                template_One : newFlow
            }

            setDraftTemplate(newTemplate)


        }
        else{
            console.log(trigger , ' 1')
            const firstBranchNodeId = Number(trigger[0].next)
            console.log(firstBranchNodeId , ' 2')
            const newTrigger = trigger.map((item) => {
                return {
                    ...item,
                    next : firstBranchNodeId
                }
            })
            console.log(newTrigger , ' 3')
            setTrigger(newTrigger)
        }
       
     }



     const handleAddButton = () => {

        const listOfNewId = getIdForNewNode(draftTemplate , 1)
        const initialDataOfEndMessageNode = initialDataOfNode[100]

        const newTrigger = [...trigger , {
            id : '',
            name : 'Button',
            next : listOfNewId[0]
        }]

        setTrigger(newTrigger)

        let newFlow = draftTemplate.template_One
        newFlow = {
            ...newFlow,
            [nodeId] : {
                ...newFlow[nodeId],
                trigger : newTrigger
            },
            [listOfNewId] : {
                ...initialDataOfEndMessageNode,
                parentNode : nodeId
            }
        }

        const newTemplate = {
            ...draftTemplate,
            template_One : newFlow
        }

        setDraftTemplate(newTemplate)

        

     }

     const handleDeleteButton = (indexToDelete) => {

        if(trigger.length <= 1){
            return
        }
       
        const newTrigger = trigger.filter((item,index) => {
            if(index !== indexToDelete){
                return item
        }})

        setTrigger(newTrigger)
        
        let newFlow = draftTemplate.template_One

        newFlow = {
            ...newFlow,
            [nodeId] : {
                ...newFlow[nodeId],
                trigger : newTrigger
            }
        }

        const newTemplate = {
            ...draftTemplate,
            template_One : newFlow
        }

        setDraftTemplate(newTemplate)


    
     }


     //handler function
     const onButtonNameChange = (event,indexToChange) => {
        const value = event.target.value 

        const newTrigger = trigger.map((item,index) => {
            if(index === indexToChange){
                return {
                    ...item,
                    name  : value
                }
            }
            else{
                return item
            }
        })

        setTrigger(newTrigger)
     }

  
     

  return (
   <>
      <div className="mb-4 px-3">
        <h5 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot Message</h5>
        <RichTextEditor editorState = {botMessageText} setEditorState = {setBotMessageText} selectedNode = {selectedNode}/>
      </div>
      <div className="mb-4 px-3">
        <h5 className='mb-2 text-[#5C5C5C] text-sm'>Buttons</h5>
        {trigger.map((item,index) => {
            return <div key={index} className='flex items-center mb-3'>
            <input onChange={(event) => onButtonNameChange(event,index) } value = {item.name} type='text' className='w-9/12 border-[1px] focus:outline-none h-8 rounded px-2 text-xs' />
            <span className='ml-auto w-8 h-8 bg-thmBG rounded flex items-center justify-center cursor-pointer'>
                <svg onClick={() => handleDeleteButton(index)} xmlns="http://www.w3.org/2000/svg" width="11.703" height="15.048" viewBox="0 0 11.703 15.048">
                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.336,17.876a1.677,1.677,0,0,0,1.672,1.672H16.7a1.677,1.677,0,0,0,1.672-1.672V7.844H8.336ZM19.2,5.336H16.277L15.442,4.5h-4.18l-.836.836H7.5V7.008H19.2Z" transform="translate(-7.5 -4.5)" fill="#fff"/>
                </svg>
            </span>
        </div>
        })}
      </div>

        <a onClick={handleAddButton} class="edgebutton pulse-animation m-auto my-3" type="button">+</a>

        <div className='px-3'>
            <input onChange={(event) => isBranchingChange(event)} checked = {isBranching} type='checkbox' id='check1' /> 
            <label for="check1" className='cursor-pointer text-sm text-[#5C5C5C] pl-2'>Branching</label> 
        </div>
        {!isBranching && <div className='px-3'>
            <input onChange={(event) => setIsOptional(event.target.checked)} checked = {isOptional} type='checkbox' id='check2' /> 
            <label for="check2" className='cursor-pointer text-sm text-[#5C5C5C] pl-2'>Optional</label> 
        </div>}
        {!isBranching && isOptional && <><div className="mb-4 px-3">
        <h5 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Option skipping suggestion text</h5>
        <RichTextEditor editorState = {optionSuggestion} setEditorState = {setOptionSuggestion} selectedNode = {selectedNode}/>
      </div>
      <div className="mb-4 px-3">
        <h5 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Option skipping text placeholder</h5>
        <RichTextEditor editorState = {optionPlaceholder} setEditorState = {setOptionPlaceholder} selectedNode = {selectedNode}/>
      </div></>}
        <div className='px-3'>
            <input onChange={(event) => setShortHand(event.target.checked)} checked = {shortHand} type='checkbox' id='check3' /> 
            <label for="check2" className='cursor-pointer text-sm text-[#5C5C5C] pl-2'>Shorten for Handoffs</label> 
        </div>
        {shortHand && <div className="mb-4 px-3">
        <h5 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Text for Handoffs</h5>
        <RichTextEditor editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode}/>
      </div>}
       
      <div className="editorBtn px-3 py-3">
        <button onClick={handleSave} className="border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded bg-indigo-600 text-white">Save as draft</button>
        <button onClick={handleCancel} className="group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">Cancel</button>
     </div>
    </>

  )

}

export default Button