import React ,  {ReactDOM} from 'react'
import DropDownList from './DropDownList'


function DropDown({botId , selected , setSelected}) {
  return (
    <div className="dropdown inline-block relative" id={botId}>
    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      onMouseOver={() => setSelected(botId)}
                    >
                      <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                    </svg>

       {/* check index of map and current*/}
    {selected === botId && <DropDownList edit = {true} share = {true} duplicate = {true} delete = {true}/>}
    </div>
  )
}

export default DropDown


{/* <ul className="dropdown-list">
                      <li>
                        <a className="rounded-t border-b py-1 px-4 block whitespace-no-wrap" href="#">
                          Edit
                        </a>
                      </li>
                      <li>
                        <a className="py-1 px-4 border-b block whitespace-no-wrap" href="#">
                          Share
                        </a>
                      </li>
                      <li>
                        <a className="rounded-b border-b py-1 px-4 block whitespace-no-wrap" href="#">
                          Duplicate
                        </a>
                      </li>
                      <li>
                        <a className="rounded-b py-1 px-4 block whitespace-no-wrap" href="#">
                          Delete
                        </a>
                      </li>
    </ul> */}