import React, { useState ,useEffect} from "react";
import { useSelector ,useDispatch  } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SideMenu from "../../../Component/sidemenu";
import TestButton from "../../../Component/TestButton";
import EditorDesign from "./EditoeDesign";
import EditorView from "./EditorView";
import { ReactFlowProvider } from "reactflow";
import Botpreview from "../../../frontBot/ServerTimestamp";
import { getBotFlowChartData, resetTemplate, updateNodeMenuPosition, updateTemplate } from "../../../features/botTemplate/botTemplateSlice";
import DesignCustomization from "../DesignCustomization";
import Botpersonality from "../Botpersonality";
import Trainbot from "../Trainbot";
import Integration from "../Integration";
import Setup from "../Setup";
import { getDashboardData } from "../../../features/userBots/userBotsSlice";
import { getAgentData } from "../../../features/agent/agentSlice";
import { getInstalledIntegrations } from "../../../features/integraion/integrationSlice";
import Traingpt from "../Traingpt";
import { trainPCNew , updateGptData , trainText , deletePC,updateTemplateinDB  } from "../../../utilities/chatbotMethods";
import { NotificationManager } from "react-notifications";
import { setGptDataGlobal , setGptLoader } from "../../../features/trainGpt/trainGptSlice";
import { setAllowSave,setShowPreview } from "../../../features/globalSave/globalSaveSlice";
import strings from './../../../data/en.json'


const Editor =()=>{


    const activeCss = "inline-block cursor-pointer rounded-t-lg hover:bg-white bg-white"
    const inactiveCss = "inline-block cursor-pointer rounded-t-lg hover:bg-white"

    //initializing the navigate
    const navigate = useNavigate()


    //getting data from the global state
    //state -- botTemplate.selectedNode
    const {selectedNode , template , isLoading} = useSelector(state => state.botTemplate)
    const {installedIntegrations} = useSelector(state => state.integration)
    const {allowSave,showPreview} = useSelector(state => state.globalSave)
    const {gptDataGlobal} = useSelector(state => state.trainGpt)
    const {userBots}=useSelector(state=>state.userBots)



    // const botDetails = useSelector(state => state.userBots.userBots[localStorage.getItem("botId")])

    //local state
    //to decide whether to show the editor page or design page
    const [botPage, setBotPage] = useState('editor')
    const [nameEdit , setNameEdit] = useState(false)
    const [botName , setBotName] = useState()
    const [botDetails , setBotDetails] = useState()


    // const [isLoader , setIsLoader] = useState(true)



    // const [viewEditor, setVewEditor] = useState(false)

    //initialzing the dispatch
    const dispatch = useDispatch()

    //for mounting
    useEffect(() => {


        if(!userBots){
          dispatch(getDashboardData())
          dispatch(getAgentData())
        }else{
        const urlParams = new URLSearchParams(window.location.search)
        const botId = urlParams.get('did')

        
       

        if(localStorage.getItem('botId')!=botId){
          // set DETAILS FOR URL BOTID
          localStorage.setItem('botId',botId)
          console.log(userBots,"userBots",botId)
          var dashData=Object.keys(userBots).includes(botId)?userBots[botId]:null
          if(dashData){
            const foundBot = strings.botList.find(obj => obj.id==dashData.bot_template_category)
      
            // console.log(foundBot , 'fb')
            const newBotType = foundBot? foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):dashData.type.toLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-')
            localStorage.setItem("botType",dashData.bot_template_category);
            localStorage.setItem("botId", botId);
            localStorage.setItem('bot_type' , newBotType)
            localStorage.setItem('BOTNAME',dashData.bot_name)
            setBotDetails(dashData)
            dispatch(getBotFlowChartData(botId))
            dispatch(getInstalledIntegrations())
          }else{
            navigate("/cx/Chatbot")
          }
      

        }else{
          localStorage.setItem('botId',botId)
          console.log(userBots,"userBots",botId)
          var dashData=Object.keys(userBots).includes(botId)?userBots[botId]:null
          if(dashData ){
            const foundBot = strings.botList.find(obj => obj.id==dashData.bot_template_category)
      
            // console.log(foundBot , 'fb')
            const newBotType = foundBot? foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):dashData.type.toLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-')
            localStorage.setItem("botType",dashData.bot_template_category);
            localStorage.setItem("botId", botId);
            localStorage.setItem('bot_type' , newBotType)
            localStorage.setItem('BOTNAME',dashData.bot_name)
            setBotDetails(dashData)
            dispatch(getBotFlowChartData(botId))
            dispatch(getInstalledIntegrations())
          }
        }
        

      }

    },[userBots])


    useEffect(() => {
        const keywords = ['botPersonality', 'trainbot', 'traingpt', 'editor', 'customisation', 'integration', 'setup'];
        const currentHref = window.location.href;
        console.log(currentHref , ' chref')
        for (const keyword of keywords) {
            if (currentHref.includes(keyword)) {
                setBotPage(keyword);
                break; // Once a match is found, exit the loop
            }
        }
    }, [navigate])

    


    //for unmounting
    useEffect(() => {
        return () => {
            dispatch(resetTemplate())
        }
    },[])

    

    const handleLinkClick = (e) => {

        
        navigate(`/cx/Chatbot/${localStorage.getItem("bot_type")}/${e}?did=${localStorage.getItem("botId")}`)
    }

    const handleSave = () => {
        if(!allowSave){
            return
        }
        if(window.location.href.includes('traingpt')){
            trainPCData()
            

        }else if (window.location.href.includes('editor')||window.location.href.includes('customisation')){
           saveTemplate()
        }
    }

    const saveTemplate = () => {
      updateTemplateinDB(template.template_One,localStorage.getItem('BOTNAME')).then(status=>{
        if(status){
          NotificationManager.success('Template Saved', '', 3000)
          // this.props.saveNextDisabled(false)
          dispatch(setAllowSave(false))
        }else{
          NotificationManager.error('Template Saving Failed', '', 3000)
        }
      })
    }

    const trainPCData= async () => {
        console.log(gptDataGlobal,"DECIDE HERE IF ADD DELETE")
        var {addList,delList,pcTextEdit}=compareGpt()
        var {addUrlList,delUrlList}=compareGptUrl()
        console.log(addList,delList,"addList,delList",pcTextEdit)
      var loaders=0;
      if(addList.length>0||delList.length>0||pcTextEdit||addUrlList.length>0||delUrlList.length>0){
        // this.props.setPCLoader(true)
        // console.log("SET PC LOADER TRUE",this.props.qaSources)
        // this.setState({})
        updateGptData(gptDataGlobal,{},"init").then(()=>{
    
        
      if(addList.length>0){
        // this.props.setPCLoader(true)
        dispatch(setGptLoader(true))
        loaders=loaders+1
        trainPCNew(addList,"file")
        .then((res)=>{
          console.log(res,"resPCPCPCPCPCPC")
          if(res.length>0){
          updateGptData(gptDataGlobal,res,"add","file").then((newData)=>{
            console.log(newData,"newData")
            // this.props.saveGptData(newData)
            // var gptData = {...gptDataGlobal};
            newData["initFiles"]=newData["import_files"].slice()
           dispatch(setGptDataGlobal(newData))
            
            console.log(gptDataGlobal,"GPTAFTER TRAIN FILE")
            // stopLoader("trainGpt")
            setTimeout(() => {
              loaders=loaders-1
              if (loaders == 0) {
                // this.props.showPreviewMethod(true)

                // this.props.setPCLoader(false)
                dispatch(setGptLoader(false))
                dispatch(setShowPreview(true))
              }
            },2000)
            })
          NotificationManager.success('Adding Completed', '', 3000)
          }else{
            NotificationManager.error('Adding Failed', 'Please try again in a few minutes.', 4000)
            setTimeout(() => {
              loaders=loaders-1
              if (loaders == 0) {
                // this.props.showPreviewMethod(true)
                // this.props.setPCLoader(false)
                dispatch(setGptLoader(false))
                dispatch(setShowPreview(true))
              }
            },2000)
          }
          // stopLoader("trainGpt")
        //   this.props.saveNextDisabled(false)
        dispatch(setAllowSave(false))
    
        })
      }
      if(delList.length>0){
        // this.props.setPCLoader(true)
        dispatch(setGptLoader(true))
        loaders=loaders+1
        // startLoader("delGpt")
        // editLoader("Training your GPT input...")
        
        // stopLoader("delGpt")
        // removeTrainGptData(localStorage.getItem("tablealias")+"-"+localStorage.getItem('bot_id_new'),addList).
        deletePC(localStorage.getItem('bot_id_new'),delList).
        then((res)=>{
          // stopLoader("delGpt")
          NotificationManager.success('Deleting Completed', '', 3000)
          updateGptData(gptDataGlobal,delList,"delete","file")
    
         var gptData = {...gptDataGlobal};
          gptData["initFiles"]=gptData["import_files"].slice()
        //   this.props.saveGptData(gptData)
        dispatch(setGptDataGlobal(gptData))
          setTimeout(() => {
            loaders=loaders-1
            if (loaders == 0) {
            //   this.props.showPreviewMethod(true)
            //   this.props.setPCLoader(false)
            dispatch(setGptLoader(false))
            dispatch(setShowPreview(true))
            }
          },2000)
        //   this.props.saveNextDisabled(false)
        dispatch(setAllowSave(false))
    
        })
      }
      
      if(pcTextEdit){
        // this.props.setPCLoader(true)
        dispatch(setGptLoader(true))
        loaders=loaders+1
        
        // startLoader("trainText")
        // editLoader("Training your Text input...")
        // removeTrainGptData(localStorage.getItem("tablealias")+"-"+localStorage.getItem('bot_id_new'),addList).
        
        deletePC(localStorage.getItem('botId'),[{name:"pcText"}]).
        then((res)=>{
          
          updateGptData(gptDataGlobal,"","delete","text")
          
    
    
          trainText(gptDataGlobal.pcText,"pcText")
          .then((res)=>{
            if(res.status){
             var gptData = {...gptDataGlobal};
              gptData["initText"]=gptData["pcText"]
            //   this.props.saveGptData(gptData)
            dispatch(setGptDataGlobal(gptData))
              NotificationManager.success('Text Training Completed', '', 3000)
              setTimeout(() => {
                loaders=loaders-1
                if (loaders == 0) {
                //   this.props.showPreviewMethod(true)
                //   this.props.setPCLoader(false)
                dispatch(setGptLoader(false))
                dispatch(setShowPreview(true))
                }
              },2000)
          
          
            updateGptData(gptDataGlobal,"","add","text")
          
            }else{
              NotificationManager.error('Text Training Failed', '', 3000)
              setTimeout(() => {
                loaders=loaders-1
                if (loaders == 0) {
                //   this.props.showPreviewMethod(true)
                //   this.props.setPCLoader(false)
                dispatch(setGptLoader(false))
                dispatch(setShowPreview(true))
                }
              },2000)
            }
            // stopLoader("trainText")
            // this.props.saveNextDisabled(false)
            dispatch(setAllowSave(false))
    
        })
          
    
        })
        
      }
    
      if(addUrlList.length>0){
        // startLoader("trainGpt")
        // editLoader("Training your GPT input...")
        // this.props.setPCLoader(true)
        dispatch(setGptLoader(true))
        loaders=loaders+1
    
        
        // trainGptData(localStorage.getItem("tablealias")+"-"+localStorage.getItem('bot_id_new'),addList).
    
        // const loader = new PDFLoader(addList[0].url, {
        //   splitPages: false,
        // });
        
        // const docs = await loader.load()
        trainPCNew(addUrlList,"url")
        .then((res)=>{
          console.log(res,"resPCPCPCPCPCPC")
          if(res.length>0){
          // updateGptData(localStorage.getItem('bot_id_new'),gptDataGlobal)
          updateGptData(gptDataGlobal,res,"add","url").then((newData)=>{
          //  var gptData = {...gptDataGlobal};
           newData["initUrls"]=newData["import_urls"].slice()
            // this.props.saveGptData(newData)
            dispatch(setGptDataGlobal(newData))
            console.log(gptDataGlobal,"newData after train urk")
            setTimeout(() => {
              loaders=loaders-1
              if (loaders == 0) {
                // this.props.showPreviewMethod(true)
                // this.props.setPCLoader(false)
                dispatch(setGptLoader(false))
                dispatch(setShowPreview(true))
              }
            },2000)
            // this.props.saveGptData(newData)
            // stopLoader("trainGpt")
            })
          
          NotificationManager.success('Adding Completed', '', 3000)
          }else{
            setTimeout(() => {
              loaders=loaders-1
              if (loaders == 0) {
                // this.props.setPCLoader(false)
                // this.props.showPreviewMethod(true)
                dispatch(setGptLoader(false))
                dispatch(setShowPreview(true))
              }
            },2000)
            NotificationManager.error('Adding Failed', 'Please try again in a few minutes.', 4000)
          }
        //   stopLoader("trainGpt")
        //   this.props.saveNextDisabled(false)
        dispatch(setAllowSave(false))
        })
      }
      if(delUrlList.length>0){
        // this.props.setPCLoader(true)
        dispatch(setGptLoader(true))
        loaders=loaders+1
        // startLoader("delGpt")
        // editLoader("Training your GPT input...")
        
        // stopLoader("delGpt")
        // removeTrainGptData(localStorage.getItem("tablealias")+"-"+localStorage.getItem('bot_id_new'),addList).
        deletePC(localStorage.getItem('botId'),delUrlList).
        then((res)=>{
          var gptData = {...gptDataGlobal};
          gptData["initUrls"]=gptData["import_urls"].slice()
          this.props.saveGptData(gptData)
        //   stopLoader("delGpt")
          setTimeout(() => {
            loaders=loaders-1
            if (loaders == 0) {
            //   this.props.showPreviewMethod(true)
            //   this.props.setPCLoader(false)
            dispatch(setGptLoader(false))
            dispatch(setShowPreview(true))
            }
          },2000)
          NotificationManager.success('Deleting Completed', '', 3000)
          updateGptData(gptDataGlobal,delUrlList,"delete","url")
        //   this.props.saveNextDisabled(false)
        dispatch(setAllowSave(false))
    
        })
      }
    
    
    
    
    
      })
    }
    
      }


      const compareGpt=()=>{

        // const addurl = gptDataGlobal.import_urls.filter(item => !gptDataGlobal.initUrls.includes(item));
        // const delurl = gptDataGlobal.initUrls.filter(item => !gptDataGlobal.import_urls.includes(item));
      
        // console.log('Additions:', addurl);
        // console.log('Deletions:', delurl);
      
      
      
          const addfile = gptDataGlobal.import_files.filter(item => item.tokens==undefined);
          const delfile = gptDataGlobal.initFiles.filter(item => !gptDataGlobal.import_files.some(modifiedItem => modifiedItem.name === item.name));
      
          console.log('Additions:', addfile);
          console.log('Deletions:', delfile);
      
          var addList=addfile.map(item => ({
            name: item.name,
            url: item.url,
          }))
          // .concat(addurl.map(item => ({
          //   name: item.split('/').pop(),
          //   url: item
          // })))
          var delList=delfile.map(item => ({
            name: item.name,
            url: item.url
          }))
          // .concat(delurl.map(item => ({
          //   name: item.split('/').pop(),
          //   url: item
          // })))
          console.log(JSON.stringify(addList),JSON.stringify(delList))
          var pcTextEdit=false
          if(gptDataGlobal.initText!=gptDataGlobal.pcText){
            pcTextEdit=true
          }
          return {addList,delList,pcTextEdit}
        }
        const compareGptUrl=()=>{
      
          const addurl = gptDataGlobal.import_urls.filter( item => item.tokens==undefined);
          const delurl = gptDataGlobal.initUrls.filter(item => !gptDataGlobal.import_urls.includes(item));
        
          console.log('Additions:', addurl);
          console.log('Deletions:', delurl);
        
        
        
            // const addfile = gptDataGlobal.import_files.filter(item => item.tokens==undefined);
            // const delfile = gptDataGlobal.initFiles.filter(item => !gptDataGlobal.import_files.some(modifiedItem => modifiedItem.name === item.name));
        
            // console.log('Additions:', addfile);
            // console.log('Deletions:', delfile);
        
            var addUrlList=addurl.map(item => ({
              name: item.url,
              url: item.url
            }))
            var delUrlList=delurl.map(item => ({
              name: item.url,
              url: item.url
            }))
            console.log(JSON.stringify(addUrlList),JSON.stringify(delUrlList))
            return {addUrlList,delUrlList}
          }
    return(
        <>
        {/* <SideMenu /> */}

            <div className="pl-[76px] lg:pl-[276px] p-5">
                <div className="drop-shadow-sm bg-white w-full flex justify-between p-4 rounded-xl items-center">
                    <div className="text-black font-semibold text-sm flex items-center">
                        <span onClick={()=>{navigate("/cx/Chatbot")}}className="boatBack bg-[#F5F6FA] w-6 h-6 rounded-full flex items-center justify-center mr-2 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="6.41" height="11.213" viewBox="0 0 6.41 11.213">
                                <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.183,11.8l4.243-4.239a.8.8,0,0,0-1.135-1.132l-4.807,4.8a.8.8,0,0,0-.023,1.1l4.827,4.837a.8.8,0,0,0,1.135-1.132Z" transform="translate(-11.251 -6.194)" fill="#5269c9" />
                            </svg>
                        </span>
                        {!nameEdit && <span className="boatName max-w-xs">
                            <p className="truncate text-sm font-semibold "> {botDetails && localStorage.getItem("BOTNAME")} </p>
                        </span>}
                        <span onClick={() => setNameEdit(true)} className="boatEdit flex items-center justify-center w-7 h-7 rounded-md border border-[#C6C6C6] ml-3 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.213" height="11.213" viewBox="0 0 11.213 11.213">
                                <path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,13.373v2.336H6.836L13.724,8.82,11.389,6.485ZM15.531,7.014a.62.62,0,0,0,0-.878L14.073,4.678a.62.62,0,0,0-.878,0l-1.14,1.14,2.336,2.336Z" transform="translate(-4.5 -4.496)" fill="#5269c9" />
                            </svg>
                        </span>
                    </div>
                    <div className="flex gap-3 items-center">
                        <button className="group flex items-center border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">
                            Share
                            <span className="inline-block pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.439" height="11.76" viewBox="0 0 13.439 11.76">
                                    <path id="Icon_open-share-boxed" data-name="Icon open-share-boxed" d="M1.26,0A1.269,1.269,0,0,0,0,1.26V10.5a1.269,1.269,0,0,0,1.26,1.26H8.82a1.269,1.269,0,0,0,1.26-1.26V8.4H8.4v1.68H1.68V1.68H5.04V0Zm8.82,0V1.68A6.673,6.673,0,0,0,3.461,7.61,3.336,3.336,0,0,1,6.72,5.04h3.36V6.72l3.36-3.36Z" fill="#4e4e4e" className="group-hover:fill-white" />
                                </svg>
                            </span>    
                        </button>
                        <span className=" border-r-[1px]">&nbsp;</span>
                        {allowSave?
                        <button onClick={()=>{handleSave()}} className="flex items-center border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white ">
                            Save
                            <span className="inline-block pl-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.651" height="10.651" viewBox="0 0 10.651 10.651">
                                    <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M10.316,4.578,8.322,2.584a1.141,1.141,0,0,0-.807-.334H1.141A1.141,1.141,0,0,0,0,3.391v8.368A1.141,1.141,0,0,0,1.141,12.9H9.509a1.141,1.141,0,0,0,1.141-1.141V5.385a1.141,1.141,0,0,0-.334-.807Zm-4.991,6.8A1.522,1.522,0,1,1,6.847,9.858,1.522,1.522,0,0,1,5.325,11.379ZM7.608,4.14v2.39a.285.285,0,0,1-.285.285H1.807a.285.285,0,0,1-.285-.285V4.057a.285.285,0,0,1,.285-.285H7.24a.285.285,0,0,1,.2.084l.083.083A.285.285,0,0,1,7.608,4.14Z" transform="translate(0 -2.25)" fill="#fff" />
                                </svg>
                            </span>
                        </button> :
                        <span className="flex items-center border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-grey-600 text-red ">
                        Save
                        <span className="inline-block pl-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10.651" height="10.651" viewBox="0 0 10.651 10.651">
                                <path id="Icon_awesome-save" data-name="Icon awesome-save" d="M10.316,4.578,8.322,2.584a1.141,1.141,0,0,0-.807-.334H1.141A1.141,1.141,0,0,0,0,3.391v8.368A1.141,1.141,0,0,0,1.141,12.9H9.509a1.141,1.141,0,0,0,1.141-1.141V5.385a1.141,1.141,0,0,0-.334-.807Zm-4.991,6.8A1.522,1.522,0,1,1,6.847,9.858,1.522,1.522,0,0,1,5.325,11.379ZM7.608,4.14v2.39a.285.285,0,0,1-.285.285H1.807a.285.285,0,0,1-.285-.285V4.057a.285.285,0,0,1,.285-.285H7.24a.285.285,0,0,1,.2.084l.083.083A.285.285,0,0,1,7.608,4.14Z" transform="translate(0 -2.25)" fill="#fff" />
                            </svg>
                        </span>
                    </span>
                        }
                    </div>
                </div>
             {/* End of Header Section  */}
                {/* <div className="botTabs mt-5 text-sm px-5">
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white"><Link className="px-4 py-2 inline-block" to="/botpersonality">Bot personality</Link></span>
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white"><span onClick={() => {handleLinkClick('trainbot')}} className="px-4 py-2 inline-block">Train bot</span></span>
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white bg-white font-medium"><Link className="px-4 py-2 inline-block" to="/editor">Editor</Link></span>
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white px-4 py-2" onClick={() => setShowDesignPage(true)}>Design Customization</span>
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white"><Link className="px-4 py-2 inline-block">Design Customization</Link></span>
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white"><Link className="px-4 py-2 inline-block" to="/integration">Integration</Link></span>
                    <span className="inline-block cursor-pointer rounded-t-lg hover:bg-white"><Link className="px-4 py-2 inline-block" to="/setup">Setup</Link></span>
                </div>  */}
                <div className="botTabs mt-5 text-sm px-5">
                    {localStorage.getItem('botType') === '5' && <span className={botPage === 'botPersonality' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('botPersonality')}} className="px-4 py-2 inline-block">Bot Personality</span></span>}
                    {localStorage.getItem('botType') === '5' && <span className={botPage === 'trainbot' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('trainbot')}} className="px-4 py-2 inline-block">Train bot</span></span>}
                    {localStorage.getItem('botType') === '36' && <span className={botPage === 'traingpt' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('traingpt')}} className="px-4 py-2 inline-block">Train gpt</span></span>}
                    <span className={botPage === 'editor' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('editor')}} className="px-4 py-2 inline-block">Editor</span></span>
                    <span className={botPage === 'customisation' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('customisation')}} className="px-4 py-2 inline-block">Design Customisation</span></span>
                    <span className={botPage === 'integration' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('integration')}} className="px-4 py-2 inline-block">Integration</span></span>
                    <span className={botPage === 'setup' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('setup')}} className="px-4 py-2 inline-block">Setup</span></span>
                </div>
                <div className="boatEditSection p-5 bg-white rounded-xl shadow-md relative h-calc-body overflow-auto items-start">
                    {/* {showDesignPage && template &&  <DesignCustomization template = {template}/>} */}
                {botPage === 'editor' && selectedNode ?  <EditorView type="text"  selectedNode = {selectedNode}/> : null }

                {/* replace krna he */}
                {/* {botPage === 'editor' && true ?  <EditorView type="text"  selectedNode = {selectedNode}/> : null } */}
                         {/* {!showDesignPage && template && <ReactFlowProvider><EditorDesign template = {template}/></ReactFlowProvider> }   */}
                        {isLoading ? <div>Loading...</div> :
                        <>
                        {/* {console.log(botDetails , ' ghj')} */}
                         {botPage === 'botPersonality'  && <Botpersonality/>}
                         {botPage === 'trainbot'  && <Trainbot/>}
                         {botPage === 'traingpt' && <Traingpt/>}
                         {botPage === 'editor'  && <ReactFlowProvider><EditorDesign template = {template}/></ReactFlowProvider>}
                         {botPage === 'customisation'  && <DesignCustomization template = {template} botDetails = {botDetails}/>}
                         {botPage === 'integration'  && <Integration/>}
                         {botPage === 'setup'  && <Setup/>}
                         </>
                            }
                    <div className={showPreview == true ? "boatSettings absolute w-full":"boatSettings absolute w-full"} >
                        <div className="editorAll">
                        </div>
                    </div>

                    {showPreview && botPage !== 'integration' && botPage !== 'setup' ?
                    <div className="boatDisplay w-80 ml-auto absolute right-4 top-2 bg-white z-10">
                        <div className="dimensialSection flex items-center">
                            <div className="dimensialTabMobile flex bg-[#F0F0F0] rounded px-2 py-2 mr-auto">
                                <span className="desktop hover:bg-white bg-white px-3 py-2 rounded cursor-pointer ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.967" height="15.967" viewBox="0 0 15.967 15.967">
                                        <path id="Icon_ionic-md-desktop" data-name="Icon ionic-md-desktop" d="M16.7,2.25H3.771A1.525,1.525,0,0,0,2.25,3.771V13.845a1.525,1.525,0,0,0,1.521,1.521H8.713L7.192,17.076v1.14h6.083v-1.14l-1.521-1.711H16.7a1.525,1.525,0,0,0,1.521-1.521V3.771A1.525,1.525,0,0,0,16.7,2.25Zm0,10.264H3.771V3.771H16.7Z" transform="translate(-2.25 -2.25)" fill="#637ce6" />
                                    </svg>
                                </span>
                                <span className="mobile hover:bg-white px-3 py-2 rounded cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.553" height="15.284" viewBox="0 0 9.553 15.284">
                                        <path id="Icon_awesome-mobile-alt" data-name="Icon awesome-mobile-alt" d="M8.12,0H1.433A1.433,1.433,0,0,0,0,1.433V13.851a1.433,1.433,0,0,0,1.433,1.433H8.12a1.433,1.433,0,0,0,1.433-1.433V1.433A1.433,1.433,0,0,0,8.12,0ZM4.776,14.329a.955.955,0,1,1,.955-.955A.954.954,0,0,1,4.776,14.329ZM8.12,11.1a.359.359,0,0,1-.358.358H1.791a.359.359,0,0,1-.358-.358V1.791a.359.359,0,0,1,.358-.358h5.97a.359.359,0,0,1,.358.358Z" fill="#637ce6" />
                                    </svg>
                                </span>
                            </div>
                            <span className="close flex items-center justify-center bg-[#6D85EB] w-6 h-6 rounded-full cursor-pointer top-2" onClick={()=>dispatch(setShowPreview(false))}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.499" height="10.499" viewBox="0 0 10.499 10.499">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M18,8.557,16.941,7.5l-4.192,4.192L8.557,7.5,7.5,8.557l4.192,4.192L7.5,16.941,8.557,18l4.192-4.192L16.941,18,18,16.941l-4.192-4.192Z" transform="translate(-7.5 -7.5)" fill="#fff" />
                                </svg>
                            </span>
                        </div>

                        
                        {/* <div className="boatSection bg-[#F7FCFF] border border-[#D8D8D8] rounded-xl px-3 py-3 mt-4  w-80">
                            <div className="mainBoat shadow-md rounded-b-2xl">
                                <div className="boatHeader bg-[#5269C9] rounded-t-2xl px-4 py-4 flex items-center">
                                    <span className="flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-4 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                             <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                        </svg>
                                    </span>
                                   <span className="inline-block text-white font-semibold text-base"> GPT bot</span>
                                </div>
                                <div className="boatBody bg-white px-4 py-3">
                                    <h6 className="text-center pb-2 text-sm">Powered by Userbot</h6>
                                    <div className="sendMessage flex items-baseline mb-5">
                                        <div className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12">
                                            <p className="text-[#272727]  text-sm leading-6">Hi! I am businees, a friendly bot, Welcome to Appy Pie LLP.</p>
                                        </div>
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                                <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="recivedMessage flex mb-5">
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                                <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                            </svg> 
                                        </span>
                                        <div className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12">
                                            <p className="text-[#272727]  text-sm leading-6">How old are you?</p>
                                        </div>
                                    </div>
                                    <div className="sendMessage flex items-baseline mb-5">
                                        <div className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12">
                                            <p className="text-[#272727]  text-sm leading-6">Age doesn’t really apply to me.</p>
                                        </div>
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                                <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="boatFooter bg-white px-4 pb-4 rounded-b-2xl">
                                    <div className="flex items-center bg-[#F4F5FA] rounded-md px-3 py-2">
                                        <input type="text" placeholder="Type your Message" className="text-sm w-9/12 border-0 focus:outline-none bg-[#F4F5FA]" />
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-7 h-7 cursor-pointer ml-auto">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.793 12.792">
                                                <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M16.892,4.531,4.661,9.862a.28.28,0,0,0,.01.51L7.98,12.241a.533.533,0,0,0,.61-.06l6.524-5.624c.043-.037.147-.107.187-.067s-.023.143-.06.187L9.6,13.034a.532.532,0,0,0-.053.636L11.7,17.139a.281.281,0,0,0,.506-.007L17.269,4.9A.28.28,0,0,0,16.892,4.531Z" transform="translate(-4.503 -4.503)" fill="#fff" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {!isLoading &&  <Botpreview
                            dataBot={template.template_One}
                            botId={localStorage.getItem('botId')}
                            key={""}
                            view={""}
                            
                        />}
                    </div>: null }

                    {!showPreview && botPage !== 'integration' && botPage !== 'setup' ? 
                    <span className="flex items-center justify-center w-6 h-6 rounded-full bg-[#6D85EB] cursor-pointer ml-auto absolute right-4 top-5" onClick={()=>dispatch(setShowPreview(true))}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.832" height="8.891" viewBox="0 0 13.832 8.891">
                            <path id="Path_22814" data-name="Path 22814" d="M9.16,7.875c-2.482,0-4.44,1.562-6.78,4.115a.488.488,0,0,0,0,.658c2,2.214,3.77,4.118,6.783,4.118,2.976,0,5.208-2.4,6.8-4.137a.484.484,0,0,0,.015-.639C14.353,10.03,12.115,7.875,9.16,7.875ZM9.3,15.1a2.779,2.779,0,1,1,2.646-2.646A2.78,2.78,0,0,1,9.3,15.1Z" transform="translate(-2.252 -7.875)" fill="#fff" />
                        </svg>
                    </span>:null }
                </div>
            </div>
        </>
    )
}

export default Editor;