import React from 'react'
import {Handle , Position} from 'reactflow'


function End(props) {
    return (
        <div className="editorBox border border-[#DDDDDD] rounded-xl w-96 my-2 mx-2">
                   <div className="editorHeader flex items-center justify-center border-[#DDDDDD] border-b-[1px] px-3 py-2">
                       <span className="editorIcon w-5 h-5 rounded bg-[#019354] mr-2 text-white flex items-center justify-center" >
                           <img src={require("../../img/edit-message.png")} />
                       </span>
                       <span className="editorTitle text-[#838383] text-sm" >{props.data.UI} {props.id}</span>
                       <span className="editorDropDown ml-auto cursor-pointer px-2">    
                           <svg xmlns="http://www.w3.org/2000/svg" width="3.216" height="10.864" viewBox="0 0 2.216 8.864">
                               <path id="Icon_metro-more-vert" data-name="Icon metro-more-vert" d="M16.532,9.928A1.108,1.108,0,1,0,15.424,8.82,1.108,1.108,0,0,0,16.532,9.928Zm0,1.108a1.108,1.108,0,1,0,1.108,1.108A1.108,1.108,0,0,0,16.532,11.036Zm0,3.324a1.108,1.108,0,1,0,1.108,1.108A1.108,1.108,0,0,0,16.532,14.36Z" transform="translate(-15.424 -7.712)" fill="#acacac" />
                           </svg>
                       </span>
                   </div>
                   <div className="editorBody px-3 py-3">
                       <p className="text-xs font-semibold text-[#5C5C5C]" onClick={props.viewEditorHandle}>{props.data.data.text}</p>
                   </div>
                   <Handle type="target" position={Position.Top} isConnectable={true} style = {{visibility : 'hidden'}} />
                   {/* <Handle
                       type="source"
                       position={Position.Bottom}
                       id="a"
                       //style={handleStyle}
                       isConnectable={true}
                   /> */}
                   
               </div> 
   
   
       // <div>
       //     <Handle type="target" position={Position.Top} isConnectable={true} />
       //     <p>{props.id}</p>
       //     <p>{props.data.UI}</p>
       //     <Handle
       //     type="source"
       //     position={Position.Bottom}
       //     id="a"
       //     //style={handleStyle}
       //     isConnectable={true}
       //   />
       // </div>
     )
}

export default End