import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "../../Component/DropDown";
import Pagignation from "../../Component/Pagination";
import Search from "../../Component/Search";

import Topheader from "../../Component/TopHeader";
import { getDashboardData, toggleBotStatus, toggleStatus } from "../../features/userBots/userBotsSlice";

// import { useNavigate } from "react-router-dom";
import botList from './../../data/en.json'
import { getAgentData } from "../../features/agent/agentSlice";
import { stopLoader } from "../../utilities/chatbotMethods";



const HomePage = () => {
 
  //to display bots per page
  let pageSize = 5;


  //initializing the dispatch
  const dispatch = useDispatch()
  
  const navigate = useNavigate()  

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [botStatusFilter, setBotStatusFilter] = useState('all')
  const [filteredBotlength, setFilteredBotlength] = useState(0)

  //@DEF - fetching data from the global state
  //state -- userBots
  const {userBots : data} = useSelector(state => state.userBots)
  const {agentData}  = useSelector(state => state.agentData)






  //@DEF - set to bot-id when you hover over the dropdown
  //@USED IN - DropDown component
  const [selected , setSelected] = useState('')

  let [count, setCount] = useState(0)

  const [currentPage, setCurrentPage] = useState(1)
  const [currentData , setCurrentData] = useState([])
  



  useEffect(()=>{

   
    if(!data){
      dispatch(getDashboardData())
      dispatch(getAgentData())
    }
    else{
      const allBots =  Object.entries(data).map(([key, value]) => ({ key, value })).sort((a,b) => {
        return b.value.update_date-a.value.update_date
      }).filter((item) => {return item.value.bot_template_category!=undefined})

      console.log(allBots)



      const searchedBots=allBots.filter((item) => {return !searchValue&&item.value.bot_name.toLowerCase().includes(searchValue.toLowerCase())})
      console.log(searchedBots,"searchedBots")
      const toggledBots = searchedBots.filter((item) => {return botStatusFilter==="all" || item.value.status == botStatusFilter})
      console.log(toggledBots,"toggledBots",botStatusFilter)

      if(toggledBots.length<pageSize){
        setCurrentPage(1)
      }
      setFilteredBotlength(toggledBots.length)
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, toggledBots.length);
      // Get the array of objects to display for the current page
      const objectsToDisplay = toggledBots.slice(startIndex, endIndex);
        setCurrentData(objectsToDisplay)
        console.log(objectsToDisplay,"objectsToDisplay")
      setIsLoading(false)
      stopLoader("route")
      }
      
  },[data,currentPage,searchValue,botStatusFilter])

  const handleCopy =(copy)=>{
    navigator.clipboard.writeText(copy.appointmentID)
  }

  //handler function
  const handleStatusChange = (botId , newStatus) => {

    // console.log(botId , ' ' , value)
    const newStatusBinary = newStatus ? 1 : 0
    dispatch(toggleBotStatus({botId , newStatus : newStatusBinary}))
    
  }

  //handler function
  // const handleSearch = (e) => {
  //   const searchText = e.target.value
  //   if(searchText === ''){
  //     setCurrentData(Object.entries(data).map(([key, value]) => ({ key, value }))).sort((a,b) => {
  //       return b.value.update_date-a.value.update_date
  //     })
  //   }
  //   else{
      
  //     const currentData = Object.entries(data).map(([key, value]) => ({ key, value })).filter((item) => {
  //       if(item.value.bot_name.includes(searchText)){
  //         return item
  //       }
  //     }).sort((a,b) => {
  //       return b.value.update_date-a.value.update_date
  //     })
  //     setCurrentData(currentData)
  //   }
    
  // }

  //handlert function
  // const filterBotByStatus = (event) => {
  //   const value = event.target.value
  //   let currentData = []
  //   if(value === 'activeChatbots'){
  //     currentData = Object.entries(data).map(([key, value]) => ({ key, value })).filter((item) => {
  //       if(item.value.status == 1){
  //         return item
  //       }
  //     }).sort((a,b) => {
  //       return b.value.update_date-a.value.update_date
  //     })
  //     setCurrentData(currentData)
  //   }
  //   else if(value === 'inactiveChatbots'){
  //     currentData = Object.entries(data).map(([key, value]) => ({ key, value })).filter((item) => {
  //       if(item.value.status == 0){
  //         return item
  //       }
  //     }).sort((a,b) => {
  //       return b.value.update_date-a.value.update_date
  //     })
  //     setCurrentData(currentData)
  //   }
  //   else{
  //     setCurrentData(Object.entries(data).map(([key, value]) => ({ key, value })).sort((a,b) => {
  //       return b.value.update_date-a.value.update_date
  //     }))
  //   }
  // }



  const handleLocationUrlChange = (data) => {
    // console.log("HANDLE LOCATION URL");
    // alert("clicked open bot");

    let type_ = data.value.type
      .replace(" bot", "")
      .replace(" Bot", "")
      .replace(/ /g, "")
      .toLowerCase();
    // if (data.value.bot_template_category == "5") {
    //   type_ = "answer";
    //   localStorage.setItem("bot_type_", "answer");
    //   localStorage.setItem("botType", "answer");
    // } else  if (data.value.bot_template_category == "36") {
    //   type_ = "q-a";
    //   localStorage.setItem("bot_type_", "q-a");
    //   localStorage.setItem("botType", "q-a");
    // }else {
    //   localStorage.setItem("bot_type_", type_);
    //   localStorage.setItem("botType", type_);

    // }
    const foundBot = botList.botList.find(botObj => botObj.id==data.value.bot_template_category)
    
    // console.log(foundBot , 'fb')
    const newBotType = foundBot? foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):data.value.type;
    localStorage.setItem("botType",data.value.bot_template_category);
    localStorage.setItem("botId", data.key);
    localStorage.setItem('bot_type' , newBotType)
    localStorage.setItem('BOTNAME',data.value.bot_name)

    
    // if (localStorage.getItem("currentLink") != null) {
    //   var botID = localStorage.getItem("currentLink").split("=")[1];
    //   if (botID != data.key) {
    //     localStorage.removeItem("currentLink");
    //   }
    // }
  };

  const createEditorLink=(data)=>{
    
    // console.log(botList , ' ehs ' , data)
    const foundBot = botList.botList.find(botObj => botObj.id==data.value.bot_template_category)
    
    // console.log(foundBot , 'fb')
    const newBotType = foundBot? foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):data.value.type;
    const botPage = foundBot&&foundBot.botPage ? foundBot.botPage : 'editor'
    return `/cx/Chatbot/${newBotType}/${botPage}?did=${data.key}`
  }


  const handleSetupLink = (data) => {
    // console.log(botList , ' ehs ' , data)
    const foundBot = botList.botList.find(botObj => botObj.id==data.value.bot_template_category)
    
    // console.log(foundBot , 'fb')
    const newBotType = foundBot? foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-'):data.value.type;
    const botPage = 'setup'
    return `/cx/Chatbot/${newBotType}/${botPage}?did=${data.key}`
  }


  const handleCreateBot = (data) => { 


    navigate(`/builder`)
  }


  


  return (
    <>
      {/* <SideMenu /> */}

      {isLoading?<><p>"...Loading"</p></>:
      <div className="pl-[76px] lg:pl-[276px] p-5">
        <Topheader Chatbot="Chatbot" />
        <div className="w-full flex justify-between rounded-xl items-center mt-7">
          <select className="p-4 rounded-lg drop-shadow-sm bg-white w-44 outline-none font-light text-[#2E4765]" onChange={(event) => setBotStatusFilter(event.target.value)}>
            <option value={"all"}>All chatbots</option>
            <option value={1}>Active chatbots</option>
            <option value={0}>Inactive chatbots</option>
          </select>
          


          {/* creating a seperate component -- Search.jsx */}
          <Search src = {require("../../img/search-icon.png")} onChangeHandler = {(e)=>setSearchValue(e)} searchValue={searchValue}/>

          <div className="relative" onClick={handleCreateBot}>
            <button className="w-44 bg-white drop-shadow-sm rounded-xl p-4 text-left font-light text-[#2E4765]">
              Create Bot
              <img
                className="cursor-pointer absolute top-[20px] right-[10px] w-4"
                src={require("../../img/add-icon.png")}
              />
            </button>
          </div>
        </div>

        {/* {userBots.map((item,index) => {
          return <h1 key={index}>{item.interaction}</h1>
        })} */}
        {/* {Object.keys(data).map(item => {
          return <p>J{JSON.stringify(data[item])}</p>
        })} */}

    
        
        
        {currentData.length === 0 ? (<>
        <span>No bots found</span> 
        <br/>
         <button onClick={()=>{navigate('/builder')}}>Create one</button>
        </>):( 
        currentData.map((row, i) => {
          return (
            
            <div
              className="drop-shadow-sm bg-white w-full flex justify-between rounded-xl mt-7 hover:shadow-xl transition delay-100 duration-300 ease-in-out group"
              key={row.key}
            >
              <div className="text-black p-6 w-full flex justify-between">
                <div className="flex w-full">
                  <div className="w-12 h-12 text-center bg-[#2E476534] rounded-lg ">
                    <img className="w-6 m-auto pt-1" src={require("../../img/bot-icon.png")} />{" "}
                  </div>
                  <div className="pl-4">
                    <Link onClick={() => handleLocationUrlChange(row)} to= {createEditorLink(row)}>
                      <h3 className="text-xl font-semibold text-[#2E4765] mb-1 group-hover:text-linkColor cursor-pointer">
                        {row.value.bot_name}
                      </h3>
                    </Link>
                    <span className="text-xs text-[#2E4765] font-extralight flex items-center gap-2">
                      {row.key}
                      <img className="h-3 cursor-pointer" src={require("../../img/copy-icon.png")} onClick={()=>handleCopy(row)} />
                    </span>
                    <div className="flex gap-2 pt-10 text-sm font-medium mb-2">
                      <span className="text-[#2E4765]">{row.value.type}</span>
                      <span> | </span>
                      { (row.value.payment && row.value.payment.payment_status != 'pending' && row.value.payment.payment_status != 'canceled' ) &&   row.value.payment.planName === "Free Plan" ? (
                        <span className="text-[#70B600] font-bold">{row.value.payment.planName}</span>
                      ) : (
                        <span className="text-[#FFBC6E] font-bold">{row.value.payment?row.value.payment.planName:"Free"}</span>
                      )}
                    </div>
                    <span className="text-xs text-[#2E4765] font-extralight">{new Date(row.value.update_date).getDate() + ' ' + new Date(row.value.create_date).toLocaleString('en-US', { month: 'short' }) + ', ' + new Date(row.value.create_date).getFullYear()  }</span>
                  </div>
                </div>

                {/* botdata.data.email,
                      botdata.data.sms,
                      botdata.data.whatsapp,
                      botdata.data.interaction,
                      botdata.data.email_used,
                      botdata.data.sms_used,
                      botdata.data.whatsapp_used,
                      botdata.data.interaction_used, */}
                <div className="right w-64 flex flex-wrap items-baseline">
                  <div className="flex items-center w-full justify-end text-[#2E4765] text-sm font-semibold">
                    {`${row.value.interaction_used?row.value.interaction_used:0} / ${row.value.interaction?row.value.interaction:250} Interactions`} <img className="pl-2 h-2" src={require("../../img/down-arrow.png")} />
                  </div>
                  <div className="flex items-center w-full justify-end text-[#2E4765] text-sm font-semibold">
                    {`${row.value.whatsapp_used?row.value.whatsapp_used:0} / ${row.value.whatsapp?row.value.whatsapp:10} WhatsApp`} 
                  </div>
                  <div className="flex items-center w-full justify-end text-[#2E4765] text-sm font-semibold">
                    {`${row.value.sms_used?row.value.sms_used:0} / ${row.value.sms?row.value.sms:10} Interactions`} 
                  </div>
                  <div className="flex items-center w-full justify-end text-[#2E4765] text-sm font-semibold">
                    {`${row.value.email_used?row.value.email_used:0} / ${row.value.email?row.value.email:20} Interactions`} 
                  </div>
                  <div className="flex gap-2 pt-10 w-full justify-end">
                    <a href="#" className="text-linkColor text-sm font-medium">
                      {row.inquery}
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-72 border-l-2 p-6 flex flex-wrap items-end justify-center border-gray-100">
                <div className="flex justify-between w-full mb-auto">
                  {/* <label htmlFor={`toggle${i}`} className="flex cursor-pointer">
                    <div className="relative">
                      <input type="checkbox" id={`toggle${i}`} className="sr-only"  checked={row.active} onChange={()=>statusCheck(row)}/>
                      <div className={`block w-12 h-6 rounded-full ${row.active === true ? 'bg-thmBG' : 'bg-[#00000042]'}`}></div>
                      <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>
                  </label> */}


                   <label htmlFor={row.botId} className="flex cursor-pointer">
                    <div className="relative">
                      <input type="checkbox" id={row.key} className="sr-only"  checked={row.value.status} onChange={(event)=>handleStatusChange(row.key , event.target.checked)}/>
                      <div className={`block w-12 h-6 rounded-full ${row.value.status  ? 'bg-thmBG' : 'bg-[#00000042]'}`}></div>
                      <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                    </div>
                  </label>


                  

                  {/* @COMPONENT -  DropDown */}
                  <DropDown botId={row.appointmentID} selected = {selected} setSelected = {setSelected}/>
                  
                </div>
                {row.value.domain_url === undefined ? (
                  <button className="font-medium text-sm rounded-3xl px-4 py-2 bg-thmBG text-white hover:bg-[#354db0] duration-300">
                    <Link to={handleSetupLink(row)}>Deploy Bot</Link>
                  </button>
                ) : (
                  <button className="flex items-center font-medium text-sm rounded-3xl px-4 py-2 bg-white  text-[#70B600] duration-200 cursor-auto">
                    <span className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="13.402" height="9.994" viewBox="0 0 13.402 9.994">
                        <path
                          id="Icon_awesome-check"
                          data-name="Icon awesome-check"
                          d="M4.552,14.375.2,10.019a.67.67,0,0,1,0-.948l.948-.948a.67.67,0,0,1,.948,0l2.934,2.934L11.31,4.773a.67.67,0,0,1,.948,0l.948.948a.67.67,0,0,1,0,.948L5.5,14.375A.67.67,0,0,1,4.552,14.375Z"
                          transform="translate(0 -4.577)"
                          fill="#70b600"
                        />
                      </svg>
                    </span>
                    Deployed
                  </button>
                )}
              </div>
              {row.plan === "Free Plan" ? (
                <div className="flex bg-badgeBG absolute -top-3 left-6 rounded-md p-1 text-[10px] gap-2 text-[#fff]">
                  <img className="w-3 h-3" src={require("../../img/paid-icon.png")} alt="paid icon" /> {row.premium}
                </div>
              ) : null}
            </div>
          );
        }))}

        {filteredBotlength&&<Pagignation totalCount={filteredBotlength} currentPage={currentPage} pageSize={pageSize} onPageChange={page => setCurrentPage(page)} />}
      </div>}
    </>
  );
};

export default HomePage;
