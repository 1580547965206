import React, { useEffect } from 'react'

const BackScreen = () => {
    var PopOverlay = document.getElementById('PopOverlay')
    const BackPop = ()=>{
        PopOverlay.remove();
    }
  return (
    <div className='back-view border rounded p-3 bg-white absolute top-0 z-50 h-full overflow-auto' id='PopOverlay'>
                <div className='flex items-center gap-2 cursor-pointer' onClick={BackPop}>
                    <img className='w-5 h-3' src={require('../../src/img/back-icon.png')} alt='Back Icon' />
                    <h3 className='text-[#626262]'>Back</h3>
                </div>
                <h2 className='text-sm mb-1 mt-1'>Add Button</h2>
                <p className='text-[#626262] text-xs'>
                    Configure button to display at the bottom of card
                </p>
                <h2 className='text-sm mb-1 mt-1'>Button text</h2>
                <p className='text-[#5F5F5F] text-[10px] bg-[#F4F5FA] p-2'>Learn More</p>

                <h3 className='text-sm text-[#626262] mt-2'>Actions</h3>
            <div className='flex items-center mt-1'>
                <input type="radio" id="paymentBtn12" name="fav_language" />
                <label for="paymentBtn12" className="cursor-pointer text-xs text-[#5C5C5C] pl-2">Add as Node</label>
            </div>
            <div className='flex items-center mt-1'>
                <input type="radio" id="paymentBtn13" name="fav_language" checked/>
                <label for="paymentBtn13" className="cursor-pointer text-xs text-[#5C5C5C] pl-2">Url</label>
            </div>
            <div className='flex items-center mt-1'>
                <input type="radio" id="paymentBtn134" name="fav_language" checked/>
                <label for="paymentBtn134" className="cursor-pointer text-xs text-[#5C5C5C] pl-2">Contact Number</label>
            </div>
            <input className='text-[#5F5F5F] text-[10px] bg-[#F4F5FA] p-2 w-full rounded outline-0 mt-2' type="text" placeholder='Enter Contact Number' />
            <div className='editorBtn py-3'>
                <button className='group border border-[#C6C6C6] hover:border-[#4E4E4E] text-[11px] mr-1 px-2 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
                <button className='border border-indigo-600 text-[11px] px-2 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save Button</button>
            </div>
    </div>
  )
}

export default BackScreen