import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateinstalledIntegrations } from "../../features/integraion/integrationSlice";
import {
  getconnectURL,
  installZoom,
  uninstallZoom,
  getConnectHandoff
} from "./../../../src/utilities/chatbotMethods";

function ZoomIntegration(props) {
    const [isLoading, setIsLoading] = useState(true);
  const { installedIntegrations } = useSelector((state) => state.integration);

  const [mode, setMode] = useState(props.mode);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  
  const [error, setError] = useState(false);
  

  const dispatch = useDispatch();
  useEffect(() => {
    console.log(props, "props in zendesk");
    localStorage.setItem("connectType", "Appointment");
    localStorage.setItem("connectApp", "zoom");



    if (props.mode == "edit") {

        getConnectHandoff("zoom").then((res) => {
            if(res){
                
                        setIsLoading(false);
           
            }else{
                setIsLoading(false);
                setMode("install")
            }
        })

     
    } else {
      setIsLoading(false);
    }

    

   
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsBtnLoading(true);
    setError(false)
    
    var data = {};
    var body={
       installed:true
    }
    data["teams"] = body;

    installZoom(data).then((status) => {
        if (status) {
            dispatch(
            updateinstalledIntegrations(["zoom", ...installedIntegrations])
            );
            setIsBtnLoading(false);
            props.closePopup();
        }
    })
    

    

  };

   

  const handleLogin = () => {
    setIsBtnLoading(true);
    getconnectURL(props.appData.appId, props.appData.integrationUrl).then(
      (url) => {
        setIsBtnLoading(false);
        var wind = window.open(
          `${url}`,
          "popUpWindow",
          "height=500,width=700,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
        );

        // Add an event listener to listen for messages from the popup
        window.addEventListener("message", handleMessage);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("message", handleMessage);
        };
      }
    );
  };

  const handleMessage = (event) => {
    // Ensure the message is from an expected origin to avoid security risks

    console.log(event, "event", event.data);
    if (event.origin == window.location.origin) {
      
      const receivedData = event.data;
      if(receivedData&&receivedData.connectId){
     
        setMode("edit")
        setIsBtnLoading(false);
        setIsLoading(false);
     
    }

      
    }
  };

  const handleUninstall = () => {
    setIsBtnLoading(true);
    uninstallZoom().then((status) => {
      if (status) {
        dispatch(
          updateinstalledIntegrations(
            installedIntegrations.filter((item) => item !== "zoom")
          )
        );
        setIsBtnLoading(false);
        props.closePopup();
      }
    });
  };
    return (
        <>
          {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}
    
          {isLoading ? (
            <p>Loading...</p>
          ) : mode == "uninstall" ? (
            <>
              <span>
                {" "}
                Are you sure you want to uninstall {props.appData.title} ?
              </span>
              <br />
              {isBtnLoading ? (
                <span>...</span>
              ) : (
                <button
                  onClick={() => {
                    handleUninstall();
                  }}
                >
                  {" "}
                  Yes,uninstall
                </button>
              )}
              <br />
              <button
                onClick={() => {
                  props.closePopup();
                }}
              >
                No
              </button>
            </>
          ) : mode == "edit" ? (
            <div className="zendeskContainer">
              <div className="zendeskHeader">
                <span className="zendeskHeaderImage">
                  <img src="/dist/img/public/img/croseBgWhite.svg" alt="close" />
                </span>
              </div>
              <div className="zendeskBody pt-4 pb-4">
                <div className="integration-form-heading mb-3">
                  <div className="integrationLeftImage">
                    <img src="https://mediaconnect.appypie.com/media/icons/128x128/microsoft_teams.png" />
                  </div>
                  <div className="integrationReftDetails as">
                    <div className="title">
                      <h4>{props.appData.title}</h4>
                    </div>
                    <div className="description">
                      <p className="mb-1">
                     {props.appData.description}
                      </p>
                    </div>
                    <div className="link">
                      <span className="learnMore">Learn more:</span>{props.appData.connect_desc}
                    </div>
                    <div />
                    <div />
                  </div>
                </div>
                <div>
                  <div>
                    <form
                      autoComplete="off"
                      onSubmit={
                        handleSubmit
                      }
                    >
                      <div>
                        <div className="row">
                          <div className="col-md-12 md-3">
                            <label
                              htmlFor="defaultFormRegisterNameEx"
                              className="grey-text"
                            >
                              Select Integration *
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 mb-3">
                            <select className="form-control" required>
                              <option name="option" value= {props.appData.connect_action}>
                                {props.appData.connect_action}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                     
                      <div className="row">
                        <div className="col">
                          {isBtnLoading ? (
                            <span>...</span>
                          ) : (
                            
                            <input type="submit" value="Connect" />
                          )
                          
                        }
                            {error&&<p>{"Select all fields"}</p>}
    
                          <span
                            onClick={() => {
                              props.closePopup();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div>
                  <img src={props.appData.img} />
                </div>
                <div>
                  <div>
                    <h4>{props.appData.title}</h4>
                  </div>
                  <div>
                    <p>
                     {props.appData.connect_desc}
                    </p>
                  </div>
                  <div>
                    <span>Learn more:</span>
                  </div>
                  <div />
                  <div />
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <label>Login with your Zoom account</label>
    
                    {isBtnLoading ? (
                      <span>...</span>
                    ) : (
                      <button onClick={() => handleLogin()}>Sign in with Zoom</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
}

export default ZoomIntegration