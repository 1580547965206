import React from 'react'
import { useSelector } from 'react-redux'

function DesignPreview() {

  //getiing data from the global state
  //state -- state.botDesign
  const {design , basicSetting} = useSelector(state => state.botDesign)
  const {template} = useSelector(state => state.botTemplate)
  const {customisation} = template.template_One
  const {widgetImage} = template.widgetImages
  const {chatBotAvtar} = template.chatBotAvtars
  const badge = design.badge
  const avtarSelect = design.avtarSelect

  
  return (
    <div className="boatDisplay w-80 ml-auto">
            {/* <div className="dimensialSection flex items-center">
                            <div className="dimensialTabMobile flex bg-[#F0F0F0] rounded px-2 py-2 mr-auto">
                                <span className="desktop hover:bg-white bg-white px-3 py-2 rounded cursor-pointer ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.967" height="15.967" viewBox="0 0 15.967 15.967">
                                        <path id="Icon_ionic-md-desktop" data-name="Icon ionic-md-desktop" d="M16.7,2.25H3.771A1.525,1.525,0,0,0,2.25,3.771V13.845a1.525,1.525,0,0,0,1.521,1.521H8.713L7.192,17.076v1.14h6.083v-1.14l-1.521-1.711H16.7a1.525,1.525,0,0,0,1.521-1.521V3.771A1.525,1.525,0,0,0,16.7,2.25Zm0,10.264H3.771V3.771H16.7Z" transform="translate(-2.25 -2.25)" fill="#637ce6" />
                                    </svg>
                                </span>
                                <span className="mobile hover:bg-white px-3 py-2 rounded cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.553" height="15.284" viewBox="0 0 9.553 15.284">
                                        <path id="Icon_awesome-mobile-alt" data-name="Icon awesome-mobile-alt" d="M8.12,0H1.433A1.433,1.433,0,0,0,0,1.433V13.851a1.433,1.433,0,0,0,1.433,1.433H8.12a1.433,1.433,0,0,0,1.433-1.433V1.433A1.433,1.433,0,0,0,8.12,0ZM4.776,14.329a.955.955,0,1,1,.955-.955A.954.954,0,0,1,4.776,14.329ZM8.12,11.1a.359.359,0,0,1-.358.358H1.791a.359.359,0,0,1-.358-.358V1.791a.359.359,0,0,1,.358-.358h5.97a.359.359,0,0,1,.358.358Z" fill="#637ce6" />
                                    </svg>
                                </span>
                            </div>
                            <span className="close flex items-center justify-center bg-[#6D85EB] w-7 h-7 rounded-full cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.499" height="10.499" viewBox="0 0 10.499 10.499">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M18,8.557,16.941,7.5l-4.192,4.192L8.557,7.5,7.5,8.557l4.192,4.192L7.5,16.941,8.557,18l4.192-4.192L16.941,18,18,16.941l-4.192-4.192Z" transform="translate(-7.5 -7.5)" fill="#fff" />
                                </svg>
                            </span>
                        </div> */}
            <div className="boatSection bg-[#F7FCFF] border border-[#D8D8D8] rounded-xl px-3 py-3 mt-4 ">
              <div className="mainBoat shadow-md rounded-b-2xl">
                <div
                  className="boatHeader bg-[#5269C9] rounded-t-2xl px-4 py-4 flex items-center"
                  style={{ background: customisation.headerClr }}
                >
                  {/* <span className="flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-4 ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                      <path
                        id="Icon_awesome-robot"
                        data-name="Icon awesome-robot"
                        d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z"
                        fill="#f7f8f8"
                      />
                    </svg>
                  </span> */}
                  <div className='bg-[#6D85EB] w-11 h-11 mr-4 rounded-full flex items-center justify-center'>
                  <img className='w-8' src={customisation.widgetImage} alt="" />
                  </div>
                  <span className="inline-block text-white font-semibold text-base" style={{ color: customisation.headertextcolor }}>
                    {customisation.headerName}
                  </span>
                </div>
                <div className="boatBody bg-white px-4 py-3" style={{ background: design.themeBgColor }}>
                  {/* <h6 className="text-center pb-2 text-sm">Powered by Userbot</h6> */}
                  <div className="sendMessage flex items-baseline mb-5">
                    <div
                      className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
                      style={{
                        background: customisation.messagebubble,
                      }}
                    >
                      <p
                        className="text-[#272727]  text-sm leading-6"
                        style={{
                          color: customisation.botTextcolor,
                          fontFamily: customisation.fontStyle,
                          fontSize: customisation.fontSize + "px",
                        }}
                      >
                        Hi! I am businees, a friendly bot, Welcome to Appy Pie LLP.
                      </p>
                    </div>
                    <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-16 h-14 ml-2">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                        <path
                          id="Icon_awesome-robot"
                          data-name="Icon awesome-robot"
                          d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z"
                          fill="#f7f8f8"
                        />
                      </svg> */}
                      <img className='w-8' src={customisation.chatBotAvtar} alt="" />
                    </span>
                  </div>
                  <div className="recivedMessage flex mb-5">
                    <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-2">
                      {avtarSelect == "avatar1" ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16.599 16.602">
                          <g
                            id="Icon_ionic-ios-chatboxes"
                            data-name="Icon ionic-ios-chatboxes"
                            transform="translate(-3.375 -3.375)"
                          >
                            <path
                              id="Path_3815"
                              data-name="Path 3815"
                              d="M22.051,11.981H13.207A1.523,1.523,0,0,0,11.686,13.5V19.66a1.519,1.519,0,0,0,1.521,1.517h5.061a.436.436,0,0,1,.3.128l2.514,2.319c.14.136.371.08.371-.116v-2.02c0-.239.152-.315.391-.315h.04a1.661,1.661,0,0,0,1.68-1.517V13.5A1.514,1.514,0,0,0,22.051,11.981Z"
                              transform="translate(-3.593 -3.721)"
                              fill="#fff"
                              class="group-hover:fill-white"
                            ></path>
                            <path
                              id="Path_3816"
                              data-name="Path 3816"
                              d="M8.42,7.282H15.5V4.676a1.3,1.3,0,0,0-1.3-1.3H4.676a1.3,1.3,0,0,0-1.3,1.3V11.27a1.3,1.3,0,0,0,1.3,1.3H7.115V8.583A1.307,1.307,0,0,1,8.42,7.282Z"
                              fill="#fff"
                              class="group-hover:fill-white"
                            ></path>
                          </g>
                        </svg>
                      ) : null}
                      {avtarSelect == "avatar2" ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16.859 16.859">
                          <g
                            id="Icon_ionic-ios-chatbubbles"
                            data-name="Icon ionic-ios-chatbubbles"
                            transform="translate(-3.375 -3.375)"
                          >
                            <path
                              className="group-hover:fill-white"
                              id="Path_3817"
                              data-name="Path 3817"
                              d="M20.931,14.422a.982.982,0,0,1,.134-.494,1.365,1.365,0,0,1,.085-.126,6.567,6.567,0,0,0,1.119-3.66,6.894,6.894,0,0,0-7.039-6.768,6.991,6.991,0,0,0-6.9,5.386,6.513,6.513,0,0,0-.15,1.386A6.875,6.875,0,0,0,15.107,17a8.346,8.346,0,0,0,1.913-.312c.458-.126.912-.292,1.029-.336a1.071,1.071,0,0,1,.377-.069,1.054,1.054,0,0,1,.409.081l2.3.815a.548.548,0,0,0,.158.041.323.323,0,0,0,.324-.324.521.521,0,0,0-.02-.109Z"
                              transform="translate(-2.034 0)"
                              fill="#fff"
                            />
                            <path
                              className="group-hover:fill-white"
                              id="Path_3818"
                              data-name="Path 3818"
                              d="M14.337,21.372c-.146.041-.332.085-.535.13a7.48,7.48,0,0,1-1.378.182,6.875,6.875,0,0,1-6.93-6.853,7.662,7.662,0,0,1,.061-.867c.024-.174.053-.349.093-.519s.089-.365.142-.543l-.324.288a6.031,6.031,0,0,0-2.091,4.535A5.964,5.964,0,0,0,4.38,21.048c.093.142.146.251.13.324s-.482,2.513-.482,2.513a.325.325,0,0,0,.109.312.33.33,0,0,0,.207.073.291.291,0,0,0,.118-.024l2.274-.9a.633.633,0,0,1,.486.008,6.821,6.821,0,0,0,2.46.486,6.365,6.365,0,0,0,4.867-2.233s.13-.178.28-.389C14.678,21.275,14.508,21.328,14.337,21.372Z"
                              transform="translate(0 -4.036)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      ) : null}
                      {avtarSelect == "avatar3" ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 16.018 14.786">
                          <g
                            id="Icon_ionic-md-chatboxes"
                            data-name="Icon ionic-md-chatboxes"
                            transform="translate(-3.375 -4.5)"
                          >
                            <path
                              className="group-hover:fill-white"
                              id="Path_3819"
                              data-name="Path 3819"
                              d="M16.6,4.5H3.745a.371.371,0,0,0-.37.314v8.249a.386.386,0,0,0,.37.332h2.21v3.423l3.47-3.423H16.6a.311.311,0,0,0,.287-.332V4.814A.3.3,0,0,0,16.6,4.5Z"
                              fill="#fff"
                            />
                            <path
                              className="group-hover:fill-white"
                              id="Path_3820"
                              data-name="Path 3820"
                              d="M23.273,8.93H22.026v6.414c0,.616-.266,1.018-.968,1.018H14.1l-1.5,1.5h4.994l3.47,3.427V17.863h2.21a.317.317,0,0,0,.293-.334V9.28A.333.333,0,0,0,23.273,8.93Z"
                              transform="translate(-4.173 -2.004)"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      ) : null}
                      {avtarSelect == "avatar4" ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16.599 16.602">
                          <g
                            id="Icon_ionic-ios-chatboxes"
                            data-name="Icon ionic-ios-chatboxes"
                            transform="translate(-3.375 -3.375)"
                          >
                            <path
                              className="group-hover:fill-white"
                              id="Path_3815"
                              data-name="Path 3815"
                              d="M22.051,11.981H13.207A1.523,1.523,0,0,0,11.686,13.5V19.66a1.519,1.519,0,0,0,1.521,1.517h5.061a.436.436,0,0,1,.3.128l2.514,2.319c.14.136.371.08.371-.116v-2.02c0-.239.152-.315.391-.315h.04a1.661,1.661,0,0,0,1.68-1.517V13.5A1.514,1.514,0,0,0,22.051,11.981Z"
                              transform="translate(-3.593 -3.721)"
                              fill="#fff"
                            />
                            <path
                              className="group-hover:fill-white"
                              id="Path_3816"
                              data-name="Path 3816"
                              d="M8.42,7.282H15.5V4.676a1.3,1.3,0,0,0-1.3-1.3H4.676a1.3,1.3,0,0,0-1.3,1.3V11.27a1.3,1.3,0,0,0,1.3,1.3H7.115V8.583A1.307,1.307,0,0,1,8.42,7.282Z"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      ) : null}
                    </span>
                    <div
                      className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
                      style={{
                        background: customisation.clientBubbleClr
                      }}
                    >
                      <p
                        className="text-[#272727]  text-sm leading-6"
                        style={{
                          color: customisation.clientchattextcolor,
                          fontFamily: customisation.fontStyle,
                          fontSize: customisation.fontSize + "px",
                        }}
                      >
                        How old are you?
                      </p>
                    </div>
                  </div>
                  <div className="sendMessage flex items-baseline mb-5">
                    <div
                      className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
                      style={{
                        background: customisation.messagebubble,
                      }}
                    >
                      <p
                        className="text-[#272727]  text-sm leading-6"
                        style={{
                          color: customisation.botTextcolor,
                          fontFamily: customisation.fontStyle,
                          fontSize: customisation.fontSize + "px",
                        }}
                      >
                        Age doesn’t really apply to me.
                      </p>
                    </div>
                    <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-16 h-14 ml-2">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                        <path
                          id="Icon_awesome-robot"
                          data-name="Icon awesome-robot"
                          d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z"
                          fill="#f7f8f8"
                        />
                      </svg> */}
                      <img className='w-8' src={customisation.chatBotAvtar} alt="" />
                    </span>
                  </div>
                  <div className="recivedMessage flex mb-5">
                    <div className="mesage px-3 py-3 w-full text-center">
                      <button
                        className="group border border-[#C6C6C6] text-sm px-3 py-1 text-center rounded text-[#4E4E4E]"
                        style={{
                          borderColor: design.botBtnColor,
                          color: design.botBtnColor,
                          backgroundColor : customisation.botButtonColor,
                          fontFamily: customisation.buttonfontStyle? customisation.buttonfontStyle : "Roboto",
                          fontSize: customisation.buttonfontSize ? customisation.buttonfontSize + 'px' : "10" + "px",
                        }}
                      >
                        Button 1
                      </button>{" "}
                      <button
                        className="group border border-[#C6C6C6] text-sm px-3 py-1 text-center rounded text-[#4E4E4E]"
                        style={{
                          borderColor: design.botBtnColor,
                          color: design.botBtnColor,
                          backgroundColor : customisation.botButtonColor,
                          fontFamily: customisation.buttonfontStyle? customisation.buttonfontStyle : "Roboto",
                          fontSize: customisation.buttonfontSize ? customisation.buttonfontSize + 'px' : "10" + "px",
                        }}
                      >
                        Button 2
                      </button>
                    </div>
                  </div>
                </div>
                <div className={` boatFooter bg-white px-4 pb-4 ${design.footerPowerdBy ? null : "rounded-b-2xl"}`}>
                  <div className="flex items-center bg-[#F4F5FA] rounded-md px-3 py-2">
                    <input
                      type="text"
                      placeholder="Type your Message"
                      className="text-sm w-9/12 border-0 focus:outline-none bg-[#F4F5FA]"
                    />
                    <span
                      className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-7 h-7 cursor-pointer ml-auto"
                      style={{ background: customisation.sendButtonClr }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.793 12.792">
                        <path
                          id="Icon_ionic-ios-send"
                          data-name="Icon ionic-ios-send"
                          d="M16.892,4.531,4.661,9.862a.28.28,0,0,0,.01.51L7.98,12.241a.533.533,0,0,0,.61-.06l6.524-5.624c.043-.037.147-.107.187-.067s-.023.143-.06.187L9.6,13.034a.532.532,0,0,0-.053.636L11.7,17.139a.281.281,0,0,0,.506-.007L17.269,4.9A.28.28,0,0,0,16.892,4.531Z"
                          transform="translate(-4.503 -4.503)"
                          fill="#fff"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                {customisation.showFooter === true || customisation.showFooter === undefined ? (
                  <div className="powerdByCompany flex items-center justify-center bg-[#f5f5f5] py-2 rounded-b-2xl">
                    <span className="flex w-3 h-3">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                        <g data-name="Layer 2">
                          <g data-name="Layer 1">
                            <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                            <path
                              d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z"
                              fill="#f4a51f"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    <span className="inline-block text-xs pl-2">Powered by {customisation.footerCompanyName}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="widgetSection">
              <span
                className={`flex items-center justify-center w-14 h-14 mt-4 rounded relative ${
                  design.widgetPosition == "left"
                    ? `mr-auto`
                    : "ml-auto" && design.widgetPosition == "right"
                    ? `ml-auto`
                    : "ml-auto" && design.widgetPosition == "center"
                    ? `m-auto`
                    : "ml-auto"
                }`}
                style={{
                  background: customisation.widgetBackgroundColor,
                  bottom: customisation.widgetPosition.bottom + "px",
                  right: customisation.widgetPosition.position == "right" ? customisation.widgetPosition.side + "px" : "auto",
                  left: customisation.widgetPosition.position == "left" ? customisation.widgetPosition.side + "px" : "auto",
                }}
              >
                {/* {badge === "badge1" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.599" height="26.602" viewBox="0 0 16.599 16.602">
                    <g
                      id="Icon_ionic-ios-chatboxes"
                      data-name="Icon ionic-ios-chatboxes"
                      transform="translate(-3.375 -3.375)"
                    >
                      <path
                        id="Path_3815"
                        data-name="Path 3815"
                        d="M22.051,11.981H13.207A1.523,1.523,0,0,0,11.686,13.5V19.66a1.519,1.519,0,0,0,1.521,1.517h5.061a.436.436,0,0,1,.3.128l2.514,2.319c.14.136.371.08.371-.116v-2.02c0-.239.152-.315.391-.315h.04a1.661,1.661,0,0,0,1.68-1.517V13.5A1.514,1.514,0,0,0,22.051,11.981Z"
                        transform="translate(-3.593 -3.721)"
                        fill="#fff"
                        className="group-hover:fill-white"
                      />
                      <path
                        id="Path_3816"
                        data-name="Path 3816"
                        d="M8.42,7.282H15.5V4.676a1.3,1.3,0,0,0-1.3-1.3H4.676a1.3,1.3,0,0,0-1.3,1.3V11.27a1.3,1.3,0,0,0,1.3,1.3H7.115V8.583A1.307,1.307,0,0,1,8.42,7.282Z"
                        fill="#fff"
                        className="group-hover:fill-white"
                      />
                    </g>
                  </svg>
                ) : null}
                {badge === "badge2" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.859" height="26.859" viewBox="0 0 16.859 16.859">
                    <g
                      id="Icon_ionic-ios-chatbubbles"
                      data-name="Icon ionic-ios-chatbubbles"
                      transform="translate(-3.375 -3.375)"
                    >
                      <path
                        className="group-hover:fill-white"
                        id="Path_3817"
                        data-name="Path 3817"
                        d="M20.931,14.422a.982.982,0,0,1,.134-.494,1.365,1.365,0,0,1,.085-.126,6.567,6.567,0,0,0,1.119-3.66,6.894,6.894,0,0,0-7.039-6.768,6.991,6.991,0,0,0-6.9,5.386,6.513,6.513,0,0,0-.15,1.386A6.875,6.875,0,0,0,15.107,17a8.346,8.346,0,0,0,1.913-.312c.458-.126.912-.292,1.029-.336a1.071,1.071,0,0,1,.377-.069,1.054,1.054,0,0,1,.409.081l2.3.815a.548.548,0,0,0,.158.041.323.323,0,0,0,.324-.324.521.521,0,0,0-.02-.109Z"
                        transform="translate(-2.034 0)"
                        fill="#fff"
                      />
                      <path
                        className="group-hover:fill-white"
                        id="Path_3818"
                        data-name="Path 3818"
                        d="M14.337,21.372c-.146.041-.332.085-.535.13a7.48,7.48,0,0,1-1.378.182,6.875,6.875,0,0,1-6.93-6.853,7.662,7.662,0,0,1,.061-.867c.024-.174.053-.349.093-.519s.089-.365.142-.543l-.324.288a6.031,6.031,0,0,0-2.091,4.535A5.964,5.964,0,0,0,4.38,21.048c.093.142.146.251.13.324s-.482,2.513-.482,2.513a.325.325,0,0,0,.109.312.33.33,0,0,0,.207.073.291.291,0,0,0,.118-.024l2.274-.9a.633.633,0,0,1,.486.008,6.821,6.821,0,0,0,2.46.486,6.365,6.365,0,0,0,4.867-2.233s.13-.178.28-.389C14.678,21.275,14.508,21.328,14.337,21.372Z"
                        transform="translate(0 -4.036)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                ) : null}
                {badge === "badge3" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.018" height="26.786" viewBox="0 0 16.018 14.786">
                    <g
                      id="Icon_ionic-md-chatboxes"
                      data-name="Icon ionic-md-chatboxes"
                      transform="translate(-3.375 -4.5)"
                    >
                      <path
                        class="group-hover:fill-white"
                        id="Path_3819"
                        data-name="Path 3819"
                        d="M16.6,4.5H3.745a.371.371,0,0,0-.37.314v8.249a.386.386,0,0,0,.37.332h2.21v3.423l3.47-3.423H16.6a.311.311,0,0,0,.287-.332V4.814A.3.3,0,0,0,16.6,4.5Z"
                        fill="#fff"
                      ></path>
                      <path
                        class="group-hover:fill-white"
                        id="Path_3820"
                        data-name="Path 3820"
                        d="M23.273,8.93H22.026v6.414c0,.616-.266,1.018-.968,1.018H14.1l-1.5,1.5h4.994l3.47,3.427V17.863h2.21a.317.317,0,0,0,.293-.334V9.28A.333.333,0,0,0,23.273,8.93Z"
                        transform="translate(-4.173 -2.004)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                ) : null}
                {badge === "badge4" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.599" height="26.602" viewBox="0 0 16.599 16.602">
                    <g
                      id="Icon_ionic-ios-chatboxes"
                      data-name="Icon ionic-ios-chatboxes"
                      transform="translate(-3.375 -3.375)"
                    >
                      <path
                        className="group-hover:fill-white"
                        id="Path_3815"
                        data-name="Path 3815"
                        d="M22.051,11.981H13.207A1.523,1.523,0,0,0,11.686,13.5V19.66a1.519,1.519,0,0,0,1.521,1.517h5.061a.436.436,0,0,1,.3.128l2.514,2.319c.14.136.371.08.371-.116v-2.02c0-.239.152-.315.391-.315h.04a1.661,1.661,0,0,0,1.68-1.517V13.5A1.514,1.514,0,0,0,22.051,11.981Z"
                        transform="translate(-3.593 -3.721)"
                        fill="#fff"
                      />
                      <path
                        className="group-hover:fill-white"
                        id="Path_3816"
                        data-name="Path 3816"
                        d="M8.42,7.282H15.5V4.676a1.3,1.3,0,0,0-1.3-1.3H4.676a1.3,1.3,0,0,0-1.3,1.3V11.27a1.3,1.3,0,0,0,1.3,1.3H7.115V8.583A1.307,1.307,0,0,1,8.42,7.282Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                ) : null} */}
                <img src={customisation.widgetImage} alt="" />
              </span>
            </div>
          </div>
  )
}

export default DesignPreview