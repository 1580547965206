import React  , {useState , useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Calendar from './Calendar'
import Availability from './Availability'
import { getAgentData } from '../../features/agent/agentSlice'

function Appointment() {

  const [page, setSelectedPage] = useState('calendar')

  const {agentData} = useSelector((state) => state.agentData)

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const handleLinkClick = (tab) => {
    navigate(`/cx/appointment/${tab}`)
    setSelectedPage(tab)
  }

  const params = useParams()


  useEffect(() => {
    // console.log(page , ' lksahjd')

    const page  = params.page
    setSelectedPage(page)
    console.log(page , ' page')

    if(JSON.stringify(agentData) === '{}'){
      dispatch(getAgentData())
    }

  },[])


  const activeCss = "inline-block cursor-pointer rounded-t-lg hover:bg-white bg-white"
  const inactiveCss = "inline-block cursor-pointer rounded-t-lg hover:bg-white"
  return (
    <div className="pl-[76px] lg:pl-[276px] p-5">
      <>
      <div className="botTabs mt-5 text-sm px-5">
                    <span className={page === 'calendar' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('calendar')}} className="px-4 py-2 inline-block">Calendar</span></span>
                    <span className={page === 'availability' ? activeCss : inactiveCss}><span onClick={() => {handleLinkClick('availability')}} className="px-4 py-2 inline-block">Availability</span></span>
      </div>
      </>

      <>
        {page === 'calendar'&&  Object.keys(agentData).length>0 &&  <><div>
          <Calendar/>
        </div> 
        
        </>}
        {page === 'availability'&&  Object.keys(agentData).length>0 &&  <div>
          <Availability/>
        </div>

        
        
        
        }
      </>
    </div>
  )
}

export default Appointment