import React from 'react'

function ColorPicker(props) {
  return (
    <div className="designSettingFeildsColum w-10/12">
                      <label className="text-xs block mb-1">{props.label}</label>
                      <span className="w-64 h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center">
                        {props.initialValue}
                        <input
                          type="color"
                          name={props.inputName}
                          value={props.initialValue}
                          className="ml-auto w-6 h-6 rounded cursor-pointer border-0"
                          // onChange={designSettings}
                        onChange={(event) => {props.changeColor(event.target.value)}}
                        />
                      </span>
                    </div>
  )
}

export default ColorPicker