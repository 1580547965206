
import { db } from "../../config/firebase";
// import { setUserBots } from "./userBotsSlice";
import {setAgentData} from './agentSlice'

//getting data from firebase
export const getAgentDataAsync = async (_,thunkAPI) => {
return new Promise((resolve, reject) => {
    db
  .collection("user_" + localStorage.getItem("tablealias"))
  .doc(localStorage.getItem("agent_id"))
  
  .onSnapshot((doc) => {
    // console.log(doc.data() , ' maggiaa')
    thunkAPI.dispatch(setAgentData(doc.data()))
    resolve()
    // reject(doc)

  }).catch((err) => {
    console.log(err)
    reject(err)
  })
}) 

  
}