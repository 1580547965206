import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideMenu from "../../Component/sidemenu";
import SetupListItem from "../../Component/setup/SetupListItem";
import { setup } from "../../server/service";
import { db } from "../../config/firebase";
import SocialApps from "../../Component/setup/socialMediaPlatform/SocialApps";

const Setup = () => {


  const [depWeb, setDepWeb] = useState("customWebsite")

  const [setupMethods , setSetupMethods] = useState([])

  const [isLoading , setIsLoading] = useState(true)

  const [socialMediaAllowed , setSocialMediaAllowed] = useState(false)
  const [sdkAllowed , setSdkAllowed] = useState(false)

  const [propToPass , setPropToPass] = useState({})
  const [radioApp,setRadioApp]=useState('web')


  // var socialMediaAllowed = false
  // var sdkAllowed = false

  // return the setup object based on setup type
  const getSetupData = (setupType , category) => {
      //length of array
      const length = setup[category].length

      //getting the collection/array of setup types
      const arr = setup[category]

      for (var i=0;i<length;i++){
        if(arr[i].setupType === setupType){
          return arr[i]
        }
      }
  }

const pre = `<!-- Appy Chatbot widget, Paste the code in the <body> section -->
<script id="appyWidgetInit" src="https://chatbot.appypie.com/widget/loadbuild.js?cid=le80eo67-AGENT1676606812639-BOTID1678787934997&name=mixBuild"></script>
<!-- /. End Appy chatbot widget code -->`

    const selectDeployWeb =(usCusWeb)=>{
        setDepWeb(usCusWeb)
    }

    useEffect(() => {
      db.collection('setup_bot').doc('self_service_new').get().then((doc) => {
        console.log(doc.data() , ' setupdata')
        const meth = []
        doc.data().deployment_options.map((deploymentType) => {

          console.log(deploymentType.botlist , ' setupdata deploymentType' , parseInt(localStorage.getItem("botType")))
          

          if(deploymentType.botlist&&deploymentType.botlist.includes(parseInt(localStorage.getItem("botType")))){
              if(deploymentType.id === "social_media_platform"){
                setSocialMediaAllowed(true)
                setPropToPass(deploymentType)
              }
              else if(deploymentType.id === 'sdk'){
                setSdkAllowed(true)
              }
          }
        })
        setSetupMethods(doc.data().deployment_options)
        // debugger
        console.log(socialMediaAllowed)
        console.log(sdkAllowed)
        setIsLoading(false)
      }).catch((err) => {
          console.log(err , ' dkjuaghwd')
      })
    },[])



  return (
    <>
      {/* <SideMenu /> */}
        <div className="boatEditSection py-5 bg-white">
          <div className="boatSettings ">
            {isLoading ? 'Loading...' :
          <>
              <input id="draft" className="peer/draft mr-3 ml-5" type="radio" name="status" defaultChecked onClick={()=>{setRadioApp("web")}} />
              <label htmlFor="draft" className="peer-checked/draft:text-sky-500 mr-5 cursor-pointer">
                Deploy bot in website
              </label>
              {socialMediaAllowed && <>
              <input id="published" className="peer/published mr-3" type="radio" name="status" onClick={()=>{setRadioApp("socialapp")}}/>
              <label htmlFor="published" className="peer-checked/published:text-sky-500 mr-5 cursor-pointer">
                Deploy bot on social media apps
              </label>
              </>}
              {sdkAllowed && <>
              <input id="setup" className="peer/setup mr-3" type="radio" name="status" onClick={()=>{setRadioApp("sdk")}}/>
              <label htmlFor="setup" className="peer-checked/setup:text-sky-500 cursor-pointer">
                Install Chatbot SDK
              </label>
              </>}

              {radioApp=="web"&&<div className="hidden peer-checked/draft:block border-t-[1px] border-[#BFBFBF] mt-5 w-full">
                <div className="deployBoatInWebsiteRow flex">
                  <div className="deployeWebsiteLink border-r-[1px] border-[#BFBFBF] w-3/12">


                    {setup.website.map((item) => {
                    return <SetupListItem key = {item.setupType} depWeb = {depWeb} setupType = {item.setupType} imageUrl = {item.imageUrl} name = {item.name} arrowUrl = {item.arrowUrl} selectDeployWeb = {selectDeployWeb}/>

                    })}

                    {/* <div className={depWeb == "customWebsite" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer":"depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("customWebsite")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/customeWebsite.png")} />
                      </span>
                      <span className="deplWebName text-sm">Custom website</span>
                      <span className={depWeb == "customWebsite"? "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div> */}

                    {/* <SetupListItem depWeb = {depWeb} setupType = {'customWebsite'} imageUrl = 'customeWebsite.png' name = 'Custom website' arrowUrl = 'rightArrow.png' selectDeployWeb = {selectDeployWeb}/> */}

                    {/* <div className={depWeb == "wordpress" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer":"depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("wordpress")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/wordpress.png")} />
                      </span>
                      <span className="deplWebName text-sm">Wordpress</span>
                      <span className={depWeb == "wordpress" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "wix" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("wix")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/wix.png")} />
                      </span>
                      <span className="deplWebName text-sm">Wix</span>
                      <span className={depWeb == "wix" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "squarespace" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("squarespace")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/squarespace.png")} />
                      </span>
                      <span className="deplWebName text-sm">Squarespace</span>
                      <span className={depWeb == "squarespace" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "shopify" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("shopify")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/shopfy.png")} />
                      </span>
                      <span className="deplWebName text-sm">Shopify</span>
                      <span className={depWeb == "shopify" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "weebly" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("weebly")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/weebly.png")} />
                      </span>
                      <span className="deplWebName text-sm">Weebly</span>
                      <span className={depWeb == "weebly" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "godaddy" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("godaddy")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/godady.png")} />
                      </span>
                      <span className="deplWebName text-sm">Godaddy</span>
                      <span className={depWeb == "godaddy" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "magento" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("magento")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/magento.png")} />
                      </span>
                      <span className="deplWebName text-sm">Magento</span>
                      <span className={depWeb == "magento" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "webflow" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer" : "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("webflow")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/webflow.png")} />
                      </span>
                      <span className="deplWebName text-sm">Webflow</span>
                      <span className={depWeb == "webflow" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div> */}
                  </div>
                  <div className="deployeWebsiteDetails w-9/12">
                    <div className="breadcrumb px-5 pt-5">
                        <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF] rounded-l-md">
                          Self-service
                        </span>
                        <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF]">
                          <img className="inline-block w-1.5 h-2.5" src={require("../../img/rightArrow.png")} />
                        </span>
                        <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF]">
                          Deploy bot in website
                        </span>
                        <span className="inline-block relative text-xs px-3 py-3 bg-[#F2F5FF] rounded-b">
                          <img className="inline-block w-1.5 h-2.5" src={require("../../img/rightArrow.png")} />
                        </span>
                        <span className="inline-block relative text-xs px-3 py-3 ">Deploy in custom website</span>
                        {/* <span className="inline-block relative text-xs px-3 py-3 ">Deploy in {getSetupData(depWeb , 'website').name}</span> */}
                    </div>

                    {depWeb == "customWebsite" ? 
                    <div className="customeWebsite customeWebsiteWidget px-5 py-5">
                      
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                          To view step of adding Appy Pie Chatbot account’s widget in Wordpress website check out the
                          help doc -
                        </p>
                        <Link className="text-sm text-blue-500">
                          How to deploy Appy Pie Chatbot’s widget in wordpress
                        </Link>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <h4 className="text-base font-medium text-center">
                          How to create a chatbot and deploy it on your website
                        </h4>
                        <iframe
                          className="w-full h-96 my-5"
                          src="https://www.youtube.com/embed/Gfut4qu1lCw"
                          title="How to deploy Appy Pie chatbot's widget in wordpress?"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div>
                      <div className="copyPasteWidget py-5">
                        <h4 className="text-base font-medium">
                          Copy and paste the widget code in the &lt;body&gt; section of each page of your website.
                        </h4>
                        <p className="font-medium text-sm text-blue-500 text-right mt-3"><span className="cursor-pointer inline-block">Send widget code</span></p>
                        <div className="codeWidget bg-[#F4F5F9] px-5 py-5 flex my-3 relative">
                          <div className="overflow-x-auto min-w-full max-w-xs w-full">
                            <p className="text-[#343434] text-sm">
                            <pre>
                                <code>
                               {pre}
                                </code>
                            </pre>
                            </p>
                          </div>
                          <span className="flex items-center justify-center border rounded bg-white w-9 h-9 cursor-pointer ml-auto absolute right-1 top-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.058"
                              height="17.436"
                              viewBox="0 0 15.058 17.436"
                            >
                              <path
                                id="Icon_material-content-copy"
                                data-name="Icon material-content-copy"
                                d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z"
                                transform="translate(-3 -1.5)"
                                fill="#5269c9"
                              />
                            </svg>
                          </span>
                        </div>
                        <p className="text-sm leading-6">
                          Note: Once you’ve added the code on your website then you need to reload your website to see
                          the widget code. Widget didn’t appear on your website?{" "}
                          <Link className="text-sm text-blue-500">Verify installation</Link>
                        </p>
                      </div>
                    </div>:null }

                    {depWeb == "wordpress" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                            To view step of adding Appy Pie Chatbot account's widget in Wordpress website check out the help doc  <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in wordpress
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe
                          className="w-full h-96 my-5"
                          src="https://www.youtube.com/embed/Gfut4qu1lCw"
                          title="How to deploy Appy Pie chatbot's widget in wordpress?"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </div>
                    </div>:null }

                    {depWeb == "wix" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Wix website check out the help doc   <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in wix
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/IjqzXH8CJmk" title="How to deploy Appy Pie chatbot widget in wix?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "squarespace" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Squarespace website check out the help doc  <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in squarespace
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/kgmgzSDgsI8" title="How to deploy Appy Pie chatbot's widget in squarespace website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "shopify" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Shopify website check out the help doc  <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in shopify
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/Tk8_JfSX5aA" title="How to deploy Appy Pie chatbot's widget in shopify?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "weebly" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Weebly website check out the help doc  <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in weebly
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/7afrwjB3Yds" title="How you can deploy Appy Pie Chatbot’s widget on your Weebly website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "godaddy" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Godaddy website check out the help doc  <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in godaddy
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/Tjj6sRmXiqw" title="How you can deploy Appy Pie Chatbot’s widget on your Godaddy website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "magento" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        To view step of adding Appy Pie Chatbot account's widget in Magento website check out the help doc  <Link className="text-sm text-blue-500">
                        How to deploy Appy Pie Chatbot's widget in magento
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/uf96mx5-3Zk" title="How you can deploy Appy Pie Chatbot’s widget on your Magento website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }

                    {depWeb == "webflow" ? 
                    <div className="botWordpress customeWebsiteWidget px-5 py-5">
                      <div className="widgetParaGraphGuideLine py-5">
                        <p className="text-sm">
                        How to deploy Appy Pie Chatbot's widget in webflow  <Link className="text-sm text-blue-500">
                        To view step of adding Appy Pie Chatbot account's widget in Webflow website check out the help doc
                        </Link>
                        </p>
                        <p className="text-sm">You can also check out this video added below:</p>
                      </div>
                      <div className="watchGuideVideo py-5 px-5 bg-[#F4F5F9]">
                        <iframe className="w-full h-96 my-5" src="https://www.youtube.com/embed/1SGHLFzfn9M" title="How you can deploy Appy Pie Chatbot’s widget on your Webflow website?" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                      </div>
                    </div>:null }
                  </div>
                </div>
              </div>}
              {/* comment */}
              {radioApp=="socialapp" && <SocialApps apps = {propToPass}/>}
              {radioApp=="sdk"&&<div className="hidden peer-checked/setup:block border-t-[1px] border-[#BFBFBF] mt-5 w-full">
              <div className="deployBoatInWebsiteRow flex">
                  <div className="deployeWebsiteLink border-r-[1px] border-[#BFBFBF] w-3/12">


                  {setup.sdk.map((item) => {
                    return <SetupListItem key = {item.setupType} depWeb = {depWeb} setupType = {item.setupType} imageUrl = {item.imageUrl} name = {item.name} arrowUrl = {item.arrowUrl} selectDeployWeb = {selectDeployWeb}/>

                    })}


                    {/* <div className={depWeb == "iosSdk" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer":"depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("iosSdk")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/ios.png")} />
                      </span>
                      <span className="deplWebName text-sm">iOS SDK</span>
                      <span className={depWeb == "iosSdk" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div>
                    <div className={depWeb == "androidSdk" ? "depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb bg-[#F2F5FF] hover:bg-[#F2F5FF] cursor-pointer":"depWebName flex items-center px-5 py-3 border-b-[1px] border-[#BFBFBF] group/depWeb hover:bg-[#F2F5FF] cursor-pointer"} onClick={()=>selectDeployWeb("androidSdk")}>
                      <span className="deplWebLogo flex items-center justify-center w-8 h-8 mr-3">
                        <img className="w-full h-full" src={require("../../img/android.png")} />
                      </span>
                      <span className="deplWebName text-sm">Android SDK</span>
                      <span className={depWeb == "androidSdk" ?  "deplWebArrow ml-auto visible group-hover/depWeb:visible" : "deplWebArrow ml-auto invisible group-hover/depWeb:visible"}>
                        <img src={require("../../img/rightArrow.png")} />
                      </span>
                    </div> */}
                  </div>
                  <div className="deployeWebsiteDetails w-9/12">

                    {depWeb == "iosSdk" ? 
                    <div className="customeWebsite customeWebsiteWidget px-5 py-5">
                      
                      <div className="widgetParaGraphGuideLine pb-5 flex items-center">
                        <h4 className="text-base font-medium">Chatbot SDK initialization in iOS</h4>
                        <button className="ml-auto border border-indigo-600 text-sm px-3 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white ">Download SDK</button>
                      </div>
                      <div className="watchGuideCode">
                        <ol className="text-sm list-decimal px-5 leading-6" >
                            <li className="py-2">Download Chatbot SDK from here</li>
                            <li className="py-2">First Add ChatAgentFramework to your project using below steps:</li>
                            <ol className="text-sm  list-[upper-roman] leading-6 px-5 leading-6">
                                <li className="py-2">You can directly drag and drop the framework into the project using Xcode with your open project or by adding the same in the project files where your project is available in your system</li>
                                <li className="py-2">Then tap on your project name on the top left corner in your Xcode, right hand side you will have “General” setting of your project slightly scroll to the bottom you will get “Framework, Libraries, and Embedded Content”</li>
                                <li className="py-2">Tap on + icon just below the option above</li>
                                <li className="py-2">Tapping on that, there will be a popup “Choose frameworks and libraries to add:"</li>
                                <li className="py-2">On the bottom left corner there will be a button “Add Other” with bottom arrow</li>
                                <li className="py-2">Tap on the button “Add Other” then tap on the option “Add Files…”</li>
                                <li className="py-2">Tapping on the button “Add Files…” it will open your project directory where you can select your framework “ChatAgentFrameworkDev.framework” for device and “ChatAgentFrameworkSim.framework” for simulator as per the name given while creating your framework.</li>
                                <li className="py-2">Finally, after selecting your framework tap on the “Open” on the bottom right corner.</li>
                                <p className="text-sm">By following the all above steps, your framework will added to your project.</p>
                            </ol>
                        </ol>
                        <p className="text-sm pt-3">3. Add Chatbot Framework to your project from:</p>
                        <div className="codeSDKviewEditor py-2">
                            <div className="codeSDKviewEditorHeader flex items-center bg-[#3c4257] rounded-t-lg px-4 py-2">
                                <p className="text-white text-sm">Path</p>  
                                <span className="inline-block ml-auto cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.058" height="17.436" viewBox="0 0 15.058 17.436">
                                        <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z" transform="translate(-3 -1.5)" fill="#fff" />
                                    </svg>
                                </span>  
                            </div>
                            <div className="codeSDKviewEditorBody bg-[#4f566b] px-4 py-3 rounded-b-lg text-white text-sm overflow-x-auto min-w-full max-w-xs w-full">
                                <pre>
                                    <code>
                                        pod 'Firebase/Firestore'<br />
                                        pod 'Firebase/Auth'<br />
                                        pod 'Firebase/Messaging'<br />
                                        pod 'Firebase/Storage'<br />
                                        pod 'ADCountryPicker'<br />
                                        pod 'PaddingLabel'<br />
                                        pod 'TwilioVoice'<br />
                                        pod 'AMDots'<br />
                                        pod 'DropDown'<br />
                                        pod 'Cosmos'<br />
                                        pod 'SwiftJWT'<br />
                                        pod 'RichString'<br />
                                        pod 'SVGKit'<br />
                                        pod 'IQKeyboardManagerSwift'
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <p className="text-sm pt-3">4. Make sure in your build settings -&gt; Validate Workspace - YES</p>
                        <p className="text-sm pt-3">5. Add the below code from where you want to open Chatbot</p>
                        <div className="codeSDKviewEditor py-2">
                            <div className="codeSDKviewEditorHeader flex items-center bg-[#3c4257] rounded-t-lg px-4 py-2">
                                <p className="text-white text-sm">Path</p>  
                                <span className="inline-block ml-auto cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.058" height="17.436" viewBox="0 0 15.058 17.436">
                                        <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z" transform="translate(-3 -1.5)" fill="#fff" />
                                    </svg>
                                </span>  
                            </div>
                            <div className="codeSDKviewEditorBody bg-[#4f566b] px-4 py-3 rounded-b-lg text-white text-sm overflow-x-auto min-w-full max-w-xs w-full">
                                <pre>
                                    <code>
                                        let frameWorkBundle = Bundle(identifier: "com.appypiellc.appypiellc.LiveChatFramework")<br />
                                        let storyBoard = UIStoryboard(name: "Main", bundle: frameWorkBundle)<br />
                                        let mainViewController = storyBoard.instantiateViewController(withIdentifier: "ChatViewController") as? ChatViewController<br />
                                        mainViewController?.manifestComapnyID =le80eo67<br />
                                        mainViewController?.manifestChatbotId = BOTID1678787934997<br />
                                        mainViewController?.manifestAgentId = AGENT1676606812639<br />
                                        mainViewController?.modalPresentationStyle = .fullScreen<br />
                                        self.present(mainViewController!, animated: true, completion: nil)<br />
                                    </code>
                                </pre>
                            </div>
                        </div>
                        <p className="text-sm pt-3">6. Last step : Run your application</p>
                      </div>
                      
                    </div>:null }

                    {depWeb == "androidSdk" ? 
                    <div className="customeWebsite customeWebsiteWidget px-5 py-5">
                      
                    <div className="widgetParaGraphGuideLine pb-5 flex items-center">
                      <h4 className="text-base font-medium">Steps of importing ChatBot are file in android Studio</h4>
                      <button className="ml-auto border border-indigo-600 text-sm px-3 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white ">Download SDK</button>
                    </div>
                    <div className="watchGuideCode">
                      <ol className="text-sm list-decimal px-5 leading-6" >
                          <li className="py-2">Download Chatbot SDK from here</li>
                          <li className="py-2">Generate google-services.json file from <Link>firebase console</Link> and add inside 'app' folder</li>
                          <li className="py-2">Create a ‘libs’ folder in the ‘app’ folder in the project and add Chatbot aar file downloaded in step 1.</li>
                          
                      </ol>
                      <p className="text-sm pt-3">4. Add dependencies code in the app build.gradle file</p>
                      <div className="codeSDKviewEditor py-2">
                          <div className="codeSDKviewEditorHeader flex items-center bg-[#3c4257] rounded-t-lg px-4 py-2">
                              <p className="text-white text-sm">Add dependencies code in the app build.gradle file</p>  
                              <span className="inline-block ml-auto cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15.058" height="17.436" viewBox="0 0 15.058 17.436">
                                      <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z" transform="translate(-3 -1.5)" fill="#fff" />
                                  </svg>
                              </span>  
                          </div>
                          <div className="codeSDKviewEditorBody bg-[#4f566b] px-4 py-3 rounded-b-lg text-white text-sm overflow-x-auto min-w-full max-w-xs w-full">
                              <pre>
                                  <code>
                                    dependencies {'{'}<br />
                                    //============= lifeCycle annotation processor dependency for java 8 =========<br />
                                    implementation "androidx.lifecycle:lifecycle-common-java8:$lifecycle_version"<br />
                                    //=============nsions of viewModel, liveData etc =======<br />
                                    implementation "androidx.lifecycle:lifecycle-extensions:$lifecycle_version"<br />
                                    //============= used in User authentication by fireBase ======================<br />
                                    //api "com.google.firebase:firebase-auth:$firebase_auth"<br />
                                    //============= used to upload and share contents like images,videos and file on fireBase cloud storage =========<br />
                                    implementation "com.google.firebase:firebase-core:$firebase_core"<br />
                                    api "com.android.volley:volley:$volley_version"<br />
                                    implementation "com.google.android.material:material:$google_material"<br />
                                    implementation "androidx.appcompat:appcompat:$androidx_appcompat"<br />
                                    implementation "androidx.core:core-ktx:$androidx_ktx"<br />
                                    api "androidx.activity:activity-ktx:$activity_ktx"<br />
                                    api "androidx.fragment:fragment-ktx:$fragment_ktx"<br />
                                    implementation "org.jetbrains.kotlin:kotlin-reflect:$kotlin_version"<br />
                                    // Dimens<br />
                                    api "com.intuit.sdp:sdp-android:$sdp_android"<br />
                                    api "com.intuit.ssp:ssp-android:$sdp_android"<br />
                                    api "androidx.constraintlayout:constraintlayout:$androidx_constraintlayout"<br />
                                    // glide<br />
                                    implementation "com.github.bumptech.glide:glide:$glide"<br />
                                    implementation "com.google.firebase:firebase-storage-ktx:$firebase_storage"<br />
                                    kapt "com.github.bumptech.glide:compiler:$glide_compiler"<br />
                                    // No need to change<br />
                                    //=================== used for static visual elements ============<br />
                                    implementation "com.caverock:androidsvg-aar:$caverock_svg"<br />
                                    //============= Used to show colors ===============<br />
                                    implementation "com.github.matthewyork:ColoursLibrary:$coloursLibrary"<br />
                                    api "com.google.firebase:firebase-firestore:$firebase_firestore"<br />
                                    implementation "com.google.firebase:firebase-auth-ktx:$firebase_auth_ktx"<br />
                                    //implementation("com.android.support:support-annotations:$support_annotations")<br />
                                    //================ Used in fileUtils in ChatBotActivity =========================<br />
                                    implementation "commons-io:commons-io:$commons_io"<br />
                                    {'}'}<br />
                                    apply plugin: 'com.google.gms.google-services'<br />
                                  </code>
                              </pre>
                          </div>
                      </div>
                      <p className="text-sm pt-3">4. Following dependencies inside buildscript project build.gradle file</p>
                      <div className="codeSDKviewEditor py-2">
                          <div className="codeSDKviewEditorHeader flex items-center bg-[#3c4257] rounded-t-lg px-4 py-2">
                              <p className="text-white text-sm">Following dependencies inside buildscript project build.gradle file</p>  
                              <span className="inline-block ml-auto cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15.058" height="17.436" viewBox="0 0 15.058 17.436">
                                      <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z" transform="translate(-3 -1.5)" fill="#fff" />
                                  </svg>
                              </span>  
                          </div>
                          <div className="codeSDKviewEditorBody bg-[#4f566b] px-4 py-3 rounded-b-lg text-white text-sm overflow-x-auto min-w-full max-w-xs w-full">
                              <pre>
                                  <code>
                                    //=========== It should be above plugins for latest android studio =================<br />
                                    buildscript {'{'}<br />
                                    repositories {'{'}<br />
                                    mavenCentral()<br />
                                    {'}'}<br />
                                    dependencies {'{'}<br />
                                    classpath "com.google.gms:google-services:4.3.2"<br />
                                    {'}'}<br />
                                    {'}'}<br />
                                    ext.kotlin_version = "1.4.32"<br />
                                    ext {'{'}<br />
                                    google_services = '4.3.2'<br />
                                    androidx_ktx = '1.3.2'<br />
                                    androidx_appcompat = '1.2.0'<br />
                                    google_material = '1.3.0'<br />
                                    androidx_constraintlayout = '2.0.4'<br />
                                    androidx_liveData = '2.3.0'<br />
                                    androidx_viewModel = '2.3.0'<br />
                                    junit='4.12'<br />
                                    androidx_espresso='3.3.0'<br />
                                    firebase_core ='19.0.1'<br />
                                    lifecycle_version='2.2.0'<br />
                                    firebase_core='17.2.1'<br />
                                    volley_version='1.2.1'<br />
                                    activity_ktx='1.2.3'<br />
                                    fragment_ktx='1.3.5'<br />
                                    sdp_android='1.0.6'<br />
                                    glide='4.12.0'<br />
                                    glide_compiler='4.12.0'<br />
                                    caverock_svg='1.4'<br />
                                    coloursLibrary='1.0.+@aar'<br />
                                    firebase_firestore = '23.0.3'<br />
                                    firebase_auth_ktx = '21.0.1'<br />
                                    //support_annotations ='28.0.0'<br />
                                    commons_io='2.7'<br />
                                    firebase_auth='21.0.1'<br />
                                    firebase_storage='20.0.1'<br />
                                  </code>
                              </pre>
                          </div>
                      </div>
                      <p className="text-sm pt-3">6. in app level manifest file</p>
                      <div className="codeSDKviewEditor py-2">
                          <div className="codeSDKviewEditorHeader flex items-center bg-[#3c4257] rounded-t-lg px-4 py-2">
                              <p className="text-white text-sm">in app level manifest file</p>  
                              <span className="inline-block ml-auto cursor-pointer">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15.058" height="17.436" viewBox="0 0 15.058 17.436">
                                      <path id="Icon_material-content-copy" data-name="Icon material-content-copy" d="M6.963,1.5h9.51a1.59,1.59,0,0,1,1.585,1.585v11.1H16.473V3.085H6.963ZM4.585,4.67H13.3a1.59,1.59,0,0,1,1.585,1.585v11.1A1.59,1.59,0,0,1,13.3,18.936H4.585A1.59,1.59,0,0,1,3,17.351V6.255A1.59,1.59,0,0,1,4.585,4.67Zm0,12.681H13.3V6.255H4.585Z" transform="translate(-3 -1.5)" fill="#fff" />
                                  </svg>
                              </span>  
                          </div>
                          <div className="codeSDKviewEditorBody bg-[#4f566b] px-4 py-3 rounded-b-lg text-white text-sm overflow-x-auto min-w-full max-w-xs w-full">
                              <pre>
                                  <code>
                                    tools:replace="android:allowBackup,android:icon"
                                  </code>
                              </pre>
                          </div>
                      </div>
                      <p className="text-sm pt-3">7. Last step : Run your application</p>
                    </div>
                    
                  </div>:null }

                  </div>
                </div>
              </div>}
              
          </>

         }
          </div>
        </div>
    </>
  );
};

export default Setup;
