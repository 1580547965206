import React from "react";
import WebNotification from "react-web-notifications";
//import Notification from 'react-web-notification';

export default class Notifications1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			should_render: "NO_VALUE",
			message: "NO_MESSAGE",
			list: [],
		};
		this.redirectToBot = this.redirectToBot.bind(this);
	}

	redirectToBot() {
		this.props.openBot();
		window.focus();
	}

	shouldComponentUpdate() {
		if (
			this.state.should_render == "NO_VALUE" ||
			this.state.should_render == this.props.show
		) {
			this.state.should_render = this.props.show;
			return false;
		} else {
			this.state.should_render = this.props.show;
			this.state.message = this.props.body;
			this.state.list.push(
				<WebNotification
					title="New LiveChat Message"
					body={this.state.message}
					timeout={5000}
					onClickFn={this.redirectToBot}
				/>
			);
			return true;
		}
	}

	render() {
		return <div>{this.state.list}</div>;
	}
}
