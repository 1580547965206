import React from 'react'
import { useEffect,useState } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import { updateinstalledIntegrations } from '../../features/integraion/integrationSlice'
import { verifyOtp, sendOtp, installWhatsApp, uninstallWhatsApp, installSms, uninstallSms , removeGlobal, getAllAccounts } from './../../../src/utilities/chatbotMethods'
// import strings from "./../../../src/data/en.json"
import countryList from "./../../../src/data/country.json"

function SmsIntegration(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [accountKey, setAccountKey] = useState("");
    const { installedIntegrations } = useSelector(state => state.integration)
    const [countryCode , setCountryCode] = useState({})
    const [phone , setPhone] = useState("")
    const [otp , setOtp] = useState("")
    const [isError , setIsError] = useState(false)
    const [mode , setMode] = useState(props.mode)
    const [installedAccounts , setInstalledAccounts] = useState([])

    const [isVerifying , setIsVerifying] = useState(false)




    const dispatch = useDispatch();
    const handleNewNumber = () => {
        setPhone("")
        setOtp("")
        setIsVerifying(false)
    }

    const handleSelect = (event) => {
        console.log(event.target.value , 'swdfhjkgw')
        setCountryCode(event.target.value)
    }

    useEffect(() => {
        if(props.mode=="uninstall"){
            setIsLoading(false);
        }else{
        getAllAccounts("sms").then((data) => {
          if (data.length > 0) {
            setInstalledAccounts(data);
            setMode("switch")
            setIsLoading(false);
          }else{
            setIsLoading(false);
          }
        });
        }
        
        // console.log(props, "props in zendesk");

    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);


        if(isVerifying){
            verifyOtp(countryCode, phone,otp).then(status=>{
                if(status){
                    var data = {clientId : localStorage.getItem('tablealias') ,isSSMSinstalled : true , receiver : phone ,code :  countryCode}
                    setSMS(data,true)
                
            }
            })
        }else{
            sendOtp(countryCode,phone).then(status=>{
                if(status){
                    setIsError(false)
                    setIsVerifying(true)
                    setIsLoading(false);
                }else{
                    setIsError(true)
                    setIsLoading(false);
                }
            })
        }
       




    };


    const handleUninstall = () => {
        setIsLoading(true);
        uninstallSms().then(status => {
            if (status) {
                dispatch(updateinstalledIntegrations(installedIntegrations.filter(item => item !== 'sms')))
                setIsLoading(false);
                props.closePopup()

            }
        })
    }

    const setSMS = (data,addGlobal) => {
        

        installSms(data , addGlobal).then((data)=>{
            dispatch(updateinstalledIntegrations(['sms',...installedIntegrations]))
            setIsLoading(false);
            props.closePopup()
            
        })
      }
    return (
        <>
            {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}

            {isLoading ? (<p>Loading...</p>) :
                mode === 'uninstall' ? (<>
                    <span> Are you sure you want to uninstall {props.appData.title} ?</span>
                    <br />
                    {isLoading ? <span>...</span> : <button onClick={() => { handleUninstall() }}> Yes,uninstall</button>}
                    <br />
                    <button onClick={() => { props.closePopup() }}>No</button>
                </>) :  mode == "switch" ? (
        <>
          <div className="mt-5">
            <h4>
              <b>Choose to continue</b>
            </h4>
            <ul className="useracc_list">
            {installedAccounts.map((item,index)=> {
                return(
                <li>
                    <div onClick={()=>{setSMS(item,false)}}>

                <span className="us_icon">{index+1}</span>
                <br/>
                <span className="us_details">
                  <span className="us_name">{item.receiver}</span>
                </span>
                </div>
                <span onClick={()=>{removeGlobal("sms",index)}} className="sign_out">Sign out</span>
              </li>
              )
            }
                )}
                <li>
                    <div onClick={()=>{setMode("install")}}>
                <span className="us_icon">
                  <img src="/dist/img/public/img/user.svg" alt="user Img" />
                </span>
                <span className="us_details">
                  <span className="us_name">Use another account</span>
                </span>
                </div>
              </li>
            </ul>
          </div>
        </>
      ):

                    (<div >
                        <div >
                            <div >
                                <img src={props.appData.img} />
                            </div>
                            <div>
                                <div >
                                    <h4>SMS</h4>
                                </div>
                                <div >
                                    <p >
                                    Receive information about visitor via SMS
                                    </p>
                                </div>
                                <div>
                                    <span >Learn more:</span>
                                </div>
                                <div />
                                <div />
                            </div>
                        </div>
                        <div>
                            <div>
                                <form onSubmit={(event) => handleSubmit(event)}>
                                    <div>
                                        {isVerifying ? <div>
                                            <label htmlFor="">Enter the OTP</label>
                                            <input type="text" value={otp} onChange={(event) => setOtp(event.target.value)} required placeHolder='Enter OTP' />
                                            <span onClick={() => handleNewNumber()}>Enter another number</span>
                                            <br />
                                        </div>:
                                        <div>

                                            <select
                                                value={countryCode}
                                                onChange={handleSelect}
                                                className='form-control'
                                            >
                                                {countryList.map((data, index) =>
                                                   (
                                                        <option
                                                            key={index}
                                                            name='code'
                                                            value={data.dial_code}
                                                        >
                                                            {data.flag +
                                                                ' ' +
                                                                data.dial_code +
                                                                ' ' +
                                                                data.name}
                                                        </option>
                                                    )
                                                )}
                                            </select>

                                            <label htmlFor="">Enter the Phone number</label>
                                            <input type="text" value={phone} onChange={(event) => setPhone(event.target.value)} required placeHolder='Enter phone number' />
                                            {isError && <span>Invalid Phone Number</span>}
                                            <br />


                                        </div>}
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <br />
                                            {isLoading ? <span>...</span> : <input type="submit" value={isVerifying ? 'Verify' :'Send Otp' }/>}
                                            <br />
                                            <span onClick={() => { props.closePopup() }}>Cancel</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>)}
        </>

    );
}

export default SmsIntegration