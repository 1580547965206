import React from 'react'
import { useEffect,useState } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import { updateinstalledIntegrations } from '../../features/integraion/integrationSlice'
import { installGoogle, uninstallGoogle } from './../../../src/utilities/chatbotMethods'
import { API_URL } from '../../config/params'


function GoogleIntegration(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [accountKey, setAccountKey] = useState("");
  const {installedIntegrations} = useSelector(state => state.integration)
  const [error, setError] = useState(false);

  


  const dispatch = useDispatch();

  useEffect(() => {



      setIsLoading(false);

      const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/client.js'
    script.async = true
    script.id = "dheerajtesting"
    script.onload = () => {
      //  const SCOPE =
      // "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/spreadsheets";
      const SCOPE = 'https://www.googleapis.com/auth/drive.file'
      window.gapi.load('client:auth2', async () => {
        window.gapi.client
          .init({
            apiKey: 'AIzaSyAP4U2zaW_4G6yBsBioRntTOXHgiq4Sqkc',
            clientId:
              '386870191393-cqhh1ubo5nc6emc3gd8o6iuts3fc969h.apps.googleusercontent.com',
            discoveryDocs: [
              'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
            ],
            response_type: 'code',
            access_type: 'offline',
            scope: SCOPE
          })
          .then(response => {
            console.log('111111-', response);
          })
          .catch(err => {
            console.log(err)
          })
      
       
      })
    }
    
    document.body.appendChild(script);
  
      console.log(props , 'props in zendesk')

  }, []);

  const gdriveLogin =  (event) => {
    setError(false)

    let GoogleAuth = window.gapi.auth2.getAuthInstance()
		GoogleAuth.grantOfflineAccess().then(res => {
		  console.log(res, "resafterauth")
		  if (res.error == undefined) {
        setIsLoading(true)
	
			  var body = JSON.stringify({
				botId: localStorage.getItem("botId"),
				botName:"Chatbot Inquiries" ,
				gdriveCode: res.code,
				tablealias:localStorage.getItem("tablealias"),
				redirectUrl:window.location.origin
			  });
        fetch("http://localhost:4089/appycx-beta/us-central1/gDrivenew/setupGoogleDrive",{

			  // fetch(API_URL+"/gDrivenew/setupGoogleDrive",{
				method: "post",
				body: body,
				headers: { "Content-Type": "application/json" },
			  })
				.catch((e) => {
				  console.log(e, "ERROR");
          setError(`Connection to Google sheet <br />
					has failed due to missing<br />
					permission`)
          setIsLoading(false)
				  // this.setState({error:`Connection to Google sheet <br />
					// has failed due to missing<br />
					// permission`,loader:false})
				})
				.then((res) => res.json())
				.then((response) => {
				  if(response.status){

       
                dispatch(updateinstalledIntegrations(['gdrive',...installedIntegrations]))
                setIsLoading(false);
                props.closePopup()
            
					
				  }else{
					
          setError(`Connection to Google sheet <br />
          has failed due to missing<br />
          permission`)
          setIsLoading(false)
				  }
				  
				});
			}})

  }



  const handleUninstall = () => {
      setIsLoading(true);
      uninstallGoogle().then(status=>{
          if(status){
              dispatch(updateinstalledIntegrations(installedIntegrations.filter(item=>item!=='gdrive')))
              setIsLoading(false);
              props.closePopup()

          }
      })
  }
return (
  <>
  {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}

  {false ? (<p>Loading...</p>) : 
  props.isInstalled ? (<>
  <span> Are you sure you want to uninstall {props.appData.title} ?</span>
  <br/>
      {isLoading ? <span>...</span> :<button onClick={()=>{handleUninstall()}}> Yes,uninstall</button>}
      <br/>
      <button onClick={()=>{props.closePopup()}}>No</button> 
  </>) : 
  
  (<div >
  <div >
    <div >
      <img src={props.appData.img} />
    </div>
    <div>
      <div >
        <h4>Google Sheets</h4>
      </div>
      <div >
        <p >
         Manage all inquiries in Google Sheet
        </p>
      </div>
      <div>
        <span >Learn more:</span>
      </div>
      <div />
      <div />
    </div>
  </div>
  <div>
    <div>
      {/* <form > */}
        <div>
          <div>
          
            <label htmlFor="">Login using your Google account and provide required permissions</label>
                            
                             
            
          </div>
        </div>
        <div className="row">
          <div className="col">
          <br/>
          {isLoading ? <span>...</span> :<button onClick={()=>gdriveLogin()}>Sign in with Google</button>}
            {error&&<p dangerouslySetInnerHTML={{__html:error}}/>}
            <br/>
           {/* <span onClick={()=>{props.closePopup()}}>Cancel</span> */}
          </div>
        </div>
      {/* </form> */}
    </div>
  </div>
</div>)}
</>
  
);
}

export default GoogleIntegration