import React from "react"
import { Link } from "react-router-dom"
import SideMenu from "../../Component/sidemenu"


const Botpersonality = () =>{
    return(
        <>
            {/* <SideMenu /> */}
                <div className="boatEditSection bg-white">
                    <div className="boatSettings w-4/6">
                        <h4 className="mb-5">Bot Personality</h4>
                        <div className="botPersnalityBox flex flex-wrap gap-6">
                            <div className="boxBoat flex items-center px-5 py-5 border-2 border-[#E6EDF5] rounded-2xl hover:border-[#5269C9] cursor-pointer w-2/5 shadow-md">
                                <span className="flex items-center justify-center bg-[#F5F9FC] w-16 h-16 rounded-lg mr-5">
                                    <img src={require("../../img/none.png")} />
                                </span>
                                <p className="text-[#5C5C5C] text-base font-medium">None</p>
                            </div>
                            <div className="boxBoat flex items-center px-5 py-5 border-2 border-[#E6EDF5] rounded-2xl hover:border-[#5269C9] cursor-pointer w-2/5 shadow-md">
                                <span className="flex items-center justify-center bg-[#F5F9FC] w-16 h-16 rounded-lg mr-5">
                                    <img src={require("../../img/professional.png")} />
                                </span>
                                <p className="text-[#5C5C5C] text-base font-medium">Professional</p>
                            </div>
                            <div className="boxBoat flex items-center px-5 py-5 border-2 border-[#E6EDF5] rounded-2xl hover:border-[#5269C9] cursor-pointer w-2/5 shadow-md">
                                <span className="flex items-center justify-center bg-[#F5F9FC] w-16 h-16 rounded-lg mr-5">
                                    <img src={require("../../img/freindly.png")} />
                                </span>
                                <p className="text-[#5C5C5C] text-base font-medium">Friendly</p>
                            </div>
                            <div className="boxBoat flex items-center px-5 py-5 border-2 border-[#E6EDF5] rounded-2xl hover:border-[#5269C9] cursor-pointer w-2/5 shadow-md">
                                <span className="flex items-center justify-center bg-[#F5F9FC] w-16 h-16 rounded-lg mr-5">
                                    <img src={require("../../img/witty.png")} />
                                </span>
                                <p className="text-[#5C5C5C] text-base font-medium">Witty</p>
                            </div>
                            <div className="boxBoat flex items-center px-5 py-5 border-2 border-[#E6EDF5] rounded-2xl hover:border-[#5269C9] cursor-pointer w-2/5 shadow-md">
                                <span className="flex items-center justify-center bg-[#F5F9FC] w-16 h-16 rounded-lg mr-5">
                                    <img src={require("../../img/carring.png")} />
                                </span>
                                <p className="text-[#5C5C5C] text-base font-medium">Caring</p>
                            </div>
                            <div className="boxBoat flex items-center px-5 py-5 border-2 border-[#E6EDF5] rounded-2xl hover:border-[#5269C9] cursor-pointer w-2/5 shadow-md">
                                <span className="flex items-center justify-center bg-[#F5F9FC] w-16 h-16 rounded-lg mr-5">
                                    <img src={require("../../img/Enthusiastic.png")} />
                                </span>
                                <p className="text-[#5C5C5C] text-base font-medium">Enthusiastic</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="boatDisplay w-80 ml-auto">
                        <div className="dimensialSection flex items-center">
                            <div className="dimensialTabMobile flex bg-[#F0F0F0] rounded px-2 py-2 mr-auto">
                                <span className="desktop hover:bg-white bg-white px-3 py-2 rounded cursor-pointer ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.967" height="15.967" viewBox="0 0 15.967 15.967">
                                        <path id="Icon_ionic-md-desktop" data-name="Icon ionic-md-desktop" d="M16.7,2.25H3.771A1.525,1.525,0,0,0,2.25,3.771V13.845a1.525,1.525,0,0,0,1.521,1.521H8.713L7.192,17.076v1.14h6.083v-1.14l-1.521-1.711H16.7a1.525,1.525,0,0,0,1.521-1.521V3.771A1.525,1.525,0,0,0,16.7,2.25Zm0,10.264H3.771V3.771H16.7Z" transform="translate(-2.25 -2.25)" fill="#637ce6" />
                                    </svg>
                                </span>
                                <span className="mobile hover:bg-white px-3 py-2 rounded cursor-pointer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.553" height="15.284" viewBox="0 0 9.553 15.284">
                                        <path id="Icon_awesome-mobile-alt" data-name="Icon awesome-mobile-alt" d="M8.12,0H1.433A1.433,1.433,0,0,0,0,1.433V13.851a1.433,1.433,0,0,0,1.433,1.433H8.12a1.433,1.433,0,0,0,1.433-1.433V1.433A1.433,1.433,0,0,0,8.12,0ZM4.776,14.329a.955.955,0,1,1,.955-.955A.954.954,0,0,1,4.776,14.329ZM8.12,11.1a.359.359,0,0,1-.358.358H1.791a.359.359,0,0,1-.358-.358V1.791a.359.359,0,0,1,.358-.358h5.97a.359.359,0,0,1,.358.358Z" fill="#637ce6" />
                                    </svg>
                                </span>
                            </div>
                            <span className="close flex items-center justify-center bg-[#6D85EB] w-7 h-7 rounded-full cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.499" height="10.499" viewBox="0 0 10.499 10.499">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M18,8.557,16.941,7.5l-4.192,4.192L8.557,7.5,7.5,8.557l4.192,4.192L7.5,16.941,8.557,18l4.192-4.192L16.941,18,18,16.941l-4.192-4.192Z" transform="translate(-7.5 -7.5)" fill="#fff" />
                                </svg>
                            </span>
                        </div>
                        <div className="boatSection bg-[#F7FCFF] border border-[#D8D8D8] rounded-xl px-3 py-3 mt-4 ">
                            <div className="mainBoat shadow-md rounded-b-2xl">
                                <div className="boatHeader bg-[#5269C9] rounded-t-2xl px-4 py-4 flex items-center">
                                    <span className="flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-4 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                             <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                        </svg>
                                    </span>
                                   <span className="inline-block text-white font-semibold text-base"> GPT bot</span>
                                </div>
                                <div className="boatBody bg-white px-4 py-3">
                                    <h6 className="text-center pb-2 text-sm">Powered by Userbot</h6>
                                    <div className="sendMessage flex items-baseline mb-5">
                                        <div className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12">
                                            <p className="text-[#272727]  text-sm leading-6">Hi! I am businees, a friendly bot, Welcome to Appy Pie LLP.</p>
                                        </div>
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                                <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="recivedMessage flex mb-5">
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                                <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                            </svg> 
                                        </span>
                                        <div className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12">
                                            <p className="text-[#272727]  text-sm leading-6">How old are you?</p>
                                        </div>
                                    </div>
                                    <div className="sendMessage flex items-baseline mb-5">
                                        <div className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12">
                                            <p className="text-[#272727]  text-sm leading-6">Age doesn’t really apply to me.</p>
                                        </div>
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26.02" height="20.816" viewBox="0 0 26.02 20.816">
                                                <path id="Icon_awesome-robot" data-name="Icon awesome-robot" d="M0,10.408v5.2a1.3,1.3,0,0,0,1.3,1.3H2.6V9.107H1.3A1.3,1.3,0,0,0,0,10.408ZM18.864,3.9H14.311V1.3a1.3,1.3,0,0,0-2.6,0V3.9H7.155A3.252,3.252,0,0,0,3.9,7.155V18.214a2.6,2.6,0,0,0,2.6,2.6h13.01a2.6,2.6,0,0,0,2.6-2.6V7.155A3.252,3.252,0,0,0,18.864,3.9Zm-8.456,13.01h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,9.107,12.034Zm5.2,4.879h-2.6v-1.3h2.6Zm3.9,0h-2.6v-1.3h2.6Zm-1.3-4.879a1.626,1.626,0,1,1,1.626-1.626A1.626,1.626,0,0,1,16.913,12.034Zm7.806-2.927h-1.3v7.806h1.3a1.3,1.3,0,0,0,1.3-1.3v-5.2A1.3,1.3,0,0,0,24.719,9.107Z" fill="#f7f8f8" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <div className="boatFooter bg-white px-4 pb-4 rounded-b-2xl">
                                    <div className="flex items-center bg-[#F4F5FA] rounded-md px-3 py-2">
                                        <input type="text" placeholder="Type your Message" className="text-sm w-9/12 border-0 focus:outline-none bg-[#F4F5FA]" />
                                        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-7 h-7 cursor-pointer ml-auto">
                                             <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 12.793 12.792">
                                                <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M16.892,4.531,4.661,9.862a.28.28,0,0,0,.01.51L7.98,12.241a.533.533,0,0,0,.61-.06l6.524-5.624c.043-.037.147-.107.187-.067s-.023.143-.06.187L9.6,13.034a.532.532,0,0,0-.053.636L11.7,17.139a.281.281,0,0,0,.506-.007L17.269,4.9A.28.28,0,0,0,16.892,4.531Z" transform="translate(-4.503 -4.503)" fill="#fff" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
        </>
    )
}

export default Botpersonality