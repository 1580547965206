// import React, { useState } from 'react';
// import { EditorState , convertToRaw , convertFromRaw , Modifier} from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { useState } from 'react';
import { EditorState, convertToRaw, Modifier  , ContentState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './editor.css'
import { useEffect } from 'react';

const RichTextEditor = (props) => {


  //react-draft-wysisyg is a controlled editor just like controlled components in react
  //initializing the EditorState
  // const [editorState, setEditorState] = useState(EditorState.createEmpty());

  //destructuring props
  //state of the rich text editor coming from the parent component and the data of the selected node is coming from the parent
  const {editorState , setEditorState , selectedNode} = props


  //callback function
  //triggers when there is a change happen in the editor
  //recieves the new EditorState object
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    
  };


  //will be called when the editor loads or the global state --- selectedNode changes
  //set the editor state to the selected node text
  // useEffect(() => {
  //     setEditorState(EditorState.createWithContent(ContentState.createFromText(selectedNode.data.data.text)))
  // },[selectedNode])


  //callback function
  //event -- paste in the editor
  //convert the pasted text into plain text
  const handlePastedText = (text, html, editorState) => {
    const plainText = text.replace(/(<([^>]+)>)/gi, '');
    const contentState = editorState.getCurrentContent();
    const contentStateWithText = Modifier.insertText(contentState, contentState.getSelectionAfter(), plainText);

    const newEditorState = EditorState.push(editorState, contentStateWithText, 'insert-characters');
    setEditorState(newEditorState);

    return 'handled';
  };


  //callback function
  //event -- pressing the backspace button
  //clears the selected text

  const handleKeyCommand = (command, editorState) => {
    if (command === 'backspace') {
      // Handle the backspace key command
      const selection = editorState.getSelection();
      if (selection.isCollapsed() && selection.getStartOffset() === 0) {
        // Clear the selected text if any
        const contentState = editorState.getCurrentContent();
        const clearedContentState = Modifier.removeRange(contentState, selection, 'backward');
        const newEditorState = EditorState.push(editorState, clearedContentState, 'remove-range');
        setEditorState(newEditorState);

        return 'handled';
      }
    }

    return 'not-handled';
  };


  //@CUSTOM TOOLBAR 
  const toolbar = {
    options: ['inline', 'link' , 'list' ,'image' ,  'history' , 'fontSize', 'fontFamily',  'blockType'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline'],
      
    },
    list: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['unordered', 'ordered'],
      
    }
    
  }

  return (
    <>
      <Editor
        editorState={editorState}
        toolbar = {toolbar}
        wrapperClassName="demo-wrapper"
        editorClassName="edit-field text-[14px]"
        onEditorStateChange={onEditorStateChange}
        handlePastedText={handlePastedText}
        handleKeyCommand={handleKeyCommand}
      />

      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </>
  );
};

export default RichTextEditor;





// EditorState.createEmpty()











