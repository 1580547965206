import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import strings from './../../data/en.json'
import { createBot } from '../../utilities/chatbotMethods'
import { NotificationManager } from 'react-notifications'

function SubscribeSuccess() {
    const navigate = useNavigate()
    const [botLink,setBotLink] = useState('')
    const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {

        if(sessionStorage.getItem("botType") && sessionStorage.getItem("botName")){
            const foundBot = strings.botList.find(botObj => botObj.id==sessionStorage.getItem("botType"))
           
            const newBotType = foundBot.botType ? foundBot.botType : foundBot.button.toLocaleLowerCase().replace(' bot' , '').trim().replace(/[\s!@#$%^&*()_+{}\[\]:;<>,.?\/\\\-='"]/g, '-')
            localStorage.setItem('bot_type' , newBotType)
            localStorage.setItem('BOTNAME' , sessionStorage.getItem("botName"))
            localStorage.setItem('botType' , sessionStorage.getItem("botType"))
            setBotLink(`/cx/Chatbot/${newBotType}/editor?did=${localStorage.getItem('botId')}`)
            createBot(sessionStorage.getItem("botName") , sessionStorage.getItem("botType")).then((result) => {
                console.log(result , ' result of bot creation')
                if(result){
                    // navigate(`/cx/Chatbot/${newBotType}/editor?did=${newBotId}`)
                    NotificationManager.success('Bot Created Successfully' , 'You can now customise it ' , 4000)
                    sessionStorage.clear()
                    setIsLoading(false)
                }
                else{
                    NotificationManager.error('Bot Creation Failed' , 'Please try again in few minutes.' , 4000)
                    setIsLoading(false)
                }
            })

        }else{
            setIsLoading(false)

        }




    },[])

  return (<>
    {isLoading?<div>Loading...</div>:
    <>
    <div>SubscribeSuccess</div>
   <span>HAPPY HAPPY HAPPY</span>
   <video controls>
            <source src="https://www.videomeme.in/wp-content/uploads/2023/04/happy-happy-happy-cat.mp4" type="video/mp4" />
            Your browser does not support the video tag.
    </video>
    <br/>

   <button>Book Appt</button>
   <br/>
   <button onClick={()=>{navigate(botLink)}}>Go to Dashboard</button>
   </>
    }
    
    </>
  )
}

export default SubscribeSuccess