import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { COMMON_BILLING_URL } from "../config/params";
import SettingOption from "./sideOptions";

const SideMenu = (props) => {
  // const [setting, setSetting] = useState("");
  // const sideSubmenu = (e) => {
  //   setSetting(e);
  //   console.log(e);
  // };
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);

  const {subscriptionUrl} = useSelector((state) => state.payment)


  const activeCss = "flex p-3 items-center text-white active-bg"
  const inactiveCss = "flex p-3 items-center text-white hover-div group text-sm hover:cursor-pointer"
  useEffect(() => {
    // console.log("props.url", window.location.pathname);
    
    const currentURL = location.pathname;

    let activeMenu;
    
    switch (true) {
        case currentURL.includes("cx/setting"):
            activeMenu = "settings";
            break;
    
        case currentURL.includes("cx/analytics"):
            activeMenu = "analytics";
            break;
    
        case currentURL.includes("cx/appointment"):
            activeMenu = "appointment";
            break;
    
        case currentURL.includes("cx/Chatbot"):
            activeMenu = "dashboard";
            break;
    
        case currentURL.includes("cx/chatBoatTemplate"):
            activeMenu = "template";
            break;
    
        case currentURL === "http://example.com/": // Adjust this with your base URL
            activeMenu = "dashboard";
            break;
    
        default:
            activeMenu = null;
            break;
    }
    
    setActiveMenu(activeMenu);
  

    
  },[location]);

  const openSubscription = () => {

    const form = document.createElement("form");
  form.setAttribute("id", "historydata");
  form.setAttribute("method", "POST");
  form.setAttribute("target", "_blank");
  form.setAttribute("action", COMMON_BILLING_URL+`/billing/dashboard`);

  const input = document.createElement("input");
  input.setAttribute("type", "hidden");
  input.setAttribute("name", "request");
  input.setAttribute("value", subscriptionUrl);

  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);

  }
  



  const MenuShowHide = () => {
    var sidebarMenu = document.getElementById('sidebarMenu')
    console.log("sidebarMenu", sidebarMenu);
    sidebarMenu.classList.toggle("off-menu");
  };
  // return null
  return (

    <>{activeMenu&&
    <div
      className="w-14 lg:w-64 bg-thmBG fixed top-0 left-0 h-screen z-10"
      id="sidebarMenu"
    >
      <svg
        className="lg:hidden"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="29"
        viewBox="0 0 45 36"
      >
        <path
          id="Icon_awesome-robot"
          data-name="Icon awesome-robot"
          d="M0,18v9a2.248,2.248,0,0,0,2.25,2.25H4.5V15.75H2.25A2.248,2.248,0,0,0,0,18ZM32.625,6.75H24.75V2.25a2.25,2.25,0,0,0-4.5,0v4.5H12.375A5.623,5.623,0,0,0,6.75,12.375V31.5a4.5,4.5,0,0,0,4.5,4.5h22.5a4.5,4.5,0,0,0,4.5-4.5V12.375A5.623,5.623,0,0,0,32.625,6.75ZM18,29.25H13.5V27H18Zm-2.25-8.437A2.813,2.813,0,1,1,18.563,18,2.812,2.812,0,0,1,15.75,20.813Zm9,8.438h-4.5V27h4.5Zm6.75,0H27V27h4.5Zm-2.25-8.437A2.813,2.813,0,1,1,32.063,18,2.812,2.812,0,0,1,29.25,20.813Zm13.5-5.062H40.5v13.5h2.25A2.248,2.248,0,0,0,45,27V18A2.248,2.248,0,0,0,42.75,15.75Z"
          fill="#fff"
        />
      </svg>
      <img
        className="hidden lg:block w-40 py-3"
        src={require("../img/logo.png")}
        alt="Logo"
      />
      <div className="border-t-[1px]">
        <div className="relative pt-6">
          <div className="top-0 left-0 w-full" onClick={()=>{setActiveMenu("dashboard")}}>
            <Link to="/cx/Chatbot
            ">
            <h3 className={activeMenu=="dashboard"?activeCss:inactiveCss} >
              <svg className="lg:mr-3 lg:ml-0 sm:m-auto" xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 45 36">
                <path
                  id="Icon_awesome-robot"
                  data-name="Icon awesome-robot"
                  d="M0,18v9a2.248,2.248,0,0,0,2.25,2.25H4.5V15.75H2.25A2.248,2.248,0,0,0,0,18ZM32.625,6.75H24.75V2.25a2.25,2.25,0,0,0-4.5,0v4.5H12.375A5.623,5.623,0,0,0,6.75,12.375V31.5a4.5,4.5,0,0,0,4.5,4.5h22.5a4.5,4.5,0,0,0,4.5-4.5V12.375A5.623,5.623,0,0,0,32.625,6.75ZM18,29.25H13.5V27H18Zm-2.25-8.437A2.813,2.813,0,1,1,18.563,18,2.812,2.812,0,0,1,15.75,20.813Zm9,8.438h-4.5V27h4.5Zm6.75,0H27V27h4.5Zm-2.25-8.437A2.813,2.813,0,1,1,32.063,18,2.812,2.812,0,0,1,29.25,20.813Zm13.5-5.062H40.5v13.5h2.25A2.248,2.248,0,0,0,45,27V18A2.248,2.248,0,0,0,42.75,15.75Z"
                  fill={activeMenu=="dashboard"?"#0351b1":"#fff"}
                />
              </svg>
              <span className="hidden lg:block">My Chatbots</span>
            </h3>
            </Link>
          </div>
          <div className="flex flex-wrap h-[90vh]">
            <ul className="w-full">
              <li className="py-3" onClick={()=>{setActiveMenu("appointment")}}>
                <Link to="/cx/appointment/calendar">
                <h3 className={activeMenu=="appointment"?activeCss:inactiveCss}>
                  {/* <a>dhee</a> */}
                  <svg
                    className="lg:mr-3 lg:ml-0 sm:m-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.5"
                    height="20"
                    viewBox="0 0 17.5 20"
                  >
                    <path
                      id="Icon_awesome-calendar-alt"
                      data-name="Icon awesome-calendar-alt"
                      d="M0,18.125A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V7.5H0Zm12.5-7.656A.47.47,0,0,1,12.969,10h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,12.969,15h1.563a.47.47,0,0,1,.469.469v1.562a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,7.969,10H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,7.969,15H9.531a.47.47,0,0,1,.469.469v1.562a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,2.969,10H4.531A.47.47,0,0,1,5,10.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,2.969,15H4.531A.47.47,0,0,1,5,15.469v1.562a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469ZM15.625,2.5H13.75V.625A.627.627,0,0,0,13.125,0h-1.25a.627.627,0,0,0-.625.625V2.5h-5V.625A.627.627,0,0,0,5.625,0H4.375A.627.627,0,0,0,3.75.625V2.5H1.875A1.875,1.875,0,0,0,0,4.375V6.25H17.5V4.375A1.875,1.875,0,0,0,15.625,2.5Z"
                      fill={activeMenu=="appointment"?"#0351b1":"#fff"}
                      className="group-hover:fill-[#0351B1]"
                    />
                  </svg>
                  {/* <img className='w-[18px] h-[18px] mr-2' src={require('../img/calendar.png')} />  */}
                  <span className="hidden lg:block">Appointments</span>
                </h3>
                </Link>
              </li>
              <li className="py-3" onClick={()=>{setActiveMenu("template")}}>
              <Link to="/cx/chatBoatTemplate">
                <h3 className={activeMenu=="template"?activeCss:inactiveCss}>
                  <svg className="lg:mr-3 lg:ml-0 sm:m-auto" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                    <defs>
                      <clipPath id="clipPath">
                        <rect
                          id="Rectangle_1792"
                          data-name="Rectangle 1792"
                          width="19"
                          height="19"
                          transform="translate(0 0.384)"
                          fill={activeMenu=="template"?"#0351b1":"#fff"}
                          className="group-hover:fill-[#0351B1]"
                        />
                      </clipPath>
                    </defs>
                    {/* </svg> */}
                    <g id="Group_13237" data-name="Group 13237" transform="translate(0 -0.384)">
                      <g id="Group_13234" data-name="Group 13234">
                        <g id="Group_13238" data-name="Group 13238">
                          <g id="Group_13232" data-name="Group 13232">
                            <g id="Group_13231" data-name="Group 13231" clipPath="url(#clip-path)">
                              <path
                                id="Path_9209"
                                data-name="Path 9209"
                                d="M0,9.63v-9A.954.954,0,0,1,.63,0H8.074A.875.875,0,0,1,8.7.88c0,2.838,0,5.675-.008,8.513a1.142,1.142,0,0,1-.163.553.778.778,0,0,1-.741.325c-2.264,0-4.528-.009-6.792,0A.9.9,0,0,1,0,9.63"
                                fill="#fff"
                                className="group-hover:fill-[#0351B1]"
                              />
                              <path
                                id="Path_9210"
                                data-name="Path 9210"
                                d="M277.322,0h7.444a.92.92,0,0,1,.635,1c-.015,1.74-.005,3.48-.006,5.221a.8.8,0,0,1-.879.889h-6.942a.8.8,0,0,1-.871-.861q-.006-2.694,0-5.387a.794.794,0,0,1,.289-.671,2.753,2.753,0,0,1,.33-.191"
                                transform="translate(-266.433)"
                                fill="#fff"
                                className="group-hover:fill-[#0351B1]"
                              />
                              <path
                                id="Path_9211"
                                data-name="Path 9211"
                                d="M0,325.71v-5.852a.921.921,0,0,1,1-.635c2.252.013,4.5.006,6.756.006a.82.82,0,0,1,.937.927q0,2.628,0,5.257a.82.82,0,0,1-.937.927c-2.252,0-4.5-.007-6.756.006a.921.921,0,0,1-1-.635"
                                transform="translate(0 -307.377)"
                                fill="#fff"
                                className="group-hover:fill-[#0351B1]"
                              />
                              <path
                                id="Path_9214"
                                data-name="Path 9214"
                                d="M285.4,239.4q0,2.111,0,4.222a.812.812,0,0,1-.908.916h-6.888a.808.808,0,0,1-.9-.893q0-4.24,0-8.481a.8.8,0,0,1,.892-.894q3.454,0,6.907,0a.8.8,0,0,1,.894.908q0,2.111,0,4.222"
                                transform="translate(-266.442 -225.579)"
                                fill="#fff"
                                className="group-hover:fill-[#0351B1]"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                      </g>
                    </svg>
                    {/* <img className='w-[18px] h-[18px] mr-2' src={require('../img/chatbot-templates.png')} /> */}
                    <span className="hidden lg:block">Chatbot Templates</span>
                  </h3>
                </Link>
              </li>
              <li className="py-3" onClick={()=>{setActiveMenu("analytics")}}>
                <Link to="cx/analytics">
                  <h3 className={activeMenu=="analytics"?activeCss:inactiveCss}>
                    <svg
                      className="mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.558"
                      height="18.558"
                      viewBox="0 0 18.558 18.558"
                    >
                      <path
                        id="Icon_simple-googleanalytics"
                        data-name="Icon simple-googleanalytics"
                        d="M17.068,18.558H1.465A1.469,1.469,0,0,1,0,17.092V13.675a1.469,1.469,0,0,1,1.465-1.466h4.4V7.326A1.469,1.469,0,0,1,7.326,5.861h4.9V1.488A1.5,1.5,0,0,1,13.709,0h3.36a1.5,1.5,0,0,1,1.488,1.489V17.068A1.5,1.5,0,0,1,17.068,18.558Z"
                        fill={activeMenu=="analytics"?"#0351b1":"#fff"}
                        className="group-hover:fill-[#0351B1]"
                      />
                    </svg>
                    {/* <img className='w-[18px] h-[18px] mr-2' src={require('../img/google-analytics.png')} />  */}
                    <span className="hidden lg:block">Analytics</span>
                  </h3>
                </Link>
              </li>
              <li className="py-3"
              onClick={()=>{setActiveMenu("settings")}}
               >
                <Link to="/cx/settings/datamanagement">
                  <h3 className={activeMenu=="settings"?activeCss:inactiveCss}>
                    <svg
                      className="mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.559"
                      height="19.079"
                      viewBox="0 0 18.559 19.079"
                    >
                      <path
                        id="Icon_material-settings"
                        data-name="Icon material-settings"
                        d="M19.776,13.475a6.578,6.578,0,0,0,0-1.87l2.013-1.574A.481.481,0,0,0,21.9,9.42l-1.908-3.3a.479.479,0,0,0-.582-.21l-2.375.954a6.97,6.97,0,0,0-1.612-.935L15.063,3.4A.465.465,0,0,0,14.6,3H10.78a.465.465,0,0,0-.467.4L9.95,5.929a7.329,7.329,0,0,0-1.612.935L5.962,5.91a.465.465,0,0,0-.582.21L3.472,9.42a.47.47,0,0,0,.114.611L5.6,11.6a7.566,7.566,0,0,0-.067.935,7.566,7.566,0,0,0,.067.935L3.587,15.049a.481.481,0,0,0-.114.611l1.908,3.3a.479.479,0,0,0,.582.21l2.375-.954a6.97,6.97,0,0,0,1.612.935l.363,2.528a.465.465,0,0,0,.467.4H14.6a.465.465,0,0,0,.467-.4l.363-2.528a7.329,7.329,0,0,0,1.612-.935l2.375.954a.465.465,0,0,0,.582-.21l1.908-3.3a.481.481,0,0,0-.114-.611l-2.013-1.574Zm-7.088,2.4a3.339,3.339,0,1,1,3.339-3.339A3.343,3.343,0,0,1,12.688,15.879Z"
                        transform="translate(-3.406 -3)"
                        fill={activeMenu=="settings"?"#0351b1":"#fff"}
                        className="group-hover:fill-[#0351B1]"
                      />
                    </svg>

                    {/* <img className='w-[18px] h-[18px] mr-2' src={require('../img/settings.png')} />  */}
                    <span className="hidden lg:block">Settings</span>
                  </h3>
                </Link>
              </li>
              {/* {setting == "setting" ? <SettingOption /> : null} */}
            </ul>

            <ul className="w-full mt-auto">
              <h3 className="pl-5 text-white hidden lg:block text-xs font-semibold heading">
                ACCOUNT
              </h3>
              <li className="py-3" onClick={openSubscription}>
                <a href="#">
                  <h3 className="flex p-3 items-center text-white text-sm">
                    <svg
                      className="lg:mr-3 lg:ml-0 sm:m-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.52"
                      height="26.373"
                      viewBox="0 0 21.52 26.373"
                    >
                      <g
                        id="Group_72093"
                        data-name="Group 72093"
                        transform="translate(0.5 0.5)"
                      >
                        <g
                          id="Group_72089"
                          data-name="Group 72089"
                          transform="translate(0 0)"
                        >
                          <g id="Group_72094" data-name="Group 72094">
                            <g id="Group_72087" data-name="Group 72087">
                              <g id="Group_72095" data-name="Group 72095">
                                <path
                                  id="Subtraction_7"
                                  data-name="Subtraction 7"
                                  d="M10.258,20.519a2.364,2.364,0,0,1-.779-.133,2.733,2.733,0,0,1-.74-.4l-.449-.334h0c-.329-.244-.669-.5-1-.754A2.132,2.132,0,0,0,6.2,18.459c-.566-.069-1.142-.153-1.683-.234a2.61,2.61,0,0,1-1.537-.734,2.665,2.665,0,0,1-.691-1.566c-.014-.11-.027-.223-.039-.323-.056-.466-.114-.948-.214-1.413a2.8,2.8,0,0,0-.4-.929c-.247-.372-.524-.733-.793-1.082C.73,12.036.616,11.888.5,11.741a2.515,2.515,0,0,1-.372-.7,2.362,2.362,0,0,1,0-1.558,2.5,2.5,0,0,1,.372-.7c.1-.128.192-.26.284-.387.2-.28.412-.569.641-.834a2.694,2.694,0,0,0,.487-.806,3.309,3.309,0,0,0,.2-.916c.038-.426.106-.856.172-1.273L2.3,4.493A2.617,2.617,0,0,1,3.043,2.97,2.652,2.652,0,0,1,4.6,2.29l.282-.034c.479-.058.975-.118,1.454-.219a2.68,2.68,0,0,0,.907-.384c.357-.237.7-.5,1.038-.76h0c.158-.121.32-.246.482-.367A2.737,2.737,0,0,1,9.493.132,2.305,2.305,0,0,1,11.012.126a2.7,2.7,0,0,1,.72.379c.618.451,1.085.8,1.515,1.138a1.943,1.943,0,0,0,.506.287,2.351,2.351,0,0,0,.564.137c.7.086,1.236.162,1.737.246a2.6,2.6,0,0,1,1.505.741A2.668,2.668,0,0,1,18.231,4.6c.014.108.027.218.04.324h0c.057.465.115.946.213,1.41a2.69,2.69,0,0,0,.383.907c.231.348.49.686.741,1.012h0c.122.159.248.323.37.487a2.865,2.865,0,0,1,.405.748,2.287,2.287,0,0,1,0,1.546,2.844,2.844,0,0,1-.4.747c-.079.106-.157.216-.233.321h0c-.207.288-.421.585-.657.855a2.674,2.674,0,0,0-.488.805,3.32,3.32,0,0,0-.2.916c-.038.429-.107.862-.174,1.282l-.011.07a2.621,2.621,0,0,1-.724,1.513,2.672,2.672,0,0,1-1.538.69l-.476.063c-.451.059-.917.12-1.369.21a2.259,2.259,0,0,0-.8.349c-.334.227-.661.477-.977.718-.185.141-.376.287-.566.427a2.747,2.747,0,0,1-.735.389A2.372,2.372,0,0,1,10.258,20.519Zm0-17.394A7.13,7.13,0,0,0,4.338,14.241a7.207,7.207,0,0,0,1.926,1.934A7.087,7.087,0,0,0,10.227,17.4h.032A7.139,7.139,0,0,0,10.28,3.125Z"
                                  fill="#f5f6fa"
                                  stroke="rgba(0,0,0,0)"
                                  strokeMiterlimit={10}
                                  strokeWidth={1}
                                  className="group-hover:fill-[#0351B1]"
                                />
                                <path
                                  id="Path_174095"
                                  data-name="Path 174095"
                                  d="M103.641,366.918l-2.052.89-.995.432a.45.45,0,0,1-.605-.595q.864-2.14,1.724-4.283c.013-.032.035-.06.016-.027.943.147,1.849.277,2.749.435a1.766,1.766,0,0,1,.653.267c.4.267.77.582,1.165.859s.784.519,1.205.8c-.139.348-.295.742-.453,1.135-.412,1.023-.827,2.045-1.236,3.07-.086.215-.2.382-.454.384s-.375-.161-.463-.375q-.556-1.35-1.122-2.7c-.039-.093-.083-.183-.133-.294"
                                  transform="translate(-98.371 -344.409)"
                                  fill="#f5f6fa"
                                  className="group-hover:fill-[#0351B1]"
                                />
                                <path
                                  id="Path_174096"
                                  data-name="Path 174096"
                                  d="M275.836,365.758c.63-.445,1.253-.821,1.8-1.288a3.408,3.408,0,0,1,2-.791c.643-.062,1.278-.206,1.956-.32a.9.9,0,0,1,.068.13c.547,1.36,1.09,2.722,1.64,4.081.089.22.146.428-.042.619s-.415.138-.641.038c-.874-.386-1.752-.765-2.628-1.146-.093-.04-.186-.079-.3-.128l-.8,1.912c-.161.385-.3.782-.495,1.15a.6.6,0,0,1-.418.286.522.522,0,0,1-.4-.269c-.6-1.438-1.174-2.886-1.732-4.273"
                                  transform="translate(-264.438 -344.446)"
                                  fill="#f5f6fa"
                                  className="group-hover:fill-[#0351B1]"
                                />
                                <path
                                  id="Path_174098"
                                  data-name="Path 174098"
                                  d="M175.592,134.733a6.119,6.119,0,1,1,6.075,6.144,6.128,6.128,0,0,1-6.075-6.144m5.507-3.051c-.1.012-.147.016-.2.024a1.838,1.838,0,0,0,.285,3.664c.318,0,.637,0,.955,0,.509,0,.8.23.79.622s-.287.6-.773.6c-.7,0-1.4,0-2.1,0-.511,0-.8.227-.79.62s.288.6.808.6c.338,0,.675,0,1.025,0,0,.417,0,.774,0,1.13.007.425.236.692.592.7.371.011.622-.265.629-.706.006-.367,0-.735,0-1.11.106-.014.181-.02.254-.034a1.837,1.837,0,0,0-.323-3.656c-.331,0-.662,0-.994,0-.485,0-.764-.224-.769-.6s.276-.617.753-.619c.688,0,1.376,0,2.064,0,.555,0,.841-.2.848-.6s-.284-.62-.828-.621h-1c0-.415.006-.783,0-1.15a.614.614,0,1,0-1.221,0c-.007.368,0,.736,0,1.13"
                                  transform="translate(-171.451 -124.495)"
                                  fill="#f5f6fa"
                                  className="group-hover:fill-[#0351B1]"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>

                    <span className="hidden lg:block">Subscription</span>
                  </h3>
                </a>
              </li>
              <li className="py-3">
                <a href="#">
                  <h3 className="flex p-3 items-center text-white text-sm">
                    <svg
                      className="lg:mr-3 lg:ml-0 sm:m-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.041"
                      height="19.041"
                      viewBox="0 0 19.041 19.041"
                    >
                      <path
                        id="Icon_awesome-user-circle"
                        data-name="Icon awesome-user-circle"
                        d="M9.521.563a9.521,9.521,0,1,0,9.521,9.521A9.519,9.519,0,0,0,9.521.563Zm0,3.685A3.378,3.378,0,1,1,6.142,7.626,3.378,3.378,0,0,1,9.521,4.248Zm0,13.206A7.356,7.356,0,0,1,3.9,14.836a4.28,4.28,0,0,1,3.781-2.3.939.939,0,0,1,.273.042,5.083,5.083,0,0,0,1.57.265,5.064,5.064,0,0,0,1.57-.265.939.939,0,0,1,.273-.042,4.28,4.28,0,0,1,3.781,2.3A7.356,7.356,0,0,1,9.521,17.454Z"
                        transform="translate(0 -0.563)"
                        fill="#f5f6fa"
                      />
                    </svg>

                    <span className="hidden lg:block">My Profile</span>
                  </h3>
                </a>
              </li>
              <li className="py-3">
                <a href="#">
                  <h3 className="flex p-3 items-center text-white text-sm">
                    <svg
                      className="lg:mr-3 lg:ml-0 sm:m-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.52"
                      height="20.851"
                      viewBox="0 0 20.52 20.851"
                    >
                      <path
                        id="Icon_awesome-power-off"
                        data-name="Icon awesome-power-off"
                        d="M16.78,2.238a10.259,10.259,0,1,1-11.923,0A1,1,0,0,1,6.3,2.561l.654,1.162a.992.992,0,0,1-.273,1.282,6.95,6.95,0,1,0,8.27,0,.987.987,0,0,1-.269-1.278l.654-1.162a.992.992,0,0,1,1.44-.323Zm-4.3,8.684V.993A.99.99,0,0,0,11.484,0H10.16a.99.99,0,0,0-.993.993v9.929a.99.99,0,0,0,.993.993h1.324A.99.99,0,0,0,12.477,10.922Z"
                        transform="translate(-0.562)"
                        fill="#f5f6fa"
                      />
                    </svg>

                    <span className="hidden lg:block">Logout</span>
                  </h3>
                </a>
              </li>
            </ul>
            <div
              className="arrowBack text-right w-full px-4 mb-auto border-t-[1px] py-5 flex justify-end"
              onClick={() => MenuShowHide()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.496"
                height="16.61"
                viewBox="0 0 9.496 16.61"
                className="cursor-pointer"
              >
                <path
                  id="Icon_ionic-ios-arrow-back"
                  data-name="Icon ionic-ios-arrow-back"
                  d="M14.113,14.5,20.4,8.217A1.187,1.187,0,0,0,18.717,6.54L11.6,13.656a1.185,1.185,0,0,0-.035,1.637l7.15,7.165a1.187,1.187,0,1,0,1.681-1.676Z"
                  transform="translate(-11.251 -6.194)"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};

export default SideMenu;
