
//@INPUT - template of bot flow
//@OUTPUT - returns the array of nodes and edges

import { functions } from "firebase"
import { initialDataOfNode } from "../data/node/nodeData"
import { MarkerType } from "reactflow"

//@DEF - traverses the template json and converts the objects into nodes of the flowchart and create the connection using edges
export default function convertTemplateToFlowChart(template){

    //initializing nodes and edges array
    const nodes = []
    const edges = []
    
    //getting the flow from the template
    const flow = template.template_One

    

    //getting the root node
    const firstNode = flow[0]

    

    

    var coordinateX = 0 
    var coordinateY = 50
    var level = 2

    //setting a counter to traverse the json template
    const key = Number(firstNode.next)


    //initialization
    //@PURPOSE - to create and edge between the nodes by splitting the string and creating an edge between the first element to last element
    const edgeString = '0-'

    const data = flowToArray(key , edgeString , flow , nodes , edges , coordinateX , coordinateY , level , template)

    // nodes.push({data : firstNode , id : '0' , position : {x : }})

    nodes.push({data : {...firstNode,template} ,style : {width : 'auto' , height : 'auto' , border : 0 , padding : 0} , type : 'default' , id : '0' , position : {x : data.childNodeX , y : 50}})


    // console.log('*****Nodes****')
    // console.log(nodes)            
    // console.log(nodes.length)

    // console.log('*****Edges****')
    // console.log(edges)
    // console.log(edges.length)
    return {
        nodes,
        edges
    }


   

    
}


//recursive function
function flowToArray(key , edgeString , flow , nodes , edges , coordinateX , coordinateY ,  level , template){

    // debugger
    // console.log(flow[key] , ' hello ' , key)
  //!flow[key].addedBy
    // @CASE -- Bot message should not be shown in the UI therefore not pushing into the array
    // updating only the edge string
    if(flow[key]?.UI === 'Bot Message' &&  
    (flow[Number(flow[key].parentNode)].UI === 'Get Email' 
    || flow[Number(flow[key].parentNode)].UI === 'Get Phone' 
    || flow[Number(flow[key].parentNode)].UI === 'Get Name'
    || flow[Number(flow[key].parentNode)].UI === 'Date Selector' 
    || flow[Number(flow[key].parentNode)].UI === 'Date Picker'
    || flow[Number(flow[key].parentNode)].UI === 'Currency' 
    || flow[Number(flow[key].parentNode)].UI === 'Follow'
    || flow[Number(flow[key].parentNode)].UI === 'Gender' 
    || flow[Number(flow[key].parentNode)].UI === 'Get Address'
    || flow[Number(flow[key].parentNode)].UI === 'Country' 
    || flow[Number(flow[key].parentNode)].UI === 'Get Country'
    || flow[Number(flow[key].parentNode)].UI === 'Capture Name' 
    || flow[Number(flow[key].parentNode)].UI === 'Bot Question'
    || flow[Number(flow[key].parentNode)].UI === 'Get User Input' 
    || flow[Number(flow[key].parentNode)].UI === 'Payment'
    || flow[Number(flow[key].parentNode)].UI === 'Request Callback'
    || flow[Number(flow[key].parentNode)].UI === 'Get Location' 
    )){
      const data = flowToArray(Number(flow[key].next) ,edgeString + key + '-' , flow , nodes , edges , coordinateX ,coordinateY, level  , template)
      return data
    }

    //creating an edge and pushing it into edges array
    const sourceOfEdge = edgeString.split('-')[0]
    const targetOfEdge = key.toString()
    edges.push({id : sourceOfEdge + '-' + targetOfEdge  , source : sourceOfEdge , target : targetOfEdge , type : 'buttonedge', markerEnd : {type : MarkerType.ArrowClosed , color : 'blue'}
    })

    //case of a leaf node
    //base case of recusrion
    if(flow[key].trigger.length <= 0 && !(flow[key].Validation.doValidate) && Number(flow[key].next) === 0){
        const positionX = coordinateX === 0  ? coordinateX + 50 :  coordinateX + 400
        const positionY = coordinateY + (level-1)*150
        nodes.push({data : {...flow[key] , template} ,style : {width : 'auto' , height : 'auto' , border : 0 , padding : 0} , type : 'default' , id : key.toString() , position : {x : positionX , y : positionY}})
        return {
            coordinateX : positionX,
            childNodeX : positionX
        }

    }


    var newCoordinateX = coordinateX
    var data = null


    //if the nodes have multiple edges
    //applying dfs on each edge
    if(flow[key].trigger.length > 0){
        

      //@CASE - when branching is true
      //that means that the node have branches
        if(flow[key].data.isBranching){

          flow[key].trigger.map((item) => {
            if(item.next){
              data = flowToArray(Number(item.next) , key + '-' ,flow , nodes , edges , newCoordinateX ,coordinateY ,  level+1 , template)
              newCoordinateX = data.coordinateX
            }
        })

        // const noOfChildNodes = flow[key].trigger.length
        const noOfChildNodes = flow[key].trigger.filter((item) => {
          if(item.next){
            return item
          }
        }).length
        const left = data.childNodeX - (noOfChildNodes-1)*300
        const right = data.childNodeX + 250
        const centerofChildNodes = (left + right)/2
        nodes.push({data : {...flow[key] , template} ,style : {width : 'auto' , height : 'auto' , border : 0 , padding : 0} , type : 'default' , id : key.toString() , position : {x : centerofChildNodes - (250/2) , y : coordinateY + (level-1)*150}})
        return {
            coordinateX : data.coordinateX ,
            childNodeX : centerofChildNodes - (250/2)
        }

        }

        //@CASE - when branching is false
        //that means all the trigger elements have same next
        else{
          data = flowToArray(Number(flow[key].trigger[0].next) , key + '-' , flow , nodes , edges , coordinateX ,coordinateY, level+1  , template)
        nodes.push({data : {...flow[key] , template} ,style : {width : 'auto' , height : 'auto' , border : 0 , padding : 0} , type : 'default' , id : key.toString() , position : {x : data.childNodeX , y : coordinateY + (level-1)*150}})
        return {
            coordinateX : data.coordinateX ,
            childNodeX : data.childNodeX
        }
        }



    }

    //if the node has validation
    else if(flow[key].Validation.doValidate){
        data = flowToArray(Number(flow[key].Validation.successful) , key + '-' , flow , nodes , edges , coordinateX ,coordinateY, level+1 , template )
        nodes.push({data : {...flow[key] , template} ,style : {width : 'auto' , height : 'auto' , border : 0 , padding : 0} , type : 'default' , id : key.toString() , position : {x : data.childNodeX , y : coordinateY + (level-1)*150}})
        return {
            coordinateX : data.coordinateX ,
            childNodeX : data.childNodeX
        }


    }

    //if the node has no validations , bo triggers/branches and has only the next value
    else{
        data = flowToArray(Number(flow[key].next) , key + '-' , flow , nodes , edges , coordinateX ,coordinateY, level+1  , template)
        nodes.push({data : {...flow[key] , template} ,style : {width : 'auto' , height : 'auto' , border : 0 , padding : 0} , type : 'default' , id : key.toString() , position : {x : data.childNodeX , y : coordinateY + (level-1)*150}})
        return {
            coordinateX : data.coordinateX ,
            childNodeX : data.childNodeX
        }

    }

}


// export function moveNodeUp(template,   parentNode , currentNode){

//     //getting the flow from the template
//     const flow = template.template_One

//     var newUpperNode = null
//     var newLowerNode = null



//     //CREATING THE NEW UPPER NODE

//     //if both current node and parent node has validations 
//     if(flow[currentNode].Validation.doValidate && flow[parentNode].Validation.doValidate){
//         newUpperNode = {
//             ...flow[parentNode],
//             Validation : {
//                 ...flow[parentNode].Validation,
//                 successful : flow[currentNode].Validation.successful
//                 // successful : parentNode
//             }
    
//         }
//     }

//     //if only the current node has validations
//     else if(flow[currentNode].Validation.doValidate && !flow[parentNode].Validation.doValidate){
//         newUpperNode = {
//             ...flow[parentNode],
//             Validation : {
//                 ...flow[parentNode].Validation,
//                 successful : flow[currentNode].next
//                 // successful : parentNode
//             }
    
//         }
//     }

//     //if only the paremnt node has validations
//     else if(!flow[currentNode].Validation.doValidate && flow[parentNode].Validation.doValidate){
//         newUpperNode = {
//             ...flow[parentNode],
//             next : flow[currentNode].Validation.successful
//             // next : parentNode
    
//         }
//     }

//     //if both the current node and the paremnt node has no validations
//     else{
//         newUpperNode = {
//             ...flow[parentNode],
//             next : flow[currentNode].next
//             // next : parentNode
    
//         }
//     }



//     //CREATING THE NEW LOWER NODE



//     //if both current node and parent node has validations 
//     if(flow[currentNode].Validation.doValidate && flow[parentNode].Validation.doValidate){
//         newLowerNode = {
//             ...flow[currentNode],
//             Validation : {
//                 ...flow[currentNode].Validation,
//                 // successful : flow[currentNode].Validation.successful
//                 successful : parentNode
//             }
    
//         }
//     }

//     //if only the current node has validations
//     else if(flow[currentNode].Validation.doValidate && !flow[parentNode].Validation.doValidate){
//         newLowerNode = {
//             ...flow[currentNode],
//             // next : flow[parentNode].Validation.successfull
//             next : parentNode
    
//         }
//     }

//     //if only the paremnt node has validations
//     else if(!flow[currentNode].Validation.doValidate && flow[parentNode].Validation.doValidate){
//         newLowerNode = {
//             ...flow[currentNode],
//             Validation : {
//                 ...flow[currentNode].Validation,
//                 // successful : flow[currentNode].next
//                 successful : parentNode
//             }
//         }
//     }

//     //if both the current node and the paremnt node has no validations
//     else{
//         newLowerNode = {
//             ...flow[currentNode],
//             // next : flow[currentNode].next
//             next : parentNode
    
//         }
//     }
    


//     const newFlow  = {
//         ...flow,
//         [parentNode] : newUpperNode,
//         [currentNode] : newLowerNode
//     }


//     const newTemplate = {
//         ...template,
//         template_One : newFlow
//     }


//     return newTemplate

// }



var appointment = {
    chatBotAvtars: {
      chatBotAvtar: [
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar2.svg?alt=media&token=8b7982ab-0060-472b-ad51-6792e3fba162',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar1.svg?alt=media&token=fe41f944-828d-47af-bfcc-d14d14af0956'
      ]
    },
    template_One: {
      0: {
        UI: 'Welcome Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: "Hi! I am Snappy. Seems like you're looking for an appointment."
        },
        input: '',
        next: 1,
        trigger: []
      },
      1: {
        UI: 'Get Name',
        Validation: {
          doValidate: true,
          loop: 0,
          successful: 2,
          type: 'Name'
        },
        action: '',
        continue: false,
        data: {
          responseText: 'Great! Thanks for the name.',
          text: 'Can I please get your name?'
        },
        input: '',
        next: null,
        trigger: []
      },
      2: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Great! Thanks for the name.'
        },
        input: '',
        next: 3,
        trigger: []
      },
      3: {
        UI: 'Get Email',
        Validation: {
          doValidate: true,
          loop: 0,
          successful: 5,
          type: 'Email',
          unsuccessful: 4,
          text: 'Please tell me your email id.'
        },
        action: '',
        continue: false,
        data: {
          successful: 'Thanks for sharing your E-mail Id.',
          text: 'Please tell me your email id.',
          unsuccessful: {
            failedAttemptsFinal:
              'Oops! Looks like you are entering an invalid email address. We usually accept business email ids only like abc@appypiellp.com',
            failedAttemptsText:
              'Oops! Looks like you have entered an invalid email address. Please give me a valid email address.',
            no_of_email: '',
            nonBusinessCheck: '',
            nonBusinessEmails: [],
            spamMailCheck: '',
            spamMailText: []
          }
        },
        input: '',
        next: null,
        parentNode : 2,
        trigger: []
      },
      4: {
        UI: 'Get Email',
        Validation: {
          doValidate: true,
          loop: 0,
          successful: 5,
          type: 'Email',
          unsuccessful: 4,
          text: 'Please tell me your email id.'
        },
        action: '',
        continue: false,
        data: {
          text:
            'Oops! Looks like you have entered an invalid email address. Please give me a valid email address.'
        },
        input: '',
        next: null,
        trigger: []
      },
      5: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Thanks for sharing your E-mail Id.'
        },
        input: '',
        next: 6,
        parentNode : 3,
        trigger: []
      },
      6: {
        UI: 'Get Phone',
        Validation: {
          doValidate: true,
          loop: 0,
          successful: 8,
          type: 'Phone',
          unsuccessful: 7,
          text: 'Can I please get your phone number?'
        },
        action: '',
        continue: false,
        data: {
          successful: {
            botResponseCheck: '',
            botResponseText: 'Great! Thanks for your phone number.',
            countrycode: ''
          },
          text: 'Can I please get your phone number?',
          unsuccessful: {
            botResponseUnCheck: '',
            botResponseUnText:
              'Oops! Looks like you entered an invalid contact number. Please give me a valid one.',
            botResponseUsTextFinal: 'Thanks for the number.',
            no_of_repeatQuestion: ''
          }
        },
        input: '',
        next: null,
        trigger: []
      },
      7: {
        UI: 'Get Phone',
        Validation: {
          doValidate: true,
          loop: 0,
          successful: 8,
          type: 'Phone',
          unsuccessful: 7,
          text: 'Can I please get your phone number?'
        },
        action: '',
        continue: false,
        data: {
          text:
            'Oops! Looks like you entered an invalid contact number. Please give me a valid one.'
        },
        input: '',
        next: null,
        trigger: []
      },
      8: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Great! Thanks for your phone number.'
        },
        input: '',
        next: 9,
        trigger: []
      },
      9: {
        UI: 'Condition',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          text: 'Upcoming Appointment'
        },
        input: 'Button',
        next: '',
        trigger: [
          {
            id: '9.1',
            name: 'Present',
            next: 10
          },
          {
            id: '9.2',
            name: 'Not Present',
            next: 33
          }
        ]
      },
      10: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text:
            "We noticed that you've an appointment on {{appointment data and time}}"
        },
        input: '',
        next: 11,
        trigger: []
      },
      11: {
        UI: 'Button',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          isBranching: true,
          text: 'What do you wish to do?'
        },
        input: 'Button',
        next: '',
        trigger: [
          // {
          // 	id: 11.1,
          // 	name: "Book another appointment",
          // 	next: 24,
          // },
          {
            id: 11.1,
            name: 'Reschedule appointment',
            next: 12
          },
          {
            id: 11.2,
            name: 'Cancel appointment',
            next: 17
          }
        ]
      },
      12: {
        UI: 'Date_&_Time',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          available: {
            proceedClick: true,
            responseText:
              'Appointment scheduled successfully! An email with relevant details has been sent to you.'
          },
        //   meetingLink: localStorage.getItem('agent_id'),
          text: 'Please select date and time on which you wish to reschedule.',
          unavailable: {
            textUn:
              "We're sorry! No slots are available at the moment. In case a slot opens up, we'll send you an email immediately."
          }
        },
        input: 'Date_&_Time',
        next: 13,
        trigger: []
      },
      13: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text:
            'Your appointment has been successfully rescheduled to {{date and time}}.'
        },
        input: '',
        next: 14,
        trigger: []
      },
      14: {
        UI: 'Handoffs',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          handoffs: {
            appy_email: {
              id: 0,
              type: 'Email',
              title: 'Appy Email',
              data: {
                email: ''
              }
            },
            appy_appointment: {
              id: 1,
              type: 'appointment',
              title: 'Appy Appointment',
              data: {
                email: ''
              }
            }
          },
          text: 'Great! Thanks for the informations.'
        },
        input: '',
        next: 15,
        trigger: []
      },
      15: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 16,
        trigger: []
      },
      16: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      17: {
        UI: 'Button',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          isBranching: true,
          text: 'Are you sure that you wish to cancel the appointment'
        },
        input: 'Button',
        next: '',
        trigger: [
          {
            id: 17.1,
            name: 'Yes',
            next: 18
          },
          {
            id: 17.2,
            name: 'No',
            next: 21
          }
        ]
      },
      18: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Your appointment has been cancelled.'
        },
        input: '',
        next: 100,
        trigger: []
      },
  
  
      100: {//
        UI: "Handoffs",
        HandOffType:"Cancel Appointment",
        Validation: {
            doValidate: false,
            type: "",
        },
        action: "SaveInquiry",
        continue: true,
        data: {
            handoffs: {
                appy_email: {
                    id: 0,
                    type: "Email",
                    title: "Appy Email",
                    data: {
                        email: "",
                    },
                },
            },
            text: "Great! Thanks for the informations.",
        },
        input: "",
        next: 19,
        trigger: [],
    },
  
      19: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 20,
        trigger: []
      },
      20: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      21: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Your appointment is still intact with us.'
        },
        input: '',
        next: 22,
        trigger: []
      },
      22: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 23,
        trigger: []
      },
      23: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      24: {
        UI: 'Button',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          isBranching: true,
          text: 'Would you like to book an appointment with us?'
        },
        input: 'Button',
        next: '',
        trigger: [
          {
            id: 24.1,
            name: 'Yes',
            next: 25
          },
          {
            id: 24.2,
            name: 'No',
            next: 30
          }
        ]
      },
      25: {
        UI: 'Date_&_Time',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          available: {
            proceedClick: true,
            responseText:
              'Appointment scheduled successfully! An email with relevant details has been sent to you.'
          },
        //   meetingLink: localStorage.getItem('agent_id'),
          text: 'Please select date and time on which you wish to reschedule.',
          unavailable: {
            textUn:
              "We're sorry! No slots are available at the moment. In case a slot opens up, we'll send you an email immediately."
          }
        },
        input: 'Date_&_Time',
        next: 26,
        trigger: []
      },
      26: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text:
            'Your appointment has been successfully booked on {{date and time}}.'
        },
        input: '',
        next: 27,
        trigger: []
      },
      27: {
        UI: 'Handoffs',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          handoffs: {
            appy_email: {
              id: 0,
              type: 'Email',
              title: 'Appy Email',
              data: {
                email: ''
              }
            },
            appy_appointment: {
              id: 1,
              type: 'appointment',
              title: 'Appy Appointment',
              data: {
                email: ''
              }
            }
          },
          text: 'Great! Thanks for the informations.'
        },
        input: '',
        next: 28,
        trigger: []
      },
      28: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 29,
        trigger: []
      },
      29: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      30: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Thank you for precious time.'
        },
        input: '',
        next: 31,
        trigger: []
      },
      31: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 32,
        trigger: []
      },
      32: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      33: {
        UI: 'Button',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          isBranching: true,
          text: 'Would you like to book an appointment with us?'
        },
        input: 'Button',
        next: '',
        trigger: [
          {
            id: 33.1,
            name: 'Yes',
            next: 34
          },
          {
            id: 33.2,
            name: 'No',
            next: 39
          }
        ]
      },
      34: {
        UI: 'Date_&_Time',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: false,
        data: {
          available: {
            proceedClick: true,
            responseText:
              'Appointment scheduled successfully! An email with relevant details has been sent to you.'
          },
        //   meetingLink: localStorage.getItem('agent_id'),
          text: 'Please select date and time on which you wish to schedule.',
          unavailable: {
            textUn:
              "We're sorry! No slots are available at the moment. In case a slot opens up, we'll send you an email immediately."
          }
        },
        input: 'Date_&_Time',
        next: 35,
        trigger: []
      },
      35: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text:
            'Your appointment has been successfully booked on {{date and time}}.'
        },
        input: '',
        next: 36,
        trigger: []
      },
      36: {
        UI: 'Handoffs',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          handoffs: {
            appy_email: {
              id: 0,
              type: 'Email',
              title: 'Appy Email',
              data: {
                email: ''
              }
            },
            appy_appointment: {
              id: 1,
              type: 'appointment',
              title: 'Appy Appointment',
              data: {
                email: ''
              }
            }
          },
          text: 'Great! Thanks for the informations.'
        },
        input: '',
        next: 37,
        trigger: []
      },
      37: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 38,
        trigger: []
      },
      38: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      39: {
        UI: 'Bot Message',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: '',
        continue: true,
        data: {
          text: 'Thank you for precious time.'
        },
        input: '',
        next: 40,
        trigger: []
      },
      40: {
        UI: 'Feedback',
        Validation: {
          doValidate: false,
          loop: 0,
          type: ''
        },
        action: 'feedbaack',
        continue: false,
        data: {
          feedback_type: {
            presentation: {
              label: '1 to 5 scale',
              value: '1-5'
            },
            type: {
              label: 'Scale',
              value: 'rating-scale'
            }
          },
          isEnable: true,
          responseText: 'Thank you for the feedback.',
          text: 'Please provide your feedback.'
        },
        input: '',
        next: 41,
        trigger: []
      },
      41: {
        UI: 'End Message',
        Validation: {
          doValidate: false,
          type: ''
        },
        action: 'SaveInquiry',
        continue: false,
        data: {
          text: 'Great! Thanks for the information.'
        },
        input: '',
        next: null,
        trigger: []
      },
      '00': {
        Validation: {
          doValidate: false,
          type: ''
        },
        action: '',
        continue: false,
        input: '',
        message: 'null',
        next: 0,
        trigger: []
      },
      customisation: {
        backgroundChatBot: '#FFFFFF',
        botButtonColor: '#2c3049ff',
        botButtonText: 'lora',
        botButtonTextColor: '#FFFFFF',
        botTextcolor: '#000',
        botType: 'Appointment',
        chatBotAvtar:
          'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2',
        clientBubbleClr: '#2c3049ff',
        clientchattextcolor: '#ffffff',
        designLanguage: 'english(en)',
        designPage: 'default',
        headerClr: '#2c3049ff',
        headerName: 'Appointment bot',
        headertextcolor: '#ffffff',
        messagebubble: '#2c30494d',
        sendButtonClr: '#2c3049ff',
        textfonts: 'roboto',
        widgetBackgroundColor: '#2c3049ff',
        widgetImage:
          'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&token=6a39c949-c97b-4811-9414-c21c2e249985',
        widgetPosition: {
          bottom: '8',
          position: 'right',
          side: '8'
        },
        widgetSize: 'large'
      },
      time: 1562221203801
    },
    widgetImages: {
      widgetImage: [
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&token=6a39c949-c97b-4811-9414-c21c2e249985',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar2.svg?alt=media&token=eda6ee3f-a82e-405d-91cc-ae80ae5910d0',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar1.svg?alt=media&token=23fb8ec9-5129-4c87-b060-cc0fe84e9377',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget2.svg?alt=media&token=210ce6f4-f9ef-475c-a757-d08cd1d84467',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget1.svg?alt=media&token=782bf939-6627-4f33-ae1a-a9f6e9cf8c1f',
        'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget3.svg?alt=media&token=49981112-ba7b-48e6-90f0-4cd8486b97a2'
      ]
    }
  }
  
//   export default appointment
  

//utility function
//@DEF - to move the node of the graph in the upwards direction
//@PARAMETERS -- template , id of the node to be moved upwards
//@RETURN - new template after movement
//@CONDITION -- current node should not have branches / parent node should also not have branches / current node should not be the root node / current node should not be the direct child of the root node
export function moveNodeUp(template , nodeToMove){

    //getting the flow
    const flow  = template.template_One


    //Validating conditions
    //if the conditions falied then throwing error
    
    //checking whether the current node has branches
    if(flow[nodeToMove].trigger.length > 0){
      throw Error('This node has branches therefore cannot be moved upwards')
    }

    //checking whether the parent node has branches
    if(flow[Number(flow[nodeToMove].parentNode)].trigger.length > 0){
      throw Error('Its parent node has branches therefore cannot be moved upwards')
    }

    //checking whether the current node is root node
    if(nodeToMove === 0){
      throw Error('This node is a root node therefore cannot be moved upwards')
    }

    //checking whether the current node is the direct child of the root node
    if(Number(flow[nodeToMove].parentNode) === 0){
      throw Error('This node is the direct child of the root node therefore cannot be moved upwards')
    }

    

    //getting the node id of the parent node
    const parentNode = Number(flow[nodeToMove].parentNode)


    //getting the node id of the second parent
    const superNode = Number(flow[parentNode].parentNode)

    //getting the node id of the child node
    const childNode = Number(flow[nodeToMove].Validation.doValidate ? flow[nodeToMove].Validation.successful : flow[nodeToMove].next)

    // console.log(superNode + ' ' + parentNode + ' ' + nodeToMove + ' ' + childNode)
    
    //changing the  link of the second parent node
    //changing only the next value
    //@CASES - multiple branches , validations , only next
    const newSuperNode = flow[superNode].trigger.length > 0 ? {
      ...flow[superNode],
      trigger : flow[superNode].trigger.map((item) => {
        if(Number(item.next) === parentNode ){
          return {
            ...item,
            next : nodeToMove
          }
        }
        else{
          return item
        }
      })
    } : flow[superNode].Validation.doValidate ?  {
        ...flow[superNode],
        Validation : {
            ...flow[superNode].Validation,
            successful : nodeToMove
        }
        
    } :

    {
        ...flow[superNode],
        next : nodeToMove
        
    }


    //changing the  link of the  parent node
    //changing the next value and the parent node
    //@CASES - validations , only next
    const newParentNode = flow[parentNode].Validation.doValidate ?  {
        ...flow[parentNode],
        Validation : {
            ...flow[parentNode].Validation,
            successful : childNode
        },
        parentNode : nodeToMove
        
    } :

    {
        ...flow[parentNode],
        next : childNode,
        parentNode : nodeToMove
        
    }


    //changing the  link of the  node to be moved up 
    //changing the next value and the parent node
    //@CASES - validations , only next
    const newCurrentNode = flow[nodeToMove].Validation.doValidate ?  {
        ...flow[nodeToMove],
        Validation : {
            ...flow[nodeToMove].Validation,
            successful : parentNode
        },
        parentNode : superNode
        
    } :

    {
        ...flow[nodeToMove],
        next : parentNode,
        parentNode : superNode
        
    }

    //changing the link of the childnode
    //changing only the parent node
    //@CASES - child node can be null means that the current node is the leaf node
    const newChildNode = childNode !== 0 ? {
        ...flow[childNode],
        parentNode : parentNode
    } : null

    //creating the new flow
    //@CASES -- current node is leaf node / current node is not the leaf node that means it has the child node
    const newFlow = newChildNode ?  {
        ...flow,
        [superNode] : newSuperNode,
        [parentNode] : newParentNode,
        [nodeToMove] : newCurrentNode,
        [childNode] : newChildNode
    } : {
      ...flow,
        [superNode] : newSuperNode,
        [parentNode] : newParentNode,
        [nodeToMove] : newCurrentNode,
        
    }


    //creating a new template
    const newTemplate = {
        ...template,
        template_One : newFlow
    }

    //returning new template
    return newTemplate

}





//utility function
//@DEF - to move the node of the graph in the downwards direction
//@PARAMETERS -- template , id of the node to be moved downwards
//@RETURN - new template after movement
//@CONDITION -- current node should not have branches / child node should also not have branches / current node should not be the root node / current node should not be the leaf node
export function moveNodeDown(template , nodeToMove){

  //getting the flow
  const flow  = template.template_One


  //Validating conditions
  //if the conditions falied then throwing error
  
  //checking whether the current node has branches
  if(flow[nodeToMove].trigger.length > 0){
    throw Error('This node has branches therefore cannot be moved downwards')
  }

  

  //checking whether the child node has branches
  if(flow[Number(flow[nodeToMove].Validation.doValidate ? flow[nodeToMove].Validation.successful : flow[nodeToMove].next)].trigger.length > 0){
    throw Error('Its child node has branches therefore cannot be moved downwards')
  }

  //checking whether the current node is root node
  if(nodeToMove === 0){
    throw Error('This node is a root node therefore cannot be moved downwards')
  }

  

  //checking whether the current node is the leaf node
  if(flow[nodeToMove].trigger.length <= 0 && !(flow[nodeToMove].Validation.doValidate ? flow[nodeToMove].Validation.successful : flow[nodeToMove].next)){
    throw Error('This node is the leaf node therefore cannot be moved downwards')
  }


  //getting the node id of the parent node
  const parentNode = Number(flow[nodeToMove].parentNode)

  //getting the node id of the child node
  const childNode = Number(flow[nodeToMove].Validation.doValidate ? flow[nodeToMove].Validation.successful : flow[nodeToMove].next)

  //getting the node id of the second child
  const secondChildNode = Number(flow[childNode].Validation.doValidate ? flow[childNode].Validation.successful : flow[childNode].next)


  // console.log(parentNode + ' ' + nodeToMove + ' ' + childNode + ' ' + secondChildNode)


  //changing the  link of the  parent node
  //changing only the next value
  //@CASES - multiple branches , validations , only next
  const newParentNode = flow[parentNode].trigger.length > 0 ? {
    ...flow[parentNode],
    trigger : flow[parentNode].trigger.map((item) => {
      if(Number(item.next) === nodeToMove){
        return {
          ...item,
          next : childNode
        }
      }
      else{
        return item
      }
    })
  } : flow[parentNode].Validation.doValidate ? {
    ...flow[parentNode],
    Validation : {
      ...flow[parentNode].Validation,
      successful : childNode
    }
  } : {
    ...flow[parentNode],
    next : childNode
  }



  //changing the  link of the  current node
  //changing the next value and the parentNode
  //@CASES -  validations , only next
  const newCurrentNode = flow[nodeToMove].Validation.doValidate ? {
    ...flow[nodeToMove],
    Validation : {
      ...flow[nodeToMove].Validation,
      successful : secondChildNode
    },
    parentNode : childNode
  } : {
    ...flow[nodeToMove],
    next : secondChildNode,
    parentNode : childNode
  }


  //changing the  link of the  child node
  //changing the next value and the parentNode
  //@CASES -  validations , only next

  const newChildNode = flow[childNode].Validation.doValidate ? {
    ...flow[childNode],
    Validation : {
      ...flow[childNode].Validation,
      successful : nodeToMove
    },
    parentNode
  } : {
    ...flow[childNode],
    next : nodeToMove,
    parentNode
  }


  //changing the  link of the  second child node
  //changing only the  parentNode
  //@CASES -  no cases needed 

  const newSecondChildNode = {
    ...flow[secondChildNode],
    parentNode : nodeToMove
  }


  

  //creating the new flow
  const newFlow = {
    ...flow,
    [parentNode] : newParentNode,
    [nodeToMove] : newCurrentNode,
    [childNode] : newChildNode,
    [secondChildNode] : newSecondChildNode
  }


  //creating the new template
  const newTemplate = {
    ...template,
    template_One : newFlow
  }


  return newTemplate
}



//utility function
//@DEF - to delete the node from the graph
//@PARAMETERS -- template , id of the node to be deleted
//@RETURN - new template after deletion
//@CONDITION -- current node has branches / parent node has branches
export function deleteNode(template , nodeToBeDeleted){


  //getting the flow from the template
  const flow = template.template_One


  
  //getting the id of the child node
  //if node to be deleted has branches then child node id will be null
  //else if the node to be deleted has bot message after it then the child node will be the next of that bot message
  //else the child node will be the next of the node to be deleted
  let childNode = null

  //node to be deleted have branches
  if(flow[nodeToBeDeleted].trigger.length > 0 ){
    childNode = null
  }

  //node to be deleted have branches
  else if(flow[nodeToBeDeleted].Validation.doValidate){
    const successfulBotMessageNodeId = Number(flow[nodeToBeDeleted].Validation.successful)
    childNode = Number(flow[successfulBotMessageNodeId].next)
  }

  //node to be deleted have bot message in its next
  else if(flow[nodeToBeDeleted].UI === 'Get Location'){
    const botMessageNodeId = Number(flow[nodeToBeDeleted].next)
    childNode = Number(flow[botMessageNodeId].next)
  }

  //node to be deleted does not have bot message after it
  else{
    childNode = Number(flow[nodeToBeDeleted].next)
  }

  console.log(childNode , ' child - delete')//for debugging purpose


  


  //getting parent node id
  const parentNode = Number(flow[nodeToBeDeleted].parentNode)




  //if child node is not null that means node to be deleted does not have branches
  //then changing only the link of the parent and child
  if(childNode){


      //creating new parent node
      let newParentNode = null
      
      
      //@CASE - parent node have branches
      if(flow[parentNode].trigger.length > 0){
        newParentNode = {
          ...flow[parentNode],
          trigger : flow[parentNode].trigger.map((item) => {
            if(Number(item.next) === nodeToBeDeleted){
              return {
                ...item,
                next : childNode
              }
            }
            else{
              return item
            }
          })
        }
      }

      //@CASE -- parent node is a bot message or any other node that does not validations
      else{
        newParentNode = {
          ...flow[parentNode],
          next : childNode
        }
      }


      //creating new child node
      let newChildNode = null
      newChildNode = {
        ...flow[childNode],
        parentNode,
      }

      //creating new flow
      const newFlow = {
        ...flow,
        [parentNode] : newParentNode,
        [childNode] : newChildNode
      }
    
      // delete newFlow[nodeToBeDeleted]
    
    
      //creating new template
      const newTemplate = {
        ...template,
        template_One : newFlow
      }
    
    
      return newTemplate
  }

  //@CASE -- child node is null
  //that means the node to be deleted have branches
  //therefore adding end message node by deleting the node
  else{


    //creating end message node if the node to be deleted have branches
    //that means that childNode is null therefore creating new end message node

    //getting new id for end message node
    const newEndMessageNodeId = getIdForNewNode(template , 1)

    //creating new end message node
    let newEndMessageNode = null
  
    //getting the initial data of end message node
    newEndMessageNode = {...initialDataOfNode[100],
    parentNode}
  


    //creating new parent node
    let newParentNode = null
      
      
    //@CASE - parent node have branches
    if(flow[parentNode].trigger.length > 0){
      newParentNode = {
        ...flow[parentNode],
        trigger : flow[parentNode].trigger.map((item) => {
          if(Number(item.next) === nodeToBeDeleted){
            return {
              ...item,
              next : newEndMessageNodeId
            }
          }
          else{
            return item
          }
        })
      }
    }

    //@CASE -- parent node is a bot message or any other node that does not validations
    else{
      newParentNode = {
        ...flow[parentNode],
        next : newEndMessageNodeId
      }
    }

    //creating new flow

    const newFlow = {
      ...flow,
      [parentNode] : newParentNode,
      [newEndMessageNodeId] : newEndMessageNode
    }


    //creating new template
    const newTemplate = {
      ...template,
      template_One : newFlow
    }


    return newTemplate
  }


  


  


  // console.log(parentNode + " " + childNode + " ")//for debugging purpose

  


  //getting the new parent node and the child node
  //changing the next link of parent node and parent link of child node
  // var newParentNode = null
  // var newChildNode = null

  //CASE - if the current node has no branches
  //changing the next link of the parent node to the child node of the current node
  // if(childNode){

  //   //if the parent node has branches -- changing the link of the parent node of that particular branch 
  //   if(flow[parentNode].trigger.length > 0){
  //     newParentNode = {
  //       ...flow[parentNode],
  //       trigger : flow[parentNode].trigger.map(item => {
  //         if(Number(item.next) === nodeToBeDeleted){
  //           return {
  //             ...item,
  //             next : childNode
  //           }
  //         }
  //         else{
  //           return item
  //         }
  //       })
  //     }
  //   }

  //   //if the parent node has no branches
  //   else if(flow[parentNode].Validation.doValidate){
  //     newParentNode = {
  //         ...flow[parentNode],
  //         Validation : {
  //           ...flow[parentNode].Validation,
  //           successful : childNode
  //         }
  //       }
  //   }

  //   //if the parent node has no branches
  //   else{
  //     newParentNode = {
  //         ...flow[parentNode],
  //         next : childNode
  //       }
  //   }


  //   //changing the parent link of the child node
  //   newChildNode = {
  //     ...flow[childNode],
  //     parentNode
  //   }

    

  // }
  // //CASE - if the current node has branches
  // //changing the next link of the parent node to NULL i.e deleting the whole subtree
  // else{

  //   //if the parent node has branches
  //   //changing the next link of the branch to null 
  //   if(flow[parentNode].trigger.length > 0){
  //     newParentNode = {
  //       ...flow[parentNode],
  //       trigger : flow[parentNode].trigger.filter(item => {
  //         if(Number(item.next) !== nodeToBeDeleted){
  //           return item
  //         }
  //       })
  //     }
  //   }

  //   //if the parent node has no branches
  //   //setting the next link of parent node to NULL
  //   else if(flow[parentNode].Validation.doValidate){
  //     newParentNode = {
  //         ...flow[parentNode],
  //         Validation : {
  //           ...flow[parentNode].Validation,
  //           successful : null
  //         }
  //       }
  //   }

  //   //if the parent node has no branches
  //   //setting the next link of the parent node to NULL
  //   else{
  //     newParentNode = {
  //         ...flow[parentNode],
  //         next : null
  //       }
  //   }


  //   //no change needed for the parent link of child node because we are deleting the entire subtree
  //   newChildNode = null
    

  // }


  // console.log( newParentNode)
  // console.log(newChildNode)



  //creating the new flow
  //if the current node has branches -- changing only parent node
  //if the current node has no branches -- changing the parent node and the child node
  // const newFlow = newChildNode  ?   {
  //   ...flow,
  //   [parentNode] : newParentNode,
  //   [childNode] : newChildNode
  // } : {
  //   ...flow,
  //   [parentNode] : newParentNode
  // } 


  

}




//utility function
//@DEF - to delete the node from the graph
//@PARAMETERS -- template , id of the node to be deleted
//@RETURN - new template after deletion
//@CONDITION -- current node has branches / parent node has branches
export function addNode(template , nodeToBeAdded){

  
  const source = Number(nodeToBeAdded.source)
  const target = Number(nodeToBeAdded.target)
  const nodeTypeId = Number(nodeToBeAdded.nodeTypeId)


  //array -- to store list of id's available for new nodes
  let listOfIdAvailable = []

  //@CASE -- node that have validations
  //need 3 id's -- for node itself, successful and unsuccessful
  if(nodeTypeId === 2 || nodeTypeId === 3){
    const noofIdNeeded = 3
    listOfIdAvailable = getIdForNewNode(template , noofIdNeeded)
  }
  //@CASE -- node that have branches or the node that show bot message on successful validation
  //need 2 id's -- for node itself, other is for end message node/successful bot message
  else if(nodeTypeId === 19 || nodeTypeId === 7 || nodeTypeId === 16 || nodeTypeId === 17 || nodeTypeId === 24 
    || nodeTypeId === 15 || nodeTypeId === 20 || nodeTypeId === 4 || nodeTypeId === 1 
    || nodeTypeId === 8 || nodeTypeId === 27 || nodeTypeId === 25 || nodeTypeId === 21){
    const noofIdNeeded = 2
    listOfIdAvailable = getIdForNewNode(template , noofIdNeeded)
  }

  else{
    const noofIdNeeded = 1
    listOfIdAvailable = getIdForNewNode(template , noofIdNeeded)
  }
  


  console.log(listOfIdAvailable , ' new id')//for debugging purpose

  //getting the flow from the template
  const flow = template.template_One

  
  //checking whether the node be added is a email node
  //email and phone node has validations
  //therefore need to add successful and unsuccessful node as well
  //@CASE 1 -- Email node
  if(nodeTypeId === 2){

    //getting the initialData of node
    let initialData = initialDataOfNode[nodeTypeId]
    
    //creating new email node and setting the next and parent link
    initialData = {
      ...initialData,
      parentNode : source,
      Validation : {
        ...initialData.Validation,
        successful : listOfIdAvailable[1],
        unsuccessful : listOfIdAvailable[2]
      }
    }

    //creating the successful node of email validation node
    let successfulNode = {
      data: { text: initialData.data.successful },
      trigger: initialData.trigger,
      next: target,
      continue: true,
      UI: 'Bot Message',
      Validation: { type: '', doValidate: false, loop: 0 },
      input: initialData.input,
      action: initialData.action,
      parentNode: listOfIdAvailable[0]
    }

    //creating the unsuccessful node of email validation node
    let unsuccessfulNode = {
      data: { text: initialData.data.unsuccessful.failedAttemptsText },
      trigger: initialData.trigger,
      next: null,
      continue: initialData.continue,
      UI: initialData.UI,
      Validation: {...initialData.Validation,
      successful : target,
      unsuccessful : listOfIdAvailable[2],
      // parentNode: listOfIdAvailable[0]
    },
      parentNode: listOfIdAvailable[0],
      input: initialData.input,
      action: initialData.action
    }



    //modifying parent node -- changing the next link
    //@CASES - source can have branches / source can have validate / source has only next


    let parentNode = null
    let successfulNodeOfParent = null
    let unsuccessfulNodeOfParent = null
    let successfulNodeOfParentId = null
    let unsuccessfulNodeOfParentId = null
    //@CASE 1 - source have branches
    if(flow[source].trigger.length > 0){

      initialData = {
        ...initialData,
        parentNode : source
      }

      parentNode = {
        ...flow[source],
        trigger : flow[source].trigger.map((item) => {
          if(Number(item.next) === target ){
            return {
              ...item,
              next : listOfIdAvailable[0]
            }
          }
          else{
            return item
          }
        })
      }

      

      
  }


  //@CASE 2 - source has validations
    else if(flow[source].Validation.doValidate){

      initialData = {
        ...initialData,
        parentNode : Number(flow[source].Validation.successful)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].Validation.successful)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

      console.log(unsuccessfulNodeOfParentId , ' maggi don')

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
        ...flow[unsuccessfulNodeOfParentId],
        Validation : {
          ...flow[unsuccessfulNodeOfParentId].Validation,
          successful : listOfIdAvailable[0]
        }
      } : null
    }

    else if(flow[source].UI === 'Get Location'){
      initialData = {
        ...initialData,
        parentNode : Number(flow[source].next)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].next)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = null

      console.log(unsuccessfulNodeOfParentId , ' maggi don')//for debugging purpose

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  null
    }


    //@CASE 3 - source has only next
    else{


      initialData = {
        ...initialData,
        parentNode : source
      }

      //modifying parent node
      parentNode = {
        ...flow[source],
        next : listOfIdAvailable[0]
        }
      }

    console.log(parentNode , ' parent')//for debugging purpose 


    //modifying child node
    //changing the link of the parent
    //making its parent to successful node of email node
    let childNode = flow[target]
    childNode = {
      ...childNode,
      parentNode : listOfIdAvailable[1]
    }

    console.log(childNode , ' child')//for debugging purpose

    //creating new flow
    let newFlow = null
    if(parentNode){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        [listOfIdAvailable[2]] : unsuccessfulNode,
        [source] : parentNode,
        [target] : childNode
      }
    }
    else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        [listOfIdAvailable[2]] : unsuccessfulNode,
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
        [target] : childNode
      }
    }
    else{
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        [listOfIdAvailable[2]] : unsuccessfulNode,
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [target] : childNode
      }
    }
    


    //creating new template
    const newTemplate = {
      ...template,
      template_One : newFlow
    }

    return {newTemplate,
      selectedNode : {
        data : initialData,
        id : listOfIdAvailable[0]
      }}
    }

  //checking whether the node be added is a  phone node
  //email and phone node has validations
  //therefore need to add successful and unsuccessful node as well
  //@CASE 2 -- phone node
    else if(nodeTypeId === 3){
       //getting the initialData of node
    let initialData = initialDataOfNode[nodeTypeId]
    
    //creating new email node and setting the next and parent link
    initialData = {
      ...initialData,
      parentNode : source,
      Validation : {
        ...initialData.Validation,
        successful : listOfIdAvailable[1],
        unsuccessful : listOfIdAvailable[2]
      }
    }
    let successfulNode = {
      data: { text: initialData.data.successful.botResponseText },
      trigger: initialData.trigger,
      next: target,
      continue: true,
      UI: 'Bot Message',
      Validation: { type: '', doValidate: false, loop: 0 },
      input: initialData.input,
      action: initialData.action,
      parentNode : listOfIdAvailable[0]
    }


    let unsuccessfulNode = {
      data: { text: initialData.data.unsuccessful.botResponseUnText },
      trigger: initialData.trigger,
      next: null,
      continue: initialData.continue,
      UI: initialData.UI,
      Validation: {...initialData.Validation,
        successful : target,
        unsuccessful : listOfIdAvailable[2],
        // parentNode: listOfIdAvailable[0]
      },
      input: initialData.input,
      action: initialData.action,
      parentNode : listOfIdAvailable[0]
    }



    //modifying parent node -- changing the next link
    //@CASES - source can have branches / source can have validate / source has only next


    let parentNode = null
    let successfulNodeOfParent = null
    let unsuccessfulNodeOfParent = null
    let successfulNodeOfParentId = null
    let unsuccessfulNodeOfParentId = null
    //@CASE 1 - source have branches
    if(flow[source].trigger.length > 0){

      initialData = {
        ...initialData,
        parentNode : source
      }

      parentNode = {
        ...flow[source],
        trigger : flow[source].trigger.map((item) => {
          if(Number(item.next) === target ){
            return {
              ...item,
              next : listOfIdAvailable[0]
            }
          }
          else{
            return item
          }
        })
      }

      
  }


  //@CASE 2 - source has validations
    else if(flow[source].Validation.doValidate){

      initialData = {
        ...initialData,
        parentNode : Number(flow[source].Validation.successful)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].Validation.successful)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

      console.log(unsuccessfulNodeOfParentId , ' maggi')

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
        ...flow[unsuccessfulNodeOfParentId],
        Validation : {
          ...flow[unsuccessfulNodeOfParentId].Validation,
          successful : listOfIdAvailable[0]
        }
      } : null
    }

    else if(flow[source].UI === 'Get Location'){
      initialData = {
        ...initialData,
        parentNode : Number(flow[source].next)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].next)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = null

      console.log(unsuccessfulNodeOfParentId , ' maggi don')//for debugging purpose

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  null
    }


    //@CASE 3 - source has only next
    else{


      initialData = {
        ...initialData,
        parentNode : source
      }

      //modifying parent node
      parentNode = {
        ...flow[source],
        next : listOfIdAvailable[0]
        }
      }

    console.log(parentNode , ' parent')//for debugging purpose 

    //modifying child node
    //changing the link of the parent
    //making its parent to successful node of email node
    let childNode = flow[target]
    childNode = {
      ...childNode,
      parentNode : listOfIdAvailable[1]
    }

    console.log(childNode , ' child')//for debugging purpose


    //creating new flow
    let newFlow = null
    if(parentNode){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        [listOfIdAvailable[2]] : unsuccessfulNode,
        [source] : parentNode,
        [target] : childNode
      }
    }
    else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        [listOfIdAvailable[2]] : unsuccessfulNode,
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
        [target] : childNode
      }
    }
    else{
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        [listOfIdAvailable[2]] : unsuccessfulNode,
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [target] : childNode
      }
    }


    //creating new template
    const newTemplate = {
      ...template,
      template_One : newFlow
    }

    return {newTemplate,
      selectedNode : {
        data : initialData,
        id : listOfIdAvailable[0]
      }}
    
    }

    //checking whether the node to be added have branches
    //button node and card node have branches
    //need to create 2 new id's -- for the node itself and one for the end node
    //@CASE 3 -- button node and card node
    else if(nodeTypeId === 19 || nodeTypeId === 7){


      //getting the initialData of node
      let initialData = initialDataOfNode[nodeTypeId]
    
      //creating new button/card node and setting the next and parent link
      initialData = {
        ...initialData,
        parentNode : source,
        trigger : initialData.trigger.map((item,index) => {
            if(index === 0){
              return {
                ...item,
                next : target
              }
            }
            else{
              return {
                ...item,
                next : listOfIdAvailable[1]
              };
            }
        })
      }

 //modifying parent node -- changing the next link
    //@CASES - source can have branches / source can have validate / source has only next


    let parentNode = null
    let successfulNodeOfParent = null
    let unsuccessfulNodeOfParent = null
    let successfulNodeOfParentId = null
    let unsuccessfulNodeOfParentId = null
    //@CASE 1 - source have branches
    if(flow[source].trigger.length > 0){

      initialData = {
        ...initialData,
        parentNode : source
      }

      parentNode = {
        ...flow[source],
        trigger : flow[source].trigger.map((item) => {
          if(Number(item.next) === target ){
            return {
              ...item,
              next : listOfIdAvailable[0]
            }
          }
          else{
            return item
          }
        })
      }

      
  }


  //@CASE 2 - source has validations
    else if(flow[source].Validation.doValidate){

      initialData = {
        ...initialData,
        parentNode : Number(flow[source].Validation.successful)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].Validation.successful)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

      console.log(unsuccessfulNodeOfParentId , ' maggi')

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
        ...flow[unsuccessfulNodeOfParentId],
        Validation : {
          ...flow[unsuccessfulNodeOfParentId].Validation,
          successful : listOfIdAvailable[0]
        }
      } : null
    }

    else if(flow[source].UI === 'Get Location'){
      initialData = {
        ...initialData,
        parentNode : Number(flow[source].next)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].next)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = null

      console.log(unsuccessfulNodeOfParentId , ' maggi don')//for debugging purpose

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  null
    }


    //@CASE 3 - source has only next
    else{


      initialData = {
        ...initialData,
        parentNode : source
      }

      //modifying parent node
      parentNode = {
        ...flow[source],
        next : listOfIdAvailable[0]
        }
      }

    console.log(parentNode , ' parent')//for debugging purpose 



  //modifying child node
  //changing the link of the parent
  //making its parent to newly created node
  let childNode = flow[target]
  childNode = {
    ...childNode,
    parentNode : listOfIdAvailable[0]
  }

  console.log(childNode , ' child') //for debugging purpose


  const initialDataOfEndMessageNode = initialDataOfNode[100]

  let newEndMessageNode = {
    ...initialDataOfEndMessageNode,
    parentNode : listOfIdAvailable[0]
  }

  // initialData = {
  //   ...initialData,
  //   trigger : initialData.trigger.map((item , index) => {
  //       if(index === 1){
  //         return {
  //           ...item,
  //           next : newEndMessageNodeId
  //         }
  //       }
  //       else{
  //         return item
  //       }
  //   })
  // }


      //creating new flow
      let newFlow = null
      if(parentNode){
        newFlow = {
          ...flow,
          [listOfIdAvailable[0]] : initialData,
          [listOfIdAvailable[1]] : newEndMessageNode,          
          [source] : parentNode,
          [target] : childNode
        }
      }
      else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
        newFlow = {
          ...flow,
          [listOfIdAvailable[0]] : initialData,
          [listOfIdAvailable[1]] : newEndMessageNode,
          
          // [source] : parentNode,
          [successfulNodeOfParentId] : successfulNodeOfParent,
          [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
          [target] : childNode
        }
      }
      else{
        newFlow = {
          ...flow,
          [listOfIdAvailable[0]] : initialData,
          [listOfIdAvailable[1]] : newEndMessageNode,
          
          // [source] : parentNode,
          [successfulNodeOfParentId] : successfulNodeOfParent,
          [target] : childNode
        }
      }


  //creating new template
  const newTemplate = {
    ...template,
    template_One : newFlow
  }


  return {newTemplate,
    selectedNode : {
      data : initialData,
      id : listOfIdAvailable[0]
    }}
  

  }

  //@CASE 4 -- handoff node , appointment , bot message , file upload
  else if(nodeTypeId === 9 || nodeTypeId === 5 || nodeTypeId === 0 
    || nodeTypeId === 13 || nodeTypeId === 10 || nodeTypeId === 18 
    || nodeTypeId === 22 || nodeTypeId === 23 || nodeTypeId === 14
    || nodeTypeId === 12){

    //getting the initial data of the handoff node
    let initialData = initialDataOfNode[nodeTypeId]

    //creating new node to add
    if(nodeTypeId === 0){
      initialData = {
        ...initialData,
        addedBy : 'user',
        // parentNode : source,
        // parentNode : flow[source],
        next : target
      }
    }else{
      initialData = {
        ...initialData,
        // parentNode : source,
        // parentNode : flow[source],
        next : target
      }
    }
    
    


    //modifying parent node -- changing the next link
    //@CASES - source can have branches / source can have validate / source has only next


    let parentNode = null
    let successfulNodeOfParent = null
    let unsuccessfulNodeOfParent = null
    let successfulNodeOfParentId = null
    let unsuccessfulNodeOfParentId = null
    //@CASE 1 - source have branches
    if(flow[source].trigger.length > 0){


      //checking whether the parent node has any bot message in the branch or not
      //if the target is not found in trigger array that means there is a bot message in that branch

      // let targetFound  = false
      
      // for(var i=0;i<flow[source].trigger.length;i++){
      //     if(Number(flow[source].trigger[i]) === target){
      //       targetFound = true
      //     }
      // }

      // if(targetFound){
        initialData = {
          ...initialData,
          parentNode : source
        }
  
        parentNode = {
          ...flow[source],
          trigger : flow[source].trigger.map((item) => {
            if(Number(item.next) === target ){
              return {
                ...item,
                next : listOfIdAvailable[0]
              }
            }
            else{
              return item
            }
          })
        }
      // }

      // else{
      //   initialData = {
      //     ...initialData,
      //     parentNode : Number(flow[target].parentNode)
      //   }

      //   parentNode = {
      //     ...flow[Number(flow[target].parentNode)],
      //     next : listOfIdAvailable[0]
      //   }
      // }

      

      
  }


  //@CASE 2 - source has validations
    else if(flow[source].Validation.doValidate){

      initialData = {
        ...initialData,
        parentNode : Number(flow[source].Validation.successful)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].Validation.successful)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

      console.log(unsuccessfulNodeOfParentId , ' maggi')

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
        ...flow[unsuccessfulNodeOfParentId],
        Validation : {
          ...flow[unsuccessfulNodeOfParentId].Validation,
          successful : listOfIdAvailable[0]
        }
      } : null
    }


    else if(flow[source].UI === 'Get Location'){
      initialData = {
        ...initialData,
        parentNode : Number(flow[source].next)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].next)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = null

      console.log(unsuccessfulNodeOfParentId , ' maggi don')//for debugging purpose

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  null
    }

    //@CASE 3 - source has only next
    else{


      initialData = {
        ...initialData,
        parentNode : source
      }

      //modifying parent node
      parentNode = {
        ...flow[source],
        next : listOfIdAvailable[0]
        }
      }

    console.log(parentNode , ' parent')//for debugging purpose 



    //modifying child node
    //changing the link of the parent
    //making its parent to the newly created node
    let childNode = flow[target]
    childNode = {
      ...childNode,
      parentNode : listOfIdAvailable[0]
    }

    console.log(childNode , ' child')//for debugging purpose


    //creating new flow
    let newFlow = null
    if(parentNode){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        // [listOfIdAvailable[1]] : successfulNode,
        // [listOfIdAvailable[2]] : unsuccessfulNode,
        [source] : parentNode,
        [target] : childNode
      }
    }
    else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        // [listOfIdAvailable[1]] : successfulNode,
        // [listOfIdAvailable[2]] : unsuccessfulNode,
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
        [target] : childNode
      }
    }
    else{
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        // [listOfIdAvailable[1]] : successfulNode,
        // [listOfIdAvailable[2]] : unsuccessfulNode,
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [target] : childNode
      }
    }


    //creating new template
    const newTemplate = {
      ...template,
      template_One : newFlow
    }

    return {newTemplate,
      selectedNode : {
        data : initialData,
        id : listOfIdAvailable[0]
      }}
    
  }

  //@CASE 5 -- date picker or currency
  //these nodes show bot message after succesful 
  //therefore need to add 2 nodes here
  else if(nodeTypeId === 16 || nodeTypeId === 17 || nodeTypeId === 24 
    || nodeTypeId === 15 || nodeTypeId === 20 
    || nodeTypeId === 4 || nodeTypeId === 1 || nodeTypeId === 8
    || nodeTypeId === 27 || nodeTypeId === 25){


    //getting the initial data
    let initialData = initialDataOfNode[nodeTypeId]


    //creating new email node and setting the next and parent link
    initialData = {
      ...initialData,
      parentNode : source,
      Validation : {
        ...initialData.Validation,
        successful : listOfIdAvailable[1],
        // unsuccessful : listOfIdAvailable[2]
      }
    }

    //creating bot message node on successfull
    let successfulNode = {
      data: { text: initialData.data.responseText },
      trigger: initialData.trigger,
      next: target,
      continue: true,
      UI: 'Bot Message',
      Validation: { type: '', doValidate: false, loop: 0 },
      input: initialData.input,
      action: initialData.action,
      parentNode: listOfIdAvailable[0]
    }


   //modifying parent node -- changing the next link
    //@CASES - source can have branches / source can have validate / source has only next


    let parentNode = null
    let successfulNodeOfParent = null
    let unsuccessfulNodeOfParent = null
    let successfulNodeOfParentId = null
    let unsuccessfulNodeOfParentId = null
    //@CASE 1 - source have branches
    if(flow[source].trigger.length > 0){

      initialData = {
        ...initialData,
        parentNode : source
      }

      parentNode = {
        ...flow[source],
        trigger : flow[source].trigger.map((item) => {
          if(Number(item.next) === target ){
            return {
              ...item,
              next : listOfIdAvailable[0]
            }
          }
          else{
            return item
          }
        })
      }

      
  }


  //@CASE 2 - source has validations
    else if(flow[source].Validation.doValidate){

      initialData = {
        ...initialData,
        parentNode : Number(flow[source].Validation.successful)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].Validation.successful)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

      console.log(unsuccessfulNodeOfParentId , ' maggi')

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
        ...flow[unsuccessfulNodeOfParentId],
        Validation : {
          ...flow[unsuccessfulNodeOfParentId].Validation,
          successful : listOfIdAvailable[0]
        }
      } : null
    }


    else if(flow[source].UI === 'Get Location'){
      initialData = {
        ...initialData,
        parentNode : Number(flow[source].next)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].next)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = null

      console.log(unsuccessfulNodeOfParentId , ' maggi don')//for debugging purpose

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  null
    }

    //@CASE 3 - source has only next
    else{


      initialData = {
        ...initialData,
        parentNode : source
      }

      //modifying parent node
      parentNode = {
        ...flow[source],
        next : listOfIdAvailable[0]
        }
      }

    console.log(parentNode , ' parent')//for debugging purpose 



    //modifying child node
    //changing the link of the parent
    //making its parent to successful node of email node
    let childNode = flow[target]
    childNode = {
      ...childNode,
      parentNode : listOfIdAvailable[1]
    }

    console.log(childNode , ' child')//for debugging purpose


    //creating new flow
    let newFlow = null
    if(parentNode){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        
        [source] : parentNode,
        [target] : childNode
      }
    }
    else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
        [target] : childNode
      }
    }
    else{
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [target] : childNode
      }
    }


    //creating new template
    const newTemplate = {
      ...template,
      template_One : newFlow
    }

    return {newTemplate,
      selectedNode : {
        data : initialData,
        id : listOfIdAvailable[0]
      }}
    


  }

  //@CASE 6 -- get location
  //these nodes show bot message through their next property
  //therefore need to add 2 nodes here
  else if(nodeTypeId === 21){

    console.log('control comes here')
       //getting the initial data
    let initialData = initialDataOfNode[nodeTypeId]


    //creating new email node and setting the next and parent link
    initialData = {
      ...initialData,
      parentNode : source,
      next : listOfIdAvailable[1]
    }

    //creating bot message node on successfull
    let successfulNode = {
      data: { text: initialData.data.responseText },
      trigger: initialData.trigger,
      next: target,
      continue: true,
      UI: 'Bot Message',
      Validation: { type: '', doValidate: false, loop: 0 },
      input: initialData.input,
      action: initialData.action,
      parentNode: listOfIdAvailable[0]
    }


   //modifying parent node -- changing the next link
    //@CASES - source can have branches / source can have validate / source has only next


    let parentNode = null
    let successfulNodeOfParent = null
    let unsuccessfulNodeOfParent = null
    let successfulNodeOfParentId = null
    let unsuccessfulNodeOfParentId = null
    //@CASE 1 - source have branches
    if(flow[source].trigger.length > 0){

      initialData = {
        ...initialData,
        parentNode : source
      }

      parentNode = {
        ...flow[source],
        trigger : flow[source].trigger.map((item) => {
          if(Number(item.next) === target ){
            return {
              ...item,
              next : listOfIdAvailable[0]
            }
          }
          else{
            return item
          }
        })
      }

      
  }


  //@CASE 2 - source has validations
    else if(flow[source].Validation.doValidate){

      initialData = {
        ...initialData,
        parentNode : Number(flow[source].Validation.successful)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].Validation.successful)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

      console.log(unsuccessfulNodeOfParentId , ' maggi')

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
        ...flow[unsuccessfulNodeOfParentId],
        Validation : {
          ...flow[unsuccessfulNodeOfParentId].Validation,
          successful : listOfIdAvailable[0]
        }
      } : null
    }


    else if(flow[source].UI === 'Get Location'){
      initialData = {
        ...initialData,
        parentNode : Number(flow[source].next)
      }

      // parentNode = {
      //   ...flow[source],
      //   Validation : {
      //     ...flow[source].Validation,
      //     successful : listOfIdAvailable[0]
      //   }
      // }

      successfulNodeOfParentId = Number(flow[source].next)

      //modifying the successful node of parent
      successfulNodeOfParent = {
        ...flow[successfulNodeOfParentId],
        next : listOfIdAvailable[0]
      }

      unsuccessfulNodeOfParentId = null

      console.log(unsuccessfulNodeOfParentId , ' maggi don')//for debugging purpose

      //modifying unsuccessful node of parent
      unsuccessfulNodeOfParent =  null
    }

    //@CASE 3 - source has only next
    else{


      initialData = {
        ...initialData,
        parentNode : source
      }

      //modifying parent node
      parentNode = {
        ...flow[source],
        next : listOfIdAvailable[0]
        }
      }

    console.log(parentNode , ' parent')//for debugging purpose 


    //modifying child node
    //changing the link of the parent
    //making its parent to successful node of email node
    let childNode = flow[target]
    childNode = {
      ...childNode,
      parentNode : listOfIdAvailable[1]
    }

    console.log(childNode , ' child')//for debugging purpose


    //creating new flow
    let newFlow = null
    if(parentNode){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        
        [source] : parentNode,
        [target] : childNode
      }
    }
    else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
        [target] : childNode
      }
    }
    else{
      newFlow = {
        ...flow,
        [listOfIdAvailable[0]] : initialData,
        [listOfIdAvailable[1]] : successfulNode,
        
        // [source] : parentNode,
        [successfulNodeOfParentId] : successfulNodeOfParent,
        [target] : childNode
      }
    }


    //creating new template
    const newTemplate = {
      ...template,
      template_One : newFlow
    }

    return {newTemplate,
      selectedNode : {
        data : initialData,
        id : listOfIdAvailable[0]
      }}
    

  }

}





//utility function
//@DEF - to get the id available for new node
//@PARAMETERS -- template
//@RETURN - integer(of which key is not there in the flow)

export function getIdForNewNode(template , noofIdNeeded){

  //getting the flow from the template
  const flow = template.template_One

  //constant
  const maxValue = 2000

  //counter is used to track how many id has been found available
  let counter = 0;
  const arr = new Array(noofIdNeeded)
  //iterating over the all integer and for whose the key is not there in flow we are returning that integer
  for(var i=1 ; i <= maxValue ; i++){
    if(!flow[i]){
      arr[counter] = i;
      counter++
    }
    if(counter === noofIdNeeded){
      return arr;
    }
  }
  return [];
}

//utility function
//@DEF - to add the parent node property in each node of template
//@PARAMETERS -- template
//@RETURN - new template with added property parentNode


export function addParentNodeInTemplate(template){


  //starting index
  const startKey = 0

  //parent node of root node / welcome node
  const rootParentNode = -1

  //getting the flow from the template
  const flow = template

  console.log(flow , ' flow') //for debugging purpose

  //calling recursive function
  //traverse through each node and add the property in each call
  const newFlow = getNewFlow(flow , rootParentNode , startKey)

  console.log(newFlow , ' uyuy')//for debugging purpose

  return newFlow
}


//recursive function
function getNewFlow(flow ,parentNode , key){


  //base case 
  if(flow[key].trigger.length <= 0 && !(flow[key].Validation.doValidate) && Number(flow[key].next) === 0){
    const newFlow = {
      ...flow,
      [key] : {
        ...flow[key],
        parentNode
      }
    }
    return newFlow
  }

  const newFlow = {
    ...flow,
    [key] : {
      ...flow[key],
      parentNode
    }
  }

  
  if(flow[key].trigger.length > 0){
    let result = newFlow
    flow[key].trigger.map((item) => {
      result  = getNewFlow(result , key , Number(item.next))
    })

    return result
  }

  else if(flow[key].Validation.doValidate){
    let result = newFlow
    result = getNewFlow(result , key , Number(flow[key].Validation.successful))
    // if(flow[key].Validation.unsuccessful){
    //     result = getNewFlow(result , key , Number(flow[key].Validation.unsuccessful))
    // }
    return result
  }

  else{
    return getNewFlow(newFlow , key , Number(flow[key].next))
  }

  

}


// addParentNodeInTemplate(require('./../data/templates/Answer'))











