import React from "react";
import { useEffect,useState } from "react";
import { useSelector , useDispatch
 } from "react-redux";
import { updateinstalledIntegrations } from "../../features/integraion/integrationSlice";
import { installZendesk, uninstallZendesk ,getAllAccounts,removeGlobal} from "./../../../src/utilities/chatbotMethods";

function ZendeskIntegration(props) {

    const [isLoading, setIsLoading] = useState(true);
    const [accountKey, setAccountKey] = useState("");
    const {installedIntegrations} = useSelector(state => state.integration)
    const [mode, setMode] = useState(props.mode);
    const [installedAccounts, setInstalledAccounts] = useState([]);

    


    const dispatch = useDispatch();

    useEffect(() => {
      if(props.mode=="uninstall"){
        setIsLoading(false);
    }else{
    getAllAccounts("zendesk").then((data) => {
      if (data.length > 0) {
        setInstalledAccounts(data);
        setMode("switch")
        setIsLoading(false);
      }else{
        setIsLoading(false);
      }
    });
    }

    }, []);

    const handleSubmit =  (event) => {
        event.preventDefault();
        setIsLoading(true);
        var data ={"Account key":accountKey}
        setZendesk(data,true)
        
       
     



    };


    const handleUninstall = () => {
        setIsLoading(true);
        uninstallZendesk().then(status=>{
            if(status){
                dispatch(updateinstalledIntegrations(installedIntegrations.filter(item=>item!=='zendesk')))
                setIsLoading(false);
                props.closePopup()

            }
        })
    }

    const setZendesk = (data,addGlobal) => {
      installZendesk(data,addGlobal).then(status=>{
        if(status){
            dispatch(updateinstalledIntegrations(['zendesk',...installedIntegrations]))
            setIsLoading(false);
            if(props.isFromEditor){
              props.editorFunction()
          }
            props.closePopup()

        }
    })
    }
  return (
    <>
    {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}

    {isLoading ? (<p>Loading...</p>) : 
    mode === 'uninstall' ? (<>
    <span> Are you sure you want to uninstall {props.appData.title} ?</span>
    <br/>
        {isLoading ? <span>...</span> :<button onClick={()=>{handleUninstall()}}> Yes,uninstall</button>}
        <br/>
        <button onClick={()=>{props.closePopup()}}>No</button> 
    </>) : mode == "switch" ? (
        <>
          <div className="mt-5">
            <h4>
              <b>Choose to continue</b>
            </h4>
            <ul className="useracc_list">
            {installedAccounts.map((item,index)=> {
                return(
                <li>
                    <div onClick={()=>{setZendesk(item,false)}}>

                <span className="us_icon">{index+1}</span>
                <br/>
                <span className="us_details">
                  <span className="us_name">{item['Account key']}</span>
                </span>
                </div>
                <span onClick={()=>{removeGlobal("zendesk",index)}} className="sign_out">Sign out</span>
              </li>
              )
            }
                )}
                <li>
                    <div onClick={()=>{setMode("install")}}>
                <span className="us_icon">
                  <img src="/dist/img/public/img/user.svg" alt="user Img" />
                </span>
                <span className="us_details">
                  <span className="us_name">Use another account</span>
                </span>
                </div>
              </li>
            </ul>
          </div>
        </>
      ):
    
    (<div >
    <div >
      <div >
        <img src={props.appData.img} />
      </div>
      <div>
        <div >
          <h4>Zendesk Chat</h4>
        </div>
        <div >
          <p >
            Manage all live agent transfers in zendesk chat
          </p>
        </div>
        <div>
          <span >Learn more:</span>
        </div>
        <div />
        <div />
      </div>
    </div>
    <div>
      <div>
        <form  onSubmit={(event) => handleSubmit(event)}>
          <div>
            <div>
            
              <label htmlFor="">Enter the account key</label>
                                <input type="text" value={accountKey} onChange={(event) => setAccountKey(event.target.value)} required placeHolder='Account Key' />
                                <br />
                               
              
            </div>
          </div>
          <div className="row">
            <div className="col">
            <br/>
            {isLoading ? <span>...</span> :<input type="submit" value='Connect' />}

                
              
              <br/>
             <span onClick={()=>{props.closePopup()}}>Cancel</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>)}
  </>
    
  );

}

export default ZendeskIntegration;
