import React from 'react'
import NodePopup from '../node-types/NodePopup'

function NodeMenu() {
  return (
    <div>
        <NodePopup/>         
    </div>
  )
}

export default NodeMenu