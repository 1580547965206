import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { setGptDataGlobal,setGptLoader } from "./../../features/trainGpt/trainGptSlice";
import { getGptData } from'../../utilities/chatbotMethods'
import {storageConnection} from './../../config/firebase' 
import { NotificationManager } from "react-notifications";



import "react-accessible-accordion/dist/fancy-example.css";

import { useEffect } from "react";
import { setAllowSave } from "../../features/globalSave/globalSaveSlice";

const Traingpt = () => {

  const storage=storageConnection
  const dispatch = useDispatch();
  const {gptDataGlobal,gptLoader} = useSelector((state) => state.trainGpt);

  const allowedExts = ["pdf", "docx", "txt", "csv", "xlsx", "xls", "md", "doc"]
  const fileInputRef = React.createRef();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [initFiles , setInitFiles] = useState([]);
  const [uploadedUrls, setUploadedUrls] = useState([]);
  // const [initUrls, setInitUrls] = useState([]);
  const [pcText, setPcText] = useState("");
  const [fileError, setFileError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [websiteError, setWebsiteError] = useState(null);
  const [input_url_new, setInputUrlNew] = useState("");
  const [textError, setTextError] = useState(null);
  const [allowSaveText, setAllowSaveText] = useState(null);
  const [enableText,setEnableText] = useState(false)
  const [activeTabIndex,setActiveTabIndex] = useState(0)
  
  // const [websiteError, setWebsiteError] = useState(null);
  



  useEffect(() => {

    // this.props.showPreviewMethod(false);
    // console.log(this.props.data, "ISTHIS STEPS");

    getGptData(localStorage.getItem("botId")).then((gptData) => {

    
    console.log(gptData, "gptDatagptDatagptData");

    let initUrls = gptData && gptData.import_urls ? [...gptData.import_urls] : [];
    let initFiles =
      gptData && gptData.import_files ? [...gptData.import_files] : [];
    let initText = gptData && gptData.pcText ? gptData.pcText : "";

    setUploadedFiles(initFiles)
    setUploadedUrls(initUrls)
    setPcText(initText)

    // setInitFiles(initFiles)


    gptData["initFiles"] = initFiles;
    gptData["initText"] = initText;
    gptData["initUrls"] = initUrls;
    gptData["import_urls"] =initUrls;
    gptData["import_files"] = initFiles
    gptData["pcText"] = initText
    var areUntrainedData = false;
    initFiles.forEach((file) => {
      if (!file.tokens) {
        areUntrainedData = true;
      }
    });
    if (!areUntrainedData) {
      initUrls.forEach((url) => {
        if (!url.tokens) {
          areUntrainedData = true;
        }
      });
    }
    if (areUntrainedData) {
      // this.props.saveNextDisabled(true);
    }
    // this.setState({
    //   // import_url_kb_status:
    //   //   gptData && gptData.import_url_status ? gptData.import_url_status : {},
    //   urls: gptData && gptData.import_urls ? gptData.import_urls : [], //["https://www.airtel.in/airtel-thanks/faq"],
    //   files: gptData && gptData.import_files ? gptData.import_files : [],
    //   pcText: gptData && gptData.pcText ? gptData.pcText : "",
    // });
    // this.props.saveGptData(gptData);
    dispatch(setGptDataGlobal(gptData));
    // stopLoader("*");

    setInterval(() => {
     updateFileUploadPercentage();
    }, 100);
  })




  },[])

  useEffect(()=>{
    
    let initUrls = gptDataGlobal && gptDataGlobal.import_urls ? [...gptDataGlobal.import_urls] : [];
    let initFiles =
    gptDataGlobal && gptDataGlobal.import_files ? [...gptDataGlobal.import_files] : [];
    let initText = gptDataGlobal && gptDataGlobal.pcText ? gptDataGlobal.pcText : "";

    setUploadedFiles(initFiles)
    setUploadedUrls(initUrls)
    setPcText(initText)

  },[gptDataGlobal])


  const handleFileUpload = async (e,drag=false) => {
    var tfiles = Array.from(uploadedFiles)
    var files = 
    drag ? e.dataTransfer.files :
    e.target.files; // array of file objects
   setFileError(false);
    var storageRef = storage.ref();
    console.log(files, "upload_files");
    Array.from(files).forEach((file) => {
      console.log(file, "file_object");
      var i = -1;
      let isValidFile = false;
      var name = file.name;
      let s = [];
      s = name.split(".");
      if (s.length == 0) {
      } else {
        s = s.reverse();
        s = s[0];
        if (allowedExts.includes(s)) {
          isValidFile = true;
        }
      }

      if (!isValidFile) {
        // this.setState({
        //   fileError: true,
        //   errorMsg: "File type not supported.",
        // });
        setFileError(true);
        setErrorMsg("File type not supported.");

      }

      var fsiz = file.size / 1024 / 1024;
      if (fsiz > 2) {
        // this.setState({ fileError: true, errorMsg: "File size is too large." });
        setFileError(true);
        setErrorMsg("File size is too large.");
        isValidFile = false;
      }
      tfiles.forEach((f, index) => {
        console.log("CHECK IF ", f.name, file.name);
        if (f.name == file.name) {
          i = index;
          // this.setState({ fileError: true, errorMsg: "File is already uploaded." });
          setFileError(true);
          setErrorMsg("File is already uploaded.");
          
        }
      });
      if (i == -1 && isValidFile) {
        if (tfiles.length >= 2) {
          // this.setState({
          //   fileError: true,
          //   errorMsg: "Only 2 files allowed.",
          // });
          setFileError(true);
          setErrorMsg("Only 2 files allowed.");
        } else {
          // this.setState({ fileError: false, errorMsg: "" });
          setFileError(false);
          
         tfiles.push({
            name: file.name,
            url: "",
            isUploaded: false,
            u_percent: 10,
          });
          setUploadedFiles(tfiles);
          var fileRef = storageRef.child(
            localStorage.getItem("tablealias") + "/" + file.name
          );
          fileRef.put(file).then((snapshot) => {
            console.log("Uploaded a blob or file!");
            fileRef.getDownloadURL().then(async (url) => {
              tfiles.forEach(async (f) => {
                if (f.name == name) {
                  f.url = url;
                  f.isUploaded = true;

                  // var gptData = {
                  //   initUrls: initUrls,
                  //   initFiles: initFiles,
                  //   import_urls: this.state.urls,
                  //   import_files: this.state.files,
                  //   initText: initText,
                  //   pcText: this.state.pcText,
                  //   // import_url_status: this.state.import_url_kb_status,
                  // };
                  // setUploadedFiles(tfiles);
                  var gptData = {...gptDataGlobal};
                  gptData["import_files"] = tfiles
                  dispatch(setGptDataGlobal(gptData))
                  dispatch(setAllowSave(true))
                  // await updateImportDataToDb(param_array, gptData)
                  // console.log(gptData,"this.state.import_url_kb_status")
                  // console.log(this.state.files, "COMPLEgptData", gptData);
                  // this.props.saveNextColor("#dc3545");
                  // this.props.changesNotSaved(true);
                  // this.props.saveNextDisabled(true);
                }
              });
            });
          });
        }
      } else {
        // setTimeout(() => {
        //   this.setState({ fileError: false, errorMsg: "" });
        // }, 3000);
      }
    });
    
  };

 
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Prevent default behavior for the dragenter event to enable drop
  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  // Handle the drop event when files are dropped onto the input
  const handleDrop = (event) => {
    event.preventDefault();
    handleFileUpload(event, true);
  }
  const handleDeleteFiles = (item) => {
    var tfiles=Array.from(uploadedFiles)
    // console.log(item, "deleteFile");
    var i = -1;
    uploadedFiles.forEach((f, index) => {
      if (f.name == item.name) {
        i = index;
      }
    });
    if (i > -1) {
      tfiles.splice(i, 1);
      // this.setState({ files: this.state.files, fileError: false });
      var gptData = {...gptDataGlobal};
      gptData["import_files"] = tfiles
      // setUploadedFiles(tfiles)
      dispatch(setGptDataGlobal(gptData))
      console.log("AFTER DELETE FILE", gptData);
      
      // this.props.saveGptData(gptData);
      // // updateImportDataToDb(param_array, gptData)
      // this.props.saveNextColor("#dc3545");
      // this.props.changesNotSaved(true);
      // this.props.saveNextDisabled(true);
    }

  }
  const handleTabChange = (e) => {
    setActiveTabIndex(e)
  }

  const updateFileUploadPercentage = () => {
    var check = false;
    // console.log(this.state.files,"THIS SYTAYTE FILES")
    var tfiles = uploadedFiles;
    tfiles.forEach((file) => {
      if (!file.isUploaded) {
        check = true;
        file.u_percent = file.u_percent + (100 - file.u_percent) / 20;
      }
    });
    if (check) {
     setUploadedFiles(tfiles);
    }
  };


  const handleAddUrl = async () => {
    var add_url = input_url_new.trim();
    var turls=Array.from(uploadedUrls)
    if (add_url == "") {
      // alert("url_cant_be_empty");
      NotificationManager.error("", "Url can not be empty.", 2500);
      return;
    }
    var isValid = false;
    if (add_url.search("https://") == 0) {
      isValid = true;
    }
    if (!isValid) {
      alert("enter valid url");
      NotificationManager.error("", "Enter valid url.", 2500);
      // this.setState({ input_url_new: "" });
      setInputUrlNew("")
      return;
    }
    var isAlreadyAdded = false;
    uploadedUrls.forEach((url) => {
      if (add_url == url.name) {
        isAlreadyAdded = true;
        NotificationManager.error("", "Url is already added.", 2500);
      }
    });

    if (!isAlreadyAdded) {
      if (uploadedUrls.length >= 2) {
        // this.setState({ websiteError: true });
        setWebsiteError(true)
      } else {
        turls.push({ name: add_url, url: add_url });
        // console.log(this.state.import_url_kb_status,"this.state.import_url_kb_status")
        // this.state.import_url_kb_status[add_url] = 'added'
        // this.setState({ input_url_new: "" });
        setInputUrlNew("")
        // var gptData = {
        //   initUrls: initUrls,
        //   initFiles: initFiles,
        //   import_urls: this.state.urls,
        //   import_files: this.state.files,
        //   initText: initText,
        //   pcText: this.state.pcText,
        //   // import_url_status: this.state.import_url_kb_status,
        // };
        var gptData = {...gptDataGlobal};
        gptData["import_urls"] = turls;
        // this.props.saveGptData(gptData);
        dispatch(setGptDataGlobal(gptData))

        // this.props.saveNextDisabled(true);
        dispatch(setAllowSave(true))
      }
    }
  };
  const handleDeleteUrl = (item, temp) => {
    var turls = Array.from(uploadedUrls);
    // this.props.saveNextDisabled(true);
    dispatch(setAllowSave(true))
    var i = uploadedUrls.indexOf(item);
    console.log(uploadedUrls,item,"ITE< OF UPLOADED URL",i)

    //
    if (i > -1) {
      turls.splice(i, 1);
      // this.setState({ urls: this.state.urls, websiteError: false });
      // setUploadedUrls(turls)
      setWebsiteError(false)
      // var gptData = {
      //   initUrls: initUrls,
      //   initFiles: initFiles,
      //   import_urls: this.state.urls,
      //   import_files: this.state.files,
      //   initText: initText,
      //   pcText: this.state.pcText,
      //   // import_url_status: this.state.import_url_kb_status,
      // };
      var gptData = {...gptDataGlobal};
      gptData["import_urls"] = turls
      // this.props.saveGptData(gptData);
      dispatch(setGptDataGlobal(gptData))

      // if(!isTrained){
      // updateImportDataToDb(param_array, import_data)
      // this.props.saveNextColor('#dc3545')
      // this.props.changesNotSaved(true)
      // }
    }
  };

  const handleInputUrl = (e) => {
    // this.setState({ input_url_new: e.target.value });
    setInputUrlNew(e.target.value)
  };

  const handleTextInput = (e) => {
    if (e.target.value.length <= 5000) {
      // this.setState({
      //   pcText: e.target.value,
      //   allowSaveText:
      //     e.target.value != gptDataGlobal.pcText ||
      //     e.target.value != gptDataGlobal.initText,
      // });
      setPcText(e.target.value)
      setAllowSaveText(e.target.value != gptDataGlobal.pcText ||
             e.target.value != gptDataGlobal.initText)
      if (textError) {
        // this.setState({ textError: false, errorMsg: "" });
        setTextError(false)
        setErrorMsg("")
      }
    } else {
      // this.setState({
      //   pcText: e.target.value,

      //   textError: true,
      //   errorMsg:
      //     "You've crossed the limit of 1000 characters. Upload a file instead.",
      // });
      setPcText(e.target.value)
      setTextError(true)
      setErrorMsg("You've crossed the limit of 1000 characters. Upload a file instead.")
    }
  };
  const saveText = async () => {
    // this.setState({ allowSaveText: false });
    setAllowSaveText(false)
    // var gptData = {
    //   initUrls: initUrls,
    //   initFiles: initFiles,
    //   import_urls: this.state.urls,
    //   import_files: this.state.files,
    //   initText: initText,
    //   pcText: this.state.pcText,
    //   // import_url_status: this.state.import_url_kb_status,
    // };
    var gptData = {...gptDataGlobal};
    gptData["pcText"] = pcText;
    // this.props.saveNextDisabled(true);
    // this.props.saveGptData(gptData);
    dispatch(setGptDataGlobal(gptData))
    dispatch(setAllowSave(true))
    NotificationManager.success("Text saved successfully. Train Now", "", 1000);
  };
  const resetText = () => {
    // this.setState({ pcText: "", textError: false, allowSaveText: true });
    setPcText("")
    setTextError(false)
    setAllowSaveText(true)
  };

  return (
    <>
      {/* <SideMenu /> */}
        <div className="boatEditSection bg-white">

      {gptLoader?(
                   <div className="loaderViewBot">
                    <div className="loaderPreview">
                  
                    

                  <div
                    id="mainView">
                    <div className="headerChatBot" style={{display: "flex", background: 'rgb(44, 48, 73)', height: "70px", borderRadius: '15px 15px 0px 0px'}}>
                      <div className="avatar">
                        <div className="tpl-avatar">
                          <div className="tpl-avatar-img">
                            <img src="https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&amp;token=6a39c949-c97b-4811-9414-c21c2e249985" />
                          </div>
                          <div className="tpl-avatar-status"></div>
                        </div>
                      </div>
                      <div
                        className="innerHeaderBoatLogo"
                        style={{display: "flex"}}
                      >
                        <h1 style={{color: "#fff"}}>Q&A Bot</h1>
                      </div>
                    </div>
                    <ul className="loder-view next-step-change">
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">
                      Uploading your data
                      </span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">
                      Analyzing data format
                      </span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">Preparing data for training</span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">Initializing training process</span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">
                      Training the chatbot on your data
                      </span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">
                      Fine-tuning responses
                      </span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">
                      Optimizing model performance
                      </span>
                    </li>
                    <li>
                      <span className="circle"></span>
                      <span className="line"></span>
                      <span className="loder-text">
                      Done! Your Chatbot is ready for preview
                      </span>
                    </li>
                  </ul>
                  
                  <div className="powerdByAppypie">
                          <p>
                            <span className="powerdBySnapyIcon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 9.15 12.64"
                              >
                                <g data-name="Layer 2">
                                  <g data-name="Layer 1">
                                    <path
                                      fill="#fcf4a0"
                                      d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z"
                                    ></path>
                                    <path
                                      d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z"
                                      fill="#f4a51f"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </span>
                            {/* {strings("chatbot.powered_by")}
                             */}
                            {/* {console.log(
                              this.props.data.customisation.companyNameLogo ==
                                "Logo",
                              "SSPPPP",
                              this.props.data.customisation.companyNameLogo
                                ? this.props.data.customisation
                                    .companyNameLogo == "Logo"
                                : true
                            )} */}
                           
                          </p>
                        </div>
                  </div>
                  
                </div>
                    </div>
                ):<>
        <Tabs  selectedIndex={activeTabIndex}
                        // onSelect={() => {
                        //   handleTabChange;
                        // }}
                        >
      <TabList className="flex space-x-4">
        <Tab   onClick={() => {
                              handleTabChange(0);
                            }}className="py-2 px-4 bg-blue-500 text-white hover:bg-blue-700 cursor-pointer">
         File
        </Tab>
        <Tab  onClick={() => {
                              handleTabChange(1);
                            }}className="py-2 px-4 bg-green-500 text-white hover:bg-green-700 cursor-pointer">
         Text
        </Tab>
        <Tab  onClick={() => {
                              handleTabChange(2);
                            }}className="py-2 px-4 bg-red-500 text-white hover:bg-red-700 cursor-pointer">
         Web link
        </Tab>
        <Tab  onClick={() => {
                              handleTabChange(3);
                            }}className="py-2 px-4 bg-yellow-500 text-white hover:bg-yellow-700 cursor-pointer">
          Custom
        </Tab>
      </TabList>

      <TabPanel>
        <div className="p-4">Content for Tab 1</div>


        <div className="upload-section text-center">
                              <h4 className="mb-3">Upload files</h4>
                              <div className="uploadInput">
                                <input
                                  className="fileInput"
                                  type="file"
                                  title={allowedExts.toString(",")}
                                  ref={fileInputRef}
                                  onClick={(e) => {
                                  fileInputRef.current.value = null;
                                  }}
                                  accept={allowedExts}
                                  onChange={(e) => {
                                   handleFileUpload(e);
                                  }}
                                  onDragOver={handleDragOver}
                                  onDragEnter={handleDragEnter}
                                  onDrop={handleDrop}
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="38.367"
                                  height="44.675"
                                  viewBox="0 0 38.367 44.675"
                                >
                                  <g
                                    id="Group_94200"
                                    data-name="Group 94200"
                                    transform="translate(-502.238 -411)"
                                  >
                                    <g
                                      id="Group_94171"
                                      data-name="Group 94171"
                                      transform="translate(30 -13.134)"
                                    >
                                      <g
                                        id="Group_94166"
                                        data-name="Group 94166"
                                        transform="translate(465.845 420.846)"
                                      >
                                        <g
                                          id="Group_94167"
                                          data-name="Group 94167"
                                        >
                                          <g
                                            id="Group_94164"
                                            data-name="Group 94164"
                                          >
                                            <g
                                              id="Group_94168"
                                              data-name="Group 94168"
                                            >
                                              <g
                                                id="Group_94162"
                                                data-name="Group 94162"
                                              >
                                                <g
                                                  id="Group_94169"
                                                  data-name="Group 94169"
                                                >
                                                  <path
                                                    id="Path_141459"
                                                    data-name="Path 141459"
                                                    d="M80.861,32.941V33.5c0,2.625-.01,5.251,0,7.876a5.636,5.636,0,0,0,4.613,5.637,7.262,7.262,0,0,0,1.439.119c2.514.011,5.028.005,7.542.005h.623v8.731c-5.494-.791-9.945.912-12.876,5.638-2.716,4.378-2.378,8.859.327,13.245a3.069,3.069,0,0,1-.35.038c-4.528,0-9.057.01-13.585,0a4.7,4.7,0,0,1-4.6-4.582q-.013-16.35,0-32.7a4.7,4.7,0,0,1,4.6-4.589c3.946-.013,7.891,0,11.837,0,.129,0,.257.014.436.024M79.519,53.053q4.6,0,9.191,0a3.1,3.1,0,0,0,.788-.089,1.4,1.4,0,0,0-.06-2.708,3.378,3.378,0,0,0-.742-.069q-9.166-.006-18.332,0a3.3,3.3,0,0,0-.644.049,1.367,1.367,0,0,0-1.144,1.464,1.465,1.465,0,0,0,1.653,1.356q4.645.006,9.291,0m-4.668,7.695q2.372,0,4.745,0a1.417,1.417,0,1,0,.032-2.819q-4.77-.01-9.54,0a1.38,1.38,0,0,0-1.515,1.418,1.4,1.4,0,0,0,1.533,1.4c1.582,0,3.163,0,4.745,0M73.785,45.3c1.248,0,2.5,0,3.743,0a1.414,1.414,0,1,0,.015-2.818q-3.719-.009-7.437,0a1.413,1.413,0,1,0-.015,2.818c1.231.006,2.462,0,3.694,0m-.218,23.214c1.148,0,2.3.029,3.443-.017a2.232,2.232,0,0,0,1.1-.363,1.3,1.3,0,0,0,.42-1.524,1.415,1.415,0,0,0-1.431-.962q-3.518-.02-7.037.006a1.842,1.842,0,0,0-.846.246,1.375,1.375,0,0,0-.605,1.556A1.455,1.455,0,0,0,70.024,68.5c1.181.015,2.362,0,3.543,0"
                                                    transform="translate(-57.594 -29.625)"
                                                    fill="#161615"
                                                  />
                                                  <path
                                                    id="Path_141460"
                                                    data-name="Path 141460"
                                                    d="M261.788,303.994a9.772,9.772,0,1,1,9.8-9.711,9.754,9.754,0,0,1-9.8,9.711m1.424-9.749c.164.085.262.136.361.186a1.383,1.383,0,0,0,1.749-.353,1.4,1.4,0,0,0,.016-1.751c-.835-.97-1.685-1.927-2.569-2.852a1.354,1.354,0,0,0-1.936,0c-.894.937-1.776,1.892-2.6,2.894a1.346,1.346,0,0,0,.83,2.168,4.738,4.738,0,0,0,1.317-.154c0,.089,0,.249,0,.41,0,1.063-.012,2.126.008,3.189a1.408,1.408,0,0,0,2.816-.052c.011-1.206,0-2.411,0-3.681"
                                                    transform="translate(-226.829 -256.031)"
                                                    fill="#161615"
                                                  />
                                                  <path
                                                    id="Path_141461"
                                                    data-name="Path 141461"
                                                    d="M267.738,47.927H265.2c-1.765,0-3.529,0-5.294,0a3.316,3.316,0,0,1-3.539-3.376c-.046-2.591-.011-5.184-.01-7.776a3.32,3.32,0,0,1,.062-.358,17.5,17.5,0,0,1,1.442,1.137c1.559,1.522,3.091,3.071,4.633,4.61,1.4,1.4,2.812,2.786,4.2,4.2.422.432.767.94,1.147,1.413l-.1.15"
                                                    transform="translate(-230.733 -32.778)"
                                                    fill="#161615"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                                <div className="mt-3">
                                  <strong>Click to Upload</strong> or drag and
                                  drop
                                </div>
                                <span className="warning">
                                  Files must be{" "}
                                  {allowedExts.toString(",")} and not
                                  exceed more than 2 mb.
                                </span>
                              </div>

                             

                              <>
                                {fileError ? (
                                  <div className="fileDanger d-flex align-items-center">
                                    <div className="leftDanger">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="11.977"
                                        height="10.646"
                                        viewBox="0 0 11.977 10.646"
                                      >
                                        <path
                                          id="Icon_awesome-exclamation-triangle"
                                          data-name="Icon awesome-exclamation-triangle"
                                          d="M11.842,9.15a1,1,0,0,1-.865,1.5H1a1,1,0,0,1-.865-1.5L5.124.5A1,1,0,0,1,6.853.5ZM5.989,7.361a.957.957,0,1,0,.957.957A.957.957,0,0,0,5.989,7.361ZM5.08,3.923l.154,2.828a.25.25,0,0,0,.249.236H6.493a.25.25,0,0,0,.249-.236L6.9,3.923a.25.25,0,0,0-.249-.263H5.33a.25.25,0,0,0-.249.263Z"
                                          fill="#b10707"
                                        />
                                      </svg>
                                    </div>
                                    <div className="rightDanger">
                                      <h4>{errorMsg}</h4>
                                      <p>
                                        Choose{" "}
                                        <span className="text-primary font-weight-bold cursor-pointer">
                                          <u
                                            onClick={() => {
                                             handleTabChange(3);
                                            }}
                                          >
                                            Custom
                                          </u>
                                        </span>{" "}
                                        for larger dataset training
                                      </p>
                                    </div>
                                  </div>
                                ) : // <p className="error-msg">
                                //   {this.state.errorMsg}
                                // </p>
                                null}
                              </>
                              <div className="file-upload text-left container">
                                {uploadedFiles.length > 0 ? (
                                  <h5>Files Uploaded</h5>
                                ) : null}
                                {uploadedFiles.map((item, i) => {
                                  return (
                                    <div className="uploadList mb-4">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="file-details">
                                          <span>{item.name}</span>
                                          {/* <small>{item.size}</small> */}
                                        </div>

                                        <div className="d-flex align-items-center delete">
                                          {item.isUploaded ? (
                                            <>
                                              <span>
                                                {item.tokens
                                                  ? item.tokens + " Token"
                                                  : "To be trained"}{" "}
                                              </span>
                                              <p className="mb-0">
                                                <svg
                                                  onClick={(i) =>
                                                   handleDeleteFiles(
                                                      item,
                                                      i
                                                    )
                                                  }
                                                  id="DeleteIcon"
                                                  data-name="Group 94221"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14.984"
                                                  height="15.749"
                                                  viewBox="0 0 14.984 15.749"
                                                >
                                                  <path
                                                    id="Path_141464"
                                                    data-name="Path 141464"
                                                    d="M52.521,39.987c0-.272,0-.517,0-.763a1.528,1.528,0,0,1,1.5-1.532q1.386-.022,2.773,0a1.526,1.526,0,0,1,1.494,1.533c0,.245,0,.49,0,.761H58.5q1.882,0,3.764,0a.582.582,0,0,1,.63.59.567.567,0,0,1-.573.569c-.276.012-.552.011-.828,0-.134,0-.175.042-.183.172q-.106,1.847-.222,3.694c-.078,1.237-.162,2.474-.241,3.711-.053.831-.111,1.661-.15,2.493A2.361,2.361,0,0,1,58.68,53.4a4.15,4.15,0,0,1-.484.031q-2.809,0-5.618,0a2.342,2.342,0,0,1-2.414-2.022c-.063-.376-.063-.763-.088-1.146q-.106-1.577-.209-3.155c-.065-1-.127-2.008-.191-3.012-.06-.932-.123-1.864-.18-2.8-.007-.12-.057-.15-.168-.147-.258.006-.516,0-.774,0a.585.585,0,1,1,.008-1.163l3.746,0h.215m-1.847,1.2c.013.186.028.365.039.544q.145,2.439.288,4.877c.088,1.506.171,3.013.261,4.519a1.17,1.17,0,0,0,.5.914,1.3,1.3,0,0,0,.8.222q2.836,0,5.672,0c.072,0,.144,0,.216-.009a1.158,1.158,0,0,0,1.067-.955,4.294,4.294,0,0,0,.045-.5q.1-1.577.2-3.155c.054-.866.1-1.733.157-2.6.066-1.052.136-2.1.2-3.155.014-.225.025-.451.038-.7Zm6.455-1.212c0-.263.01-.513,0-.762a.321.321,0,0,0-.343-.319q-1.384-.006-2.768,0a.316.316,0,0,0-.334.308c-.014.254,0,.51,0,.772Z"
                                                    transform="translate(-47.91 -37.682)"
                                                    fill="#2c3049"
                                                  />
                                                  <path
                                                    id="Path_141465"
                                                    data-name="Path 141465"
                                                    d="M177.085,190.814q0,1.518,0,3.037a.576.576,0,0,1-1.126.18.644.644,0,0,1-.025-.194q0-3.046,0-6.092a.576.576,0,1,1,1.151,0q0,1.536,0,3.073"
                                                    transform="translate(-171.323 -181.766)"
                                                    fill="#2c3049"
                                                  />
                                                  <path
                                                    id="Path_141466"
                                                    data-name="Path 141466"
                                                    d="M239.946,190.777q0-1.5,0-3a.578.578,0,1,1,1.152,0q0,3.028,0,6.056a.577.577,0,0,1-.471.591.569.569,0,0,1-.649-.386.724.724,0,0,1-.03-.228q0-1.518,0-3.037"
                                                    transform="translate(-233.031 -181.766)"
                                                    fill="#2c3049"
                                                  />
                                                  <path
                                                    id="Path_141467"
                                                    data-name="Path 141467"
                                                    d="M303.959,190.8q0-1.509,0-3.019a.578.578,0,1,1,1.152,0q0,3.028,0,6.056a.576.576,0,0,1-1.127.191.8.8,0,0,1-.024-.23q0-1.5,0-3"
                                                    transform="translate(-294.739 -181.766)"
                                                    fill="#2c3049"
                                                  />
                                                </svg>
                                              </p>
                                            </>
                                          ) : (
                                            <span className="min-150 text-right">
                                              <div
                                                className="progress"
                                                style={{ height: "6px" }}
                                              >
                                                <div
                                                  className="progress-bar"
                                                  style={{
                                                    height: "6px",
                                                    width: item.u_percent + "%",
                                                    border: "none",
                                                    backgroundColor: "#4092fd",
                                                  }}
                                                ></div>
                                              </div>
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
      </TabPanel>
      <TabPanel>
        <div className="p-4">Content for Tab 2</div>

        <div className="container">
                              <div className="text-details">
                                <div className="total-text text-right mb-2">
                                  {/* {this.state.textError ? (
                                  <p style={{ color: "red" }}>
                                    {this.state.errorMsg}
                                  </p>
                                ) : null} */}
                                  <strong
                                    className={`${
                                      textError ? "error-msg" : ""
                                    }`}
                                  >
                                    {pcText.length}/5000 Characters
                                  </strong>
                                </div>
                                {true ||
                                enableText ||
                                pcText.length === 0 ? (
                                  <textarea
                                    style={{
                                      borderColor: textError
                                        ? "#f00"
                                        : "",
                                    }}
                                    placeholder="Type or paste your text here..."
                                    className="form-control mb-4"
                                    value={pcText}
                                    onChange={(e) => {
                                      handleTextInput(e);
                                    }}
                                    rows={15}
                                    onBlur={() => {
                                      // this.setState({ enableText: false });
                                      setEnableText(false)
                                    }}
                                  ></textarea>
                                ) : (
                                  <p
                                    style={{ cursor: "text" }}
                                    onClick={() => {
                                      // this.setState({ enableText: true });
                                      setEnableText(true)
                                    }}
                                  >
                                    {pcText}
                                  </p>
                                )}
                                <>
                                  {textError ? (
                                    <div className="fileDanger d-flex align-items-center">
                                      <div className="leftDanger">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="11.977"
                                          height="10.646"
                                          viewBox="0 0 11.977 10.646"
                                        >
                                          <path
                                            id="Icon_awesome-exclamation-triangle"
                                            data-name="Icon awesome-exclamation-triangle"
                                            d="M11.842,9.15a1,1,0,0,1-.865,1.5H1a1,1,0,0,1-.865-1.5L5.124.5A1,1,0,0,1,6.853.5ZM5.989,7.361a.957.957,0,1,0,.957.957A.957.957,0,0,0,5.989,7.361ZM5.08,3.923l.154,2.828a.25.25,0,0,0,.249.236H6.493a.25.25,0,0,0,.249-.236L6.9,3.923a.25.25,0,0,0-.249-.263H5.33a.25.25,0,0,0-.249.263Z"
                                            fill="#b10707"
                                          />
                                        </svg>
                                      </div>
                                      <div className="rightDanger">
                                        <h4>
                                          Content limit is 5000 characters
                                        </h4>
                                        <p>
                                          Choose{" "}
                                          <span className="text-primary font-weight-bold cursor-pointer">
                                            <u
                                              onClick={() => {
                                                handleTabChange(3);
                                              }}
                                            >
                                              Custom
                                            </u>
                                          </span>{" "}
                                          for training beyond this limit.
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}

                                  {textError ||
                                  !allowSaveText ? (
                                    <span className="btnBlue px-4 mr-4 disabled-Btn">
                                      Save
                                    </span>
                                  ) : (
                                    <button
                                      className="btnBlue px-4 mr-4"
                                      onClick={() => {
                                        saveText();
                                      }}
                                    >
                                      {" "}
                                      Save
                                    </button>
                                  )}
                                  <button
                                    className="btnBlue reset-btn"
                                    onClick={() => {
                                      resetText();
                                    }}
                                  >
                                    {" "}
                                    Reset
                                  </button>
                                </>
                              </div>
                            </div>

      </TabPanel>
      <TabPanel>
        <div className="p-4">Content for Tab 3</div>
        <div className="file-upload text-left container">
                              <h5>Crawl</h5>
                              <div className="inputLink d-flex mb-4 justify-content-between">
                                <div className="input-view">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="https://www.example.com/"
                                    value={input_url_new}
                                    onChange={(e) => {
                                      handleInputUrl(e);
                                    }}
                                  />
                                  <p>
                                    This will crawl all the content starting
                                    with the URL (not including files on the
                                    website).
                                  </p>
                                </div>
                                <button
                                  className="btnBlue"
                                  onClick={() => {
                                    handleAddUrl();
                                  }}
                                >
                                  Save link
                                </button>
                              </div>
                              {websiteError ? (
                                <div className="fileDanger d-flex align-items-center">
                                  <div className="leftDanger">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="11.977"
                                      height="10.646"
                                      viewBox="0 0 11.977 10.646"
                                    >
                                      <path
                                        id="Icon_awesome-exclamation-triangle"
                                        data-name="Icon awesome-exclamation-triangle"
                                        d="M11.842,9.15a1,1,0,0,1-.865,1.5H1a1,1,0,0,1-.865-1.5L5.124.5A1,1,0,0,1,6.853.5ZM5.989,7.361a.957.957,0,1,0,.957.957A.957.957,0,0,0,5.989,7.361ZM5.08,3.923l.154,2.828a.25.25,0,0,0,.249.236H6.493a.25.25,0,0,0,.249-.236L6.9,3.923a.25.25,0,0,0-.249-.263H5.33a.25.25,0,0,0-.249.263Z"
                                        fill="#b10707"
                                      />
                                    </svg>
                                  </div>
                                  <div className="rightDanger">
                                    <h4>
                                      {"Could not train more than 2 Urls."}
                                    </h4>
                                    <p>
                                      Choose{" "}
                                      <span className="text-primary font-weight-bold cursor-pointer">
                                        <u
                                          onClick={() => {
                                            handleTabChange(3);
                                          }}
                                        >
                                          Custom
                                        </u>
                                      </span>{" "}
                                      for training more Urls.
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="file-upload text-left container mt-5">
                              <div className="d-flex justify-content-between">
                                {uploadedUrls.length > 0 ? (
                                  <h5>Websites Added</h5>
                                ) : null}
                                {/* <h5 className="linkView">Delete all</h5> */}
                              </div>
                              {uploadedUrls.map((item, index) => {
                                return (
                                  <div className="uploadList mb-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="file-details">
                                        <span>{item.name}</span>
                                      </div>
                                      <div className="d-flex align-items-center delete">
                                        <span>
                                          {item.tokens
                                            ? item.tokens + " Token"
                                            : "To be trained"}{" "}
                                        </span>
                                        <p className="mb-0">
                                          <svg
                                            onClick={() => {
                                              handleDeleteUrl(item);
                                            }}
                                            id="Group_94221"
                                            data-name="Group 94221"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.984"
                                            height="15.749"
                                            viewBox="0 0 14.984 15.749"
                                          >
                                            <path
                                              id="Path_141464"
                                              data-name="Path 141464"
                                              d="M52.521,39.987c0-.272,0-.517,0-.763a1.528,1.528,0,0,1,1.5-1.532q1.386-.022,2.773,0a1.526,1.526,0,0,1,1.494,1.533c0,.245,0,.49,0,.761H58.5q1.882,0,3.764,0a.582.582,0,0,1,.63.59.567.567,0,0,1-.573.569c-.276.012-.552.011-.828,0-.134,0-.175.042-.183.172q-.106,1.847-.222,3.694c-.078,1.237-.162,2.474-.241,3.711-.053.831-.111,1.661-.15,2.493A2.361,2.361,0,0,1,58.68,53.4a4.15,4.15,0,0,1-.484.031q-2.809,0-5.618,0a2.342,2.342,0,0,1-2.414-2.022c-.063-.376-.063-.763-.088-1.146q-.106-1.577-.209-3.155c-.065-1-.127-2.008-.191-3.012-.06-.932-.123-1.864-.18-2.8-.007-.12-.057-.15-.168-.147-.258.006-.516,0-.774,0a.585.585,0,1,1,.008-1.163l3.746,0h.215m-1.847,1.2c.013.186.028.365.039.544q.145,2.439.288,4.877c.088,1.506.171,3.013.261,4.519a1.17,1.17,0,0,0,.5.914,1.3,1.3,0,0,0,.8.222q2.836,0,5.672,0c.072,0,.144,0,.216-.009a1.158,1.158,0,0,0,1.067-.955,4.294,4.294,0,0,0,.045-.5q.1-1.577.2-3.155c.054-.866.1-1.733.157-2.6.066-1.052.136-2.1.2-3.155.014-.225.025-.451.038-.7Zm6.455-1.212c0-.263.01-.513,0-.762a.321.321,0,0,0-.343-.319q-1.384-.006-2.768,0a.316.316,0,0,0-.334.308c-.014.254,0,.51,0,.772Z"
                                              transform="translate(-47.91 -37.682)"
                                              fill="#2c3049"
                                            />
                                            <path
                                              id="Path_141465"
                                              data-name="Path 141465"
                                              d="M177.085,190.814q0,1.518,0,3.037a.576.576,0,0,1-1.126.18.644.644,0,0,1-.025-.194q0-3.046,0-6.092a.576.576,0,1,1,1.151,0q0,1.536,0,3.073"
                                              transform="translate(-171.323 -181.766)"
                                              fill="#2c3049"
                                            />
                                            <path
                                              id="Path_141466"
                                              data-name="Path 141466"
                                              d="M239.946,190.777q0-1.5,0-3a.578.578,0,1,1,1.152,0q0,3.028,0,6.056a.577.577,0,0,1-.471.591.569.569,0,0,1-.649-.386.724.724,0,0,1-.03-.228q0-1.518,0-3.037"
                                              transform="translate(-233.031 -181.766)"
                                              fill="#2c3049"
                                            />
                                            <path
                                              id="Path_141467"
                                              data-name="Path 141467"
                                              d="M303.959,190.8q0-1.509,0-3.019a.578.578,0,1,1,1.152,0q0,3.028,0,6.056a.576.576,0,0,1-1.127.191.8.8,0,0,1-.024-.23q0-1.5,0-3"
                                              transform="translate(-294.739 -181.766)"
                                              fill="#2c3049"
                                            />
                                          </svg>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
      </TabPanel>
      <TabPanel>
        <div className="p-4">Content for Tab 4</div>
      </TabPanel>
    </Tabs>
    </>}
        </div>
    </>
  );
};

export default Traingpt;
