import React, { useState, useEffect } from "react";
import Select from "react-select";
import sortBy from "lodash.sortby";
import moment from "moment-timezone";
import { List } from "react-virtualized";

export const formatTimezone = tzString => 
  `(GMT${moment.tz(tzString).format("Z")}) ${tzString}`;

export const getTimezoneProps = tzString => {
  const tz = moment.tz(tzString);
  const tzStringOffset = tz
    .format("Z")
    .replace(":00", "")
    .replace(":30", ".5");
  const x = tzStringOffset === "0" ? 0 : parseFloat(tzStringOffset).toFixed(2);

  return {
    label: formatTimezone(tzString),
    value: `${tzString}`,
    time: `${x}`,
    offset: tz._offset
  };
};

export const makeVirtualMenuListComponent = ListProps => {
  return props => {
    return (
      <List
        rowCount={props.children.length}
        style={{
          width: "100%"
        }}
        rowHeight={34.18}
        height={300}
        width={600}
        rowRenderer={({ key, index, style }) => {
          return (
            <div style={style} key={key}>
              {props.children[index]}
            </div>
          );
        }}
        {...ListProps}
      />
    );
  };
};

const SelectTimezone = ({
  onChange,
  value: selectedTimezoneValue,
  isClearable = false,
  className,
  guess,
  ...restProps
}) => {
  const selectClassName = className ? className : "react-select-timezone";
  const [selectedValue, setSelectedValue] = useState(selectedTimezoneValue);

  useEffect(() => {
    if (guess && !selectedTimezoneValue) {
      try {
        const guessed = moment.tz.guess();
        onChange && onChange(guessed);
        setSelectedValue(guessed);
      } catch (error) {
        // Handle error if needed
      }
    }
  }, [selectedTimezoneValue, guess, onChange]);

  const timeZones = moment.tz.names().map(tz => {
    return getTimezoneProps(tz);
  });

  const sortedTimezones = sortBy(timeZones, [
    function(el) {
      return -el.time;
    }
  ]);

  const selected = sortedTimezones.find(({ value }) => {
    return value === selectedValue;
  });

  return (
    <Select
      className={selectClassName}
      isClearable={isClearable}
      options={sortedTimezones}
      isMulti={false}
      onChange={option => {
        if (option) {
          setSelectedValue(option.value);
          onChange && onChange(option.value);
        } else {
          setSelectedValue(null);
          onChange && onChange(null);
        }
      }}
      value={selected}
      {...restProps}
    />
  );
};

export default SelectTimezone;
