import React,{useState} from "react";
import WorkingHours from './WorkingHours'
import Config from './Config'
import { useSelector } from "react-redux";


function Availability(props) {
  const [configType, setconfigType] = useState("work");
    const {agentData} = useSelector((state) => state.agentData)



  return (



    <>
     
      <div className="border-b">
        <ul className="flex gap-2">
          <li
            className={`mr-4 px-4 py-2 cursor-pointer ${
              configType === "work" ? "border-b-2 border-thmColor" : null
            }`}
            onClick={() => setconfigType("work")}
          >
            Working Hours
          </li>
          <li
            className={`px-4 py-2 cursor-pointer ${
              configType === "config" ? "border-b-2 border-thmColor" : null
            }`}
            onClick={() => setconfigType("config")}
          >
            Configuration
          </li>
        </ul>
      </div>
      {configType === "work" &&  Object.keys(agentData).length>0&&
      <WorkingHours/>
    
     }
      {configType === "config" &&  Object.keys(agentData).length>0&&
      
      <Config/>
      }
    </>
  );
}

export default Availability;
