import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allowSave : false,
    showPreview : false
}



const globalSaveSlice = createSlice({
    name : "globalSave",
    initialState,
    reducers : {

        setAllowSave : (state, action) => {
            state.allowSave = action.payload
        },
        setShowPreview : (state, action) => {
            state.showPreview = action.payload
        }
    }
})


export const { setAllowSave  , setShowPreview} = globalSaveSlice.actions
export default globalSaveSlice.reducer