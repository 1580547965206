import React, { useEffect, useState } from "react";
import { useSelector  , useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import ColorPicker from "../../Component/ColorPicker";
import DesignPreview from "../../Component/previews/DesignPreview";
import SideMenu from "../../Component/sidemenu";
import { changeBasicSetting, updateDesign } from "../../features/botDesign/botDesignSlice";
import { updateTemplate } from "../../features/botTemplate/botTemplateSlice";
// import { getBotFlowChartData , resetTemplate } from "../../features/botTemplate/botTemplateSlice";

const DesignCustomization = (props) => {


  //getiing data from the global state
  //state -- state.botDesign
  const {design , basicSetting} = useSelector(state => state.botDesign)
  const {template , botDetails} = props
  const {customisation} = props.template.template_One
  const widgetImage = props.template.widgetImages
  const chatBotAvtar = props.template.chatBotAvtars
  
  // const badge = design.badge
  // const avtarSelect = design.avtarSelect

  const activeCss = "block bg-thmBG w-12 h-6 rounded-full"
  const disableCss = "block w-12 h-6 rounded-full bg-[#00000042]"




  //initializing dispatch
  const dispatch = useDispatch()


  //dispatching action
  //action -- updateDesign
  //updating the color value 
  const changeColor = (property , value) => {
    dispatch(updateDesign({property , value}))
  }
const [themeType, setThemeType] = useState('Color')
const [planType, setPlanType] = useState('Basic')
useEffect(() => {
  console.log(props.botDetails,"BOT DETAILS",props)
},[])


// useEffect(() => {
//   const urlParams = new URLSearchParams(window.location.search)
//   const botId = urlParams.get('did')
//   dispatch(getBotFlowChartData(botId))
// },[])


// useEffect(() => {
//   return () => {
//       dispatch(resetTemplate())
//   }
// },[])
    
  // const [design, setDesign] = useState({
  //   inquiryBot: "Answer bot",
  //   bgColor: "#5269C9",
  //   textColor: "#ffffff",
  //   widgetBgColor: "#6D85EB",
  //   widgetPosition: "right",
  //   widgetSideSpacing: 0,
  //   widgetBottomSpacing: 0,
  //   themeBgColor: "#ffffff",
  //   themeTextColor: "#333333",
  //   messageBgColor: "#EDF1FF",
  //   botMsgBGColor: "#edf1ff",
  //   botMsgTextColor: "#333333",
  //   UserMsgBgColor: "#edf1ff",
  //   UserMsgTextColor: "#333333",
  //   chatBublefontFamily: "'Poppins', sans-serif",
  //   chatBublefontSize: 14,
  //   sendBtnColor: "#6D85EB",
  //   botBtnColor: "#C6C6C6",
  //   botBtnFontFamily: "'Poppins', sans-serif",
  //   botBtnFontSize: 14,
  //   selectPowerdBy: "Company",
  //   enterCompanyName: "Appypie",
  //   footerPowerdBy: true,
  // });
  // const [basicSetting, setBasicSetting] = useState("basicSettings");
  // const [badge, setBadge] = useState("badge1");
  // const [avtarSelect, setAvtarSelect] = useState("avatar1");

  // const designSettings = (evt) => {
  //   let desName = evt.target.name;
  //   let desValue = evt.target.value;
  //   setDesign({ ...design, [desName]: desValue });
  // };

  // const designCustomizationSettings = (evt) => {
  //   let desValue = evt.target.value;
  //   setBasicSetting(desValue);
  // };

  // const displayPowerdBy = () => {
  //   setDesign({ ...design, footerPowerdBy: !design.footerPowerdBy });
  // };

  // const badgeStyle = (badge) => {
  //   setBadge(badge);
  // };

  // const avtarSelectClick = (avtarSelect) => {
  //   setAvtarSelect(avtarSelect);
  // };


  //handler function
  function handleHeaderNameChange(name){

    console.log(name , ' akshdga')
    const newTemplate = {
      ...template,
      template_One : {
        ...template.template_One,
        customisation : {
          ...template.template_One.customisation,
          headerName : name
        }
      }
    }
    dispatch(updateTemplate(newTemplate))
  }

    //handler function
    function handleHeaderBgColorChange(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            headerClr : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }


    //handler function
    function handleHeaderTextColorChange(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            headertextcolor : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleWidgetImageChange(url){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            widgetImage : url
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleWidgetBgColorChange(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            widgetBackgroundColor : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleWidgetPosition(property , value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            widgetPosition : {
              ...template.template_One.customisation.widgetPosition,
              [property] : value
            }
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }


    //handler function
    function handleChatbotAvtarChange(url){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            chatBotAvtar : url
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }


    //handler function
    function handleBotMessageBgColor(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            messagebubble : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleBotMessageTextColor(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            botTextcolor : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleUserMessageBgColor(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            clientBubbleClr : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleUserMessageTextColor(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            clientchattextcolor : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleChatBubbleFontSize(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            fontSize : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleChatBubbleFontFamily(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            fontStyle : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleSendButtonBgColorChange(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            sendButtonClr : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleBotButtonBgColorChange(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            botButtonColor : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }


     //handler function
     function handleBotButtonTextColorChange(color){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            botButtonTextColor : color
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

       //handler function
       function handleBotButtonFontSizeChange(value){
        const newTemplate = {
          ...template,
          template_One : {
            ...template.template_One,
            customisation : {
              ...template.template_One.customisation,
              buttonfontSize : value
            }
          }
        }
        dispatch(updateTemplate(newTemplate))
      }
  
      //handler function
      function handleBotButtonFontFamilyChange(value){
        const newTemplate = {
          ...template,
          template_One : {
            ...template.template_One,
            customisation : {
              ...template.template_One.customisation,
              buttonfontStyle : value
            }
          }
        }
        dispatch(updateTemplate(newTemplate))
      }

       //handler function
       function handleMessageOnLoadChange(value){
        const newTemplate = {
          ...template,
          template_One : {
            ...template.template_One,
            customisation : {
              ...template.template_One.customisation,
              messageOnLoad : value
            }
          }
        }
        dispatch(updateTemplate(newTemplate))
      }

      //handler function
      function handleInitialOpenChange(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            initialPopup : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

     //handler function
     function handleInitialPopupTimeChange(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            initialPopupTime : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

     //handler function
     function handleShowFooterChange(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            showFooter : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

    //handler function
    function handleCompanyNameLogoChange(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            companyNameLogo : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }

     //handler function
     function handleFooterCompanyNameChange(value){
      const newTemplate = {
        ...template,
        template_One : {
          ...template.template_One,
          customisation : {
            ...template.template_One.customisation,
            footerCompanyName : value
          }
        }
      }
      dispatch(updateTemplate(newTemplate))
    }
      

    

  return (
    <>
      {/* <SideMenu /> */}
        <div className="boatEditSection bg-white w-full">
          <div className="boatSettings w-full">
            <fieldset>
            

            <div className="border-b">
                  <ul className="flex gap-2">
                    <li className={`mr-4 px-4 py-2 cursor-pointer ${planType === 'Basic'? 'border-b-2 border-thmColor':null}`} onClick={ ()=> setPlanType('Basic')}>Basic Settings</li>
                    <li className={`px-4 py-2 cursor-pointer ${planType === 'Advance'? 'border-b-2 border-thmColor':null}`} onClick={ ()=> setPlanType('Advance')}>Advance Settings</li>
                  </ul>
              </div>

            {
              planType  ===  'Basic'  &&  <div className="py-5">
              <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                <p className="text-base text-[#5C5C5C] font-medium mb-4">Header</p>
                <div className="designSettingFeildsRow flex gap-6">
                  <div className="designSettingFeildsColum w-10/12">
                    <label className="text-xs block mb-1">Name</label>
                    <input
                      type="text"
                      placeholder=""
                      name="botName"
                      className="w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8]"
                      // onChange={designSettings}
                      value={customisation.headerName}
                      onChange={(event) => handleHeaderNameChange(event.target.value)}
                    />
                  </div>
                  

                  {/* Color Picker Component */}
                  <ColorPicker label = 'Background Color' inputName = 'bgColor' initialValue = {customisation.headerClr} changeColor = {handleHeaderBgColorChange}/>


                  


                  {/* Color Picker Component */}
                  <ColorPicker label = 'Text Color' inputName = 'textColor' initialValue = {customisation.headertextcolor} changeColor = {handleHeaderTextColorChange}/>

                </div>
              </div>
              <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                <p className="text-base text-[#5C5C5C] font-medium mb-4">Widget</p>
                <div className="designSettingFeildsRow flex gap-6 mb-4">
                  <div className="designSettingFeildsColum w-5/12">
                    <label className="text-xs block mb-1">Badge style</label>
                    <div className="flex gap-3">
                      

                      {/* looping over the widget images array and rendering */}
                      {widgetImage.map((item,index) => {
                        return <span key={index}
                        className="flex w-10 h-10 rounded bg-[#E4E9F9] items-center justify-center cursor-pointer hover:bg-[#536BDE] group"
                        // onClick={() => badgeStyle("badge1")}
                        onClick={() => {handleWidgetImageChange(item)}}
                      >
                        <img className="w-5" src={item}/>
                      </span>
                      })}
                      <span className="relative flex w-10 h-10 rounded bg-[#E4E9F9] items-center justify-center cursor-pointer hover:bg-[#536BDE] group">
                        <input type="file" className="absolute left-0 top-0 w-full h-full cursor-pointer opacity-0" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.591"
                          height="17.718"
                          viewBox="0 0 14.591 17.718"
                        >
                          <path
                            className="group-hover:fill-white"
                            id="Icon_material-file-upload"
                            data-name="Icon material-file-upload"
                            d="M11.669,18.049h6.253V11.8h4.169L14.8,4.5,7.5,11.8h4.169ZM7.5,20.133H22.091v2.084H7.5Z"
                            transform="translate(-7.5 -4.5)"
                            fill="#536bde"
                          />
                        </svg>
                      </span> 
                    </div>
                  </div>
                  

                  {/* Color Picker Component */}
                  <ColorPicker label = 'Background Color' inputName = 'widgetBgColor' initialValue = {customisation.widgetBackgroundColor} changeColor = {handleWidgetBgColorChange}/>

                </div>
                <div className="designSettingFeildsRow flex gap-6">
                  <div className="designSettingFeildsColum w-10/12">
                    <label className="text-xs block mb-1">Widget Position</label>
                    <select
                      className="w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center"
                      name="widgetPosition"
                      // onChange={designSettings}
                      value={customisation.widgetPosition.position}
                      onChange={(event) => {handleWidgetPosition('position' , event.target.value)}}
                    >
                      {/* <option>Widget Position</option> */}
                      <option value="right">Right</option>
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                      <option value="center">Center</option>
                    </select>
                  </div>
                  <div className="designSettingFeildsColum w-10/12">
                    <label className="text-xs block mb-1">Side spacing</label>
                    <span className="flex items-center text-[#8497A2]">
                      <input
                        type="number"
                        placeholder="Side spacing"
                        name="widgetSideSpacing"
                        className="mr-1 w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8]"
                        // onChange={designSettings}
                        value={customisation.widgetPosition.side}
                        onChange={(event) => {handleWidgetPosition('side' , event.target.value)}}
                      />
                      px
                    </span>
                  </div>
                  <div className="designSettingFeildsColum w-10/12">
                    <label className="text-xs block mb-1">Bottom spacing</label>
                    <span className="flex items-center text-[#8497A2]">
                      <input
                        type="number"
                        placeholder="Bottom spacing"
                        name="widgetBottomSpacing"
                        className="mr-1 w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8]"
                        // onChange={designSettings}
                        value={customisation.widgetPosition.bottom}
                        onChange={(event) => {handleWidgetPosition('bottom' , event.target.value)}}
                      />
                      px
                    </span>
                  </div>
                </div>
              </div>
              <div className="designSettingFeilds border rounded-2xl px-5 py-5 mb-4">
                <div className="flex justify-between mb-3">
                  <p className="text-base text-[#5C5C5C] font-medium mb-4">Theme Color</p>
                  <div className="bg-[#F7F8F8] p-3 rounded">
                      <ul className="flex justify-between gap-2">
                        <li className={`px-4 py-2 cursor-pointer rounded hover:bg-white hover:shadow-sm ${themeType === 'Color'? 'bg-white shadow-sm':null}`} onClick={ ()=> setThemeType('Color')}>Color</li>
                        <li className={`px-4 py-2 cursor-pointer rounded hover:bg-white hover:shadow-sm ${themeType === 'Gradient'? 'bg-white shadow-sm':null}`} onClick={ ()=> setThemeType('Gradient')}>Gradient</li>
                        <li className={`px-4 py-2 cursor-pointer rounded hover:bg-white hover:shadow-sm ${themeType === 'Transparent'? 'bg-white shadow-sm':null}`} onClick={ ()=> setThemeType('Transparent')}>Transparent</li>
                      </ul>
                  </div>
                </div>
                {
                  themeType  ===  'Color'  && <div className="designSettingFeildsRow flex gap-6">
                  <ColorPicker label = 'Select color' inputName = 'themeBgColor' initialValue = {design.themeBgColor} changeColor = {changeColor}/>
                </div>
                }
                {
                  themeType  ===  'Gradient'  && <div className="designSettingFeildsRow flex gap-6">
                  <ColorPicker label = 'Gradient From' inputName = 'themeBgColor' initialValue = {design.themeBgColor} changeColor = {changeColor}/>
                  <ColorPicker label = 'Gradient to' inputName = 'themeBgColor' initialValue = {design.themeBgColor} changeColor = {changeColor}/>
                </div>
                }
                {
                  themeType  ===  'Transparent'  && <div className="designSettingFeildsRow flex gap-6">
                  <ColorPicker label = 'Transparent' inputName = 'themeBgColor' initialValue = {design.themeBgColor} changeColor = {changeColor}/>
                </div>
                }
                
              </div>
              <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                <p className="text-base text-[#5C5C5C] font-medium mb-4">Chatbot</p>
                <div className="designSettingFeildsRow flex gap-6">
                  <div className="designSettingFeildsColum w-10/12">
                    <label className="text-xs block mb-1">Avatar Icons</label>
                    <div className="flex gap-3">
                      

                      {/* looping over the chatbot avatar images array and rendering */}
                      {chatBotAvtar.map((item,index) => {
                        return <span key={index}
                        className="flex w-10 h-10 rounded bg-[#E4E9F9] items-center justify-center cursor-pointer hover:bg-[#536BDE] group"
                        // onClick={() => badgeStyle("badge1")}
                        onClick={() => {handleChatbotAvtarChange(item)}}
                      >
                        <img className="w-5" src={item}/>
                      </span>
                      })}
                      <span className="relative flex w-8 h-8 rounded-full bg-[#E4E9F9] items-center justify-center cursor-pointer hover:bg-[#536BDE] group">
                        <input type="file" className="absolute left-0 top-0 w-full h-full cursor-pointer opacity-0" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14.591"
                          height="17.718"
                          viewBox="0 0 14.591 17.718"
                        >
                          <path
                            className="group-hover:fill-white"
                            id="Icon_material-file-upload"
                            data-name="Icon material-file-upload"
                            d="M11.669,18.049h6.253V11.8h4.169L14.8,4.5,7.5,11.8h4.169ZM7.5,20.133H22.091v2.084H7.5Z"
                            transform="translate(-7.5 -4.5)"
                            fill="#536bde"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="designSettingFeilds">
                <button className="flex items-center border border-indigo-600 text-sm px-3 py-2 text-center rounded text-indigo-600 bg-indigo-600 text-white ">
                  Save
                  <span className="inline-block pl-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.651"
                      height="10.651"
                      viewBox="0 0 10.651 10.651"
                    >
                      <path
                        id="Icon_awesome-save"
                        data-name="Icon awesome-save"
                        d="M10.316,4.578,8.322,2.584a1.141,1.141,0,0,0-.807-.334H1.141A1.141,1.141,0,0,0,0,3.391v8.368A1.141,1.141,0,0,0,1.141,12.9H9.509a1.141,1.141,0,0,0,1.141-1.141V5.385a1.141,1.141,0,0,0-.334-.807Zm-4.991,6.8A1.522,1.522,0,1,1,6.847,9.858,1.522,1.522,0,0,1,5.325,11.379ZM7.608,4.14v2.39a.285.285,0,0,1-.285.285H1.807a.285.285,0,0,1-.285-.285V4.057a.285.285,0,0,1,.285-.285H7.24a.285.285,0,0,1,.2.084l.083.083A.285.285,0,0,1,7.608,4.14Z"
                        transform="translate(0 -2.25)"
                        fill="#fff"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            }


      {/*Advanced Customisation */}
      {planType==="Advance"&& <div className="py-5 ">
              {/* Free plan  Design */}
              {/* {console.log("ADACVKJBSJKBDJKAISBDJKAB",botDetails.payment,botDetails.payment.payment_status  === 'complete' || botDetails.payment.payment_status  === 'trial' , botDetails.payment.planName  === 'Premium Plan')} */}
              {botDetails.payment&&(botDetails.payment.payment_status  === 'complete' || botDetails.payment.payment_status  === 'trial') && botDetails.payment.planName  === 'Premium Plan'? <><div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                    <p className="text-base text-[#5C5C5C] font-medium mb-4">Chat Bubbles</p>
                    <div className="designSettingFeildsRow flex gap-6 mb-4">
                       
  
                      {/* Color Picker Component */}
                      <ColorPicker label = 'Bot Message Background color' inputName = 'botMsgBGColor' initialValue = {customisation.messagebubble} changeColor = {handleBotMessageBgColor}/>
  
                      
  
                      {/* Color Picker Component */}
                      <ColorPicker label = 'Bot Message Text Color' inputName = 'botMsgTextColor' initialValue = {customisation.botTextcolor} changeColor = {handleBotMessageTextColor}/>
  
  
  
                      
  
  
                      {/* Color Picker Component */}
                      <ColorPicker label = 'User Message Background color' inputName = 'UserMsgBgColor' initialValue = {customisation.clientBubbleClr} changeColor = {handleUserMessageBgColor}/>
                    </div>
                    <div className="designSettingFeildsRow flex gap-6">
                      
  
  
                      {/* Color Picker Component */}
                      <ColorPicker label = 'User Message Text Color' inputName = 'UserMsgTextColor' initialValue = {customisation.clientchattextcolor} changeColor = {handleUserMessageTextColor}/>
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">Font Family</label>
                        <select
                          className="w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center"
                          value={customisation.fontStyle ? customisation.fontStyle : 'Roboto'}
                          name="chatBublefontFamily"
                          // onChange={designSettings}
                          onChange={(event) => {handleChatBubbleFontFamily(event.target.value)}}
                        >
                          <option value="Poppins">Poppins</option>
                          <option value="Roboto">Roboto</option>
                          <option value="Railway">Railway</option>
                          <option value="Aireal">Aireal</option>
                        </select>
                      </div>
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">Font Size</label>
                        <select
                          className="w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center"
                          value={customisation.fontSize ? customisation.fontSize : '13'}
                          name="chatBublefontSize" 
                          // onChange={designSettings}
                          onChange={(event) => {handleChatBubbleFontSize(event.target.value)}}
                        >
                          <option value="1">1px</option>
                          <option value="2">2px</option>
                          <option value="3">3px</option>
                          <option value="4">4px</option>
                          <option value="5">6px</option>
                          <option value="6">7px</option>
                          <option value="7">8px</option>
                          <option value="8">9px</option>
                          <option value="10">10px</option>
                          <option value="11">11px</option>
                          <option value="12">12px</option>
                          <option value="13">13px</option>
                          <option value="14">14px</option>
                          <option value="15">15px</option>
                          <option value="16">16px</option>
                          <option value="17">17px</option>
                          <option value="18">18px</option>
                          <option value="19">19px</option>
                          <option value="20">20px</option>
                          <option value="21">21px</option>
                          <option value="22">22px</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                    <p className="text-base text-[#5C5C5C] font-medium mb-4">Buttons</p>
                    <div className="designSettingFeildsRow flex gap-6 mb-4">
                      
  
                      {/* Color Picker Component */}
                      <ColorPicker label = 'Send Button Color' inputName = 'sendBtnColor' initialValue = {customisation.sendButtonClr} changeColor = {handleSendButtonBgColorChange}/>
  
                      
  
                      {/* Color Picker Component */}
                      <ColorPicker label = 'Bot Button Color' inputName = 'botBtnColor' initialValue = {customisation.botButtonColor} changeColor = {handleBotButtonBgColorChange}/>
  
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">Button Font Family & Size</label>
                        <span className="flex">
                          <select
                            name="botBtnFontFamily"
                            value={customisation.buttonfontStyle ? customisation.buttonfontStyle : 'Roboto'}
                            className="w-7/12 h-8 text-[#8497A2] rounded-l border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center"
                            // onChange={designSettings}
                            onChange={(event) => {handleBotButtonFontFamilyChange(event.target.value)}}
                          >
                            <option value="Poppins">Poppins</option>
                            <option value="Roboto">Roboto</option>
                            <option value="Railway">Railway</option>
                            <option value="Aireal">Aireal</option>
                          </select>
                          <select
                            name="botBtnFontSize"
                            value={customisation.buttonfontSize ? customisation.buttonfontSize : '10'}
                            className="w-4/12 h-8 text-[#8497A2] rounded-r border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center"
                            // onChange={designSettings}
                            onChange={(event) => {handleBotButtonFontSizeChange(event.target.value)}}
                          >
                            <option value="1">1px</option>
                            <option value="2">2px</option>
                            <option value="3">3px</option>
                            <option value="4">4px</option>
                            <option value="5">6px</option>
                            <option value="6">7px</option>
                            <option value="7">8px</option>
                            <option value="8">9px</option>
                            <option value="10">10px</option>
                            <option value="11">11px</option>
                            <option value="12">12px</option>
                            <option value="13">13px</option>
                            <option value="14">14px</option>
                            <option value="15">15px</option>
                            <option value="16">16px</option>
                            <option value="17">17px</option>
                            <option value="18">18px</option>
                            <option value="19">19px</option>
                            <option value="20">20px</option>
                            <option value="21">21px</option>
                            <option value="22">22px</option>
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                    <p className="text-base text-[#5C5C5C] font-medium mb-4">Show Messages</p>
                    <div className="designSettingFeildsRow flex gap-6 mb-4">
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">&nbsp;</label>
                        <label for="toggle" className="flex cursor-pointer text-sm">
                          On Load
                          <div className="relative ml-3">
                            <input onChange={(event) => handleMessageOnLoadChange(event.target.checked) } checked={customisation.messageOnLoad === undefined ? true : customisation.messageOnLoad } type="checkbox" id="toggle" className="sr-only" />
                            <div className={customisation.messageOnLoad === true || customisation.messageOnLoad === undefined ? activeCss : disableCss}></div>
                            <div className="dot absolute left-1 top-1 bg-gray-300 w-4 h-4 rounded-full transition"></div>
                          </div>
                        </label>
                      </div>
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">&nbsp;</label>
                        <label for="toggle2" className="flex cursor-pointer text-sm">
                          Initially Open
                          <div className="relative ml-3">
                            <input onChange={(event) => handleInitialOpenChange(event.target.checked) } checked={customisation.initialPopup === undefined ? false : customisation.initialPopup } type="checkbox" id="toggle2" className="sr-only" />
                            <div className={customisation.initialPopup === true  ? activeCss : disableCss}></div>
                            <div className="dot absolute left-1 top-1 bg-gray-300 w-4 h-4 rounded-full transition"></div>
                          </div>
                        </label>
                      </div>
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">Time to Open</label>
                        <span className="flex items-center text-[#8497A2]">
                          <input
                            onChange={(event) => handleInitialPopupTimeChange(event.target.value) }
                            value={customisation.initialPopupTime === undefined ? 0 : customisation.initialPopupTime }
                            type="number"
                            placeholder="Inquiry bot"
                            className="mr-1 w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8]"
                          />
                          Second
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                    <p className="text-base text-[#5C5C5C] font-medium mb-4">Footer (Powered By)</p>
                    <div className="designSettingFeildsRow flex gap-6 mb-4">
                      <div className="designSettingFeildsColum w-10/12">
                        <label className="text-xs block mb-1">&nbsp;</label>
                        <label for="footer" className="flex cursor-pointer text-sm">
                          Show Footer
                          <div className="relative ml-3">
                            {/* <input type="checkbox" id="footer" className="sr-only" onChange={displayPowerdBy} /> */}
                            <input checked = {customisation.showFooter === undefined ? true : customisation.showFooter} type="checkbox" id="footer" className="sr-only" name = 'footerPowerdBy' onChange={(event) => {handleShowFooterChange(event.target.checked)}} />
                            <div className={customisation.showFooter === true || customisation.showFooter === undefined ? activeCss : disableCss}></div>
                            <div className="dot absolute left-1 top-1 bg-gray-300 w-4 h-4 rounded-full transition"></div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="designSettingFeildsRow flex gap-16">
                      <div className="designSettingFeildsColum w-4/12">
                        <label className="text-xs block mb-1">Logo/Company Name</label>
                        <select
                          name="selectPowerdBy"
                          value={customisation.companyNameLogo ? customisation.companyNameLogo : "Name"}
                          className="w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8] flex items-center"
                          // onChange={designSettings}
                          onChange={(event) => {handleCompanyNameLogoChange(event.target.value)}}
                        >
                          
                          <option value="Logo">Logo</option>
                          <option value="Name">Name</option>
                        </select>
                      </div>
                      {customisation.companyNameLogo === "Name" || customisation.companyNameLogo === undefined ? (
                        <div className="designSettingFeildsColum w-4/12">
                          <label className="text-xs block mb-1">Enter Company Name</label>
                          <input
                            type="text"
                            name="enterCompanyName"
                            value={customisation.footerCompanyName ? customisation.footerCompanyName : ""}
                            placeholder="Appy Pie"
                            className="w-full h-8 text-[#8497A2] rounded border px-3 py-1 text-sm bg-[#F7F8F8]"
                            // onChange={designSettings}
                            onChange={(event) => {handleFooterCompanyNameChange(event.target.value)}}
                          />
                        </div>
                      ) : (
                        <div className="designSettingFeildsColum w-4/12">
                          <label className="text-xs block mb-1">Enter Company Logo</label>
                          <input
                            type="file"
                            class="border rounded bg-[#F7F8F8] block w-full text-sm text-[#8497A2]
                                                  file:mr-4 file:py-2 file:px-4
                                                  file:rounded-full file:border-0
                                                  file:text-sm file:font-semibold
                                                  file:bg-violet-50 file:text-violet-700
                                                  hover:file:bg-violet-100
                                                  "
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="designSettingFeilds border  rounded-2xl px-5 py-5 mb-4">
                    <p className="text-base text-[#5C5C5C] font-medium mb-4">Chatbot Settings</p>
                    <div className="designSettingFeildsRow flex gap-6">
                      <button className="border border-indigo-600 text-sm px-5 py-2 text-center rounded text-indigo-600 bg-indigo-600 text-white ">
                        Switch Bot Type/Bot Name
                      </button>
                    </div>
                  </div></> : <div className="text-center py-20">
                <h3 className="text-[28px] text-[#747474] mt-14">Advanced settings come under the premium plan</h3>
                <p className="text-[#747474] text-lg mt-2 mb-5">Please upgrade to access the advanced settings.</p>
                <img className="max-w-sm m-auto my-5" src={require('../../img/free-plan.png')} alt="Free Plan"/>
                <button className="border border-[#0983f8] text-sm px-10 py-2 text-center rounded-3xl bg-[#0983f8] text-white mt-8">Upgrade</button>
              </div>}
                
                  
                        </div>}
            </fieldset>
          </div>

        

          {/* new preview  */}
          {/* <DesignPreview/> */}
        </div>
    </>
  );
};

export default DesignCustomization;
