import React from "react";
import { Link } from "react-router-dom";
import SideMenu from "../../Component/sidemenu";
import Topheader from "../../Component/TopHeader";
import { COMMON_LOGIN_URL } from "../../config/params";

const DataManagement = () => {


  const onDelete = () => {
    window.open(COMMON_LOGIN_URL+"/privacy/subscriptions",'_blank')
	};
  return (
    <>
      {/* <SideMenu /> */}
      <div className="pl-[76px] lg:pl-[276px] p-5">
        <Topheader datamanagement="Data mangement" />
        <div className="previewSelectTemp">
          <div className="previewTempName mt-4">
            <p className="pb-4 text-[#454545] text-sm leading-7  font-light">
              In compliance with GDPR regulations we’ve provided some tools to enable customers to have primary control
              over their data.
            </p>
            <div className="previewTempSection p-10 bg-white sm:flex block rounded-2xl drop-shadow">
              <div className="dataManagementRow w-full flex flex-wrap justify-between justify-items-stretch ">
                <div className="dataManagementCol w-[32%] border px-5 py-5 rounded-lg mb-5">
                  <h4 className="font-semibold text-[#2E4765] text-base">View and edit your information</h4>
                  <p className="text-sm text-[#454545] py-5 leading-6">
                    With this option you’ll able to view and edit your information.
                  </p>
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    View and edit profile
                  </button>
                </div>
                <div className="dataManagementCol w-[32%] border px-5 py-5 rounded-lg mb-5">
                  <h4 className="font-semibold text-[#2E4765] text-base">Export data</h4>
                  <p className="text-sm text-[#454545] py-5 leading-6">
                    With this option you’ll be able to export all data of your bots.
                  </p>
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    Export data in process
                  </button>
                </div>
                <div className="dataManagementCol w-[32%] border px-5 py-5 rounded-lg mb-5">
                  <h4 className="font-semibold text-[#2E4765] text-base">Delete account</h4>
                  <p className="text-sm text-[#454545] py-5 leading-6">With this option you can delete your account.</p>
                  <button onClick={()=>{onDelete()}} className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    Delete my account
                  </button>
                </div>
                <div className="dataManagementCol w-[42%] border px-5 py-5 rounded-lg mb-5 mr-6">
                  <h4 className="font-semibold text-[#2E4765] text-base">Data consent</h4>
                  <p className="text-sm text-[#454545] py-5 leading-6">
                    Provide consent for processing your data to show analytics of your bot’s performance.
                  </p>
                  <div>
                    <label className="text-sm text-[#454545] pb-5 inline-block cursor-pointer leading-6">
                      <input type="checkbox" className="mr-4" />I herby agree that my personal data may be processed for the needs of
                      automated individual decision making.
                    </label>
                  </div>
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    I Agree
                  </button>
                </div>
                <div className="dataManagementCol w-[42%] border px-5 py-5 rounded-lg mb-5 mr-auto">
                  <h4 className="font-semibold text-[#2E4765] text-base">Deactivate account</h4>
                  <p className="text-sm text-[#454545] py-5 leading-6">
                    With this option you can deactive your account.You can reactivate it anytime you want by just
                    logging in your account.
                  </p>
                  <button onClick={()=>{onDelete()}} className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    Deactivate my account
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataManagement;
