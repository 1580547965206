import { createSlice , createAsyncThunk } from "@reduxjs/toolkit"
import { getPaymentDetailsAsync } from "./paymentThunk"

const initialState = {
    subscriptionUrl : "",
    pricingPlan : null,
    comboPlan : null,
}


export const getPaymentDetails = createAsyncThunk('payment/getPaymentDetails' , getPaymentDetailsAsync)


const paymentSlice = createSlice({
    name : 'payment',
    initialState,
    reducers : {
        updatePaymentDetails : (state, action) => {
            state.subscriptionUrl = action.payload.subscriptionUrl
            state.pricingPlan = action.payload.pricingPlan
            state.comboPlan = action.payload.comboPlan
        }
    },
    extraReducers : (builder) => {
        builder.addCase(getPaymentDetails.fulfilled , (state , action) => {

            console.log(action.payload , "PAYLOAD IN PAYMENT SLICE")
            state.subscriptionUrl = action.payload.subscriptionUrl
            state.pricingPlan = action.payload.pricingPlan
            state.comboPlan = action.payload.comboPlan
        })
    }
})



export const { updatePaymentDetails} = paymentSlice.actions 

export default paymentSlice.reducer