import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import HomePage from "../pages/Chatboat/home";
import Analytics from "../pages/Analytics/Index";
import ChatBoatTemplate from "../pages/ChatbotTemplates/Index";
import PreviewTemp from "../pages/ChatbotTemplates/PreviewTemp";
import Editor from "../pages/Chatboat/Editor/Editor";
import SideMenu from "../Component/sidemenu";
import Login from "../pages/Chatboat/Login";
import Builder from "../pages/createNewBot/Builder";
import VerifyIntegration from "./../../src/Component/integration/VerifyIntegration";
// import Appointment from "../Component/editor-types/Appointment";
// "./src/Component/integration/VerifyIntegration.jsx";
import Appointment from "../pages/Appointment/Appointment";
import Home from "../pages/Home";
import { useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import Settings from "../pages/Settings/Settings";
import { getPaymentDetails } from "../features/payment/paymentSlice";
import { useDispatch } from "react-redux";
import GlobalLoader from "../Component/loaders/GlobalLoader";
import { startLoader } from "../utilities/chatbotMethods";
import SubscribeNow from "../pages/payment/SubscribeNow";
import SubscribeSuccess from "../pages/payment/SubscribeSuccess";
import SubscribeFail from "../pages/payment/SubscribeFail";

const AppRoute = () => {


    const dispatch = useDispatch();
    useEffect(() => {
        // startLoader("route")
        if (window.location.pathname === "/login" || window.location.pathname === "/builder") {
         
        }else if (!localStorage.getItem("tablealias")){
            window.location.href=window.location.origin+"/login";
        }
        dispatch(getPaymentDetails())

    },[])
  return (

    
    <>

      <div className="w-full min-h-screen bg-bodyBG">
        <NotificationContainer />
        {/* <Popup /> */}
        <BrowserRouter>
        <GlobalLoader/>
        <SideMenu />
         
          <Routes>
         
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cx/Chatbot" element={<HomePage />} />
            <Route path="/cx/Chatbot/:type/:botPage" element={<Editor />} />
            <Route path="/cx/analytics" element={<Analytics />} />
            <Route path="/cx/chatBoatTemplate" element={<ChatBoatTemplate />} />
            <Route path="/cx/appointment/:page" element={<Appointment />} />
            <Route path="/cx/settings/:page" element={<Settings />} />
            
            <Route path="/verify" element={<VerifyIntegration />} />
            <Route path="/builder" element={<Builder />} />
            <Route path="/subscribe" element={<SubscribeNow />} />
            <Route path="/subscribe/success" element={<SubscribeSuccess />} />
            <Route path="/subscribe/fail" element={<SubscribeFail />} />
            <Route path="/previewTemp" element={<PreviewTemp />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default AppRoute;
