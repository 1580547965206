import React from 'react'
import { useState } from 'react'


//@COMPONENT - Search
//@PROPS - {src --> url/path of the icon , onChangeHandler --> handler function for input field onChange event}

function Search({src , onChangeHandler,searchValue}) {

  return (
    <div className="relative w-calc-search bg-white drop-shadow-sm rounded-xl">
            <img
              className="cursor-pointer absolute top-[18px] left-[10px] w-5"
            //   src={require("../../img/search-icon.png")}
            src = {src}
            />
            <input
              className="rounded-xl block w-full outline-none pl-10 p-4 font-light text-[#2E4765]"
              type="text"
              placeholder="Search by name"
              value={searchValue}
              onChange={(event) => onChangeHandler(event.target.value)}
            />
          </div>
  )
}

export default Search