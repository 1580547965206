import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateinstalledIntegrations } from "../../features/integraion/integrationSlice";
import {
  getconnectURL,
  installZohoDesk,
  uninstallZohoDesk,
  fetchZHDropdown,
  getConnectHandoff
} from "./../../../src/utilities/chatbotMethods";

function ZohoIntegration(props) {
    const [isLoading, setIsLoading] = useState(true);
  const { installedIntegrations } = useSelector((state) => state.integration);
//   const [isLogin, setIsLogin] = useState(false);
//   const [isInstalled, setIsInstalled] = useState(false);
//   const [toDisconnect, setToDisconnect] = useState(false);
  const [zhOrgArr, setzhOrgArr] = useState([]);
  const [zhDeptArr, setzhDeptArr] = useState([]);
    const [zhOrg, setzhOrg] = useState("");
    const [zhDept, setzhDept] = useState("");
    const [zhOrgName, setzhOrgName] = useState("");
    const [zhDeptName, setzhDeptName] = useState("");
  const [mode, setMode] = useState(props.mode);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const [connectId, setConnectId] = useState("");
  const [nodeId, setNodeId] = useState("");
  const [error, setError] = useState(false);
  const [zhAssignArr, setzhAssignArr] = useState([]);
  const [zhAssign, setzhAssign] = useState("");
    const [zhAssignName, setzhAssignName] = useState("");
    const [zhSubject, setzhSubject] = useState("");
    const [zhStatus, setzhStatus] = useState("");
    const [zhPriority, setzhPriority] = useState("");
    const [zhClassification, setzhClassification] = useState("");
    const [zhChannel, setzhChannel] = useState("");
    const [zhShowAdv, setzhShowAdv] = useState(false);
    const zHStatusArr=[{"disp_val":"Open"},{"disp_val":"On Hold"},{"disp_val":"Escalated"},{"disp_val":"Closed"}]
    const zHPriorityArr=[{"disp_val":"None"},{"disp_val":"High"},{"disp_val":"Medium"},{"disp_val":"Low"}]
    const zHClassificationArr=[{"disp_val":"None"},{"disp_val":"Question"},{"disp_val":"Problem"},{"disp_val":"Feature"},{"disp_val":"Others"}]
    const zHChannelArr=[{"disp_val":"Chatbot"},{"disp_val":"Website"}]

  const dispatch = useDispatch();
  const triggerMSLogin = (data) => {};
  useEffect(() => {
    // setIsLoading(false);
    console.log(props, "props in zendesk");
    localStorage.setItem("connectType", "Message");
    localStorage.setItem("connectApp", "zoho_desk");



    if (props.mode == "edit") {

        getConnectHandoff("zoho_desk").then((res) => {
            if(res){
                setConnectId(res.connectId);
                setNodeId(res.actionNode);
                setzhDept(res.teams&&res.teams.dept?res.teams.dept:"");
                setzhDeptName(res.teams&&res.teams.deptName?res.teams.deptName:"");
                setzhOrg(res.teams&&res.teams.org?res.teams.org:"");
                setzhOrgName(res.teams&&res.teams.orgName?res.teams.orgName:"");
                setzhAssign(res.teams&&res.teams.assign?res.teams.assign:"");
                setzhAssignName(res.teams&&res.teams.assignName?res.teams.assignName:"");
                setzhSubject(res.teams&&res.teams.subject?res.teams.subject:"");
                setzhStatus(res.teams&&res.teams.status?res.teams.status:"");
                setzhPriority(res.teams&&res.teams.priority?res.teams.priority:"");
                setzhClassification(res.teams&&res.teams.classification?res.teams.classification:"");
                setzhChannel(res.teams&&res.teams.channel?res.teams.channel:"");



                // setMsTeam(res.teams&&res.teams.team?res.teams.team:"");
                // setMsTeamName(res.teams&&res.teams.teamName?res.teams.teamName:"");
                // setMsChannel(res.teams&&res.teams.channel?res.teams.channel:"");
                // setMsChannelName(res.teams&&res.teams.channelName?res.teams.channelName:"");
                

                fetchZHDropdown(res.connectId,res.actionNode,"getZDOrganizations").then((list) => {
                    // setIsInstalled(true);
                    setzhOrgArr(list);
                    console.log(list)

                    if(res.teams&&res.teams.org){
                        fetchZHDropdown(res.connectId,res.actionNode,"getZDAssignees",res.teams.org).then((list) => {
                            setzhAssignArr(list);
                        })
                        fetchZHDropdown(res.connectId,res.actionNode,"getZDDepartments",res.teams.org).then((list) => {
                           setzhDeptArr(list);
                            setIsLoading(false);
                        })
                        
                    }else{
                        setIsLoading(false);
                    }
                  });

            }else{
                setIsLoading(false);
                setMode("install")
            }
        })

     
    } else {
      setIsLoading(false);
    }

   
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsBtnLoading(true);
    setError(false)
    if(zhOrg&&zhDept){
    var data = {};
    // var body = {org,orgName,dept,deptName,assign,assignName,subject,priority,status,classification,channel}
    var body={
        "org":zhOrg,
        "orgName":zhOrgName,
        "dept":zhDept,
        "deptName":zhDeptName,
        "assign":zhAssign,
        "assignName":zhAssignName,
        "subject":zhSubject,
        "priority":zhPriority,
        "status":zhStatus,
        "classification":zhClassification,
        "channel":zhChannel
    }
    data["teams"] = body;

    installZohoDesk(data).then((status) => {
        if (status) {
            dispatch(
            updateinstalledIntegrations(["zoho_desk", ...installedIntegrations])
            );
            setIsBtnLoading(false);
            props.closePopup();
        }
    })
    }else{
        setError(true)
        setIsBtnLoading(false);
    }

    
    //   if (status) {
    //     dispatch(updateinstalledIntegrations(["ms", ...installedIntegrations]));
    //     setIsLoading(false);
    //     props.closePopup();
    //   }
    // });
  };

    const handleOrgChange = (event) => {
        setzhOrg(event.target.value.split('(')[1].split(')')[0]);
        setzhOrgName(event.target.value.split("(")[0]);
        setzhDept("");
        fetchZHDropdown(connectId ,nodeId,"getZDDepartments",event.target.value.split('(')[1].split(')')[0] ).then((list) => {
            setzhDeptArr(list);
        });
        fetchZHDropdown(connectId ,nodeId,"getZDAssignees",event.target.value.split('(')[1].split(')')[0] ).then((list) => {
            setzhAssignArr(list);
        })
    };
    const handleDeptChange = (event) => {
        setzhDept(event.target.value.split('(')[1].split(')')[0]);
        setzhDeptName(event.target.value.split("(")[0]);
    };
    const handleAssignChange = (event) => {
        setzhAssign(event.target.value.split('(')[1].split(')')[0]);
        setzhAssignName(event.target.value.split("(")[0]);
    };
    const handleSubjectChange = (event) => {
        setzhSubject(event.target.value);
    };
    const handleStatusChange = (event) => {
        setzhStatus(event.target.value);
    };
    const handlePriorityChange = (event) => {
        setzhPriority(event.target.value);
    };
    const handleClassificationChange = (event) => {
        setzhClassification(event.target.value);
    };
    const handleChannelChange = (event) => {
        setzhChannel(event.target.value);
    };
    

//   const handleTeamChange = (event) => {
//     setMsTeam(event.target.value.split('(')[1].split(')')[0]);
//     setMsTeamName(event.target.value.split("(")[0]);
//     setMsChannel("");
//     fetchMSDropdown(connectId ,nodeId,event.target.value.split('(')[1].split(')')[0] ).then((list) => {
//       setMsChannelArr(list);
//     });
//   };
//   const handleChannelChange = (event) => {
//     setMsChannel(event.target.value.split('(')[1].split(')')[0]);
//     setMsChannelName(event.target.value.split("(")[0]);
//   };

  const handleLogin = () => {
    setIsBtnLoading(true);
    getconnectURL(props.appData.appId, props.appData.integrationUrl).then(
      (url) => {
        setIsBtnLoading(false);
        var wind = window.open(
          `${url}`,
          "popUpWindow",
          "height=500,width=700,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
        );

        // Add an event listener to listen for messages from the popup
        window.addEventListener("message", handleMessage);

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("message", handleMessage);
        };
      }
    );
  };

  const handleMessage = (event) => {
    // Ensure the message is from an expected origin to avoid security risks

    console.log(event, "event", event.data);
    if (event.origin == window.location.origin) {
      
      const receivedData = event.data;
      if(receivedData&&receivedData.connectId){
      setConnectId(receivedData.connectId);
      setNodeId(receivedData.actionNode);
      setIsLoading(true);
    
      fetchZHDropdown(receivedData.connectId,receivedData.actionNode,"getZDOrganizations").then((list) => {
        setzhOrgArr(list);
        setMode("edit")
        setIsBtnLoading(false);
        setIsLoading(false);
        console.log(list)
        })
    }

      
    }
  };

  const handleUninstall = () => {
    setIsBtnLoading(true);
    uninstallZohoDesk().then((status) => {
      if (status) {
        dispatch(
          updateinstalledIntegrations(
            installedIntegrations.filter((item) => item !== "zoho_desk")
          )
        );
        setIsBtnLoading(false);
        props.closePopup();
      }
    });
  };
    return (
        <>
          {/* ADD LOADER SPINNER ON CLICK OF INSTALL UNINSTALL */}
    
          {isLoading ? (
            <p>Loading...</p>
          ) : mode == "uninstall" ? (
            <>
              <span>
                {" "}
                Are you sure you want to uninstall {props.appData.title} ?
              </span>
              <br />
              {isBtnLoading ? (
                <span>...</span>
              ) : (
                <button
                  onClick={() => {
                    handleUninstall();
                  }}
                >
                  {" "}
                  Yes,uninstall
                </button>
              )}
              <br />
              <button
                onClick={() => {
                  props.closePopup();
                }}
              >
                No
              </button>
            </>
          ) : mode == "edit" ? (
            <div className="zendeskContainer">
              <div className="zendeskHeader">
                <span className="zendeskHeaderImage">
                  <img src="/dist/img/public/img/croseBgWhite.svg" alt="close" />
                </span>
              </div>
              <div className="zendeskBody pt-4 pb-4">
                <div className="integration-form-heading mb-3">
                  <div className="integrationLeftImage">
                    <img src="https://mediaconnect.appypie.com/media/icons/128x128/microsoft_teams.png" />
                  </div>
                  <div className="integrationReftDetails as">
                    <div className="title">
                      <h4>Zoho Desk</h4>
                    </div>
                    <div className="description">
                      <p className="mb-1">
                     {props.appData.description}
                      </p>
                    </div>
                    <div className="link">
                      <span className="learnMore">Learn more:</span>{props.appData.connect_desc}
                    </div>
                    <div />
                    <div />
                  </div>
                </div>
                <div>
                  <div>
                    <form
                      autoComplete="off"
                      onSubmit={
                        handleSubmit
                      }
                    >
                      <div>
                        <div className="row">
                          <div className="col-md-12 md-3">
                            <label
                              htmlFor="defaultFormRegisterNameEx"
                              className="grey-text"
                            >
                              Select Integration *
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 mb-3">
                            <select className="form-control" required>
                              <option name="option" value= {props.appData.connect_action}>
                                {props.appData.connect_action}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Organisation
                          </label>
                          <select
                            className="form-control"
                            required
                            disabled={!zhOrgArr.length}
                            onChange={(event) =>
                              handleOrgChange(event)
                            }
                          >
                            <option name="option" value="">
                              Please select
                            </option>
    
                            {zhOrgArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val.split("(")[0]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Department
                          </label>
                          <select
                            className="form-control"
                            required
                            disabled={!zhDeptArr.length}
                            onChange={(event) => {
                              handleDeptChange(event);
                            }}
                          >
                            <option name="option" value="">
                              Please select
                            </option>
                            {zhDeptArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val.split("(")[0]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <span onClick={()=>{setzhShowAdv(!zhShowAdv)}}> {zhShowAdv? "Hide Advanced":"Show Advanced"}</span>
                      {zhShowAdv&&(<>
                        <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Assignee
                          </label>
                          <select
                            className="form-control"
                            
                            disabled={!zhAssignArr.length}
                            onChange={(event) =>
                              handleAssignChange(event)
                            }
                          >
                            <option name="option" value="">
                              Please select
                            </option>
    
                            {zhAssignArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val.split("(")[0]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Enter Subject
                          </label>
                          <input type="text" value={zhSubject} onChange={(event) => setzhSubject(event.target.value)}  placeHolder='Enter Subject' />

                        </div>
                      </div> <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Priority
                          </label>
                          <select
                            className="form-control"
                            
                            
                            onChange={(event) =>
                              handlePriorityChange(event)
                            }
                          >
                            <option name="option" value="">
                              Please select
                            </option>
    
                            {zHPriorityArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Status
                          </label>
                          <select
                            className="form-control"
                            
                           
                            onChange={(event) => {
                              handleStatusChange(event);
                            }}
                          >
                            <option name="option" value="">
                              Please select
                            </option>
                            {zHStatusArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div> <div className="row">
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Classification
                          </label>
                          <select
                            className="form-control"
                          
                           
                            onChange={(event) =>
                              handleClassificationChange(event)
                            }
                          >
                            <option name="option" value="">
                              Please select
                            </option>
    
                            {zHClassificationArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-sm-6 mb-3">
                          <label
                            htmlFor="defaultFormRegisterNameEx"
                            className="grey-text"
                          >
                            Select Channel
                          </label>
                          <select
                            className="form-control"
                           
                           
                            onChange={(event) => {
                              handleChannelChange(event);
                            }}
                          >
                            <option name="option" value="">
                              Please select
                            </option>
                            {zHChannelArr.map((optn, index) => (
                              <option
                                // key={1}
                                name="option"
                                value={optn.disp_val}
                              >
                                {optn.disp_val.split("(")[0]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      </>)}
                      <div className="row">
                        <div className="col">
                          {isBtnLoading ? (
                            <span>...</span>
                          ) : (
                            
                            <input type="submit" value="Connect" />
                          )
                          
                        }
                            {error&&<p>{"Select all fields"}</p>}
    
                          <span
                            onClick={() => {
                              props.closePopup();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Cancel
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div>
                  <img src={props.appData.img} />
                </div>
                <div>
                  <div>
                    <h4>{props.appData.title}</h4>
                  </div>
                  <div>
                    <p>
                     {props.appData.connect_desc}
                    </p>
                  </div>
                  <div>
                    <span>Learn more:</span>
                  </div>
                  <div />
                  <div />
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <label>Login with your zoho desk account</label>
    
                    {isBtnLoading ? (
                      <span>...</span>
                    ) : (
                      <button onClick={() => handleLogin()}>Sign in with Zoho</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
}

export default ZohoIntegration