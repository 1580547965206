import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNode, moveNodeDown, moveNodeUp } from '../utilities/convertTemplateToFlowChart'
import { updateSelectedNode, updateTemplate } from '../features/botTemplate/botTemplateSlice'
import DropDownList from './DropDownList'

function TestButton(props) {


//getting template from the global state
//const {template}  = useSelector(state => state.botTemplate)

const template = props.template

//getting the flow from the template
const flow = template.template_One


const nodeId = Number(props.nodeId)




const parentNode = Number(flow[nodeId].parentNode)



//checking the branching in current node and its child
const childNode = flow[nodeId].trigger.length > 0 ? null : flow[nodeId].Validation.doValidate ? flow[nodeId].Validation.successful : flow[nodeId].next


// (nodeId !== 0 && childNode && flow[childNode].trigger.length <= 0)

//initializing dispatch
const dispatch = useDispatch()



const onDelete = () => {
    const newTemplate = deleteNode(template,Number(props.nodeId))
    console.log(newTemplate , 'maggi')
    dispatch(updateTemplate(newTemplate))
    dispatch(updateSelectedNode(null))
}


//moves the node up
//dispatching action
const moveUp = () => {

    const newTemplate = moveNodeUp(template,Number(props.nodeId))
    console.log(newTemplate)
    dispatch(updateTemplate(newTemplate))
}

//moves the node down
//dispatching action
const moveDown = () => {
    const newTemplate =  moveNodeDown(template,Number(props.nodeId))
    console.log(newTemplate)
    dispatch(updateTemplate(newTemplate))
}
  return (
    <div>
        {/* {(nodeId !== 0 && parentNode !== 0 && flow[nodeId].trigger.length <= 0 && flow[parentNode].trigger <= 0) && <button style={{backgroundColor : 'green'}} onClick={(event) => {moveUp()}}> <small>up</small> </button>}
        {(nodeId !== 0 && childNode && flow[childNode].trigger.length <= 0) && <button style={{backgroundColor : 'yellow' , marginLeft : '4px'}} onClick={(event) => {moveDown()}}> <small>down</small> </button>}
        { nodeId !== 0 && !(!childNode && flow[nodeId].trigger.length <= 0) && <button style={{backgroundColor : 'red' , color : 'white' , marginLeft : '4px'}} onClick={(event) => {onDelete()}}> <small>delete</small> </button>}
         */}
        {flow[nodeId].UI === 'Welcome Message' || flow[nodeId].UI === 'End Message'  ? <DropDownList onDelete = {onDelete} edit = {true} delete = {false} share = {false} duplicate = {false}/> : <DropDownList onDelete = {onDelete} edit = {true} delete = {true} share = {false} duplicate = {false}/>}
        
    </div>
  )
}

export default TestButton