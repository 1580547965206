import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';


function RequestCallback(props) {



     //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)

     const optionPlaceholderString = selectedNode.data.data.optionPlaceholder ? selectedNode.data.data.optionPlaceholder : 'Skip this question'
     const optionSuggestionString = selectedNode.data.data.optionSuggestion ? selectedNode.data.data.optionSuggestion : 'You can skip this question by clicking on '
     const tipIcon = selectedNode.data.data.tipIcon ? selectedNode.data.data.tipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f'
     const skipIcon = selectedNode.data.data.skipIcon ? selectedNode.data.data.skipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556'
     const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text

     //initial state of rich text editor
    //  const [botMessageText , setBotMessageText] = useState(EditorState.createWithContent(ContentState.createFromText(selectedNode.data.data.text)))
     const [botMessageText , setBotMessageText] = useState(getInitialStateForEditor(selectedNode.data.data.text))
     const [responseText , setResponseText] = useState(getInitialStateForEditor(selectedNode.data.data.responseText))
     const [optionPlaceholder , setOptionPlaceholder] = useState(getInitialStateForEditor(optionPlaceholderString))
     const [optionSuggestion , setOptionSuggestion] = useState(getInitialStateForEditor(optionSuggestionString))
     const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
     const [isOptional , setIsOptional] = useState(selectedNode.data.data.isOptional ? selectedNode.data.data.isOptional : false)
     const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)
     const [isCalendly , setIsCalendly] = useState(selectedNode.data.data.isCalendly)
     const [calendlyLink , setCalendlyLink] = useState(selectedNode.data.data.calendlyLink)
     


 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }


     

     



     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                ...selectedNode.data.data,
                text : getHtmlFromEditorState(botMessageText),
                responseText : getHtmlFromEditorState(responseText),
                isOptional,
                optionPlaceholder : getHtmlFromEditorState(optionPlaceholder),
                optionSuggestion : getHtmlFromEditorState(optionSuggestion),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff),
                skipIcon,
                tipIcon,
                isCalendly,
                calendlyLink
                

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }

  return (
    <>
        <div className='flex px-3 mt-2'>
            
            <div className='mr-3'>
                <input checked = {!isCalendly} onChange = {(event) => setIsCalendly(!isCalendly)} type="radio" id="paymentBtn" name="fav_language" />
                <label for="paymentBtn" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Chatbot Calender</label>
            </div>
            <div>
                <input checked = {isCalendly} onChange = {(event) => setIsCalendly(!isCalendly)} type="radio" id="paymentBtn1" name="fav_language"/>
                <label for="paymentBtn1" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Calendly</label>
            </div>
        </div>
        {isCalendly && <div className='px-3 mt-4'>
            <div className='flex flex-wrap'>
                    <div className='text-[10px] text-[#5C5C5C] w-32'>Calendly Meeting Url</div>
                    <div className='text-[10px] text-[#5C5C5C] w-32'>
                        <a href='https://snappy.desk.appypie.com/kb/article/how-to-get-calendly-meeting-url' className='text-[#0275ff] underline'> How to get Calendly Meeting Url</a>
                    </div>
            </div>
            <input value = {calendlyLink} onChange = {(event) => {setCalendlyLink(event.target.value)}} type="text" class="w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs mt-3" placeholder="Enter link here"></input>
        </div>}
        <div className="mb-2 mt-5">
        <div className='px-3'>
             <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot Message</h6>
            <RichTextEditor editorState = {botMessageText} setEditorState = {setBotMessageText} selectedNode = {selectedNode} />
        </div>
        <div className='px-3 mt-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot response</h6>
            <RichTextEditor editorState = {responseText} setEditorState = {setResponseText} selectedNode = {selectedNode} />
        </div>
        </div>
           
    <div className="editorBtn px-3 py-3">
    <div className="mb-3">
        <input type="checkbox" id="Shorten" checked = {isOptional} onChange = {() => setIsOptional(!isOptional)}/>
        <label for="Shorten" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Optional</label>
    </div>
    {isOptional && <div>
            <p>Option skipping suggestion text</p>
            <RichTextEditor editorState = {optionSuggestion} setEditorState = {setOptionSuggestion} selectedNode = {selectedNode}/>
            <p>Option skipping text placeholder</p>
            <RichTextEditor editorState = {optionPlaceholder} setEditorState = {setOptionPlaceholder} selectedNode = {selectedNode}/>
        </div>}
    <div className="mb-3">
        <input type="checkbox" id="Shorten" checked = {shortHand} onChange = {() => setShortHand(!shortHand)}/>
        <label for="Shorten" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Shorten for Handoffs</label>
    </div>
    {shortHand && <div>
            <p>Text for Handoffs</p>
            <RichTextEditor editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode}/>
        </div>}
      <button onClick={handleSave} className="border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white">Save as draft</button>
      <button onClick={handleCancel} className="group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">Cancel</button>
   </div>
  </>
    )

}

export default RequestCallback;