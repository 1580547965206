export const nodeMenu = [  
  { id: 9, name: 'Add HandOffs' },//done
  { id: 5, name: 'Date & Time', newname:"Appointment" }, //done
  { id: 0, name: 'Bot Message'},//done
  { id: 7, name: 'Button' },//done
  { id: 19, name: 'Cards' },//done
  { id: 11, name: 'Condition' },//skip -- not present in any bot
  { id: 17, name: 'Currency' },//done
  { id: 16, name: 'Date Selector',newname:"Date Picker" },//done
  { id: 13, name: 'File Upload' },//done
  { id: 24, name: 'Follow'},//done
  { id: 23, name: 'Food Court' },//skip -- because this node is present only in food court bot ----> done
  { id: 15, name: 'Gender' },//done
  { id: 20, name: 'Get Address' },//done
  { id: 4, name: 'Country',newname:"Get Country" },//done
  { id: 2, name: 'Capture Email',newname:"Get Email" },//done
  { id: 21, name: 'Get Location' },//done
  { id: 1, name: 'Capture Name',newname:"Get Name" },//done
  { id: 3, name: 'Capture Phone Number',newname:"Get Phone" },//done
  { id: 8, name: 'Bot Question', newname:"Get User Input" },//done
  { id: 14, name: 'Get Webinar Link' },//skip -- becasue this node is only meant for webinar bot ----> done
  { id: 12, name: 'Item List' },//skip -- not present in any bot
  { id: 6, name: 'Keyword Response' },//skip -- not present in any bot
  { id: 10, name: 'NPS Survey' },//done
  { id: 27, name: 'Payment'},//done
  { id: 25, name: 'Request Callback' },//done
  { id: 22, name: 'Send Media',newname:"Send File" },//done
  { id: 18, name: 'Survey' },//done

  // { id: 26, name: 'Followers'}


  
  // { id: 21, name: 'Get User Input (Short)' },
  // { id: 22, name: 'Get User Input (Long)' },
]
//



export const nodeMessages = {
    "botMessages": {
        "BotMessage": {
            "text": "This is bot message",
            "UI": "Bot Message"
        },
        "GetName": {
            "text": "This is name",
            "response": "Hi! It's exciting to see you here and I'm totally pumped to start helping you out!",
            "UI": "Get Name",
            "type": "Name"
        },
        "RequestCallback": {
            "text": "Please select date and time on which you want to schedule the call.",
            "response": "Your callback request has been booked successfully. An email regarding it has been sent to you.",
            "UI": "Request Callback",
            "type": "callback"
        },
        "GetEmail": {
            "text": "This is email",
            "successfull": "Got it! Thanks for entering your email address.",
            "failedAttemptFinal": "Oops! Looks like you entered an invalid email address. Please give me a valid email address.",
            "failedAttemptsText": "Sorry, I didn't understand that",
            "UI": "Get Email",
            "type": "Email"
        },
        "GetPhone": {
            "text": "Please provide me your phone number so that in case we get disconnected we can reach you?",
            "botResponseText": "Got it! Thanks for entering your phone number.",
            "botResponseUnText": "Sorry, I didn't understand that",
            "UI": "Get Phone",
            "type": "Phone",
            "failedAttemptsText":"Oops! Looks like you entered an invalid phone number. Please give me a valid one.",
            "failedAttemptsFinal":"You have exceeded maximum attempts."

        },
        "GetCountry": {
            "text": "Enter your location",
            "response": "Great! Thanks for selecting your country.",
            "UI": "Get Country"     
        },
        "DateAndTime": {
            "text": "Please select date and time on which you wish to schedule the appointment.",
            "responseText": "Your appointment has been booked successfully. An email regarding it has been sent to you.",
            "unsuccess": "We're sorry to inform you that at the moment no slots are available the we'll inform you via email.",
            "UI": "Date & Time",
            "input": "date and time"
        },
        "Keyword": {
            "text": "Enter some text",
            "UI": "Keyword Response"
        },
        "Button": {
            "text": "We have looked up to your account and found that there are bookings already scheduled. Do you want to reschedule or cancel your booking?",
            "UI": "Button",
            "input": "Button"
        },
        "BotQuestion": {
            "text": "Please tell me your query. It'll help the agent respond more accurately.",
            "response": "Great! Thanks for the information",
            "UI": "Bot Question"
        },
        "GetAddress": {
            "text": "Please tell me your query. It'll help the agent respond more accurately.",
            "response": "Great! Thanks for the Address",
            "UI": "Get Address"
        }, "Payment": {
            "text": "Please provide your preferred mode of payment.",
            "response": "Click on the link below to complete the payment and return to this page",
            "UI": "Payment"
        }
    }
}



export const initialDataOfNode = {
    0 : { 
        data: { text: nodeMessages.botMessages.BotMessage.text },
        trigger: [],
        UI: nodeMessages.botMessages.BotMessage.UI,
        continue: true,
        Validation: { type: '', doValidate: false, loop: 0 },
        input: '',
        action: '',
        next: null
      },

    7 : {
        data: {
            isBranching: true,
            optionPlaceholder: 'Skip this question ',
            optionSuggestion: 'You can skip this question by clicking on ',
            selected_type: { label: 'Single Selection', value: 'single' },
            selectionOption: 'Submit selected options',
            text: 'So how can I help you today?'
          },
          trigger: [
            {
              id: 0.1,
              name: 'Button 1',
              next: ''
            },
            {
              id: 0.2,
              name: 'Button 2',
              next: ''
            }
          ],
          UI: 'Button',
          continue: false,
          Validation: { type: '', doValidate: false },
          input: 'Button',
          action: '',
          next: null
    },
    19 : {
        // id: id,
       
        // subid: subid,
        // data: {
          data: {
            isUrl:false,
            isUrll:false,
            
            isbtnorcard:true,
            isBranching: true,
            ishorizontal: true,
            optionPlaceholder: 'Skip this question ',
            optionSuggestion: 'You can skip this question by clicking on ',
            selected_type: { label: 'Single Selection', value: 'single' },
            selectionOption: 'Submit selected options',      
            text: 'Kindly select your options from the cards below.',
            
          },
          trigger : [
            {
              id: 0.1,
              name: 'Button 1',
              name1: 'Button 1',
              next: '',
              imageUrl: ``,
                title: 'Card Title 1',
                description: 'Card description',
                footer_button_text: 'Learn More',
                footer_button_text1: 'Learn More',
                NTI:'',
                bocv:'View More',
                whichOptions:'node',
                whichOptions1:'node',
                msg:'',
                url:'',
                contactnumber:'',
                NTI1:'',
                url1:'',                
                contactnumber1:'',
                btnornot: 'yes',
              imageUrl: `https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13`
            },
            
            {
              id: 0.2,
              name: 'Button 2',
              name1: 'Button 2',
              next: '',
              imageUrl: `https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13`
              ,title: 'Card Title 2',
              description: 'Card description',
              footer_button_text: 'Learn More',
              footer_button_text1: 'Learn More',
              NTI:'',
              bocv:'View More',
              whichOptions:'node',
              whichOptions1:'node',
              msg:'',
              url:'',
              contactnumber:'',
              NTI1:'',
              url1:'',                
              contactnumber1:'',
              btnornot: 'yes',
            imageUrl: `https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13`
            }
          ],
          UI: 'Cards',
          continue: false,
          Validation: { type: '', doValidate: false },
          input: '',
          action: '',
          net: null
        },
      

      2 : {
        // id: id,
        // subid: subid,
        // data: {
          data: {
            text: 'Please provide your email address.',
            successful: 'Thanks for providing your email address.',
            unsuccessful: {
              no_of_email: 0,
              failedAttemptsFinal:
              nodeMessages.botMessages.GetEmail.failedAttemptFinal,
              failedAttemptsText:
                'Oops! Looks like you have entered an invalid email address. Please give me a valid email address.',
              nonBusinessCheck: false,
              nonBusinessEmails: [],
              spamMailCheck: false,
              spamMailText: '',
              
            }
          },
          trigger: [],
          UI: nodeMessages.botMessages.GetEmail.UI,
          continue: false,
          Validation: {
            type: nodeMessages.botMessages.GetEmail.type,
            doValidate: true,
            successful: '',
            unsuccessful: '',
            loop: 0
          },
          input: '',
          action: '',
          next: null
        // }
      },
      3 : {
        // id: id,
        // subid: subid,
        // data: {
          data: {
            text: `Please provide me your phone number. It will enable us to reach you even when you aren't online.`,
            successful: {
              botResponseCheck: true,
              botResponseText: 'Thanks for providing your phone number.',
              countrycode: ''
            },
            unsuccessful: {
              no_of_repeatQuestion:'',
              botResponseUnCheck: true,
              botResponseUnText:
                nodeMessages.botMessages.GetPhone.botResponseUnText,
              failedAttemptsText:nodeMessages.botMessages.GetPhone.failedAttemptsText,
              failedAttemptsFinal:nodeMessages.botMessages.GetPhone.failedAttemptsFinal
            }
          },
          trigger: [],
          UI: nodeMessages.botMessages.GetPhone.UI,
          continue: false,
          Validation: {
            type: nodeMessages.botMessages.GetPhone.type,
            doValidate: true,
            successful: '',
            unsuccessful: '',
            loop: 0
          },
          input: '',
          action: '',
          next: null
        // }
      },

      9 : {
        // id: id,
        // subid: subid,
        // data: {
          data: {
            handoffs: {
              appy_email: {
                id: 0,
                type: 'Email',
                title: 'Appy Email',
                data: {
                  email: localStorage.getItem('email')
                }
              }
            },
            text: 'This is HandOffs'
          },
          trigger: [],
          UI: 'Handoffs',
          continue: true,
          Validation: { type: '', doValidate: false },
          input: '',
          action: 'SaveInquiry',
          next: null
        // }
      },
      5 : {
        data: {
          meetingLink: '',
          text: nodeMessages.botMessages.DateAndTime.text,
          available: {
            proceedClick: true,
            responseText: nodeMessages.botMessages.DateAndTime.responseText
          },
          unavailable: {
            textUn: nodeMessages.botMessages.DateAndTime.unsuccess
          }
        },
        trigger: [],
        UI: nodeMessages.botMessages.DateAndTime.UI,
        continue: false,
        Validation: { type: '', doValidate: false, loop: 0 },
        input: nodeMessages.botMessages.DateAndTime.input,
        action: '',
        next: null
      },

      16 : {
        data: {
          text: 'Please select Date.',
          responseText: 'Thanks for selecting Date.'
        },
        trigger: [],
        UI: 'Date Selector',
        continue: false,
        Validation: {
          type: 'date',
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },

      17 : {
        data: {
          text: 'Please select your currency',
          responseText: 'Thanks for providing your currency.'
        },
        trigger: [],
        UI: 'Currency',
        continue: false,
        Validation: {
          type: 'currency',
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },

      13 : {
        data: {
          placeholderText:
            'Drag and drop or click here to upload your portfolio document.',
          text: 'Please upload your  resume or CV.'
        },
        trigger: [],
        UI: 'File Upload',
        continue: false,
        Validation: { type: 'upload', doValidate: false, loop: 0 },
        input: '',
        action: '',
        next: null
      },
      24 : {
        data: {
          text: 'Please Select your Social Plateforms',
          responseText: 'Thanks for providing your information',
          cardsOptions: [
            {
              // imageUrl: `${require("../../../../assets/img/fb-login.png")}`,
              title: 'Facebook',
              description: 'link',
              footer_button_text: 'Footer Button Text'
            },
            {
              // imageUrl: `${require("../../../../assets/img/icon/Icon-instagram.svg")}`,
              title: 'Instagram',
              description: 'link',
              footer_button_text: 'Footer Button Text'
           },
            {
              // imageUrl: `${require("../../../../assets/img/icon/Icon-twitter.svg")}`,
              title: 'Twitter',
              description: 'link',
              footer_button_text: 'Footer Button Text'
            },
            
          ]
        },
        trigger: [],
        UI: 'Follow us',
        continue: false,
        Validation: {
          type: 'follow us',
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      }, 
      15 : {
        data: {
          text: 'Please select your gender',
          responseText: 'Thank you for letting us know!'
        },
        trigger: [],
        UI: 'Gender',
        continue: false,
        Validation: {
          type: 'gender',
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },
      20 : {
        data: {
          text: 'Please enter your Street address',
          responseText: nodeMessages.botMessages.GetAddress.response,
          Address:[
            {
              field_name:"Street address",
              isSelected:true,
              isMandatory:true,
              isExpanded:true,
              question:"Please enter your Street address"
            },
            {
              field_name:"Street address 2",
              isSelected:true,
              isMandatory:false,
              isExpanded:true,
              question:"Please enter your Street address 2"
            },
            {
              field_name:"Country",
              isSelected:true,
              isMandatory:true,
              isExpanded:false,
              question:"Please select your country"
            },
            {
              field_name:"State",
              isSelected:true,
              isMandatory:true,
              isExpanded:false,
              question:"Please select your state"
            },
            {
              field_name:"City",
              isSelected:true,
              isMandatory:true,
              isExpanded:false,
              question:"Please enter your City"
            },
            {
              field_name:"Postal code",
              isSelected:true,
              isMandatory:true,
              isExpanded:false,
              question:"Please enter your postal code"
            }
          ],
        },
        trigger: [],
        UI: nodeMessages.botMessages.GetAddress.UI,
        continue: false,
        Validation: {
          type: 'address',
          doValidate: true,
          loop: 0,
          successful: ''
        },
        input: '',
        action: '',
        next: null
      },
      4 : {
        data: {
          text: 'Select your country.',
          responseText: nodeMessages.botMessages.GetCountry.response
        },
        trigger: [],
        UI: nodeMessages.botMessages.GetCountry.UI,
        continue: false,
        Validation: {
          type: '',
          doValidate: true,
          loop: 0,
          successful: ''
        },
        input: '',
        action: '',
        next: null
      },
      1 : {
        data: {
          text: 'Please provide your name',
          responseText: 'Thanks {{Name}} for providing us your name'
        },
        trigger: [],
        UI: nodeMessages.botMessages.GetName.UI,
        continue: false,
        Validation: {
          type: nodeMessages.botMessages.GetName.type,
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },

      8 : {
        data: {
          text: 'Please provide your query.',
          responseText: nodeMessages.botMessages.BotQuestion.response,
          dropDownresponseText: 'Option 1, Option 2, Option 3',
          rtype: { label: 'Text', value: 'text' },
          isOptional: false
        },
        trigger: [],
        UI: nodeMessages.botMessages.BotQuestion.UI,
        continue: false,
        Validation: {
          type: 'question',
          doValidate: true,
          loop: 0,
          successful: ''
        },
        input: '',
        action: '',
        next: null
      },

      10 : {
        data: {
          feedback_type: {
            presentation: { label: '1 to 5 scale', value: '1-5' },
            type: { label: 'Scale', value: 'rating-scale' }
          },
          isEnable: true,
          responseText: 'Thank you for your feedback.',
          text: 'Please provide your feedback.'
        },
        trigger: [],
        UI: 'Feedback',
        continue: false,
        Validation: { doValidate: false, type: '', loop: 0 },
        input: '',
        action: 'feedback',
        next: null
      },
      21 : {
        data: {
          text: 'Please select your location',
          isUrl: false,
          distance: null,
          fare: null,
          dist: null,
          currency:null,
          responseText: 'Thanks for providing us your location',
          api_key:''
        },
        trigger: [],
        UI: "Get Location",
        continue: false,
        Validation: {
          type: 'location',
          doValidate: false,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },

      27 : {
        data: {
          text: 'To make your purchase click on the below button.',
          responseText: 'Your order is successful',
          isurl: false,
          cardsOptions: [
            {
              imageUrl: '',
              title: '',
              title1:'Amount $75 onlydfvdfb',
              description: 'Card description 1',
              footer_button_text: 'Buy Now',
              footer_button_text1: 'Buy Now',
              publicable_key: '',
              secret_key: '',
              payment_url: '',
              payment_url1: ''
            },
          
          ]
        },
        trigger: [],
        UI: 'Payment',
        continue: false,
        Validation: {
          type: 'payment',
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },
      25 : {
        data: {
          text: nodeMessages.botMessages.RequestCallback.text,
          responseText: nodeMessages.botMessages.RequestCallback.response,
          isCalendly:false,
          calendlyLink:""
        },
        trigger: [],
        UI: nodeMessages.botMessages.RequestCallback.UI,
        continue: false,
        Validation: {
          type: nodeMessages.botMessages.RequestCallback.type,
          doValidate: true,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },

      22 : {
        data: {
          text: 'Upload the media file here.',
          isUrl:false,
          url:"",
        },
        trigger: [],
        UI: 'Send Media',
        continue: false,
        Validation: {
          type: 'survey',
          doValidate: false,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },
      18 : {
        data: {
          feedback_type: {
            presentation: { label: '1 to 5 scale', value: '1-5' },
            type: { label: 'Scale', value: 'rating-scale' }
          },
          text: 'How would you rate our services?'
        },
        trigger: [],
        UI: 'Survey',
        continue: false,
        Validation: {
          type: 'survey',
          doValidate: false,
          successful: '',
          loop: 0
       },
        input: '',
        action: '',
        next: null
      },

      23 : {
        data: {
          text: 'Please select app id for food court.',
          appName:'Select',
          app_details: {
            appId:"",
            appAdminName:"",
            appName:"Select",
            foodcourtStatus:true
          }
        },
        trigger: [],
        UI: 'Food Court',
        continue: false,
        Validation: {
          type: 'survey',
          doValidate: false,
          successful: '',
          loop: 0
        },
        input: '',
        action: '',
        next: null
      },

      14 : {
        data: {
          text: 'Please provide the link to the webinar*.'
        },
        trigger: [],
        UI: 'Get Webinar Link',
        continue: false,
        Validation: { type: 'upload', doValidate: false, loop: 0 },
        input: '',
        action: '',
        next: null
      },

      12 : {
        data: {
          uploadFile: true,
          text:
            "Please enter the item's name and quantity which you wish to order. In case you have a list prepared then you can upload it."
        },
        trigger: [],
        UI: 'Item List',
        continue: false,
        Validation: { type: 'item', doValidate: false, loop: 0 },
        input: '',
        action: '',
        next: null
      },

      6 : {
        data: { text: nodeMessages.botMessages.Keyword.text },
        trigger: [],
        UI: nodeMessages.botMessages.Keyword.UI,
        continue: false,
        Validation: { type: '', doValidate: false, loop: 0 },
        input: '',
        action: '',
        next: null
      },

      11 : {
        data: { text: 'Get Condition' },
        trigger: [
          {
            id: 0.1,
            name: 'True',
            next: ''
          },
          {
            id: 0.2,
            name: 'False',
            next: ''
          }
        ],
        UI: 'Condition',
        continue: false,
        Validation: { type: '', doValidate: false },
        input: 'Button',
        action: '',
        next: null
      },

    100 : {
        UI: 'End Message',
        Validation: { doValidate: false, type: '' },
        action: 'SaveInquiry',
        continue: false,
        data: { text: 'Great! Thanks for the information.' },
        input: '',
        next: null,
        trigger: []   
      }
}




// {
//   data: { text: payload.data.data.responseText },
//   trigger: payload.data.trigger,
//   next: tempNext,
//   continue: true,
//   UI: 'Bot Message',
//   Validation: { type: '', doValidate: false, loop: 0 },
//   input: payload.data.input,
//   action: payload.data.action
// }


//2371



// //modifying parent node -- changing the next link
//     //@CASES - source can have branches / source can have validate / source has only next


//     let parentNode = null
//     let successfulNodeOfParent = null
//     let unsuccessfulNodeOfParent = null
//     let successfulNodeOfParentId = null
//     let unsuccessfulNodeOfParentId = null
//     //@CASE 1 - source have branches
//     if(flow[source].trigger.length > 0){

//       initialData = {
//         ...initialData,
//         parentNode : source
//       }

//       parentNode = {
//         ...flow[source],
//         trigger : flow[source].trigger.map((item) => {
//           if(Number(item.next) === target ){
//             return {
//               ...item,
//               next : listOfIdAvailable[0]
//             }
//           }
//           else{
//             return item
//           }
//         })
//       }

      
//   }


//   //@CASE 2 - source has validations
//     else if(flow[source].Validation.doValidate){

//       initialData = {
//         ...initialData,
//         parentNode : Number(flow[source].Validation.successful)
//       }

//       // parentNode = {
//       //   ...flow[source],
//       //   Validation : {
//       //     ...flow[source].Validation,
//       //     successful : listOfIdAvailable[0]
//       //   }
//       // }

//       successfulNodeOfParentId = Number(flow[source].Validation.successful)

//       //modifying the successful node of parent
//       successfulNodeOfParent = {
//         ...flow[successfulNodeOfParentId],
//         next : listOfIdAvailable[0]
//       }

//       unsuccessfulNodeOfParentId = Number(flow[source].Validation.unsuccessful)

//       console.log(unsuccessfulNodeOfParentId , ' maggi')

//       //modifying unsuccessful node of parent
//       unsuccessfulNodeOfParent =  flow[source].Validation.unsuccessful ? {
//         ...flow[unsuccessfulNodeOfParentId],
//         Validation : {
//           ...flow[unsuccessfulNodeOfParentId].Validation,
//           successful : listOfIdAvailable[0]
//         }
//       } : null
//     }


//     //@CASE 3 - source has only next
//     else{


//       initialData = {
//         ...initialData,
//         parentNode : source
//       }

//       //modifying parent node
//       parentNode = {
//         ...flow[source],
//         next : listOfIdAvailable[0]
//         }
//       }

//     console.log(parentNode , ' parent')//for debugging purpose 


    // //creating new flow
    // let newFlow = null
    // if(parentNode){
    //   newFlow = {
    //     ...flow,
    //     [listOfIdAvailable[0]] : initialData,
    //     [listOfIdAvailable[1]] : successfulNode,
    //     [listOfIdAvailable[2]] : unsuccessfulNode,
    //     [source] : parentNode,
    //     [target] : childNode
    //   }
    // }
    // else if(successfulNodeOfParent && unsuccessfulNodeOfParent){
    //   newFlow = {
    //     ...flow,
    //     [listOfIdAvailable[0]] : initialData,
    //     [listOfIdAvailable[1]] : successfulNode,
    //     [listOfIdAvailable[2]] : unsuccessfulNode,
    //     // [source] : parentNode,
    //     [successfulNodeOfParentId] : successfulNodeOfParent,
    //     [unsuccessfulNodeOfParentId] : unsuccessfulNodeOfParent,
    //     [target] : childNode
    //   }
    // }
    // else{
    //   newFlow = {
    //     ...flow,
    //     [listOfIdAvailable[0]] : initialData,
    //     [listOfIdAvailable[1]] : successfulNode,
    //     [listOfIdAvailable[2]] : unsuccessfulNode,
    //     // [source] : parentNode,
    //     [successfulNodeOfParentId] : successfulNodeOfParent,
    //     [target] : childNode
    //   }
    // }
