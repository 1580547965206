import React, { useState, useEffect } from "react";
// import { updateWorkingHours } from "../../../../config/chatbotmethods";
// import firebase from "../../../../config/firebase";
import { useSelector, useDispatch } from "react-redux";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
// import PreventTransitionPrompt from "../../../components/PreventTransitionPrompt";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { saveApptWorkHours } from "../../utilities/chatbotMethods";
// import { setUserDetails } from "../../../actions";

var timeFormat = "24";

const WorkingHours = () => {
  const dispatch = useDispatch();
//   const agentData = useSelector((state) => state.chatbot.agentData);
  const {agentData} = useSelector((state) => state.agentData);
  const [weeks, setWeeks] = useState([]);
  const [format, setFormat] = useState("");
  const [timePeriod, setTimePeriod] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [notSave, setNotSave] = useState(false);
  const [Week_days, setWeekDays] = useState([]);
  const [isChanges, setIsChanges] = useState(false);

  useEffect(() => {
    if(agentData){
    setIsLoading(true);
    console.log(agentData, "agentData")
    const states = agentData.appointment;
    const appointemts = agentData.appointment;

    var workingEvents = appointemts.workingHours;
    var config = appointemts.configuration;
    var days = 30;
    switch (config.schedule.split(" ")[1]) {
      case "Month":
        days = config.schedule.split(" ")[0] * 30;
        break;
      case "Week":
        days = config.schedule.split(" ")[0] * 7;
        break;
      case "Day":
      case "day":
        days = config.schedule.split(" ")[0] * 1;
        break;
    }
    timeFormat = config.timeFormat;
    var format = config.timeFormat === "12" ? "hh:mm a" : "HH:mm";
    var weeks = workingEvents.workingHours;

    if (workingEvents.workingHours !== undefined) {
      var Week_days = [];
      for (let index = 0; index < weeks.length; index++) {
        const element = weeks[index].day;
        Week_days.push(element);
      }
      setWeeks(weeks);
      setFormat(format);
      setTimePeriod(days);
      setWeekDays(Week_days);
      setIsLoading(false);
    }
    }
  }, [agentData]);

  const onChange = (value, day, index, fromorto) => {
    setIsChanges(true);
    console.log(value, "value", day, "day", index, "index", fromorto, "fromorto")
    if (value != null) {
      var weeksCopy =JSON.parse(JSON.stringify(weeks));
      weeksCopy.forEach((element) => {
        if (element.day === day.day) {
          for (let i = 0; i < element.timeSlots.length; i++) {
            if (i == index) {
                if (fromorto == "from") {
                    var isValid = true;
                    element.timeSlots.forEach((slot, j) => {
                        if (j != index) {
                            if (
                                slot.from <= value.format("HH:mm") &&
                                slot.to >= value.format("HH:mm")
                            ) {
                                isValid = false;
                            }
                        }
                    });
                    if (isValid) {
                        //console.log(element.timeSlots[i].isSet_from)
                        if (element.timeSlots[i].isSet_to) {
                            console.log(element.timeSlots[i], "element.timeSlots[i]");
                            if (value.format("HH:mm") < element.timeSlots[i].to) {
                                var isValid = true;
                                element.timeSlots.forEach((slot, j) => {
                                    if (j != index) {
                                        if (
                                            slot.from >= value.format("HH:mm") &&
                                            slot.to <= element.timeSlots[i].to
                                        ) {
                                            isValid = false;
                                        }
                                    }
                                });
                                if (isValid) {
                                    element.timeSlots[i].from = value.format("HH:mm");
                                    setWeeks(weeksCopy);
                                    element.timeSlots[i].isSet_from = true;
                                } else {
                                    // console.log("slot overlaps with already booked ones");
                                    NotificationManager.error(
                                        "",
                                        "Slot overlaps with already booked ones",
                                        1200
                                    );
                                    element.timeSlots[i].from = element.timeSlots[i].from;
                                    // this.setState({ key: !this.state.key });
                                }
                                // element.timeSlots[i].from = value.format("HH:mm");
                                // element.timeSlots[i].isSet_from=true;
                            } else {
                                // console.log("event start time cant be later than finish time");
                                NotificationManager.error(
                                    "",
                                    "Event start time cant be later than finish time",
                                    1200
                                );
                                element.timeSlots[i].from = element.timeSlots[i].from;
                                // this.setState({ key: !this.state.key });
                            }
                        } else {
                            // if(value.format("HH:mm")<element.timeSlots[i].to){
                            element.timeSlots[i].from = value.format("HH:mm");
                            element.timeSlots[i].isSet_from = true;
                            setWeeks(weeksCopy);
                            // }else{
                            // 	console.log('event start time cant be later than finish time');
                            // }
                        }
                    } else {
                        // console.log("this slot is already booked");
                        NotificationManager.error(
                            "",
                            "This slot is already booked",
                            1200
                        );
                        element.timeSlots[i].from = element.timeSlots[i].from;
                        // this.setState({ key: !this.state.key });
                    }
                } else {
                    //to case this
                    var isValid = true;
                    element.timeSlots.forEach((slot, j) => {
                        if (j != index) {
                            if (
                                slot.from <= value.format("HH:mm") &&
                                slot.to >= value.format("HH:mm")
                            ) {
                                console.log(slot.from, "slot.from", slot.to, "slot.to");
                                isValid = false;
                            }
                        }
                    });
                    if (isValid) {
                        if (element.timeSlots[i].isSet_from) {
                            if (value.format("HH:mm") > element.timeSlots[i].from) {
                                var isValid = true;
                                element.timeSlots.forEach((slot, j) => {
                                    if (j != index) {
                                        if (
                                            slot.to <= value.format("HH:mm") &&
                                            slot.from >= element.timeSlots[i].from
                                        ) {
                                            isValid = false;
                                        }
                                    }
                                });
                                if (isValid) {
                                    element.timeSlots[i].to = value.format("HH:mm");
                                    element.timeSlots[i].isSet_to = true;
                                    setWeeks(weeksCopy);
                                } else {
                                    // console.log("slot overlaps with already booked ones");
                                    NotificationManager.error(
                                        "",
                                        "Slot overlaps with already booked ones",
                                        1000
                                    );
                                    // this.setState({ key: !this.state.key });
                                }

                                // element.timeSlots[i].to = value.format("HH:mm");
                                // element.timeSlots[i].isSet_to=true;
                            } else {
                                // console.log("finish time cant be earlier than start time");
                                NotificationManager.error(
                                    "",
                                    "Finish time cant be earlier than start time",
                                    1000
                                );
                                // this.setState({ key: !this.state.key });
                            }
                        } else {
                            // if(value.format("HH:mm")>element.timeSlots[i].from){
                            element.timeSlots[i].to = value.format("HH:mm");
                            element.timeSlots[i].isSet_to = true;
                            setWeeks(weeksCopy);
                            // }else{
                            // 	console.log('finish time cant be earlier than start time');
                            // }
                        }
                    } else {
                        // console.log("the time slot is already booked");
                        NotificationManager.error(
                            "",
                            "The time slot is already booked",
                            1000
                        );
                        // this.setState({ key: !this.state.key });
                    }
                }
            }
          }
        }
      });
      setShouldBlockNavigation(true);
    } else {
      setNotSave(true);
      setShouldBlockNavigation(true);
    }
  };

  const handleAdd = async (day) => {
    var weeksCopy = JSON.parse(JSON.stringify(weeks));
    console.log(weeksCopy,"HANDLEADDJSON",weeks)
    weeksCopy.forEach((element) => {
      if (element.day === day.day) {
        if (element.timeSlots.length === 0) {
          element.timeSlots.push({
            from: "select",
            to: "select",
            isSet_from: true,
            isSet_to: true,
            delete_button: true,
          });
        } else {
          element.timeSlots.push({
            from: "select",
            to: "select",
            isSet_from: false,
            isSet_to: false,
            delete_button: true,
          });
        }
      }
    });
    setTimeout(() => {
      setWeeks(weeksCopy);
      setShouldBlockNavigation(true);
    }, 10);
  };

  const handleDelete = (day, times) => {
    var weeksCopy = JSON.parse(JSON.stringify(weeks));
    var newArr = [];
    weeksCopy.forEach((element) => {
      if (element.day === day.day) {
        for (let index = 0; index < element.timeSlots.length; index++) {
          if (index !== times) {
            newArr.push(element.timeSlots[index]);
          }
        }
        element.timeSlots = newArr;
      }
    });
    setWeeks(weeksCopy);
    setShouldBlockNavigation(true);
  };

  const handleEnable = (day, e) => {
    var weeksCopy =JSON.parse(JSON.stringify(weeks));
    weeksCopy.forEach((element) => {
      if (element.day === day.day) {
        element.enable = e.target.checked;
      }
    });
    setWeeks(weeksCopy);
    setShouldBlockNavigation(true);
  };

  const tConvert = (time) => {
    if(time){
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
    }else {return time}
  };

  const saveAppointmentSlots = async (events) => {
    var e = agentData.appointment.configuration;
    var k = events;
    var buffer = parseInt(e.buffer.split(" ")[0]) * 60 * 1000;
    var slotTime = parseInt(e.duration.split(" ")[0]) * 60 * 1000;
    var slots = {};
    var total_time1 = buffer + slotTime;
    if (k && e) {
      k.map((e, j) => {
        var start_time = new Date(e.start).getTime();
        var end_time = new Date(e.end).getTime();
        var total_time = end_time - start_time;
        var num_slots = Math.floor(total_time / (buffer + slotTime));
        var left_time = total_time % (buffer + slotTime);
        for (var i = 0; i < num_slots; i++) {
          slots[parseInt((start_time + i * total_time1) / 1000)] = {
            start: new Date(start_time + i * total_time1),
            end: new Date(start_time + i * total_time1 + slotTime),
            status: "free_slot",
          };
        }
        if (left_time >= slotTime) {
          slots[parseInt((start_time + num_slots * total_time1) / 1000)] = {
            start: new Date(start_time + num_slots * total_time1),
            end: new Date(start_time + num_slots * total_time1 + slotTime),
            status: "free_slot",
          };
        }
      });
      var counter = 0;
      var pagesSlots = {};
      var All_slots = [];
      for (const slot in slots) {
        if (slots.hasOwnProperty(slot)) {
          const s = slots[slot];
          pagesSlots[slot] = s;
          counter++;
        }
        if (counter > 450) {
          var dataTosend = Object.keys(pagesSlots).map((key) => pagesSlots[key]);
          All_slots.push(dataTosend);
          pagesSlots = {};
          counter = 0;
        }
      }
      if (counter > 0) {
        var dataTosend = Object.keys(pagesSlots).map((key) => pagesSlots[key]);
        All_slots.push(dataTosend);
      }
      var finalAll_slots = All_slots.flat();
      return finalAll_slots;
    }
  };

  const handleSave = async () => {

    //   var e = agentData.appointment.configuration;
    //   var e1 = agentData.appointment.workingHours;
      var k = weeks;
    //   var obj = {
    //     is_work_hours: true,
    //     workingHours: {
    //       workingHours: k,
    //     },
    //     // configuration: e,
    //     // metaData: agentData.appointment.metaData,
    //   };

      if (k) {
        var days = k.length;
        var working_days = k.map((element) => {
          return element.enable;
        });
        working_days = working_days.filter(Boolean).length;
        if (working_days >= 1) {
         
            setIsLoading(true);

            saveApptWorkHours(weeks).then((res) => {
                if(res){
                    // setIsLoading(false);
                    // setShouldBlockNavigation(false);
                    NotificationManager.success(
                        "Working Hours Updated Successfully",
                        "Success",
                        1000
                    );
                    setIsChanges(false);
                }
            })
            // firebase
            //   .firestore()
            //   .collection("users")
            //   .doc(agentData.uid)
            //   .collection("chatbot")
            //   .doc("appointment")
            //   .update(obj)
            //   .then(() => {
            //     setIsLoading(false);
            //     setShouldBlockNavigation(false);
            //     NotificationManager.success(
            //       "Working Hours Updated Successfully",
            //       "Success",
            //       1000
            //     );
            //     setIsChanges(false);
            //   });
            setIsLoading(false);
          
        } else {
          NotificationManager.error("Please enable one day", "Error", 1000);
        }
      }

    // setIsChanges(true);
  };

  const handleCancel = async () => {
    if (shouldBlockNavigation) {
      setIsLoading(true);
      var elements = agentData.appointment.workingHours.workingHours;
      setWeeks(elements);
      setShouldBlockNavigation(false);
      setIsChanges(false);
      setIsLoading(false);
    }
  };

  return (
    <div className="appointmentRight">
      {/* <PreventTransitionPrompt
        when={shouldBlockNavigation && isChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
      /> */}
      <div className="appointmentTitle">Working Hours</div>
      {isLoading ? (
        <div className="loading-div">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="workingHoursList">
          {Week_days.map((day) => {
            return (
              <div key={day} className="dayWeeks">
                <div className="day-name">
                  <label>{day}</label>
                </div>
                <div className="working-hours-details">
                  {weeks.map((d, index) => {
                    if (d.day === day) {
                      return (
                        <div key={index} className="day-details">
                          <div className="enable_checkbox">
                            <input
                              type="checkbox"
                              id={`enable_${day}`}
                              checked={d.enable}
                              onChange={(e) => handleEnable(d, e)}
                            />
                            <label htmlFor={`enable_${day}`}>Enable</label>
                          </div>
                          {d.enable &&
                            d.timeSlots.map((times, tIndex) => (
                              <div key={tIndex} className="times-div">
                                <TimePicker
                                  onChange={(value) =>
                                    onChange(value, d, tIndex, "from")
                                  }
                                  value={
                                    times.from !== "select"
                                      ? moment(tConvert(times.from), format)
                                      : null
                                  }
                                  format={format}
                                  showSecond={false}
                                />
                                <span className="time-span">to</span>
                                <TimePicker
                                  onChange={(value) =>
                                    onChange(value, d, tIndex, "to")
                                  }
                                  value={
                                    times.to !== "select"
                                      ? moment(tConvert(times.to), format)
                                      : null
                                  }
                                  format={format}
                                  showSecond={false}
                                />
                                {times.delete_button && (
                                  <button
                                    className="delete-btn"
                                    onClick={() => handleDelete(d, tIndex)}
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            ))}
                          <div className="add_button_div">
                            <button
                              className="add-btn"
                              onClick={() => handleAdd(d)}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            );
          })}
          <div className="save-cancel-btns">
            <button className="save-btn" onClick={handleSave}>
              Save
            </button>
            {/* <button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkingHours;
