import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';



function GetAddress(props) {


    //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)

     
     const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text

     //initial state of rich text editor
    
    const [responseText , setResponseText] = useState(getInitialStateForEditor(selectedNode.data.data.responseText))
    const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
    const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)
    const [address , setAddress] = useState([])


 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }


     

     



     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,           
            data : {
                ...selectedNode.data.data,
                Address : address.map((item,index) => {
                    return {
                        ...item,
                        isExpanded : index === 0 ? true :false,
                        question : getHtmlFromEditorState(item.question)
                    }
                }),
                responseText : getHtmlFromEditorState(responseText),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff)
                

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }


     //handler function
     const onEditorStateChange = (newEditorState , indexToBeModified) => {
        const newAddress = address.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    question : newEditorState
                }
            }
            else{
                return item
            }
        })

        setAddress(newAddress)
     }

     //handler function
     const onSelectionChange = (value , indexToBeModified) => {
        const newAddress = address.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    isSelected : value
                }
            }
            else{
                return item
            }
        })

        setAddress(newAddress)
     }

     //handler function
     const onMandatoryChange = (value , indexToBeModified) => {
        const newAddress = address.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    isMandatory : value
                }
            }
            else{
                return item
            }
        })

        setAddress(newAddress)
     }

     //handler function
     const onExpandChange = (indexToBeModified) => {
        const newAddress = address.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    isExpanded : !item.isExpanded
                }
            }
            else{
                return item
            }
        })

        setAddress(newAddress)
     }


     useEffect(() => {

        const addresss = selectedNode.data.data.Address
        const newAddress = addresss.map((item) => {
            return {
                ...item,
                question : getInitialStateForEditor(item.question)
            }
        }
        )
        setAddress(newAddress)
     },[])


  return (
    <>

    {address.length > 0 && address.map((item,index) => {
        return <div key={index} className='editorView  rounded-xl border border-[#D8D8D8] relative transition'>
        <div className="mb-4">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
        <div className="px-0">
            <input type="checkbox" id="address" checked = {item.isSelected} onChange = {(event) => {onSelectionChange(event.target.checked , index)}} />
            <label for="address" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">{item.field_name}</label>
        </div>
        <div onClick={() => onExpandChange(index)} className='absolute right-3 top-2 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
            </svg>
        </div>
        </h5>
        {item.isExpanded && <div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot question text</h6>
            <RichTextEditor editorState = {item.question} setEditorState = {(newEditorState) => {onEditorStateChange(newEditorState, index)}} selectedNode = {selectedNode} />
        </div>}
        </div>
        {item.isExpanded && <label htmlFor={'row.botId' + index} className="flex cursor-pointer justify-end text-[#5C5C5C] pr-3 text-sm mb-3">
            Mandatory
            <div className="relative ml-2"> 
                <input type="checkbox" id={'row.botId' + index} value = {item.isMandatory} onChange = {(event) => {onMandatoryChange(event.target.checked ,index)}} className="sr-only"/>
                <div className={`block w-12 h-6 rounded-full ${item.isMandatory === true ? 'bg-thmBG' : 'bg-[#00000042]'}`}></div>
                <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
        </label>}
    </div>
    })}

<div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot Response text</h6>
            <RichTextEditor editorState = {responseText} setEditorState = {setResponseText} selectedNode = {selectedNode} />
        </div>
    {/* <div className='editorView  rounded-xl border border-[#D8D8D8] relative transition'>
        <div className="mb-4">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
        <div className="px-0">
            <input type="checkbox" id="address" />
            <label for="address" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Street address</label>
        </div>
        <div className='absolute right-3 top-2 cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
            </svg>
        </div>
        </h5>
        <div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot question text</h6>
            <RichTextEditor />
        </div>
        </div>
        <label htmlFor={'row.botId'} className="flex cursor-pointer justify-end text-[#5C5C5C] pr-3 text-sm mb-3">
            Mandatory
            <div className="relative ml-2"> 
                <input type="checkbox" id={'row.botId'} className="sr-only"/>
                <div className={`block w-12 h-6 rounded-full bg-thmBG`}></div>
                <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
        </label>
    </div>

    <div className='editorView  rounded-xl border border-[#D8D8D8] relative transition mt-4'>
        <div className="mb-4">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
            <div className="px-0">
                <input type="checkbox" id="address1" />
                <label for="address1" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Street address</label>
            </div>
            <div className='absolute right-3 top-2 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
                </svg>
            </div>
        </h5>
        <div className='px-3 '>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot question text</h6>
            <RichTextEditor />
        </div>
        </div>
        <label htmlFor={'row.botId1'} className="flex cursor-pointer justify-end text-[#5C5C5C] pr-3 text-sm mb-3">
            Mandatory
            <div className="relative ml-2"> 
                <input type="checkbox" id={'row.botId1'} className="sr-only"/>
                <div className={`block w-12 h-6 rounded-full bg-thmBG`}></div>
                <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
        </label>
    </div> */}

    {/* <div className='editorView  rounded-xl border border-[#D8D8D8] relative transition mt-4'>
        <div className="mb-0">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
            <div className="px-0">
                <input type="checkbox" id="address2" />
                <label for="address2" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Country</label>
            </div>
            <div className='absolute right-3 top-2 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
                </svg>
            </div>
        </h5>
        </div>
    </div>
    <div className='editorView  rounded-xl border border-[#D8D8D8] relative transition mt-4'>
        <div className="mb-0">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
            <div className="px-0">
                <input type="checkbox" id="address2" />
                <label for="address2" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">State</label>
            </div>
            <div className='absolute right-3 top-2 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
                </svg>
            </div>
        </h5>
        </div>
    </div>
    <div className='editorView  rounded-xl border border-[#D8D8D8] relative transition mt-4'>
        <div className="mb-0">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
            <div className="px-0">
                <input type="checkbox" id="address2" />
                <label for="address2" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">City</label>
            </div>
            <div className='absolute right-3 top-2 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
                </svg>
            </div>
        </h5>
        </div>
    </div>
    <div className='editorView  rounded-xl border border-[#D8D8D8] relative transition mt-4'>
        <div className="mb-0">
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
            <div className="px-0">
                <input type="checkbox" id="address2" />
                <label for="address2" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Postal code</label>
            </div>
            <div className='absolute right-3 top-2 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="11.731" height="6.707" viewBox="0 0 11.731 6.707">
                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M15.932,12.059,11.493,7.624a.835.835,0,0,1,0-1.184.845.845,0,0,1,1.187,0l5.029,5.026a.837.837,0,0,1,.024,1.156l-5.05,5.061A.838.838,0,0,1,11.5,16.5Z" transform="translate(17.927 -11.246) rotate(90)" fill="#c3c3c3"/>
                </svg>
            </div>
        </h5>
        </div>
    </div> */}
    
    <div className="editorBtn px-3 py-3">
    <div className="mb-3">
        <input type="checkbox" id="Shorten" value={shortHand} onChange = {(event) => setShortHand(event.target.checked)}/>
        <label for="Shorten" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Shorten for Handoffs</label>
    </div>
    {shortHand && <div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot Response text</h6>
            <RichTextEditor editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode} />
        </div>}
     
   </div>
   <div className='px-3 py-3'>
   <button onClick={handleSave} className="border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white">Save as draft</button>
      <button onClick={handleCancel} className="group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">Cancel</button>
   </div>
  </>
    )

}

export default GetAddress;

// 'bg-thmBG' : 'bg-[#00000042]'