import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'


function Appointment () {

  return (
    <>
    <div className='px-3 mt-2'>
        <p className='text-sm text-[#5C5C5C]'>Meeting link:</p> 
        <div className='border text-sm p-2 mt-3 bg-white'>AGENT1625123112616</div>
        <div className='border-b-[2px] text-sm mt-3 bg-white flex justify-between cursor-pointer'>
            <div className='text-[#6D85EB] border-b-[2px] p-2 border-[#6D85EB]'>Slots available</div>
            <div className='text-[#5C5C5C] p-2'>Slots unavailable</div>
        </div>
    </div>
        <div className="mb-2 mt-5">
        <div className='px-3'>
            <RichTextEditor />
        </div>
        <div className='px-3 mt-5'>
            <p className='text-sm text-[#5C5C5C] mb-3'>Bot message on successful scheduling</p>
            <RichTextEditor />
        </div>
        </div>
           
    <div className="editorBtn px-3 py-3">
      <button className="border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white">Save as draft</button>
      <button className="group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">Cancel</button>
   </div>
  </>
    )

}

export default Appointment;