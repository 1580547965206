import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import Select from 'react-select';
import { CurrencyList } from '../../data/currency';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';
// import Select from 'react-select'

function GetLocation(props) {
    const selectStatusOption = [
        { label: 'S$ (US Dollar)cale', value: 'rating-scale' },
        { label: '$ (US Dollar)', value: 'rating-stars' },
        { label: '$ (US Dollar)', value: 'rating-smile' }
      ]
    //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)

     const optionPlaceholderString = selectedNode.data.data.optionPlaceholder ? selectedNode.data.data.optionPlaceholder : 'Skip this question'
     const optionSuggestionString = selectedNode.data.data.optionSuggestion ? selectedNode.data.data.optionSuggestion : 'You can skip this question by clicking on '
     const tipIcon = selectedNode.data.data.tipIcon ? selectedNode.data.data.tipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f'
     const skipIcon = selectedNode.data.data.skipIcon ? selectedNode.data.data.skipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556'
     const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text

     //initial state of rich text editor
    const [botMessageText , setBotMessageText] = useState(getInitialStateForEditor(selectedNode.data.data.text))
    const [responseText , setResponseText] = useState(getInitialStateForEditor(selectedNode.data.data.responseText))
    const [optionPlaceholder , setOptionPlaceholder] = useState(getInitialStateForEditor(optionPlaceholderString))
    const [optionSuggestion , setOptionSuggestion] = useState(getInitialStateForEditor(optionSuggestionString))
    const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
    const [isOptional , setIsOptional] = useState(selectedNode.data.data.isOptional ? selectedNode.data.data.isOptional : false)
    const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)
    const [isDual , setIsDual] = useState(selectedNode.data.data.isDual ? selectedNode.data.data.isDual : false)
    const [apiKey , setApiKey] = useState(selectedNode.data.data.api_key)
    const [distance , setDistance] = useState(selectedNode.data.data.distance ? selectedNode.data.data.distance : false)
    const [fare , setFare] = useState(selectedNode.data.data.fare ? selectedNode.data.data.fare : false)
    const [currencyOptions , setCurrencyOptions] = useState([])
    const [currency , setCurrency] = useState(selectedNode.data.data.currency ? selectedNode.data.data.currency : null )
    const [pricePerKm , setPricePerKm] = useState(selectedNode.data.data.pricePerKm ? selectedNode.data.data.pricePerKm : 0)
    
    //currency: this.props.data.currency == undefined ?`${CurrencyList[0].symbol} (${CurrencyList[0].name})`: this.props.data.currency,

    useEffect(() => {
        const newCurrencyOptions = CurrencyList.map((item) => {
            return {
                ...item,
                value : `${item.symbol} (${item.name})`,
                label : `${item.symbol} (${item.name})`
            }
        })
        setCurrencyOptions(newCurrencyOptions)

        if(!currency){
            setCurrency(`${newCurrencyOptions.symbol} (${newCurrencyOptions.name})`)
        }
    },[CurrencyList])
    


 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }


     

     



     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                ...selectedNode.data.data,
                text : getHtmlFromEditorState(botMessageText),
                isDual,
                api_key : apiKey,
                distance,
                fare,
                currency,
                pricePerKm,
                responseText : getHtmlFromEditorState(responseText),
                isOptional,
                optionPlaceholder : getHtmlFromEditorState(optionPlaceholder),
                optionSuggestion : getHtmlFromEditorState(optionSuggestion),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff),
                skipIcon,
                tipIcon,
                

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))
        dispatch(setAllowSave(true))

        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }

  return (
    <>
    <div className='flex px-3 mt-3'>
            <div className='mr-3'>
                <input type="radio" id="paymentBtn" name="fav_language" checked = {!isDual} onChange = {() => setIsDual(false)}/>
                <label for="paymentBtn" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Single Location</label>
            </div>
            <div>
                <input type="radio" id="paymentBtn1" name="fav_language" checked = {isDual} onChange = {() => setIsDual(true)}/>
                <label for="paymentBtn1" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Dual Location</label>
            </div>
    </div>
    <div className='px-3 my-4'>
        <RichTextEditor editorState = {botMessageText} setEditorState = {setBotMessageText} selectedNode = {selectedNode} />
    </div>
    <div className='px-3 my-4 text-[#5C5C5C] text-sm'>
        <span>Google Maps API Key</span>
        <a className='block text-[#3D80F6] mt-1' href='#'>How to get Google Maps API Key</a>
        <div className='mt-3'>
        <input type="text" class="w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Enter API KEY' value={apiKey} onChange = {(event) => setApiKey(event.target.value)} />
        </div>
    </div>
    {isDual && <><div class="flex items-center px-3 pb-3">
        <input type="checkbox" id="aa" value={distance}  onChange = {(event) => setDistance(event.target.checked)} />
        <label class="text-[14px] pl-2 cursor-pointer " for="aa">Calculate distance & time between the two locations</label>
    </div>
    <div class="flex items-center px-3 pb-3">
        <input type="checkbox" id="bb" value={fare}  onChange = {(event) => setFare(event.target.checked)} />
        <label class="text-[14px] pl-2 cursor-pointer " for="bb">Calculate the Fare</label>
    </div>
    <div className='flex items-center mb-2 px-3 gap-2 text-sm'>
        <p className='mr-auto text-[14px]'>Select Currency </p>
        <Select options = {currencyOptions} value = {currency} onChange = {(selectedOption) => setCurrency(selectedOption)} />
        <input className='w-10 h-9 border px-2' type='number' placeholder='0' value={pricePerKm} onChange = {(event) => setPricePerKm(event.target.value)} /> /KM
    </div></>}
    <div className='px-3 my-4'>
        <RichTextEditor editorState = {responseText} setEditorState = {setResponseText} selectedNode = {selectedNode} />
    </div>
    <div class="flex items-center px-3 pb-3">
        <input type="checkbox" id="aaa" value={shortHand} onChange = {(event) => setShortHand(event.target.checked)}/>
        <label class="text-[14px] pl-2 cursor-pointer " for="aaa">Shorten for Handoffs</label>
    </div>
    {shortHand && <div>
            <p>Text for Handoffs</p>
            <RichTextEditor editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode}/>
        </div>}
    <div className='editorBtn px-3 py-3'>
        <button onClick={handleSave} className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button onClick={handleCancel} className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
    </div>
    </>
  )
}

export default GetLocation