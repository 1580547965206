import React from "react";
import { useEffect } from "react";

function AppIntegration(props) {
  useEffect(() => {
    console.log(props, "PRPS in app integration");
  }, []);

  return (
    <div className="w-[48%] conectionIntegrationBox flex border rounded-2xl shadow-md border-[#E6EDF5] px-5 py-5">
      <span className="flex items-center justify-center bg-[#F5F9FC] border border-[#E6EDF5] rounded-xl mr-4 w-20 h-20">
        <img src={props.img} />
      </span>
      <div className="w-8/12 conectionBoxAside">
        <h4 className="text-[#5C5C5C]  font-medium text-base">{props.title}</h4>
        <p className="text-[#343434] text-sm pb-2">{props.description}</p>
        {props.allowInstall && (
          <button
            onClick={() => {
              console.log(props.isInstalled, "is installed");
              props.openPopup(
                props.subCatData,
                props.isInstalled ? "uninstall" : "install"
              );
            }}
            className="flex items-center border border-indigo-600 text-sm px-3 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white "
          >
            {props.isInstalled
              ? "Uninstall"
              : props.id == "appy_email"
              ? "Edit"
              : "Install"}
          </button>
        )}
        {console.log(props.title,props.isConnectApp, "is connect app", props.isMultipleAllowed, "is multiple allowed", props.isInstalled, "is installed")}
        {(props.isConnectApp || props.isMultipleAllowed) &&
          props.isInstalled && (
            <button
              onClick={() => {
                console.log(props.isInstalled, "is installed");
                props.openPopup(props.subCatData, "edit");
              }}
              className="flex items-center border border-indigo-600 text-sm px-3 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white "
            >
              {"Edit"}
            </button>
          )}
        {props.isInstalled && (
          <p className="text-[#343434] text-sm pb-2">Installed</p>
        )}
      </div>
    </div>
  );
}

export default AppIntegration;
