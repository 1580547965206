import React from "react";
import { Link } from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import SideMenu from "../../Component/sidemenu";
import Topheader from "../../Component/TopHeader";

const ChatBoatTemplate = () => {
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      items: 4,
    },
  };

  return (
    <>
      {/* <SideMenu /> */}
      <div className="pl-[76px] lg:pl-[276px] p-5">
        <Topheader cT="Chatbot Templates" />
        <div className="aliceCoursolRow py-5">
          <div className="alicCoursolDetails flex items-center content-center mb-5">
            <h4 className="text-[#2E4765] font-semibold text-lg">Browse by Booking Bot</h4>
            <div className="search ml-auto"></div>
            <Link className="text-[#3188FF] underline text-sm">View More</Link>
          </div>
          <div className="coursolRow ">
            <AliceCarousel responsive={responsive} disableDotsControls>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Religion Education Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Automatically answer questions about various religious, their customs, and beliefs with an
                    intelligent chatbot
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Donation for education Bot</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Raise money from a large number of people online to cover any education-related cause with an
                    education donation chatbot created with Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Education Survey Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden ">
                    Find out the education level of your students, employees, or volunteers with highly functional
                    education level survey bot and form created using Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Religion Education Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Automatically answer questions about various religious, their customs, and beliefs with an
                    intelligent chatbot
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Donation for education Bot</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Raise money from a large number of people online to cover any education-related cause with an
                    education donation chatbot created with Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Education Survey Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden ">
                    Find out the education level of your students, employees, or volunteers with highly functional
                    education level survey bot and form created using Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
            </AliceCarousel>
          </div>
        </div>

        <div className="aliceCoursolRow py-5">
          <div className="alicCoursolDetails flex items-center content-center mb-5">
            <h4 className="text-[#2E4765] font-semibold text-lg">Browse by Education Bot</h4>
            <div className="search ml-auto"></div>
            <Link className="text-[#3188FF] underline text-sm">View More</Link>
          </div>
          <div className="coursolRow ">
            <AliceCarousel responsive={responsive} disableDotsControls>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Religion Education Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Automatically answer questions about various religious, their customs, and beliefs with an
                    intelligent chatbot
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Donation for education Bot</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Raise money from a large number of people online to cover any education-related cause with an
                    education donation chatbot created with Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Education Survey Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden ">
                    Find out the education level of your students, employees, or volunteers with highly functional
                    education level survey bot and form created using Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Religion Education Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Automatically answer questions about various religious, their customs, and beliefs with an
                    intelligent chatbot
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Donation for education Bot</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden">
                    Raise money from a large number of people online to cover any education-related cause with an
                    education donation chatbot created with Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
              <div className="aliceCoursol w-11/12">
                <div className="aliceCoursolInner bg-white p-5 rounded-lg relative group/item hover:before:bg-[#5269C9] before:w-full before:h-full before:bg-[#5269C9] hover:before:absolute before:inset-0 before:rounded-lg before:opacity-90 z-0 hover:z-10">
                  <div className="mediaContentHeadng flex items-center mb-3">
                    <span className="flex items-center content-center w-16 bg-[#F5F6FA] mr-3 rounded-md">
                      <img src={require("../../img/cbt.png")} className="m-auto" />
                    </span>
                    <h4 className="text-[#2E4765]">Education Survey Bot & Form</h4>
                  </div>
                  <p className="text-xs leading-5 text-[#454545] font-normal h-16 text-clip overflow-hidden ">
                    Find out the education level of your students, employees, or volunteers with highly functional
                    education level survey bot and form created using Appy Pie’s No-code Chatbot builder.
                  </p>
                  <Link to="/previewTemp">
                    <button className="border border-white text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 invisible group-hover/item:visible absolute inset-0 w-24 h-10 bg-white">
                      Preview
                    </button>
                  </Link>
                </div>
                <div className="text-center  my-3">
                  <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white">
                    Use this template
                  </button>
                </div>
              </div>
            </AliceCarousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBoatTemplate;
