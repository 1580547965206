import React, { Component } from "react";
import logo1 from "./chat.png";
import cross from "./cross.png";
import one from "./smile1.svg";
import two from "./smile2.svg";
import reload from "./reload.svg";
import three from "./smile3.svg";
import four from "./smile4.svg";
import five from "./smile5.svg";
import right from "./right.svg";
import left from "./left.svg";
import male from "./male.svg";
import female from "./female.svg";
import other from "./other.svg";
import checkphonelength from "./countrynumber.json";
import checkphoneprefix from "./phoneprefix.json";
import user from "./boy.png";
import phoneCode from "./country.json";
import { DialcodeList } from "./country";
import StarRatings from "react-star-ratings";
// import sendbtn from "../src/assets/images/send2.svg";
import sendbtn from "./../assets/images/send2.svg"; 
import { CurrencyList } from "./Currency";
import loader from "./boucingloader.gif";
// import db from "../config/firebase";
import { db } from "./../config/firebase";
import * as EmailValidator from "email-validator";
import * as firebase1 from "firebase";
import { Picker } from "emoji-mart";
import Device from "react-device";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css';
import sdb from "../config/firebasestorage";
import parse from "html-react-parser";
import Notifications1 from "./Notifications";
import { connect } from "react-redux";
import { CountryList } from "./CountryList";
// import { element } from "prop-types";
import { NotificationManager} from 'react-notifications'
import calpng from "./calendar-interface-symbol-tool.png";
// const publicIp = require("public-ip");
import  publicIp  from "public-ip";
// import moment from "moment-timezone";
// import { Directions, PlaylistAddOutlined } from "@material-ui/icons";

// import Swiper from "../src/plugin/swiper/dist/js/swiper";
// import "../src/plugin/swiper/dist/css/swiper.css";
// import { Button } from "@material-ui/core";

// import Followswiper from "../src/modules/chatbot/view/components/followswiper";
var axios = require("axios");
<script src="https://js.stripe.com/v3/"></script>;

// const publicIp = require("public-ip");
var storageRef = sdb.ref();
var Gpthistory = [];
var web_url =
  process.env.NODE_ENV === "production"
    ? "https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"
    : "https://us-central1-appycx-beta.cloudfunctions.net";


const increment = firebase1.firestore.FieldValue.increment(1);
const add_1 = firebase1.firestore.FieldValue.increment(1);
const add_2 = firebase1.firestore.FieldValue.increment(2);
const add_3 = firebase1.firestore.FieldValue.increment(3);
const add_4 = firebase1.firestore.FieldValue.increment(4);
const add_5 = firebase1.firestore.FieldValue.increment(5);

var google = window.google;
var s_view = false;
var d = new Date(3000);
var serverTimestamp = firebase1.firestore.FieldValue.serverTimestamp();
var nextcId = 0;
var geoLocation = {
  country_name: "No_country",
  country_ip: "No_ip",
  country_code: "No_code",
};
var skipnext = false;
var steps = {};
var iframe;
var anas;
var scrollPosition = 0;
var lastFeedback = false;
var dialCode = "+91";
var slots = [];
var dateSlots= [];
var bookedTimeSlots = [];
var timeSlotFormat = "24";
var multstr = [];
var typingInterval = "";
var appointment_agent_ids_list = [];
var dropDownList = [];
var skipindex = false;
var d = new Date();
var timeDifference = d.getTimezoneOffset() * 60000;
var last_page_url = window.location.href;
var answerbutton = 0;
var invokebutton = 0;
var skipText=false;
var bookedAppointmentTime = "";
var LastButtonText = "Start bot flow again";
var prevcId = "0";
var firstSearch = false;
var TimeGap = 0;
class Botpreview extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.image_addedon = "https://chatbot.appypie.com/";
    this.state = {
      isOptional: false,
      isPaid: false,
      isOptionalButton: false,
      showDropDown: false,
      showSurvey: { enable: false, type: "rating-scale", value: "1-5" },
      agentId: localStorage.getItem("agent_id"),
      currencyText: "₹ (Indian Rupee)", //₪", "name": "Israeli New Sheqel
      currencyPicker: false,
      phonecodeText: "+91 (India)",
      UserCountry: "",
      showCountryList: false,
      Amo: "",
      dropDownText: "",
      countryCode: "",
      stateName: "",
      showFileError: false,
      uploadErrorMessage: "",
      uploadingFile: false,
      showFileUpload: false,
      horizontalButtons: "true",
      stopDropdown: false,
      phoneInput: false,
      showError: "none",
      finalList: [],
      uploadFileButton: false,
      itemordered: false,
      showList: false,
      uploadFilelist: true,
      initiall:'',
      isDual:false,
      finall:'',
      singlelocation:'',
      uploadType: "",
      uploadMultipleList: [],
      sendListButton: true,
      listInput: [],
      itemsHere: [{}],
      mainScrollHeight: "calc(100% - 120px)",
      buttonBottomSpace: 5,
      feedbackText: "",
      feedbackResponseText: "",
      locationErrMsg:"",
      toggleInput: false,
      List_allMsg: [],
      interval: true,
      sendImage: false,
      sendImageUrl: "",
      shouldSetVisitorActive: false,
      shouldShowNotification: false,
      acceptExts: ".txt,.xlsx,.xlsb,.csv,.tsv,.doc,.docx,.pdf,.xls",
      n_msg: "NO_MESSAGE",
      update_count: "",
      formData_Live_id: "",
      incoming11: 1,
      chat_status: "NEW_CHAT",
      singlelocationinput: '',
      All_Message_List: [],
      chatLanguage: "hi",
      google_translater_key: "",
      isDefaultLanguage: true,
      chooseLanguage: false,
      showMenu: false,
      userdistance: null,
      userfair: null,
      isUpdatingBatch: false,
      isActive: document.hasFocus(),
      isSendingFile: false,
      updateRead_Ids: [],
      btns: [],
      exitFlow: false,
      ptext:
        "I agree for my personal data, provided via chat, to be processed by <your company name> for the purpose of providing support via chat. I agree for my personal data to be processed for the time <e.g. needed to carry out the service>. I understand the consent may be revoked by sending an email at <your business email/your data protection officers email>. \n",
      files: [],
      takeUserPermission: true,
      isChatEnd: false,
      isSlotLoading: true,
      timeSlots: [],
      distance: null,
      duration: null,
      fare: null,
      perkilometreprice: null,
      googlemapapi:'',
      botType: "1",
      botName: "Snappy",
      buttonType: "",
      dateSelected: false,
      timeZone:"",
      visTimeZone:"",
      Input: null,
      isTyping: false,
      isChatInDraft: true,
      showListing: false,
      isOldchat: false,
      chatList: [],
      gMultiple: {},
      oldChatList: [],
      renderList: [],
      msgGet: "",
      OS: "",
      optionalPlaceholder: "Type a message",
      skipIcon:
        "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556",
      newAppointment: false,
      UA: "",
      myBrowser: "",
      isSession_saved: false,
      showNewChatButton: false,
      myIcon: this.image_addedon + logo1,
      chat_id: "",
      showHideEmoji: false,
      botId: "",
      PageURL: window.location.href,
      timeSpent: 50,
      num_unread: 0,
      client__id: "",
      country: "India",
      Agents: [],
      AllRules: {},
      isAgent: true,
      formData: [],
      showFeedback: "hidden",
      a_showFeedback: "hidden",
      openWindow: false,
      showText: "visible",
      isfeedbackgiven: false,
      canSend: false,
      backup_id: "",
      dualorsingle: null,
      isNewChat: false,
      agentData: {},
      tempEmail: "dd@test.com",
      companyID: "txmgnr",
      IPv4: "",
      IPv6: "",
      latestId: "",
      inputText: "",
      processing: false,
      showHide: "cHide",
      agentPicUpdated: false,
      listID: [],
      t: Date.now() + timeDifference,
      header: "Header from state...",
      content: "Content from state...",
      text: "",
      text1: "",
      list: [],
      list_translated: [],
      rList: [],
      isBot: false,
      whichloc: null,
      cId: "00",
      startDate: new Date(),
      Email: "noreply@appypie.com",
      Phone: "1234567890",
      Name: "New Visitor",
      startTime: "time",
      isLoading: true,
      numReschedule: 0,
      show: false,
      chatIcon: this.image_addedon + logo1,
      isFirst: true,
      chatRef: "",
      isLivechat: false,
      visibility: "hidden",
      showFeedbackMessage: false,
      isGeoGet: false,
      isDevice: false,
      showfooter: "inline-block",
      appointmentmailsent: false,
      Address: "",
      AddressFixed: "",
      AddressVariable: "",
      addressArr: [],
      addressArrInd: undefined,
      addressSkip: false,
      AddressDropdown: false,
      addressCountryArr: [],
      addressStateArr: [],
      addressField: "",
      uploadCount: 0,
      showGoogleMaps: false,
      clickonduallocation: null,
      errorcode:null,
      isDual: false,
      foodAppId: "",
      googleMapsKey: "",
      faree: null,
      gMapLat: "",
      gMapLong: "",
      baseHotel: "",
      baseDish: "",
      pickToppings: false,
      toppingIndex: 0,
      toppingList: [],
      toppingsText: "",
      toppingPrice: 0,
      foodCart: {},
      dishName: "",
      cartButton: [],
      maxTop: 1,
      searchAllowed: false,
      foodEmpty: false,
      foodKeyList: {},
      foodPaymentObj: {},
      foodOrderId: "AP" + (new Date().getTime() + "").slice(2),
      foodpayUrl: "",
      foodCurrency: "",
      foodSubTotal: 0.0,
      foodDiscountCost: 0.0,
      foodDiscountVendorCost: 0.0,
      foodDiscountRuleCost: 0.0,
      foodCouponDiscount: 0.0,
      couponText: "",
      foodDeliveryCharge: 0.0,
      foodTax: {},
      foodTaxCost: 0.0,
      foodMiscTax: {},
      foodMiscTaxCost: 0.0,
      topPriceAll: 0.0,
      foodTip: 0.0,
      foodCentActive: "",
      foodTotal: 0.0,
      foodDistance: 0.0,
      foodDistanceunit: "km",
      foodInstructions: "",
      sendHandoff: false, //Revert on june 3
      popup: false,
      customisation: {
        HeaderIcon:
          "https://www.appypie.com/wp-content/themes/appypie2016/images/logo_new.png",
        addeon: "blank",
        headerClr:
          "linear-gradient(135deg,rgb(35, 146, 236) 0%,rgb(13, 92, 156) 100%)",
        clientBubbleClr: "#286efa",
        botTextcolor: "#000",
        chatIcon: "chat",
        clientchattextcolor: "#ffffff",
        headerName: "Appypie",
        headertextcolor: "#ffffff",
        isBackgroundTransparent: false,
        isHeader: "flex",
        widgetImage: this.image_addedon + logo1,
        messagebubble: "#dde6ef",
        sendbuttoncolor: "#007bff",
        widgetbackgroundcolor: "rgba(100,200,255,0.0)",
        fontSize: 13,
        fontStyle: "Nunito",
        chatBotAvtar:
          "https://firebasestorage.googleapis.com/v0/b/chatbotbuilder-ca3b3.appspot.com/o/boy.png?alt=media&token=9b613f92-659f-49da-9072-7bf44279260f",
      },
    };

    publicIp.v4().then((ip4) => {
      // this.state.ip = ip4;
      fetch("https://chatbottest.appypie.com/getAdress?ip=" + ip4)
        .then((res) => res.json())
        .then((result) => {
          if (
            result.CountryName != undefined &&
            result.CountryName != "undefined"
          ) {
            var index = phoneCode.findIndex(function (item, i) {
              return item.code === result.CountryCode;
            });
            this.state.countryCode = result.CountryCode;
            dialCode = phoneCode[index].dial_code;
            this.state.CountryName = result.CountryName;
            this.state.stateName = result.subdivisions;

            this.state.currencyText = `${result.symbol} (${result.name})`;
            this.state.phonecodeText = `${phoneCode[index].dial_code} (${result.CountryName})`;
            this.state.visTimeZone=result.timezone
            this.setState({})
            this.fetchTimeSlots();
          
            console.log("this.state.visTimeZone",this.state.visTimeZone,'timezone')

          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
    this.frameMessages = this.frameMessages.bind(this);
    this.openIfClosed = this.openIfClosed.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.setLanguage111 = this.setLanguage111.bind(this);
    this.userAgree = this.userAgree.bind(this);
    this.getFile = this.getFile.bind(this);

    this.startNewAppointment = this.startNewAppointment.bind(this);
    this.a_feedback = this.a_feedback.bind(this);
    this.appointmentRoster = this.appointmentRoster.bind(this);
    this.dateShifter = this.dateShifter.bind(this);
    this.backTocalendar = this.backTocalendar.bind(this);
    this.handleDateSelection = this.handleDateSelection.bind(this);
    this.chooseDate_time = this.chooseDate_time.bind(this);
    this.listOldChat = this.listOldChat.bind(this);
    this.onChangee = this.onChangee.bind(this);
    this.saveFormData = this.saveFormData.bind(this);
    this.startNewChat = this.startNewChat.bind(this);
    this.afterclient = this.afterclient.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputChange1 = this.onInputChange1.bind(this);
    this.fetchTimeSlots = this.fetchTimeSlots.bind(this);
    this.onSendbtnPress = this.onSendbtnPress.bind(this);
    this.onSendButtonPress = this.onSendButtonPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.endChat = this.endChat.bind(this);
    this.thumbUp = this.thumbUp.bind(this);
    this.thumbDown = this.thumbDown.bind(this);
    this.doneOrder = this.doneOrder.bind(this);
    this.selectSurvey = this.selectSurvey.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.skipQuestion = this.skipQuestion.bind(this);
    this.personalityChange = this.personalityChange.bind(this);

    // var Id = document.getElementById('appyWidget').src;
    // this.image_addedon = Id.split('widget')[0] + 'widget/';
    this.image_addedon = "";
    iframe = document.getElementById("Appypie");
    setInterval(() => {
      if (this.state.isActive == document.hasFocus()) {
      } else {
        this.state.isActive = document.hasFocus();
        if (document.hasFocus() && this.state.show) {
          if (this.state.updateRead_Ids.length > 0) {
            this.windowGetFocus(1);
          }
        }
      }
    }, 1000);

    setInterval((h) => {
      try {
        if (this.state.shouldSetVisitorActive) {
          this.state.chatRef.update({ visitor_last_seen: serverTimestamp });
        }
      } catch (e) {
        //LOG here
      }
    }, 1 * 60 * 1000);
  }

  // stripeCheckout = async () => {
  // 	const res = await fetch(`http://localhost:5000/appycx-beta/us-central1/stripe-stripe/create-checkout-session`, {
  // 	  method: 'POST',
  // 	  headers: {
  // 		"Content-Type": 'application/json'
  // 	  }
  // 	})
  // 	const body = await res.json()
  // 	window.location.href = body.url
  //   }

  stripeCheckout1(num, pub, sec) {

    let data = fetch(
      `https://us-central1-appycx-beta.cloudfunctions.net/stripee` +
        "/checkout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price: num,
          publicable_key: `${pub}`,
          secret_key: sec,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        window.open(data.url, "_blank");
        this.state.text = `Amount $${this.state.Amo}`
          this.onSendButtonPress();
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  stripeCheckout(num, pub, sec) {

    var stripe = stripe(`${pub}`);
    fetch(
      `http://localhost:5000/livechat-commonlogin/us-central1/stripe-stripe/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price: num,
          publicable_key: `${pub}`,
          secret_key: sec,
          quantity: 2,
        }),
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (session) {
        
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function (result) {
        console.log(
          "********************************************************************************RESULTTTTT",
          result
        );
        if (result.error) {
          alert(result.error.message);
        }
      });
    this.state.text = `Please pay ${this.state.Amo}USD`
      this.onSendButtonPress();
  }

  async payment_url(pay_url) {
    const res = await fetch(`${pay_url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await res.json();
    console.log(data);
  }

  getMetaContentByName(name, content) {
    var content = content == null ? "content" : content;
    return document
      .querySelector("meta[name='" + name + "']")
      .getAttribute(content);
  }

  transferLivechat(email, i) {
    if (this.state.isLivechat && i == 0) {
      return;
    }
  }

  checkUrl(text, source) {
    // var temp = this.state.customisation.clientchattextcolor
    // if (source) { temp = this.state.customisation.botTextcolor }
    text = text.replace("çp>", "").replace("ç/p>", "");
    // text = text.replace(/ç/g, '<')
    text = text.replace("_self", "_blank");
    text = text.replace(/&nbsp;/g, "");

    var textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    text = textarea.value;

    // text = text.replace(/&nbsp/g, "")
    text = this.getProper(text);
    if (text.includes("src")) {
      return text;
    }
    if (text.includes("href")) {
      // text=text.replace("href",`style="color:${temp}" href`)
      if (!text.includes("https"))
        // https:// appypie.com/#
        text = text.replace(`href="`, `href="https://appypie.com/#/`);
      // href ="12345678909://"
      return text;
    }
    var urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return text.replace(urlRegex, function (url) {
      if (!/^https?:\/\//i.test(url)) {
        url = "https://" + url;
      }
      // return '<a target="_blank" style="color: ' + temp + '" href="' + url + '">' + url + '</a>';
      return '<a target="_blank" href="' + url + '">' + url + "</a>";
    });
  }

  //**************************** CREATING CHAT TO START TALKING ***********************************//

  updateAgents(Ref) {
    if (!this.state.isAgent) {
      return;
    }
    this.state.isAgent = false;
  }

  insertchat(cRef, msg1, msg_type) {
    this.focusNEW();
    if (!this.state.isLivechat) {
      return;
    }
    if (this.state.text == "") {
      return;
    }
    this.state.text = "";
    try {
      if (this.state.Agents.length > 0) {
        this.state.Agents.forEach((agent) => {
          var t = {};
          t[agent] = increment;
          cRef.update(t);
        });
      } else {
      }
    } catch (error) {
      console.log("error sending data...");
    }
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  makeVisitor(c) {
    var visitID = new Date().getTime();
    console.log(c);
    if ((this.state.isGeoGet && this.state.isDevice) || c > 5) {
      console.log("making");
      this.transferLivechat("VISITOR" + visitID, 0);
    } else {
      setTimeout(() => {
        this.makeVisitor(c + 1);
      }, 300);
    }
  }

  componentWillMount() {
    // return
    var c = 0;
    console.log("SPSPSPSPS", this.props);
    var bot_id = this.props.botId;
    this.state.botId = bot_id;
    this.state.companyID = localStorage.getItem("tablealias");
    this.state.botName = localStorage.getItem("BOTNAME");
    var chatId = this.getCookie("chatId");

    var uagree = this.getCookie("userAgree");
    if (uagree == "Agree") {
      this.state.takeUserPermission = false;
    } else {
      this.state.takeUserPermission = true;
    }
    this.state.takeUserPermission = false;
    chatId = "";
    if (chatId == "") {
      var newChatId = Date.now();
      document.cookie = "chatId=" + newChatId + ";path=/";
      this.state.backup_id = newChatId;
      this.state.isNewChat = true;
      var visitID = Date.now();
      document.cookie = "visitorId=" + visitID + ";path=/";
      // this.makeVisitor(0);
      setInterval(() => {
        if ((this.state.isGeoGet && this.state.isDevice) || c > 5) {
          if (this.state.interval) {
            this.state.interval = false;
            //console.log('making');
            //this.transferLivechat('VISITOR' + visitID, 0);
          }
        } else {
          c++;
        }
      }, 1000);
    } else {
      this.state.backup_id = chatId;
    }

    this.createForm();
    steps = {...this.props.dataBot};//modified by maggie
    var doc = this.props.dataBot;
	console.log(doc.customisation,this.state.customisation,"CUSTOMISATION BEFORE")
	this.state.customisation =Object.assign({}, this.state.customisation,  this.props.dataBot.customisation)
	this.setState({})
	console.log(this.state.customisation,"CUSTOMISATION AFTER")
    this.state.chatIcon = doc.customisation.widgetImage;
    this.state.myIcon = doc.customisation.widgetImage;
    for (const key in steps) {
      if (Object.hasOwnProperty.call(steps, key)) {
        const element = steps[key];
        // if (element['UI'] == 'Handoffs') {
        // 	if (element.data.handoffs["zendesk chat"]) {
        // 		this.state.isZendesk = true;
        // 	}
        // 	localStorage.setItem("feedback", key)
        // }
        // if (element['UI'] == 'Feedback') {
        // 	if (feedbackcid == 0) { feedbackcid = key }
        // }
        if (element["UI"] == "Bot Invoke") {
          if (answerbutton == 0) {
            if (steps[element.trigger[0].next + ""]["UI"] == "Button") {
              invokebutton = key;
              answerbutton = steps[key].trigger[0].next;
              console.log(invokebutton, answerbutton, "answernode");
            }
            // invokebutton = key
            // answerbutton = steps[key].trigger[0].next
          }
        }

        if (element["UI"] == "Chat Gpt") {
          if (answerbutton == 0) {
            if (steps[element.trigger[0].next + ""]["UI"] == "Button") {
              invokebutton = key;
              answerbutton = steps[key].trigger[0].next;
            }
          }
        }
        if (element["UI"] == "Food Court") {
          this.state.foodAppId =
            element.data.app_details && element.data.app_details.appId
              ? element.data.app_details.appId
              : "5c8e46e1967a";
        }
        if (element["UI"] == "Get Location") {
          this.state.googleMapsKey = element.data.api_key
            ? element.data.api_key.replace(/(<([^>]+)>)/gi, "")
            : "";
        }
        // if(element['UI'] == 'Feedback'){
        //     localStorage.setItem("feedback",key)
        //     console.log(element,key,"feeeeeee")
        // }
      }
    }

    console.log("steps data", steps);
    steps["00"] = {
      message: "null",
      trigger: [],
      next: 0,
      continue: false,
      Validation: { type: "", doValidate: false },
      input: "",
      action: "",
    };

    setTimeout(() => {
      this.onSendButtonPress("continue");
    }, 500);
    this.state.isFirst = false;
    this.state.cId = "00";
    this.setState({ isLoading: false });
    
   
  }

  CardNextt() {
    let scrollRef = document.getElementsByClassName("bootSliderr");
    console.log(scrollRef, "NextCard");
    scrollRef[0].scrollLeft += 270;
    scrollRef[1].scrollLeft += 270;
  }
  CardPrevv() {
    let scrollRef = document.getElementsByClassName("bootSliderr");
    console.log(scrollRef, "PrevCard");
    scrollRef[0].scrollLeft -= 270;
    scrollRef[1].scrollLeft -= 270;
  }

  CardNext() {
    let scrollRef = document.getElementsByClassName("bootSlider");
    console.log(scrollRef, "NextCard");
    scrollRef[0].scrollLeft += 100;
    scrollRef[1].scrollLeft += 100;
  }
  CardPrev() {
    let scrollRef = document.getElementsByClassName("bootSlider");
    console.log(scrollRef, "PrevCard");
    scrollRef[0].scrollLeft -= 100;
    scrollRef[1].scrollLeft -= 100;
  }
  buttonfunction(element, index) {
    if (element.contactnumber !== "") {
      this.contactnum(element.contactnumber);
    } else if (element.url !== "") {
      this.murl(element.url);
    } else {
    }
    console.log(element);
    if (
      !steps["" + this.state.cId + ""]["data"]["selected_type"] ||
      steps["" + this.state.cId + ""]["data"]["selected_type"].value == "single"
    ) {
      this.onSendButtonPress(element);
    } else {
      try {
        // if (this.state.horizontalButtons) {
        // 	var Selectbutton = document.getElementsByClassName('buttonStyleV')
        // }
        // else {
        var Selectbutton = document.getElementsByClassName("buttonStyleV");
        // var selectLen=Selectbutton.length
        // selectLen=selectLen/2

        // }

        var Selectbox = document.getElementById(`checkbox${index}`);
        console.log(Selectbox, Selectbutton, "Selectme");
        // console.log(Selectbox.__proto__,"selectbox")
        if (multstr.includes(this.checkUrl(element.name))) {
          multstr.splice(multstr.indexOf(this.checkUrl(element.name)), 1);
          Selectbutton[index].style.backgroundColor =
            this.state.customisation.clientchattextcolor;
          Selectbutton[index].style.color =
            this.state.customisation.botButtonColor;

          // Selectbutton[index+selectLen].style.backgroundColor = this.state.customisation.clientchattextcolor
          // Selectbutton[index+selectLen].style.color = this.state.customisation.botButtonColor

          console.log(
            Selectbox.__proto__,
            Selectbox.checked,
            "unselectbox",
            Selectbutton[index].style.backgroundColor,
            Selectbutton[index].style.color
          );
          Selectbox.checked = false;
          Selectbutton[index].classList.remove("selected");
          // Selectbutton[index+selectLen].classList.remove("selected");

          if (multstr.length == 0) {
            this.state.isOptionalButton = true;
          }
          this.setState({});
        } else {
          Selectbutton[index].style.backgroundColor =
            this.state.customisation.clientBubbleClr;
          Selectbutton[index].style.color =
            this.state.customisation.clientchattextcolor;
          // Selectbutton[index+selectLen].style.backgroundColor = this.state.customisation.clientBubbleClr
          // Selectbutton[index+selectLen].style.color = this.state.customisation.clientchattextcolor
          console.log(
            Selectbox.__proto__,
            Selectbox.checked,
            "selectedbox",
            Selectbutton[index].style.backgroundColor,
            Selectbutton[index].style.color
          );
          Selectbox.checked = true;
          Selectbutton[index].classList.add("selected");
          // Selectbutton[index+selectLen].classList.add("selected");

          multstr.push(this.checkUrl(element.name));
          if (multstr.length == 1) {
            this.state.isOptionalButton = false;
          }
          this.setState({});
        }
        console.log(multstr, "stringmult");
      } catch (err) {
        console.log(err);
      }
    }
  }

  selectAddressDropdown(e) {
    try {
      var index = this.state.addressCountryArr.findIndex(function (item, i) {
        return item.name === e.target.value;
      });
      this.state.addressField =
        index == -1 ? undefined : this.state.addressCountryArr[index].filename;
    } catch (error) {
      console.log(error);
    }
    this.setState({ dropDownText: e.target.value });
    this.state.text = e.target.value;
  }

  fetchTimeSlots() {
    
    if (
      window.location.href.includes(
        "pbodev.info" 
      )
    ) {
      return;

        // setTimeout(() => {
        //   slots = [];
        //   var now = Date.now() / 1000;
        //   db.collection("user_" + this.state.companyID)
        //     .doc(this.state.agentId)
        //     .get()
        //     .then((docs) => {
        //       // var dbslots = docs.data().appointment.appointmentslots;
              
        //       var dbslots = require('/Users/shubham/Downloads/download (63).json');
             
        //       timeSlotFormat = docs.data().appointment.configuration.timeFormat;
        //       this.state.timeZone= docs.data().appointment.configuration.timezone;
        //       console.log(timeSlotFormat,"timeSlotFormat",this.state.timeZone,"timeZone")
        //       TimeGap=this.getTimeNew()
              
        //       console.log(dbslots,"SLOTS");
        //       for (const key in dbslots) {
        //         if (dbslots.hasOwnProperty(key)) {
        //           if (key > now-TimeGap*60) {
        //             slots.push(dbslots[key]);
        //           }
        //         }
        //       }
        //       // TimeGap=this.getTimeNew()
              
        //       console.log(TimeGap,"TIME GAP",slots[0])
        //       slots.forEach((doc, index) => {
        //         var data = {...doc};
        //         // var startTime = new Date(data.start)
        //         // startTime = firebase1.firestore.Timestamp.fromDate(startTime);
        //         // var endTime = new Date(data.end)
        //         // endTime = firebase1.firestore.Timestamp.fromDate(endTime);
        //         var startTime = data.start;
        //         var displaystart=startTime
        //         var displayd = new Date((displaystart.seconds-TimeGap*60)*1000).getDate()
                
        //         if(!dateSlots.includes(displayd)){
        //         dateSlots.push(displayd)
        //         }
        //         })
        //       console.log(dateSlots,"DATES ALLOWED")

           
        //       var nowTime = firebase1.firestore.Timestamp.now().toMillis();
        //       var bookedSlots = docs.data().appointment.appointmentsbooked;
        //       bookedSlots.forEach((element) => {
        //         var el = element.start.seconds * 1000;
        //         if (el > nowTime) {
        //           bookedTimeSlots.push(element);
        //         }
        //       });
        //     });
        //   },500)
       
      
    }
    else{
    var data = [`tablealias=${this.state.companyID}`];
    fetch("https://chatbottest.appypie.com/makemoreappointment", {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((result) => {
        setTimeout(() => {
          slots = [];
          var now = Date.now() / 1000;
          db.collection("user_" + this.state.companyID)
            .doc(this.state.agentId)
            .get()
            .then((docs) => {
              var dbslots = docs.data().appointment.appointmentslots;
              timeSlotFormat = docs.data().appointment.configuration.timeFormat;
              this.state.timeZone= docs.data().appointment.configuration.timezone;
              TimeGap=this.getTimeNew()
              
              console.log(dbslots,"SLOTS");
              for (const key in dbslots) {
                if (dbslots.hasOwnProperty(key)) {
                  if (key > now-TimeGap*60) {
                    slots.push(dbslots[key]);
                  }
                }
              }
              
              slots.forEach((doc, index) => {
                var data = {...doc};
                // var startTime = new Date(data.start)
                // startTime = firebase1.firestore.Timestamp.fromDate(startTime);
                // var endTime = new Date(data.end)
                // endTime = firebase1.firestore.Timestamp.fromDate(endTime);
                var startTime = data.start;
                var displaystart=startTime
                var displayd = new Date((displaystart.seconds-TimeGap*60)*1000).getDate()
                
                if(!dateSlots.includes(displayd)){
                dateSlots.push(displayd)
                }
                })
              console.log(dateSlots,"DATES ALLOWED")

              
              var nowTime = firebase1.firestore.Timestamp.now().toMillis();
              var bookedSlots = docs.data().appointment.appointmentsbooked;
              bookedSlots.forEach((element) => {
                var el = element.start.seconds * 1000;
                if (el > nowTime) {
                  bookedTimeSlots.push(element);
                }
              });
            });
        }, 2000);
      })
      .catch((error) => console.log("error", error));
    }
  }
 
  componentDidUpdate() {
 
    // console.log(this.state.mainScrollHeight, "didUpdate  q");
    if (this.myRef) {
      this.myRef.scrollTop = 100000000000;
    }
    // try {
    //   console.clear();
    //   var clientHeight = document.getElementById('BottomButtons').clientHeight;
    //   var s_view = document.getElementById("PreviewChatScroller_dheeraj");
    //   this.state.mainScrollHeight = `calc(100% - 120px)`;
    //   if (this.state.btns.length == 2) {
    //     this.state.buttonBottomSpace = clientHeight - 10 - 35;
    //   }
    //   else {
    //     var s_view = document.getElementById("PreviewChatScroller_dheeraj");
    //     var h = (clientHeight - 25 - (this.state.btns.length * 10)) + 140;
    //     console.clear();
    //     console.log(s_view.style.height);
    //     this.state.mainScrollHeight = `calc(100% - ${h}px)`;
    //     console.log(s_view.style.height);

    //   }
    // }
    // catch (e) {
    //   console.log(e);
    // }

    return;
    document.getElementsByClassName(
      "chatscroller"
    )[0].scrollTop = 10000000000000000;
    try {
      document.getElementsByClassName(
        "chatscroller"
      )[0].scrollTop = 10000000000000000;
      document.getElementById("Testing11").scrollTop = 10000000000000000;
      console.log(
        "scroll top is done",
        document.getElementById("Testing11").scrollTop
      );
    } catch (error) {
      console.log("error in scrolling  scrollview to bottom", error);
    }
    return;
    document.getElementById("Testing11").scrollTo({
      top: 10000000,
      left: 0,
      behavior: "smooth",
    });
    document.getElementById("Testing11").scrollTop = 1000000000;
    return;
    this.focusNEW();
    try {
      if (
        document.getElementById("Testing11").scrollTop + 50 >=
        scrollPosition
      ) {
        document.getElementById("Testing11").scrollTop = 10000000000000000;
      }
    } catch (error) {
      console.log("error in scrolling  scrollview to bottom", error);
    }
  }

  murl(url) {
    window.open(url, "_blank");
    // { this.state.text = url; this.onSendButtonPress() }
  }
  contactnum(num) {
    // { this.state.text = num; this.onSendButtonPress() }
  }
  focusNEW() {
    if (this.myRef) {
      this.myRef.scrollTop = 100000000000;
    }
    return;
    document.getElementById("Testing11").scrollTo({
      top: 10000000,
      left: 0,
      behavior: "smooth",
    });
    // console.log(e.target.scrollHeight);

    // scrollPosition = e.target.scrollHeight - e.target.clientHeight;
  }

  fileDownload(doc) {
    window.open(doc.msg);
  }

  componentDidMount() {
    console.log(this.props.botId , ' clg')//for debugging purpose
	var param_array = window.location.href.includes("did")? window.location.href.split("?")[1].split("=")[1]:this.props.botId
  // console.log(this.props.paid_answerbot , ' glc')//for debugging purpose
  //   if (
      
  //     this.props.paid_answerbot[param_array] == undefined ||
  //     this.props.paid_answerbot[param_array] == null ||
  //     this.props.paid_answerbot[param_array] == "undefined"
  //   ) {
  //   } else {
  //     if (
  //       this.props.paid_answerbot[param_array].status == "dedicated_kb_created"
  //     ) {
  //       this.setState({ isPaid: true });
  //     }
  //   }

  

    this.setState({
      isPaid : true,
      show: true,
      chatIcon: this.image_addedon + cross,
      showHide: "Container1",
    });
  }

  getID() {
    for (let i = 0; i < 200; i++) {
      if (this.state.listID[i].isFree) {
        this.state.listID[i].isFree = false;
        return this.state.listID[i].ID;
      }
    }
  }

  freeID(id) {
    this.state.listID.forEach((element) => {
      if (element.ID == id) {
        element.isFree = true;
      }
    });
  }

  onSendbtnPress() {
    console.log("here");
    if (this.state.AddressDropdown) {
      return this.onSendButtonPress(this.state.dropDownText);
    }
    if (
      this.state.inputText.trim() == "" &&
      this.state.text.trim() == "" &&
      this.state.buttonType != "multiple"
    ) {
      console.log("return hapened");
      return;
    }
    if (this.state.isOptional) {
      this.state.list.splice(skipindex, 1);
      skipindex = false;
    }
    this.state.isOptional = false;
    this.state.isOptionalButton = false;
    this.state.optionalPlaceholder = "Type a message";
    this.setState({
      inputText: "",
      showHideEmoji: false,
      showCountryList: false,
      showDropDown: false,
    });
    this.state.buttonType == "multiple"
      ? this.onSendButtonPress(multstr, "multiple")
      : this.onSendButtonPress();
    // this.onSendButtonPress();
    try {
      var tt = document.getElementById("msg_id");
      tt.style.height = "30px";
      var ff = document.getElementById("FOOTER");
      ff.style.height = 30 + "px";
    } catch (error) {}
  }

  onInputChange1() {
    setTimeout(() => {
      try {
        var tt = document.getElementById("msg_id");
        tt.style.height = "auto";
        if (tt.scrollHeight > 164) {
          tt.style.height = "150px";
          var ff = document.getElementById("FOOTER");
          ff.style.height = 164 + "px";
          return;
        }
        var ff = document.getElementById("FOOTER");
        ff.style.height = tt.scrollHeight + "px";
        tt.style.height = tt.scrollHeight + "px";
      } catch (error) {}
    }, 200);
  }

  onInputChange(event) {
    if (this.state.isOptional) {
      if (event.target.value.trim() == "") {
        this.state.isOptionalButton = true;
      } else {
        this.state.isOptionalButton = false;
      }
    }
    if (event.keyCode == 13) {
      if (!event.shiftKey) {
        if (this.state.text.trim() != "") {
          event.preventDefault();
          this.setState({ inputText: "", showHideEmoji: false });
          this.state.searchAllowed
          ? this.setupHotel("search")
          : this.state.showGoogleMaps
            ? this.onSendButtonPress(
              { lat: this.state.gMapLat, lng: this.state.gMapLong },
              "location"
            )
          : //  this.setupHotel("initial")
          this.state.pickToppings
          ? this.handleToppings("submit")
          : this.onSendButtonPress();
        event.preventDefault();
        return;
          var ff = document.getElementById("FOOTER");
          ff.style.height = 50 + "px";
        }
        return;
      }
    }
    try {
      this.setState({
        inputText: event.target.value,
        text: event.target.value,
      });
      var tt = document.getElementById("msg_id");
      var ff = document.getElementById("FOOTER");
      tt.style.height = "auto";
      if (tt.scrollHeight > 164) {
        tt.style.height = "150px";
        return;
      }

      if (tt.scrollHeight < 50) {
        tt.style.height = "30px";
        ff.style.height = "50px";
      } else {
        ff.style.height = tt.scrollHeight + "px";
        tt.style.height = tt.scrollHeight - 14 + "px";
      }
    } catch (error) {
      // console.log('erro')
    }
  }

  userAgree(e) {
    document.cookie = "userAgree=" + "Agree" + ";path=/";
    setTimeout(() => {
      this.state.cId = "00";
      this.onSendButtonPress("continue");
    }, 100);
    this.setState({ isFirst: false, takeUserPermission: false });
  }

  selectGender(element) {
    steps["" + this.state.cId + ""]["trigger"] = element;
    this.state.mainScrollHeight = "calc(100% - 120px)";
    this.onSendButtonPress(element);
  }

  getProper = (answer) => {
    answer = answer.replaceAll("çp>", "<p>").replaceAll("ç/p>", "</p>");
    answer = answer
      .replaceAll("çspan", " <span")
      .replaceAll("ç/span>", "</span>");

    answer = answer
      .replaceAll("ça target", " <a target")
      .replaceAll("ç/a>", "</a>");
    answer = answer
      .replaceAll("ça href", " <a href")
      .replaceAll("ç/a>", "</a>");
    answer = answer
      .replaceAll("çstrong>", "<strong>")
      .replaceAll("ç/strong>", "</strong>");
    answer = answer.replaceAll("çins>", "<ins>").replaceAll("ç/ins>", "</ins>");
    answer = answer.replaceAll("çem>", "<em>").replaceAll("ç/em>", "</em>");
    answer = answer.replaceAll("çli>", "<li>").replaceAll("ç/li>", "</li>");
    answer = answer.replaceAll("çul>", "<ul>").replaceAll("ç/ul>", "</ul>");
    answer = answer.replaceAll("çbr>", "<br>").replaceAll("ç/br>", "</br>");
    answer = answer
      .replaceAll("çspan", "<span")
      .replaceAll("ç/span>", "</span>");
    return answer;
  };

  async handlePersonalityPaidBot(question) {
    let personality_name = localStorage.getItem("answer_type");
    var host = this.props.kb_details.getAnswerUrl + "/qnamaker";
    var endpoint_key = this.props.kb_details.endpoint; //"afec2d78-1dcd-4888-9023-19a0558b057b";

    if (this.props.kb_details.id == "a24d3e0c-aa62-4727-99f4-cb77d6bc5d13") {
      host = "https://italianlang.azurewebsites.net/qnamaker";
      endpoint_key = "f61cdbb0-891d-433d-b7c1-965e0e9bcfac";
      console.log("kb-id--------d----->>", this.props.kb_details.id);
      var route =
        "/knowledgebases/" + this.props.kb_details.id + "/generateAnswer";
    } else {
      var route =
        "/knowledgebases/" +
        "3c49ac70-9645-439d-bd4c-3fa716f8eb0f" +
        "/generateAnswer";
    }

    var question = {
      question: question,
      top: 1,

      scoreThreshold: 40,
      RankerType: "QuestionOnly",
      strictFilters: [
        {
          name: "editorial",
          value: personality_name,
        },
      ],
    };

    var options = {
      method: "POST",
      headers: {
        Authorization: "EndpointKey " + endpoint_key,
        "Content-type": "application/json",
      },
      body: JSON.stringify(question),
    };
    var r = await fetch(host + route, options);
    r = await r.json();
    var myanswer = r.answers[0].answer;
    return myanswer;
  }
  async exitGpt() {
    this.state.cId = answerbutton;

    this.state.list.pop();
    this.setupNode();
  }
  async pcAnswer(text,nextcId){

			
    var alias=(this.props.botId?this.props.botId:localStorage.getItem('botId'))
    console.log(alias,this.state.companyID,"-alias for GPT",this.props.botId)
    var raw = JSON.stringify({
      "question": text,
      "history": Gpthistory,
      "nameSpace":alias
    });
    
    var requestOptions = {
      method: 'POST',
      headers:  { "Content-Type": "application/json" },
      body: raw,
      redirect: 'follow'
    };
    // fetch("http://localhost:5051/chatbot-production-d6ea3/us-central1"+"/gpt/answer", requestOptions)
    fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"+"/pineCone/search", requestOptions)
      .then(response => response.json())
      .then(result => {
      this.state.processing = false;
    
    
      var myanswer=result.text
      // 
      
      this.state.list.pop();
      skipindex=false
      this.setState({})
      
      // if (Gpthistory.length < 8) {
      //   Gpthistory.push(text)
      //   Gpthistory.push(myanswer)
      // } else {
      //   Gpthistory.shift(); // remove the first element from the array
      //   Gpthistory.shift(); // add the element to the end of the array
      //   Gpthistory.push(text)
      //   Gpthistory.push(myanswer)
      // }
    
      
      // addIdData("session", Gpthistory);
      
      text = "";
      
      this.state.list.push(
        <div style={{ display: "flex" }}>
        <img
          style={{
          width: 40,
          height: 40,
          marginRight: 10,
          alignSelf: "flex-end",
          marginLeft: 10,
          borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
          background: this.state.customisation.messagebubble,
          color: this.state.customisation.botTextcolor,
          }}
        >
          <p
          style={{
            fontSize: this.state.customisation.fontSize,
            fontFamily: this.state.customisation.fontStyle,
          }}
          dangerouslySetInnerHTML={{
            __html: this.checkUrl(
            this.getProper(myanswer.trim()),
            "bot"
            ),
          }}
          />
          {/* {parse(this.getProper(this.checkUrl(myanswer.trim()),"bot") )} */}
        </div>
        </div>
      );
      // var skipTipIcon = steps["" + cId + ""]["data"].tipIcon
      // 	  ? steps["" + cId + ""]["data"].tipIcon
      // 	  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f";
      // 	this.state.skipIcon = steps["" + cId + ""]["data"].skipIcon
      // 	  ? steps["" + cId + ""]["data"].skipIcon
      // 	  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556";
      // 	  this.state.list.push(
      //   <div className="skiptext" style={{ fontSize: 9 }}>
      // 	<img
      // 	  style={{ width: 12, height: 12, marginRight: 5 }}
      // 	  src={skipTipIcon}
      // 	/>{" "}
      // 	{
      // 	  <p
      // 		style={{
      // 		  margin: 0,
      // 		  fontSize: "8px !important",
      // 		  wordBreak: "break-word",
      // 		  maxWidth: "65%",
      // 		  textAlign: "center",
      // 		  fontFamily: this.state.customisation.fontStyle,
      // 		}}
      // 		dangerouslySetInnerHTML={{
      // 		  __html: this.checkUrl(steps["" + cId + ""].data.text, "bot"),
      // 		}}
      // 	  />
      // 	}{" "}
      // 	<img
      // 	  style={{
      // 		width: 10,
      // 		height: 10,
      // 		marginLeft: 5,
      // 		cursor: "pointer",
      // 	  }}
      // 	  src={this.state.skipIcon}
      // 	  onClick={() => this.exitGpt()}
      // 	/>
      //   </div>
      // );
      this.state.optionalPlaceholder = steps['' + this.state.cId + '']['data'].optionPlaceholder ? steps['' + this.state.cId + '']['data'].optionPlaceholder : "Type a message"
      var optionalSuggestiontext = steps['' + this.state.cId + '']['data'].optionSuggestion ? steps['' + this.state.cId + '']['data'].optionSuggestion : "You skip this question by clicking on"
      var skipTipIcon = steps['' + this.state.cId + '']['data'].tipIcon ? steps['' + this.state.cId + '']['data'].tipIcon : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f"
      this.state.skipIcon = steps['' + this.state.cId + '']['data'].skipIcon ? steps['' + this.state.cId + '']['data'].skipIcon : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556"
  
      this.state.list.push(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 12,
            marginBottom: 3,
            alignItems: "center",
            fontSize: 9,
          }}
        >
          <img
            style={{ width: 12, height: 12, marginRight: 5 }}
            src={skipTipIcon}
          />
          <p style={{ margin: 0, fontSize: "8px !important", wordBreak: "break-word", maxWidth: "65%", textAlign: "center",fontFamily: this.state.customisation.fontStyle }} 
          dangerouslySetInnerHTML={{ __html: this.checkUrl(steps["" + nextcId+ ""].data.text, "bot")}} />
          <img
            style={{ width: 10, height: 10, marginLeft: 5, cursor: 'pointer' }}
            src={this.state.skipIcon}
            onClick={() => this.exitGpt()}
          />
        </div>
      );
      this.setState({exitFlow:true})
    
      
      
      // console.log(response, myanswer);
      // console.log(result)
      })
      .catch(error => {text = "";console.log('error', error)});
      
      this.setState({ toggleInput: false });
      try {
      document
        .getElementById("Appypie")
        .contentWindow.document.getElementById("InputBar")
        .focus();
      } catch (error) { }
    
    
      }
  async gptAnswerBook(text,nextcId){

			
	var alias=this.state.companyID+"-"+(this.props.botId?this.props.botId:localStorage.getItem('botId'))
	console.log(alias,this.state.companyID,"-alias for GPT",this.props.botId)
	var raw = JSON.stringify({
	  "question": text,

	  "alias":alias
	});
	
	var requestOptions = {
	  method: 'POST',
	  headers:  { "Content-Type": "application/json" },
	  body: raw,
	  redirect: 'follow'
	};
	// fetch("http://localhost:5051/chatbot-production-d6ea3/us-central1"+"/gpt/answer", requestOptions)
	fetch("https://us-central1-chatbot-production-d6ea3.cloudfunctions.net"+"/gpt/answer", requestOptions)
	  .then(response => response.json())
	  .then(result => {
		this.state.processing = false;
	
	
		var myanswer=result.answer
		// 
		
		this.state.list.pop();
		skipindex=false
		this.setState({})
		
		// if (Gpthistory.length < 8) {
		//   Gpthistory.push(text)
		//   Gpthistory.push(myanswer)
		// } else {
		//   Gpthistory.shift(); // remove the first element from the array
		//   Gpthistory.shift(); // add the element to the end of the array
		//   Gpthistory.push(text)
		//   Gpthistory.push(myanswer)
		// }
	
		
		// addIdData("session", Gpthistory);
	  
		text = "";
		
		this.state.list.push(
		  <div style={{ display: "flex" }}>
			<img
			  style={{
				width: 40,
				height: 40,
				marginRight: 10,
				alignSelf: "flex-end",
				marginLeft: 10,
				borderRadius: 25,
			  }}
			  src={this.state.customisation.chatBotAvtar}
			/>
			<div
			  className="bot"
			  style={{
				background: this.state.customisation.messagebubble,
				color: this.state.customisation.botTextcolor,
			  }}
			>
			  <p
				style={{
				  fontSize: this.state.customisation.fontSize,
				  fontFamily: this.state.customisation.fontStyle,
				}}
				dangerouslySetInnerHTML={{
				  __html: this.checkUrl(
					this.getProper(myanswer.trim()),
					"bot"
				  ),
				}}
			  />
			  {/* {parse(this.getProper(this.checkUrl(myanswer.trim()),"bot") )} */}
			</div>
		  </div>
		);
		// var skipTipIcon = steps["" + cId + ""]["data"].tipIcon
		// 	  ? steps["" + cId + ""]["data"].tipIcon
		// 	  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f";
		// 	this.state.skipIcon = steps["" + cId + ""]["data"].skipIcon
		// 	  ? steps["" + cId + ""]["data"].skipIcon
		// 	  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556";
		// 	  this.state.list.push(
		//   <div className="skiptext" style={{ fontSize: 9 }}>
		// 	<img
		// 	  style={{ width: 12, height: 12, marginRight: 5 }}
		// 	  src={skipTipIcon}
		// 	/>{" "}
		// 	{
		// 	  <p
		// 		style={{
		// 		  margin: 0,
		// 		  fontSize: "8px !important",
		// 		  wordBreak: "break-word",
		// 		  maxWidth: "65%",
		// 		  textAlign: "center",
		// 		  fontFamily: this.state.customisation.fontStyle,
		// 		}}
		// 		dangerouslySetInnerHTML={{
		// 		  __html: this.checkUrl(steps["" + cId + ""].data.text, "bot"),
		// 		}}
		// 	  />
		// 	}{" "}
		// 	<img
		// 	  style={{
		// 		width: 10,
		// 		height: 10,
		// 		marginLeft: 5,
		// 		cursor: "pointer",
		// 	  }}
		// 	  src={this.state.skipIcon}
		// 	  onClick={() => this.exitGpt()}
		// 	/>
		//   </div>
		// );
		this.state.optionalPlaceholder = steps['' + this.state.cId + '']['data'].optionPlaceholder ? steps['' + this.state.cId + '']['data'].optionPlaceholder : "Type a message"
		var optionalSuggestiontext = steps['' + this.state.cId + '']['data'].optionSuggestion ? steps['' + this.state.cId + '']['data'].optionSuggestion : "You skip this question by clicking on"
		var skipTipIcon = steps['' + this.state.cId + '']['data'].tipIcon ? steps['' + this.state.cId + '']['data'].tipIcon : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f"
		this.state.skipIcon = steps['' + this.state.cId + '']['data'].skipIcon ? steps['' + this.state.cId + '']['data'].skipIcon : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556"

		this.state.list.push(
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 12,
					marginBottom: 3,
					alignItems: "center",
					fontSize: 9,
				}}
			>
				<img
					style={{ width: 12, height: 12, marginRight: 5 }}
					src={skipTipIcon}
				/>
				<p style={{ margin: 0, fontSize: "8px !important", wordBreak: "break-word", maxWidth: "65%", textAlign: "center",fontFamily: this.state.customisation.fontStyle }} 
				dangerouslySetInnerHTML={{ __html: this.checkUrl(steps["" + nextcId+ ""].data.text, "bot")}} />
				<img
					style={{ width: 10, height: 10, marginLeft: 5, cursor: 'pointer' }}
					src={this.state.skipIcon}
					onClick={() => this.exitGpt()}
				/>
			</div>
		);
		this.setState({exitFlow:true})
	
		
	  
	  // console.log(response, myanswer);
	  // console.log(result)
	  })
	  .catch(error => {text = "";console.log('error', error)});
	  
	  this.setState({ toggleInput: false });
	  try {
		document
		  .getElementById("Appypie")
		  .contentWindow.document.getElementById("InputBar")
		  .focus();
	  } catch (error) { }
	
	
	  }
  async gptAnswer(text, nextcId) {
    var raw = JSON.stringify({
      message: text,
      previousResponses: Gpthistory,
      alias: this.state.botId,
    });

    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
      redirect: "follow",
    };

    fetch(web_url + "/gpt/chat", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.state.processing = false;

        var myanswer = result.data;
        //

        this.state.list.pop();
        skipindex = false;
        this.setState({});

        if (Gpthistory.length < 8) {
          Gpthistory.push(text);
          Gpthistory.push(myanswer);
        } else {
          Gpthistory.shift(); // remove the first element from the array
          Gpthistory.shift(); // add the element to the end of the array
          Gpthistory.push(text);
          Gpthistory.push(myanswer);
        }

        // addIdData("session", Gpthistory);

        text = "";

        this.state.list.push(
          <div style={{ display: "flex" }}>
            <img
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                alignSelf: "flex-end",
                marginLeft: 10,
                borderRadius: 25,
              }}
              src={this.state.customisation.chatBotAvtar}
            />
            <div
              className="bot"
              style={{
                background: this.state.customisation.messagebubble,
                color: this.state.customisation.botTextcolor,
              }}
            >
              <p
                style={{
                  fontSize: this.state.customisation.fontSize,
                  fontFamily: this.state.customisation.fontStyle,
                }}
                dangerouslySetInnerHTML={{
                  __html: this.checkUrl(this.getProper(myanswer.trim()), "bot"),
                }}
              />
              {/* {parse(this.getProper(this.checkUrl(myanswer.trim()),"bot") )} */}
            </div>
          </div>
        );
        // var skipTipIcon = steps["" + cId + ""]["data"].tipIcon
        // 	  ? steps["" + cId + ""]["data"].tipIcon
        // 	  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f";
        // 	this.state.skipIcon = steps["" + cId + ""]["data"].skipIcon
        // 	  ? steps["" + cId + ""]["data"].skipIcon
        // 	  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556";
        // 	  this.state.list.push(
        //   <div className="skiptext" style={{ fontSize: 9 }}>
        // 	<img
        // 	  style={{ width: 12, height: 12, marginRight: 5 }}
        // 	  src={skipTipIcon}
        // 	/>{" "}
        // 	{
        // 	  <p
        // 		style={{
        // 		  margin: 0,
        // 		  fontSize: "8px !important",
        // 		  wordBreak: "break-word",
        // 		  maxWidth: "65%",
        // 		  textAlign: "center",
        // 		  fontFamily: this.state.customisation.fontStyle,
        // 		}}
        // 		dangerouslySetInnerHTML={{
        // 		  __html: this.checkUrl(steps["" + cId + ""].data.text, "bot"),
        // 		}}
        // 	  />
        // 	}{" "}
        // 	<img
        // 	  style={{
        // 		width: 10,
        // 		height: 10,
        // 		marginLeft: 5,
        // 		cursor: "pointer",
        // 	  }}
        // 	  src={this.state.skipIcon}
        // 	  onClick={() => this.exitGpt()}
        // 	/>
        //   </div>
        // );
        this.state.optionalPlaceholder = steps["" + this.state.cId + ""]["data"]
          .optionPlaceholder
          ? steps["" + this.state.cId + ""]["data"].optionPlaceholder
          : "Type a message";
        var optionalSuggestiontext = steps["" + this.state.cId + ""]["data"]
          .optionSuggestion
          ? steps["" + this.state.cId + ""]["data"].optionSuggestion
          : "You skip this question by clicking on";
        var skipTipIcon = steps["" + this.state.cId + ""]["data"].tipIcon
          ? steps["" + this.state.cId + ""]["data"].tipIcon
          : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f";
        this.state.skipIcon = steps["" + this.state.cId + ""]["data"].skipIcon
          ? steps["" + this.state.cId + ""]["data"].skipIcon
          : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556";

        this.state.list.push(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 12,
              marginBottom: 3,
              alignItems: "center",
              fontSize: 9,
            }}
          >
            <img
              style={{ width: 12, height: 12, marginRight: 5 }}
              src={skipTipIcon}
            />
            <p
              style={{
                margin: 0,
                fontSize: "8px !important",
                wordBreak: "break-word",
                maxWidth: "65%",
                textAlign: "center",
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{
                __html: this.checkUrl(
                  steps["" + nextcId + ""].data.text,
                  "bot"
                ),
              }}
            />
            <img
              style={{
                width: 10,
                height: 10,
                marginLeft: 5,
                cursor: "pointer",
              }}
              src={this.state.skipIcon}
              onClick={() => this.exitGpt()}
            />
          </div>
        );
        this.setState({ exitFlow: true });

        // console.log(response, myanswer);
        // console.log(result)
      })
      .catch((error) => {
        text = "";
        console.log("error", error);
      });

    this.setState({ toggleInput: false });
    try {
      document
        .getElementById("Appypie")
        .contentWindow.document.getElementById("InputBar")
        .focus();
    } catch (error) {}
  }
  getPrice(){
    // .replace(',','')
   console.log(this.state.perkilometreprice,this.state.distanceforCalc.replace(',',''),'-->amo' )
    let totalprice = this.state.distanceforCalc.replace(',','') * parseFloat(this.state.perkilometreprice)

    console.log(totalprice.toFixed(2),'-->totalprice');
    return totalprice.toFixed(2)
  }
  async getAnswerPersonalityQA(text) {
		let personality_name =this.props.personality?this.props.personality:localStorage.getItem("answer_type");
		personality_name=personality_name.charAt(0).toUpperCase() + personality_name.slice(1);
		var body={
			"question": text,
			"userId": "SP",
			"confidenceScoreThreshold": 0.1,
			"rankerType": "Default",
			"top": 1,
			"includeUnstructuredSources": true,
			"filters": {
				"sourceFilter": [
					"qna_chitchat_"+personality_name
				]
			}
		}
		var host=`https://chatbot-rnd.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=${"Chatbot"}&deploymentName=production&api-version=2021-10-01`
		var options = {
			method: "POST",
			headers: {
				'Ocp-Apim-Subscription-Key': 'b5248ccc4376418cb5320312bbdf2815',
				"Content-type": "application/json",
			},
			body: JSON.stringify(body),
		};
		
		var r = await fetch(host, options)
		r = await r.json()
		var myanswer = r.answers[0].answer
		return myanswer
	}

  async getAnswerPaid(text, next) {
    let personality_name = localStorage.getItem("answer_type");
    var host = this.props.kb_details.getAnswerUrl + "/qnamaker";
    var endpoint_key = this.props.kb_details.endpoint; //"afec2d78-1dcd-4888-9023-19a0558b057b";
    // var route =
    // 	"/knowledgebases/" + this.props.kb_details.id + "/generateAnswer";
    if (this.props.kb_details.id == "a24d3e0c-aa62-4727-99f4-cb77d6bc5d13") {
      host = "https://italianlang.azurewebsites.net/qnamaker";
      endpoint_key = "f61cdbb0-891d-433d-b7c1-965e0e9bcfac";
    }
    var route =
      "/knowledgebases/" + this.props.kb_details.id + "/generateAnswer";
    var question = {
      question: text,
      top: 10,
      isTest: true,
      scoreThreshold: 50,
      // RankerType: "QuestionOnly",
    };

    var options = {
      method: "POST",
      headers: {
        Authorization: "EndpointKey " + endpoint_key,
        "Content-type": "application/json",
      },
      body: JSON.stringify(question),
    };

    var r = await fetch(host + route, options);
    r = await r.json();
    var myanswer = r.answers[0].answer;
    if (myanswer.includes("No good match found")) {
      if (
        personality_name != "none" &&
        personality_name != "undefined" &&
        personality_name != undefined
      ) {
        let r = await this.getAnswerPersonalityQA(text);
        myanswer = r;
      }
    }

    this.state.list.pop();
    if (myanswer.includes("TRANSFER_CHAT")) {
      this.state.isTrainedbot = false;
      var trigger = steps[answerbutton + ""]["trigger"][1];
      this.state.cId = answerbutton;
      skipnext = true;
      this.state.processing = false;
      this.onSendButtonPress(trigger);
    } else if (myanswer.includes("No good match found")) {
      this.state.isTrainedbot = false;
      var trigger = steps[invokebutton + ""]["trigger"][0];
      this.state.cId = answerbutton;
      skipnext = true;
      this.state.processing = false;
      this.onSendButtonPress(trigger);
    } else {
      this.state.processing = false;
      this.state.list.push(
        <div style={{ display: "flex" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <div
            className="bot"
            style={{
              color: this.state.customisation.botTextcolor,
              background: this.state.customisation.messagebubble,
            }}
            dangerouslySetInnerHTML={{
              __html: this.getProper(
                myanswer.includes("No good match found in KB.")
                  ? "No answer found for the mentioned question."
                  : this.getProper(this.checkUrl(myanswer.trim()), "bot")
              ),
            }}
          ></div>
        </div>
      );
      this.setState({ toggleInput: false });
      try {
        document.getElementById("InputBar").focus();
      } catch (error) {}
    }
  }

  async getAnswer(text, next, isPer) {
    var host = this.props.kb_details.getAnswerUrl + "/qnamaker";
    var endpoint_key = this.props.kb_details.endpoint; //"afec2d78-1dcd-4888-9023-19a0558b057b";
    var route =
      "/knowledgebases/" + this.props.kb_details.id + "/generateAnswer";
    var _strictFilters = [];
    if (isPer) {
      _strictFilters = [
        {
          name: "editorial",
          value: localStorage.getItem("answer_type"),
        },
      ];
    } else {
      _strictFilters = [
        {
          name: "email",
          value: localStorage.getItem("email") + this.props.botId,
        },
        // {
        // 	name: "action",
        // 	value: "transfer_chat",
        // },
      ];
    }
    var question = {
      question: text,
      top: 10,
      isTest: true,
      scoreThreshold: 40,
      RankerType: "QuestionOnly",
      strictFilters: _strictFilters,
      // strictFilters: [
      // 	{
      // 		name: "email",
      // 		value: localStorage.getItem("email") + this.props.botId,
      // 	},
      // 	{
      // 		name: "editorial",
      // 		value: localStorage.getItem("answer_type"),
      // 	},
      // 	{
      // 		name: "action",
      // 		value: "transfer_chat",
      // 	},
      // ],
      strictFiltersCompoundOperationType: "OR",
    };

    try {
      var options = {
        method: "POST",
        headers: {
          Authorization: "EndpointKey " + endpoint_key,
          "Content-type": "application/json",
        },
        body: JSON.stringify(question),
      };
      await fetch(host + route, options)
        .then((res) => res.json())
        .then((response) => {
          if (response.answers.length == 1) {
            if (response.answers[0].id == -1 && !isPer) {
              // alert("now asking personality")
              this.getAnswer(this.state.text, next, true);
              return;
            }
          }

          this.state.list.pop();
          var myanswer = "No good match found";
          var isAdded = false;
          console.log(response, "fdsafdsfadsfadsfdsfewrqwerfsdfds");

          response.answers.forEach((ans) => {
            if (!isAdded && ans.metadata.length > 0) {
              if (
                ans.metadata[0].value == "transfer_chat" ||
                ans.metadata[0].value ==
                  localStorage.getItem("email") +
                    this.props.botId.toLocaleLowerCase() ||
                ans.metadata[0].value == localStorage.getItem("answer_type")
              ) {
                myanswer = ans.answer;
                isAdded = true;
              }
            }
          });
          // let score = response.answers[0].score;
          // let source = response.answers[0].source;

          // if (source == localStorage.getItem("answer_type") + ".tsv") {
          // 	if (score < 50) {
          // 		myanswer = "No good match found";
          // 	} else {
          // 		myanswer = response.answers[0].answer;
          // 	}
          // } else {
          // 	if (score < 40) {
          // 		myanswer = "No good match found";
          // 	} else {
          // 		myanswer = response.answers[0].answer;
          // 	}
          // }
          if (myanswer.includes("TRANSFER_CHAT")) {
            this.state.isTrainedbot = false;
            var trigger = steps[answerbutton + ""]["trigger"][1];
            this.state.cId = answerbutton;
            skipnext = true;
            this.state.processing = false;
            this.onSendButtonPress(trigger);
          } else if (myanswer.includes("No good match found")) {
            this.state.isTrainedbot = false;
            // var trigger = steps['' + next + '']['trigger'][0]
            var trigger = steps[invokebutton + ""]["trigger"][0];
            this.state.cId = answerbutton;
            skipnext = true;
            this.state.processing = false;
            this.onSendButtonPress(trigger);
          } else {
            this.state.processing = false;
            this.state.list.push(
              <div style={{ display: "flex" }}>
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 10,
                    alignSelf: "flex-end",
                    marginLeft: 10,
                    borderRadius: 25,
                  }}
                  src={this.state.customisation.chatBotAvtar}
                />
                <div
                  className="bot"
                  style={{
                    color: this.state.customisation.botTextcolor,
                    background: this.state.customisation.messagebubble,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: this.getProper(
                      myanswer.includes("No good match found in KB.")
                        ? "No answer found for the mentioned question."
                        : this.checkUrl(this.getProper(myanswer.trim()), "bot")
                    ),
                  }}
                >
                  {/* {myanswer} */}
                </div>
              </div>
            );
            console.log(
              response,
              myanswer,
              this.checkUrl(this.getProper(myanswer.trim()), "bot"),
              "myanswer"
            );
            this.setState({ toggleInput: false });
            try {
              document.getElementById("InputBar").focus();
            } catch (error) {
              // console.log(error)
            }
          }
        });
    } catch (err) {
      console.log(err.statusCode);
      console.log(err.message);
      console.log(err.error);
    }
    this.state.text = "";
  }
  convertToPlain = (html) => {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  };
  personalityChange(rtext) {
    console.log(rtext, "rtextrtextrtext");
    this.state.text = rtext;
    this.setState();
    this.onSendButtonPress();
  }
















  onSendButtonPress(event, checkboolean) {
  
    console.log("---->>HII vikas");
    console.log(
      event,
      "------->>>>event",
      checkboolean,
      "-________>>>>checkbolean"
    );
    this.setState({errorcode:''})
    this.state.toggleInput = false;
    console.log(
      "prevcid",
      prevcId,
      this.state.cId,
      steps[this.state.cId].UI,
      steps[prevcId].UI
    );
    prevcId =
      steps[this.state.cId].UI == steps[prevcId].UI &&
      steps[prevcId].UI == "Get Phone"
        ? prevcId
        : this.state.cId;
    var skipaddresslast = false;
    this.state.text = this.state.text.trim();
    console.log(this.state.processing, "processing");
    if (this.state.processing) {
      console.log("RETURN BY PROCESSING");
      return;
    }
    this.state.processing = true;

    if (steps["" + this.state.cId + ""]["UI"] == "Get Address") {
      if (checkboolean != "AddressSkip" && this.state.text == "") {
        return;
      }
      if (this.state.addressSkip) {
        this.state.list.splice(skipindex, 1);
        skipindex = false;
        this.state.addressSkip = false;
      }
      this.state.AddressDropdown = false;
      if (checkboolean != "AddressSkip") {
        this.state.Address = this.state.Address + this.state.text + " ; ";
      } else {
        this.state.addressField = "";
      }
      this.state.addressArrInd++;
      console.log(
        "process address",
        this.state.addressArrInd,
        this.state.addressArr.length,
        this.state.Address
      );
      if (this.state.addressArrInd >= this.state.addressArr.length) {
        if (checkboolean == "AddressSkip") {
          skipaddresslast = true;
        }
        console.log("process address");
      } else {
        if (checkboolean != "AddressSkip") {
          this.state.list.push(
            <div className="sendMessage flex items-baseline mb-5">
              <div
                className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
                style={{
                  background: this.state.customisation.clientBubbleClr,
                  color: this.state.customisation.clientchattextcolor,
                  fontSize: this.state.customisation.fontSize,
                  fontFamily: this.state.customisation.fontStyle,
                }}
              >
                <p className="text-[#fff]  text-sm leading-6">{this.state.text}</p>
              </div>
              <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
              <img
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  borderWidth: 1,
                  borderRadius: 25,
                }}
                src={this.image_addedon + user}
              />
              </span>
              
            </div>
          );
        }

        return this.setupNode();
      }
    }

    if (steps["" + this.state.cId + ""]["UI"] == "Bot Question") {
      nextcId = steps["" + this.state.cId + ""]["Validation"]["successful"];
      nextcId = steps["" + nextcId + ""]["next"];

      if (
        steps["" + nextcId + ""]["UI"] == "Bot Invoke" ||
        steps["" + nextcId + ""]["UI"] == "Chat Gpt"
      ) {
        if (this.state.text.trim() == "") {
          return;
        }

        if (this.state.exitFlow) {
          this.state.list.pop();
          this.state.exitFlow = false;
        }
        if (skipindex) {
          this.state.list.splice(skipindex, 1);
          skipindex = false;
        }
        // skipindex = this.state.list.length-1
        this.state.list.push(
          <div className="sendMessage flex items-baseline mb-5">
            <div
              className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
              style={{
                background: this.state.customisation.clientBubbleClr,
                color: this.state.customisation.clientchattextcolor,
              }}
            >
             <p className="text-[#fff]  text-sm leading-6">{this.state.text}</p> 
            </div>
            <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
              <img
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  borderWidth: 1,
                  borderRadius: 25,
                }}
                src={this.image_addedon + user}
              />
            </span>
          </div>
        );

        this.state.list.push(
          <div style={{ display: "-webkit-box" }}>
            <img
              style={{ width: 50, height: 50, marginLeft: 10 }}
              src={this.state.customisation.chatBotAvtar}
            />
            <img
              style={{ width: 70, height: 70 }}
              src={this.image_addedon + loader}
            />
          </div>
        );
        this.setState({});

        if (steps["" + nextcId + ""]["UI"] == "Chat Gpt") {
			if (localStorage.getItem("botType")=="36"){
				// this.gptAnswerBook(this.state.text, nextcId)
        this.pcAnswer(this.state.text, nextcId);
			}else{
          this.gptAnswer(this.state.text, nextcId);
			}
          return;
        } else {
          if (this.state.isPaid) {
            this.getAnswerPaid(this.state.text, nextcId);
          } else {
            this.getAnswer(this.state.text, nextcId, false);
          }

          return;
        }
      }
    }
    if (this.state.isOptional && skipindex != false) {
      this.state.list.splice(skipindex, 1);
      skipindex = false;
      // alert(this.state.isOptional + "  " + this.state.isOptionalButton)
      // this.state.list.pop();
      // if (!this.state.isOptionalButton) {
      // 	this.state.isOptional = false;
      // 	this.state.isOptionalButton = false;
      // }
    }
    if (this.state.phoneInput) {
      if (!event == "continue" && this.state.text.trim() == "") return;
      // this.state.text = dialCode + " " + this.state.text;
    }

    if (this.state.btns.length) {
      try {
        this.state.mainScrollHeight = "calc(100% - 120px)";
      } catch (error) {}
      this.state.list.pop();
    }
    if (
      steps["" + this.state.cId + ""]["UI"] == "Food Court" &&
      !checkboolean
    ) {
      return this.setupHotel("initial");
    }

    if (
      steps["" + this.state.cId + ""]["UI"] == "Get Location" ||
      (steps["" + this.state.cId + ""]["UI"] == "Food Court" &&
        this.state.foodEmpty)
    ) {

      console.log("Processing Location");

      // var dataUrl=await fetch("https://maps.googleapis.com/maps/api/staticmap?center=40.714728,-73.998672&zoom=12&format=jpg&size=400x400&key=AIzaSyAMt4qRjSSCXj-XkHiAm2vSp75Wcq14sx0", requestOptions)
      // .then(response => console.log(response))

      // .catch(error => console.log('error', error));
      // console.log(dataUrl,dataUrl,dataUrl)
      this.setState({ showGoogleMaps: false });
      if(this.state.isDual ){

      this.state.text=`Initial location: ${this.state.initial}
      \n Final location: ${this.state.final}`
      
      skipText = true;


            if(this.state.userdistance){
              this.state.text=this.state.text+`\n Total Distance: ${this.state.distance}
              Total Duration: ${this.state.duration}`
            }
            if(this.state.userfair){
              this.state.text=this.state.text+`\n Total Fare is: ${this.state.localCurrency} ${this.getPrice()}`
          }
            }
      this.state.text=""
      this.state.list.push(
        <div className="Box 222">
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              borderWidth: 1,
              borderRadius: 25,
            }}
            src={this.image_addedon + user}
          />
          <div
            className="botc"
            style={{
              backgroundColor: "#0b1538",

              color: "black",
              backgroundColor: "black",
              lineHeight: "12px",

              background: "#0b1538",
              color: this.state.customisation.clientchattextcolor,
            }}
          >
            {this.state.isDual  ? (
              <img
                style={{ width: "150px", borderRadius: "15px 15px 5px 15px" }}
                src={`https://maps.googleapis.com/maps/api/staticmap?size=512x512&maptype=roadmap\&markers=size:mid%7Ccolor:red%7C${
                 document.getElementById("searchInput").value
                },CA%7C${
                  document.getElementById("searchInputt").value
                }&key=${this.state.googleMapsKey}`}
              />
            ) : (
              <img
                style={{ width: "150px", borderRadius: "15px 15px 5px 15px" }}
                src={`https://maps.googleapis.com/maps/api/staticmap?&markers=${
                  document.getElementById("searchInput").value
                }&zoom=12&format=jpg&size=400x400&key=${
                  this.state.googleMapsKey
                }`}
              />
            )}

            {this.state.isDual &&
            this.state.userdistance  &&
            this.state.userfair ? (
              <>
                <p>{`Initial location: ${this.state.initial}`}</p>
                <p>{`Final location: ${this.state.final}`}</p>
                <p>{`Total Distance: ${this.state.distance}`}</p>
                <p>{`Total Duration: ${this.state.duration}`}</p>
                <p>{`Total Fare is: ${this.state.localCurrency} ${this.getPrice()}`}</p>
              </>
            ) : null}

            <br></br>
            <br></br>

            {this.state.userfair == false && this.state.userdistance == true ? (
              <>
                <p>{`Initial location: ${this.state.initial}`}</p>
                <p>{`Final location: ${this.state.final}`}</p>
                <p>{`Total Distance: ${this.state.distance}`}</p>
                <p>{`Total Duration: ${this.state.duration}`}</p>
              </>
            ) : null}

            {this.state.userfair == true && this.state.userdistance == false ? (
              <>              <p>{`Initial location: ${this.state.initial}`}</p>
              <p>{`Final location: ${this.state.final}`}</p>
              <p>{`Total Fare is: ${this.state.localCurrency} ${this.getPrice()}`}</p>
              </>
            ) : null}
            {this.state.userfair == false && this.state.userdistance == false ? (
              <>              <p>{`Initial location: ${this.state.initial}`}</p>
              <p>{`Final location: ${this.state.final}`}</p>
             
              </>
            ) : null}

            {/* <img
              style={{ width: "150px", borderRadius: "15px 15px 5px 15px" }}
              src={ this.state.gMultiple&&this.state.gPosition!="pickup"?
                
                
                `https://maps.googleapis.com/maps/api/staticmap?center=${this.state.gMapLat2},${this.state.gMapLong2}&markers=${this.state.gMapLat2},${this.state.gMapLong2}&zoom=12&format=jpg&size=400x400&key=${this.state.googleMapsKey}`
              :`https://maps.googleapis.com/maps/api/staticmap?center=${this.state.gMapLat},${this.state.gMapLong}&markers=${this.state.gMapLat},${this.state.gMapLong}&zoom=12&format=jpg&size=400x400&key=${this.state.googleMapsKey}`
            }
            /> */}
            {/* <p
              style={{
                margin: 0,
                fontSize: this.state.customisation.fontSize,
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{
                __html:
                  "Coordinates:" +
                  this.state.gMapLat +
                  "," +
                  this.state.gMapLong,
              }}
            /> */}
            {/* {text} */}
          </div>
        </div>
      );
      if (!this.state.foodEmpty) {
        this.state.list.push(
          <div className="BoxBot" style={{ display: "flex" }}>
            <img
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                alignSelf: "flex-end",
                marginLeft: 10,
                borderRadius: 25,
              }}
              src={this.state.customisation.chatBotAvtar}
            />
            <div
              className="bot"
              style={{
                color: this.state.customisation.botTextcolor,
                background: this.state.customisation.messagebubble,
              }}
            >
              {/* {parse(this.checkUrl(hnd.msg))} */}

              <p
                style={{
                  margin: 0,
                  fontSize: this.state.customisation.fontSize,
                  fontFamily: this.state.customisation.fontStyle,
                }}
                dangerouslySetInnerHTML={{
                  __html: this.checkUrl(
                    steps["" + this.state.cId + ""].data.responseText
                  ),
                }}
              />
            </div>
          </div>
        );
        this.setState({});
      }

      //For Food Court
      if (this.state.foodEmpty) {
        return this.setupHotel("initial");
      }
    }

    this.state.mainScrollHeight = `calc(100% - 120px)`;
    this.setState({ btns: [] });

    if (steps["" + this.state.cId + ""]["UI"] == "Condition") {
      this.state.cId = steps["" + this.state.cId + ""]["trigger"][1];
      // if (this.state.bottype == 1){
      // 	this.state.cId = steps["" + this.state.cId + ""]["trigger"][1];
      // 	alert("Tested")
      // }
      // else {
      // 	this.state.cId = steps["" + this.state.cId + ""]["trigger"][0];
      // 	alert("NotTested")
      // }
    }

    if (this.state.isLivechat) {
      this.insertchat(this.state.chatRef, this.state.text, 1);
      this.state.processing = false;
      return;
    }
    if (event != "continue") {
      this.state.isOptionalButton = false;
      this.state.isOptional = false;
    } else {
      this.state.list.push(
        <div style={{ display: "-webkit-box" }}>
          <img
            style={{ width: 50, height: 50, marginLeft: 10 }}
            src={this.state.customisation.chatBotAvtar}
          />
          <img
            style={{ width: 70, height: 70 }}
            src={this.image_addedon + loader}
          />
        </div>
      );
      this.setState({});
    }
    var isNext = true;
    if (steps["" + this.state.cId + ""]["UI"] == "Feedback") {
      this.state.cId = steps["" + this.state.cId + ""]["next"];
    }
    if (steps["" + this.state.cId + ""]["UI"] == "End Message") {
      console.log("EndMessage");
      isNext = false;
      if (localStorage.getItem("botType") == "appointment") {
        LastButtonText = "Book new appointment";
      } else {
        LastButtonText = "Restart Conversation";
      }
      LastButtonText =
        this.checkUrl(steps["" + this.state.cId + ""].data.text).replace(
          /(<([^>]+)>)/gi,
          ""
        ) == "Great! Thanks for the information."
          ? LastButtonText
          : this.checkUrl(steps["" + this.state.cId + ""].data.text).replace(
              /(<([^>]+)>)/gi,
              ""
            );
      LastButtonText =
        `<img src=${
          this.image_addedon + reload
        } style="margin-right: 10px;margin-left: 15px;height:16px;"/>` +
        LastButtonText;
      this.setState({ newAppointment: true });
    }

    console.log(steps["" + this.state.cId + ""], "steps is here");
    //*******checking for button trigger if null terminate******** */

    if (steps["" + this.state.cId + ""]["trigger"].length == 0) {
      if (
        !skipaddresslast &&
        this.state.text == "" &&
        event != "continue" &&
        !["Food Court", "Get Location"].includes(steps["" + this.state.cId].UI)
      ) {
        //returning because event is not continue hence text must not be blank.!!
        console.log("steps is here jump");
        this.state.processing = false;
        return;
      }
      //**checking validation to check data types email and num etc******* */
      console.log(this.state.isOptional, "Phone", this.state.isOptionalButton);
      if (steps["" + this.state.cId + ""]["Validation"]["doValidate"]) {
        var type = steps["" + this.state.cId + ""]["Validation"]["type"];
        if (
          this.state.isOptional ||
          this.state.isOptionalButton ||
          skipaddresslast
        ) {
          this.state.isOptional = false;
          this.state.isOptionalButton = false;
          isNext = false;
          this.state.phoneInput = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
          if (!skipaddresslast) {
            this.state.cId = steps["" + this.state.cId + ""]["next"];
          }

          //this.state.cId = steps["" + this.state.cId + ""]["next"];
          console.log("------------------ opoiiiii");
        } else if (type == "Email") {
          isNext = false;
          if (!EmailValidator.validate(this.state.text)) {
            this.setState({ text: "" });
            this.state.cId =
              steps["" + this.state.cId + ""]["Validation"]["unsuccessful"];
          } else {
            this.state.Email = this.state.text.toLowerCase();
            this.state.text = this.state.text.toLowerCase();
            document.cookie = "EmailId=" + this.state.text + ";path=/";
            //this.updateTodb();
            this.state.cId =
              steps["" + this.state.cId + ""]["Validation"]["successful"];
          }
        } else if (type == "Phone") {
          // isNext = false;
          // var num = this.state.text.split(" ")[1];
          // if (!isNaN(num)) {
          // 	this.state.Phone = this.state.text;
          // 	this.state.phoneInput = false;
          // 	this.state.cId =
          // 		steps["" + this.state.cId + ""]["Validation"]["successful"];
          // } else {
          // 	this.setState({ text: "" });
          // 	this.state.cId =
          // 		steps["" + this.state.cId + ""]["Validation"]["unsuccessful"];
          // }
          var actnum = this.state.text;
          actnum = actnum.replace(/\s|-|\(|\)/g, "");
          this.state.text =
            this.state.phonecodeText.split(" (")[0] + " " + this.state.text;
          console.log(actnum, "Checknum");
          if (!isNaN(actnum)) {
            // if (PhoneValidator(text.replace(/\s|-|\(|\)/g, "").replace(" ",""), "", false).length>0) {
            //     Phone = text;
            //     this.state.phoneInput = false;
            //    this.state.cId = steps['' +this.state.cId + '']['Validation']['successful'];
            // } else {
            //    this.state.cId = steps['' +this.state.cId + '']['Validation']['unsuccessful'];
            // }

            if (checkphoneprefix[this.state.phonecodeText.split(" (")[0]]) {
              if (
                checkphoneprefix[
                  this.state.phonecodeText.split(" (")[0]
                ].includes(actnum.toString()[0])
              ) {
                if (
                  checkphonelength[this.state.phonecodeText.split(" (")[0]] &&
                  checkphonelength[this.state.phonecodeText.split(" (")[0]]
                    .MaxLen &&
                  checkphonelength[this.state.phonecodeText.split(" (")[0]]
                    .MinLen
                ) {
                  if (
                    actnum.toString().length >=
                      checkphonelength[this.state.phonecodeText.split(" (")[0]]
                        .MinLen &&
                    actnum.toString().length <=
                      checkphonelength[this.state.phonecodeText.split(" (")[0]]
                        .MaxLen
                  ) {
                    this.state.Phone = this.state.text;
                    this.state.phoneInput = false;
                    this.state.cId =
                      steps["" + this.state.cId + ""]["Validation"][
                        "successful"
                      ];
                  } else {
                    this.state.cId =
                      steps["" + this.state.cId + ""]["Validation"][
                        "unsuccessful"
                      ];
                  }
                } else {
                  if (
                    actnum.toString().length >= 4 &&
                    actnum.toString().length <= 17
                  ) {
                    this.state.Phone = this.state.text;
                    this.state.phoneInput = false;
                    this.state.cId =
                      steps["" + this.state.cId + ""]["Validation"][
                        "successful"
                      ];
                  } else {
                    this.state.cId =
                      steps["" + this.state.cId + ""]["Validation"][
                        "unsuccessful"
                      ];
                  }
                }
              } else {
                this.state.cId =
                  steps["" + this.state.cId + ""]["Validation"]["unsuccessful"];
              }
            } else {
              if (
                checkphonelength[this.state.phonecodeText.split(" (")[0]] &&
                checkphonelength[this.state.phonecodeText.split(" (")[0]]
                  .MaxLen &&
                checkphonelength[this.state.phonecodeText.split(" (")[0]].MinLen
              ) {
                if (
                  actnum.toString().length >=
                    checkphonelength[this.state.phonecodeText.split(" (")[0]]
                      .MinLen &&
                  actnum.toString().length <=
                    checkphonelength[this.state.phonecodeText.split(" (")[0]]
                      .MaxLen
                ) {
                  this.state.Phone = this.state.text;
                  this.state.phoneInput = false;
                  this.state.cId =
                    steps["" + this.state.cId + ""]["Validation"]["successful"];
                } else {
                  this.state.cId =
                    steps["" + this.state.cId + ""]["Validation"][
                      "unsuccessful"
                    ];
                }
              } else {
                if (
                  actnum.toString().length >= 4 &&
                  actnum.toString().length <= 17
                ) {
                  this.state.Phone = this.state.text;
                  this.state.phoneInput = false;
                  this.state.cId =
                    steps["" + this.state.cId + ""]["Validation"]["successful"];
                } else {
                  this.state.cId =
                    steps["" + this.state.cId + ""]["Validation"][
                      "unsuccessful"
                    ];
                }
              }
            }
          } else {
            this.state.cId =
              steps["" + this.state.cId + ""]["Validation"]["unsuccessful"];
          }
          // if (!isNaN(actnum)) {
          // 	// if (PhoneValidator(this.state.text.replace(/\s|-|\(|\)/g, "").replace(" ",""), "", false).length>0) {
          //     //     this.state.Phone = this.state.text;
          //     //     this.state.phoneInput = false;
          //     //     this.state.cId = steps['' + this.state.cId + '']['Validation']['successful'];
          //     // } else {
          //     //     this.state.cId = steps['' + this.state.cId + '']['Validation']['unsuccessful'];
          //     // }
          // 	if (checkphonelength[this.state.phonecodeText.split(" (")[0]].MaxLen && checkphonelength[this.state.phonecodeText.split(" (")[0]].MinLen) {
          // 		if (actnum.toString().length >= checkphonelength[this.state.phonecodeText.split(" (")[0]].MinLen && actnum.toString().length <= checkphonelength[this.state.phonecodeText.split(" (")[0]].MaxLen) {
          // 			this.state.Phone = this.state.text;
          // 			this.state.phoneInput = false;
          // 			this.state.cId = steps['' + this.state.cId + '']['Validation']['successful'];
          // 		}
          // 		else {
          // 			this.state.cId = steps['' + this.state.cId + '']['Validation']['unsuccessful'];
          // 		}
          // 	}
          // 	else {
          // 		if (actnum.toString().length >= 4 && actnum.toString().length <= 17) {
          // 			this.state.Phone = this.state.text;
          // 			this.state.phoneInput = false;
          // 			this.state.cId = steps['' + this.state.cId + '']['Validation']['successful'];
          // 		}
          // 		else {
          // 			this.state.cId = steps['' + this.state.cId + '']['Validation']['unsuccessful'];
          // 		}
          // 	}
          // }
          // else {
          // 	this.state.cId = steps['' + this.state.cId + '']['Validation']['unsuccessful'];
          // }
          isNext = false;
        } else if (type == "Name") {
          //this.updateTodb();
          this.state.Name = this.state.text;
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
        } else if (type == "cards") {
          console.log(
            "-------->>>>>>>>>>cardssssssss@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
          );
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
          console.log(this.state.cId, "-------->>>>>>>>>>>>>>>>>cards");
        } else if (type == "follow us") {
          console.log(
            "-------->>>>>>>>>>followwwwwwwwwwwwww@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
          );
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
          console.log(this.state.cId, "-----************thisstateid");
        } else if (type == "payment") {
          console.log(
            "-------->>>>>>>>>>followers@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
          );
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
          console.log(this.state.cId, "-----************thisstateid");
        } else if (type == "country") {
          this.state.UserCountry = this.state.text;
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
        } else if (type == "date") {
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
        } else if (type == "currency") {
          this.state.currencyPicker = false;
          this.state.text =
            this.state.currencyText.split(" ")[0] + " " + this.state.text;
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
        } else if (!checkboolean) {
          isNext = false;
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
        } else {
          isNext = false;
        }
      }

     

      //**********************once input is validated taking action******************* */

      //going to next step
      console.log("NEXT HERE1", isNext);
      if (isNext) {
        if (steps["" + this.state.cId + ""]["UI"] == "Bot Question") {
          this.state.cId =
            steps["" + this.state.cId + ""]["Validation"]["successful"];
        } else {
          console.log("NEXT HERE");
          this.state.cId = steps["" + this.state.cId + ""]["next"];
        }
      }
    } else {
      if (steps["" + this.state.cId + ""]["UI"] == "Gender") {
        steps["" + this.state.cId + ""]["trigger"] = [];
      }
      if (event == "continue") {
        // alert("hi")
        this.state.cId = checkboolean;
      } else if (checkboolean == "multiple") {
        this.state.cId = steps["" + this.state.cId + ""]["trigger"][0].next;
        // for (var i = 0; i < event.length; i++) {
        // 	this.state.text = this.state.text + event[i] + ","
        // }
        for (var i = 0; i < event.length; i++) {
          // this.state.text = this.state.text + "•" + event[i] + "<br>"aWd
          this.state.text =
            this.state.text +
            "<li style='text-indent: -18px; padding-left: 15px'>" +
            event[i] +
            "</li>";
        }
        this.state.text = this.state.text.slice(0, -1);
      } else if (steps["" + this.state.cId + ""]["UI"] == "Cards") {
        console.log("-->dd");
        if (event.url !== "") {
          this.state.cId = event.next;
          this.state.text = event.url;
        } else if (event.contactnumber !== "") {
          this.state.cId = event.next;
          this.state.text = event.contactnumber;
        } else {
          this.state.cId = event.next;
          this.state.text = event.name;
        }
      } else {
        this.state.cId = event.next;
        this.state.text = event.name;
        // if (event.url !== '') {
        // 	this.state.cId = event.next;
        // 	this.state.text = event.url;
        // } else if (event.contactnumber !== '') {
        // 	this.state.cId = event.next;
        // 	this.state.text = event.contactnumber;
        // } else {
        // 	this.state.cId = event.next;
        // 	this.state.text = event.name;
        // }
      }
    }

    console.log(
      this.state.cId,
      steps["" + this.state.cId + ""],
      "Check for UI"
    );

    try {
      if (steps["" + this.state.cId + ""]["UI"] == "Get Webinar Link") {
        this.state.cId = steps["" + this.state.cId + ""]["next"];
      }
    } catch (error) {}

    try {
      if (steps["" + this.state.cId + ""]["Validation"]["type"] == "Phone") {
        this.state.phoneInput = true;
      }
      if (steps["" + this.state.cId + ""]["Validation"]["type"] == "currency") {
        this.state.currencyPicker = true;
      }
    } catch (error) {}

    //***********till here we have validated input and taken action now we are actually showing tha chat which client entered*********** */

    console.log("text check kro", steps["" + this.state.cId + ""]);
    if (steps["" + this.state.cId + ""]["UI"] == "Condition") {
      // this.onSendButtonPress(steps['' + this.state.cId + '']['trigger'][0])
      this.state.cId = steps["" + this.state.cId + ""]["trigger"][1]["next"];
      // this.state.bottype=1
      // if (this.state.bottype == 1){
      // 	this.state.cId = steps["" + this.state.cId + ""]["trigger"][1]["next"];
      // 	alert("Tested1")
      // 	alert(this.state.bottype)
      // }
      // else {
      // 	this.state.cId = steps["" + this.state.cId + ""]["trigger"][0]["next"];
      // 	alert("NotTested2")
      // 	alert(this.state.bottype)
      // }
      // this.state.cId = steps["" + this.state.cId + ""]["trigger"][0]["next"];
      console.log("next is here", this.state.cId);
    }
    if (event != "continue" && !skipaddresslast) {
      if (false) {
        var message = this.state.text.split("@")[1];
        this.state.text = this.state.text.replace("@", "");
        this.state.list.push(
          <div className="sendMessage flex items-baseline mb-5">
            <div
              className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
              style={{
                background: this.state.customisation.clientBubbleClr,
                color: this.state.customisation.clientchattextcolor,
              }}
            >
              <p className="text-[#fff]  text-sm leading-6">
                {this.state.text}
              </p>
            </div>
            <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
            <img
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                alignSelf: "flex-end",
                borderWidth: 1,
                borderRadius: 25,
              }}
              src={this.image_addedon + user}
            />
            </span>
          </div>
        );
        this.state.rList.push({
          text: this.state.text,
          sender: "client",
          cId: this.state.cId,
        });

        // this.state.list.push(
        // 	<div style={{ display: "flex" }}>
        // 		<img
        // 			style={{
        // 				width: 40,
        // 				height: 40,
        // 				marginRight: 10,
        // 				alignSelf: "flex-end",
        // 				marginLeft: 10,
        // 				borderRadius: 25,
        // 			}}
        // 			src={this.state.customisation.chatBotAvtar}
        // 		/>
        // 		<div
        // 			className="bot"
        // 			style={{
        // 				color: this.state.customisation.botTextcolor,
        // 				background: this.state.customisation.messagebubble,
        // 			}}
        // 		>
        // 			{this.state.text}
        // 		</div>
        // 	</div>
        // );

        // this.state.rList.push({
        // 	text: this.state.text,
        // 	sender: "bot",
        // 	cId: this.state.cId,
        // 	continue: steps["" + this.state.cId + ""]["continue"],
        // });

        this.state.list.push(
          <div style={{ display: "-webkit-box" }}>
            <img
              style={{ width: 50, height: 50, marginLeft: 10 }}
              src={this.state.customisation.chatBotAvtar}
            />
            <img
              style={{ width: 70, height: 70 }}
              src={this.image_addedon + loader}
            />
          </div>
        );
        this.setState({});
      } else {
        if (this.state.text.includes(".svg")) {
          this.state.list.push(
            <div className="sendMessage flex items-baseline mb-5">
              
              <div
                className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
                style={{
                  background: this.state.customisation.clientBubbleClr,
                  color: this.state.customisation.clientchattextcolor,
                }}
              >
                {/* <img style={{ width: 25, height: 25 }} src={this.state.text} /> */}
              </div>
              <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 10,
                    alignSelf: "flex-end",
                    borderWidth: 1,
                    borderRadius: 25,
                  }}
                  src={this.image_addedon + user}
                />
              </span>
            </div>
          );
        } else {
          if (!skipnext && this.state.text != "") {
            this.state.list.push(
              <div className="sendMessage flex items-baseline mb-5">
                 <div
                  className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
                  style={{
                    background: this.state.customisation.clientBubbleClr,
                    color: this.state.customisation.clientchattextcolor,
                  }}
                >
                  <p className="text-[#fff]  text-sm leading-6"
                    style={{
                      margin: 0,
                      fontSize: this.state.customisation.fontSize,
                      ontFamily: this.state.customisation.fontStyle,
                    }}
                    dangerouslySetInnerHTML={{ __html: this.state.text }}
                  />
                </div>
              <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
                  <img
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    src={this.image_addedon + user}
                  />
                </span>
               
              </div>
            );
          } else {
            skipnext = false;
          }
        }
        this.state.rList.push({
          text: this.state.text,
          sender: "client",
          cId: this.state.cId,
        });

        this.state.list.push(
          <div style={{ display: "-webkit-box" }}>
            <img
              style={{ width: 50, height: 50, marginLeft: 10 }}
              src={this.state.customisation.chatBotAvtar}
            />
            <img
              style={{ width: 70, height: 70 }}
              src={this.image_addedon + loader}
            />
          </div>
        );
        this.setState({});
        // console.log(rList, list, "arrays");
      }

      // this.state.List_allMsg.push({ agent_id: null });
      // this.state.list.push(<div className="Box">
      //   <img style={{ width: 40, height: 40, marginRight: 10, alignSelf: 'flex-end', borderWidth: 1, borderRadius: 25 }} src={this.image_addedon + user} />
      //   <div className="botc" style={{ background: this.state.customisation.clientBubbleClr, color: this.state.customisation.clientchattextcolor }}>
      //     {this.state.text}
      //   </div>
      // </div>);
      // this.state.rList.push({ text: this.state.text, sender: 'client',this.state.cId: this.state.cId });
      // //this.updateTodb();
      // //this.state.List_allMsg.push({agent_id:null});
      // this.state.list.push(<div style={{ display: '-webkit-box' }}>
      //   <img style={{ width: 50, height: 50, marginLeft: 10 }} src={this.state.customisation.chatBotAvtar} />
      //   <img style={{ width: 70, height: 70 }} src={this.image_addedon + loader} />
      // </div>);
    }

    // if (steps["" + this.state.cId + ""]["UI"] == "Handoffs") {
    // 	this.state.cId = steps["" + this.state.cId + ""]["next"];
    // }

    // if (steps["" + this.state.cId + ""]["action"] == "SaveInquiry") {
    // 	this.state.list.pop();
    // 	this.state.processing = false;
    // 	this.setState({
    // 		a_showFeedback: "hidden",
    // 		newAppointment: true,
    // 		showfooter: "none",
    // 	});
    // 	return;
    // }

    if (steps["" + this.state.cId + ""]["UI"] == "Invoke bot") {
      // new bot
      var inqbot = steps["" + this.state.cId + ""]["data"]["select_bot"];

      // alert(this.state.companyID + "  " + this.state.botId + "  " + this.state.agentId)
      var docRef = db
        .collection("user_" + this.state.companyID)
        .doc(this.state.agentId)
        .collection("chatbot")
        .doc(inqbot);
      docRef.get().then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
          steps = doc.data().template;
          this.state.botType = doc.data().bot_details.bot_template_category;
          if (localStorage.getItem("botType") == "appointment") {
            LastButtonText = "Book new appointment";
          } else {
            LastButtonText = "Restart Conversation";
          }
          this.state.cId = steps["0"]["next"];
          this.onSendButtonPress();
        }
      });
    }

    setTimeout(() => {
      console.log("------Yess---------rrrr------vikas", event);

      this.state.list.pop();
      this.setupNode();
    }, 1000);

    this.setState({ text: "" });
  }






































  async setupNode() {
    var t = steps["" + this.state.cId + ""]["data"]["text"].replace(
      "///>>>name<<<///",
      this.state.Name
    );

    if (steps["" + this.state.cId + ""]["UI"] == "Send Media") {
      console.log(
        "sendmediaaaaaaaaa---------------------------------------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
      );
      this.state.sendImageUrl = steps["" + this.state.cId + ""]["data"]["url"];
      this.state.sendImage = true;
    } else if (steps["" + this.state.cId + ""]["UI"] == "Bot Message") {
      if (
        steps["" + prevcId + ""]["UI"] == "Cards" ||
        steps["" + prevcId + ""]["UI"] == "Follow us" ||
        steps["" + prevcId + ""]["UI"] == "Currency" ||
        steps["" + prevcId + ""]["UI"] == "Get Country" ||
        steps["" + prevcId + ""]["UI"] == "Date Selector" ||
        steps["" + prevcId + ""]["UI"] == "Get Name" ||
        steps["" + prevcId + ""]["UI"] == "Get Email" ||
        steps["" + prevcId + ""]["UI"] == "Get Phone" ||
        steps["" + prevcId + ""]["UI"] == "Date Picker" ||
        steps["" + prevcId + ""]["UI"] == "Get Address"
      ) {
        t = steps["" + prevcId + ""]["data"]["responseText"]
          ? steps["" + prevcId + ""]["data"]["responseText"].replace(
              "///>>>name<<<///",
              this.state.Name
            )
          : t;
      }
    }
    // if(steps[''+this.state.cId+'']['UI']=="Get Phone") {
    // 	console.log("GETPHONE",this.state.cId,prevcId,steps[''+prevcId+''])
    // 	if (steps[''+prevcId+'']['UI']=="Get Phone") {
    // 		t=steps['' + prevcId + '']['data']['unsuccessful']?steps['' + prevcId + '']['data'].unsuccessful.failedAttemptsText:t
    // 	}
    // }
    if (steps["" + this.state.cId + ""]["UI"] == "Get Address") {
      // if (this.state.addressArrInd==0){
      //     this.state.list.push(<div style={{ display: 'flex' }}>
      //     <img style={{ width: 40, height: 40, marginRight: 10, alignSelf: 'flex-end', marginLeft: 10, borderRadius: 25 }} src={this.state.customisation.chatBotAvtar} />
      //     <div className="bot" style={{ color: this.state.customisation.botTextcolor, background: this.state.customisation.messagebubble }}>
      //         {/* {parse(this.checkUrl(hnd.msg))} */}

      //         <p style={{
      //             margin: 0, fontSize: this.state.customisation.fontSize,
      //             fontFamily: this.state.customisation.fontStyle
      //         }} dangerouslySetInnerHTML={{ __html: this.checkUrl(t) }} />
      //     </div>
      // </div>)
      // }
      if (this.state.addressArrInd != undefined) {
        t = this.state.addressArr[this.state.addressArrInd].question;
        this.state.addressSkip =
          !this.state.addressArr[this.state.addressArrInd].isMandatory;
        console.log(t, this.state.addressSkip, "ADDRESSSKIP");
      }
    } else if (
      steps["" + prevcId + ""]["UI"] == "Request Callback" &&
      steps["" + prevcId + ""].data.isCalendly == false
    ) {
      t = "";
    }
    if (steps["" + this.state.cId + ""]["UI"] == "Food Court") {
      console.log("FOODCOURT");
      return this.setupHotel("initial");
    }
    if (steps["" + this.state.cId + ""]["action"] == "SaveInquiry") {
      if (this.state.botType == 2) {
        this.saveFormData();
        this.state.isLivechat = true;
        setTimeout(() => {
          this.state.processing = false;
          this.state.rList.push({
            cId: "",
            sender: "livechat_start",
            text: "You are now being transferred to live agent",
          });
          this.state.List_allMsg.push({ agent_id: null });
          this.state.list.push(
            <div
              style={{
                marginTop: 12,
                marginBottom: 3,
                textAlign: "center",
                fontSize: 12,
              }}
            >
              You are now being transferred to live agent
            </div>
          );
          //this.convert_visitor_to_livechat();
          this.transferLivechat(this.state.Email, 1);
          console.log(this.state.rList);
          this.setState({});
          return;
        }, 100);
      }
    } else if (steps["" + this.state.cId + ""]["action"] == "Akash") {
    }
    if (steps["" + this.state.cId + ""]["UI"] == "Get Phone") {
      console.log(t, "ttttttt1", this.state.cId, prevcId);
      if (steps["" + prevcId + ""]["UI"] == "Get Phone") {
        if (
          steps["" + prevcId + ""]["data"]["unsuccessful"] &&
          steps["" + prevcId + ""]["data"].unsuccessful.failedAttemptsText
        ) {
          t = steps["" + prevcId + ""]["data"].unsuccessful.failedAttemptsText;
        }
        //   t = steps["" + prevcId + ""]["data"]["unsuccessful"]
        // 	&& steps["" + prevcId + ""]["data"].unsuccessful.failedAttemptsText
        // 	  ? steps["" + prevcId + ""]["data"].unsuccessful.failedAttemptsText

        // 	: t;
      }
      console.log(t, "ttttttt2");
    }
    this.state.List_allMsg.push({ agent_id: null });
    console.log(t, "ttttttt");
    t = this.checkUrl(t, "bot");
    t = t.replace("{{Name}}", this.state.Name);
    t = t.replace("{{bot_name}}", this.state.botName);
    t = t.replace("{{date and time}}", bookedAppointmentTime);
    // t.replace(/\s/g, "&nbsp;");
    // t = t.replace(/[ç]/g, "<")
    // t = t.replace('\\n\g', '<br/>');
    console.log("----------rawat------->>", steps["" + this.state.cId + ""], t);
    if (this.state.sendImage) {
      if (this.state.sendImageUrl) {
        const downUrl=this.state.sendImageUrl
        this.state.list.push(
          <div className="BoxBot" style={{ display: "flex" }}>
            <img
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                alignSelf: "flex-end",
                marginLeft: 10,
                borderRadius: "15px 15px 15px 5px",
              }}
              src={this.state.customisation.chatBotAvtar}
            />
            {/* <div
				  className="bot"
				  style={{
					color: this.state.customisation.botTextcolor,
					background: this.state.customisation.messagebubble,
				  }}
				> */}
            <>
            {this.state.sendImageUrl.toLowerCase().includes(".jpg")||this.state.sendImageUrl.toLowerCase().includes(".jpeg")||this.state.sendImageUrl.toLowerCase().includes(".png")||this.state.sendImageUrl.toLowerCase().includes(".mp4")||this.state.sendImageUrl.toLowerCase().includes(".mkv")||this.state.sendImageUrl.toLowerCase().includes(".heic")?(

              <img
                style={{ width: "150px", borderRadius: "15px 15px 15px 5px" }}
                src={this.state.sendImageUrl}
              />):
              (<div
                  onClick={()=>{window.open( downUrl,"_blank")}}
                  className="fileFormatDClient"
                >
                  <span className="fileFormatName">{}</span>
                  <span className="fileFormatSize">{}</span>
                  <span className="fileFormatDownload">Download</span>
                  <span className="whichFile">
                    <span className="whichFileName">File</span>
                  </span>
                </div>)}
            </>
            {/* <iframe style={{maxWidth: "100%"}} src="https://www.youtube.com/embed/T4-rIvTEe3g" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
            {/* </div> */}
          </div>
        );
        this.state.sendImage = false;
        this.state.sendImageUrl = "";
      }
      this.state.sendImage = false;
        this.state.sendImageUrl = "";
    } else if (
      steps["" + this.state.cId + ""]["UI"] != "Feedback" &&
      steps["" + this.state.cId + ""]["UI"] != "Handoffs" &&
      steps["" + this.state.cId + ""]["UI"] != "End Message"
    ) {
      if (t.trim() != "") {
        this.state.list.push(
          <div className="recivedMessage flex mb-5" style={{ display: "flex", marginBottom: 5 }}>
            <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-2">
              <img
                className="NOT_FOUND"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  marginLeft: 10,
                  borderRadius: 25,
                }}
                src={this.state.customisation.chatBotAvtar}
              />
            </span>
            
            <div
              className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
              style={{
                color: this.state.customisation.botTextcolor,
                background: this.state.customisation.messagebubble,
              }}
            >
              <p className="text-[#272727]  text-sm leading-6" dangerouslySetInnerHTML={{ __html: t }} />
            </div>
          </div>
        );
      }
      if (localStorage.getItem("botType") == "answer") {
        if (this.state.cId == 1) {
          // Bot personality
          setTimeout(() => {
            let paramarray = window.location.href;
            let answer_type = localStorage.getItem("answer_type");
            if (answer_type != "none" || answer_type == undefined) {
              if (
                paramarray.includes("botpersonality") ||
                paramarray.includes("editor")
              ) {
                switch (answer_type) {
                  case "professional":
                    this.state.text = "How old are you?";
                    break;
                  case "friendly":
                    this.state.text = "How old are you?";
                    break;
                  case "witty":
                    this.state.text = "Who is your boss?";
                    break;
                  case "caring":
                    this.state.text = "Why don't you ask me questions?";
                    break;
                  case "enthusiastic":
                    this.state.text = "Can you ask questions?";
                    break;
                }
                this.setState();
              }
              this.onSendButtonPress();
              return;
            }
          }, 700);
        }
      }
    }
    this.state.processing = false;
    this.state.rList.push({
      text: t,
      sender: "bot",
      cId: this.state.cId,
      continue: steps["" + this.state.cId + ""]["continue"],
    });
    //this.updateTodb();
    console.log(t, "texttttdhfhdfhdhhd");
    this.setState({ text: "" });
    this.state.optionalPlaceholder = "Type a message";
    if (steps["" + this.state.cId + ""]["data"]["isOptional"]) {
      console.log(steps["" + this.state.cId + ""]["data"]["isOptional"]);

      this.state.isOptional = true;
      this.state.isOptionalButton = true;
      skipindex = this.state.list.length;

      this.state.optionalPlaceholder = steps["" + this.state.cId + ""]["data"]
        .optionPlaceholder
        ? steps["" + this.state.cId + ""]["data"].optionPlaceholder
        : "Type a message";
      var optionalSuggestiontext = steps["" + this.state.cId + ""]["data"]
        .optionSuggestion
        ? steps["" + this.state.cId + ""]["data"].optionSuggestion
        : "You skip this question by clicking on";
      var skipTipIcon = steps["" + this.state.cId + ""]["data"].tipIcon
        ? steps["" + this.state.cId + ""]["data"].tipIcon
        : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f";
      this.state.skipIcon = steps["" + this.state.cId + ""]["data"].skipIcon
        ? steps["" + this.state.cId + ""]["data"].skipIcon
        : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556";

      this.state.list.push(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 12,
            marginBottom: 3,
            alignItems: "center",
            fontSize: 9,
          }}
        >
          <img
            style={{ width: 12, height: 12, marginRight: 5 }}
            src={skipTipIcon}
          />
          <p
            style={{
              margin: 0,
              fontSize: "8px !important",
              wordBreak: "break-word",
              maxWidth: "65%",
              textAlign: "center",
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{
              __html: this.checkUrl(optionalSuggestiontext, "bot"),
            }}
          />
          <img
            style={{ width: 10, height: 10, marginLeft: 5, cursor: "pointer" }}
            src={this.state.skipIcon}
            onClick={() => this.skipQuestion()}
          />
        </div>
      );
    }
    if (this.state.addressSkip) {
      skipindex = this.state.list.length;
      this.state.list.push(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 12,
            marginBottom: 3,
            alignItems: "center",
            fontSize: 9,
          }}
        >
          <img
            style={{ width: 12, height: 12, marginRight: 5 }}
            src={
              "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f"
            }
          />{" "}
          {
            <p
              style={{
                margin: 0,
                fontSize: "8px !important",
                wordBreak: "break-word",
                maxWidth: "65%",
                textAlign: "center",
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{
                __html: this.checkUrl(
                  "You skip this question by clicking on",
                  "bot"
                ),
              }}
            />
          }{" "}
          <img
            style={{ width: 10, height: 10, marginLeft: 5, cursor: "pointer" }}
            src={
              "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556"
            }
            onClick={() => this.onSendButtonPress("AddressSkip", "AddressSkip")}
          />
        </div>
      );
    }

    if (steps["" + this.state.cId + ""]["continue"] ||steps["" + this.state.cId + ""]['UI']=="Send Media") {
      this.onSendButtonPress("continue");
    } else if (steps["" + this.state.cId + ""]["UI"] == "Get Location") {
      // var cardListt = steps["" + this.state.cId + ""]["data"];
      //  list.pop()
      console.log("GET LOCAtion");
      //  processing = true;
      ;
      this.setupHotel("gMap");
    } else {
      //input goes here prepare here for input if there are input like date time country etc implement here.
      if (steps["" + this.state.cId + ""]["trigger"].length == 0) {
        this.state.btns = [];
        this.state.buttonType = "";
      } else {
        this.state.btns = [];
        multstr = [];

        this.state.mainScrollHeight = "calc(100% - 75px)";
        var len = steps["" + this.state.cId + ""]["trigger"].length;
        if (
          steps["" + this.state.cId + ""]["data"]["selected_type"] &&
          steps["" + this.state.cId + ""]["data"]["selected_type"].value ==
            "multiple"
        ) {
          this.setState({ buttonType: "multiple" });
          this.state.mainScrollHeight = "calc(100% - 100px)";
          var s_view = document.getElementById("PreviewChatScroller_dheeraj");
          s_view.style.height = "calc(100% - 150px)";
        } else {
          this.setState({ buttonType: "single" });
        }
        if (steps["" + this.state.cId + ""]["trigger"].length == 2) {
          //qwerqwdwef
          if (
            steps["" + this.state.cId + ""]["trigger"][0].name.length > 14 ||
            steps["" + this.state.cId + ""]["trigger"][1].name.length > 14
          ) {
            this.state.horizontalButtons = false;
            steps["" + this.state.cId + ""]["trigger"].forEach(
              (element, index) => {
                this.state.btns.push(
                  <button
                    className={
                      this.state.buttonType == "multiple"
                        ? "buttonStyleV"
                        : "buttonStyleV"
                    }
                    style={{
                      borderColor: this.state.customisation.botButtonColor,
                      color: this.state.customisation.botButtonColor,
                      fontSize: this.state.customisation.fontSize,
                      fontFamily: this.state.customisation.fontStyle,
                    }}
                    onClick={() => this.buttonfunction(element, index)}
                  >
                    {this.state.buttonType == "multiple" ? (
                      <input
                        className="checkboxButton"
                        id={`checkbox${index}`}
                        type="checkbox"
                      />
                    ) : null}
                    <span
                      className={
                        this.state.buttonType == "multiple"
                          ? "checkButtonText"
                          : ""
                      }
                    >
                      <p
                        style={{ margin: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: this.checkUrl(element.name),
                        }}
                      />
                    </span>
                  </button>
                );
              }
            );

            // this.state.list.push(<div
            // 	id="BottomButtons"
            // 	className="appointment-yes-no-btn-div"
            // 	style={{
            // 		bottom: this.state.buttonBottomSpace,
            // 		position: "relative",
            // 		display: this.state.horizontalButtons
            // 			? "inline-flex"
            // 			: "grid",
            // 		textAlign: "center",
            // 		padding: "0px 0px",
            // 		background: "#fff",
            // 		maxHeight: 195,
            // 		overflowY: "scroll",
            // 	}}
            // >
            // 	{this.state.btns}
            // </div>);
          } else {
            this.state.horizontalButtons = true;
            steps["" + this.state.cId + ""]["trigger"].forEach(
              (element, index) => {
                this.state.btns.push(
                  <button
                    className={
                      this.state.buttonType == "multiple"
                        ? "buttonStyleV"
                        : "buttonStyleV"
                    }
                    style={{
                      borderColor: this.state.customisation.botButtonColor,
                      color: this.state.customisation.botButtonColor,
                      fontSize: this.state.customisation.buttonfontSize + "px",
                      fontFamily: this.state.customisation.buttonfontStyle,
                    }}
                    onClick={() => this.buttonfunction(element, index)}
                  >
                    {this.state.buttonType == "multiple" ? (
                      <input
                        className="checkboxButton"
                        id={`checkbox${index}`}
                        type="checkbox"
                      />
                    ) : null}
                    <span
                      className={
                        this.state.buttonType == "multiple"
                          ? "checkButtonText"
                          : ""
                      }
                    >
                      <p
                        style={{ margin: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: this.checkUrl(element.name),
                        }}
                      />
                    </span>
                  </button>
                );
              }
            );
          }
        } else {
          this.state.horizontalButtons = false;
          steps["" + this.state.cId + ""]["trigger"].forEach(
            (element, index) => {
              this.state.btns.push(
                <button
                  className={
                    this.state.buttonType == "multiple"
                      ? "buttonStyleV"
                      : "buttonStyleV"
                  }
                  style={{
                    borderColor: this.state.customisation.botButtonColor,
                    color: this.state.customisation.botButtonColor,
                    fontSize: this.state.customisation.buttonfontSize + "px",
                    fontFamily: this.state.customisation.buttonfontStyle,
                  }}
                  onClick={() => this.buttonfunction(element, index)}
                >
                  {this.state.buttonType == "multiple" ? (
                    <input
                      className="checkboxButton"
                      id={`checkbox${index}`}
                      type="checkbox"
                    />
                  ) : null}
                  <span
                    className={
                      this.state.buttonType == "multiple"
                        ? "checkButtonText"
                        : ""
                    }
                  >
                    <p
                      style={{ margin: 0 }}
                      dangerouslySetInnerHTML={{
                        __html: this.checkUrl(element.name),
                      }}
                    />
                  </span>
                </button>
              );
            }
          );
        }

        this.state.list.push(
          <div
            id="bottomButtons"
            className="appointment-yes-no-btn-div"
            style={{
              position: "relative",
              display: this.state.horizontalButtons ? "inline-flex" : "grid",
              textAlign: "center",
              padding: "0px 0px",
              background: "transparent",
              maxHeight: 250,
              overflowY: "scroll",
              marginTop: 10,
              marginBottom: this.state.buttonType == "multiple" ? 40 : 10,
            }}
          >
            {/* {this.state.mainScrollHeight = "calc(100% - 50px)"} */}
            {/* {this.state.btns} */}
            {steps["" + this.state.cId + ""]["UI"] == "Button"
              ? this.state.btns
              : null}
          </div>
        );

        // setTimeout(() => {
        // 	try {
        // 		console.log(
        // 			"juicyccc test 3000",
        // 			document.getElementById("BottomButtons").clientHeight,
        // 			document.getElementById("BottomButtons").style.height,
        // 			document.getElementById("BottomButtons").offsetHeight
        // 		);

        // 		var clientHeight = document.getElementById("BottomButtons")
        // 			.clientHeight;
        // 		this.state.mainScrollHeight = `calc(100% - 120px)`;
        // 		if (this.state.horizontalButtons) {
        // 			// this.state.buttonBottomSpace = 5;
        // 			// console.log(this.state.buttonBottomSpace, clientHeight, "ye aa rha");
        // 		} else {
        // 			var h = clientHeight + this.state.btns.length * 5;
        // 			console.log(h, h + 30, this.state.mainScrollHeight);
        // 			h = h + 30;
        // 			if (h >= 245)
        // 				this.state.mainScrollHeight = `calc(100% - 245px)`;
        // 			else {
        // 				if (h < 100) {
        // 					h = h + 90;
        // 				}
        // 				this.state.mainScrollHeight = `calc(100% - ${h + 10}px)`;
        // 			}
        // 			this.state.buttonBottomSpace = 0;
        // 		}
        // 		this.setState({});
        // 	} catch (error) { }
        // }, 1);

        // try {
        //   var clientHeight = document.getElementById('BottomButtons').clientHeight;
        //   this.state.mainScrollHeight = `calc(100% - 120px)`;
        //   if (this.state.horizontalButtons) {
        //     // this.state.buttonBottomSpace = 5;
        //     // console.log(this.state.buttonBottomSpace, clientHeight, "ye aa rha");
        //   }
        //   else {
        //     this.state.mainScrollHeight = 98 + clientHeight;
        //     // var h = (clientHeight + (this.state.btns.length * 55));
        //     // h = h + 20;
        //     // if (h >= 245)
        //     //   this.state.mainScrollHeight = `calc(100% - 245px)`;
        //     // else
        //     //   this.state.mainScrollHeight = `calc(100% - ${h + 10}px)`;
        //     this.state.buttonBottomSpace = 0;

        //   }
        //   this.setState({});
        // }
        // catch (e) {
        //   console.error(e);
        // }
      }

      console.log(steps["" + this.state.cId + ""], "yahoou");
      if (steps["" + this.state.cId + ""]["UI"] == "Gender") {
        this.state.btns = [];
        var genders = steps["" + this.state.cId + ""].data.genderTitles
          ? steps["" + this.state.cId + ""].data.genderTitles
          : { gender1: "Male", gender2: "Female", gender3: "Other" };
        this.state.buttonType = "single";
        this.state.horizontalButtons = true;
        if (
          (genders.gender1 + "" + genders.gender2 + "" + genders.gender3)
            .length > 16
        ) {
          this.state.horizontalButtons = false;
        }
        var n = steps["" + this.state.cId + ""]["Validation"]["successful"];

        var genderList = [
          {
            id: 1.1,
            name: genders.gender1,
            next: n,
            img: male,
            type: "gender",
          },
          {
            id: 1.2,
            name: genders.gender2,
            next: n,
            img: female,
            type: "gender",
          },
          {
            id: 1.3,
            name: genders.gender3,
            next: n,
            img: other,
            type: "gender",
          },
        ];
        //steps['' + this.state.cId + '']['trigger'] = genderList;

        genderList.forEach((element, index) => {
          this.state.btns.push(
            <button
              key={index}
              className={
                this.state.horizontalButtons ? "buttonStyleV" : "buttonStyleV"
              }
              style={{
                borderColor: this.state.customisation.botButtonColor,
                color: this.state.customisation.botButtonColor,
                fontSize: this.state.customisation.buttonfontSize + "px",
                fontFamily: this.state.customisation.buttonfontStyle,
              }}
              onClick={() => this.selectGender(element)}
            >
              <img style={{ padding: "5px 3px" }} src={element.img}></img>
              <span
                className={
                  this.state.buttonType == "multiple" ? "checkButtonText" : ""
                }
              >
                {this.checkUrl(element.name)}
              </span>
            </button>
          );
        });

        this.state.list.push(
          <div
            id="BottomButtons"
            className="appointment-yes-no-btn-div"
            style={{
              position: "relative",
              display: "inline-flex",
              textAlign: "center",
              padding: "0px 0px",
              background: "#fff",
              maxHeight: 250,
              overflowY: "scroll",
              marginTop: 10,
              marginBottom: this.state.buttonType == "multiple" ? 40 : 10,
            }}
          >
            {/* {this.state.mainScrollHeight = "calc(100% - 50px)"} */}
            {this.state.btns}
          </div>
        );
        this.state.mainScrollHeight = `calc(100% - 50px)`;
        this.setState({});
      } else if (steps["" + this.state.cId + ""]["UI"] == "Cards") {
        this.setState({ btnorcard: "card" });

        if (steps["" + this.state.cId + ""]["trigger"].length == 0) {
          this.state.btns = [];
          this.state.buttonType = "";
        } else {
          this.state.btns = [];
          multstr = [];

          this.state.mainScrollHeight = "calc(100% - 75px)";
          var len = steps["" + this.state.cId + ""]["trigger"].length;
          if (
            steps["" + this.state.cId + ""]["data"]["selected_type"] &&
            steps["" + this.state.cId + ""]["data"]["selected_type"].value ==
              "multiple"
          ) {
            this.setState({ buttonType: "multiple" });
            this.state.mainScrollHeight = "calc(100% - 100px)";
            var s_view = document.getElementById("PreviewChatScroller_dheeraj");
            s_view.style.height = "calc(100% - 150px)";
          } else {
            this.setState({ buttonType: "single" });
          }
          if (steps["" + this.state.cId + ""]["trigger"].length == 2) {
            //qwerqwdwef
            if (
              steps["" + this.state.cId + ""]["trigger"][0].name.length > 14 ||
              steps["" + this.state.cId + ""]["trigger"][1].name.length > 14
            ) {
              this.state.horizontalButtons = false;
              steps["" + this.state.cId + ""]["trigger"].forEach(
                (element, index) => {
                  this.state.btns.push(
                    <div className="cardNodeee">
                      <img
                        className="cardimggg"
                        src={
                          element.imageUrl
                            ? element.imageUrl
                            : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
                        }
                      />
                      <h6 className="mt-2">{element.title}</h6>
                      <div className="mt-0 card-descriptionn scroll-cstmm">
                        {element.description}
                      </div>
                      {element.btnornot == "yes" ? (
                        element.url !== "" ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.buttonfunction(element, index)}
                          >
                            {element.footer_button_text}
                          </button>
                        ) : element.contactnumber !== "" ? (
                          <a
                            target="_blank"
                            href={`tel:${element.contactnumber}`}
                          >
                            {" "}
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                this.buttonfunction(element, index)
                              }
                            >
                              {element.footer_button_text}
                            </button>
                          </a>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.buttonfunction(element, index)}
                          >
                            {element.footer_button_text}
                          </button>
                        )
                      ) : null}
                    </div>
                  );
                }
              );
            } else {
              this.state.horizontalButtons = true;
              steps["" + this.state.cId + ""]["trigger"].forEach(
                (element, index) => {
                  this.state.btns.push(
                    <div className="cardNodeee">
                      <img
                        className="cardimggg"
                        src={
                          element.imageUrl
                            ? element.imageUrl
                            : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
                        }
                      />
                      <h6 className="mt-2">{element.title}</h6>
                      <div className="mt-0 card-descriptionn scroll-cstmm">
                        {element.description}
                      </div>
                      {element.btnornot == "yes" ? (
                        element.url !== "" ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.buttonfunction(element, index)}
                          >
                            {element.footer_button_text}
                          </button>
                        ) : element.contactnumber !== "" ? (
                          <a
                            target="_blank"
                            href={`tel:${element.contactnumber}`}
                          >
                            {" "}
                            <button
                              className="btn btn-primary"
                              onClick={() =>
                                this.buttonfunction(element, index)
                              }
                            >
                              {element.footer_button_text}
                            </button>
                          </a>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={() => this.buttonfunction(element, index)}
                          >
                            {element.footer_button_text}
                          </button>
                        )
                      ) : null}
                    </div>
                  );
                }
              );
            }
          } else {
            this.state.horizontalButtons = false;
            steps["" + this.state.cId + ""]["trigger"].forEach(
              (element, index) => {
                this.state.btns.push(
                  <div className="cardNodeee">
                    <img
                      className="cardimggg"
                      src={
                        element.imageUrl
                          ? element.imageUrl
                          : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
                      }
                    />
                    <h6 className="mt-2">{element.title}</h6>
                    <div className=" mt-0 card-descriptionn scroll-cstmm">
                      {element.description}
                    </div>
                    {element.btnornot == "yes" ? (
                      element.url !== "" ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => this.buttonfunction(element, index)}
                        >
                          {element.footer_button_text}
                        </button>
                      ) : element.contactnumber !== "" ? (
                        <a
                          target="_blank"
                          href={`tel:${element.contactnumber}`}
                        >
                          {" "}
                          <button
                            className="btn btn-primary"
                            onClick={() => this.buttonfunction(element, index)}
                          >
                            {element.footer_button_text}
                          </button>
                        </a>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => this.buttonfunction(element, index)}
                        >
                          {element.footer_button_text}
                        </button>
                      )
                    ) : null}
                  </div>
                );
              }
            );
          }

          this.state.list.push(
            <div>
              <div
                className="bottomButtonsOldd"
                style={{
                  display: "inline-flex",
                  overflowX: "scroll",
                  width: "100%",
                  position: "relative",
                }}
              >
                {/* <span className="PreviousCard" onClick={this.CardPrevv}><img id="CardArrow" src={this.image_addedon + left} /></span> */}
                <div className="bootSliderr">{this.state.btns}</div>
                {/* <span className="NextCard" onClick={this.CardNext} ><img id="CardArrow" src={this.image_addedon + right} /> </span> */}
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <span className="PreviousCard" onClick={this.CardPrevv}>
                  <img id="CardArrow" src={this.image_addedon + left} />
                </span>
                <span className="NextCard" onClick={this.CardNextt}>
                  <img id="CardArrow" src={this.image_addedon + right} />{" "}
                </span>
              </div>
            </div>
          );
        }
      } else if (steps["" + this.state.cId + ""]["UI"] == "Follow us") {
        console.log(
          steps["" + this.state.cId + ""]["UI"],
          "--------------------------------------------------------->>>>>>FOllow  USSSSSSSSSSSSSSSSSSSSSSSS"
        );
        var cardList = steps["" + this.state.cId + ""]["data"]["cardsOptions"];
        this.state.buttonType = "single";
        console.log(cardList, "----->>>>>cardlist item------>>>");
        cardList.forEach((elements) => {
          console.log(elements, "------->>>>>>>>>>>>elementss");
          this.state.btns.push(
            <div class="logo-slide1">
              <div class="swiper1 logoSwiper1">
                <div class="swiper-wrapper1">
                  <div class="swiper-slide1">
                    <div className="img-bg1">
                      <img
                        src={
                          elements.imageUrl
                            ? elements.imageUrl
                            : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
                        }
                      />
                    </div>

                    {/* <div className="card-description scroll-cstm" onClick={() => { this.state.text = elements.imageUrl,  this.state.text = elements.description, this.onSendButtonPress() }}><a target="_blank" href={elements.description} >{elements.title}</a></div> */}
                    {/* <button className="buttonStyle" style={{ borderColor: this.state.customisation.botButtonColor, color: this.state.customisation.botButtonColor, width: "90%", display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} onClick={() => { this.state.text = element.title, this.state.text = element.description, this.onSendButtonPress() }}>
								{element.footer_button_text}
							</button> */}
                    <span
                      onClick={() => {
                        this.state.text = elements.imageUrl
                          this.state.text = elements.description
                          this.onSendButtonPress();
                      }}
                    >
                      <a target="_blank" href={elements.description}>
                        {elements.title}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        });

        this.state.list.push(
          // <div className="bottomButtonsOld" style={{ display: 'inline-flex', overflowX: 'scroll', width: "100%", position: "relative" }}>
          // 	<span className="PreviousCard" onClick={this.CardPrevv}><img id="CardArrow" src={this.image_addedon + left} /></span>
          // 	<div className="bootSlider flow-node">
          // 		{this.state.btns}
          // 	</div>
          // 	<span className="NextCard" onClick={this.CardNextt} ><img id="CardArrow" src={this.image_addedon + right} /> </span>
          // </div>

          <div
            className="bottomButtonsOld"
            style={{
              display: "inline-flex",
              overflowX: "scroll",
              width: "100%",
              position: "relative",
            }}
          >
            <span className="PreviousCard" onClick={this.CardPrev}>
              <img id="CardArrow" src={this.image_addedon + left} />
            </span>
            <div className="bootSlider">{this.state.btns}</div>
            <span className="NextCard" onClick={this.CardNext}>
              <img id="CardArrow" src={this.image_addedon + right} />{" "}
            </span>
          </div>
        );
        try {
          s_view.style.height = "calc(100% - 70px)";
        } catch (error) {}

        // },1000)
      } else if (steps["" + this.state.cId + ""]["UI"] == "Payment") {
        console.log(
          steps["" + this.state.cId + ""]["UI"],
          "--------------------------------------------------------->>>>>>cards   USSSSSSSSSSSSSSSSSSSSSSSS"
        );
        var cardList = steps["" + this.state.cId + ""]["data"]["cardsOptions"];
        this.state.buttonType = "single";

        cardList.forEach((element) => {
          console.log(element, "---->>ELEMENT");
          this.state.btns.push(
            <div className="cardNode">
              {/* <h6 className="mb-0">
								  {element.title == '' ?  <span>Amount- $75 Only</span> : <h6>Amount- ${element.title}</h6> } 
							</h6> */}
              {/* <h6>Amount- ${element.title}</h6> . Amount- $75 Only */}
              {/* {
							 
								element.payment_url1 !== "" ? 
						 		

									<div class="custom-btn" style={{ borderColor: this.state.customisation.botButtonColor, color: this.state.customisation.botButtonColor, width: "90%", display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
									<button class="buy-now"
									onClick={element.payment_url == '' ? () => NotificationManager.error('', 'Please fill valid Informations', 1000): () => { this.state.text = 'Please pay'; this.onSendButtonPress()} } 
									><a target="_blank" style={{color: '#fff'}} href={`${element.payment_url}`}>{element.footer_button_text == '' ? "Buy Now" :element.footer_button_text}</a></button>
									</div>
								:
								 
								<div class="custom-btn" target="_blank" style={{ borderColor: this.state.customisation.botButtonColor, color: this.state.customisation.botButtonColor, width: "90%", display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} onClick={() => { this.state.Amo = element.title, element.title !== "" && element.title > 0  ? this.stripeCheckout1(element.title, element.publicable_key, element.secret_key) : NotificationManager.error('', 'Please fill valid Informations', 1000) }}>
									<button class="buy-now">{element.footer_button_text == '' ? "Buy Now" :element.footer_button_text}</button>
								</div>
								 
							} */}

              {element.payment_url1 !== "" ? (
                <div
                  class="custom-btn"
                  style={{
                    borderColor: this.state.customisation.botButtonColor,
                    color: this.state.customisation.botButtonColor,
                    width: "90%",
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <button
                    class="buy-now"
                    onClick={
                      element.payment_url == ""
                        ? () =>
                            NotificationManager.error(
                              "",
                              "Please fill valid Informations",
                              1000
                            )
                        : () => {
                            this.state.text = "Please pay";
                            this.onSendButtonPress();
                          }
                    }
                  >
                    <a
                      target="_blank"
                      style={{ color: "#fff" }}
                      href={`${element.payment_url}`}
                    >
                      {element.footer_button_text1 == ""
                        ? "Buy Now"
                        : element.footer_button_text1}
                    </a>
                  </button>
                </div>
              ) : (
                <div
                  class="custom-btn"
                  target="_blank"
                  style={{
                    borderColor: this.state.customisation.botButtonColor,
                    color: this.state.customisation.botButtonColor,
                    width: "90%",
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => {
                    this.state.Amo = element.title
                      element.title !== "" && element.title > 0
                        ? this.stripeCheckout1(
                            element.title,
                            element.publicable_key,
                            element.secret_key
                          )
                        : NotificationManager.error(
                            "",
                            "Please fill valid Informations",
                            1000
                          );
                  }}
                >
                  <button class="buy-now">
                    {element.footer_button_text == ""
                      ? "Buy Now"
                      : element.footer_button_text}
                  </button>
                </div>
              )}
            </div>
          );
        });
        this.state.list.push(
          <div
            className="bottomButtonsOld"
            style={{
              display: "inline-flex",
              overflowX: "scroll",
              width: "100%",
              position: "relative",
            }}
          >
            <div className="bootSlider">{this.state.btns}</div>
          </div>
        );
        try {
          s_view.style.height = "calc(100% - 70px)";
        } catch (error) {}
      }
      this.setState({});
      var props = steps["" + this.state.cId + ""]["input"];
      if (
        props == "Date_&_Time" ||
        steps["" + this.state.cId + ""]["UI"] == "Date Selector" ||
        props == "date and time" ||
        (steps["" + this.state.cId + ""]["UI"] == "Request Callback" &&
          steps["" + this.state.cId + ""].data.isCalendly == false)
      ) {
        this.state.dateSelected = false;
        setTimeout(() => {
          this.state.mainScrollHeight = `calc(100% - 398px)`;
          if (steps["" + this.state.cId + ""]["UI"] == "Date Selector")
            this.chooseDate_time("Date Selector");
          else this.chooseDate_time();
        }, 500);
      } else if (
        steps["" + this.state.cId + ""]["UI"] == "Request Callback" &&
        steps["" + this.state.cId + ""].data.isCalendly
      ) {
        this.state.Input = (
          <div className="checkout-container" id="calendly">
            <iframe
              src={steps["" + this.state.cId + ""].data.calendlyLink}
              style={{ height: "90%", width: "100%" }}
            />
            <button
              style={{
                background: "rgb(224, 224, 224)",
                cursor: "pointer",
                display: "inline-block",
                width: "93%",
                position: "relative",
                bottom: "-2px",
                "z-index": "99",
                height: "40px",
                "border-radius": "0px 0px 0px 0px",
              }}
              onClick={() => {
                this.onSendButtonPress("continue");
              }}
            >
              NEXT
            </button>
          </div>
        );
        this.setState({});
        console.log("SHOW CALENDLY");
      } else if (props == "feedbaack") {
        this.setState({ showText: "hidden", a_showFeedback: "visible" });
      } else {
        this.state.Input = null;
        this.setState({});
      }
    }
    console.log(this.state.cId);
    if (steps["" + this.state.cId + ""]["UI"] == "Feedback") {
      var s_view = document.getElementById("PreviewChatScroller_dheeraj");

      lastFeedback = true;
      try {
        var s_view = document.getElementById("PreviewChatScroller_dheeraj");
        var extratext = Math.floor(
          steps["" + this.state.cId + ""]["data"]["text"].length / 45
        );
        console.log(s_view.style.height, s_view.scrollHeight);
        if (
          steps["" + this.state.cId + ""]["data"]["feedback_type"][
            "presentation"
          ]["value"] == "1-10"
        ) {
          this.state.mainScrollHeight = `calc(100% - ${
            185 + 20 * parseInt(extratext)
          }px)`;
          // s_view.style.height = 'calc(100% - 150px)';
        } else {
          this.state.mainScrollHeight = `calc(100% - ${
            135 + 20 * parseInt(extratext)
          }px)`;
        }

        if (
          steps["" + this.state.cId + ""]["data"]["feedback_type"]["type"][
            "value"
          ] == "rating-smile" ||
          steps["" + this.state.cId + ""]["data"]["feedback_type"]["type"][
            "value"
          ] == "rating-stars"
        ) {
          // if (s_view.scrollHeight > 470)
          this.state.mainScrollHeight = `calc(100% - ${
            130 + 20 * parseInt(extratext)
          }px)`;
          // else
          // s_view.style.height = 'auto'
        }
      } catch (error) {
        console.error(error);
      }
      this.setState({
        showSurvey: {
          enable: true,
          type: steps["" + this.state.cId + ""]["data"]["feedback_type"][
            "type"
          ]["value"],
          value:
            steps["" + this.state.cId + ""]["data"]["feedback_type"][
              "presentation"
            ]["value"],
        },
        feedbackText: steps["" + this.state.cId + ""]["data"]["text"],
        feedbackResponseText:
          steps["" + this.state.cId + ""]["data"]["responseText"],
        showText: "hidden",
        showMenu: false,
      });
      // alert((this.state.mainScrollHeight))
      // alert($("#PreviewChatScroller_dheeraj").height())

      // var extratext=Math.floor(this.state.feedbackText.length/45)
      // var s_view = document.getElementById(
      // 	"PreviewChatScroller_dheeraj"
      // 	);
      // console.log($("PreviewChatScroller_dheeraj").height(),"jsheight")
      // this.state.mainScrollHeight=parseInt(this.state.mainScrollHeight)-15*parseInt(extratext)

      // }
      // this.setState({ showText: 'hidden', a_showFeedback: 'visible', showMenu: false });
    }

    if (
      steps["" + this.state.cId + ""]["UI"] == "Item List" ||
      steps["" + this.state.cId + ""]["UI"] == "Item list"
    ) {
      setTimeout(() => {
        console.log(
          steps["" + this.state.cId + ""].data.uploadFile,
          "ITEMLISTDATA"
        );
        if (
          steps["" + this.state.cId + ""].data.uploadFile ||
          steps["" + this.state.cId + ""].data.uploadFile == false ||
          steps["" + this.state.cId + ""].data.uploadFile == true
        ) {
          this.state.uploadFilelist =
            steps["" + this.state.cId + ""].data.uploadFile;
        }
        try {
          var s_view = document.getElementById("PreviewChatScroller_dheeraj");
          s_view.style.height = `calc(100% - ${
            this.state.uploadFilelist ? "310px" : "210px"
          })`;
          this.state.mainScrollHeight = `calc(100% - ${
            this.state.uploadFilelist ? "310px" : "210px"
          })`;
          console.log("setItemorder");
        } catch (error) {
          console.log(error);
        }
        this.setState({ showList: true });
      }, 1000);
    }

    if (steps["" + this.state.cId + ""]["UI"] == "Survey") {
      // setTimeout(() => {
      try {
        if (
          steps["" + this.state.cId + ""]["data"]["feedback_type"][
            "presentation"
          ]["value"] == "1-10"
        ) {
          this.state.mainScrollHeight = `calc(100% - 180px)`;
          s_view.style.height = "calc(100% - 180px)";
        }
        // else {
        //   s_view.style.height = 'calc(100% - 170px)';
        // }

        // if (steps['' + this.state.cId + '']['data']['feedback_type']['type']['value'] == "rating-smile" || steps['' + this.state.cId + '']['data']['feedback_type']['type']['value'] == "rating-stars") {
        //   if (s_view.scrollHeight > 470)
        //     s_view.style.height = 'calc(100% - 140px)';
        //   else
        //     s_view.style.height = 'auto';
        // }
      } catch (error) {}
      console.log(steps[["" + this.state.cId + ""]]);
      this.setState({
        showSurvey: {
          enable: true,
          type: steps["" + this.state.cId + ""]["data"]["feedback_type"][
            "type"
          ]["value"],
          value:
            steps["" + this.state.cId + ""]["data"]["feedback_type"][
              "presentation"
            ]["value"],
        },
      });
      // }, 1000);
    }

    if (steps["" + this.state.cId + ""]["UI"] == "File Upload") {
      setTimeout(() => {
        if (steps["" + this.state.cId + ""]["data"]["rtype"]) {
          this.setState({
            uploadType:
              steps["" + this.state.cId + ""]["data"]["rtype"]["value"],
          });
          if (
            steps["" + this.state.cId + ""]["data"]["rtype"]["value"] == "image"
          ) {
            this.setState({ acceptExts:  ".jpeg,.jpg,.png,.heic,.bmp" });
          } else if (
            steps["" + this.state.cId + ""]["data"]["rtype"]["value"] == "video"
          ) {
            this.setState({ acceptExts:  ".mp4,.mov,.mkv,.avi,.wmv"});
          } else {
            this.setState({
              acceptExts: ".txt,.xlsx,.xlsb,.csv,.tsv,.doc,.docx,.pdf,.xls,.jpeg,.jpg,.png,.mp4,.mov"
            });
          }
        }
        this.setState({
          showFileUpload: true,
          mainScrollHeight: "calc(100% - 145px)",
          uploadCount: 0,
          uploadMultipleList: [],
        });
      }, 500);
    }
    if (steps["" + this.state.cId + ""]["UI"] == "End Message") {
      console.log("EndMessage");
      if (localStorage.getItem("botType") == "appointment") {
        LastButtonText = "Book new appointment";
      } else {
        LastButtonText = "Restart Conversation";
      }
      LastButtonText =
        this.checkUrl(steps["" + this.state.cId + ""].data.text).replace(
          /(<([^>]+)>)/gi,
          ""
        ) == "Great! Thanks for the information."
          ? LastButtonText
          : this.checkUrl(steps["" + this.state.cId + ""].data.text).replace(
              /(<([^>]+)>)/gi,
              ""
            );
      LastButtonText =
        `<img src=${
          this.image_addedon + reload
        } style="margin-right: 10px;margin-left: 15px;height:16px;"/>` +
        LastButtonText;
      this.setState({ newAppointment: true });
    }
    // if (steps['' + this.state.cId + '']['UI'] == 'End Message') {
    // 	this.setState({ newAppointment: true })
    // }
    if (steps["" + this.state.cId + ""]["UI"] == "Get Address") {
      try {
        this.state.addressArr =
          steps["" + this.state.cId + ""]["data"]["Address"];
        this.state.addressArr = this.state.addressArr.filter(function (item) {
          return item.isSelected == true;
        });
        // this.state.addressSkip=this.state.addressArr[this.state.addressArrInd].isMandatory
        console.log(this.state.addressArr, "Addressssss", this.state.list);

        if (this.state.addressArrInd == undefined) {
          this.state.addressArrInd = 0;
        } else {
          console.log(this.state.addressArrInd);
          this.state.addressSkip =
            !this.state.addressArr[this.state.addressArrInd].isMandatory;
          if (
            this.state.addressArr[this.state.addressArrInd].field_name ==
            "Country"
          ) {
            this.setState({ stopDropdown: true });
            await fetch(
              `https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/getCountryStatelist`
            )
              .then((response) => response.json())
              .then((result) => {
                this.state.AddressDropdown = true;
                this.state.addressCountryArr = result;
                this.setState({ stopDropdown: false });
                var countryCode = this.state.countryCode;
                var index = result.findIndex(function (item, i) {
                  return item.code === countryCode;
                });
                if (index == -1) {
                  this.state.dropDownText = result[0].name;
                  this.state.text = result[0].name;
                  this.state.addressField = result[0].filename;
                } else {
                  this.state.dropDownText = result[index].name;
                  this.state.text = result[index].name;
                  this.state.addressField = result[index].filename;
                }
                console.log(
                  "INSIDEADDRESSAPI",
                  this.state.AddressDropdown,
                  this.state.dropDownText,
                  result[0].name,
                  result
                );
              })
              .catch((error) => {
                console.log("error", error);
                this.setState({ stopDropdown: false });
              });
          } else if (
            this.state.addressArr[this.state.addressArrInd].field_name ==
              "State" &&
            this.state.addressField
          ) {
            this.setState({ stopDropdown: true });
            await fetch(
              `https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/getCountryStatelist?country=${this.state.addressField}`
            )
              .then((response) => response.json())
              .then((result) => {
                this.state.AddressDropdown = true;
                this.state.addressStateArr = result;
                var stateName = this.state.stateName;
                this.setState({ stopDropdown: false });
                var index = result.findIndex(function (item) {
                  return item.name === stateName;
                });
                if (index == -1) {
                  this.state.dropDownText = result[0].name;
                  this.state.text = result[0].name;
                } else {
                  this.state.dropDownText = result[index].name;
                  this.state.text = result[index].name;
                }

                console.log(
                  "INSIDEADDRESSAPI",
                  this.state.AddressDropdown,
                  this.state.dropDownText
                );
              })
              .catch((error) => {
                console.log("error", error);
                this.setState({ stopDropdown: false });
              });
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({});
    }

    try {
      if (steps["" + this.state.cId + ""]["UI"] == "Bot Question") {
        if (
          steps["" + this.state.cId + ""]["data"]["rtype"]["value"] ==
          "dropdown"
        ) {
          dropDownList = this.checkUrl(
            steps["" + this.state.cId + ""]["data"][
              "dropDownresponseText"
            ].replace(/çp>,/g, "")
          )
            .replace(/(<([^>]+)>)/gi, "")
            .split(","); //[alpha,beta,gama] value=alph
          dropDownList = dropDownList.filter((n) => n);

          this.state.dropDownText = dropDownList[0];
          this.state.text = dropDownList[0];
          this.setState({ showDropDown: true });
        }
      }
    } catch (error) {}

    if (steps["" + this.state.cId + ""]["UI"] == "Handoffs") {
      console.log("-------->>>>>>>>>handoff UIIIIIIIiiiii");

      this.state.cId = steps["" + this.state.cId + ""]["next"];
      this.onSendButtonPress();
      // try {
      // 	var s_view = document.getElementById("PreviewChatScroller_dheeraj");
      // 	s_view.style.height = "76%";
      // } catch (error) {
      // 	console.log(error);
      // }
      // this.setState({
      // 	showText: "hidden",
      // 	a_showFeedback: "visible",
      // 	showMenu: false,
      // });
    }

    console.log(
      steps["" + this.state.cId + ""]["UI"],
      this.state.cId,
      "tere passs"
    );
    
    if (steps["" + this.state.cId + ""]["UI"] == "Get Country") {
      this.setState({
        showCountryList: true,
        text: this.state.CountryName ? this.state.CountryName : "Afghanistan",
        dropDownText: this.state.CountryName
          ? this.state.CountryName
          : "Afghanistan",
      });
    }
  }

  skipQuestion() {
    this.state.showDropDown = false;
    this.state.showSurvey.enable = false;
    this.state.showCountryList = false;
    this.state.showFileUpload = false;
    this.state.list.splice(skipindex, 1);
    this.state.optionalPlaceholder = "Type a message";
    // this.state.isOptional = false;
    // this.state.isOptionalButton = false;
    if (steps["" + this.state.cId]["UI"] == "Button") {
      this.onSendButtonPress(
        "continue",
        steps["" + this.state.cId].trigger[0].next
      );
    }
    // this.state.list.pop();
    // this.state.text = "Question Skipped";
    this.onSendButtonPress("continue");
  }

  saveFormData() {
    var data = [];
    var t = "";
    this.state.rList.forEach((e) => {
      if (e.sender == "livechat") {
      } else if (e.sender == "bot") {
        t = e.text;
      } else if (e.sender == "visitor") {
      } else {
        data.push({ Q: t, A: e.text });
      }
    });
    this.state.formData = data;
  }

  afterclient() {
    this.state.List_allMsg.push({ agent_id: null });
    this.state.list.push(
      <div style={{ display: "-webkit-box" }}>
        <img
          style={{ width: 50, height: 50, marginLeft: 10 }}
          src={this.state.customisation.chatBotAvtar}
        />
        <img
          style={{ width: 70, height: 70 }}
          src={this.image_addedon + loader}
        />
      </div>
    );
    this.setState({});
    setTimeout(() => {
      var t = steps["" + this.state.cId + ""]["data"]["text"].replace(
        "///>>>name<<<///",
        this.state.Name
      );
      this.state.List_allMsg.push({ agent_id: null });
      this.state.list.push(
        <div style={{ display: "flex" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <div
            className="bot"
            style={{
              color: this.state.customisation.botTextcolor,
              background: this.state.customisation.messagebubble,
            }}
          >
            {t}
          </div>
        </div>
      );
      this.state.rList.push({
        text: t,
        sender: "bot",
        cId: this.state.cId,
        continue: steps["" + this.state.cId + ""]["continue"],
      });
      //this.updateTodb();
      this.setState({ text: "" });
      if (steps["" + this.state.cId + ""]["continue"]) {
        this.onSendButtonPress("continue");
      }
    }, 1000);
    this.setState({ text: "" });
  }

  handleChange(date) {
    if (this.state.isLivechat) {
      this.state.chatRef.update({ front_read_count: 0 });
    }
    var d = String(date).split(" ");
    this.setState({
      startDate: date,
      text: String(d[1] + " " + d[2] + " " + d[3]),
    });
    document.getElementById("InputBar").focus();
  }

  a_feedback(rate) {
    var t = "";
    if (rate == 1) {
      t = "you have rated 1 on 5 point scale 😟";
    } else if (rate == 2) {
      t = "you have rated 2 on 5 point scale 😕";
    } else if (rate == 3) {
      t = "you have rated 3 on 5 point scale 😐";
    } else if (rate == 4) {
      t = "you have rated 4 on 5 point scale 🙂";
    } else {
      t = "you have rated 5 on 5 point scale 😍";
    }

    this.setState({ showFeedbackMessage: true });

    // this.state.List_allMsg.push({ agent_id: null });
    // this.state.list.push(<div className="Box">
    //   <img style={{ width: 40, height: 40, marginRight: 15, alignSelf: 'flex-end', borderWidth: 1, borderRadius: 25 }} src={this.image_addedon + user} />
    //   <div className="botc" style={{ background: this.state.customisation.clientBubbleClr, color: this.state.customisation.clientchattextcolor }}>
    //     {t}
    //   </div>
    // </div>);
    // this.state.List_allMsg.push({ agent_id: null });
    // this.state.list.push(<div style={{ display: 'flex' }}>
    //   <img className="NOT_FOUND" style={{ width: 40, height: 40, marginRight: 10, alignSelf: 'flex-end', marginLeft: 10, borderRadius: 25 }} src={this.state.customisation.chatBotAvtar} />
    //   <div className="bot" style={{ color: this.state.customisation.botTextcolor, background: this.state.customisation.messagebubble }}>
    //     Thanks for giving feedback. Your response has been recorded successfully.
    //   </div>
    // </div>);

    setTimeout(() => {
      // var s_view = document.getElementById("PreviewChatScroller_dheeraj");
      // s_view.style.height = '76%';
      this.setState({ a_showFeedback: "hidden", newAppointment: true });
    }, 2000);
  }

  thumbUp(v) {
    if (this.state.isfeedbackgiven) {
      return;
    }
    var score = add_1;
    if (v == 1) {
      score = add_1;
      this.state.chatRef.update({ feedback_score: 1 });
    } else if (v == 2) {
      score = add_2;
      this.state.chatRef.update({ feedback_score: 2 });
    } else if (v == 3) {
      score = add_3;
      this.state.chatRef.update({ feedback_score: 3 });
    } else if (v == 4) {
      score = add_4;
      this.state.chatRef.update({ feedback_score: 4 });
    } else if (v == 5) {
      score = add_5;
      this.state.chatRef.update({ feedback_score: 5 });
    }

    this.state.List_allMsg.push({ agent_id: null });
    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          Thanks for giving feedback. Your response has been recorded
          successfully.
        </div>
      </div>
    );
    this.setState({
      isfeedbackgiven: true,
      showListing: true,
      showFeedback: "hidden",
    });
  }

  startNewAppointment() {
    this.state.showfooter = "inline-block";
    this.state.showFeedbackMessage = false;
    this.state.isfeedbackgiven = false;
    this.state.toggleInput = false;
    this.state.newAppointment = false;
    this.state.showText = "visible";
    this.state.dateSelected = false;
    this.state.addressField = "";
    this.state.Address = "";
    this.state.addressArrInd = undefined;
    this.state.addressStateArr = [];
    bookedAppointmentTime = "";
    this.state.cId = "00";
    this.state.list = [];
    this.state.dropTick=false;
    this.state.pickupTick=false;
    this.state.phoneInput=false;
    if (this.state.botType == "appointment") {
      LastButtonText = "Book new appointment";
    } else {
      LastButtonText = "Restart Conversation";
    }
    // console.log(this.state.companyID, this.state.agentId, this.state.botId)
    // var docRef = db.collection('user_' + this.state.companyID).doc(this.state.agentId).collection('chatbot').doc(localStorage.getItem("BotID"));
    // docRef.get().then((doc) => {
    // 	if (doc.exists) {
    // 		steps = doc.data().template;
    // 		this.state.botType = doc.data().bot_details.bot_template_category
    // 	}
    // 	if (this.state.botType == 1)
    // 		this.fetchTimeSlots();
    // 	this.onSendButtonPress('continue')
    // })
    steps = this.props.dataBot;
    try {
     
      document.getElementById("searchInput").value=""     
      document.getElementById("searchInputt").value=""     
    } catch (error) {
      
    }

    this.onSendButtonPress("continue");
  }

  startNewChat() {
    this.state.isfeedbackgiven = false;
    this.setState({ showListing: false, isOldchat: false });
    if (this.state.isChatInDraft) {
      return;
    }
    var s_view = document.getElementById("Testing11");
    s_view.style.height = "80%";
    this.state.isChatInDraft = true;
    this.setState({
      showNewChatButton: false,
      chat_id: "",
      showHideEmoji: false,
      PageURL: window.location.href,
      num_unread: 0,
      client__id: "",
      country: "India",
      isAgent: true,
      formData: [],
      isChatEnd: false,
      isDefaultLanguage: true,
      showFeedback: "hidden",
      a_showFeedback: "hidden",
      openWindow: false,
      showText: "visible",
      isfeedbackgiven: false,
      canSend: false,
      isNewChat: false,
      agentData: {},
      IPv6: "",
      inputText: "",
      processing: false,
      listID: [],
      t: Date.now(),
      header: "Header from state...",
      content: "Content from state...",
      text: "",
      list: [],
      list_translated: [],
      rList: [],
      isBot: false,
      cId: "00",
      startTime: "time",
      isLoading: false,
      numReschedule: 0,
      chatRef: "",
      isLivechat: false,
      isLivechat: false,
      List_allMsg: [],
    });

    setTimeout(() => {
      var newChatId = Date.now();
      document.cookie = "chatId=" + newChatId + ";path=/";
      this.state.backup_id = newChatId;

      var visitID = Date.now();
      document.cookie = "visitorId=" + visitID + ";path=/";
      this.transferLivechat("VISITOR" + visitID, 0);
      this.onSendButtonPress("continue");
    }, 500);
  }

  thumbDown() {
    if (this.state.isfeedbackgiven) {
      return;
    }
    this.state.chatRef.update({ status: 2 });
    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          Thanks for giving feedback. Your response has been recorded
          successfully.
        </div>
      </div>
    );
    this.setState({
      isfeedbackgiven: true,
      showListing: true,
      showFeedback: "hidden",
    });
  }

  endChat(type, msg_code) {
    try {
      var s_view = document.getElementById("Testing11");
      s_view.style.height = "75%";
      this.setState({});
    } catch (error) {
      console.log(error);
    }
    try {
      this.state.msgGet();
      this.state.update_count();
    } catch (error) {
      console.log(error);
    }
    var zeroDate = new Date(0);
    var zeroTimeFirebase = firebase1.firestore.Timestamp.fromDate(zeroDate);
    this.state.isChatEnd = true;
    this.state.isChatInDraft = false;
    var Intimation_msg = "";
    if (type == "VISITOR_ENDED_CHAT") {
      Intimation_msg = "Visitor has ended the chat";
      //this.state.chatRef.update({incoming:3,updatedon:zeroTimeFirebase,isChatend:1,agent_end:"VISITOR_END_CHAT",completedon:serverTimestamp});
      this.state.chatRef.update({
        isChatend: 1,
        agent_end: "VISITOR_END_CHAT",
        completedon: serverTimestamp,
        message_to_show: "Visitor has ended the chat",
      });
    } else {
      //Intimation_msg="Agent has Ended the chat";
      Intimation_msg = msg_code;
      this.setState({
        n_msg: Intimation_msg,
        shouldShowNotification: !this.state.shouldShowNotification,
      });
    }
    this.state.isSession_saved = false;
    document.cookie = "chatId =;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    this.state.visibility = "hidden";

    this.setState({
      showText: "hidden",
      showFeedback: "visible",
      showMenu: false,
    });
  }

  createForm() {
    this.state.listInput = [];

    this.addMoreItems();
    // intialItemNo = intialItemNo++
  }

  // postData = async() => {
  // 	const res = await fetch('',{
  // 		method: 'POST',
  // 		headers:{
  // 			'COntent-Type':'application/json'
  // 		},
  // 		body: JSON.stringify({})
  // 	})
  // 	const data = await res.json()
  // 	if(data.status == 411 || !data){
  // 		console.log('Error occurs')
  // 		window.alert('Error occurs')
  // 	}else{
  // 		console.log('Success')
  // 		window.alert('Success')
  // 	}
  // 	let pr1 = new Promise((resolve, reject) => {
  // 		setTimeout(() => {
  // 			resolve('First')
  // 		},1000)
  // 	})
  // 	let pr2 = new Promise.resolve(() => {

  // 	})
  // 	Promise.all([pr1, pr2]).then(() => {
  // 		console.log('Error iccurs')
  // 		window.alert('Error ccirs')
  // 	})
  // }
  getDatafromDB = async () => {
    const res = await fetch("", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringidy,
    });
  };
  addMoreItems() {
    var len = this.state.listInput.length;
    for (var i = len; i < len + 1; i++) {
      this.state.itemsHere[i] = { name: "", quantity: "" };
      this.state.listInput.push(
        <div
          id={"listInputStyle" + i}
          style={{
            display: "flex",
            borderBottom: "1px solid rgb(221, 221, 221)",
          }}
        >
          <input
            style={{ padding: "8px 15px", fontSize: "12px" }}
            className="itemLeft"
            id="InputBar"
            name={i}
            autoComplete="off"
            placeholder={"Item name"}
            onChange={(event) => {
              this.addItemsInForm(event, i, "name");
            }}
          />
          <input
            className="itemRight"
            style={{ padding: "8px 15px", fontSize: "12px" }}
            id="InputBar"
            name={i}
            autoComplete="off"
            placeholder={"Quantity"}
            onChange={(event) => {
              this.addItemsInForm(event, i, "quantity");
            }}
          />
        </div>
      );
      this.setState({});
    }
  }

  addItemsInForm(event, i, place) {
    var check = 0;
    var check1 = 0;
    var check2 = 0;
    this.state.sendListButton = false;
    this.state.itemsHere[event.target.name][place] = event.target.value;

    if (this.state.itemsHere.length) {
      console.log(this.state.itemsHere);
      this.state.itemsHere.map((item, index) => {
        if (item.name.trim() && item.quantity.trim()) {
          check = 1;
          this.setState({ sendListButton: false });
        }
        console.log(
          item.name.trim() == "" && item.quantity.trim() == "",
          "kite play"
        );
        if (item.name.trim() == "" && item.quantity.trim() == "") {
          check1 = 0;
          check2 = 0;
        } else if (item.name.trim() == "") {
          check1 = 1;
        } else if (item.quantity.trim() == "") {
          check2 = 1;
        }
        if (index == this.state.itemsHere.length - 1) {
          console.log(check, " =>  check");
          if (check == 0 || check1 == 1 || check2 == 1) {
            this.setState({ sendListButton: true });
          }
        }
      });
    }

    if (event.target.name == this.state.listInput.length - 1) {
      if (
        this.state.itemsHere[event.target.name].name.trim() != "" &&
        this.state.itemsHere[event.target.name].quantity.trim() != ""
      ) {
        if (this.state.listInput.length < 21) this.addMoreItems();
      }
    }

    try {
      var objDiv = document.getElementById("listDiv");
      objDiv.scrollTop = objDiv.scrollHeight;
    } catch (e) {}
  }
  async properdist(val) {
    let myval = val;
    console.log(val, "-->myvalll");
  }

  doneOrder() {
    console.log(this.state.itemsHere.length);
    if (this.state.itemsHere.length) {
      var check = 0;
      this.state.itemsHere.map((item, index) => {
        if (item.name.trim() && item.quantity.trim()) {
          check = 1;
          // this.state.finalList.push({ name: item.name.trim(), quantity: item.quantity.trim() })
        }

        if (index == this.state.itemsHere.length - 2) {
          if (item.name.trim() == "" || item.quantity.trim() == "") {
            this.setState({ showError: "flex" });
            setTimeout(() => {
              this.setState({ showError: "none" });
            }, 2000);
          } else {
            this.state.list.push(
              <div className="Box aa">
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 10,
                    alignSelf: "flex-end",
                    borderWidth: 1,
                    borderRadius: 25,
                  }}
                  src={this.image_addedon + user}
                />
                <div
                  className="botc"
                  style={{
                    background: this.state.customisation.clientBubbleClr,
                    color: this.state.customisation.clientchattextcolor,
                  }}
                >
                  {this.state.itemsHere.map((item, index) => {
                    if (item.name.trim() && item.quantity.trim()) {
                      this.state.finalList.push({
                        name: item.name.trim(),
                        quantity: item.quantity.trim(),
                      });
                    }
                    return (
                      <div style={{ width: 170, display: "flex" }}>
                        <span
                          style={{
                            width: "80%",
                            fontSize: this.state.customisation.fontSize,
                            fontFamily: this.state.customisation.fontStyle,
                          }}
                        >
                          {item.name}
                        </span>
                        <span
                          style={{
                            width: "20%",
                            textAlign: "end",
                            fontSize: this.state.customisation.fontSize,
                            fontFamily: this.state.customisation.fontStyle,
                          }}
                        >
                          {item.quantity}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );

            this.state.itemordered = true;

            try {
              var s_view = document.getElementById(
                "PreviewChatScroller_dheeraj"
              );
              s_view.style.height = "calc(100% - 120px)";
              this.setState({ showList: false });
              this.componentDidUpdate();
              this.onSendButtonPress("continue");
            } catch (error) {
              console.log(error);
            }
          }
        }
        // if (index == this.state.itemsHere.length - 1) {
        //     if (check == 0) {
        //         var s_view = document.getElementById('Appypie').contentWindow.document.getElementById("listInputStyle");
        //         s_view.style.borderColor = 'red';
        //         setTimeout(() => {
        //             s_view.style.borderColor = 'rgb(221, 221, 221)';
        //         }, 2000);
        //     }
        //     else {
        //         list.push(<div className="Box">
        //             <img style={{ width: 40, height: 40, marginRight: 10, alignSelf: 'flex-end', borderWidth: 1, borderRadius: 25 }} src={this.image_addedon + user} />
        //             <div className="botc" style={{ background: this.state.customisation.clientBubbleClr, color: this.state.customisation.clientchattextcolor }}>
        //                 {this.state.itemsHere.map((item, index) => {
        //                     return (
        //                         <div style={{ width: 170, display: 'flex' }}>
        //                             <span style={{ width: '80%' }}>{item.name}</span>
        //                             <span style={{ width: '20%', textAlign: 'end' }}>{item.quantity}</span>
        //                         </div>
        //                     )
        //                 })}
        //             </div>
        //         </div>)

        //         this.state.itemordered = true

        //         try {
        //             var s_view = document.getElementById("PreviewChatScroller_dheeraj");
        //             s_view.style.height = 'calc(100% - 140px)';
        //             this.setState({ showList: false })
        //             this.componentDidUpdate();
        //             this.onSendButtonPress('continue');
        //         } catch (error) {
        //             alert(error);
        //         }
        //     }
        // }
      });
    }
  }

  onChangee(deviceInfo) {
    this.state.UA = deviceInfo.ua;
    this.state.myBrowser = deviceInfo.browser.name;
    this.state.OS = deviceInfo.os.name;
    this.state.isDevice = true;
  }

  newFeedbackSave(value) {
    this.state.showSurvey.enable = false;
    this.state.feedbackText = "";
    var feedback_type = "";

    try {
      // var s_view = document.getElementById('Appypie').contentWindow.document.getElementById("Testing");
      var s_view = document.getElementById("PreviewChatScroller_dheeraj");
      this.state.mainScrollHeight = `calc(100% - 90px)`;
    } catch (error) {}
    var type = this.state.showSurvey.type;
    var scale = this.state.showSurvey.value;
    console.log(type, value, scale, "Feedbackapi");

    this.setState({ showFeedbackMessage: true });

    setTimeout(() => {
      // var s_view = document.getElementById('Appypie').contentWindow.document.getElementById("Testing");
      var s_view = document.getElementById("PreviewChatScroller_dheeraj");
      s_view.style.height = "calc(100% - 150px)";
      this.setState({
        //  a_showFeedback: 'hidden', newAppointment: true,
        showFeedbackMessage: false,
      });
      this.onSendButtonPress();
    }, 2000);
  }

  listOldChat() {
    this.setState({ showHideEmoji: false });
    if (this.state.takeUserPermission) {
      return;
    }
    if (this.state.showListing) {
      if (this.state.isOldchat) {
        this.setState({
          showListing: true,
          renderList: this.state.chatList,
          isOldchat: false,
        });
      } else {
        if (this.state.isChatInDraft) {
          this.setState({ showListing: false });
        } else {
          this.startNewChat();
        }
      }
    } else {
      this.setState({ showListing: true, renderList: this.state.chatList });
    }
  }
  getTimeNew(){
    return 0

    // const timezone1 = 'America/Los_Angeles';
    // const timezone2 = 'Europe/London';le
    
    // console.log(this.state.timeZone,this.state.visTimeZone,"timezones")
    // if(this.state.timeZone&&this.state.visTimeZone&&this.state.timeZone!=this.state.visTimeZone){
    // const now1 = moment.tz(this.state.timeZone);
    // const now2 = moment.tz(this.state.visTimeZone);
    // console.log(now1.utcOffset(),now2.utcOffset(),"offsets")
    // const differenceInHours = now1.utcOffset() - now2.utcOffset();
    
    // return differenceInHours
    // }else{
    //   return 0
    // }
    
      }

  bookAppointment(data) {
    var d = this.state.startDate.toString().split(" ");
    var dd = d[0] + " " + d[1] + " " + d[2] + ", " + d[3];
    var jsday = {
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
      Sun: "Sunday",
    };

    //   appointmentdate = jsday[d[0]]
    // this.state.text =
    //   // "Your Appointment is successfully booked on @" +
    //   // d[0]+
    //   dd + " at " + data.bookTime;
    // bookedAppointmentTime = dd + " at " + data.bookTime;
    bookedAppointmentTime = "" + dd + " at " + (data.displayTime?data.displayTime:data.bookTime);
    this.state.text =bookedAppointmentTime
    this.state.Input = null;
    this.onSendbtnPress();
    try {
      var s_view = document.getElementById("Testing11");
      s_view.style.height = "calc(100% - 117px)";
      this.componentDidUpdate();
    } catch (error) {
      console.log(error);
    }
  }

  handleDateSelection(e) {
    this.state.dateSelected = true;
    this.state.startDate = e;
    this.chooseDate_time();
  }
  isDateDisabled(e){
    // console.log("ISDATE DISABLED",e.date,e.date.getDate())
    return !dateSlots.includes(e.date.getDate())

  }

  backTocalendar() {
    this.state.dateSelected = false;
    this.chooseDate_time();
  }

  dateShifter(e) {
    var t = this.state.startDate.getDate();
    var dd = new Date();

    if (e == "previous") {
      dd.setDate(t - 1);
      this.state.startDate = dd;
      this.chooseDate_time();
    }
    if (e == "next") {
      dd.setDate(t + 1);
      this.state.startDate = dd;
      this.chooseDate_time();
    }
  }

  appointmentRoster() {
    var d = this.state.startDate.toString().split(" ");
    var dd = d[2] + " " + d[1] + " " + d[3];
    this.state.Input = (
      <div className="calendarAnother">
        <div
          style={{ background: this.state.customisation.messagebubble }}
          className="calendareIconDateAnothert"
        >
          <div className="iconAndDate">
            <span className="pr-2">{dd}</span>
            <img
              onClick={() => {
                this.state.dateSelected = false;
                this.chooseDate_time();
              }}
              src={this.image_addedon + calpng}
              style={{ width: "20px", cursor: "pointer" }}
            />
          </div>
          {/* <div className="iconAndDateArrow">
            <span onClick={this.dateShifter.bind(this, 'previous')} className="leftArrowIcon">‹</span>
            <span onClick={this.dateShifter.bind(this, 'next')} className="rightArrowIcon">›</span>
          </div> */}
        </div>
        {this.state.isSlotLoading ? (
          <div
            className="btnAnotherTime"
            style={{ padding: 84, textAlign: "center" }}
          >
            <img src="https://chatbot.appypie.com/widget/sloader.gif" />
          </div>
        ) : (
          <div className="anotherTimeList">
            <div className="innerAnotherTimeList scrollDesign">
              {this.state.timeSlots}
            </div>
          </div>
        )}
      </div>
    );
    this.setState({});
  }

  chooseDate_time(calenderType) {
    this.state.toggleInput = true;
    try {
      var s_view = document.getElementById("Testing11");
      if (this.state.dateSelected) {
        s_view.style.height = "39%";
      } else {
        s_view.style.height = "46%";
      }
      this.componentDidUpdate();
    } catch (error) {
      console.log(error);
    }

    if (this.state.dateSelected) {
      this.state.isSlotLoading = true;
      this.state.timeSlots = [];
      this.appointmentRoster();
      var start = new Date(this.state.startDate);
      start.setHours(0, 0, 0);
      var t = this.state.startDate.getDate();
      var dd = new Date();
      dd.setDate(t + 1);
      var end = new Date(dd);
      end.setHours(0, 0, 0);

      var st = parseInt(start.getTime() / 1000);
      var en = parseInt(end.getTime() / 1000);

      var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var date = start.getDate();
      var month = start.getMonth();
      var year = start.getFullYear();

      var ttt = new Date(`${date} ${monthNames[month]} ${year} 00:00:00`);
      var initial = firebase1.firestore.Timestamp.fromDate(ttt);

      var todayend = new Date(`${date} ${monthNames[month]} ${year} 23:59:59`);
      var last = firebase1.firestore.Timestamp.fromDate(todayend);

      st = initial.seconds;
      en = last.seconds;

      var qqq = Math.floor(new Date().getTime() / 1000);

      var slotsav = 0;
      console.log("SLOT EXAMPLE",slots[0])
      slots.forEach((doc, index) => {
        var data = doc;
        // var startTime = new Date(data.start)
        // startTime = firebase1.firestore.Timestamp.fromDate(startTime);
        // var endTime = new Date(data.end)
        // endTime = firebase1.firestore.Timestamp.fromDate(endTime);
        var startTime = data.start;
        var endTime = data.end;

       
        if (startTime.seconds-TimeGap*60 > st&& endTime.seconds-TimeGap*60 < en) {
          if (startTime.seconds -TimeGap*60 > qqq) {
            var chek = this.checkAlreadyBookedTimeSlots(
              startTime.seconds,
              endTime.seconds
            );
            if (chek) {
              slotsav = 1;
              data.ID = startTime.seconds;
              if (data.status == "free_slot") {
                var d = startTime.toDate().toString().split(" ")[4].split(":");
                var dd = "";
                var display=""
                TimeGap=this.getTimeNew()

                if(TimeGap){
                  var displaystart=startTime
                  // displaystart.seconds=displaystart.seconds-TimeGap*60
                  var displayd = new Date((displaystart.seconds-TimeGap*60)*1000).toString().split(" ")[4].split(":");
                  

                  if (timeSlotFormat == "12") {
                    if (parseInt(displayd[0]) == 12) {
                      display = displayd[0] + ":" + displayd[1] + " PM";
                    } else if (parseInt(displayd[0]) > 12) {
                      if (displayd[0] > 21) {
                        display = parseInt(displayd[0]) - 12 + ":" + displayd[1] + " PM";
                      } else {
                        display = "0" + (parseInt(displayd[0]) - 12) + ":" + displayd[1] + " PM";
                      }
                    } else {
                      display = displayd[0] + ":" + displayd[1] + " AM";
                    }
                  } else {
                    display = displayd[0] + ":" + displayd[1];
                  }

                }

                if (timeSlotFormat == "12") {
                  if (parseInt(d[0]) == 12) {
                    dd = d[0] + ":" + d[1] + " PM";
                  } else if (parseInt(d[0]) > 12) {
                    if (d[0] > 21) {
                      dd = parseInt(d[0]) - 12 + ":" + d[1] + " PM";
                    } else {
                      dd = "0" + (parseInt(d[0]) - 12) + ":" + d[1] + " PM";
                    }
                  } else {
                    dd = d[0] + ":" + d[1] + " AM";
                  }
                } else {
                  dd = d[0] + ":" + d[1];
                }

                data.bookTime = dd;
                data.date=new Date(startTime.seconds*1000)
                data.displaydate=new Date((startTime.seconds-TimeGap*60)*1000)
                data.displayTime=display;

                this.state.timeSlots.push(
                  <div
                    onClick={this.bookAppointment.bind(this, data)}
                    className="btnAnotherTime"
                  >
                    <button>{display?display:dd}</button>
                  </div>
                );

                if (this.state.timeSlots.length == 0) {
                  this.state.timeSlots.push(
                    <div style={{ padding: "120px 0px", margin: "auto" }}>
                      No free slots found
                    </div>
                  );
                }

                this.state.isSlotLoading = false;
                this.appointmentRoster();
              }
            }
          }
        }
      });
      if (slotsav == 0) {
        this.state.timeSlots.push(
          <div style={{ padding: "130px 0px", margin: "auto" }}>
            No free slots found
          </div>
        );
        this.state.isSlotLoading = false;
        this.appointmentRoster();
      }
    } else {
      var mindate = new Date();
      if (calenderType == "Date Selector") {
        this.state.mainScrollHeight = "calc(100% - 398px);";
        this.setState({ mainScrollHeight: "calc(100% - 398px);" });
        this.state.Input = (
          <div>
            <Calendar
              value={this.state.startDate}
              minDate={new Date()}
              onChange={(e) => this.chooseDob(e)}
            />
          </div>
        );
      } else {
        this.state.Input = (
          <div>
            <Calendar
              value={this.state.startDate}
              minDate={new Date()}
              maxDate={new Date( Date.now() + 15 * 24 * 60 * 60 * 1000)}
              tileDisabled={(e)=>this.isDateDisabled(e)}
              onChange={(e) => this.handleDateSelection(e)}
            />
          </div>
        );
        console.log(this.state.Input);
        this.setState({});
      }
    }
    this.setState({ mainScrollHeight: "calc(100% - 398px)" });
  }

  checkAlreadyBookedTimeSlots(c, d) {
    var b = true;
    if (bookedTimeSlots.length) {
      for (var i = 0; i < bookedTimeSlots.length; i++) {
        var a = bookedTimeSlots[i].start.seconds;
        var b = bookedTimeSlots[i].end.seconds;

        if (c >= a && c <= b) {
          console.log("firest false");
          b = false;
          break;
        } else if (d >= a && d <= b) {
          console.log("second false");
          b = false;
          break;
        } else if (c <= a && d >= b) {
          console.log("third false");
          b = false;
          break;
        } else {
          console.log("last true");
          b = true;
        }
      }
    }
    return b;
  }

  chooseDob(e) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.state.text =
      e.getDate() + " " + months[e.getMonth()] + ", " + e.getFullYear();
    this.state.Input = null;
    this.onSendbtnPress();
    try {
      // var s_view = document.getElementById('Appypie').contentWindow.document.getElementById("Testinng");
      // s_view.style.height = 'calc(100% - 140px)';

      this.state.mainScrollHeight = `calc(100% - 120px)`;

      this.componentDidUpdate();
    } catch (error) {
      console.log(error);
    }
  }

 
  dualMapFunction = async () => {
    this.setState({ showGoogleMaps: true, buttonType: "" });
    this.setState({});
    var _this = this;
    var changed = false;
    // var pickupTick=false;
    // var dropTick=false;

    var AUTOCOMPLETE_CONFIG = {
      /* fill-me */
    };
    // var iframeWindow = document.contentWindow;
    // var iframeDocument = document.contentDocument;
    var googleScript = document.createElement("script");
    googleScript.type = "text/javascript";
    googleScript.src =
      "https://maps.googleapis.com/maps/api/js?key=" +
      this.state.googleMapsKey +
      "&libraries=places";
    document.body.appendChild(googleScript);
    googleScript.addEventListener("load", function () {
      const directionsRenderer =
        new google.maps.DirectionsRenderer();
      const directionsService =
        new google.maps.DirectionsService();
      const map = new google.maps.Map(
        document.getElementById("map"),
        {
          zoom: 14,
          center: { lat: 28.70, lng: 77.10 },
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
        }
      );

      
      let marker = new google.maps.Marker({
        map: map,
      });

      var southWest = new google.maps.LatLng(50.5, -0.5);
      var northEast = new google.maps.LatLng(51.5, 0.5);
      var bounds = new google.maps.LatLngBounds(
        southWest,
        northEast
      );
      map.fitBounds(bounds);

      // let infoWindow = new google.maps.InfoWindow();
      // navigator.geolocation.getCurrentPosition((position) => {
      //   const pos = {
      //     lat: position.coords.latitude,
      //     lng: position.coords.longitude,
      //   };

      //   console.log(position, "ateempt Set", pos);

      //   var southWest = new google.maps.LatLng(
      //     pos.lat - 0.5,
      //     pos.lng - 0.5
      //   );
      //   var northEast = new google.maps.LatLng(
      //     pos.lat + 0.5,
      //     pos.lng + 0.5
      //   );
      //   var bounds = new google.maps.LatLngBounds(
      //     southWest,
      //     northEast
      //   );
      //   if (!changed) {
      //     map.fitBounds(bounds);
      //     map.panTo(pos);
      //     // map.addListener("center_changed", () => {
      //     //   _this.setState({
      //     //     gMapLat: map.getCenter().lat(),
      //     //     gMapLong: map.getCenter().lng(),
      //     //   });
      //     // });
      //     // marker.setPosition(map.getCenter());
      //     // if (map.getCenter()) {
      //     //   _this.setState({
      //     //     gMapLat: map.getCenter().lat(),
      //     //     gMapLong: map.getCenter().lng(),
      //     //   });
      //     // }
      //     console.log("Current Set", pos);
      //   }
      // });

      let addressInput = document.getElementById("searchInput");
      let addressInputt = document.getElementById("searchInputt");
      console.log(addressInput, "INU");
      let pickup = new google.maps.places.Autocomplete(
        addressInput,
        AUTOCOMPLETE_CONFIG
      );
      let drop = new google.maps.places.Autocomplete(
        addressInputt,
        AUTOCOMPLETE_CONFIG
      );

      

  
   

      pickup.addListener("place_changed", (info) => {
        
       _this.setState({pickupTick:true,locationErrMsg:""})
        let origin = document.getElementById("searchInput").value;
        let destination = document.getElementById("searchInputt").value;
        console.log(origin, destination, "--->>>OOOfrominit");

        if(origin && destination&& origin!==destination &&_this.state.pickupTick&&_this.state.dropTick){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          pickup: `${origin}`,
          drop: `${destination}`,
          key: _this.state.googleMapsKey,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-appycx-beta.cloudfunctions.net/list/direction",
          requestOptions
        )
          .then((response) => response.json())

          .then((result) =>
            console.log(
              "--->resulttttttt",
              result,
              result.duration,
              result.data,
              "--repll",
              result.data.replace("km", ""),
              _this.setState({
                distance: result.data,
                duration: result.duration,
                distanceforCalc: result.data.replace("km", ""),
                initial: result.initial,
                final: result.final,
                locationErrMsg: "",
                pickupTick: true,
                dropTick: true,
              })
            )
          )
          .catch((error) => console.log("error", error),
          _this.setState({ pickupTick: false })
          // _this.setState({ locationErrMsg: "Select Correct location from dropdown suggestions." })
          );
        const directionsRenderer =
          new google.maps.DirectionsRenderer();
        const directionsService =
          new google.maps.DirectionsService();
        const map = new google.maps.Map(
          document.getElementById("map"),
          {
            zoom: 14,
            center: { lat: 37.77, lng: -122.447 },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
          }
        );

        directionsRenderer.setMap(map);
        calculateAndDisplayRoute(directionsService, directionsRenderer);
        function calculateAndDisplayRoute(
          directionsService,
          directionsRenderer
        ) {
          directionsService
            .route({
              origin: document.getElementById("searchInput").value,
              destination: document.getElementById("searchInputt").value,
              travelMode: "DRIVING",
            })
            .then((response) => {
              directionsRenderer.setDirections(response);
              _this.setState({ locationErrMsg: "" });
            })
            .catch(
              _this.setState({ locationErrMsg:  "" })
     );
        }
        }else if (origin &&_this.state.pickupTick ){
          // map.setCenter(place.geometry.location);
          // map.setZoom(25);
          let place = pickup.getPlace();
          // if (!place.geometry) {
          //   alert("Search a place");
          //   return;
          // }
          
          
  
          console.log(place.geometry.location);
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(25);
          }

          let marker = new google.maps.Marker({
            map: map,
          });
          marker.setPosition(map.getCenter());



        }
        let marker = new google.maps.Marker({
          map: map,
        });

        // let place = autoCompletee.getPlace();
        // // if (!place.geometry) {
        // //   alert("Search a place");
        // //   return;
        // // }
        // changed = true;
        // if (place.geometry.viewport) {
        //   map.fitBounds(place.geometry.viewport);
        // } else {
        //   map.setCenter(place.geometry.location);
        //   map.setZoom(25);
        // }
      });
      drop.addListener("place_changed", (info) => {
        
        _this.setState({dropTick:true,locationErrMsg:""})

        let origin = document.getElementById("searchInput").value;
        let destination = document.getElementById("searchInputt").value;
        console.log(origin, destination, "frominit");


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if(origin && destination&& origin!==destination &&_this.state.pickupTick&&_this.state.dropTick){

        var raw = JSON.stringify({
          pickup: `${origin}`,
          drop: `${destination}`,
          key: _this.state.googleMapsKey,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://us-central1-appycx-beta.cloudfunctions.net/list/direction",
          requestOptions
        )
          .then((response) => response.json())

          .then((result) =>
            console.log(
              "--->resultfrominitautocompletee",
              result,
              result.duration,
              result.data,
              "--repll",
              result.data.replace("km", ""),
              _this.setState({
                distance: result.data,
                duration: result.duration,
                distanceforCalc: result.data.replace("km", ""),
                initial: result.initial,
                final: result.final,
                locationErrMsg: "",
                dropTick:true,
                pickupTick:true
              })
            )
          )
          .catch((error) => console.log("error", error),
          _this.setState({ locationErrMsg:  "",dropTick:false })
          );
            
        const directionsRenderer =
          new google.maps.DirectionsRenderer();
        const directionsService =
          new google.maps.DirectionsService();
        const map = new google.maps.Map(
          document.getElementById("map"),
          {
            zoom: 14,
            center: { lat: 37.77, lng: -122.447 },
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
          }

        );

        directionsRenderer.setMap(map);
        calculateAndDisplayRoute(directionsService, directionsRenderer);
        function calculateAndDisplayRoute(
          directionsService,
          directionsRenderer
        ) {
          directionsService
            .route({
              origin: document.getElementById("searchInput").value,
              destination: document.getElementById("searchInputt").value,
              travelMode: "DRIVING",
            })
            .then((response) => {
              directionsRenderer.setDirections(response);
              _this.setState({ locationErrMsg: "" });
            })
            .catch(

              _this.setState({ errorcode: 401})
            );
        }
      }else{
        // _this.setState({ locationErrMsg:  "Select Correct location from dropdown suggestions." });
      }
        // let marker = new google.maps.Marker({
        //   map: map,
        // });

        // let place = autoCompletee.getPlace();
        // if (!place.geometry) {
        //   alert("Search a place");
        //   return;
        // }
        // changed = true;
        // if (place.geometry.viewport) {
        //   map.fitBounds(place.geometry.viewport);
        // } else {
        //   map.setCenter(place.geometry.location);
        //   map.setZoom(25);
        // }
      });

    });
  };

   initAutocomplete= async () => {
    this.setState({singlelocation: true})
    this.setState({ showGoogleMaps: true, buttonType: "" });
    this.setState({});
    var _this = this;
    var changed = false;

    var GOOGLE_API_KEY = this.state.googleMapsKey;
    var AUTOCOMPLETE_CONFIG = {};

    var googleScript = document.createElement("script");
    googleScript.type = "text/javascript";
    googleScript.src =
      "https://maps.googleapis.com/maps/api/js?key=" +
      GOOGLE_API_KEY +
      "&libraries=places";
    document.body.appendChild(googleScript);
    googleScript.addEventListener("load", function () {
      let map = new google.maps.Map(document.getElementById("Googlemap"), {
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
      });

      let marker = new google.maps.Marker({
        map: map,
      });
      
      

      var southWest = new google.maps.LatLng(50.5, -0.5);
      var northEast = new google.maps.LatLng(51.5, 0.5);
      var bounds = new google.maps.LatLngBounds(southWest, northEast);
      map.fitBounds(bounds);

      let infoWindow = new google.maps.InfoWindow();

      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        console.log(position, "ateempt Set", pos);
        // var curr=new google.maps.LatLng(pos.lat, pos.lng)
        // console.log('ateempt Set',curr)

        var southWest = new google.maps.LatLng(pos.lat - 0.5, pos.lng - 0.5);
        var northEast = new google.maps.LatLng(pos.lat + 0.5, pos.lng + 0.5);
        var bounds = new google.maps.LatLngBounds(southWest, northEast);
        if (!changed) {
          map.fitBounds(bounds);

          // map.setCenter(pos);
          map.panTo(pos);
          map.addListener("center_changed", () => {
            _this.setState({
              gMapLat: map.getCenter().lat(),
              gMapLong: map.getCenter().lng(),
            });
            // console.log(map.getCenter().lat(), map.getCenter().lng())
          });
          marker.setPosition(map.getCenter());
          _this.setState({
            gMapLat: map.getCenter().lat(),
            gMapLong: map.getCenter().lng(),
          });

          // marker.setPosition(pos)
          // this.setState({gMapLat:pos.lat,gMapLong:pos.lng})
          console.log("Current Set", pos);
        }
      });

      let addressInput = document
        .getElementById("searchInput");
      console.log(addressInput, "INU");
      let autoComplete = new google.maps.places.Autocomplete(
        addressInput,
        AUTOCOMPLETE_CONFIG
      );
      let addressInputt = document
        .getElementById("searchInputt");
      console.log(addressInputt, "INU");
      let autoCompletee = new google.maps.places.Autocomplete(
        addressInputt,
        AUTOCOMPLETE_CONFIG
      );

      const locationButton = document.createElement("button");
      // locationButton.textContent = "Pan to Current Location";
      locationButton.classList.add("custom-map-control-button");
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);

      // const locationButton=document.getElementById('Appypie').contentWindow.document.getElementById("pan")

      locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              // infoWindow.setPosition(pos);
              // infoWindow.setContent("Location found.");
              // infoWindow.open(map);
              map.panTo(pos);
              // map.setCenter(pos);
              marker.setPosition(pos);
              _this.setState({ gMapLat: pos.lat, gMapLong: pos.lng });
              console.log("Current Set");
            },
            () => {
              handleLocationError(true, infoWindow, map.getCenter());
            }
          );
        } else {
          // Browser doesn't support Geolocation
          handleLocationError(false, infoWindow, map.getCenter());
        }
      });
      function handleLocationError(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
          browserHasGeolocation
            ? "Error: The Geolocation service failed."
            : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
      }

      autoComplete.addListener("place_changed", (info) => {
        let place = autoComplete.getPlace();
        console.log(place,'--->place')
        if (!place.geometry) {
          alert("Search a place");
          return;
        }
        changed = true;
       
        console.log(info, "INFOOOOO", place.adr_address);

        console.log(place.geometry.location, "vikas");
        if(place.geometry.location == ''){
          console.log('oooooooooooooooo')
          this.setState({singlelocationinput: true})
        }
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(25);
        }

        map.addListener("center_changed", () => {
          marker.setPosition(map.getCenter());
          _this.setState({
            gMapLat: map.getCenter().lat(),
            gMapLong: map.getCenter().lng(),
          });
          // console.log(map.getCenter().lat(), map.getCenter().lng())
        });
        marker.setPosition(map.getCenter());
        _this.setState({
          gMapLat: map.getCenter().lat(),
          gMapLong: map.getCenter().lng(),
        });
      });
      autoCompletee.addListener("place_changed", (info) => {
        let place = autoCompletee.getPlace();
        if (!place.geometry) {
          alert("Search a place");
          return;
        }
        changed = true;

        console.log(info, "INFOOOOO", place.adr_address);

        console.log(place.geometry.location, "vikas");
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(25);
        }

        map.addListener("center_changed", () => {
          marker.setPosition(map.getCenter());
          _this.setState({
            gMapLat: map.getCenter().lat(),
            gMapLong: map.getCenter().lng(),
          });
          // console.log(map.getCenter().lat(), map.getCenter().lng())
        });
        marker.setPosition(map.getCenter());
        _this.setState({
          gMapLat: map.getCenter().lat(),
          gMapLong: map.getCenter().lng(),
        });
      });
    });
  };

  async hotelApi(body, apiurl) {
    // var raw = JSON.stringify({
    // "method": "catListingWithSubCategory",
    // "appId": this.state.foodAppId,
    // "vendorId": "53994",
    // "catId": "233276",
    // "type": "cat",
    // "count": "10",
    // "pageNo": "1",
    // "sort": "1",
    // "emailId": "null"
    // });
    var url = "https://api.appexecutable.com/webservices/FoodCourt.php";
    // url="https://72snappy.appypie.com/webservices/FoodCourt.php"
    if (apiurl) {
      url = apiurl;
    }
    console.log(typeof body, body);
    var headers =
      typeof body == Object
        ? { "Content-Type": "application/json" }
        : { "Content-Type": "application/x-www-form-urlencoded" };
    let list = await fetch(url, {
      method: "post",
      body: body,
      headers: headers,
    }).catch((e) => {
      console.log(e, "ERROR");
    });
    console.log(list, "list");

    list = await list.json();
    return list;
  }
 
  
  setupHotel = async (method, element, hideReply, popButton) => {
    this.setState({ popup: false, Input: null });

    // console.log
    if (popButton) {
      this.state.list.pop();
    }
    if (this.state.btns.length) {
      try {
        s_view.style.height = `calc(100% - ${
          170 - (this.state.customisation.isBTransparent ? 50 : 0)
        })`;
      } catch (error) {}
      this.state.list.pop();

      this.state.btns = [];
    }

    var body = {
      method: "vendorList",
      appId: this.state.foodAppId,
      latitude: "37.421998",
      longitude: "-122.084",
      emailId: "",
      version: "05052017",
    };

    this.setState({ showGoogleMaps: false });

    this.setState({ isDual: false });
    if (method == "gMap") {
      let locationData = steps["" + this.state.cId + ""];
      console.log(
        locationData.data.isUrl,
        locationData.data.currency,
        locationData.data.fare,
        locationData.data.distance,
        locationData.data.fare,
        locationData.data.dist,
        "-->ddiii"
      );
      
      this.setState({
        perkilometreprice: parseFloat(locationData.data.pricePerKm),
      });

      this.setState({ isDual:locationData.data.isUrl||locationData.data.isDual });
      this.setState({ userdistance: locationData.data.distance });
      this.setState({ userfair: locationData.data.fare });
      this.setState({ googleMapsKey: locationData.data.api_key });
      this.setState({ localCurrency: locationData.data.currency? locationData.data.currency.split(' (')[0]:"" });
      if (locationData.data.isDual == true) {
        console.log("doublelocation");

        this.state.buttonType = "single";
        this.state.btns.push(
          <button
            className={"buttonStyleV"}
            id="location"
            style={{
              borderColor: this.state.customisation.botButtonColor,
              color: this.state.customisation.botButtonColor,
              fontSize: this.state.customisation.buttonfontSize,
              fontFamily: this.state.customisation.buttonfontStyle,
            }}
            onClick={() => {
              this.setState({
                finall:'',
                initiall:'',
                singlelocation:'',
                locationErrMsg:''
              })

              this.dualMapFunction();
            }}
          >
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              {"SEND LOCATION"}
            </div>
            <div className="ld ld-ring ld-spin"></div>
          </button>
        );
          
        this.state.list.push(
          <div className="bottomButtons 1" style={{ display: "block" }}>
            {this.state.btns}
          </div>
       
        );
        this.setState({list:this.state.list})
        
      } else {
        this.state.buttonType = "single";
        this.state.btns.push(
          <button
            className={"buttonStyleV"}
            id="location"
            style={{
              borderColor: this.state.customisation.botButtonColor,
              color: this.state.customisation.botButtonColor,
              fontSize: this.state.customisation.buttonfontSize,
              fontFamily: this.state.customisation.buttonfontStyle,
            }}
            onClick={() => {
              this.initAutocomplete();
            }}
          >
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              {"SEND LOCATION"}
            </div>
            <div className="ld ld-ring ld-spin"></div>
          </button>
        );

        this.state.list.push(
          <div className="bottomButtons 1" style={{ display: "block" }}>
            {this.state.btns}
          </div>
        );
        this.setState({list:this.state.list})
      }
    }  else if (method == "initial") {
      // body=[`method=getPages&appId=5c8e46e1967&pageIdentifire=foodcourt_1649657614016_73`]
      body = JSON.stringify({
        method: "getPages",
        appId: this.state.foodAppId,
        pageIdentifire: "foodcourt_1649657614016_73",
      });
      console.log(body);
      var GOOGLE_API_KEY = this.state.googleMapsKey;

      await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.state.gMapLat},${this.state.gMapLong}&key=${GOOGLE_API_KEY}`
      )
        .then((response) => response.json())
        .then((geoCodeapi) => {
          console.log(geoCodeapi, "GEOCODE");
          if (
            geoCodeapi.results &&
            geoCodeapi.results[0] &&
            geoCodeapi.results[0].formatted_address
          ) {
            this.state.Address = geoCodeapi.results[0].formatted_address;
            this.state.AddressFixed = geoCodeapi.results[0].formatted_address
              .split(",")
              .slice(-4)
              .toString(",");
            this.state.AddressVariable = geoCodeapi.results[0].formatted_address
              .split(",")
              .slice(
                0,
                geoCodeapi.results[0].formatted_address.split(",").length - 4
              )
              .toString(",");
          }
          //   console.log(geoCode,"GEOCODE")

          // let fixed=geoCode.split(',').slice(-4).toString(",")
          // let change=geoCode.split(',').slice(0,geoCode.split(',').length-4).toString(",")
          // console.log(geoCode,"GEOCODE",fixed,change)
        });
      // console.log(geoCodeapi,"GEOCODE",geoCodeapi.json())

      // var keyList= await this.hotelApi(body,"https://www.appexecutable.com/webservices/Page.php")
      // this.state.foodKeyList =keyList.languageSetting
      // console.log(this.state.foodKeyList, "initialkeylist");

      // this.state.list.push(<div style={{ display: '-webkit-box' }}>
      //     <img style={{ width: 40, height: 40, marginRight: 10, alignSelf: 'flex-end', marginLeft: 10, borderRadius: 25 }} src={this.state.customisation.chatBotAvtar} />
      //     <img style={{ width: 70, height: 70 }} src={this.image_addedon + loader} />
      // </div>)
      // this.setState({});

      body = [
        `method=vendorListV2&appId=${this.state.foodAppId}&latitude=${
          this.state.gMapLat
        }&longitude=${this.state.gMapLong}&discount=${1}`,
      ];
      console.log(body);
      this.state.list.push(
        <div style={{ display: "-webkit-box" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <img
            style={{ width: 70, height: 70 }}
            src={this.image_addedon + loader}
          />
        </div>
      );
      this.setState({});
      var hotelList = await this.hotelApi(body);
      console.log(hotelList, "initialhotellist");

      this.setupHotelCards(hotelList.vendorList.list);
    } else if (method == "hotel") {
      // SELECTED
      body = [
        `method=foodcourtConfigurationSettings&appId=${this.state.foodAppId}&vendorId=${element.id}`,
      ];
      if (!hideReply) {
        this.state.list.push(

          <div className="sendMessage flex items-baseline mb-5">
            <div
              className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
              style={{
                background: this.state.customisation.clientBubbleClr,
                color: this.state.customisation.clientchattextcolor,
              }}
            >
              <p className="text-[#fff] text-sm leading-6"
                style={{
                  margin: 0,
                  fontSize: this.state.customisation.fontSize,
                  fontFamily: this.state.customisation.fontStyle,
                }}
                dangerouslySetInnerHTML={{
                  __html: this.checkUrl(element.name),
                }}
              />
            </div>
          <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
            <img
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                alignSelf: "flex-end",
                borderWidth: 1,
                borderRadius: 25,
              }}
              src={this.image_addedon + user}
            />
          </span>

          </div>
        );
      }
      this.state.list.push(
        <div style={{ display: "-webkit-box" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <img
            style={{ width: 70, height: 70 }}
            src={this.image_addedon + loader}
          />
        </div>
      );
      var vendDetails = await this.hotelApi(body);
      this.state.foodPaymentObj["miscTax"] = vendDetails.miscTax;
      this.state.foodDelivery = vendDetails.delivery[0];
      this.state.foodDiscount = vendDetails.discount;
      this.state.foodTax = vendDetails.tax;
      this.state.foodMiscTax = vendDetails.miscTax;

      console.log(
        vendDetails,
        "VDE",
        this.state.foodDelivery,
        vendDetails.delivery
      );
      // "[{"distancetoHide":"1","distancefrom":"0","distancefromStart":[0],"distanceto":"10","distancetoStart":["\u003E",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],"distancefrom_error":1,"distanceto_error":1,"distancePrice_error":1,"distancePrice":"7.5","distancefixper":"Per"},{"distancetoHide":"1","distancefrom":10,"distancefromStart":[10],"distanceto":"16","distancetoStart":["\u003E",11,12,13,14,15,16,17,18,19,20],"distancefrom_error":1,"distanceto_error":1,"distancePrice_error":1,"distancePrice":"8","distancefixper":"Per"},{"distancetoHide":"1","distancefrom":16,"distancefromStart":[16],"distanceto":"20","distancetoStart":["\u003E",17,18,19,20],"distancefrom_error":1,"distanceto_error":1,"distancePrice_error":1,"distancePrice":"100","distancefixper":"Fixed"}]"

      body = [
        `method=vendorDesc&appId=${this.state.foodAppId}&vendorId=${element.id}`,
      ];
      var vendorDesc = await this.hotelApi(body);

      let vendD = vendorDesc.vendorList.list.identifire_list_new;
      this.state.foodDistance = element.distance;
      // this.state.foodDistanceunit = element.distanceunit;
      this.state.foodPaymentObj["method"] = "foodOrder";
      // this.state.foodPaymentObj["orderSource"]="chatbot"
      this.state.foodPaymentObj["lang"] = "en";
      this.state.foodPaymentObj["vendorId"] = element.id;
      this.state.foodPaymentObj["vendorName"] = vendD.store_name;
      this.state.foodPaymentObj["store_email"] = vendD.store_email;
      this.state.foodPaymentObj["store_mobile"] = vendD.store_mobile;
      this.state.foodPaymentObj["address"] = vendD.store_address;
      this.state.foodPaymentObj["appId"] = this.state.foodAppId;
      this.state.foodPaymentObj["appName"] = "SP";
      this.state.foodPaymentObj["appAdminName"] = "Shubham Patil";

      console.log(vendorDesc, "VDESC");

      this.state.baseHotel = element;

      this.state.foodCurrency = element.configList.filter((obj) => {
        return obj.ConfigKey === "currency_code";
      })[0].ConfigValue;
      // this.state.foodDistanceunit = element.configList.filter((obj) => {
      //   return obj.ConfigKey === "free_delivery_unit";
      // })[0].ConfigValue;

      this.state.foodDistanceunit = "km";
      console.log(
        element.configList.filter((obj) => {
          return obj.ConfigKey === "currency_code";
        }),
        "VDESC",
        element.configList.filter((obj) => {
          return obj.ConfigKey === "currency_code";
        })[0].ConfigValue
      );

      // Next question

      body = [
        `method=getRootCategoryProductVendor&appId=${this.state.foodAppId}&vendorId=${element.id}`,
      ];
      var globlist = await this.hotelApi(body);
      console.log(globlist);
      this.setupCatCards(globlist.categoryList, "cat");

      this.state.searchAllowed = true;
      this.state.optionalPlaceholder = "Search dishes";
      this.setState({});
    } else if (method == "cat") {
      // SELECTED
      this.state.list.push(
        <div className="sendMessage flex items-baseline mb-5">
          <div
            className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
            style={{
              background: this.state.customisation.clientBubbleClr,
              color: this.state.customisation.clientchattextcolor,
            }}
          >
            <p className="text-[#fff]  text-sm leading-6"
              style={{
                margin: 0,
                fontSize: this.state.customisation.fontSize,
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{ __html: this.checkUrl(element.name) }}
            />
          </div>
          <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
              <img
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  borderWidth: 1,
                  borderRadius: 25,
                }}
                src={this.image_addedon + user}
              />
          </span>
          
        </div>
      );

      // Next question

      // body=[`method=getRootCategoryProductVendor&appId=${this.state.foodAppId}&vendorId=${id}`]
      // var globlist=await this.hotelApi(body)
      console.log(element);
      this.setupSubCatCards(
        element.subCategories,
        "subcat",
        element.productList
      );
    } else if (method == "subcat") {
      // SELECTED
      this.state.list.push(
        <div className="sendMessage flex items-baseline mb-5">
          <div
            className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
            style={{
              background: this.state.customisation.clientBubbleClr,
              color: this.state.customisation.clientchattextcolor,
            }}
          >
            <p className="text-[#fff]  text-sm leading-6"
              style={{
                margin: 0,
                fontSize: this.state.customisation.fontSize,
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{ __html: this.checkUrl(element.name) }}
            />
            {/* {text} */}
          </div>
          <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              borderWidth: 1,
              borderRadius: 25,
            }}
            src={this.image_addedon + user}
          />
          </span>
          
        </div>
      );

      // Next question

      body = [
        `method=catListingWithSubCategory&appId=${this.state.foodAppId}&vendorId=${this.state.baseHotel.id}&catId=${element.id}`,
      ];
      this.state.list.push(
        <div style={{ display: "-webkit-box" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <img
            style={{ width: 70, height: 70 }}
            src={this.image_addedon + loader}
          />
        </div>
      );

      var prodList = await this.hotelApi(body);
      console.log(prodList);
      this.setupProduct(prodList.productList);
    } else if (method == "product") {
      this.state.searchAllowed = false;
      this.state.baseDish = element;
      element.customised = [];
      element.customobj = "";
      element.customisedCost = 0;
      if (this.state.foodCart[element.name]) {
        console.log(
          this.state.foodCart[element.name].data.customised,
          element.customised,
          typeof this.state.foodCart[element.name].data.customised,
          typeof element.customised,
          this.state.foodCart[element.name].data.customised ==
            element.customised
        );

        console.log("Same but different");
        this.state.dishName =
          element.name +
          (this.state.foodCart[element.name].quantity + 1).toString();
        this.state.foodCart[
          element.name +
            (this.state.foodCart[element.name].quantity + 1).toString()
        ] = {
          name: element.name,
          data: element,
          quantity: 1,
          topPrice: 0,
          price: element.price,
          prodDiscount: element.offeredDiscount
            ? parseFloat(element.offeredDiscount)
            : 0,
        };
        // }else if (this.state.foodCart[element.name]&&this.state.foodCart[element.name].data.name==element.name){
        //     console.log(this.state.foodCart[element.name],element)

        //     console.log(" same same")

        //     this.state.dishName=element.name
        //     this.state.foodCart[element.name]={name:element.name,data:element,quantity:this.state.foodCart[element.name].quantity+1}
      } else {
        console.log(" different different");
        console.log(element);
        this.state.dishName = element.name;
        this.state.foodCart[element.name] = {
          name: element.name,
          data: element,
          quantity: 1,
          topPrice: 0,
          price: element.price,
          prodDiscount: element.offeredDiscount
            ? parseFloat(element.offeredDiscount)
            : 0,
        };
      }
      console.log(this.state.foodCart);
      // SELECTED
      this.state.list.push(
        <div className="sendMessage flex items-baseline mb-5">
          <div
            className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
            style={{
              background: this.state.customisation.clientBubbleClr,
              color: this.state.customisation.clientchattextcolor,
            }}
          >
            <p className="text-[#fff]  text-sm leading-6"
              style={{
                margin: 0,
                fontSize: this.state.customisation.fontSize,
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{ __html: element.name }}
            />
            {/* {text} */}
          </div>
          <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              borderWidth: 1,
              borderRadius: 25,
            }}
            src={this.image_addedon + user}
          />
          </span>
        </div>
      );

      // Next question
      if (element.customOption.length > 0) {
        this.setupToppings(this.state.toppingIndex);
      } else {
        this.simplifyCart();
        this.showHotelFinal();
      }
    } else if (method == "showcart") {
      this.setupCart();
    } else if (method == "removefromCart") {
      this.state.list.pop();
      delete this.state.foodCart[element];
      this.setupCart();
      this.setState({});
    } else if (method == "addItem") {
      this.state.list.pop();
      this.state.foodCart[element].quantity =
        this.state.foodCart[element].quantity + 1;
      this.setupCart();
      this.setState({});
    } else if (method == "subItem") {
      this.state.list.pop();
      this.state.foodCart[element].quantity =
        this.state.foodCart[element].quantity - 1;
      this.setupCart();
      this.setState({});
    } else if (method == "getRes") {
      body = [
        `method=foodcourtConfigurationSettings&appId=${this.state.foodAppId}&vendorId=${this.state.baseHotel.id}`,
      ];
      var vendDetails = await this.hotelApi(body);
      console.log(vendDetails, "VDE");
    } else if (method == "search") {
      body = [
        `method=foodSearch&appId=${this.state.foodAppId}&vendorId=${this.state.baseHotel.id}&search=${this.state.text}`,
      ];
      this.state.list.push(
        <div style={{ display: "-webkit-box" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <img
            style={{ width: 70, height: 70 }}
            src={this.image_addedon + loader}
          />
        </div>
      );
      var searchDetails = await this.hotelApi(body);
      this.setupProduct(searchDetails.productList, true);
      console.log(searchDetails, "Search");
    }
    this.focusNEW();
  };
  setupCart = () => {
    this.state.buttonType = "single";
    var Cart = [];
    var CartIndName = [];
    console.log(Cart, "CAARRTT", this.state.foodCart);
    for (let dish in this.state.foodCart) {
      console.log(Cart, "CAARRTT", dish);
      Cart.push(this.state.foodCart[dish].data);
      CartIndName.push(dish);
    }
    console.log(Cart, "CAARRTT", this.state.foodCart);
    Cart.forEach((element, index) => {
      // element=item
      console.log(
        element,
        "ITEM",
        element.productImage,
        "media",
        element.name,
        "name",
        element.description,
        "desc",
        element.price,
        this.state.foodCurrency,
        "amount"
      );
      this.state.btns.push(
        <div
          className="card"
          // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
        >
          <img
            className="cardimg"
            src={
              element.productImage
                ? element.productImage[0].url
                : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
            }
          />
          <h4>{element.name}</h4>

          <div className="card-description scroll-cstm">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  element.description +
                  "Price:" +
                  element.price +
                  this.state.foodCurrency +
                  // +" Quantity:"+(this.state.foodCart[CartIndName[index]].quantity?this.state.foodCart[CartIndName[index]].quantity:1)
                  (element.customobj && element.customobj.length > 0
                    ? " Customisation: " +
                      element.customobj
                        .toString()
                        .replaceAll("{", "")
                        .replaceAll("}", "")
                    : ""),
              }}
            ></p>
          </div>
          <div className="product-increemntDecrement c-flex align-items-baseline">
            <button
              className="buttonStyle 1"
              id="select"
              disabled={this.state.foodCart[CartIndName[index]].quantity == 1}
              onClick={() => {
                this.setupHotel("subItem", CartIndName[index], false, true);
              }}
            >
              {"-"}
            </button>
            <p
              dangerouslySetInnerHTML={{
                __html: this.state.foodCart[CartIndName[index]].quantity
                  ? this.state.foodCart[CartIndName[index]].quantity
                  : 1,
              }}
            ></p>
            <button
              className="buttonStyle 2"
              onClick={() => {
                this.setupHotel("addItem", CartIndName[index], false, true);
              }}
            >
              {"+"}
            </button>
          </div>
          <button
            className="buttonStyleWidget 3"
            onClick={() => {
              this.setupHotel(
                "removefromCart",
                CartIndName[index],
                false,
                true
              );
            }}
          >
            {"Delete"}
          </button>
        </div>
      );
    });
    // this.state.list.pop()x
    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{ __html: "Edit or Complete order?" }}
          />
        </div>
      </div>
    );
    if (this.state.btns.length > 0) {
      this.state.list.push(
        <div
          className="bottomButtons 2"
          style={{
            display: "inline-flex",
            overflowX: "scroll",
            width: "92%",
            maxHeight: "250px",
          }}
        >
          {this.state.btns.length > 1 ? (
            <span className="PreviousCard" onClick={this.CardPrev}>
              <img id="CardArrow" src={this.image_addedon + left} />
            </span>
          ) : null}

          <div className="bootSlider">{this.state.btns}</div>
          {this.state.btns.length > 1 ? (
            <span className="NextCard" onClick={this.CardNext}>
              <img id="CardArrow" src={this.image_addedon + right} />{" "}
            </span>
          ) : null}
        </div>
      );
    }
    try {
      s_view.style.height = `calc(100% - ${
        125 - (this.state.customisation.isBTransparent ? 50 : 0)
      }px)`;
    } catch (error) {}

    this.state.cartButton = [];
    this.state.cartButton.push(
      <button
        className={"buttonStyleV 4"}
        style={{
          borderColor: this.state.customisation.botButtonColor,
          color: this.state.customisation.botButtonColor,
          fontSize: this.state.customisation.buttonfontSize,
          fontFamily: this.state.customisation.buttonfontStyle,
        }}
        onClick={() => {
          this.setupHotel("hotel", this.state.baseHotel, true, true);
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            textOverflow: "ellipsis",
          }}
        >
          {"Add More Items"}
        </div>
      </button>
    );
    if (this.state.btns.length > 0) {
      this.state.cartButton.push(
        <button
          className={"buttonStyleV 5"}
          style={{
            borderColor: this.state.customisation.botButtonColor,
            color: this.state.customisation.botButtonColor,
            fontSize: this.state.customisation.buttonfontSize,
            fontFamily: this.state.customisation.buttonfontStyle,
          }}
          onClick={() => {
            this.checkoutFood(true);
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              textOverflow: "ellipsis",
            }}
          >
            {"Complete order"}
          </div>
        </button>
      );
    }

    this.state.list.push(
      <div
        className="bottomButtons 3"
        style={{ display: "block", marginTop: "8px", marginBottom: "5px" }}
      >
        {this.state.cartButton}
      </div>
    );
    this.setState({});
  };

  setupHotelCards = (cardList) => {
    console.log(cardList, "settingupccard");
    this.state.list.pop();

    if (cardList.length == 0) {
      this.state.foodEmpty = true;
      this.state.list.push(
        <div className="BoxBot" style={{ display: "flex" }}>
          <img
            style={{
              width: 40,
              height: 40,
              marginRight: 10,
              alignSelf: "flex-end",
              marginLeft: 10,
              borderRadius: 25,
            }}
            src={this.state.customisation.chatBotAvtar}
          />
          <div
            className="bot"
            style={{
              color: this.state.customisation.botTextcolor,
              background: this.state.customisation.messagebubble,
            }}
          >
            {/* {parse(this.checkUrl(hnd.msg))} */}

            <p
              style={{
                margin: 0,
                fontSize: this.state.customisation.fontSize,
                fontFamily: this.state.customisation.fontStyle,
              }}
              dangerouslySetInnerHTML={{
                __html: "We do not serve here. Try a different location.",
              }}
            />
          </div>
        </div>
      );

      this.state.buttonType = "single";
      this.state.btns.push(
        <button
          className={"buttonStyleV"}
          id="location"
          style={{
            borderColor: this.state.customisation.botButtonColor,
            color: this.state.customisation.botButtonColor,
            fontSize: this.state.customisation.buttonfontSize,
            fontFamily: this.state.customisation.buttonfontStyle,
          }}
          onClick={() => {
            this.initAutocomplete();
          }}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            {"SEND LOCATION"}
          </div>
          <div className="ld ld-ring ld-spin"></div>
        </button>
      );

      this.state.list.push(
        <div className="bottomButtons 1" style={{ display: "block" }}>
          {this.state.btns}
        </div>
      );
      this.setState({});
      return;
    } else {
      this.state.foodEmpty = false;
    }

    // var cardList = steps['' +this.state.cId + '']["data"]["cardsOptions"]

    this.state.buttonType = "single";
    cardList.forEach((element, index) => {
      console.log(element, "settingupccardelement");

      this.state.btns.push(
        <div
          className="card"
          onClick={(e) => {
            this.openCard(e, element, index);
          }}
          style={{ cursor: "pointer" }}
          // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
        >
          <img
            className="cardimg"
            src={
              element.menuImage
                ? element.menuImage
                : element.imgList && element.imgList[0].name
                ? element.imgList[0].name
                : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
              // : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
            }
          />
          <h4>{element.name}</h4>
          <div className="card-description ">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  element.description +
                  element.distance.toFixed(2) +
                  " " +
                  // element.configList.filter((obj) => {
                  //   return obj.ConfigKey === "free_delivery_unit";
                  // })[0].ConfigValue +

                  "km away. Min order: " +
                  element.min_order +
                  element.configList.filter((obj) => {
                    return obj.ConfigKey === "currency_code";
                  })[0].ConfigValue +
                  (element.vendor_discount
                    ? "Vendor Discount: " + element.vendor_discount + "%"
                    : ""),
              }}
            ></p>
          </div>
          <button
            className="buttonStyleWidget 6"
            id="select"
            onClick={() => {
              this.setupHotel("hotel", element);
            }}
          >
            {"Select"}
          </button>
        </div>
      );
    });

    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{
              __html: "Please select a hotel from the list below.",
            }}
          />
        </div>
      </div>
    );
    this.state.list.push(
      <div
        className="bottomButtons 4"
        style={{
          display: "inline-flex",
          overflowX: "scroll",
          width: "92%",
          maxHeight: "250px",
        }}
      >
        {this.state.btns.length > 1 ? (
          <span className="PreviousCard" onClick={this.CardPrev}>
            <img id="CardArrow" src={this.image_addedon + left} />
          </span>
        ) : null}

        <div className="bootSlider">{this.state.btns}</div>
        {this.state.btns.length > 1 ? (
          <span className="NextCard" onClick={this.CardNext}>
            <img id="CardArrow" src={this.image_addedon + right} />{" "}
          </span>
        ) : null}
      </div>
    );
    try {
      s_view.style.height = `calc(100% - ${
        125 - (this.state.customisation.isBTransparent ? 50 : 0)
      }px)`;
    } catch (error) {}
    this.setState({});
  };
  openCard = (e, element, index) => {
    if (e.target.id != "select") {
      this.state.popup = true;
      this.state.Input = (
        <div
          id="popup"
          style={{
            // height: "60%",
            height: "auto",
            maxWidth: "60%",
            background: "white",
            border: "0px solid #a0a096",
            fontFamily: "Arial, Helvetica, sans-serif",
            lineHeight: "1.125em",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.09)",
            margin: "auto",
            borderRadius: "10px",
            left: "0",
            right: "0",
            top: "50%",
            transform: "translateY(-50%)",
            position: "absolute ",
          }}
        >
          {/* <img
				className="cardimg"
				src={
				  element.menuImage
					? element.menuImage
					: "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
				}
			  />
			  <h4>{element.name}</h4>
			  <div className="popUpDescription">
				{element.description +
				  element.distance.toFixed(2) +
				  " " +element.configList.filter((obj) => {
					return obj.ConfigKey === "free_delivery_unit";
				  })[0].ConfigValue+
				  
				  " away. Min order: " +
				  element.min_order +element.configList.filter((obj) => {
					return obj.ConfigKey === "currency_code";
				  })[0].ConfigValue+
				  (element.vendor_discount
					? "Vendor Discount: " + element.vendor_discount + "%"
					: "")}
			  </div> */}
          {/* <div
			  className="card"
			  style={{cursor:'pointer'}}
			  // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
			> */}
          <img
            className="cardimg"
            src={
              element.menuImage
                ? element.menuImage
                : element.imgList && element.imgList[0].name
                ? element.imgList[0].name
                : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
            }
          />
          <h4 style={{ textAlign: "center" }}>{element.name}</h4>
          <div
            className="card-description-popup"
            style={{ width: "90%", margin: "auto", paddingBottom: "10px" }}
          >
            <span className="description-p">
              <p
                dangerouslySetInnerHTML={{
                  __html: element.description,
                }}
              ></p>
            </span>
            <span className="distance-p">
              {
                element.distance.toFixed(2) +
                  " " +
                  element.distanceunit +
                  " away."
                // element.configList.filter((obj) => {
                //   return obj.ConfigKey === "free_delivery_unit";
                // })[0].ConfigValue
              }
            </span>
            <span className="order-p">
              Min order: {element.min_order}
              {
                element.configList.filter((obj) => {
                  return obj.ConfigKey === "currency_code";
                })[0].ConfigValue
              }
            </span>
            <span className="vender-dis-p">
              Vendor Discount: {element.vendor_discount} %
            </span>

            {element.discountruleAll.length > 0 ? (
              // <span className="vender-dis-p">
              //   Offers:{" "}
              //   {element.discountruleAll.forEach((elementinside, index) => {
              //     elementinside.discount_rate == "Flat"
              //       ? "Get Flat " +
              //         elementinside.price +
              //         " on orders above " +
              //         elementinside.total_amount +
              //         "."
              //       : elementinside.discount_rate == "Percentage"
              //       ? "Get " +
              //         elementinside.price +
              //         "% off on orders above " +
              //         elementinside.total_amount +
              //         "."
              //       : null;
              //   })}
              // </span>
              <span className="vender-dis-p">
  Offers:
  {element.discountruleAll.map((elementinside, index) => (
    <span key={index}>
      {elementinside.discount_rate === "Flat" ? (
        `Get Flat ${elementinside.price} on orders above ${elementinside.total_amount}.`
      ) : elementinside.discount_rate === "Percentage" ? (
        `Get ${elementinside.price}% off on orders above ${elementinside.total_amount}.`
      ) : null}
    </span>
  ))}
</span>

            ) : null}
            {/* <span className="offers-p">Offers</span> */}
          </div>
          <button
            className="buttonStyleWidget 6"
            id="select"
            onClick={() => {
              this.setupHotel("hotel", element);
            }}
          >
            {"Select"}
          </button>
        </div>
        // </div>
      );
      this.setState({});
    }
  };
  setupCatCards = (cardList, type) => {
    console.log(cardList, "settingupcatcard");

    // var cardList = steps['' +this.state.cId + '']["data"]["cardsOptions"]
    this.state.buttonType = "";
    cardList.forEach((element) => {
      this.state.btns.push(
        <button
          className={"buttonStyleV"}
          style={{
            borderColor: this.state.customisation.botButtonColor,
            color: this.state.customisation.botButtonColor,
            fontSize: this.state.customisation.buttonfontSize,
            fontFamily: this.state.customisation.buttonfontStyle,
          }}
          onClick={() => {
            this.setupHotel("cat", element);
          }}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            {element.name}
          </div>
        </button>

        // <div
        //   className="card"
        //   // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
        // >
        //   <img
        //     className="cardimg"
        //     src={
        //       element.image
        //         ? element.image
        //         : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
        //     }
        //   />
        //   <h4>{element.name}</h4>
        //   <div className="card-description scroll-cstm">
        //     {element.description}
        //   </div>
        //   <button
        //     className="buttonStyleWidget 7"
        //     onClick={() => {
        //       this.setupHotel(type, element);
        //     }}
        //   >
        //     {"Select"}
        //   </button>
        // </div>
      );
    });

    // if (type == "subcat" && prodList) {
    //   prodList.forEach((element) => {
    //     this.state.btns.push(
    //       <div
    //         className="card"
    //         // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
    //       >
    //         <img
    //           className="cardimg"
    //           src={
    //             element.image
    //               ? element.image
    //               : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
    //           }
    //         />
    //         <h4>{element.name}</h4>
    //         <div className="card-description scroll-cstm">
    //           {element.description +
    //             "Price:" +
    //             element.price +
    //             this.state.foodCurrency +
    //             (element.offeredDiscount
    //               ? "Discount %:" + element.offeredDiscount
    //               : "")}
    //         </div>
    //         <button
    //           className="buttonStyleWidget 8"
    //           onClick={() => {
    //             this.setupHotel("product", element);
    //           }}
    //         >
    //           {"Select"}
    //         </button>
    //       </div>
    //     );
    //   });
    // }
    this.state.list.pop();
    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{
              __html:
                type == "subcat"
                  ? "Please select a sub category from the list below."
                  : "Please select a category from the list below",
            }}
          />
        </div>
      </div>
    );
    this.state.list.push(
      <div className="bottomButtons 8" style={{ display: "block" }}>
        {this.state.btns}
      </div>
    );
    try {
      s_view.style.height = `calc(100% - ${
        125 - (this.state.customisation.isBTransparent ? 50 : 0)
      }px)`;
    } catch (error) {}
    this.setState({});
  };
  setupSubCatCards = (cardList, type, prodList) => {
    console.log(cardList, "settingupsubcatcard", prodList);

    // var cardList = steps['' +this.state.cId + '']["data"]["cardsOptions"]
    this.state.buttonType = "";
    if (prodList.length > 0) {
      cardList.forEach((element) => {
        this.state.btns.push(
          <div
            className="card"
            // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
          >
            <img
              className="cardimg"
              src={
                element.image
                  ? element.image
                  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
              }
            />
            <h4>{element.name}</h4>
            <div className="card-description scroll-cstm">
              <p
                dangerouslySetInnerHTML={{
                  __html: element.description,
                }}
              ></p>
            </div>
            <button
              className="buttonStyleWidget 7"
              onClick={() => {
                this.setupHotel(type, element);
              }}
            >
              {"Select"}
            </button>
          </div>
        );
      });
    } else {
      cardList.forEach((element) => {
        this.state.btns.push(
          <button
            className={"buttonStyleV"}
            style={{
              borderColor: this.state.customisation.botButtonColor,
              color: this.state.customisation.botButtonColor,
              fontSize: this.state.customisation.buttonfontSize,
              fontFamily: this.state.customisation.buttonfontStyle,
            }}
            onClick={() => {
              this.setupHotel(type, element);
            }}
          >
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              {element.name}
            </div>
          </button>
        );
      });
    }

    if (type == "subcat" && prodList.length > 0) {
      prodList.forEach((element) => {
        this.state.btns.push(
          <div
            className="card"
            // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
          >
            <img
              className="cardimg"
              src={
                element.image
                  ? element.image
                  : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
              }
            />
            <h4>{element.name}</h4>
            <div className="card-description scroll-cstm">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    element.description +
                    "Price:" +
                    element.price +
                    this.state.foodCurrency +
                    (element.offeredDiscount
                      ? "Discount %:" + element.offeredDiscount
                      : ""),
                }}
              ></p>
            </div>
            <button
              className="buttonStyleWidget 8"
              onClick={() => {
                this.setupHotel("product", element);
              }}
            >
              {"Select"}
            </button>
          </div>
        );
      });
    }
    this.state.list.pop();
    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{
              __html:
                type == "subcat"
                  ? "Please select a sub category from the list below."
                  : "Please select a category from the list below",
            }}
          />
        </div>
      </div>
    );
    if (prodList.length > 0) {
      this.state.list.push(
        <div
          className="bottomButtons 5"
          style={{
            display: "inline-flex",
            overflowX: "scroll",
            width: "92%",
            maxHeight: "250px",
          }}
        >
          {this.state.btns.length > 1 ? (
            <span className="PreviousCard" onClick={this.CardPrev}>
              <img id="CardArrow" src={this.image_addedon + left} />
            </span>
          ) : null}

          <div className="bootSlider">{this.state.btns}</div>
          {this.state.btns.length > 1 ? (
            <span className="NextCard" onClick={this.CardNext}>
              <img id="CardArrow" src={this.image_addedon + right} />{" "}
            </span>
          ) : null}
        </div>
      );
    } else {
      this.state.list.push(
        <div className="bottomButtons 8" style={{ display: "block" }}>
          {this.state.btns}
        </div>
      );
    }
    try {
      s_view.style.height = `calc(100% - ${
        125 - (this.state.customisation.isBTransparent ? 50 : 0)
      }px)`;
    } catch (error) {}
    this.setState({});
  };

  setupProduct = (cardList, isSearch) => {
    if (isSearch) {
      this.state.list.pop();
    }

    console.log(cardList, "settingupcatcard");

    // var cardList = steps['' +this.state.cId + '']["data"]["cardsOptions"]
    // this.state.buttonType = "single"
    cardList.forEach((element) => {
      this.state.btns.push(
        <div
          className="card"
          // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
        >
          <img
            className="cardimg"
            src={
              element.productImage
                ? element.productImage[0].url
                : "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
            }
          />
          <h4>{element.name}</h4>
          <div className="card-description scroll-cstm">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  element.description +
                  "Price:" +
                  element.price +
                  this.state.foodCurrency +
                  (element.offeredDiscount
                    ? "Discount %:" + element.offeredDiscount
                    : ""),
              }}
            ></p>
          </div>
          <button
            className="buttonStyleWidget 9"
            onClick={() => {
              this.setupHotel("product", element);
            }}
          >
            {"Select"}
          </button>
        </div>
      );
    });
    if (isSearch) {
      this.state.btns.push(
        <div
          className="
			  "
          // style={{background:this.state.customisation.isBTransparent?"#fff":"none"}}
        >
          <img
            className="cardimg"
            src={
              "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbot_icon%2Ffavicon.webp?alt=media&token=1eb33145-b8e7-4d2c-bc00-80a342dd4c13"
            }
          />
          <h4>{"Menu"}</h4>
          <div className="card-description scroll-cstm">{""}</div>
          <button
            className="buttonStyle 10"
            style={{
              fontSize: "10px",
              borderColor: this.state.customisation.botButtonColor,
              color: this.state.customisation.botButtonColor,
              width: "90%",
              display: "block",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => {
              this.setupHotel("hotel", this.state.baseHotel);
            }}
          >
            {"Go Home"}
          </button>
        </div>
      );
      if (!firstSearch) {
        this.state.list.push(
          <div style={{ display: "flex" }}>
            <img
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                alignSelf: "flex-end",
                marginLeft: 10,
                borderRadius: 25,
              }}
              src={this.state.customisation.chatBotAvtar}
            />
            <div
              className="bot"
              style={{
                color: this.state.customisation.botTextcolor,
                background: this.state.customisation.messagebubble,
              }}
            >
              {/* {parse(this.checkUrl(hnd.msg))} */}

              <p
                style={{
                  margin: 0,
                  fontSize: this.state.customisation.fontSize,
                  fontFamily: this.state.customisation.fontStyle,
                }}
                dangerouslySetInnerHTML={{
                  __html: `Here are the search results. Search again or tap on last button to see home Menu.`,
                }}
              />
            </div>
          </div>
        );
      }
      firstSearch = true;
    }
    this.state.list.pop();
    this.state.list.push(
      <div
        className="bottomButtons 6"
        style={{
          display: "inline-flex",
          overflowX: "scroll",
          width: "92%",
          maxHeight: "250px",
        }}
      >
        {this.state.btns.length > 1 ? (
          <span className="PreviousCard" onClick={this.CardPrev}>
            <img id="CardArrow" src={this.image_addedon + left} />
          </span>
        ) : null}

        <div className="bootSlider">{this.state.btns}</div>
        {this.state.btns.length > 1 ? (
          <span className="NextCard" onClick={this.CardNext}>
            <img id="CardArrow" src={this.image_addedon + right} />{" "}
          </span>
        ) : null}
      </div>
    );
    try {
      s_view.style.height = `calc(100% - ${
        125 - (this.state.customisation.isBTransparent ? 50 : 0)
      }px)`;
    } catch (error) {}
    this.setState({});
  };
  setupToppings = (index) => {
    this.state.pickToppings = true;
    var product = this.state.baseDish.customOption;
    console.log(product, "settingupcatcard", index);

    // var cardList = steps['' +this.state.cId + '']["data"]["cardsOptions"]
    this.state.buttonType = "multiple";
    this.state.maxTop = product[index].option_max
      ? product[index].option_max
      : 1;

    product[index].row.forEach((element, ind) => {
      // this.state.btns.push(<button className={"buttonStyleV"} style={{ borderColor: this.state.customisation.botButtonColor, color: this.state.customisation.botButtonColor }} onClick={() => this.buttonfunction(element, index)}>{element.name}</button>)
      this.state.btns.push(
        <button
          className={
            this.state.buttonType == "multiple"
              ? "buttonStyleV"
              : "buttonStyleV"
          }
          style={{
            borderColor: this.state.customisation.botButtonColor,
            color: this.state.customisation.botButtonColor,
            fontSize: this.state.customisation.buttonfontSize,
            fontFamily: this.state.customisation.buttonfontStyle,
          }}
          onClick={() => this.handleToppings(element, ind, product[index])}
        >
          <span>
            <input
              className="checkboxButton"
              id={`checkbox${ind}`}
              type="checkbox"
            />

            <span
              className={
                this.state.buttonType == "multiple" ? "checkButtonText" : ""
              }
            >
              {element.row_title} {"Price: " + element.row_price}
            </span>
          </span>
        </button>
      );
    });
    this.state.toppingsText = product[index].title;
    this.state.list.push(
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{ __html: product[index].title }}
          />
        </div>
      </div>
    );
    this.state.list.push(
      <div className="bottomButtons 7" style={{ display: "flex" }}>
        {/* <p>{this.state.toppingError}</p> */}
        {this.state.btns}
      </div>
    );
    try {
      var s_view = document.getElementById("Testing");
      s_view.style.height = `calc(100% - ${
        175 - (this.state.customisation.isBTransparent ? 50 : 0)
      }px)`;
    } catch (error) {}
    this.setState({});
  };
  handleToppings = (element, index, custom) => {
    if (element == "submit") {
      this.state.list.pop();
      console.log(this.state.toppingList, "TOPLIST");
      this.state.toppingPrice = 0;
      if (this.state.toppingList.length > 0) {
        let topText = "";
        let text = '<ul style="padding-left: 10px; margin :0px">';
        let customobj = this.state.foodCart[this.state.dishName].data[
          "customobj"
        ]
          ? this.state.foodCart[this.state.dishName].data["customobj"]
          : "";
        console.log(customobj, "customobj1");
        if (this.state.toppingIndex != 0) {
          customobj = customobj + "|";
        }
        for (var i = 0; i < this.state.toppingList.length; i++) {
          // text = text + "•" + event[i]+"<br>"
          text =
            text +
            "<li  style='text-indent: -18px; padding-left: 15px'>" +
            this.state.toppingList[i].name +
            " </li>";
          topText += this.state.toppingList[i].name;
          this.state.toppingPrice += parseInt(this.state.toppingList[i].price);
          customobj =
            customobj +
            (customobj == "" || i == 0 ? "" : ",") +
            this.state.toppingList[i].name;
          console.log(customobj, "customobj2", this.state.toppingList[i].name);
        }
        text = text + "</ul>";
        var dt = this.state.foodCart[this.state.dishName].data["customised"]
          ? this.state.foodCart[this.state.dishName].data["customised"]
          : [];
        var bt = {};
        bt[this.state.toppingsText] = topText.toString();
        dt.push(JSON.stringify(bt));
        this.state.foodCart[this.state.dishName].data["customised"] = dt;
        this.state.foodCart[this.state.dishName].data["customobj"] = customobj;

        this.state.foodCart[this.state.dishName].topPrice =
          (this.state.foodCart[this.state.dishName].topPrice
            ? this.state.foodCart[this.state.dishName].topPrice
            : 0) + this.state.toppingPrice;
        this.state.foodCart[this.state.dishName].price =
          this.state.foodCart[this.state.dishName].data.price;
        this.state.foodCart[this.state.dishName].prodDiscount = this.state
          .foodCart[this.state.dishName].data.offeredDiscount
          ? parseFloat(
              this.state.foodCart[this.state.dishName].data.offeredDiscount
            )
          : 0;

        // this.state.toppingPrice=0
        // this.state.foodCart[]

        this.state.list.push(
          <div className="sendMessage flex items-baseline mb-5">
            <div
              className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
              style={{
                background: this.state.customisation.clientBubbleClr,
                color: this.state.customisation.clientchattextcolor,
              }}
            >
              <p className="text-[#fff]  text-sm leading-6"
                style={{
                  margin: 0,
                  fontSize: this.state.customisation.fontSize,
                  fontFamily: this.state.customisation.fontStyle,
                }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {/* {text} */}
            </div>
            <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
              <img
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  borderWidth: 1,
                  borderRadius: 25,
                }}
                src={this.image_addedon + user}
              />
            </span>
            
          </div>
        );
        // this.state.toppingIndex=0
      }
      this.state.toppingPrice = 0;
      this.state.toppingList = [];
      this.state.btns = [];
      this.state.pickToppings = false;
      this.state.toppingIndex = this.state.toppingIndex + 1;
      console.log("submitToppings", this.state.foodCart);
      this.setState({});
      // console.log("SHOWCART",this.state.baseDish.customOption.length,this.state.toppingIndex)

      if (this.state.baseDish.customOption.length == this.state.toppingIndex) {
        console.log("SHOWCART");
        this.simplifyCart();
        this.showHotelFinal();
        // ASKCARTOPTIONS
      } else {
        this.setupToppings(this.state.toppingIndex);
      }
    } else {
      try {
        var Selectbutton = document.getElementsByClassName("buttonStyleV");
        var Selectbox = document.getElementById(`checkbox${index}`);
        var condition = this.state.toppingList.filter(function (obj) {
          return obj.name === element.row_title;
        });
        // if (this.state.toppingList.includes(element.row_title)) {
        if (condition.length > 0) {
          // this.state.toppingList.splice(this.state.toppingList.indexOf(element.name), 1)
          this.state.toppingList = this.state.toppingList.filter(function (
            obj
          ) {
            return obj.name !== element.row_title;
          });
          Selectbutton[index].style.color =
            this.state.customisation.botButtonColor;
          Selectbutton[index].style.backgroundColor = "rgb(255,255,255)";

          Selectbox.checked = false;
          Selectbutton[index].classList.remove("selected");

          //   console.log(Selectbutton[index].style,"STYLETOPremove")

          if (this.state.toppingList.length == 0) {
            // if (this.state.isOptional) {
            //     this.state.isOptionalButton = true
            // }
          }
          this.setState({});
        } else if (this.state.toppingList.length < this.state.maxTop) {
          Selectbutton[index].style.color =
            this.state.customisation.clientchattextcolor;
          Selectbutton[index].style.backgroundColor =
            this.state.customisation.clientBubbleClr;
          Selectbox.checked = true;
          Selectbutton[index].classList.add("selected");
          // text = element.name;
          this.state.toppingList.push({
            name: element.row_title,
            price: element.row_price,
          });

          if (this.state.toppingList.length == 1) {
            this.state.isOptionalButton = false;
          }
          this.setState({});
        } else {
          // raise error
          //   Selectbutton[index].style.backgroundColor = "rgb(255,255,255)";
          //   Selectbutton[index].style.color =
          // 	this.state.customisation.clientBubbleClr;
          //   Selectbox.checked = false;
        }
        console.log(this.state.toppingList, "stringmult");
      } catch (err) {
        console.log(err);
      }
    }
  };
  showHotelFinal = () => {
    this.state.toppingIndex = 0;
    this.state.buttonType = "single";
    this.state.btns.push(
      <button
        className={"buttonStyleV"}
        style={{
          borderColor: this.state.customisation.botButtonColor,
          color: this.state.customisation.botButtonColor,
          fontSize: this.state.customisation.buttonfontSize,
          fontFamily: this.state.customisation.buttonfontStyle,
        }}
        onClick={() => {
          this.setupHotel("hotel", this.state.baseHotel, true);
        }}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {"Add More Items"}
        </div>
      </button>
    );
    this.state.btns.push(
      <button
        className={"buttonStyleV"}
        style={{
          borderColor: this.state.customisation.botButtonColor,
          color: this.state.customisation.botButtonColor,
          fontSize: this.state.customisation.buttonfontSize,
          fontFamily: this.state.customisation.buttonfontStyle,
        }}
        onClick={() => {
          this.setupHotel("showcart", this.state.baseHotel, true);
        }}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {"View Cart"}
        </div>
      </button>
    );
    this.state.btns.push(
      <button
        className={"buttonStyleV"}
        style={{
          borderColor: this.state.customisation.botButtonColor,
          color: this.state.customisation.botButtonColor,
          fontSize: this.state.customisation.buttonfontSize,
          fontFamily: this.state.customisation.buttonfontStyle,
        }}
        onClick={() => {
          this.checkoutFood();
        }}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {"Complete order"}
        </div>
      </button>
    );

    this.state.list.push(
      <div className="bottomButtons 8" style={{ display: "block" }}>
        {this.state.btns}
      </div>
    );

    this.setState({});
  };

  simplifyCart = () => {
    var dish = this.state.dishName.slice(0, -1);
    console.log(this.state.foodCart, "HANDLEFOODCART");
    for (let element in this.state.foodCart) {
      if (element == this.state.dishName) {
        continue;
      }
      if (dish == this.state.foodCart[element].data.name) {
        console.log(this.state.foodCart, "SameItem");

        if (
          JSON.stringify(this.state.foodCart[element].data.customised) ==
          JSON.stringify(
            this.state.foodCart[this.state.dishName].data.customised
          )
        ) {
          // delete this.state.foodCart[]
          this.state.foodCart[dish].quantity =
            this.state.foodCart[dish].quantity + 1;
          delete this.state.foodCart[this.state.dishName];
          console.log(this.state.foodCart, "SamesameItem");
        } else {
          console.log(this.state.foodCart, "SamecustomisedItem");
        }
      }
    }
  };
  checkoutFood = async (flag) => {
    if (flag) {
      // this.state.list.pop();
      // this.state.list.pop();
    }
    this.state.showCheckout = true;
    this.state.topPriceAll = 0;
    this.state.foodDiscountCost = 0;
    this.state.foodDiscountVendorCost = 0;
    this.state.foodTaxCost = 0;
    this.state.foodMiscTaxCost = 0;
    this.state.foodSubTotal = 0;
    this.state.foodTip = 0;
    this.state.foodCouponDiscount = 0;
    this.state.foodDeliveryCharge = 0;
    this.state.foodCentActive = "";

    console.log(this.state.foodCart);
    var productList = [];
    for (let item in this.state.foodCart) {
      this.state.foodSubTotal +=
        parseFloat(this.state.foodCart[item].price) *
          this.state.foodCart[item].quantity +
        parseFloat(this.state.foodCart[item].topPrice) *
          this.state.foodCart[item].quantity;
      this.state.foodDiscountVendorCost +=
        parseFloat(this.state.foodCart[item].price) *
        (this.state.foodCart[item].prodDiscount / 100) *
        this.state.foodCart[item].quantity;

      this.state.topPriceAll =
        parseFloat(this.state.foodCart[item].topPrice) *
        this.state.foodCart[item].quantity;
      productList.push({
        productId: this.state.foodCart[item].data.id,
        name: this.state.foodCart[item].name,
        price:
          this.state.foodCart[item].price + this.state.foodCart[item].topPrice,
        qty: this.state.foodCart[item].quantity,
        description: this.state.foodCart[item].data.description,
        currency: this.state.foodCart[item].data.currency,
        custom_option: this.state.foodCart[item].data.customobj,
      });
    }
    this.state.foodPaymentObj["productDetails"] = productList;

    var paymentList = {
      paymentId: "",
      paymentMethod: "online",
      paymentMethodLabel: "online",
      paymentStatus: "processing",
      orderDate: new Date().getTime() / 1000,
      gmtTime: "GMT+0530",
      orderType: "delivery",
      deliveryPickupTime: "",
      deviceType: "chatbot",
      deviceId: this.props.botId,
      deviceToken: "",
      cust_lat: this.state.gMapLat,
      cust_long: this.state.gMapLong,
    };
    this.state.foodPaymentObj["payment"] = paymentList;

    // "payment": {
    //     "paymentId": "",
    //     "paymentMethod": "online",
    //     "paymentMethodLabel": "online",
    //     "paymentStatus": "processing",
    //     "orderDate": "1587586870",
    //     "gmtTime": "GMT+0530",
    //     "orderType": "delivery",
    //     "deliveryPickupTime": "4/23/2020 2:36",
    //     "deviceType": "android",
    //     "deviceId": "3ad373f3881183813f2d4ab7bf39",
    //     "deviceToken": "cZiHjVthMOM:APA91bGOv_UtIzmtlZ2L0zNAm58rGfXz2iJTBqbw4oRA5986MlKBEnGVXaDVTAvTFNaEwUep0gCf-_wtHDdSmEDZzlGHVxjIxr7lFXu7fWcUyNlppc22xfNiLM4bJamJPwHIMqajkDpJ",
    //     "cust_lat": "28.5355161",
    //     "cust_long": "77.3910265"
    //   },

    console.log(
      "foodsubtotaldefault",
      this.state.foodSubTotal,
      "+",
      this.state.topPriceAll
    );

    // this.state.foodPaymentObj["discount"] = {
    //   vendordiscount:
    //     ((this.state.foodSubTotal + this.state.topPriceAll) *
    //       parseFloat(
    //         this.state.baseHotel.vendor_discount
    //           ? this.state.baseHotel.vendor_discount
    //           : 0
    //       )) /
    //     100,
    // };

    // this.state.foodSubTotal =
    //   this.state.foodSubTotal *
    //   (1 -
    //     parseFloat(
    //       this.state.baseHotel.vendor_discount
    //         ? this.state.baseHotel.vendor_discount
    //         : 0
    //     ) /
    //       100);
    // this.state.topPriceAll =
    //   this.state.topPriceAll *
    //   (1 -
    // 	parseFloat(
    // 	  this.state.baseHotel.vendor_discount
    // 		? this.state.baseHotel.vendor_discount
    // 		: 0
    // 	) /
    // 	  100);
    console.log(
      "foodsubtotaldefaultafterVD",
      this.state.foodSubTotal,
      "+",
      this.state.topPriceAll
    );
    if (this.state.foodDelivery) {
      if (this.state.foodDelivery.is_delivery_free) {
        this.state.foodDeliveryCharge = 0.0;
      } else if (this.state.foodDelivery.delivery_type == "fixed") {
        this.state.foodDeliveryCharge =
          this.state.foodDelivery.delivery_charges;
      } else if (this.state.foodDelivery.delivery_type == "distance") {
        let pricejson = JSON.parse(this.state.foodDelivery.charges_per_unit);
        console.log(
          pricejson,
          "pricejson",
          pricejson.length,
          this.state.foodDistance
        );
        let pricearr = pricejson[0];
        if (pricejson.length > 1) {
          let dist = this.state.foodDistance;
          pricearr = pricejson.filter(function (obj) {
            return (
              parseFloat(obj.distancefrom) <= dist &&
              parseFloat(obj.distanceto) >= dist
            );
          })[0];
          console.log(
            pricearr,
            "parse",
            "FOODDELIVERYCHARGE1",
            this.state.foodDistance,
            pricearr.distancefixper,
            pricearr.distancePrice
          );

          if (pricearr.distancefixper == "per") {
            this.state.foodDeliveryCharge = parseFloat(
              (
                parseFloat(pricearr.distancePrice).toFixed(2) *
                this.state.foodDistance
              ).toFixed(2)
            );
          } else if (pricearr.distancefixper == "Fixed") {
            this.state.foodDeliveryCharge = parseFloat(pricearr.distancePrice);
          }
        } else {
          this.state.foodDeliveryCharge = parseFloat(
            (
              parseFloat(pricearr.distancePrice).toFixed(2) *
              this.state.foodDistance
            ).toFixed(2)
          );
        }
      }
    }
    console.log("foodDel", this.state.foodDeliveryCharge);

    this.state.foodDiscount.forEach((element, ind) => {
      if (element.discountType == "Flat") {
        if (
          element.discountRule &&
          this.state.foodSubTotal >= parseFloat(element.totalAmount)
        ) {
          this.state.foodDiscountRuleCost =
            parseFloat(element.discountPrice) < this.state.foodDiscountRuleCost
              ? parseFloat(element.discountPrice)
              : this.state.foodDiscountRuleCost;
        }
        // this.state.foodSubTotal+topPriceAll
      } else if (element.discountType == "Percentage") {
        if (
          element.discountRule &&
          this.state.foodSubTotal >= parseFloat(element.totalAmount)
        ) {
          this.state.foodDiscountRuleCost =
            (parseFloat(element.discountPrice) * this.state.foodSubTotal) /
              100 >
            this.state.foodDiscountRuleCost
              ? (parseFloat(element.discountPrice) * this.state.foodSubTotal) /
                100
              : this.state.foodDiscountRuleCost;
        }
      }
    });

    console.log("foodDisRule", this.state.foodDiscountRuleCost);
    // await this.handleCoupon();

    this.state.foodTax.forEach((element, ind) => {
      if (element.taxRate == "Flat") {
        if (
          element.taxRule &&
          this.state.foodSubTotal >= parseFloat(element.totalAmount)
        ) {
          this.state.foodTaxCost =
            parseFloat(element.taxPrice) < this.state.foodTaxCost
              ? parseFloat(element.taxPrice)
              : this.state.foodTaxCost;
        }
        // this.state.foodSubTotal+topPriceAll
      } else if (element.taxRate == "Percentage") {
        if (
          element.taxRule &&
          this.state.foodSubTotal >= parseFloat(element.totalAmount)
        ) {
          this.state.foodTaxCost =
            (parseFloat(element.taxPrice) * this.state.foodSubTotal) / 100 >
            this.state.foodTaxCost
              ? (parseFloat(element.taxPrice) * this.state.foodSubTotal) / 100
              : this.state.foodTaxCost;
        }
      }
    });

    this.state.foodMiscTax.forEach((element, ind) => {
      if (element.taxRate == "Flat") {
        this.state.foodMiscTaxCost =
          parseFloat(element.taxAmount) + this.state.foodMiscTaxCost;

        this.state.foodPaymentObj["miscTax"][ind]["tax"] = parseFloat(
          parseFloat(element.taxAmount).toFixed(2)
        );
        this.state.foodPaymentObj["miscTax"][ind]["maxTaxAmount"] = parseFloat(
          element.taxAmount
        ).toFixed(2);

        // ? parseFloat(element.taxPrice)
        // : this.state.foodTaxCost;

        // this.state.foodSubTotal+topPriceAll
      } else if (element.taxRate == "Percentage") {
        //   if (
        //     element.taxRule &&
        //     this.state.foodSubTotal + this.state.topPriceAll >=
        //       parseFloat(element.totalAmount)
        //   ) {
        this.state.foodMiscTaxCost =
          (parseFloat(element.taxAmount) * this.state.foodSubTotal) / 100 +
          this.state.foodMiscTaxCost;
        this.state.foodPaymentObj["miscTax"][ind]["tax"] = parseFloat(
          (
            (parseFloat(element.taxAmount) * this.state.foodSubTotal) /
            100
          ).toFixed(2)
        );
        this.state.foodPaymentObj["miscTax"][ind]["maxTaxAmount"] = (
          (parseFloat(element.taxAmount) * this.state.foodSubTotal) /
          100
        ).toFixed(2);

        //   }
      }
    });
    console.log(
      "foodTaxRule",
      this.state.foodTaxCost,
      this.state.foodMiscTaxCost
    );

    console.log(
      this.state.foodDeliveryCharge,
      "FOODDELIVERYCHARGE",
      typeof this.state.foodDeliveryCharge
    );

    this.state.foodTotal =
      this.state.foodSubTotal -
      //   this.state.topPriceAll -
      this.state.foodDiscountRuleCost -
      this.state.foodDiscountVendorCost -
      this.state.foodCouponDiscount +
      this.state.foodDeliveryCharge +
      this.state.foodTip +
      this.state.foodTaxCost +
      this.state.foodMiscTaxCost;
    this.state.foodPaymentObj["discount"] = {
      vendordiscount: this.state.foodDiscountVendorCost.toFixed(2),
      discount: this.state.foodDiscountRuleCost.toFixed(2),
      delivery: this.state.foodDeliveryCharge,
      tax: this.state.foodTaxCost.toFixed(2),
      coupon: this.state.foodCouponDiscount.toFixed(2),
      tip: "0",
      total: this.state.foodTotal.toFixed(2),
      subtotal: this.state.foodSubTotal.toFixed(2),
    };
    console.log(this.state.foodPaymentObj, "this.state.foodpayobj");
    // this.state.Input = (
    //   <div>
    //     <div
    //       style={{
    //         height: "350px",
    //         maxWidth: "100%",
    //         background: "white",
    //         border: "0px solid #a0a096",
    //         fontFamily: "Arial, Helvetica, sans-serif",
    //         lineHeight: "1.125em",
    //         boxShadow: "0 0 8px rgba(0, 0, 0, 0.09)",
    //         margin: "auto",
    //       }}
    //     >
    //       <button
    //         onClick={() => {
    //           this.setState({ Input: null });
    //         }}
    //       >
    //         Close checkout
    //       </button>
    //       <div>List of Cart</div>
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html: "Subtotal:" + this.state.foodSubTotal.toFixed(2),
    //         }}
    //       />
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html:
    //             "Discount:" +
    //             (
    //               this.state.foodDiscountRuleCost +
    //               this.state.foodDiscountVendorCost
    //             ).toFixed(2),
    //         }}
    //       />
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html:
    //             "Coupon Discount:" + this.state.foodCouponDiscount.toFixed(2),
    //         }}
    //       />
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html:
    //             "Delivery Charge:" + this.state.foodDeliveryCharge.toFixed(2),
    //         }}
    //       />
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html:
    //             "Tax:" +
    //             (this.state.foodTaxCost + this.state.foodMiscTaxCost).toFixed(
    //               2
    //             ),
    //         }}
    //       />

    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html: "Tip:" + this.state.foodTip.toFixed(2),
    //         }}
    //       />
    //       <p
    //         dangerouslySetInnerHTML={{
    //           __html: "Total Charge:" + this.state.foodTotal.toFixed(2),
    //         }}
    //       />
    //       <button
    //         onClick={() => {
    //           this.makePaymentUrl();
    //         }}
    //       >
    //         Proceed to Pay
    //       </button>
    //     </div>
    //   </div>
    // );
    this.setInput(flag);
    //   this.state.Input=(<body>

    //     <div class="checkout-container">
    //         <h3>Cart Details</h3>
    //         <span class="close-icon"></span>
    //         <div class="product-details">
    //             <div class="p-head">
    //                 <div class="head-title">
    //                     <img src="https://thumbs.dreamstime.com/b/pizza-rustic-italian-mozzarella-cheese-basil-leaves-35669930.jpg"
    //                         alt="product image" />
    //                     {/* <div class="p-name">
    //                         <span>Medium Veggie Feast - 1Pc</span>
    //                         <small>Hyrbed Onion & Green Cap ffgfdg dfgf</small>
    //                     </div> */}
    //                     <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 19.499 23.995">
    //                         <path id="Union_2" data-name="Union 2"
    //                             d="M8978.053-5417a2.247,2.247,0,0,1-2.246-2.142l-.618-12.985a.236.236,0,0,1,.234-.246h16.65a.238.238,0,0,1,.235.246l-.621,12.985a2.246,2.246,0,0,1-2.246,2.142Zm8.694-13.5v9.749a.75.75,0,0,0,.749.749.751.751,0,0,0,.752-.749v-9.749a.75.75,0,0,0-.752-.749A.749.749,0,0,0,8986.747-5430.5Zm-3.749,0v9.749a.75.75,0,0,0,.749.749.751.751,0,0,0,.752-.749v-9.749a.75.75,0,0,0-.752-.749A.749.749,0,0,0,8983-5430.5Zm-3.749,0v9.749a.749.749,0,0,0,.749.749.751.751,0,0,0,.752-.749v-9.749a.75.75,0,0,0-.752-.749A.747.747,0,0,0,8979.249-5430.5Zm-4.5-3.374a.751.751,0,0,1-.752-.75v-1.5a1.875,1.875,0,0,1,1.876-1.875H8980v-.75a2.249,2.249,0,0,1,2.25-2.248h3a2.25,2.25,0,0,1,2.25,2.248v.75h4.124a1.875,1.875,0,0,1,1.876,1.875v1.5a.751.751,0,0,1-.752.75Zm6.748-4.875v.75h4.5v-.75a.75.75,0,0,0-.749-.75h-3A.751.751,0,0,0,8981.5-5438.751Z"
    //                             transform="translate(-8973.999 5440.999)" fill="#f54a4a" />
    //                     </svg>
    //                 </div>
    //             </div>
    //             <div class="p-body">
    //                 <ul>
    //                     <li>
    //                         <span>Subtotal</span>
    //                         <span>Rs 320.00</span>
    //                     </li>
    //                     <li>
    //                         <span>Taxes & charges</span>
    //                         <span>Rs 20.00</span>
    //                     </li>
    //                     <li class="row-highlight">
    //                         <span>Delivery Charges</span>
    //                         <span>Rs 5.00</span>
    //                     </li>
    //                     <li class="row-highlight">
    //                         <span>Taxes & Misc</span>
    //                         <span>Rs 15.00</span>
    //                     </li>
    //                     <li class="t-amount">
    //                         <span>Total Amount</span>
    //                         <span>Rs 340.00</span>
    //                     </li>
    //                 </ul>
    //             </div>
    //         </div>

    //         <div class="apply-coupon">
    //             <input type="text" placeholder="Enter Coupon Code" />
    //             <button class="btn-cont">Apply</button>
    //         </div>
    //         <div class="description">
    //             <textarea class="my-textarea" placeholder="Enter your instruction here"></textarea>
    //         </div>
    //         <div class="tip-section">
    //             <h4>Tip</h4>
    //             <div class="tip-amount">
    //                 <button class="btn-cont" onClick={(this.state.foodTip==0.1*this.state.foodSubTotal)}> 10%</button>
    //                 <button class="btn-cont" onClick={(this.state.foodTip==0.15*this.state.foodSubTotal)}> 15%</button>
    //                 <button class="btn-cont" onClick={(this.state.foodTip==0.2*this.state.foodSubTotal)}>20%</button>
    //                 <input placeholer="Other" value={this.state.foodTip} onChange={(e) =>
    //                                       this.setState({
    //                                         foodTip: e.target.value,
    //                                       })
    //                                     } class="btn-cont"/>
    //             </div>
    //         </div>

    //         <div class="checkout-btn">
    //             <button class="btn-cont">Checkout</button>
    //         </div>
    //     </div>

    // </body>)
    this.setState({});
  };
  closeCheckout = () => {
    console.log("closeCheckout");
    this.setState({ Input: null });
  };

  setInput = (flag) => {
    console.log(
      "sub",
      this.state.foodSubTotal,
      "disc",
      this.state.foodDiscountCost,
      "rule",
      this.state.foodDiscountRuleCost,
      "vendor",
      this.state.foodDiscountVendorCost,
      "coupon",
      this.state.foodCouponDiscount,
      "tip",
      this.state.foodTip,
      "tax",
      this.state.foodTaxCost,
      "misc",
      this.state.foodMiscTaxCost,
      "deliv",
      this.state.foodDeliveryCharge
    );
    this.state.Input = (
      <div className="checkout-container">
        <div className="innerCheckout">
          <div className="CheckOutTitle">
            <h3 style={{ margin: "0px" }}>Checkout Details</h3>
            {/* <span className="crose" onClick={this.setState({Input:null})}>X</span> */}
            <span
              className="close-icon"
              onClick={() => {
                this.closeCheckout();
              }}
            ></span>
          </div>
          {/* <span className="close-icon" onClick={this.setState({Input:null})}></span> */}
          <div className="product-details">
            <div className="p-body">
              <ul style={{ padding: 0 }}>
                <li>
                  <span>Subtotal</span>
                  <span>
                    {this.state.foodCurrency + " "} {this.state.foodSubTotal}
                  </span>
                </li>
                <li>
                  <span>Discount</span>
                  <span>
                    -{this.state.foodCurrency + " "}{" "}
                    {(
                      this.state.foodDiscountRuleCost +
                      this.state.foodDiscountVendorCost
                    ).toFixed(2)}
                  </span>
                </li>
                {this.state.foodCouponDiscount ? (
                  <li>
                    <span>Coupon Discount</span>
                    <span>
                      -{this.state.foodCurrency + " "}{" "}
                      {this.state.foodCouponDiscount.toFixed(2)}
                    </span>
                  </li>
                ) : null}

                <li>
                  <span>Taxes charges</span>
                  <span>
                    {this.state.foodCurrency + " "}{" "}
                    {(
                      this.state.foodDeliveryCharge +
                      this.state.foodTaxCost +
                      this.state.foodMiscTaxCost
                    ).toFixed(2)}
                  </span>
                </li>
                <li className="row-highlight">
                  <span>Delivery Charges</span>
                  <span>
                    {this.state.foodCurrency + " "}{" "}
                    {this.state.foodDeliveryCharge.toFixed(2)}
                  </span>
                </li>
                <li className="row-highlight">
                  <span>Taxes Misc</span>
                  <span>
                    {this.state.foodCurrency + " "}{" "}
                    {(
                      this.state.foodTaxCost + this.state.foodMiscTaxCost
                    ).toFixed(2)}
                  </span>
                </li>
                {this.state.foodTip ? (
                  <li>
                    <span>Tip</span>
                    <span>
                      {this.state.foodCurrency + " "}{" "}
                      {this.state.foodTip.toFixed(2)}
                    </span>
                  </li>
                ) : null}
                <li className="t-amount">
                  <span>Total Amount</span>
                  <span>
                    {this.state.foodCurrency + " "}{" "}
                    {this.state.foodTotal.toFixed(2)}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="apply-coupon">
            <input
              id="coupon"
              type="text"
              placeholder="Enter Coupon Code"
              onChange={(e) => this.handleCouponText(e.target.value)}

              // onChange={(e) =>
              //   this.setState({
              //     couponText: e.target.value,
              //   })
              // }
            />
            <button
              className="btn-cont"
              onClick={() => {
                this.handleCoupon();
              }}
            >
              Apply
            </button>
          </div>
          <div className="description">
            <textarea
              className="my-textarea"
              placeholder="Enter your instruction here"
              // value={this.state.foodInstructions}
              onChange={(e) => this.handleInstructions(e.target.value)}
            >
              {this.state.foodInstructions}
            </textarea>
          </div>
          <div
            className="address"
            style={{ display: "block", fontSize: "14px" }}
          >
            <p style={{ margin: "0px", fontWeight: "bold" }}>Delivering to:</p>
            <textarea
              className="my-textarea"
              style={{ width: "100%" }}
              placeholder="Confirm your address."
              // value={this.state.Address}
              onChange={(e) => {
                this.handleAddress(e.target.value);
              }}
            >
              {this.state.AddressVariable}
            </textarea>
            <p style={{ margin: "5px" }}>{this.state.AddressFixed}</p>
          </div>
          <div
            className="address"
            style={{ display: "block", fontSize: "14px" }}
          >
            <p style={{ margin: "0px", fontWeight: "bold" }}>Name:</p>
            <textarea
              className="my-textarea"
              style={{ width: "100%" }}
              placeholder="Confirm your name."
              // value={this.state.Address}
              onChange={(e) => {
                this.handleAddress(e.target.value, "name");
              }}
            >
              {this.state.Name}
            </textarea>
          </div>
          <div
            className="address"
            style={{ display: "block", fontSize: "14px" }}
          >
            <p style={{ margin: "0px", fontWeight: "bold" }}>Number:</p>
            <textarea
              className="my-textarea"
              style={{ width: "100%" }}
              placeholder="Confirm your number."
              // value={this.state.Address}
              onChange={(e) => {
                this.handleAddress(e.target.value, "phone");
              }}
            >
              {this.state.Phone}
            </textarea>
          </div>
          <div
            className="address"
            style={{ display: "block", fontSize: "14px" }}
          >
            <p style={{ margin: "0px", fontWeight: "bold" }}>Email:</p>
            <textarea
              className="my-textarea"
              style={{ width: "100%" }}
              placeholder="Confirm your email."
              // value={this.state.Address}
              onChange={(e) => {
                this.handleAddress(e.target.value, "email");
              }}
            >
              {this.state.Email}
            </textarea>
          </div>
          <div className="tip-section">
            <div className="tip-amount">
              <span>Tip</span>
              <button
                className="btn-cont"
                id="tip10"
                onClick={() => {
                  this.handleTip(true, 0.1);
                }}
              >
                {" "}
                10%
              </button>
              <button
                className="btn-cont"
                id="tip15"
                onClick={() => {
                  this.handleTip(true, 0.15);
                }}
              >
                {" "}
                15%
              </button>
              <button
                className="btn-cont"
                id="tip20"
                onClick={() => {
                  this.handleTip(true, 0.2);
                }}
              >
                20%
              </button>
              <input
                placeholder="Custom"
                type="number"
                min="0"
                // oninput="validity.valid||(value='')"
                // value={this.state.foodTip}
                onChange={(e) => this.handleTip(false, e.target.value)}
                className="btn-cont"
              />
            </div>
          </div>
        </div>
        <div className="checkout-btn" id="checkoutbtn">
          <button
            className="btn-cont"
            id="checkoutbtnclick"
            onClick={() => {
              this.showPaymentMethods(flag);
            }}
          >
            Checkout
          </button>
          <div className="ld ld-ring ld-spin"></div>
        </div>
      </div>
    );
    this.setState({});
  };
  showPaymentMethods = async (flag) => {
    if (document.getElementById("checkoutbtn")) {
      document.getElementById("checkoutbtn").classList.add("running");
      document.getElementById("checkoutbtnclick").disabled = true;
    }

    // list.pop()
    this.state.list.pop();
    if (flag) {
      this.state.list.pop();
      this.state.list.pop();
    }
    this.state.list.push(
      <div className="BoxBot" style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{
              __html: "Select a payment Mode.",
            }}
          />
        </div>
      </div>
    );

    var body = JSON.stringify({
      method: "getCommonPaymentGatewayConfigurations",
      appId: this.state.foodAppId,
      pageName: "foodcourt",
      pageId: "foodcourt_1649657614016_73",
      userId: "0",
    });
    console.log(body);
    let payList = await fetch(
      "https://snappy.appypie.com/webservices/PaymentGateways.php",
      {
        method: "post",
        body: body,
        headers: { "Content-Type": "application/json" },
      }
    ).catch((e) => {
      console.log(e, "ERROR");
    });
    payList = await payList.json();
    console.log(payList, "paylist");
    this.state.buttonType = "single";
    this.state.btns = [];
    payList.paymentDetails.forEach((element, index) => {
      this.state.btns.push(
        <button
          className={"buttonStyleV"}
          id={"paymentMode" + index}
          style={{
            borderColor: this.state.customisation.botButtonColor,
            color: this.state.customisation.botButtonColor,
            fontSize: this.state.customisation.buttonfontSize,
            fontFamily: this.state.customisation.buttonfontStyle,
          }}
          onClick={() => {
            this.makePaymentUrl(element, index);
          }}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            {element.label}
          </div>
          <div className="ld ld-ring ld-spin"></div>
        </button>
      );
    });

    this.state.list.push(
      <div className="bottomButtons 8" style={{ display: "block" }}>
        {this.state.btns}
      </div>
    );

    this.setState({ Input: null });
    // makeButttons

    // payList.paymentDetails[0];
  };
  makePaymentUrl = async (payMode, index) => {
    this.state.Input = null;
    console.log(
      document.getElementById("paymentMode" + index),
      "paymentMode" + index
    );

    document.getElementById("paymentMode" + index).classList.add("running");

    document.getElementById("paymentMode" + index).disabled = true;
    console.log(this.state.foodPaymentObj);

    this.state.list.pop();

    this.state.list.push(
      <div className="sendMessage flex items-baseline mb-5">
        <div
          className="mesage bg-[#EDF1FF] rounded-b-2xl rounded-tl-2xl px-3 py-3 w-10/12"
          style={{
            background: this.state.customisation.clientBubbleClr,
            color: this.state.customisation.clientchattextcolor,
          }}
        >
          <p className="text-[#fff]  text-sm leading-6"
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
            dangerouslySetInnerHTML={{ __html: payMode.label }}
          />
          {/* {text} */}
        </div>
        <span className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 ml-2">
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            borderWidth: 1,
            borderRadius: 25,
          }}
          src={this.image_addedon + user}
        />
        </span>
        
      </div>
    );
    this.state.list.push(
      <div className="BoxBot" style={{ display: "flex" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <div
          className="bot"
          style={{
            color: this.state.customisation.botTextcolor,
            background: this.state.customisation.messagebubble,
          }}
        >
          {/* {parse(this.checkUrl(hnd.msg))} */}

          <p
            id="foodOrder"
            style={{
              margin: 0,
              fontSize: this.state.customisation.fontSize,
              fontFamily: this.state.customisation.fontStyle,
            }}
          >
            Your order is placed succesfully. Youll receive invoice and tracking
            details on your email.
          </p>
        </div>
      </div>
    );
    this.state.list.push(
      <div style={{ display: "-webkit-box" }}>
        <img
          style={{
            width: 40,
            height: 40,
            marginRight: 10,
            alignSelf: "flex-end",
            marginLeft: 10,
            borderRadius: 25,
          }}
          src={this.state.customisation.chatBotAvtar}
        />
        <img
          style={{ width: 70, height: 70 }}
          src={this.image_addedon + loader}
        />
      </div>
    );

    this.setState({});
    this.state.processing = false;
    this.onSendButtonPress("", true);
  };
  handleCouponText = (e) => {
    this.setState({
      couponText: e,
    });
  };
  // getDta(e){
  //   ( this.state.isDual == true ?
  //    this.setState({ finall: e.target.value }) : null)
  //    this.setState({ initiall: e.target.value })
     
  //     this.state.isDual == true ? this.dualMapFunction() : null
  //  }

  getDta(e) {
    if (this.state.isDual === true) {
        this.setState({ finall: e.target.value });
        this.dualMapFunction();
    }
    this.setState({ initiall: e.target.value });
}

  handleCoupon = async () => {
    console.log("couponcalled");
    var body = [
      `method=foodCoupon&appId=${this.state.foodAppId}&vendorId=${this.state.baseHotel.id}&couponCode=${this.state.couponText}`,
    ];
    var couponDetails = await this.hotelApi(body);
    console.log(couponDetails, "coupon");
    if (couponDetails.status == "success") {
      var element = couponDetails.coupon;
      if (element.discountType == "Flat") {
        this.state.foodCouponDiscount = parseFloat(element.couponDiscount);
      } else if (element.discountType == "percentage") {
        this.state.foodCouponDiscount =
          (parseFloat(element.couponDiscount) * this.state.foodSubTotal) / 100;
      }
    }
    // this.setState({});
    console.log("coupondone", this.state.foodCouponDiscount);
    this.state.foodTotal =
      this.state.foodSubTotal -
      //   this.state.topPriceAll -
      this.state.foodDiscountRuleCost -
      this.state.foodDiscountVendorCost -
      this.state.foodCouponDiscount +
      this.state.foodDeliveryCharge +
      this.state.foodTaxCost +
      this.state.foodMiscTaxCost +
      this.state.foodTip;
    console.log("coupondonetotal", this.state.foodTotal);
    this.setInput();

    this.setState({});
  };
  handleTip = async (percent, value) => {
    console.log("tipcalled", this.state.foodCentActive);
    if (percent) {
      if (this.state.foodCentActive) {
        console.log(
          "remove",
          document.getElementById("tip" + this.state.foodCentActive)
        );
        document
          .getElementById("tip" + this.state.foodCentActive)
          .classList.remove("active");
        console.log(
          "removed",
          document.getElementById("tip" + this.state.foodCentActive)
        );
      }

      console.log(
        "SAMECLICK",
        this.state.foodCentActive,
        parseInt(value * 100)
      );
      if (this.state.foodCentActive == parseInt(value * 100)) {
        this.state.foodTip = 0;
        this.state.foodCentActive = "";
      } else {
        this.state.foodTip =
          value * (this.state.foodTotal - this.state.foodTip);
        this.state.foodCentActive = parseInt(value * 100);
        document
          .getElementById("tip" + parseInt(value * 100))
          .classList.add("active");
      }
    } else {
      if (this.state.foodCentActive) {
        document
          .getElementById("tip" + this.state.foodCentActive)
          .classList.remove("active");
      }
      this.state.foodTip = value == "" ? 0 : Math.abs(parseFloat(value));
      this.state.foodCentActive = "";
    }
    console.log("tipdone", this.state.foodTip);
    this.state.foodTotal =
      this.state.foodSubTotal -
      //   this.state.topPriceAll -
      this.state.foodDiscountRuleCost -
      this.state.foodCouponDiscount -
      this.state.foodDiscountVendorCost +
      this.state.foodDeliveryCharge +
      this.state.foodTaxCost +
      this.state.foodMiscTaxCost +
      this.state.foodTip;
    this.setInput();
    this.setState({});
  };
  handleInstructions = async (value) => {
    this.setState({ foodInstructions: value });
  };
  handleAddress = async (value, type) => {
    if (type == "name") {
      this.state.Name = value;
      this.setState({});
    } else if (type == "phone") {
      this.state.Phone = value;
      this.setState({});
    } else if (type == "email") {
      this.state.Email = value;
      this.setState({});
    } else {
      this.setState({
        AddressVariable: value,
        Address: value + this.state.AddressFixed,
      });
    }
  };

  async getFile(event) {
    this.state.uploadingFile = true;
    this.state.showFileError = false;
    this.setState({});
    this.setState({ showHideEmoji: false });
    // if (!this.state.isLivechat) {
    //   event.target.value = null;
    //   //console.log('file upload not allowed')
    //   return;
    // }
    this.setState({ isSendingFile: true });
    // setTimeout(() => {
    //   console.log('file sending timed out')
    //   this.setState({isSendingFile:false});
    // }, 5000);

    var files = event.target.files;
    this.state.uploadCount = files.length;
    for (let i = 0; i < files.length; i++) {
      // files.forEach( ()=>{
      this.uploadFunctions(files[i], i);
      if (this.state.showFileError) {
        break;
      }
    }
  }
  uploadFunctions(file, index) {
    // var file = event.target.files[0];
    if (file == undefined) {
      this.state.uploadingFile = false;
      this.state.uploadFileButton = false;
      this.state.sendListButton = false;
      return;
    }
    this.state.files.push(file);
    var ext = file.name.split(".");
    ext = ext[ext.length - 1];
    ext = ext.toLowerCase();
    var checklimit = 15;
    var checkExt = [
      "txt",
      "doc",
      "pdf",
      "docx",
      "xls",
      "docx",
      "mov",
      "heic",
      "jpg",
      "png",
      "gif",
      "jpeg",
      "csv",
      "mp4",
      "mkv",
      "wmv",
      "avi",
      "avchd",
      "flv",
      "f4v",
      "swf",
      "mov",
      "webm",
      "html5",
      "tif",
      "tiff",
      "gif",
      "eps",
      "raw",
      "xlsb",
      "xlsx",
      "tsv",
      "zip",
      "bmp",
    ];
    console.log(this.state.uploadType, "uploaddd");
    if (this.state.uploadType == "image") {
      checkExt = ["jpeg", "jpg", "png", "heic"];
    } else if (this.state.uploadType == "video") {
      checkExt = ["mov", "mp4", ".mov"];
      checklimit = 20;
    }

    // var file = event.target.files[0];
    // this.state.files.push(file);
    // var fileRef = storageRef.child(file.name);

    // var checkExt = ["txt", "doc", "pdf", "docx", "xls", "docx"];
    // var ext = file.name.split(".");
    // console.log(ext, ext[ext.length - 1], !checkExt.includes(ext));
    // ext = ext[ext.length - 1];
    if (!checkExt.includes(ext)) {
      console.log("qawsewdecfdc", ext, checkExt);
      // alert('File format not acceptable. Please upload .txt, .doc, .pdf, .docx');
      // this.state.showFileError = true;
      this.setState({ showFileError: true });
      this.state.uploadErrorMessage = `File format not acceptable. Please upload relevant formats.`;
      // setTimeout(() => {
      // 	// this.state.showFileError = false
      // 	this.setState({ showFileError: false });
      // }, 2000);
      this.state.uploadingFile = false;
      this.state.uploadFileButton = false;
      this.state.sendListButton = false;
      console.log("Adjusted", this.state.botType);

      var s_view = document.getElementById("PreviewChatScroller_dheeraj");
      if (localStorage.getItem("botType") == "itemordering") {
        // document.getElementById(
        // 	"PreviewChatScroller_dheeraj"
        // ).style.height = 'calc(100% - 335px)';
        this.state.mainScrollHeight = "calc(100% - 335px)";
        // console.log('Adjusted',document.getElementById(
        // 	"PreviewChatScroller_dheeraj"
        // ).style.height)
      }
      return;
    }
    var fsiz = file.size / 1024 / 1024;
    if (fsiz > checklimit) {
      console.log(fsiz, checklimit);
      this.state.showFileError = true;
      this.state.uploadErrorMessage = `File size cannot be greater than ${checklimit} mb.`;
      this.state.uploadingFile = false;
      this.state.uploadFileButton = false;
      this.state.sendListButton = false;

      if (localStorage.getItem("botType") == "itemordering") {
        this.state.mainScrollHeight = "calc(100% - 335px)";
      }
      return;
    }

    var metadata = {
      contentType: file.type,
    };
    var fileRef = storageRef.child(this.state.companyID).child(file.name);
    var file_type = file.type.split("/")[0];
    file.arrayBuffer().then((uint8data) => {
      fileRef
        .put(new Uint8Array(uint8data), metadata)
        .then((snapshot) => {
          fileRef.getDownloadURL().then((uri) => {
            this.state.text = uri;
            console.log(uri, "FILEURL");
            var n = file.name;
            if (n.length > 20) {
              var n = n.slice(0, 5) + "..." + n.slice(n.length - 5, n.length);
            }
            var fdata = {
              msg: this.state.text,
              fname: n,
              fsize: file.size,
            };
            this.state.showFileUpload = false;
            this.state.mainScrollHeight = "calc(100% - 120px)";
            // if (this.state.isLivechat) {
            // 	if (file_type == "image") {
            // 		this.insertchat(this.state.chatRef, fdata, 2);
            // 	} else if (file_type == "video") {
            // 		this.insertchat(this.state.chatRef, fdata, 4);
            // 	} else {
            // 		this.insertchat(this.state.chatRef, fdata, 3);
            // 	}
            // } else {
            this.state.itemordered = true;
            this.state.uploadMultipleList.push(
              <div className="Box hh">
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 15,
                    alignSelf: "flex-end",
                    borderWidth: 1,
                    borderRadius: 25,
                  }}
                  src={this.image_addedon + user}
                />
                <div
                  onClick={this.fileDownload.bind(this, fdata)}
                  className="fileFormatDClient"
                >
                  <span className="fileFormatName">{n}</span>
                  <span className="fileFormatSize">{file.size}</span>
                  <span className="fileFormatDownload">Download</span>
                  <span className="whichFile">
                    <span className="whichFileName">File</span>
                  </span>
                </div>
              </div>
            );
            console.log(
              this.state.uploadCount,
              "Files sendin index",
              this.state.uploadMultipleList.length
            );
            if (
              this.state.uploadCount == this.state.uploadMultipleList.length
            ) {
              console.log("Files sendin push");
              this.state.list.push(this.state.uploadMultipleList);
              // var s_view = document.getElementById('Appypie').contentWindow.document.getElementById("Testing");
              // s_view.style.height = 'calc(100% - 140px)';
              this.setState({ showList: false, uploadingFile: false });
              this.onSendButtonPress("continue");
            }
            // }
            //event.target.value=null
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("Files sending failed");
          this.setState({ isSendingFile: false });
        });
    });
  }

  bulkTranslate(node, index) {
    //this.state.chatLanguage='hi';
    fetch(
      "https://translation.googleapis.com/language/translate/v2?q=" +
        node.msg +
        "&format=text&key=AIzaSyAcgmkptsyAKEnX6gKu_60rPYF24Rf-fdk&target=" +
        this.state.chatLanguage
    )
      .then((res) => res.json())
      .then((result) => {
        var tt = result.data.translations[0].translatedText;
        this.state.list_translated[index] = (
          <div
            style={{ display: "flex", marginBottom: 5, position: "relative" }}
          >
            {node.agent_profile_img == "" ? (
              <span
                className="agentShortsLeft"
                style={{ background: this.state.customisation.clientBubbleClr }}
              >
                {node.agent_short}
              </span>
            ) : (
              <img
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  marginLeft: 10,
                  borderRadius: 25,
                }}
                src={node.agent_profile_img}
              />
            )}

            <span className="userTitleNameFr">{node.agent_name}</span>
            <div
              id={"jj"}
              className="botLive"
              style={{
                color: this.state.customisation.botTextcolor,
                background: this.state.customisation.messagebubble,
              }}
            >
              {parse(tt)}
            </div>
          </div>
        );
        this.setState({});
      })
      .catch((err) => {
        console.log(err);
      });
  }
  

  translatedText_one(chat_data) {
    this.state.list_translated.push();
  }
  myFunction() {
    alert("You pressed a key inside the input field");
  }
  frameMessages() {}

  setLanguage111() {
    if (this.state.isDefaultLanguage) {
      this.state.chatLanguage = "en";
    } else {
      // this.state.chatLanguage='hi';
      var lang = this.getMetaContentByName("lang");
      this.state.chatLanguage = lang;
      this.state.list_translated = [];
      this.state.list.forEach((element) => {
        this.state.list_translated.push(element);
      });
      this.state.List_allMsg.forEach((node, index) => {
        if (node.agent_id != null) {
          if (node.msg_type == 1) {
            this.bulkTranslate(node, index);
          }
        }
      });
    }

    this.setState({ chooseLanguage: false });
  }

  checkEnter(event) {
    var t = document.getElementById("msg").value;
    console.log(event.target.value, "dheerajTest");

    if (event.keyCode == 13) {
      if (!event.shiftKey) {
        event.preventDefault();

        this.setState({ inputText: "", showHideEmoji: false });
        this.onSendButtonPress();
        return;
      }
    } else {
      var t = document.getElementById("msg").value;
      console.log(t);
      this.setState({ inputText: t, text: t });
    }
  }

  showNotification(message) {
    this.state.shouldShowNotification = true;
  }

  openIfClosed() {
    this.focusNEW();
    if (!this.state.show) {
      this.showBot();
    }
  }

  selectSurvey(option, type, value) {
    if (lastFeedback) {
      this.newFeedbackSave(value);
    } else {
      if (type == "scale") this.state.text = option + " - " + value;
      else this.state.text = option;
      this.state.showSurvey.enable = false;
      // this.setState({ showSurvey: false })
      this.onSendButtonPress();
      try {
        // var s_view = document
        // 	.getElementById("PreviewChatScroller_dheeraj");
        this.state.mainScrollHeight = "calc(100% - 120px)";
      } catch (error) {}
    }
  }
  handleChange = (event) => {
    // setInputValue(event.target.value);
  };
  changeRating(newRating, name) {
    // console.log(newRating, name)
    // this.state.text = "" + newRating + " stars";
    if (lastFeedback) {
      this.newFeedbackSave(newRating);
    } else {
      for (var i = 0; i < newRating; i++) {
        this.state.text = this.state.text + "⭐";
      }
      this.state.showSurvey.enable = false;
      try {
        // var s_view = document
        // 	.getElementById("PreviewChatScroller_dheeraj");
        this.state.mainScrollHeight = "calc(100% - 120px)";
      } catch (error) {}
      this.onSendButtonPress("" + newRating);
    }
  }

  render() {
    // console.log(  parseInt(this.state.faree.replace(",", "")) *
    // parseInt(this.state.perkilometreprice),'===?render')
	this.state.customisation = this.props.dataBot.customisation?this.props.dataBot.customisation:this.state.customisation;


    var bot = document.getElementsByClassName("bot");
    var botc = document.getElementsByClassName("botc");
    var avtar = document.getElementsByClassName("NOT_FOUND");
    var buttonStyle = document.getElementsByClassName("buttonStyle");
    var buttonStyleV = document.getElementsByClassName("buttonStyleV");

    if (buttonStyle.length > 0) {
      var i = 0;
      for (i = 0; i < buttonStyle.length; i++) {
        // style={{ border: `1px solid ${this.state.customisation.botButtonColor}`, color: this.state.customisation.botButtonColor }}
        buttonStyle[
          i
        ].style.border = `1px solid ${this.state.customisation.botButtonColor}`;
        buttonStyle[i].style.color = this.state.customisation.botButtonColor;
        buttonStyle[i].style.fontSize =
          this.state.customisation.buttonfontSize + "px";
        buttonStyle[i].style.fontFamily =
          this.state.customisation.buttonfontStyle;
      }
    }
    if (buttonStyleV.length > 0) {
      var i = 0;
      for (i = 0; i < buttonStyleV.length; i++) {
        // style={{ border: `1px solid ${this.state.customisation.botButtonColor}`, color: this.state.customisation.botButtonColor }}
        buttonStyleV[
          i
        ].style.border = `1px solid ${this.state.customisation.botButtonColor}`;
        if (!buttonStyleV[i].className.includes("select")) {
          buttonStyleV[i].style.color = this.state.customisation.botButtonColor;
        }
        buttonStyleV[i].style.fontSize =
          this.state.customisation.buttonfontSize + "px";
        buttonStyleV[i].style.fontFamily =
          this.state.customisation.buttonfontStyle;
      }
    }

    if (botc.length > 0) {
      var i = 0;
      for (i = 0; i < botc.length; i++) {
        botc[i].style =
          // "color:" +
          // this.state.customisation.clientchattextcolor +
          // ";background:" +
          // this.state.customisation.clientBubbleClr;
          botc[i].style.color = this.state.customisation.clientchattextcolor;
        botc[i].style.background = this.state.customisation.clientBubbleClr;
        botc[i].style.fontSize = this.state.customisation.fontSize + "px";
        botc[i].style.fontFamily = this.state.customisation.fontStyle;
      }
    }
    if (bot.length > 0) {
      var i = 0;
      for (i = 0; i < bot.length; i++) {
        // bot[i].style =
        // 	"color:" +
        // 	this.state.customisation.botTextcolor +
        // 	";background:" +
        // 	this.state.customisation.messagebubble;
        bot[i].style.color = this.state.customisation.botTextcolor;
        bot[i].style.background = this.state.customisation.messagebubble;
        bot[i].style.fontSize = this.state.customisation.fontSize + "px";
        bot[i].style.fontFamily = this.state.customisation.fontStyle;
      }
    }
    if (avtar.length > 0) {
      var i = 0;
      for (i = 0; i < avtar.length; i++) {
        avtar[i].src = this.state.customisation.chatBotAvtar;
      }
    }
    if (this.state.isLoading && !this.state.show) {
      return <div style={{ marginLeft: "45%", marginTop: "25%" }}></div>;
    }

    return (
      <div id="chatbotAppy" className="h-[500px] mainBoat shadow-md rounded-b-2xl">
        <Device onChange={this.onChangee} />

        <Notifications1
          body={this.state.n_msg}
          openBot={this.openIfClosed}
          show={this.state.shouldShowNotification}
        />

        {this.state.showHide == "cHide" ? null : (
          <div
            id="mainView"
            className='w-full h-calc-cstm-bot'
            style={{
              background:  this.state.customisation.widgetbackgroundcolor?this.state.customisation.widgetbackgroundcolor:"#fff",
            }}
          >
            {/******************HEADER******************/}

            <div
              className="mainBoat shadow-md rounded-b-2xl"
              style={{
                background: this.state.customisation.headerClr,
                borderRadius:
                  this.props.view == "mobile" ? "0" : "15px 15px 0px 0px",
              }}
            >
              {/* Start Header  */}
              <div className="boatHeader rounded-t-2xl px-4 py-4 flex items-center">
                <span className="flex items-center justify-center bg-[#6D85EB] rounded-full w-11 h-11 mr-4 ">
                    <img
                      src={
                        this.props.dataBot.customisation.widgetImage
                          ? this.props.dataBot.customisation.widgetImage
                          : "https://cdn.chatbot.com/widget/61f28451fdd7c5000728b4f9/FPBAPaZFOOqqiCbV.png"
                      }
                    />
                  </span>
                  <span style={{ color: this.state.customisation.headertextcolor }} className="inline-block text-white font-semibold text-base">
                    {this.state.customisation.headerName}
                  </span>
              </div>
              {/* End Header */}

            </div>

            {this.state.chooseLanguage ? (
              <div>
                <div className="shadow"></div>
                <div className="changeLanguage">
                  <div
                    style={{ background: this.state.customisation.headerClr }}
                    className="changeLanguageHeader"
                  >
                    <h4>Change Language</h4>
                    <span
                      onClick={() => {
                        this.setState({ chooseLanguage: false });
                      }}
                    >
                      X
                    </span>
                  </div>
                  <div className="changeLanguageBody">
                    <div className="feildsLanguage">
                      <label>
                        <input
                          onChange={() => {
                            this.setState({ isDefaultLanguage: true });
                          }}
                          checked={this.state.isDefaultLanguage}
                          type="radio"
                          name="language"
                        />
                        <span>Default Language (English)</span>
                      </label>
                      <label>
                        <input
                          onChange={() => {
                            this.setState({ isDefaultLanguage: false });
                          }}
                          checked={!this.state.isDefaultLanguage}
                          type="radio"
                          name="language"
                        />
                        <span>Show in Local Language</span>
                      </label>
                    </div>
                    <div className="buttonFeildsLanguage">
                      <span onClick={this.setLanguage111} className="saveBtn">
                        Save
                      </span>
                      <button
                        onClick={() => {
                          this.setState({ chooseLanguage: false });
                        }}
                        className="cancelBtn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {/******************CHATS********************/}

            {this.state.takeUserPermission ? (
              <div>
                <div className="userPermission">
                  <p>{this.state.ptext}</p>
                  <a
                    target="_blank"
                    href="https://www.appypie.com/privacy-policy"
                  >
                    View privacy policy
                  </a>
                </div>
                <div className="startNewChatDiv">
                  <button
                    onClick={this.userAgree}
                    style={{
                      background: this.state.customisation.clientBubbleClr,
                    }}
                  >
                    Yes, I agree
                  </button>
                </div>
              </div>
            ) : null}
            <>
              {this.state.showGoogleMaps ? (
                  <>
                    <input
                      style={{ width: "100%" }}
                      id="searchInput"
                      type="text"
                      placeholder={`${
                        this.state.isDual
                          ? "Initial Location"
                          : "Please Enter Your location"
                      } `}
                      
                      onChange={(e) =>

                        this.setState({ initiall: e.target.value ,pickupTick:false})
                       }
                        // onKeyDown={this.handleKeyDown}
                     
                     
                    />

                    {this.state.isDual == true ? (
                      <>
                        <input
                          style={{
                            width: "100%",
                            // height: "2.5rem",
                            marginTop: "0.2rem",
                          }}
                          id="searchInputt"
                          type="text"
                          placeholder="Final Location"
                          onChange={(e) =>{
                           this.getDta(e)
                           this.setState({ dropTick:false})
                          }
                          }
                          //  onKeyDown={this.handleKeyDown}
                          
                        />
                        <div className="mt-2 mb-2" id="mode"></div>
                      </>
                    ) : null}

                    {/* <button id='pan'>Pan to Current Location</button> */}
                    <div
                      style={{
                        height: this.state.isDual == true ? "58%" : "65%",
                      }}
                      id="map"
                      className="appointmentchatscroller position-relative"
                    ></div>
                  </>
                ) : (
                <div
                  id="PreviewChatScroller_dheeraj"
                  className="boatBody bg-white px-4 py-3 overflow-y-auto"
                  style={{
                    background: this.state.customisation.backgroundChatBot,
                    height: this.state.mainScrollHeight,
                  }}
                  ref={(ref) => (this.myRef = ref)}
                >
                  {this.state.list}
                </div>
              )}
            </>

            {this.state.showHideEmoji == true ? (
              <Picker 
                emoji="point_up"
                title="Emojis"
                style={{
                  width: "100%",
                  zIndex: 10,
                  position: "absolute",
                  bottom: 59,
                }}
                onSelect={(e) => {
                  this.setState({
                    inputText: this.state.inputText + e.native,
                    text: this.state.inputText + e.native,
                  });
                }}
              />
            ) : null}

            {/******************footer*******************/}

            {this.state.showFeedback == "visible" ? (
              <div
                style={{
                  background: "#f4f4f4",
                  height: 66,
                  borderBottomRightRadius: 13,
                  borderBottomLeftRadius: 13,
                  textAlign: "center",
                  fontSize: 13.3,
                  padding: "8px 0px",
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                }}
              >
                <p
                  style={{
                    padding: "0px 0px 0px 0px",
                    width: 265,
                    margin: "auto",
                  }}
                >
                  How do you rate your customer service experience?
                </p>
                <div>
                  <img
                    onClick={this.thumbUp.bind(this, 1)}
                    style={{
                      width: 22,
                      height: 22,
                      padding: "5px 6px",
                      cursor: "pointer",
                    }}
                    src={this.image_addedon + one}
                  />
                  <img
                    onClick={this.thumbUp.bind(this, 2)}
                    style={{
                      width: 22,
                      height: 22,
                      padding: "5px 6px",
                      cursor: "pointer",
                    }}
                    src={this.image_addedon + two}
                  />
                  <img
                    onClick={this.thumbUp.bind(this, 3)}
                    style={{
                      width: 22,
                      height: 22,
                      padding: "5px 6px",
                      cursor: "pointer",
                    }}
                    src={this.image_addedon + three}
                  />
                  <img
                    onClick={this.thumbUp.bind(this, 4)}
                    style={{
                      width: 22,
                      height: 22,
                      padding: "5px 6px",
                      cursor: "pointer",
                    }}
                    src={this.image_addedon + four}
                  />
                  <img
                    onClick={this.thumbUp.bind(this, 5)}
                    style={{
                      width: 22,
                      height: 22,
                      padding: "5px 6px",
                      cursor: "pointer",
                    }}
                    src={this.image_addedon + five}
                  />
                </div>
              </div>
            ) : null}

            {this.state.feedbackText && !this.state.newAppointment ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 10,
                  fontSize: 13,
                  textAlign: "center",
                  padding: "5px",
                  fontFamily: this.state.customisation.fontStyle,
                }}
              >
                {
                  <p
                    style={{ margin: 0 }}
                    dangerouslySetInnerHTML={{
                      __html: this.checkUrl(this.state.feedbackText, "bot"),
                    }}
                  />
                }
              </span>
            ) : null}

            {this.state.a_showFeedback == "visible" ? (
              <div
                style={{
                  background: "#f4f4f4",
                  height: 52,
                  textAlign: "center",
                  fontSize: 14,
                  padding: "6px 0px",
                  position: "absolute",
                  width: "100%",
                  bottom: 0,
                }}
              >
                {this.state.showFeedbackMessage ? (
                  <div
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "45%",
                      transform: "translate(-50%, -50%)",
                      fontWeight: "bold",
                      fontSize: "13px",
                      width: "100%",
                    }}
                  >
                    {
                      <p
                        style={{ margin: 0 }}
                        dangerouslySetInnerHTML={{
                          __html: this.checkUrl(
                            this.state.feedbackResponseText,
                            "bot"
                          ),
                        }}
                      />
                    }
                  </div>
                ) : (
                  <div>
                    <div className="feedbackEmoji">
                      <img
                        onClick={this.a_feedback.bind(this, 1)}
                        src={this.image_addedon + one}
                      />
                      <img
                        onClick={this.a_feedback.bind(this, 2)}
                        src={this.image_addedon + two}
                      />
                      <img
                        onClick={this.a_feedback.bind(this, 3)}
                        src={this.image_addedon + three}
                      />
                      <img
                        onClick={this.a_feedback.bind(this, 4)}
                        src={this.image_addedon + four}
                      />
                      <img
                        onClick={this.a_feedback.bind(this, 5)}
                        src={this.image_addedon + five}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {this.state.showSurvey.enable ? (
              this.state.showSurvey.type == "rating-scale" ? (
                this.state.showSurvey.value == "1-10" ? (
                  <div className="surveyRating">
                    <div className="upperSurvey">
                      <span
                        onClick={() =>
                          this.selectSurvey("1", "scale", "NEEDS IMPROVEMENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#fe392a" }}
                      >
                        1
                      </span>
                      <span
                        onClick={() =>
                          this.selectSurvey("2", "scale", "NEEDS IMPROVEMENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#fe6255" }}
                      >
                        2
                      </span>
                      <span
                        onClick={() =>
                          this.selectSurvey("3", "scale", "NEEDS IMPROVEMENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#fe8880" }}
                      >
                        3
                      </span>
                      <span
                        onClick={() =>
                          this.selectSurvey("4", "scale", "NEEDS IMPROVEMENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#fd9c95" }}
                      >
                        4
                      </span>
                      <span
                        onClick={() =>
                          this.selectSurvey("5", "scale", "NEEDS IMPROVEMENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#ffafaa" }}
                      >
                        5
                      </span>
                      <span
                        onClick={() =>
                          this.selectSurvey("6", "scale", "NEEDS IMPROVEMENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#ffc2bf" }}
                      >
                        6
                      </span>
                      <p className="surveyText" style={{ color: "#fe6255" }}>
                        NEEDS IMPROVEMENT
                      </p>
                    </div>
                    <div className="bottomSurvey">
                      <div className="innerBottomSurvey">
                        <span
                          onClick={() =>
                            this.selectSurvey("7", "scale", "GOOD")
                          }
                          className="surveyCircle"
                          style={{ backgroundColor: "#ffd17d" }}
                        >
                          7
                        </span>
                        <span
                          onClick={() =>
                            this.selectSurvey("8", "scale", "GOOD")
                          }
                          className="surveyCircle"
                          style={{ backgroundColor: "#ffb327" }}
                        >
                          8
                        </span>
                        <p className="surveyText" style={{ color: "#ffb327" }}>
                          GOOD
                        </p>
                      </div>
                      <div>
                        <span
                          onClick={() =>
                            this.selectSurvey("9", "scale", "EXCELLENT")
                          }
                          className="surveyCircle"
                          style={{ backgroundColor: "#afe391" }}
                        >
                          9
                        </span>
                        <span
                          onClick={() =>
                            this.selectSurvey("10", "scale", "EXCELLENT")
                          }
                          className="surveyCircle"
                          style={{ backgroundColor: "#7bd047" }}
                        >
                          10
                        </span>
                        <p className="surveyText" style={{ color: "#7bd047" }}>
                          EXCELLENT
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <div>
                      <span
                        onClick={() =>
                          this.selectSurvey("1", "scale", "VERY POOR")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#fe392a" }}
                      >
                        1
                      </span>
                      <p className="surveyText">Very Poor</p>
                    </div>
                    <div>
                      <span
                        onClick={() => this.selectSurvey("2", "scale", "POOR")}
                        className="surveyCircle"
                        style={{ backgroundColor: "#ffb327" }}
                      >
                        2
                      </span>
                      <p className="surveyText">Poor</p>
                    </div>
                    <div>
                      <span
                        onClick={() =>
                          this.selectSurvey("3", "scale", "AVERAGE")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#f9e104" }}
                      >
                        3
                      </span>
                      <p className="surveyText">Average</p>
                    </div>
                    <div>
                      <span
                        onClick={() => this.selectSurvey("4", "scale", "GOOD")}
                        className="surveyCircle"
                        style={{ backgroundColor: "#ace558" }}
                      >
                        4
                      </span>
                      <p className="surveyText">Good</p>
                    </div>
                    <div>
                      <span
                        onClick={() =>
                          this.selectSurvey("5", "scale", "EXCELLENT")
                        }
                        className="surveyCircle"
                        style={{ backgroundColor: "#7bd047" }}
                      >
                        5
                      </span>
                      <p className="surveyText">Excellent</p>
                    </div>
                  </div>
                )
              ) : this.state.showSurvey.type == "rating-stars" ? (
                this.state.showSurvey.value == "5" ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <StarRatings
                      rating={0}
                      starRatedColor="#ffc830"
                      starHoverColor="#ffc830"
                      changeRating={this.changeRating.bind(this)}
                      numberOfStars={5}
                      starDimension={"35px"}
                      name="rating"
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <StarRatings
                      rating={1}
                      starRatedColor="#ffc830"
                      starHoverColor="#ffc830"
                      changeRating={this.changeRating}
                      starDimension={40}
                      numberOfStars={3}
                      name="rating"
                    />
                  </div>
                )
              ) : this.state.showSurvey.value == "5" ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    onClick={() => this.selectSurvey(this.image_addedon + one)}
                    className="surveySmiley"
                    src={this.image_addedon + one}
                  />
                  <img
                    onClick={() => this.selectSurvey(this.image_addedon + two)}
                    className="surveySmiley"
                    src={this.image_addedon + two}
                  />
                  <img
                    onClick={() =>
                      this.selectSurvey(this.image_addedon + three)
                    }
                    className="surveySmiley"
                    src={this.image_addedon + three}
                  />
                  <img
                    onClick={() => this.selectSurvey(this.image_addedon + four)}
                    className="surveySmiley"
                    src={this.image_addedon + four}
                  />
                  <img
                    onClick={() => this.selectSurvey(this.image_addedon + five)}
                    className="surveySmiley"
                    src={this.image_addedon + five}
                  />
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    onClick={() => this.selectSurvey(this.image_addedon + two)}
                    className="surveySmiley"
                    src={this.image_addedon + two}
                  />
                  <img
                    onClick={() =>
                      this.selectSurvey(this.image_addedon + three)
                    }
                    className="surveySmiley"
                    src={this.image_addedon + three}
                  />
                  <img
                    onClick={() => this.selectSurvey(this.image_addedon + four)}
                    className="surveySmiley"
                    src={this.image_addedon + four}
                  />
                </div>
              )
            ) : this.state.showFeedbackMessage && !this.state.newAppointment ? (
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "80%",
                  margin: "auto",
                }}
              >
                {this.checkUrl(this.state.feedbackResponseText)}
              </div>
            ) : null}
            {/* {console.log(
              this.state.buttonType,
              this.state.isOptionalButton,
              "Buttontype"
            )} */}
            {this.state.showText == "visible" &&
            !this.state.showListing &&
            !this.state.showFileUpload &&
            !this.state.newAppointment &&
            !this.state.showSurvey.enable &&
            this.state.buttonType != "single" &&
            !this.state.showList &&
            !this.state.newAppointment &&
            !this.state.Input ? (
              // &&!this.state.AddressDropdown
              <div
                id="FOOTER"
                className="boatFooter bg-white px-4 pb-3 rounded-b-2xl w-full"
                style={{
                  display: this.state.showfooter,
                  pointerEvents: this.state.stopDropdown ? "none" : "all",
                  borderColor:
                    this.state.showCountryList ||
                    this.state.showDropDown ||
                    this.state.AddressDropdown
                      ? "transparent"
                      : "#e8e8e8",
                }}
              >
                <div className="flex items-center bg-[#F4F5FA] rounded-md">
                {/* {this.state.buttonType == "multiple"  ? <p id="InputBar" className='chat'
                                                id="InputBar"
                                                style={{ height: '5px' }}
                                            >{this.state.isOptionalButton ? 'Skip this question' : 'Submit selected options'}</p>:null
										

								} */}
                {this.state.currencyPicker ? (
                  <>
                    {console.log(CurrencyList.length)}
                    <div className="currencyPicker">
                      <span
                        style={{
                          fontSize: 15,
                          width: "100%",
                          textAlign: "center",
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                      >
                        {this.state.currencyText.split(" ")[0]}
                      </span>
                      {/* background-image: url(https://chatbot.appypie.com/dist/img/public/img/selectdroparrow.svg); */}
                      <img
                        src={
                          "https://chatbot.appypie.com/dist/img/public/img/selectdroparrow.svg"
                        }
                        className="selectImg"
                      />
                      <select
                        id={"countryListSelect"}
                        style={{
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                        value={this.state.currencyText}
                        onChange={(e) => {
                          this.setState({ currencyText: e.target.value });
                        }}
                      >
                        {CurrencyList.map((item) => (
                          <option
                            style={{
                              fontFamily: this.state.customisation.fontStyle,
                            }}
                          >
                            {item.symbol} ({item.name})
                          </option>
                        ))}
                      </select>
                    </div>
                    <textarea className="text-sm w-9/12 border-0 focus:outline-none bg-[#F4F5FA] pt-3 resize-none pl-4 rounded-md"
                      style={{
                        width: "60%",
                        left: 60,
                        fontFamily: this.state.customisation.fontStyle,
                      }}
                      value={this.state.inputText}
                      type="text"
                      placeholder={this.checkUrl(
                        this.state.optionalPlaceholder
                      )}
                      id="1st"
                      disabled={this.state.toggleInput}
                      // id="msg_id"
                      name="msg"
                      onChange={this.onInputChange}
                      onKeyDown={this.onInputChange}
                      onPaste={this.onInputChange1}
                    ></textarea>
                  </>
                ) : (
                  <>
                    {this.state.phoneInput ? (
                      <div
                        className="phonecodePicker"
                        style={{
                          width: "88px",
                          left: "-20px",
                          position: "relative",
                          top: "15px",
                        }}
                      >
                        <span
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            textAlign: "center",
                            height: "30px",
                            padding: "0px 7px",
                            fontFamily: this.state.customisation.fontStyle,
                          }}
                        >
                          {this.state.phonecodeText.split("(")[0]}
                        </span>
                        <img
                          src={
                            "https://chatbot.appypie.com/dist/img/public/img/selectdroparrow.svg"
                          }
                          className="selectImg"
                          style={{
                            paddingBottom: 0,
                            position: "relative",
                            bottom: "4px",
                          }}
                        />
                        <select
                          id={"countryListSelect"}
                          style={{
                            height: "100%",
                            cursor: "pointer",
                            position: "absolute",
                            opacity: 0,
                            left: 0,
                            fontFamily: this.state.customisation.fontStyle,
                          }}
                          value={this.state.phonecodeText}
                          onChange={(e) => {
                            this.setState({ phonecodeText: e.target.value });
                          }}
                        >
                          {DialcodeList.map((item) => (
                            <option
                              style={{
                                fontFamily: this.state.customisation.fontStyle,
                              }}
                            >
                              {item.dial_code} ({item.name})
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : // <span className="phonecodetext">{dialCode}</span>
                    null}
                    {this.state.buttonType == "multiple" ||
                    this.state.showGoogleMaps ? (
                      <p
                        className="chat"
                        id="InputBar"
                        style={{
                          height: "23px",
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                      >
                        {this.state.isOptionalButton
        ? "Skip this question"
        : this.state.showGoogleMaps
        ? 
          !this.state.locationErrMsg

          
            ? "Submit Pinned location"
            : <p style={{ color: "red", marginTop: "0rem" }}>{this.state.locationErrMsg}</p>
          
        : this.state.pickToppings
        ? `Select up to ${this.state.maxTop} options`
        : "Submit selected options"}
                      </p>
                    ) : (
                      <textarea className="text-sm w-9/12 border-0 focus:outline-none bg-[#F4F5FA] pt-3 resize-none pl-4 rounded-md"
                        style={{
                          width: this.state.phoneInput ? "60%" : "85%",
                          left: this.state.phoneInput ? 60 : 0,
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                        id="2nd"
                        value={this.state.inputText}
                        type="text"
                        placeholder={this.checkUrl(
                          this.state.optionalPlaceholder
                        )}
                        disabled={this.state.toggleInput}
                        name="msg"
                        onChange={this.onInputChange}
                        onKeyDown={this.onInputChange}
                        onPaste={this.onInputChange1}
                      ></textarea>
                    )}
                  </>
                )}

                <li style={{ listStyleType: "none" }}>
                  {this.state.isOptionalButton &&
                  !this.state.showCountryList &&
                  !this.state.showDropDown ? (
                    <span
                      className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-10 h-10 cursor-pointer ml-auto"
                      style={{
                        background: this.state.customisation.sendButtonClr,
                      }}
                      onClick={this.skipQuestion}
                    >
                      <img
                        src={this.state.skipIcon}
                        style={{ width: "18px" }}
                      />
                    </span>
                  ) : (
                    <span
                      className="user flex items-center justify-center bg-[#6D85EB] rounded-full w-10 h-10 cursor-pointer ml-auto"
                      style={{
                        background: this.state.customisation.sendButtonClr,
                      }}
                      onClick={() => {
                      
                        if (this.state.searchAllowed) {
                          this.setupHotel("search");
                        } else if (this.state.showGoogleMaps) {
                          if (
                            (this.state.pickupTick&&this.state.dropTick) ||
                            (this.state.initiall !== "" && this.state.singlelocation)
                          ) {
                            
                              this.onSendButtonPress(
                                {
                                  lat: this.state.gMapLat,
                                  lng: this.state.gMapLong,
                                },
                                "location"
                              );
                            
                          }else{
                            this.setState({locationErrMsg:"Select correct and valid locations."})
                          }
                        } else if (this.state.pickToppings) {
                          this.handleToppings("submit");
                        } else {
                          this.onSendbtnPress();
                        }
                        
                      }}
                    >
                      <img src={this.image_addedon + sendbtn} />
                    </span>
                  )}
                </li>
              </div>
              </div>
            ) : null}
            {this.state.showListing ? (
              <div className="startNewChatDiv">
                <button
                  className="btnLucky"
                  onClick={this.startNewChat}
                  style={{
                    background: this.state.customisation.clientBubbleClr,
                  }}
                >
                  {this.state.isChatInDraft
                    ? "Continue with ongoing conversation"
                    : "Start a new conversation"}
                </button>
              </div>
            ) : null}
            {this.state.newAppointment ? (
              // {true?(
              <div style={{ height: 60 }} className="startNewChatDiv">
                <button
                  className="btnLucky"
                  onClick={this.startNewAppointment}
                  style={{
                    background: this.state.customisation.clientBubbleClr,
                    fontFamily: this.state.customisation.fontStyle,
                  }}
                >
                  {
                    <p
                      style={{
                        margin: 0,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: this.checkUrl(LastButtonText),
                      }}
                    />
                  }
                </button>
              </div>
            ) : null}
            {/* {this.state.btns.length == 0 ? null : (
							<div
								id="BottomButtons"
								className="appointment-yes-no-btn-div"
								style={{
									bottom: this.state.buttonBottomSpace,
									position: "relative",
									display: this.state.horizontalButtons
										? "inline-flex"
										: "grid",
									textAlign: "center",
									padding: "0px 0px",
									background: "#fff",
									maxHeight: 195,
									overflowY: "scroll",
								}}
							>
								{this.state.btns}
							</div>
						)} */}

            {this.state.showCountryList ? (
              <div className="dropdownCountry">
                <select
                  id={"countryListSelect"}
                  value={this.state.dropDownText}
                  style={{ fontFamily: this.state.customisation.fontStyle }}
                  onChange={(e) => {
                    this.setState({ dropDownText: e.target.value });
                    this.state.text = e.target.value;
                  }}
                >
                  {CountryList.map((item) => (
                    <option
                      style={{ fontFamily: this.state.customisation.fontStyle }}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                {/* <span className="sendPreview" style={{ background: this.state.customisation.clientBubbleClr, right: 30 }} onClick={this.onSendbtnPress}>
                <img src={this.image_addedon + sendbtn} />
              </span> */}
              </div>
            ) : null}
            {this.state.AddressDropdown ? (
              <div className="dropdownCountry">
                <select
                  id={"countryListSelect"}
                  value={this.state.dropDownText}
                  style={{ fontFamily: this.state.customisation.fontStyle }}
                  onChange={(e) => {
                    this.selectAddressDropdown(e);
                  }}
                >
                  {(this.state.addressStateArr.length > 0
                    ? this.state.addressStateArr
                    : this.state.addressCountryArr
                  ).map((item) => (
                    <option
                      style={{ fontFamily: this.state.customisation.fontStyle }}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                {/* <span className="sendPreview" style={{ background: this.state.customisation.clientBubbleClr, right: 30 }} onClick={() => this.onSendButtonPress(this.state.dropDownText)}>
                <img src={this.image_addedon + sendbtn} />
              </span> */}
              </div>
            ) : null}

            {this.state.showDropDown ? (
              <div className="dropdownCountry">
                <select
                  id={"countryListSelect"}
                  style={{
                    width: "100%",
                    fontFamily: this.state.customisation.fontStyle,
                  }}
                  value={this.state.dropDownText}
                  onChange={(e) => {
                    this.setState({
                      dropDownText: e.target.value,
                      text: e.target.value,
                    });
                  }}
                >
                  {dropDownList.map((item) => (
                    <option
                      style={{ fontFamily: this.state.customisation.fontStyle }}
                    >
                      {this.checkUrl(item)}
                    </option>
                  ))}
                </select>
                {/* <span className="sendPreview" style={{ background: this.state.customisation.clientBubbleClr, right: 30 }} onClick={this.onSendbtnPress}>
								<img src={this.image_addedon + sendbtn} />
							</span> */}
              </div>
            ) : null}

            {this.state.showFileUpload ? (
              <div
                style={{ width: "100%", height: "auto", padding: "5px 0px" }}
              >
                {this.state.uploadingFile ? (
                  <img
                    style={{ margin: "auto" }}
                    src="https://chatbot.appypie.com/widget/sloader.gif"
                  />
                ) : (
                  <div
                    style={{
                      width: "80%",
                      height: "80%",
                      margin: "auto",
                      border: "2px dashed",
                      borderRadius: 10,
                      display: "grid",
                      padding: 10,
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                  >
                    <span
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        fontSize: 14,
                        wordBreak: "break-all",
                        fontFamily: this.state.customisation.fontStyle,
                      }}
                    >
                      Upload your file here
                    </span>
                    <span
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        fontSize: 10,
                        wordBreak: "break-all",
                        fontFamily: this.state.customisation.fontStyle,
                        textOverflow: "ellipsis",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      ({this.state.acceptExts.split(',').slice(0,3).toString(',')+"..."})
                    </span>
                    <input
                      type="file"
                      className="fileInput"
                      disabled={this.state.uploadFileButton}
                      multiple={true}
                      onChange={this.getFile}
                    />
                  </div>
                )}
                {this.state.showFileError ? (
                  <span
                    className="fileError"
                    style={{ fontFamily: this.state.customisation.fontStyle }}
                  >
                    {this.state.uploadErrorMessage}
                  </span>
                ) : null}
              </div>
            ) : null}

            {this.state.showList ? (
              <div
                style={{
                  bottom: 5,
                  background: this.state.customisation.backgroundChatBot,
                  position: "absolute",
                  alignItems: "center",
                  width: "100%",
                  paddingBottom: 10,
                  margin: "auto",
                  display: "block",
                }}
              >
                {this.state.uploadFilelist ? (
                  <>
                    {this.state.uploadingFile ? (
                      <img
                        style={{ margin: "auto" }}
                        src="https://chatbot.appypie.com/widget/sloader.gif"
                      />
                    ) : (
                      <div
                        style={{ width: "100%", height: 100, marginBottom: 0 }}
                      >
                        <div
                          style={{
                            width: "80%",
                            height: "80%",
                            margin: "auto",
                            border: "2px dashed",
                            borderRadius: 10,
                            display: "grid",
                            padding: 10,
                            position: "relative",
                            backgroundColor: "#fff",
                          }}
                        >
                          <span
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              fontSize: 14,
                              fontFamily: this.state.customisation.fontStyle,
                            }}
                          >
                            Upload your list here
                          </span>
                          <span
                            style={{
                              margin: "auto",
                              textAlign: "center",
                              fontSize: 10,
                            }}
                          >
                            ({this.state.acceptExts})
                          </span>
                          <input
                            type="file"
                            className="fileInput"
                            disabled={this.state.uploadFileButton}
                            onChange={this.getFile}
                          />
                        </div>
                      </div>
                    )}
                    {this.state.showFileError ? (
                      <span
                        className="fileError"
                        style={{
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                      >
                        {" "}
                        File format not acceptable. Please upload{" "}
                        {this.state.acceptExts}{" "}
                      </span>
                    ) : null}
                  </>
                ) : null}

                <div
                  className="itemBox"
                  style={{
                    width: "85%",
                    height: 150,
                    overflow: "scroll",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{ background: "#fff" }}
                    className="calendareIconDateAnothert"
                  >
                    {/* <div className="iconAndDate">
                                        <span>Please enter the item you want to order</span>
                                    </div> */}
                  </div>
                  <div
                    id="listDiv"
                    className="btnAnotherTime"
                    style={{
                      textAlign: "center",
                      margin: "auto",
                      width: "90%",
                      height: null,
                      maxHeight: "70%",
                      overflow: "scroll",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid rgb(118, 118, 118)",
                      }}
                    >
                      <span
                        className="itemLeft"
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          textAlign: "left",
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                      >
                        Item Name
                      </span>
                      <span
                        className="itemRight"
                        style={{
                          fontSize: 12,
                          fontWeight: "500",
                          fontFamily: this.state.customisation.fontStyle,
                        }}
                      >
                        Quantity
                      </span>
                    </div>
                    {this.state.listInput.map((item, index) => {
                      return item;
                    })}
                  </div>
                  {/* <div style={{ height: 40, display: 'flex' }} >
                                    <button onClick={() => this.addMoreItems(intialItemNo)} style={{ margin: 'auto', display: 'block', borderRadius: 0, borderWidth: 0, height: 30, width: '75%', backgroundColor: this.state.customisation.headerClr, color: '#fff' }}>
                                        {"+ ADD MORE"}
                                    </button>
                                </div> */}
                  <div
                    style={{
                      height: null,
                      position: "absolute",
                      width: "75%",
                      bottom: 8,
                    }}
                  >
                    <span
                      style={{
                        width: "100%",
                        height: 30,
                        background: "#efefef",
                        display: this.state.showError,
                        justifyContent: "center",
                        alignItems: "center",
                        color: "red",
                      }}
                    >
                      Some items are missing{" "}
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          fontSize: 20,
                        }}
                        onClick={() => this.setState({ showError: false })}
                      >
                        x
                      </span>{" "}
                    </span>
                    <button
                      onClick={this.doneOrder}
                      disabled={this.state.sendListButton}
                      style={{
                        margin: "auto",
                        display: "block",
                        borderRadius: 3,
                        borderWidth: 0,
                        height: 35,
                        zIndex: 9999,
                        width: "60%",
                        backgroundColor: this.state.sendListButton
                          ? "#ccc"
                          : this.state.customisation.headerClr,
                        color: "#fff",
                      }}
                    >
                      {"SEND LIST"}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              className="CalendarDiv"
              style={
                !this.state.popup
                  ? {
                      bottom: this.state.customisation.isBTransparent ? 15 : 20,
                      background: "#fff",
                      position: "absolute",
                      alignItems: "center",
                      width: "100%",
                      height:
                        this.state.Input && this.state.showCheckout
                          ? "81%"
                          : "",
                      borderRadius: this.state.customisation.isBTransparent
                        ? "10px"
                        : "",
                      boxShadow: this.state.customisation.isBTransparent
                        ? "rgba(0, 0, 0, 0.30) 0px 4px 12px"
                        : "",
                    }
                  : {
                      background: "rgba(0, 0, 0, 0.2)",
                      position: "absolute",
                      alignItems: "center",
                      width: "100%",
                      height: "83%",
                      bottom: "3%",
                    }
              }
              onClick={(e) => {
                console.log(e.target, "CLICKKKKK");
                if (e.target.id != "popup" && this.state.popup) {
                  this.setState({ popup: false, Input: null });
                }
              }}
            >
              {this.state.Input}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Botpreview;

 