import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';
const { convertToMarkdown } = require('draftjs-to-markdown');
// import Select from 'react-select'

const Gender = (props)=>{


    //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)

     const optionPlaceholderString = selectedNode.data.data.optionPlaceholder ? selectedNode.data.data.optionPlaceholder : 'Skip this question'
     const optionSuggestionString = selectedNode.data.data.optionSuggestion ? selectedNode.data.data.optionSuggestion : 'You can skip this question by clicking on '
     const tipIcon = selectedNode.data.data.tipIcon ? selectedNode.data.data.tipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f'
     const skipIcon = selectedNode.data.data.skipIcon ? selectedNode.data.data.skipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556'
     const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text

     //initial state of rich text editor
    //  const [botMessageText , setBotMessageText] = useState(EditorState.createWithContent(ContentState.createFromText(selectedNode.data.data.text)))
    const [botMessageText , setBotMessageText] = useState(getInitialStateForEditor(selectedNode.data.data.text))
    //  const [successfulText , setSuccessfulText] = useState(EditorState.createWithContent(ContentState.createFromText(selectedNode.data.data.successful)))
    //  const [failedAttemptsText , setFailedAttemptsText] = useState(EditorState.createWithContent(ContentState.createFromText(selectedNode.data.data.unsuccessful.failedAttemptsText)))
    //  const [optionPlaceholder , setOptionPlaceholder] = useState(EditorState.createWithContent(ContentState.createFromText(optionPlaceholderString)))
    //  const [optionSuggestion , setOptionSuggestion] = useState(EditorState.createWithContent(ContentState.createFromText(optionSuggestionString)))
    //  const [textHandoff , setTextHandoff] = useState(EditorState.createWithContent(ContentState.createFromText(textHandoffString)))

     const [successfulText , setSuccessfulText] = useState(getInitialStateForEditor(selectedNode.data.data.responseText))
     const [optionPlaceholder , setOptionPlaceholder] = useState(getInitialStateForEditor(optionPlaceholderString))
     const [optionSuggestion , setOptionSuggestion] = useState(getInitialStateForEditor(optionSuggestionString))
     const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
     const [isOptional , setIsOptional] = useState(selectedNode.data.data.isOptional ? selectedNode.data.data.isOptional : false)
     const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)


 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }


     

     



     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                text : getHtmlFromEditorState(botMessageText),
                responseText : getHtmlFromEditorState(successfulText),
                isOptional,
                optionPlaceholder : getHtmlFromEditorState(optionPlaceholder),
                optionSuggestion : getHtmlFromEditorState(optionSuggestion),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff),
                skipIcon,
                tipIcon,
                

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))
        dispatch(setAllowSave(true))

        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }



    


   
    return(
        <>
            <div className='mb-4 px-3'>
            <h5>Bot message</h5>
                <RichTextEditor editorState = {botMessageText} setEditorState = {setBotMessageText} selectedNode = {selectedNode} />
                
            </div>
            
            <div className='mb-4 px-3'>
                <h5>Bot Response</h5>
                {/* {activeTab === 'valid' && <RichTextEditor editorState = {successfulText} setEditorState = {setSuccessfulText} selectedNode = {selectedNode} /> }
                {activeTab === 'invalid' && <RichTextEditor editorState = {failedAttemptsText} setEditorState = {setFailedAttemptsText} selectedNode = {selectedNode} />
                } */}

                <RichTextEditor editorState = { successfulText} setEditorState = {setSuccessfulText} selectedNode = {selectedNode} />

                
            </div>
           
            <div className='flex items-center px-3 pb-3'>
                <input type='checkbox' id='aa' checked = {isOptional} onChange = {() => setIsOptional(!isOptional)} />
                <label className='text-[14px] pl-2 cursor-pointer ' for="aa">Optional</label>
            </div>
            {isOptional && <div>
            <p>Option skipping suggestion text</p>
            <RichTextEditor editorState = {optionSuggestion} setEditorState = {setOptionSuggestion} selectedNode = {selectedNode}/>
            <p>Option skipping text placeholder</p>
            <RichTextEditor editorState = {optionPlaceholder} setEditorState = {setOptionPlaceholder} selectedNode = {selectedNode}/>
        </div>}
            <div className='flex items-center px-3 pb-3'>
                <input type='checkbox' id='bb' checked = {shortHand} onChange = {() => setShortHand(!shortHand)} />
                <label className='text-[14px] pl-2 cursor-pointer ' for="bb">Shorten for Handoffs</label>
            </div>
            {shortHand && <div>
            <p>Text for Handoffs</p>
            <RichTextEditor editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode}/>
        </div>}

            <div className='editorBtn px-3 py-3'>
                  <button onClick={handleSave} className='border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save as draft</button><button onClick={handleCancel} className='group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
              </div>
        </>
    )
}


export default Gender;
