import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import BackScreen from '../BackScreen';

const Cards = (props)=>{
    const [feture, setfeture] = useState(false);
   const addFeature = ()=>{
        // alert('test')
        setfeture(true);
   }
    return(
        <>
        <div className='px-3 my-4'>
           <p className="text-sm text-[#5C5C5C] mb-3">Bot message</p>
            <RichTextEditor />
        </div>

        <div className='px-3 my-4 cstm-swiper'>
            

    <Swiper slidesPerView={2}
        spaceBetween={10}
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <div className='absolute top-0 w-full z-10'>
            <div className='flex justify-between'>
            <h3 className='text-[#5C5C5C] text-sm'>Card View</h3>
            <button className='border border-indigo-600 text-sm px-3 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white'>Add Card</button>
            </div>
        </div>
        <SwiperSlide>
            {feture? <BackScreen />: ''}
            
            {/* <div className='back-view border rounded p-3 bg-white'>
                <div className='flex items-center gap-2 cursor-pointer'>
                    <img className='w-5 h-3' src={require('../../../src/img/back-icon.png')} alt='Back Icon' />
                    <h3 className='text-[#626262]'>Back</h3>
                </div>
                <h2 className='text-sm mb-1 mt-1'>Add Button</h2>
                <p className='text-[#626262] text-xs'>
                    Configure button to display at the bottom of card
                </p>
                <h2 className='text-sm mb-1 mt-1'>Button text</h2>
                <p className='text-[#5F5F5F] text-[10px] bg-[#F4F5FA] p-2'>Learn More</p>

                <h3 className='text-sm text-[#626262] mt-2'>Actions</h3>
            <div className='flex items-center mt-1'>
                <input type="radio" id="paymentBtn12" name="fav_language" />
                <label for="paymentBtn12" className="cursor-pointer text-xs text-[#5C5C5C] pl-2">Add as Node</label>
            </div>
            <div className='flex items-center mt-1'>
                <input type="radio" id="paymentBtn13" name="fav_language" checked/>
                <label for="paymentBtn13" className="cursor-pointer text-xs text-[#5C5C5C] pl-2">Url</label>
            </div>
            <div className='flex items-center mt-1'>
                <input type="radio" id="paymentBtn134" name="fav_language" checked/>
                <label for="paymentBtn134" className="cursor-pointer text-xs text-[#5C5C5C] pl-2">Contact Number</label>
            </div>
            <input className='text-[#5F5F5F] text-[10px] bg-[#F4F5FA] p-2 w-full rounded outline-0 mt-2' type="text" placeholder='Enter Contact Number' />
            <div className='editorBtn py-3'>
                <button className='group border border-[#C6C6C6] hover:border-[#4E4E4E] text-[11px] mr-1 px-2 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white' >Cancel</button>
                <button className='border border-indigo-600 text-[11px] px-2 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white'>Save Button</button>
            </div>
            </div> */}

        <div>
            <div className='mt-1 mb-3 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.184" height="28.184" viewBox="0 0 28.184 28.184">
                    <g id="Group_72334" data-name="Group 72334" transform="translate(-287.834 -465.584)">
                    <g id="Group_72333" data-name="Group 72333" transform="translate(287.834 465.584)">
                    <rect id="Rectangle" width="28.184" height="28.184" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                    </g>
                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.214,15.923a1.432,1.432,0,0,0,1.428,1.428h5.712a1.432,1.432,0,0,0,1.428-1.428V7.356H8.214ZM17.5,5.214H15L14.282,4.5h-3.57L10,5.214H7.5V6.642h10Z" transform="translate(289.636 468.407)" fill="#fff"/>
                    </g>
                </svg>
            </div>
                <div className='border rounded p-3 bg-white'>
                    <div className='border rounded w-4/5 m-auto relative h-20 p-2'>
                        <div className='border-2 h-full border-dotted'>
                        <input type='file' className='opacity-0 w-full absolute h-full z-10 cursor-pointer'/>
                        <svg className='absolute -right-2 -bottom-2' xmlns="http://www.w3.org/2000/svg" width="21.715" height="21.715" viewBox="0 0 21.715 21.715">
                            <g id="Group_72393" data-name="Group 72393" transform="translate(0 0)">
                                <g id="Group_72333" data-name="Group 72333" transform="translate(0 0)">
                                <rect id="Rectangle" width="21.715" height="21.715" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                                </g>
                                <path id="Icon_material-file-upload" data-name="Icon material-file-upload" d="M10.524,14.33h4.537V9.793h3.024L12.793,4.5,7.5,9.793h3.024ZM7.5,15.842H18.086v1.512H7.5Z" transform="translate(-2.107 0.122)" fill="#fff"/>
                            </g>
                        </svg>
                        </div>
                    </div>

                    <div className='w-4/5 mt-4 m-auto'>
                    <input type="text" className="w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Title 1'/>
                    <input type="text" className="mt-4 w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Description' />
                    <button className='text-[#5C5C5C] text-sm text-center mt-3 block m-auto' onClick={addFeature}>+Add Button</button>
                    </div>
                </div>
                </div>
        </SwiperSlide>
        <SwiperSlide>
        {feture? <BackScreen />: ''}
        <div>
        <div className='mt-1 mb-3 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.184" height="28.184" viewBox="0 0 28.184 28.184">
                    <g id="Group_72334" data-name="Group 72334" transform="translate(-287.834 -465.584)">
                    <g id="Group_72333" data-name="Group 72333" transform="translate(287.834 465.584)">
                    <rect id="Rectangle" width="28.184" height="28.184" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                    </g>
                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.214,15.923a1.432,1.432,0,0,0,1.428,1.428h5.712a1.432,1.432,0,0,0,1.428-1.428V7.356H8.214ZM17.5,5.214H15L14.282,4.5h-3.57L10,5.214H7.5V6.642h10Z" transform="translate(289.636 468.407)" fill="#fff"/>
                    </g>
                </svg>
            </div>
                <div className='border rounded p-3 bg-white'>
                    <div className='border rounded w-4/5 m-auto relative h-20 p-2'>
                        <div className='border-2 h-full border-dotted'>
                        <input type='file' className='opacity-0 w-full absolute h-full z-10 cursor-pointer'/>
                        <svg className='absolute -right-2 -bottom-2' xmlns="http://www.w3.org/2000/svg" width="21.715" height="21.715" viewBox="0 0 21.715 21.715">
                            <g id="Group_72393" data-name="Group 72393" transform="translate(0 0)">
                                <g id="Group_72333" data-name="Group 72333" transform="translate(0 0)">
                                <rect id="Rectangle" width="21.715" height="21.715" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                                </g>
                                <path id="Icon_material-file-upload" data-name="Icon material-file-upload" d="M10.524,14.33h4.537V9.793h3.024L12.793,4.5,7.5,9.793h3.024ZM7.5,15.842H18.086v1.512H7.5Z" transform="translate(-2.107 0.122)" fill="#fff"/>
                            </g>
                        </svg>
                        </div>
                    </div>

                    <div className='w-4/5 mt-4 m-auto'>
                    <input type="text" className="w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Title 1'/>
                    <input type="text" className="mt-4 w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Description' />
                    <button className='text-[#5C5C5C] text-sm text-center mt-3 block m-auto' onClick={addFeature}>+Add Button</button>
                    </div>
                </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        {feture? <BackScreen />: ''}
        <div>
        <div className='mt-1 mb-3 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.184" height="28.184" viewBox="0 0 28.184 28.184">
                    <g id="Group_72334" data-name="Group 72334" transform="translate(-287.834 -465.584)">
                    <g id="Group_72333" data-name="Group 72333" transform="translate(287.834 465.584)">
                    <rect id="Rectangle" width="28.184" height="28.184" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                    </g>
                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.214,15.923a1.432,1.432,0,0,0,1.428,1.428h5.712a1.432,1.432,0,0,0,1.428-1.428V7.356H8.214ZM17.5,5.214H15L14.282,4.5h-3.57L10,5.214H7.5V6.642h10Z" transform="translate(289.636 468.407)" fill="#fff"/>
                    </g>
                </svg>
            </div>
                <div className='border rounded p-3 bg-white'>
                    <div className='border rounded w-4/5 m-auto relative h-20 p-2'>
                        <div className='border-2 h-full border-dotted'>
                        <input type='file' className='opacity-0 w-full absolute h-full z-10 cursor-pointer'/>
                        <svg className='absolute -right-2 -bottom-2' xmlns="http://www.w3.org/2000/svg" width="21.715" height="21.715" viewBox="0 0 21.715 21.715">
                            <g id="Group_72393" data-name="Group 72393" transform="translate(0 0)">
                                <g id="Group_72333" data-name="Group 72333" transform="translate(0 0)">
                                <rect id="Rectangle" width="21.715" height="21.715" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                                </g>
                                <path id="Icon_material-file-upload" data-name="Icon material-file-upload" d="M10.524,14.33h4.537V9.793h3.024L12.793,4.5,7.5,9.793h3.024ZM7.5,15.842H18.086v1.512H7.5Z" transform="translate(-2.107 0.122)" fill="#fff"/>
                            </g>
                        </svg>
                        </div>
                    </div>

                    <div className='w-4/5 mt-4 m-auto'>
                    <input type="text" className="w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Title 1'/>
                    <input type="text" className="mt-4 w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Description' />
                    <button className='text-[#5C5C5C] text-sm text-center mt-3 block m-auto'>+Add Button</button>
                    </div>
                </div>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        {feture? <BackScreen />: ''}
        <div>
        <div className='mt-1 mb-3 cursor-pointer'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.184" height="28.184" viewBox="0 0 28.184 28.184">
                    <g id="Group_72334" data-name="Group 72334" transform="translate(-287.834 -465.584)">
                    <g id="Group_72333" data-name="Group 72333" transform="translate(287.834 465.584)">
                    <rect id="Rectangle" width="28.184" height="28.184" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                    </g>
                    <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.214,15.923a1.432,1.432,0,0,0,1.428,1.428h5.712a1.432,1.432,0,0,0,1.428-1.428V7.356H8.214ZM17.5,5.214H15L14.282,4.5h-3.57L10,5.214H7.5V6.642h10Z" transform="translate(289.636 468.407)" fill="#fff"/>
                    </g>
                </svg>
            </div>
                <div className='border rounded p-3 bg-white'>
                    <div className='border rounded w-4/5 m-auto relative h-20 p-2'>
                        <div className='border-2 h-full border-dotted'>
                        <input type='file' className='opacity-0 w-full absolute h-full z-10 cursor-pointer'/>
                        <svg className='absolute -right-2 -bottom-2' xmlns="http://www.w3.org/2000/svg" width="21.715" height="21.715" viewBox="0 0 21.715 21.715">
                            <g id="Group_72393" data-name="Group 72393" transform="translate(0 0)">
                                <g id="Group_72333" data-name="Group 72333" transform="translate(0 0)">
                                <rect id="Rectangle" width="21.715" height="21.715" rx="4.5" transform="translate(0 0)" fill="#5269c9"/>
                                </g>
                                <path id="Icon_material-file-upload" data-name="Icon material-file-upload" d="M10.524,14.33h4.537V9.793h3.024L12.793,4.5,7.5,9.793h3.024ZM7.5,15.842H18.086v1.512H7.5Z" transform="translate(-2.107 0.122)" fill="#fff"/>
                            </g>
                        </svg>
                        </div>
                    </div>

                    <div className='w-4/5 mt-4 m-auto'>
                    <input type="text" className="w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Title 1'/>
                    <input type="text" className="mt-4 w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs" placeholder='Card Description' />
                    <button className='text-[#5C5C5C] text-sm text-center mt-3 block m-auto'>+Add Button</button>
                    </div>
                </div>
        </div>
        </SwiperSlide>
      </Swiper>

        </div>
        </>
    )
}


export default Cards;
