var answer = {
    chatBotAvtars: {
        chatBotAvtar: [
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar2.svg?alt=media&token=8b7982ab-0060-472b-ad51-6792e3fba162",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar1.svg?alt=media&token=fe41f944-828d-47af-bfcc-d14d14af0956",
        ],
    },
    template_One: {
        0: {
            UI: "Welcome Message",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Hi! I am Snappy, a friendly bot, Welcome to Appy Pie LLP.",
            },
            input: "",
            next: 1,
            trigger: [],
        },
        1: {
            UI: "Bot Question",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 2,
                type: "question",
            },
            action: "",
            continue: false,
            data: {
                dropDownresponseText: "Employee1,Employee2,Employee3",
                responseText: "Thanks",
                text: "Is there anything I can help you with?",
                rtype: { label: "Text", value: "text" },
                isOptional: false,
            },
            input: "",
            next: null,
            trigger: [],
        },
        2: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Thanks",
            },
            input: "",
            next: 3,
            trigger: [],
        },
        3: {
            UI: "Bot Invoke",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "invoking bot",
            continue: false,
            data: {
                text: "Look for answers in the knowledge base",
            },
            input: "Button",
            next: "",
            trigger: [{
                id: "3.1",
                name: "If the bot cannot answer",
                next: 4,
            }, ],
        },
        4: {
            UI: "Button",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "",
            continue: false,
            data: {
                isBranching: true,
                text: "I am a bot, and I'm learning something new every day. Could you please rephrase your question?",
            },
            input: "Button",
            next: "",
            trigger: [{
                    id: 4.1,
                    name: "Yes, sure!",
                    next: 5,
                },
                {
                    id: 4.2,
                    name: "No, connect me to an agent",
                    next: 23,
                },
                {
                    id: 4.3,
                    name: "No, I’d like to send an inquiry",
                    next: 9,
                },
            ],
        },
        5: {
            UI: "Bot Question",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 6,
                type: "question",
            },
            action: "",
            continue: false,
            data: {
                dropDownresponseText: "Employee1,Employee2,Employee3",
                responseText: "Thanks",
                text: "Great! Please rephrase your question now.",
                rtype: { label: "Text", value: "text" },
                isOptional: false,
            },
            input: "",
            next: null,
            trigger: [],
        },
        6: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great! Please rephrase your question now.",
            },
            input: "",
            next: 7,
            trigger: [],
        },
        7: {
            UI: "Bot Invoke",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "invoking bot",
            continue: false,
            data: {
                text: "Look for answers in the knowledge base",
            },
            input: "Button",
            next: "",
            trigger: [{
                id: "7.1",
                name: "If the bot cannot answer",
                next: 8,
            }, ],
        },
        8: {
            UI: "End Message",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: false,
            data: {
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        9: {
            UI: "Bot Question",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: "10",
                type: "question",
            },
            action: "",
            continue: false,
            data: {
                responseText: "Great! Thanks for the information.",
                text: "How can I help you?",
            },
            input: "",
            next: null,
            trigger: [],
        },
        10: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: 11,
            trigger: [],
        },
        11: {
            UI: "Get Name",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 12,
                type: "Name",
            },
            action: "",
            continue: false,
            data: {
                responseText: "Great, thanks!",
                text: "Before we send your query to the concerned team we'd appreciate if you can provide your name?",
            },
            input: "",
            next: null,
            trigger: [],
        },
        12: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great, thanks!",
            },
            input: "",
            next: 13,
            trigger: [],
        },
        13: {
            UI: "Get Email",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 15,
                type: "Email",
                unsuccessful: 14,
                text: "Now please tell me your email ID, so that we can stay in touch, even in future!",
            },
            action: "",
            continue: false,
            data: {
                successful: "Great! Thanks for the email ID, now we don't have to worry about getting disconnected.",
                text: "Now please tell me your email ID, so that we can stay in touch, even in future!",
                unsuccessful: {
                    failedAttemptsFinal: "Oops! Looks like you are entering an invalid email address. We usually accept business email ids only like abc@appypiellp.com",
                    failedAttemptsText: "Oops! Looks like you entered an invalid email address. Please give me a valid email address.",
                    no_of_email: "",
                    nonBusinessCheck: "",
                    nonBusinessEmails: [],
                    spamMailCheck: "",
                    spamMailText: [],
                },
            },
            input: "",
            next: null,
            trigger: [],
        },
        14: {
            UI: "Get Email",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 15,
                type: "Email",
                unsuccessful: 14,
                text: "Now please tell me your email ID, so that we can stay in touch, even in future!",
            },
            action: "",
            continue: false,
            data: {
                text: "Oops! Looks like you entered an invalid email address. Please give me a valid email address.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        15: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great! Thanks for the email ID, now we don't have to worry about getting disconnected.",
            },
            input: "",
            next: 16,
            trigger: [],
        },
        16: {
            UI: "Get Phone",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 18,
                type: "Phone",
                unsuccessful: 17,
                text: "Lastly, please share your contact number.",
            },
            action: "",
            continue: false,
            data: {
                successful: {
                    botResponseCheck: "",
                    botResponseText: "Great, thanks!",
                    countrycode: "",
                },
                text: "Lastly, please share your contact number.",
                unsuccessful: {
                    botResponseUnCheck: "",
                    botResponseUnText: "Oops! Looks like you entered an invalid contact number. Please give me a valid one.",
                    botResponseUsTextFinal: "Thanks for the number.",
                    no_of_repeatQuestion: "",
                },
            },
            input: "",
            next: null,
            trigger: [],
        },
        17: {
            UI: "Get Phone",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 18,
                type: "Phone",
                unsuccessful: 17,
                text: "Lastly, please share your contact number.",
            },
            action: "",
            continue: false,
            data: {
                text: "Oops! Looks like you entered an invalid contact number. Please give me a valid one.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        18: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great, thanks!",
            },
            input: "",
            next: 19,
            trigger: [],
        },
        19: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Thanks for providing your details. We've forwarded your inquiry to the concerned team. Someone will get back to you soon.",
            },
            input: "",
            next: 20,
            trigger: [],
        },
        20: {
            UI: "Handoffs",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: true,
            data: {
                handoffs: {
                    appy_email: {
                        id: 0,
                        type: "Email",
                        title: "Appy Email",
                        data: {
                            email: "",
                        },
                    },
                },
                text: "Great! Thanks for the informations.",
            },
            input: "",
            next: 21,
            trigger: [],
        },
        21: {
            UI: "Feedback",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "feedback",
            continue: false,
            data: {
                feedback_type: {
                    presentation: {
                        label: "1 to 5 scale",
                        value: "1-5",
                    },
                    type: {
                        label: "Scale",
                        value: "rating-scale",
                    },
                },
                isEnable: true,
                responseText: "Thank you for your feedback.",
                text: "Please rate your conversation with Snappy.",
            },
            input: "",
            next: 22,
            trigger: [],
        },
        22: {
            UI: "End Message",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: false,
            data: {
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        23: {
            UI: "Get Name",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 24,
                type: "Name",
            },
            action: "",
            continue: false,
            data: {
                responseText: "Great, thanks!",
                text: "Can I get your name please?",
            },
            input: "",
            next: null,
            trigger: [],
        },
        24: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great, thanks!",
            },
            input: "",
            next: 25,
            trigger: [],
        },
        25: {
            UI: "Get Email",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 27,
                type: "Email",
                unsuccessful: 26,
                text: "Please tell me your email ID, so that we can stay in touch, even in future!",
            },
            action: "",
            continue: false,
            data: {
                successful: "Great! Thanks for the email ID, now we don't have to worry about getting disconnected",
                text: "Please tell me your email ID, so that we can stay in touch, even in future!",
                unsuccessful: {
                    failedAttemptsFinal: "Oops! Looks like you are entering an invalid email address. We usually accept business email ids only like abc@appypiellp.com",
                    failedAttemptsText: "Oops! Looks like you entered an invalid email address. Please give me a valid email address.",
                    no_of_email: "",
                    nonBusinessCheck: "",
                    nonBusinessEmails: [],
                    spamMailCheck: "",
                    spamMailText: [],
                },
            },
            input: "",
            next: null,
            trigger: [],
        },
        26: {
            UI: "Get Email",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 27,
                type: "Email",
                unsuccessful: 26,
                text: "Please tell me your email ID, so that we can stay in touch, even in future!",
            },
            action: "",
            continue: false,
            data: {
                text: "Oops! Looks like you entered an invalid email address. Please give me a valid email address.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        27: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great! Thanks for the email ID, now we don't have to worry about getting disconnected.",
            },
            input: "",
            next: 28,
            trigger: [],
        },
        28: {
            UI: "Get Phone",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 30,
                type: "Phone",
                unsuccessful: 29,
                text: "Please share your contact number.",
            },
            action: "",
            continue: false,
            data: {
                successful: {
                    botResponseCheck: "",
                    botResponseText: "Great, thanks!",
                    countrycode: "",
                },
                text: "Please share your contact number.",
                unsuccessful: {
                    botResponseUnCheck: "",
                    botResponseUnText: "Oops! Looks like you entered an invalid contact number. Please give me a valid one.",
                    botResponseUsTextFinal: "Thanks for the number.",
                    no_of_repeatQuestion: "",
                },
            },
            input: "",
            next: null,
            trigger: [],
        },
        29: {
            UI: "Get Phone",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 30,
                type: "Phone",
                unsuccessful: 29,
                text: "Please share your contact number.",
            },
            action: "",
            continue: false,
            data: {
                text: "Oops! Looks like you entered an invalid contact number. Please give me a valid one.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        30: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great, thanks!",
            },
            input: "",
            next: 31,
            trigger: [],
        },
        31: {
            UI: "Condition",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "",
            continue: false,
            data: {
                text: "Get Condition",
            },
            input: "Button",
            next: "",
            trigger: [{
                    id: "31.1",
                    name: "Status online",
                    next: 32,
                },
                {
                    id: "31.2",
                    name: "Status offline",
                    next: 37,
                },
            ],
        },
        32: {
            UI: "Bot Question",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 33,
                type: "question",
            },
            action: "",
            continue: false,
            data: {
                responseText: "Great! Thanks for the information.",
                text: "Great! Before I transfer you to an agent please share your query with me. It'll help the agent respond better.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        33: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: 34,
            trigger: [],
        },
        34: {
            UI: "Handoffs",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: true,
            data: {
                handoffs: {
                    appy_email: {
                        id: 0,
                        type: "Email",
                        title: "Appy Email",
                        data: {
                            email: "",
                        },
                    },
                    appy_agentTransfer: {
                        id: 1,
                        type: "agent transfer",
                        title: "Appy Livechat",
                        data: {
                            email: "",
                        },
                    },
                },
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: 35,
            trigger: [],
        },
        35: {
            UI: "Feedback",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "feedback",
            continue: false,
            data: {
                feedback_type: {
                    presentation: {
                        label: "1 to 5 scale",
                        value: "1-5",
                    },
                    type: {
                        label: "Scale",
                        value: "rating-scale",
                    },
                },
                isEnable: true,
                responseText: "Thank you for your feedback",
                text: "Please rate your conversation with Snappy.",
            },
            input: "",
            next: 36,
            trigger: [],
        },
        36: {
            UI: "End Message",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: false,
            data: {
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        37: {
            UI: "Bot Question",
            Validation: {
                doValidate: true,
                loop: 0,
                successful: 38,
                type: "question",
            },
            action: "",
            continue: false,
            data: {
                responseText: "Great! Thanks for the information. We'll get in touch soon.",
                text: "Oops! There's no agent online right now! Please enter your query here, so that they can get back to you soon!",
            },
            input: "",
            next: null,
            trigger: [],
        },
        38: {
            UI: "Bot Message",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "",
            continue: true,
            data: {
                text: "Great! Thanks for the information. We'll get in touch soon.",
            },
            input: "",
            next: 39,
            trigger: [],
        },
        39: {
            UI: "Handoffs",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: true,
            data: {
                handoffs: {
                    appy_email: {
                        id: 0,
                        type: "Email",
                        title: "Appy Email",
                        data: {
                            email: "",
                        },
                    },
                },
                text: "Great! Thanks for the informations.",
            },
            input: "",
            next: 40,
            trigger: [],
        },
        40: {
            UI: "Feedback",
            Validation: {
                doValidate: false,
                loop: 0,
                type: "",
            },
            action: "feedback",
            continue: false,
            data: {
                feedback_type: {
                    presentation: {
                        label: "1 to 5 scale",
                        value: "1-5",
                    },
                    type: {
                        label: "Scale",
                        value: "rating-scale",
                    },
                },
                isEnable: true,
                responseText: "Thank you for your feedback.",
                text: "Please rate your conversation with Snappy.",
            },
            input: "",
            next: 41,
            trigger: [],
        },
        41: {
            UI: "End Message",
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "SaveInquiry",
            continue: false,
            data: {
                text: "Great! Thanks for the information.",
            },
            input: "",
            next: null,
            trigger: [],
        },
        "00": {
            Validation: {
                doValidate: false,
                type: "",
            },
            action: "",
            continue: false,
            input: "",
            message: "null",
            next: 0,
            trigger: [],
        },

        customisation: {
            backgroundChatBot: "#FFFFFF",
            botButtonColor: "#2c3049ff",
            botButtonText: "lora",
            botButtonTextColor: "#FFFFFF",
            botTextcolor: "#000",
            chatBotAvtar: "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/chatbotavtaar%2Fchatbotavatar3.svg?alt=media&token=f6319d10-02fb-490c-892a-8b49a553fcc2",
            clientBubbleClr: "#2c3049ff",
            clientchattextcolor: "#ffffff",
            designLanguage: "english(en)",
            designPage: "default",
            headerClr: "#2c3049ff",
            headerName: "Q&A bot",
            headertextcolor: "#ffffff",
            messagebubble: "#0606064d",
            sendButtonClr: "#2c3049ff",
            textfonts: "roboto",
            widgetBackgroundColor: "#2c3049ff",
            widgetImage: "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&token=6a39c949-c97b-4811-9414-c21c2e249985",
            widgetPosition: {
                bottom: "8",
                position: "right",
                side: "8",
            },
            widgetSize: "large",
        },
        time: 1562221203801,
    },
    widgetImages: {
        widgetImage: [
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar3.svg?alt=media&token=6a39c949-c97b-4811-9414-c21c2e249985",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar2.svg?alt=media&token=eda6ee3f-a82e-405d-91cc-ae80ae5910d0",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fchatbotavatar1.svg?alt=media&token=23fb8ec9-5129-4c87-b060-cc0fe84e9377",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget2.svg?alt=media&token=210ce6f4-f9ef-475c-a757-d08cd1d84467",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget1.svg?alt=media&token=782bf939-6627-4f33-ae1a-a9f6e9cf8c1f",
            "https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/widgetIcon%2Fwidget3.svg?alt=media&token=49981112-ba7b-48e6-90f0-4cd8486b97a2",
        ],
    },
    import_data: {
        import_urls: [],
        import_files: [],
    },
    trained_data: {
        details: {
            which_method: "",
        },
        data: [],
    },
};

// export default answer;
module.exports = answer