import React from 'react'
import { useNavigate } from 'react-router-dom'

function SubscribeFail() {
  const navigate = useNavigate()
    const handlePay = () => {
        navigate('/subscribe')
    }
  return (
    <>
    <div>SubscribeFail</div>
    <span>
        Gareeb go back
    </span>
    <button onClick={()=>{handlePay()}}>Try Again</button>
    </>
  )
}

export default SubscribeFail