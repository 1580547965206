import React from 'react'
import { useEffect } from 'react'
import ZendeskIntegration from './ZendeskIntegration'
// import SlackIntegration from './SlackIntegration'
import GoogleIntegration from './GoogleIntegration'
// import FacebookIntegration from './FacebookIntegration'
import WhatsappIntegration from './WhatsappIntegration'
import SmsIntegration from './SmsIntegration'
import EmailIntegration from './EmailIntegration'
import SMTPIntegration from './SMTPIntegration'
// import SalesforceIntegration from './SalesforceIntegration'
import MicrosoftIntegration from './MicrosoftIntegration'
import ZohoIntegration from './ZohoIntegration'
import SalesForceIntegration from './SalesForceIntegration'
import ZoomIntegration from './ZoomIntegration'


function IntegrationPopup(props) {

//   const {title,id,img,isInstalled} = props.appData

//   const integrationData = [sms,slack,zendesk,gdrive,facebook,whatsapp,email,smtp,salesforce,microsoftteams,zohodesk,zoom]
    useEffect(() => {
        console.log(props,"PRPS on POPUP")
    },[])
  return (
   <div >
    <div >
        
        <span >{props.isInstalled?"Remove Integration":"Setup Integration"}</span>
        <span ><img onClick={()=>props.closePopup()} src="" alt="close" />
        </span>
        </div>
        <div>
            <div>
                <div>
                    {props.appData&&props.appData.id=="zendesk"&&<ZendeskIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}  />}
                    {props.appData&&props.appData.id=="smtp"&&<SMTPIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="appy_email"&&<EmailIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="whatsapp"&&<WhatsappIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="sms"&&<SmsIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="gdrive"&&<GoogleIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="microsoftteams"&&<MicrosoftIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="zoho_desk"&&<ZohoIntegration appData={props.appData} closePopup={props.closePopup} isInstalled = {props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="salesforce"&&<SalesForceIntegration appData={props.appData} closePopup={props.closePopup} isInstalled={props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {props.appData&&props.appData.id=="zoom"&&<ZoomIntegration appData={props.appData} closePopup={props.closePopup} isInstalled={props.isInstalled} mode={props.mode} isFromEditor={props.isFromEditor} editorFunction={props.editorFunction}/>}
                    {/* {props.id=="slack"&&<SlackIntegration />}
                    {props.id=="googlesheets"&&<GoogleIntegration />}
                    {props.id=="messenger"&&<FacebookIntegration />}
                    {props.id=="whatsapp"&&<WhatsappIntegration />}
                    {props.id=="sms"&&<SmsIntegration />}
                    {props.id=="email"&&<EmailIntegration />}
                    {props.id=="smtp"&&<SmtpIntegration />}
                    {props.id=="salesforce"&&<SalesforceIntegration />}
                    {props.id=="microsoftteams"&&<MicrosoftIntegration />}
                    {props.id=="zohodesk"&&<ZohoIntegration />}
                    {props.id=="zoom"&&<ZoomIntegration />} */}
                </div>
            </div>
        </div>
    </div>

  )
}

export default IntegrationPopup