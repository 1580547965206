import React , {useState , useEffect} from 'react'
import { getDeployedWhatsappList, whatsappsetup } from '../../../utilities/chatbotMethods'
import { NotificationManager } from 'react-notifications'


function Whatsapp() {

    
    const [whatsappAccessToken, setWhatsappAccessToken] = useState("")
    const [whatsappAccessId, setWhatsappAccessId] = useState("")
    const [whatsappPhoneId, setWhatsappPhoneId] = useState("")
    const [isLoading , setIsLoading] = useState(true)
    const [isInstalled , setIsInstalled] = useState(false)
    const [enable , setEnable] = useState(false)


    

    const toggleWhatsAppStatus = (event) => {

        if(enable){
            handleWhatsapp("disable")
        }else{
            handleWhatsapp("enable")
        }
    }



    useEffect(() => {
        

        getDeployedWhatsappList(localStorage.getItem('botId')).then((data) => {
            if(data.length > 0){
                setWhatsappAccessToken(data[0].whatsappAccessToken)
                setWhatsappAccessId(data[0].whatsappAccessId)
                setWhatsappPhoneId(data[0].whatsappPhoneId)
                setEnable(data[0].isActive)
                setIsInstalled(true)

            }
            setIsLoading(false)
        })
    },[])


    async function handleWhatsapp(action) {
        setIsLoading(true)
    
    
            let whatsappData = {
              alias: localStorage.getItem("tablealias"),
              agentID: localStorage.getItem("agent_id"),
              BOTID: localStorage.getItem("botId"),
              // Token: viberAccessToken,
              whatsappAccessId: whatsappAccessId,
              whatsappAccessToken:whatsappAccessToken,
              whatsappPhoneId:whatsappPhoneId,
              email:localStorage.getItem("email"),
              action: action,
              Botname: localStorage.getItem("BOTNAME"),
              // LineUsername: result.basicId,
              // displayName:result.displayName,
              // docId:result.userId,
              Bottype:localStorage.getItem('botType'),
              isActive:true
            }
            console.log("+++++++++ whatsappData +++++++++", whatsappData)
            whatsappsetup(whatsappData, action)
              .then((response) => {
                console.log("++++++++ Response ++++++++", response) 
                let msg;
                if (response) {
                  if(action=="setup"){
                    if (response) {
                      setIsInstalled(true)
                      setIsLoading(false)
                      setEnable(true)
                    
                    } else {
                      setIsLoading(false)
                      NotificationManager.error('', 'Invalid bot token', 2000)
                    }
                  }
                  else if (action == "enable") { 
                    setEnable(true)
                    setIsLoading(false)
                    // setWhatsappList(newList)
                  } else if (action == "disable") {
                    setEnable(false)
                    setIsLoading(false)
                    
                  } else {
                    setIsLoading(false)
                    setIsInstalled(false)
                    setEnable(false)
                    setWhatsappAccessToken('')
                    setWhatsappAccessId('')
                    setWhatsappPhoneId('')
                  }
                  setIsLoading(false)
                  NotificationManager.success('', msg, 2000)
                } else {
                  NotificationManager.error('', 'Error updating bot status', 2000)
                }
              })
            
          // })
    
      }



  return (
    <div>
        {isLoading ? <div> Loading...</div> :
        
        <>
        <img src="" alt="" />
        {isInstalled ? <>
                        <div>
                          <img src="" alt="" />
                          
                          
                          {/* <input type="button" name="isEnable" id="isEnable" checked={enable} onChange = {(event) => {toggleTelegramStatus(event.target.value)}} /> */}
                          <button className='ml-auto border border-indigo-600 text-sm px-3 py-1 text-center rounded bg-indigo-600 text-white mr-5' onClick={() => {toggleWhatsAppStatus()} }>{enable ? 'Disable' : 'Enable'}</button>
                          <button className='ml-auto border border-red-600 text-sm px-3 py-1 text-center rounded bg-red-600 text-white' onClick={() => handleWhatsapp('uninstall')}>Delete</button>
                        </div>
                      </> :
        <>
        <p className='font-medium text-lg'>Deploy your bot in whatsapp account</p>
        <a href="https://snappy.desk.appypie.com/kb/article/how-to-deploy-chatbot-on-whatsapp" target='_blank'></a>
        <form onSubmit = {() => handleWhatsapp('setup')}>
            <label className='block mt-3 text-sm mb-1' htmlFor="">Enter Whatsapp Business number id</label>
            <input className="px-3 border mr-2 py-1 rounded h-9 w-2/4 mb-3" type="text" value={whatsappAccessId} onChange = {(event) => setWhatsappAccessId(event.target.value)} required placeHolder = 'number id' />
            <label className='block text-sm mb-1' htmlFor="">Enter Whatsapp Phone number id</label>
            <input className="px-3 border mr-2 py-1 rounded h-9 w-2/4 mb-3" type="text" value={whatsappPhoneId} onChange = {(event) => setWhatsappPhoneId(event.target.value)} required placeHolder = 'phone'/>
            <label className='block text-sm mb-1' htmlFor="">Enter the access key (bot token)</label>
            <input className="px-3 border mr-2 py-1 rounded h-9 w-2/4" type="text" value={whatsappAccessToken} onChange = {(event) => setWhatsappAccessToken(event.target.value)} required placeHolder = 'token'/>
            <br />
            <input  className='h-9 border border-indigo-600 text-sm px-5 py-1 text-center rounded text-indigo-600 bg-indigo-600 text-white mt-3 cursor-pointer' type="submit" value='Install'/>
            
            
        </form>
        </>
        }
        </>}
    </div>
  )
}

export default Whatsapp