import React from "react";
import {Link} from 'react-router-dom'
import SideMenu from "../../Component/sidemenu";
import Topheader from "../../Component/TopHeader";


const PreviewTemp =()=>{
    return(
        <>
            {/* <SideMenu /> */}
            <div className="pl-[76px] lg:pl-[276px] p-5">   
                <Topheader pT="Item ordering bot"  />
                <div className="previewTempSection p-5 bg-white sm:flex block rounded-2xl drop-shadow mt-5">
                    <div className="previewTempLeft sm:w-2/4 w-full py-5">
                        <p className="text-[#454545] text-sm leading-7">Businesses are searching for new technologies that can fundamentally alter digital communication as a result of the rapid global evolution. To businesses in a variety of sectors, including retail, manufacturing, eCommerce, etc., that is precisely where email bots are most valuable. An email registration bot is one among them that renders a range of benefits. There are many benefits of creating an email registration bot, including:</p>
                        <button className="bg-[#5269C9] text-white px-5 mt-5 rounded-xl py-3 text-xl">Use this template</button>
                    </div>
                    <div className="previewTempRightBoat sm:w-1/3 w-full">
                        Boat
                    </div>
                </div>
            </div>
        </>
    )
}

export default PreviewTemp;