import React, { useEffect,useState } from 'react'
import { API_URL, COMMON_BILLING_URL } from '../../config/params'
import { isAnyPaymentinAcc, stopLoader } from '../../utilities/chatbotMethods'
import { useSelector } from 'react-redux'
import axios from 'axios'

function SubscribeNow() {

    const {pricingPlan , comboPlan , subscriptionUrl} = useSelector((state) => state.payment)
    const [isLoading , setIsLoading] = useState(true)
    const [currency , setCurrency] = useState('USD')
    const [planPeriod , setPlanPeriod] = useState("monthly")
    const [planName , setPlanName] = useState()
    const [planPrice , setPlanPrice] = useState()
    const [isTryNowAllowed , setIsTryNowAllowed] = useState()
    const [planId , setPlanId] = useState()
    const [purchaseType , setPurchaseType] = useState('subscription')
const [premiumMonthly, setpremiumMonthly] = useState('')
const [premiumYearly, setpremiumYearly] = useState('')
const [basicMonthly, setbasicMonthly] = useState('')
const [basicYearly, setbasicYearly] = useState('')
const countryWithoutTryNow = ['IN', 'MX', 'BR', 'AR', 'CO']

    

    useEffect(() => {
        
        if(pricingPlan){
            // clearInterval(intv)
            console.log(pricingPlan,"PRICINGPLAN")
            setCurrency(pricingPlan.priceArr[0].currencyCode)
     
            var appcountry=localStorage.getItem("appcountry")
            var isTrialAllowed = !countryWithoutTryNow.includes(appcountry)
            pricingPlan.priceArr.map(doc=>{
                if(doc.planId === '8' && doc.planPeriod === 'monthly'){
                    // premiumMonthly = doc.planPrice
                    setPlanId('8')
                    setpremiumMonthly(doc.planPrice)
                    setPlanPeriod('monthly')
                    setPlanName('Premium Plan')
                    setPlanPrice(doc.planPrice)

                }else if (doc.planId === '8' && doc.planPeriod === 'yearly'){
                    // premiumYearly = doc.planPrice
                    setpremiumYearly(doc.planPrice/12)
                }else if (doc.planId === '7' && doc.planPeriod === 'monthly'){
                    // basicMonthly = doc.planPrice
                    setbasicMonthly(doc.planPrice)
                }else if (doc.planId === '7' && doc.planPeriod === 'yearly'){
                    // basicYearly = doc.planPrice
                    setbasicYearly(doc.planPrice/12)
                }
            })
            // console.log(premiumMonthly,premiumYearly,basicMonthly,basicYearly,"PRICINGPLAN")
            if(isTrialAllowed){
                isAnyPaymentinAcc().then(res=>{
                    // console.log(res,"RES")
                    setIsTryNowAllowed(!res)
                    setIsLoading(false)
                    stopLoader("route")
                })

            }else{
                setIsLoading(false)
                stopLoader("route")

            }

            


        }
    
    },[pricingPlan])


    const handlePayment = async (paymentType,planPeriod,planId,trialDays=7) => {
    


            
    
            var notifyUrl=API_URL+"/payment_confirmation"
            var myArray = { 1:sessionStorage.getItem("botName") }
            var botIDTemp = sessionStorage.getItem("botType")
            var product_name = "chatbot"
            
            let payment = {
                action: 'encrypt',
                string: {
                    productName: product_name,
                    planId:  planId,
                    trialPlanId:8,
                    planPeriod: planPeriod,
                    emailId: localStorage.getItem('email'),
                    phone: localStorage.getItem('phone'),
                    productId: localStorage.getItem("botId"),
                    paymentType: paymentType, //pay, trial //
                    trialDays: trialDays,
                    subscriptionType: 'subscription', //
                    successUrl: window.location.origin + "/subscribe/success", //
                    cancelUrl: window.location.origin + '/subscribe/fail', //
                    notifyUrl: notifyUrl, //
                    resetUrl: window.location.origin + '/subscribe/fail', //
                    displayMessage: myArray, //
                    orderId: `chatbot_${new Date().getTime()}`, //
                    addonType: '', //
                    currentPlanPeriod: '', //
                    currentPlanId: '' //

                }
            }
            if(sessionStorage.getItem('botType') === '36'){
                payment.string.fixedPlan="yes"
            }
            
                payment.string.extraData=JSON.stringify({
                    botName:sessionStorage.getItem("botName"),
                    botType:sessionStorage.getItem("botType"),
                    botPer:sessionStorage.getItem("answer_type")?sessionStorage.getItem("answer_type"):"",
                })
            
            if (paymentType == 'trial') {
                payment.string.countryCode = pricingPlan.priceArr[0].currencyCode
                payment.string.originalCountryCode = pricingPlan.priceArr[0].originalCountryCode
                payment.string.country = pricingPlan.priceArr[0].country
                payment.string.currencyCode = pricingPlan.priceArr[0].currencyCode
                payment.string.currentPlanPeriod = ''
                payment.string.currentPlanId = ''
                payment.string.trialPaymentMethod="stripe"
    
            }
            if (paymentType == 'trialWithoutCC') {
                payment.string.countryCode = pricingPlan.priceArr[0].currencyCode
                payment.string.originalCountryCode = pricingPlan.priceArr[0].originalCountryCode
                payment.string.country = pricingPlan.priceArr[0].country
                payment.string.currencyCode = pricingPlan.priceArr[0].currencyCode
                payment.string.currentPlanPeriod = ''
                payment.string.currentPlanId = ''
                payment.string.trialPaymentMethod="stripe"
            }
           
    
                // var trackBody = {
                //     action: "payClick",
                //     botId: localStorage.getItem("bot_id_new"),
                //     email: localStorage.getItem("email"),
                //     name: localStorage.getItem("BotName"),
                //     category: localStorage.getItem("Categoryid"),
                //     payPlan: "Premium Plan",
                //     payPeriod: planPeriod,
                //     payType: paymentType == 'trial' ? 'try_now': paymentType =="trialWithoutCC"?"try_now_stripe" : "buy_now",
                // };
                // handleFunnelQuery(trackBody)
    
    
            if(isTryNowAllowed){
                var urlfb = API_URL + "/payment_encryption"
                
                const res = await axios.post(urlfb, payment)

                // document.getElementById('request').style.display = 'block' 
                // document.getElementById('request').value = res.data.string
                
                if (res) {
                    const form = document.createElement("form");
                    form.setAttribute("id", "paymentForm");
                    form.setAttribute("method", "POST");
                    // form.setAttribute("target", "_blank");
                    form.setAttribute("action", COMMON_BILLING_URL+`/index/pay`);
                    
                    const input = document.createElement("input");
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", "request");
                    input.setAttribute("value", res.data.string);
                    
                    form.appendChild(input);
                    
                    document.body.appendChild(form);
                    form.submit();
                    document.body.removeChild(form);
                }

                
    
            }else{
                payment.string.requestState=''
                payment.string.trialPlanId=""
    
                // console.log(payment,"PAYMENTPAYLOAD")
                // localStorage.setItem('paymentbotid', this.state.botDataForPayment.key)
                // this.bqsubcriptionlog(localStorage.getItem("BotID"), this.state.botDataForPayment.data.payment ? this.state.botDataForPayment.data.payment.planName.split(" ")[0] : "Free", paymentType == 'trial' ? 'try_now': paymentType =="trialWithoutCC"?"try_now_stripe" : payment.string.planPeriod)
                var urlfb=API_URL+"/payment_encryption"
                // if (process.env.NODE_ENV != 'production') {
                //     urlfb =
                //         'https://us-central1-appycx-beta.cloudfunctions.net/payment_encryption'
                // } else {
                //     urlfb =
                //         'https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/payment_encryption'
                // }
                console.log("============ PAYMRNT DATA ============== ", payment)
                const res = await axios.post(urlfb, payment)
    
                // document.getElementById('request').style.display = 'block' 
                // document.getElementById('request').value = res.data.string
                
                if (res) {
                    const form = document.createElement("form");
                                form.setAttribute("id", "paymentForm");
                                form.setAttribute("method", "POST");
                                // form.setAttribute("target", "_blank");
                                form.setAttribute("action", COMMON_BILLING_URL+`/index/pay`);
                              
                                const input = document.createElement("input");
                                input.setAttribute("type", "hidden");
                                input.setAttribute("name", "request");
                                input.setAttribute("value", res.data.string);
                              
                                form.appendChild(input);
                              
                                document.body.appendChild(form);
                                form.submit();
                                document.body.removeChild(form);
                }
            }
            
        
    }
    const handlePeriod = (period) => {
        setPlanPeriod(period)
        if(period === 'monthly'){
            // setPlanName('Premium Plan')
            debugger
            setPlanPrice(premiumMonthly)
            setPlanId('8')
        }else{

            // setPlanName('Premium Plan')
            
            setPlanPrice(premiumYearly)
            setPlanId('8')
        }

        console.log(planPeriod,"planPeriod",premiumYearly,premiumMonthly)
    }
  return (

    <div>{isLoading?<div>Loading...</div>:<>

        {isTryNowAllowed?
        
        <>
        <span>{currency}</span>
        <br/>
        <span onClick={()=>handlePeriod('monthly')}>Monthly {planPeriod=="monthly"?"active":""}</span>
        <br/>
        <span onClick={()=>handlePeriod('yearly')}>Yearly {planPeriod=="yearly"?"active":""}</span>
        <br/>
        <span>{planName}</span>
        <br/>
        <span>{planPrice}</span>
        <br/>
        {/* <span>{isTrialAllowed}</span> */}
        <button onClick={() => handlePayment("trial",planPeriod ,planId,7)}>Start Trial</button>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
        <button onClick={() => handlePayment("trialWithoutCC",planPeriod ,planId,7)}>Start No CC Trial</button>
        </>:
        <>
        <span>{currency}</span>
        <br/>
        <span onClick={()=>handlePeriod('monthly')}>Monthly {planPeriod=="monthly"?"active":""}</span>
        <br/>
        <span onClick={()=>handlePeriod('yearly')}>Yearly {planPeriod=="yearly"?"active":""}</span>
        <br/>
        <span>{planName}</span>
        <br/>
        <span>{planPrice}</span>
        <br/>
        {/* <span>{isTrialAllowed}</span> */}
        <button onClick={() => handlePayment("pay",planPeriod ,planId)}>Subscribe Now</button>
        </>
        }
        </>
    }

    </div>
  )
  }

export default SubscribeNow