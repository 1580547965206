import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Usercontent from './UserConsent'
import HandoffLog from './HandoffLog'
import AnswerLog from './AnswerLog'
import PendingInquiries from './PendingInquiries'
import { useSelector } from 'react-redux'
import { useEffect,useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAgentData } from '../../features/agent/agentSlice'
import SettingOption from "./sideOptions";
import DataManagement from './DataManagement'



function Settings() {


    const {agentData} = useSelector((state) => state.agentData)
    const [page, setSelectedPage] = useState('gdprconsent')
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        // console.log(page , ' lksahjd')
    
        const page  = params.page
        setSelectedPage(page)
        console.log(page , ' page')
    
        if(JSON.stringify(agentData) === '{}'){
          dispatch(getAgentData())
        }
    
      },[])

    const handleClick = (tab) => {
        navigate(`/cx/settings/${tab}`)
        setSelectedPage(tab)
    }
    

  return (
    <div className="pl-[76px] lg:pl-[276px] p-5">
      <>
      <div className="botTabs mt-5 text-sm px-5">
            sideSettings
      </div>
      </>

      <SettingOption handleClick={handleClick} page={page}/>

      <>
      {page === 'gdprconsent'&&  Object.keys(agentData).length>0 &&  <><div>
        <Usercontent/>
        </div>
        </>}
        {page === 'handofflog'&&  Object.keys(agentData).length>0 &&  <><div>
        <HandoffLog/>
        </div>
        </>}
        {page === 'answerlog'&&  Object.keys(agentData).length>0 &&  <><div>
        <AnswerLog/>
        </div>
        </>}
        {page === 'datamanagement'&&  Object.keys(agentData).length>0 &&  <><div>
        <DataManagement/>
        </div>
        </>}

       

        
        
        
        
      </>
    </div>
  )
}

export default Settings