import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import inquiry from '../../data/templates/Inquiry'
import appointment from '../../data/templates/Appointment'
import answer from '../../data/templates/Answer'
import { getBotFlowChartDataAsyc } from './botTemplateThunk'
import { getProper } from '../../utilities/chatbotMethods'


//@DEF - getting the data from the data/templates/Inquiry.js file  and initializing the state
//@VARIABLE - inquiry
//@TYPE - object

const initialState = {
    isLoading : true,
    template : null,
    botId : null,
    selectedNode : null,
    nodeMenuPosition : null,
    nodeToBeFocused : 0
}


//thunk
export const getBotFlowChartData = createAsyncThunk('botTemplate/getBotFlowChartData' , getBotFlowChartDataAsyc)


//creating slice -- botTemplateSlice
//contains reducer and actions and actions creators
export const botTemplateSlice = createSlice({
  name: 'botTemplate',
  initialState,
  reducers: {
    updateTemplate : (state , action) => {
        state.template = action.payload
    },

    updateSelectedNode : (state , action) => {
        var data =JSON.parse(JSON.stringify(action.payload))
        if(data&&data.data&&data.data.data){
        for (const key in data.data.data) {
          if (data.data.data.hasOwnProperty(key) && typeof data.data.data[key] === 'string') {
            data.data.data[key] = getProper(data.data.data[key]);
          }
        }
        }
        // console.log(data.data.data,"DATA IN SELECTED NODE")
        state.selectedNode =data
    },
    updateNodeMenuPosition : (state , action) => {
      state.nodeMenuPosition = action.payload
    },
    updateNodeToBeFocused : (state , action) => {
      state.nodeToBeFocused = action.payload
    },
    resetTemplate : (state , action) => {
      state.template = null
      state.isLoading = true
    },
    setBotId : (state , action) => {
      state.botId = action.payload
    }
    

    
  },
  extraReducers : (builder) => {
    builder.addCase(getBotFlowChartData.fulfilled , (state , action) => {
      // console.log(action.payload , "payload")
      state.isLoading = false
    }).addCase(getBotFlowChartData.pending , (state , action) => {
      state.isLoading = true
    })
    // builder.addCase(toggleBotStatus.fulfilled , (state , action) => {
    //   console.log(action.payload , "payload")
    //   state.userBots = {
    //     ...state.userBots,
    //     [action.payload.botId] : {
    //       ...state.userBots[action.payload.botId],
    //       status : action.payload.newStatus
    //     }
    //   }
    // })
  }
})




export const {resetTemplate , updateTemplate , updateSelectedNode  ,updateNodeMenuPosition , updateNodeToBeFocused} =  botTemplateSlice.actions


//exporting reducer -- botTemplateReducer
//state -- {botTemplate : Object}
export default botTemplateSlice.reducer