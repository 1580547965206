import React , {useEffect, useState} from 'react'
import RichTextEditor from '../rich-text-editor/RichTextEditor'
import { EditorState , convertToRaw , ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { useDispatch } from 'react-redux';
import { updateSelectedNode, updateTemplate } from '../../features/botTemplate/botTemplateSlice';
import htmlToDraft from 'html-to-draftjs';
import { setAllowSave } from '../../features/globalSave/globalSaveSlice';



function GetPayment(props) {

     //utility function
    //@DEF - converting the html into the draftjs ContentState and the creating Editorstate from that content state
    const getInitialStateForEditor = (html) => {
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          return editorState
        }
        else{
            EditorState.createEmpty()
        }
     }



     //initializing dispatch
     const dispatch = useDispatch()

     //destructuring props
     const {selectedNode , template} = props
 
     //getting flow from the template
     const flow = template.template_One

     //getting the id of the node to be edited
     const nodeId = Number(selectedNode.id)

     const optionPlaceholderString = selectedNode.data.data.optionPlaceholder ? selectedNode.data.data.optionPlaceholder : 'Skip this question'
     const optionSuggestionString = selectedNode.data.data.optionSuggestion ? selectedNode.data.data.optionSuggestion : 'You can skip this question by clicking on '
     const tipIcon = selectedNode.data.data.tipIcon ? selectedNode.data.data.tipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fsk-6.svg?alt=media&token=51845900-3ba7-4743-adc5-56df7882641f'
     const skipIcon = selectedNode.data.data.skipIcon ? selectedNode.data.data.skipIcon : 'https://firebasestorage.googleapis.com/v0/b/chatbot-production-d6ea3.appspot.com/o/skipIcons%2Fssk-2.svg?alt=media&token=c287d606-d502-4b08-b5fd-668082f11556'
     const textHandoffString = selectedNode.data.data.textHandoff ? selectedNode.data.data.textHandoff : selectedNode.data.data.text

     //initial state of rich text editor
    //  const [botMessageText , setBotMessageText] = useState(EditorState.createWithContent(ContentState.createFromText(selectedNode.data.data.text)))
     const [botMessageText , setBotMessageText] = useState(getInitialStateForEditor(selectedNode.data.data.text))
     const [responseText , setResponseText] = useState(getInitialStateForEditor(selectedNode.data.data.responseText))
     const [optionPlaceholder , setOptionPlaceholder] = useState(getInitialStateForEditor(optionPlaceholderString))
     const [optionSuggestion , setOptionSuggestion] = useState(getInitialStateForEditor(optionSuggestionString))
     const [textHandoff , setTextHandoff] = useState(getInitialStateForEditor(textHandoffString))
     const [isOptional , setIsOptional] = useState(selectedNode.data.data.isOptional ? selectedNode.data.data.isOptional : false)
     const [shortHand , setShortHand] = useState(selectedNode.data.data.shortHand ? selectedNode.data.data.shortHand : false)
     const [paymentOption , setPaymentOption] = useState(selectedNode.data.data.isurl ? 'stripe' : 'link')
     const [cardsOptions , setCardsOptions] = useState(selectedNode.data.data.cardsOptions)


 

     //utility function 
     //@DEFR - getting html  data from  the editor state
     const getHtmlFromEditorState = (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
     }


     

     



     //handler function
     //@DEF - collects the data from the local states and modifying the tenplate by dispatching action
     const handleSave = () => {

        //creating new edited data
        const modifiedData = {
            ...selectedNode.data,
            data : {
                ...selectedNode.data.data,
                text : getHtmlFromEditorState(botMessageText),
                responseText : getHtmlFromEditorState(responseText),
                isOptional,
                optionPlaceholder : getHtmlFromEditorState(optionPlaceholder),
                optionSuggestion : getHtmlFromEditorState(optionSuggestion),
                shortHand,
                textHandoff : getHtmlFromEditorState(textHandoff),
                skipIcon,
                tipIcon,
                isurl : paymentOption === 'link' ? false : true,
                isUrl : paymentOption === 'link' ? false : true,
                cardsOptions
                

            }
        }


        //creating new flow
        const newFlow = {
            ...flow,
            [nodeId] : modifiedData
        }


        //creating new template
        const newTemplate = {
            ...template,
            template_One : newFlow
        }

        //dispatching action
        dispatch(updateTemplate(newTemplate))

        dispatch(setAllowSave(true))
        //dispatching action
        dispatch(updateSelectedNode(null))
     }


     //handler function
     //@DEF - remove the editor by dispatching action
     const handleCancel = () => {
        dispatch(updateSelectedNode(null))
     }


     //handler function
     const onPaymentLinkChange = (event,indexToBeModified) => {
        const newCardsOption = cardsOptions.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    payment_url : event.target.value,
                    payment_url1 : event.target.value,
                    publicable_key : '',
                    secret_key : '',
                    title : '',
                    footer_button_text: 'Buy Now',
                }
            }
            else{
                return item
            }
        })


        setCardsOptions(newCardsOption)
     }

     //handler function
     const onPaymentLinkButtonChange = (event,indexToBeModified) => {
        const newCardsOption = cardsOptions.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    footer_button_text1: event.target.value,
                    publicable_key : '',
                    secret_key : '',
                    title : '',
                    footer_button_text : 'Buy Now'
                }
            }
            else{
                return item
            }
        })
        setCardsOptions(newCardsOption)
     }

     //handler function
     const onPublicableKeyChange = (event,indexToBeModified) => {
        const newCardsOption = cardsOptions.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    publicable_key : event.target.value,
                    footer_button_text1: 'Buy Now',
                    payment_url: '',
                    payment_url1: ''
                }
            }
            else{
                return item
            }
        })
        setCardsOptions(newCardsOption)
     }

     //handler function
     const onSecretKeyChange = (event,indexToBeModified) => {
        const newCardsOption = cardsOptions.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    secret_key : event.target.value,
                    footer_button_text1: 'Buy Now',
                    payment_url: '',
                    payment_url1: ''
                }
            }
            else{
                return item
            }
        })
        setCardsOptions(newCardsOption)
     }

     //handler function
     const onTitleChange = (event,indexToBeModified) => {
        const newCardsOption = cardsOptions.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    title : event.target.value,
                    footer_button_text1: 'Buy Now',
                    payment_url: '',
                    payment_url1: ''
                }
            }
            else{
                return item
            }
        })
        setCardsOptions(newCardsOption)
     }

     //handler function
     const onStripeButtonChange = (event,indexToBeModified) => {
        const newCardsOption = cardsOptions.map((item,index) => {
            if(index === indexToBeModified){
                return {
                    ...item,
                    footer_button_text : event.target.value,
                    footer_button_text1: 'Buy Now',
                    payment_url: '',
                    payment_url1: ''
                }
            }
            else{
                return item
            }
        })
        setCardsOptions(newCardsOption)
     }

  return (
    <>
        <div className="mb-4">
        <div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot question text</h6>
            <RichTextEditor  editorState = {botMessageText} setEditorState = {setBotMessageText} selectedNode = {selectedNode}/>
        </div>
        </div>
        <h5 className='px-3 mt-2 mb-2 text-[#5C5C5C] text-sm border-b-2 pb-2 relative'>
        Payment method
        </h5> 
        <div className='flex px-3'>
            <div className='mr-3'>
                <input type="radio" id="paymentBtn" name="fav_language" checked = {paymentOption === 'link' ? true : false} onChange = {() => {setPaymentOption('link')}} />
                <label for="paymentBtn" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Payment link</label>
            </div>
            <div>
                <input type="radio" id="paymentBtn1" name="fav_language" checked = {paymentOption === 'stripe' ? true : false} onChange = {() => {setPaymentOption('stripe')}}/>
                <label for="paymentBtn1" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Stripe</label>
            </div>
        </div>
        {paymentOption  === 'link' && cardsOptions.map((item , index) => {
            return <div className='px-3 mt-3'>
            <input value = {item.payment_url} onChange = {(event) => onPaymentLinkChange(event,index)}  type='text' className='w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Enter payment link'/>   
            <input value = {item.footer_button_text1} onChange = {(event) => onPaymentLinkButtonChange(event,index)}  type='text' className='mt-3 w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Buy Now'/> 
            {/* <div className='flex justify-between mt-4 gap-3'>
                <input type='text' className='w-1/2 border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Publishable Key '/>   
                <input type='text' className='w-1/2 border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Secret Key'/>     
            </div>   */}
        </div>
        })}
        {paymentOption  === 'stripe' && cardsOptions.map((item,index) => {
            return <div className='px-3 mt-3'>
            <div className='flex justify-between mt-4 gap-3 mb-3'>
                <input value = {item.publicable_key} onChange = {(event) => onPublicableKeyChange(event,index)} type='text' className='w-1/2 border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Publishable Key '/>   
                <input value = {item.secret_key} onChange = {(event) => onSecretKeyChange(event,index)} type='text' className='w-1/2 border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Secret Key'/>     
            </div> 
            <input value = {item.title} onChange = {(event) => onTitleChange(event,index)} type='number' className='w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Enter price here'/>   
            <input value = {item.footer_button_text} onChange = {(event) => onStripeButtonChange(event,index)} type='text' className='mt-3 w-full border-[1px] focus:outline-none h-8 rounded px-2 text-xs' placeholder='Buy Now'/> 
             
        </div>
        })}

        <div className="mb-2 mt-5">
        <div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Payment success message</h6>
            <RichTextEditor editorState = {responseText} setEditorState = {setResponseText} selectedNode = {selectedNode} />
        </div>
        </div>
           
    <div className="editorBtn px-3 py-3">
    <div className="mb-3">
        <input checked = {shortHand} onChange = {(event) => setShortHand(event.target.checked)} type="checkbox" id="Shorten" />
        <label for="Shorten" className="cursor-pointer text-sm text-[#5C5C5C] pl-2">Shorten for Handoffs</label>
    </div>
    {shortHand && <div className='px-3'>
            <h6 className='mt-2 mb-2 text-[#5C5C5C] text-sm'>Bot question text</h6>
            <RichTextEditor  editorState = {textHandoff} setEditorState = {setTextHandoff} selectedNode = {selectedNode}/>
        </div>}
      <button onClick = {handleSave} className="border border-indigo-600 text-sm px-3 py-1 m-auto text-center rounded text-indigo-600 bg-indigo-600 text-white">Save as draft</button>
      <button onClick = {handleCancel} className="group ml-3 border border-[#C6C6C6] hover:border-[#4E4E4E] text-sm px-3 py-1 m-auto text-center rounded text-[#4E4E4E] hover:bg-[#4E4E4E]  hover:text-white">Cancel</button>
   </div>
  </>
    )

}

export default GetPayment;


//isurl === true the method === stripe