import React from "react";
import {Link} from 'react-router-dom'
import SideMenu from "../../Component/sidemenu";
import Topheader from "../../Component/TopHeader";


const Usercontent =()=>{
    return(
        <>
            {/* <SideMenu /> */}
            <div className="pl-[76px] lg:pl-[276px] p-5">
                <Topheader Usercontent="User consent"/>
                <h4 className="flex items-center py-4 text-[#363636] font-semibold ">
                <div className="ml-auto">
                    <button className="border border-[#454545] text-sm px-5 py-1 m-auto text-center rounded text-[#454545]-600 hover:bg-[#454545]  hover:text-white mr-4">Cancel</button>
                    <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">Save changes</button>
                </div>
                </h4>
                <div className="goalsTray py-4 bg-white rounded-2xl mb-5">
                    <h5 className="text-xl font-medium text-[#2E4765] px-4">Goals Tray</h5>
                    <h6 className="text-[#2E4765] font-medium text-base px-4">Bot Flow Summary</h6>
                    <div className="goalsTryRow block align-center my-5 px-4 sm:flex">
                        <div className="goalsTryCol sm:w-2/6 w-full">
                            <p className="text-xs font-light mb-2">Ask consent from</p>
                            <select className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25">
                                <option>None</option>
                            </select>
                        </div>
                    </div>
                    <div className="py-5 px-5">
                        <div className="goalsTryCol  w-full">
                            <p className="text-xs font-light mb-2" >Consent content</p>
                            <textarea type="text" className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" rows="8" placeholder="Enter Text Here…"/>
                        </div>
                    </div>
                    <div className="goalsTryRow block align-center my-5 px-4 sm:flex">
                        <div className="goalsTryCol sm:w-2/6 w-full">
                            <p className="text-xs font-light mb-2">Ask consent from</p>
                            <select className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25">
                                <option>https://chatbot.appypie.com</option>
                            </select>
                        </div>
                        <div className="goalsTryCol sm:w-2/6 w-full">
                            <p className="text-xs font-light mb-2">Consent button text</p>
                            <select className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25">
                                <option>Yes, I agree</option>
                            </select>
                        </div>
                    </div>
                    <div className="goalsTryRow block align-center my-5 px-4 sm:flex">
                        <div className="goalsTryCol sm:w-2/6 w-full">
                            <p className="text-xs font-light mb-2">Ask consent from</p>
                            <select className="border sm:w-11/12 w-full px-3 py-1 text-sm rounded" value="25">
                                <option>https://chatbot.appypie.com</option>
                            </select>
                        </div>
                    </div>
                    <div className="goalsTryRow my-5 px-4 pt-5">
                        <button className="border border-[#454545] text-sm px-5 py-1 m-auto text-center rounded text-[#454545]-600 hover:bg-[#454545]  hover:text-white mr-4">Cancel</button>
                        <button className="border border-indigo-600 text-sm px-5 py-1 m-auto text-center rounded text-indigo-600 hover:bg-indigo-600  hover:text-white ">Save changes</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Usercontent


